import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteField} from "../../foundation";



export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 0;

    const onFunding = (e) => {
        var value = e.target.value;
        dispatch({type: "updateModel", value: {Funding: value}});
    }

    // record.Funding

    return ( // --- TEMP
        <Sprite className="rds-promo-funding is-abs-all" style={{height:600, width: 'auto'}}>

            <SpriteField onChange={onFunding} debug={debug} value={''} placeholder="Search fundings" style={{top: 104, left: 7, width: 274}} />

        </Sprite>
    );
};

