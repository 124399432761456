import React from "react";
import {Layout} from "../../foundation/Layout";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar3, Paginationbar} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Sprite, SpriteButton} from '../../foundation';
import {Timeline} from '../../components/Timeline';



export default function Screen(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const onClickList = () => dispatch({type: "setScreen", value: "funding-list"});
    const onClickCards = () => dispatch({type: "setScreen", value: "funding-cards"});
    var rows = globals.fundings_rows;


    return (
        <Layout>
            <Searchbar />

            <Titlebar3 type="funding-timeline">
                <SpriteButton className="is-abs-right is-debu" style={{width: 20, right: 60}} onClick={onClickList} />
                <SpriteButton className="is-abs-right is-debu" style={{width: 20, right: 30}} onClick={onClickCards} />
            </Titlebar3>

            <Timeline rows={rows} />


            <Paginationbar />
        </Layout>
    );
}
