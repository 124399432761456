import React from "react";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteButton, SpriteText} from "../../foundation";
import {FullviewButton} from "../../Panels/MessagePanel";
import {TitlebarAdv} from "../../components/Toolbars";
import Conditions from "../Internal/Conditions";
import Accounting from "../Internal/Accounting";
import Locations from "../Internal/Locations";
import Products from "../Internal/Products";

import FundingGeneral from "./FundingGeneral";
import FundingFunds from "./FundingFunds";
import FundingProducts from "./FundingProducts";
import FundingLocations from "./FundingLocations";
import FundingConditions from "./FundingConditions";
import FundingAccounting from "./FundingAccounting";
import "./FundingView.scss";

// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';
// import "./Dashboard.scss";


function Nav ({screen}) {
    const dispatch = useGlobalsDispatch();
    // const css = classNames('rds-tabnav', className);
    console.log("------ FUNDING 111:", screen);
    if(screen === "funding" || screen === "funding-view") screen = "funding-general";
    const onClick = (screen) => {dispatch({type: "setScreen", value: screen})};
    return (
        <div className='rds-tabnav is-type-funding' style={{height: 408}}>
            <SpriteButton debug={0} className={screen === "funding-general" && "is-selected"} onClick={() => onClick("funding-general")} />
            <SpriteButton debug={0} className={screen === "funding-funds" && "is-selected"} onClick={() => onClick("funding-funds")} />
            <SpriteButton debug={0} className={screen === "funding-products" && "is-selected"} onClick={() => onClick("funding-products")} />
            <SpriteButton debug={0} className={screen === "funding-locations" && "is-selected"} onClick={() => onClick("funding-locations")} />
            <SpriteButton debug={0} className={screen === "funding-conditions" && "is-selected"} onClick={() => onClick("funding-conditions")} />
            <SpriteButton debug={0} className={screen === "funding-accounting" && "is-selected"} onClick={() => onClick("funding-accounting")} />
        </div>
    );
}


function Content({screen}){
    const globals = useGlobals();
    const record = globals.model;

    switch(screen){
        default:
        case 'funding-general':     return <FundingGeneral record={record} />; // <Sprite className="rds-funding-general is-internal" style={{height:600}} />;
        case 'funding-funds':       return <FundingFunds record={record} />
        case 'funding-products':    return <FundingProducts record={record} />
        case 'funding-locations':    return <FundingLocations record={record} />
        case 'funding-conditions':    return <FundingConditions record={record} />
        case 'funding-accounting':    return <FundingAccounting record={record} />
    }
}

function ViewPromoBar({label}){
    const dispatch = useGlobalsDispatch();
    const onClickCancel = () => {
        dispatch({type: "setScreen", value: "funding-list"});
    };

    return (
        <Sprite className="rds-viewpromobar is-center" style={{width: 1344, height: 40, marginBottom: 20}}>
            <SpriteText className="is-graybg is-abs is-body2 is-debu" style={{left: 200, minWidth: 400, top: 4}}>{label}</SpriteText>
            <SpriteButton className="is-abs-right" style={{top: 1, right: 1}} debug={0} onClick={onClickCancel} />
            <SpriteButton className="is-abs-right" style={{top: 1, right: 120}} debug={0} onClick={onClickCancel} />
        </Sprite>
    );
}


export default function Screen({screen}){
    const globals = useGlobals();
    const dispatch = useGlobalsDispatch();
    if(!screen) screen = "funding-define";

    const onSave = () => {
        dispatch({type: "saveDetails", table: "fundings", screen: "funding-list"});
    }

    const onCancel = () => {
        dispatch({type: "cancelDetails", table: "fundings", screen: "funding-list"});
        // dispatch({type: "setScreen", value: "funding-list"});
    }

    return (
        <div>
            <TitlebarAdv label="Funding Instance">
                <Template type="save-buttons" onSave={onSave} onCancel={onCancel} />
            </TitlebarAdv>



            <TabLayout>
                <TabLayoutLeft>
                    <Nav screen={screen} />
                    <Template type="changelogs" />
                    
                    <FullviewButton type="funding" />
                </TabLayoutLeft>
                <TabLayoutRight>
                    <Content screen={screen} />
                </TabLayoutRight>
            </TabLayout>
        </div>
    );
}