import React from "react";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteButton, SpriteText} from "../../foundation";
import {TitlebarAdv} from "../../components/Toolbars";
import Locations from "../Internal/Locations";
import AssignPromos from "../Internal/AssignPromos";

import VehicleDefine from "./VehicleDefine";
import VehicleLocations from "./VehicleLocations";
import VehicleAssignPromos from "./VehicleAssignPromos";

// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';
// import "./Dashboard.scss";


function Nav ({screen}) {
    const dispatch = useGlobalsDispatch();
    // const css = classNames('rds-tabnav', className);
    console.log("------ FUNDING 111:", screen);
    if(screen === "vehicle" || screen === "vehicle-view") screen = "vehicle-general";
    const onClick = (screen) => {dispatch({type: "setScreen", value: screen})};
    return (
        <div className='rds-tabnav is-type-vehicle' style={{height: 204}}>
            <SpriteButton debug={0} className={screen === "vehicle-define" && "is-selected"} onClick={() => onClick("vehicle-define")} />
            <SpriteButton debug={0} className={screen === "vehicle-locations" && "is-selected"} onClick={() => onClick("vehicle-locations")} />
            <SpriteButton debug={0} className={screen === "vehicle-assignpromos" && "is-selected"} onClick={() => onClick("vehicle-assignpromos")} />
        </div>
    );
}


function Content({screen}){
    const globals = useGlobals();
    const record = globals.model;

    switch(screen){
        default:
        case 'vehicle-define':     return <VehicleDefine record={record} />; //<Sprite className="rds-vehicle-define is-internal" />;
        case 'vehicle-locations':   return <VehicleLocations record={record} />; //<Sprite className="rds-vehicle-define is-internal" />;
        case 'vehicle-assignpromos':    return <VehicleAssignPromos record={record} />; //<Sprite className="rds-vehicle-define is-internal" />;
    }
}

function ViewPromoBar({label}){
    const dispatch = useGlobalsDispatch();
    const onClickCancel = () => {
        dispatch({type: "setScreen", value: "vehicle-list"});
    };

    return (
        <Sprite className="rds-viewpromobar is-center" style={{width: 1344, height: 40, marginBottom: 20}}>
            <SpriteText className="is-graybg is-abs is-body2 is-debu" style={{left: 200, minWidth: 400, top: 4}}>{label}</SpriteText>
            <SpriteButton className="is-abs-right" style={{top: 1, right: 1}} debug={0} onClick={onClickCancel} />
            <SpriteButton className="is-abs-right" style={{top: 1, right: 120}} debug={0} onClick={onClickCancel} />
        </Sprite>
    );
}


export default function Screen({screen}){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const model = globals.model || {};
    if(!screen) screen = "vehicle-define";

    console.log("model:", model);


    const onCancel = () => {
        dispatch({type: "setScreen", value: "vehicle-list"});
    }

    const onSave = () => {
        onCancel(); // --- TEMP
    }

    return (
        <div>
            <TitlebarAdv label="Vehicle Instance" description={model.Name}>
                <Template type="save-buttons" onCancel={onCancel} onSave={onSave} />
            </TitlebarAdv>


            <TabLayout>
                <TabLayoutLeft>
                    <Nav screen={screen} />
                    <Template type="changelogs" />
                </TabLayoutLeft>
                <TabLayoutRight>
                    <Content screen={screen} />
                </TabLayoutRight>
            </TabLayout>
        </div>
    );
}