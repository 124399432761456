import React from "react";
import classNames from 'classnames';
//import {useGlobals} from '../app/context';
// import Drawer from '@mui/material/Drawer';
import './Panel.scss';


export function PanelBottom({className, style, children}){
    const css = classNames("rds-panelbottom", className);
    return <div className={css} style={style}>{children}</div>;
}

export function Panel({name, className, style, children}){
    const css = classNames("rds-panel is-name-" + name, className);
    return <div className={css} style={style}>{children}</div>;
}

