import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton} from '../foundation';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';



export default function Panel({name}){
    const dispatch = useGlobalsDispatch();
    const onClickCancel = () => {dispatch({type: 'hidePanel'})}
    // console.log("++++++++++++++++++ StaticPanel: ", name);

    return (
        <Sprite className="rds-panel-edit-table" style={{width: 420, height: 700}}>
            <SpriteButton className="is-abs-right" debug={0} style={{top: 640, right: 140, width: 90}} onClick={onClickCancel} />
            {false && <SpriteButton className="is-abs-right" debug={0} style={{top: 640, right: 0, width: 70}} onClick={onClickCancel} />}
        </Sprite>
    );

}
