import React from "react";
import {Layout} from "../../foundation/Layout";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar, Paginationbar, Titlebar3} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Template, SpriteButton} from '../../foundation';
import {Timeline} from '../../components/Timeline';
// import {Link2} from '../../foundation/Text';
import {Icon} from '../../foundation/Icon';
import {Button2} from '../../foundation/Button';




export default function Screen(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var rows = globals.promos_rows;


    function onClickList(){dispatch({type: "setScreen", value:"promo-list"});}
    function onClickCards(){dispatch({type: "setScreen", value:"promo-cards"});}
    function onClickTimeline(){dispatch({type: "setScreen", value:"promo-timeline"});}
    function onClickGraphs(){dispatch({type: "setScreen", value:"promo-graphs"});}


    const onClick = () => {};
    return (
        <Layout>
            <Searchbar />

            <Titlebar3 type="promo-timeline">
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 60}} onClick={onClickList} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 30}} onClick={onClickCards} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20}} onClick={onClickGraphs} />
            </Titlebar3>



            <Timeline rows={rows} type="promo" />



            <Paginationbar />


        </Layout>
    );
}
