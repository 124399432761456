import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton, SpriteInput, PanelBottom, Group, EditTableList, } from '../foundation';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';



export default function Panel({name, }){
    const dispatch = useGlobalsDispatch();
    const DEBUG = 1;

    // values
    const [values, setValues] = React.useState({});
    const [changed, setChanged] = React.useState(false);

    // actions
    const onCancel = () => {dispatch({type: 'hidePanel'})}
    const onSave = () => {dispatch({type: 'hidePanel'})}
    const onAction = () => {
        setChanged(true);
        // dispatch({type: "insertColumn", });
    }

    // return
    return (
        <React.Fragment>

            <Sprite absall className="rds-base-panel-edit-table" style={{width: 420, height: 600}}>

                <EditTableList itemCount={10} onAction={onAction} style={{top: 100, left: 4, width: 414, }} />
                
            </Sprite>

            <PanelBottom>
                <Group type="cancel-save" onCancel={onCancel} onSave={onSave} disabled={!changed} />
            </PanelBottom>

        </React.Fragment>


        


    );

}
