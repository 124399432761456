import gen from "./_generator";
import periods from "./periods";
import vehicleTypes from "./vehicleTypes";
import data from "./vehiclesData";
import utils from "../app/utils";


const status_enum = ["Planning", "Active", "Pending", "Completed"];
const status_index = [1,0,3,2,0,1,0,3,2,2,3,0,3,3,1,2,0,3,1,3,2,0,2,2,3,3,0,1,3,2,3,0,1,2,3,0,1,3,0,2,3,0,3,1,3,0,2,2,0,3,3,1,2,3,0,3,1,3,2,0,2,2,3,0,1,3,3,2,0,3,1,2,3,0,1,0,3,2,0,1,0,3,2,2,3,0,3,3,1,2,0,3,1,3,2,0,2,2,3,3,0,1,3,2,3,0,1,2,3,0,1,3,0,2,3,0,3,1,3,0,2,2,0,3,3,1,2,3,0,3,1,3,2,0,2,2,3,0,1,3,3,2,0,3,1,2,3,0];

var rows = gen.rows(data.length, (i) => {
    const row = data[i];

    const obj = {
        ID: row.ID,
        Name: row.Vehicle,
        Status: status_enum[status_index[i]],
        Locations: utils.toString(row.Locations).split(","),
        Promotion_Count: row.Promotions,
        Type_ID: row.Vehicle_Type_ID, // FLOAT: 3.1 (example)
        Type: row.Vehicle_Type, // STRING
        // --- NEED MORE?

        // move over
        External_ID: row.External_ID,
        Internal_ID: row.Internal_ID,
        Start_Date: row.Start_Date,
        End_Date: row.End_Date,
        Period: row.Period,
        Description: row.Description,

        // extras
        _Products_Expanded: [], // for TreeSelect
        _Locations_Expanded: [], // for TreeSelect
    }


    /* --- OLD
    // added by kw
    row.Name = row.Vehicle;
    delete row.Vehicle;
    row.Status = gen.randomItem(["Planning", "Active", "Pending", "Completed"]);
    row.Locations = utils.isString(row.Locations) ? row.Locations.split(",") : [];
    row.Promotion_Count = gen.randomItem([3, 12, 1, 5, 10, 0, 0]);

    // extras
    row._Products_Expanded = []; // for TreeSelect
    row._Locations_Expanded = []; // for TreeSelect
    */

    // return
    return utils.createRow("vehicles", obj);
});



/*
var rows = gen.rows(10, (i) => {
    const period = gen.randomItem(periods);

    const obj = {
        ID: gen.id(i),
        Name: "Vehicle " + (i+1),
        Type: gen.randomItem(vehicleTypes).Name,
        Status: gen.randomItem(["Planning", "Active", "Pending", "Completed"]), // --- KW: added
        Promotion_Count: gen.randomItem([10, 8, 6, 14, 12, 18]), // --- KW added based on Figma prototype
        External_ID: gen.extId(i),
        Internal_ID: gen.intId(i),
        Start_Date: period.Start_Date,
        End_Date: period.End_Date,
        Period: period.Name,
        Locations: gen.randomItem(gen.locations),
        Promotions: null,
        Description: "Vehicle description " + (i + 1),
    };

    
    // return
    return utils.createRow("vehicles", obj);
});
*/


export default rows;

