import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteField, SpriteButton, SpriteSelect} from "../../foundation";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';


export default function Screen({record}){
    const debug = 0;


    return (
            <Sprite debug={debug} className="rds-vehicle-assignpromos  is-abs-all" style={{height:600, width: 'auto'}}>
            </Sprite>
    );
}

