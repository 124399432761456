import React, { useState } from 'react';
import classNames from 'classnames';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {Sprite, SpriteButton, SpriteField} from "../foundation"
import './DateSelect.scss';

const makeDate = (d) => {
    if(d instanceof Date) return d;
    if(typeof d === 'object') return;
    d = new Date(d);
    return (isNaN(d.getFullYear()) || d.getFullYear() < 2000) ? undefined : d;
};

const printDate = (d) => {
    d = makeDate(d);
    if(d) return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear(); 
    return '';
}

export function DateSelect({className, style, onStart, onEnd, start, end}){
    const [isPeriods, showPeriods] = useState(false);
    const [dates, setDates] = useState(null);
    const [startInput, setStartInput] = useState('');
    const [endInput, setEndInput] = useState('');

    React.useEffect(() => {
        start = makeDate(start);
        end = makeDate(end);
        if(start && end && (start <= end)){
            setDates([start, end]);
            setStartInput(printDate(start));
            setEndInput(printDate(end));
        }
        else setDates(null);
        
    }, [start, end]);

    const css = classNames('lds-dateselect is-debug222 is-abs-all', className, isPeriods && "is-periods");
    const onRange = () => {
        console.log("--- onRange");
        showPeriods(false);
    }
    const onPeriod = () => {
        console.log("--- onPeriod");
        showPeriods(true);
    }

    const onChange = (arr) => {
        const filled = arr?.length;
        onStart(filled ? arr[0] : null);
        onEnd(filled ? arr[1] : null);
    }

    // return
    return (
        <Sprite style={style} className={css}>
            <SpriteButton debug={0} style={{top: 20, left: 126, width: 80}} onClick={onRange} />
            <SpriteButton debug={0} style={{top: 20, left: 216, width: 80}} onClick={onPeriod} />

            <SpriteField readOnly debug={0} value={startInput} style={{top: 87, left: 30, width: 100}} />
            <SpriteField readOnly debug={0} value={endInput} style={{top: 87, left: 234, width: 100}} />

            {true && <Sprite debug={0} style={{top: 182, left: 12, width: 200, height: 30, zIndex: 2, cursor: "text", }} />}

            <Sprite className="lds-dateselect-cal" style={{top: 220, left: 10}}>
                <DateRangePicker isOpen={true} onChange={onChange} value={dates} />
            </Sprite>
            
        </Sprite>
    );
}

