import React from "react";
import utils from "../../app/utils";
import {Layout} from "../../foundation/Layout";
import {FilterToolbar, Paginationbar, } from "../../components/Toolbars";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteTooltip, SpriteInput, } from '../../foundation/Sprite';
import {Popover} from '../../foundation';
import classNames from "classnames";
import "./Taskmgt.scss";




// // invisible searchbar
// function FilterToolbar222({style, debug, }){
//     const [open, setOpen] = React.useState(false);

//     // init
//     React.useEffect(() => {
//         const onClickBody = () => {setOpen(false);}

//         // body click listener
//         document.addEventListener("click", onClickBody);
//         return () => {
//             document.removeEventListener("click", onClickBody);
//         };
//     }, []);

//     const onClickBar = (e) => {e.stopPropagation();}
//     const onClickFilter = (e) => {setOpen(!open);}
//     const onClickClearAll= () => {setOpen(!open)}

//     // popover - menu
//     const [recentEl, setRecentEl] = React.useState(null); // NOTE: remove
//     const recentOpen = !!recentEl;    
//     const onCloseMenu = () => {setRecentEl(null);};
//     const onClickMenu = (e) => setRecentEl(e.currentTarget);

//     // return
//     const css = classNames("rds-price-searchbar", open && 'is-open');
//     return (
//         <Sprite absall className={css} style={style} onClick={onClickBar}>
            
//             <SpriteButton debug={DEBUG} style={{top: 8, left: 450, width: 130, }} onClick={onClickFilter} />
//             <SpriteButton debug={DEBUG} style={{top: 60, left: 170, width: 230, }} onClick={onClickMenu} />

//             <SpriteButton debug={DEBUG} style={{top: 60, left: 'auto', right: 0, width: 200, }} onClick={onClickClearAll} />

//             <Popover name="price-searchbar-menu" shape="rounded" open={recentOpen} anchorEl={recentEl} onClose={onCloseMenu} />

//         </Sprite>
//     );
// }





const DEBUG = 0;

export default function Page(){
    const dispatch = useGlobalsDispatch();
    // --- WIP

    // popover - category
    const [catEl, setCatEl] = React.useState(null); // NOTE: remove
    const catOpen = !!catEl;    
    const onCatClose = () => {setCatEl(null);};
    const onClickCat = (e) => setCatEl(e.currentTarget);

    // popover - column menu
    const [recentEl, setRecentEl] = React.useState(null);
    const recentOpen = !!recentEl;    
    const onClickColumn = (e) => setRecentEl(e.currentTarget);
    const onCloseColumn = () => setRecentEl(null);

    // handlers
    const onClickColumnMenu = (i) => {
        // if(i === 8) showFormula(true);
    };

    const onClickFilter = () => dispatch({type: "showPanel", value: "taskmgt-filters"});
    const onClickEditTable = () => dispatch({type: "showPanel", value: "edit-price-table"});

    const onNameClick = () => utils.loader(dispatch, {type: 'setScreen', value: 'taskmgt-details'}, 1000);


    // return
    return (
        <Layout>

            <Sprite debug={0} absall className="rds-taskmgt-list" style={{width: 1364, height: 660, left: 0, }}>

                {/* toolbars */}
                <FilterToolbar debug={DEBUG} style={{top: 51, left: -3, }} onClickFilter={onClickFilter} />
                <SpriteInput debug={DEBUG} style={{top: 60, left: 165, height: 30, width: 270}} placeholder="Search"  />

                <SpriteButton debug={DEBUG} style={{top: 60, left: 0, width: 150, }} onClick={onClickCat} />
                <Popover name="searchbar-category" shape="rounded" open={catOpen} anchorEl={catEl} onClose={onCatClose} />

                <SpriteButton debug={DEBUG} style={{top: 60, left: 'auto', right: 30, width: 150, }} onClick={onClickEditTable} />


                {/* column menus */}
                <SpriteButton debug={DEBUG} style={{top: 108, left: 40, width: 120, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 160, width: 120, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 310, width: 120, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 460, width: 120, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 610, width: 120, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 820, width: 120, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 950, width: 120, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 1090, width: 120, }} onClick={onClickColumn} />

                <Popover name="price-column-menu" shape="rounded" open={recentOpen} anchorEl={recentEl} onClickItem={onClickColumnMenu} onClose={onCloseColumn} />


                {/* tooltips */}
                <SpriteTooltip debug={DEBUG} tooltip="2 days left to the due date" style={{top: 228, left: 1024, }} />
                <SpriteTooltip debug={DEBUG} tooltip="The task is overdue by 3 days" style={{top: 324, left: 1024, }} />

                {/* tooltips */}
                <SpriteButton debug={DEBUG} style={{top: 140, left: 160, width: 150, height: 500, }} onClick={onNameClick} />



            </Sprite>

            <Paginationbar />
        </Layout>
    );
}


