import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteField, Select, SelectMenu, SelectAuto} from "../../foundation";
import {DateSelect} from "../../components/DateSelect";



export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 0;



    const onName = (e) => {
        const value = utils.toFilledString(e.target.value, record.Name);
        dispatch({type: "updateModel", value: {Name: value}});
    }

    const onExtID = (e) => {
        const value = utils.toFilledString(e.target.value, record.External_ID);
        dispatch({type: "updateModel", value: {External_ID: value}});
    }

    const onDesc = (e) => {
        const value = utils.toFilledString(e.target.value, record.Description);
        dispatch({type: "updateModel", value: {Description: value}});
    }

    const onStatus = (e) => {
        const value = utils.toFilledString(e.target.value, record.Status);
        dispatch({type: "updateModel", value: {Status: value}});
    }
    const onCustom = (e) => {
        const value = utils.toFilledString(e.target.value, record.Custom_Labels);
        dispatch({type: "updateModel", value: {Custom_Labels: value}});
    }

    // // status
    // const [statusAnchor, setStatusAnchor] = React.useState(null);
    // const statusOpen = Boolean(statusAnchor);
    // const onClickStatus = (event) => {setStatusAnchor(event.currentTarget)}; // console.log("YOOOOOOOO 1111 onClickStatus");
    // const onCloseStatus = () => setStatusAnchor(null);
    //        <SelectMenu type="status" open={statusOpen} onClose={onCloseStatus} anchor={statusAnchor} width={600} />
     //       <Select type="status-solid" menuType="status" value={record.Status} onClick={onClickStatus} className="is-abs" style={{top: 333, left: 2, width: 418}} />

     const onStatusChange = (value) => {
        console.log("--------- onStatusSelect: ", value);
        dispatch({type: "updateModel", value: {Status: value}});
     }

     // dates...
     console.log("==== record.StartDate: "+ record.StartDate);
     const [startDate, setStartDate] = React.useState([record.StartDate, record.EndDate]);
     const [endDate, setEndDate] = React.useState(null);
     const onStartDate = (d) => {
        //setStartDate(d);
        // setDatesStr("START: " + printDate(d));
    }
    const onEndDate = (d) => {
        //setEndDate(d);
        // setDatesStr2("END: " + printDate(d));
    }

     // END dates

    // return
    return (
        <Sprite className="rds-promo-define is-abs-all" style={{height:600, width: 890}}>
            

            <SpriteField onChange={onName} debug={debug} value={record.Name} style={{top: 73, left: 7, width: 400}} />
            <SpriteField onChange={onExtID} debug={debug} value={record.External_ID} style={{top: 161, left: 7, width: 400}} />
            <SpriteField onChange={onDesc} debug={debug} value={record.Description} style={{top: 249, left: 7, width: 400}} />

            <Select type="status-solid" value={record.Status} onChange={onStatusChange} className="is-abs" style={{top: 333, left: 2, width: 418}} />

            <SpriteField onChange={onCustom} debug={debug} value={record.Custom_Labels} style={{top: 425, left: 7, width: 400}} />

            <DateSelect style={{top: 42, left: 463}} start={record.Start_Date} end={record.End_Date} onStart={()=>{}} onEnd={()=>{}} />

        </Sprite>
    );
};

