import classNames from 'classnames';
import {Sprite, SpriteButton} from "./Sprite";
import utils from '../app/utils';
import './Sprite.scss';


function TableControls({style, className, onClick1, onClick2, onClick3}){
    return (
        <Sprite debug={0} style={{width: 190, height: 30, ...style}} className={className + " is-abs-right"}>
            <SpriteButton debug={0} onClick={onClick1} className="is-abs-right" style={{width: 90, right: 80}} />
            <SpriteButton debug={0} onClick={onClick2} className="is-abs-right" style={{width: 25, right: 25}} />
            <SpriteButton debug={0} onClick={onClick3} className="is-abs-right" style={{width: 25, right: 0}} />
        </Sprite>
    );
}

function TableControls2({style, className, onClick1}){
    return (
        <Sprite debug={0} style={{width: 80, height: 30, ...style}} className={className + " is-abs-right"}>
            <SpriteButton debug={0} onClick={onClick1} className="is-abs-right" style={{width: 25, right: 25}} />
        </Sprite>
    );
}

function ChangeLogs({style, className, onClick}){
    return (
        <div className='rds-tabnav-below'>
            <SpriteButton debug={0} style={{width: 'auto', height: 60, ...style}} onClick={onClick} className={className} />
        </div>
    );
}

function SaveButtons({style, className, onSave, onCancel}){
    return (
        <Sprite debug={0} style={{width: 190, height: 50, ...style}} className={className}>
            <SpriteButton debug={0} onClick={onCancel} className="is-abs" style={{width: 90, top: 8, left: 6}} />
            <SpriteButton debug={0} onClick={onSave} className="is-abs" style={{width: 90, top: 8, left: 100}} />
        </Sprite>
    );
}


export function Template(props){
    const css = classNames('rds-template is-type-' + props.type, props.className);
    switch(props.type){
        case "changelogs222": return (
            <SpriteButton debug={0} style={{width: 'auto', height: 60, ...props.style}} className={css} />
        );
        case "buttons-confirm": return (
            <Sprite debug={0} style={{width: 190, height: 50, ...props.style}} className={css}>
                <SpriteButton debug={0} onClick={props.onClick1} className="is-abs" style={{width: 90, top: 8, left: 6}} />
                <SpriteButton debug={0} onClick={props.onClick2} className="is-abs" style={{width: 90, top: 8, left: 100}} />
            </Sprite>
        );
        case "buttons-save-remove": return (
            <Sprite debug={0} style={{width: 430, height: 50, ...props.style}} className={css}>
                <SpriteButton debug={0} onClick={props.onClick0} className="is-abs" style={{width: 100, top: 8, left: 6}} />
                <SpriteButton debug={0} onClick={props.onClick1} className="is-abs" style={{width: 80, top: 8, left: 266}} />
                <SpriteButton debug={0} onClick={props.onClick2} className="is-abs" style={{width: 60, top: 8, left: 360}} />
            </Sprite>
        );
        // new
        case "table-controls": return <TableControls {...props} className={css} />
        case "table-controls2": return <TableControls2 {...props} className={css} />
        case "changelogs": return <ChangeLogs {...props} className={css} />
        case "save-buttons": return <SaveButtons {...props} className={css} />
        default: console.warn("Template() Invalid type: ", props.type);
    }
}


