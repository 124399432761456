import classNames from 'classnames';
import utils from '../app/utils';
import {Sprite, SpriteButton, SpriteSelect} from '../foundation';
import './Group.scss';


function CancelSave(props){
    const {onCancel, onSave, disabled} = props;

    //  disabled = true;
    const css2 = classNames("rds-btn is-primary", disabled && "is-disabled");

    return (
        <Container {...props}>
            <SpriteButton debug={0} className="rds-btn" style={{width: 80, top: 10, height: 30, left: 10}} onClick={onCancel}>Cancel</SpriteButton>
            <SpriteButton debug={0} className={css2} style={{width: 90, top: 10, height: 30, left: 100}} onClick={onSave}>Save</SpriteButton>
        </Container>
    );
}   

function BackSave(props){
    const {onBack, onSave, disabled} = props;
    const css2 = classNames("rds-btn is-primary", disabled && "is-disabled");

    return (
        <Container {...props}>
            <SpriteButton debug={0} className="rds-btn" style={{width: 110, top: 10, height: 30, left: -20}} onClick={onBack}>Back to chart</SpriteButton>
            <SpriteButton debug={0} className={css2} style={{width: 110, top: 10, height: 30, left: 100}} onClick={onSave}>Save</SpriteButton>
        </Container>
    );
}

function BackAddOffer(props){
    const {onBack, onSave, disabled} = props;
    const css2 = classNames("rds-btn is-primary", disabled && "is-disabled");
    const ao = {vertical: 0, horizontal: 110, };
    const to = {vertical: 'bottom', horizontal: 'right', };
    const options = [
        {value: "library", label:"Save to library only"},
        {value: "add", label:"Add offer only"},
        {value: "save", label:"Save and add offer"},
    ];

    const onChange = (p1, p2, e) => {
        const value = e.target?.value;
        if(value === "library") onBack(e);
        else onSave(e);
    }

    return (
        <Container {...props}>
            <SpriteSelect debug={0} anchorOrigin={ao} transformOrigin={to} style={{top: 5, left: 100, zIndex: 1, width: 110, height: 40, opacity: 0, }} options={options} onChange={onChange} />
            <SpriteButton debug={0} className="rds-btn" style={{width: 110, top: 10, height: 30, left: -20}} onClick={onBack}>Back to chart</SpriteButton>
            <SpriteButton debug={0} className={css2} style={{width: 110, top: 10, height: 30, left: 100}} onClick={onSave}>Add offer</SpriteButton>
        </Container>
    );
}

function BackApply(props){
    const {onBack, onApply, disabled} = props;
    const css2 = classNames("rds-btn is-primary", disabled && "is-disabled");

    return (
        <Container {...props}>
            <SpriteButton debug={0} className="rds-btn" style={{width: 110, top: 10, height: 30, left: -20}} onClick={onBack}>Back to offer</SpriteButton>
            <SpriteButton debug={0} className={css2} style={{width: 110, top: 10, height: 30, left: 100}} onClick={onApply}>Apply to offer</SpriteButton>
        </Container>
    );
}

function BackOptimize(props){
    const {onBack, onOptimize, disabled} = props;
    const css2 = classNames("rds-btn is-primary", disabled && "is-disabled");

    return (
        <Container {...props}>
            <SpriteButton debug={0} className="rds-btn" style={{width: 110, top: 10, height: 30, left: -20}} onClick={onBack}>Back to offer</SpriteButton>
            <SpriteButton debug={0} className={css2} style={{width: 90, top: 10, height: 30, left: 100}} onClick={onOptimize}>Optimize</SpriteButton>
        </Container>
    );
}

function ConfirmButtons(props){
    const {onCancel, onConfirm} = props;

    return (
        <Container {...props}>
            <SpriteButton debug={0} style={{width: 80, top: 10, height: 30, left: 10}} onClick={onCancel} />
            <SpriteButton debug={0} style={{width: 90, top: 10, height: 30, left: 100}} onClick={onConfirm} />
        </Container>
    );
}


function CloseContinue(props){
    const {onClose, onContinue, disabled} = props;

    //  disabled = true;
    const css2 = classNames("rds-btn is-primary", disabled && "is-disabled");

    return (
        <Container {...props}>
            <SpriteButton debug={0} className="rds-btn" style={{width: 80, top: 10, height: 30, left: 10}} onClick={onClose}>Close</SpriteButton>
            <SpriteButton debug={0} className={css2} style={{width: 90, top: 10, height: 30, left: 100}} onClick={onContinue}>Continue</SpriteButton>
        </Container>
    );
}



// --- group / container --- //

function Container({type, className, style, children, large}){
    const css = classNames("rds-group is-type-" + type, large && "is-large", className);
    return <Sprite debug={0} className={css} style={style}>{children}</Sprite>;
}

export function Group(props){
    switch(props.type){
        default: return console.warn('Group() - Invalid type: ', props.type);
        case "confirm-buttons": return <ConfirmButtons {...props} />
        case "close-continue": return <CloseContinue {...props} />
        case "back-optimize": return <BackOptimize {...props} />
        case "back-apply": return <BackApply {...props} />
        case "back-save": return <BackSave {...props} />
        case "back-addoffer": return <BackAddOffer {...props} />
        case "cancel-save": return <CancelSave {...props} />
    }
}

