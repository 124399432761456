import * as React from 'react';
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import utils from '../../app/utils';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {Layout} from "../../foundation/Layout";
import {Sprite, SpriteButton, SpriteField, SpriteText, SpriteInput} from "../../foundation/Sprite";
import "./Login.scss";
import bcrypt from "bcryptjs";



export default function Login() {
    const dispatch = useGlobalsDispatch();
    const [error, setError] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passWidth, setPassWidth] = React.useState(260);

    //logged in?
    React.useEffect(() => {
        const loggedIn = window.sessionStorage.getItem("loggedIn");
        if(loggedIn === 'true' || loggedIn === true) dispatch({type: 'login'});
    }, []);


    const onKeyDown = (e) => {
        setPassWidth(e.target.value.length > 1 ? 210 : 260);
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter') onClickSubmit();    
    }
    const onEmailChange = (e) => {
        setEmail(e?.target?.value);
    }
    const onPasswordChange = (e) => {
        const value = e?.target?.value + '';
        setPassword(value);
        setPassWidth(value.length > 1 ? 210 : 260);
    }

    const onClickSubmit = () => {

        if(!(email+'').length && !(password+'').length) setError("Please enter your email and password.");
        else {
            //var salt = bcrypt.genSaltSync(10);
            // console.log("pass:" + bcrypt.hashSync("xxxxxxxxxxxxx", salt));
            // NEW
            var goodemail = bcrypt.compareSync(email.toLowerCase(), '$2a$10$YdwUOK7EAkYntaGRSShwXeenD1QMCHQBiXis2omzIXaco1WV/lgDu');
            var goodpass = bcrypt.compareSync(password, '$2a$10$u3MbrHMy/1xxy5exBarKFOF77wvZdEFSmLoiBuovJCBYQA9F5rG.O');
            
            // OLD
            //var goodemail = bcrypt.compareSync(email, '$2a$10$P4lwaWIhDaQVhrfNmudtaenIFNSCrm2a8JbdKJS8BnlJMl2sJTE4O');
            //var goodpass = bcrypt.compareSync(password, '$2a$10$wLiUV5VcRQF59/Mx1xCKde8vix7m/BVoOSp5ko495XPL1ilicfMo2');
            if(goodemail && goodpass) utils.loader(dispatch, {type: 'loginSubmit'}, 2000);
            else setError("The email or password you entered is invalid.");
        }
        
    }



    return (
        <Layout>
            
            <Sprite id="rds-login-bg" style={{width: '75%', height: '100%', position: 'fixed', top: 0, left: 0}} />

            <Sprite absall id="rds-login-form"  style={{width: 512, height: '100%', position: 'fixed', top: 0, left: 'auto', right: 0, backgroundColor: 'white', zIndex: 1, }} >

                <SpriteText debug={0} style={{top: 324, left: 44, width: 380, color: 'red', backgroundColor: 'transparent'}} label={error} />

                <SpriteInput debug={0} style={{top: 360, left: 60, width: 390}} value={email} onKeyPress={onKeyPress} onChange={onEmailChange} placeholder="Email" />
                <SpriteInput debug={0} style={{top: 408, left: 60, width: 390}} type="password" onKeyPress={onKeyPress} value={password} onChange={onPasswordChange} placeholder="Password" />

                <SpriteButton debug={0} style={{top: 445, left: 50, width: 120, height: 20}} />
                <SpriteButton debug={0} style={{top: 445, left: 350, width: 120, height: 20}} />

                <SpriteButton debug={0} style={{top: 496, left: 50, width: 70}} onClick={onClickSubmit} />


            </Sprite>


        </Layout>
    );
  }
