import React from "react";
import {Layout} from "../../foundation/Layout";
import utils from "../../app/utils";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar, Titlebar3, Paginationbar} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Select, DataValue, SpriteButton} from '../../foundation';
import { $ }  from 'react-jquery-plugin'
// import {Link2} from '../../foundation/Text';
import {Icon} from '../../foundation/Icon';
import {Button2} from '../../foundation/Button';


// import * as React from 'react';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function StatusMenu({anchor, value, open, onClose}) {
    console.log('value: ', value);
    return (
        <Menu anchorEl={anchor} open={open} onClose={onClose}>
            <MenuItem onClick={onClose}>Profile</MenuItem>
            <MenuItem onClick={onClose}>My account</MenuItem>
            <MenuItem onClick={onClose}>Logout</MenuItem>
        </Menu>
    );
}



const dots = {'Active': '#0F62FE',  'Approved': '#32C4A6', 'Pending': '#FF905F'};
    function Dot({status}){
    const color = dots[status] || 'gray';
    return <div style={{width: 8, height: 8, borderRadius: 8, backgroundColor: color, display: 'inline-block', marginRight: 8}}></div>;
    }



export default function PromosList(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var rows = globals.promos_rows;
    // console.log("((((((((((((((( PromosList(): ", rows);



    // load list
    utils.loadList(globals, "promos", rows);


    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const handleClick = (event) => {setAnchor(event.currentTarget)};
    const handleClose = () => {setAnchor(null)};

    // DEBUG
    React.useEffect(() => {
        // console.log('++++++++++++++++ PROMOS: ', globals.promos_rows);
        // utils.trigger(utils.getDB(), "promos_onLoadList", []);
        // utils.loadList(globals, 'promos', rows);
    }, [globals.promos_rows]);


    // const rows  = utils.remakeRows(rawrows, headers); // ['Name', 'Status', "Start_Date", "End_Date"]


    // handlers
    const onClickName = (item) => {
        // dispatch({type: "showDetails", table: "promos", id: item.ID});
    // const onClickFilter = () => dispatch({type: "showPanel", value: "c"});

        dispatch({type: "showPanel", value: "promo-details", table: "promos", id: item.ID}); // --- TEMP: reset to... id: item.ID
    }

    const onClickSort = (header) => {
        dispatch({type: "sortTable", table: "promos", field: header.name});
        // dispatch({type: "sortTable", value: ['promos', header.name]});
    }


    const onClick = () => {
        dispatch({type: "testing111"});
    }

    const onStatus = (item, value) => {
        dispatch({type: "updateRow", table: "promos", id: item.ID, values: {Status: value}});
     }

     // START show action...
    React.useEffect(() => utils.checkInit($, rows, setChecks), []);
    const [checks, setChecks] = React.useState({});
    const [checksCount, setChecksCount] = React.useState(false);
    React.useEffect(() => setChecksCount(utils.checkCounter(checks)), [checks]);
    const onChecked = (e, item) => setChecks(utils.checkSetter(checks, e, item));
    // ... END show action


     function onClickView(item){
        dispatch({type: "hidePanel"});
        dispatch({type: "showDetails", table: "promos", id: item.ID});

        // dispatch({type: "showPanel", value: "promo-details", table: "promos", id: item.ID}); // --- TEMP: reset to... id: item.ID
    }

    const onClickEditTable = () => dispatch({type: "showPanel", value: "edit-table"});



    function onClickList(){dispatch({type: "setScreen", value:"promo-list"});}
    function onClickCards(){dispatch({type: "setScreen", value:"promo-cards"});}
    function onClickTimeline(){dispatch({type: "setScreen", value:"promo-timeline"});}
    function onClickGraphs(){dispatch({type: "setScreen", value:"promo-graphs"});}

    // <DataCell name="Status" onClick={handleClick}><Dot status={item.status} />{item.Status}<Icon type="chevron-down" /></DataCell>

    // anchor, value, open, onClose
    // {utils.printCellByIDs(globals.products_rows, item.Products, 'UPC')}

    const headers = [
        {name: 'Name'},
        {name: 'Status'},
        {name: 'Start_Date', label: 'Start date', right: true},
        {name: 'End_Date', label: 'End date', right: true},
        // 
        // {name: '_Funding'},
        // {name: 'Products', label: 'UPC Numbers'},
        // {name: '_Upc_Numbers', label: 'UPC Numbers', right: true},
        // {name: 'Supplier'},
        // {name: 'Vehicle'},
        // 
        {name: '_Baseline_Units', right: true},
        {name: '_Forecast_Units', right: true},
        {name: '_Baseline_Rev', right: true},
        {name: '_Forecast_Rev', right: true},
        {name: '_Baseline_Profit', right: true},
        {name: '_Forecast_Profit', right: true},

    ];

    // {utils.printDate(item.Start_Date)}
    // {item.End_Date}
    return (
        <Layout class="rds">
            <Searchbar type="create-import" />


            <Titlebar3 type="promo-list" share={1} actionCount={checksCount}>
                <SpriteButton debug={0} className="is-abs-right" style={{width: 100, right: 120}} onClick={onClickEditTable} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 86}} onClick={onClickTimeline} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 30}} onClick={onClickCards} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20}} onClick={onClickGraphs} />
            </Titlebar3>

            {false && <Titlebar title="Promotion management" />}
            {false && <button onClick={() => dispatch({type: "showPanel", value: "addoffer"})}>Add offer</button>}

            
            <StatusMenu open={open} onClose={handleClose} anchor={anchor} />

            <DataList className="is-nooverflow" headers={headers} onChecked={onChecked} onClickSort={onClickSort}>
              {rows.map((item, i) => i<10 && ( // --- LIMIT to 10 
                    <DataRow onChecked={onChecked} index={i} key={i} onClickView={onClickView} item={item}>
                        <DataCell name="Name" onClick={() => onClickName(item)}>{item.Name}</DataCell>
                        <DataCell name="Status"><Select type="status" onChange={(value) => onStatus(item, value)} value={item.Status} /></DataCell>
                        <DataCell name="Start_Date" right>{utils.printDate(item.Start_Date)}</DataCell>
                        <DataCell name="End_Date" right>{utils.printDate(item.End_Date)}</DataCell>
                        <DataCell name="_Baseline_Units" right><DataValue type="nicenumber" value={item._Baseline_Units} /></DataCell>
                        <DataCell name="_Forecast_Units" right><DataValue type="nicenumber" value={item._Forecast_Units} /></DataCell>
                        <DataCell name="_Baseline_Rev" right><DataValue type="dollar" value={item._Baseline_Rev} /></DataCell>
                        <DataCell name="_Forecast_Rev" right><DataValue type="dollar" value={item._Forecast_Rev} /></DataCell>
                        <DataCell name="_Baseline_Profit" right><DataValue type="dollar" value={item._Baseline_Profit} /></DataCell>
                        <DataCell name="_Forecast_Profit" right><DataValue type="dollar" value={item._Forecast_Profit} /></DataCell>
                    </DataRow>

              ))}
            </DataList>


            <Paginationbar />
            
        </Layout>
    );
}

/*

                        <DataCell name="_Funding"><DataValue value={item._Funding} type="array" /></DataCell>
                        <DataCell name="_Upc_Numbers" right><DataValue value={item._Upc_Numbers} type="array" /></DataCell>
                        <DataCell name="Supplier">{item.Supplier}</DataCell>
                        <DataCell name="Vehicle">{item.Vehicle}</DataCell>

*/


