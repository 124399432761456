
import React from "react";




export default function ProductList (){
    return (
        <div>
            <h1>Product List</h1>
        </div>
    );

}