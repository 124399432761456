import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteField} from "../../foundation";
import {TreeSelect} from "../../components/TreeSelect";



export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 1;

    // tree START
    // const [expanded, setExpanded] = React.useState([]);
    const onChecked = (checkeds) => {
        dispatch({type: "updateModel", value: {Products: checkeds}});
    }
    const onExpanded = (expanded) => {
        dispatch({type: "updateModel", value: {_Products_Expanded: expanded}});
        // save which are expanded even if NOT saving
        if(record.ID) dispatch({type: "updateRow", table: "promos", id: record.ID, values: {_Products_Expanded: expanded}});
    }
    // tree END


    // const onProduct = (e) => {
    //     var value = utils.toJsonObject(e.target.value);
    //     if(!utils.isArray(value)) value = [];
    //     dispatch({type: "updateModel", value: {Products: value}});
    // }

    return (
        
        <React.Fragment>
            <Sprite id="rds-products-title" style={{top: 0, left: 0, width: 880, marginBottom: 20, }} />
            <TreeSelect type="products" checked={record.Products} expanded={record._Products_Expanded} onChecked={onChecked} onExpanded={onExpanded} style={{top: 0, left: 0}} />
        </React.Fragment>
        
    );
    // return ( // --- TEMP
    //     <Sprite className="rds-promo-product is-abs-all" style={{height:600, width: 'auto'}}>
            
    //         <TreeSelect type="products" checked={record.Locations} expanded={record._Locations_Expanded} onChecked={onChecked} onExpanded={onExpanded} style={{top: 10, left: 10}} />
    //         {false && <SpriteField onChange={onProduct} debug={debug} value={utils.toJsonString(record.Products, '')} style={{top: 200, left: 7, width: 400}} />}
    //     </Sprite>
    // );
};

