import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton, SpriteHover, SpriteCaching, SpriteText, SpriteInput, } from '../foundation';
import Dialog from '@mui/material/Dialog';
import Badge from '@mui/material/Badge';
// import CircularProgress from '@mui/material/CircularProgress';
import {Loader, Group, } from '../foundation';
import {IconButton, IconLink} from '../foundation/Icon';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';
import classNames from 'classnames';
import './MessagePanel.scss';

const debug = 0;

export function MessagePanel({closer}){

    return (
        <Sprite absall style={{width: '100%', height: '100%'}}>
            <Sprite id="rds-message-panel-top" style={{width: 400, height: 100}} />
            <Sprite id="rds-message-panel-bottom" style={{width: 400, height: 300, top: 'auto', bottom: 0 }} />

            <SpriteButton debug={0} style={{top: 10, left: 350, width: 40, height: 40}} onClick={closer} />
            <SpriteButton debug={0} style={{top: 'auto', left: 310, bottom: 20, width: 70, height: 40}} onClick={closer} />
            {false && <SpriteButton className="is-abs-right" debug={0} style={{top: 620, right: 0, width: 80}} onClick={closer} />}
        </Sprite>
    );
}

export function MessagePanelAlt({type, closer}){
    const isfunding = (type === 'funding');
    const css = classNames(isfunding && "is-type-funding");
    const toph = isfunding ? '100%' : 100;

    return (
        <Sprite absall style={{width: '100%', height: '100%'}}>
            <Sprite id="rds-message-panel-alt-top" className={css} style={{width: 352, height: toph}} />
            <Sprite id="rds-message-panel-alt-bottom" className={css} style={{width: 352, height: 350, top: 'auto', bottom: 0 }} />

            <SpriteButton debug={0} style={{top: 10, left: 300, width: 40, height: 40}} onClick={closer} />
            {false && <SpriteButton debug={0} style={{top: 'auto', left: 260, bottom: 20, width: 70, height: 40}} onClick={closer} />}
        </Sprite>
    );
}


export function SupportPanel({closer}){

    return (
        <Sprite absall style={{width: '100%', height: '100%'}}>
            <Sprite id="rds-support-panel-top" style={{width: 400, height: 100}} />
            <Sprite id="rds-support-panel-bottom" style={{width: 400, height: 380, top: 'auto', bottom: 0 }} />

            <SpriteButton debug={0} style={{top: 10, left: 350, width: 40, height: 40}} onClick={closer} />
            <SpriteButton debug={0} style={{top: 'auto', left: 310, bottom: 20, width: 70, height: 40}} onClick={closer} />
            {false && <SpriteButton className="is-abs-right" debug={0} style={{top: 620, right: 0, width: 80}} onClick={closer} />}
        </Sprite>
    );
}


function CompletedTab(props){
    const {onClick, closer, } = props;

    // return
    return (
        <Sprite absall debug={debug} className={"rds-price-convo-completed"}>

            <SpriteButton debug={debug} style={{top: 0, left: 360, width: 40, height: 40, zIndex: 3, }} onClick={closer} />
            
            <SpriteButton debug={debug} onClick={onClick} style={{top: 60, left: 0, width: 110, }} />

        </Sprite>
    );
}


function NewConvoContent(props){
    const {closer, } = props;
    const [filled, setFilled] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    // handlers
    const onClickInput = () => setFilled(true);

    const onClickSave = () => {
        setLoading(true);
        setTimeout(() => {setSaved(true); setLoading(false); }, 1000);
    }

    // return
    const css = classNames("rds-price-newconvo", saved && 'is-view-allcomments');
    return (
        <Sprite absall className={css} style={{width: '100%', height: '100%'}}>

            <SpriteButton debug={0} style={{top: 0, left: 360, width: 40, height: 40, zIndex: 3, }} onClick={closer} />


            {/* form */}
            <Sprite absall className="rds-price-newconvo-form" style={{width: '100%', height: 400, }}>

                {loading && <Loader debug={debug} type="translucent" style={{top: 65, height: 170, width: '100%', zIndex: 2, }} />}

                <SpriteButton debug={debug} style={{top: 130, left: 60, width: 320, height: 40, cursor: "text", backgroundColor: filled?undefined:"white" }} onClick={onClickInput} />
                <Group type="cancel-save" onCancel={closer} onSave={onClickSave} disabled={!filled} style={{top: 179, left: 214, backgroundColor: 'white', }} />

            </Sprite>


            {/* all comments */}
            <Sprite absall debug={debug} className="rds-price-newconvo-allcomments" style={{width: '100%', height: 630, }}>



            </Sprite>


            {/* caching */}
            <SpriteCaching items={["rds-price-newconvo-p2", ]} />
        </Sprite>
    );
}

export function BaseCommentsPanel({closer}){
    const {showMessageMode, } = useGlobals(); // --- WIP
    const mode = showMessageMode;
  
    const debug = 0;
    const [step, setStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [showingCompleted, showCompleted] = React.useState(false);


    const heights = [70, 0, 152, 0, 210, 265, 265, ];

    const onClickCancel = () => closer();

    const onClickHotspot = () => {
        // return; // TEMP
        //if(!heights[step]) return;
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setStep((step >= 7) ? 0 : (step + 1));
        }, heights[step] ? 1000 : 1);
        // if(step >= 6) setStep(0); // closer();
        // else setStep(step + 1);
    };

    const onClickCompleted = () => showCompleted(true);

    const onClickUnread = () => showCompleted(false);

    // return
    const css = classNames("rds-price-convo is-step-" + step, mode && `is-mode-${mode}`);
    return mode === "new" ? <NewConvoContent closer={closer} /> : (
        <Sprite className={css} style={{width: '100%', height: '100%'}}>

                


                {showingCompleted && <CompletedTab onClick={onClickUnread} closer={closer} />}

                <Sprite absall debug={debug} className={"rds-price-convo-top"}>

                    {/* close button  */}
                    <SpriteButton debug={debug} style={{top: 0, left: 350, width: 40, height: 40, }} onClick={closer} />

                    {step === 7 && <SpriteText border={1} label="(4)" style={{top: 57, left: 70, width: 30, minWidth: 30, color: '#027bc2', backgroundColor: 'white', fontSize: 14, }} />}

                    <SpriteButton debug={debug} className="rds-price-convo-ok222" onClick={onClickCompleted} style={{top: 60, left: 230, width: 110, }} />

                </Sprite>

                <Sprite absall debug={debug} className={"rds-price-convo-middle is-step-" + step}>

                    
                    {loading && <Loader debug={debug} type="comment" style={{height: '100%', }} />}

                    <SpriteHover debug={debug} className="rds-price-convo-hover" onClick={onClickHotspot} />

                    <Sprite absall debug={debug} className="rds-price-convo-control">

                        <SpriteButton debug={debug} className="rds-price-convo-input" style={{top: 10, left: 60, width: 320, cursor: 'text', }} />

                        {step === 1 && <SpriteButton debug={debug} className="rds-price-convo-ok" onClick={onClickHotspot} style={{top: 50, left: 130, width: 30, }} />}
                        {step === 2 && <SpriteButton debug={debug} className="rds-price-convo-ok" onClick={onClickHotspot} style={{top: 50, left: 320, width: 60, }} />}
                        {step === 3 && <SpriteButton debug={debug} className="rds-price-convo-ok" onClick={onClickHotspot} style={{top: 50, left: 170, width: 50, }} />}
                        {step === 4 && <SpriteButton debug={debug} className="rds-price-convo-ok" onClick={onClickHotspot} style={{top: 50, left: 320, width: 60, }} />}
                        {step === 5 && <SpriteButton debug={debug} className="rds-price-convo-ok" onClick={onClickHotspot} style={{top: -180, left: 0, width: '100%', height: 70, }} />}
                        {step === 6 && <SpriteButton debug={debug} className="rds-price-convo-ok" onClick={onClickHotspot} style={{top: 50, left: 240, width: 50, }} />}

                        {step < 5 && <SpriteButton debug={debug} className="rds-price-convo-cancel" onClick={onClickCancel} style={{top: 50, left: 250, width: 60, }} />}

                        {/* <SpriteButton debug={debug} className="rds-price-convo-send" onClick={onClickHotspot} style={{top: 50, left: 300, width: 60, }} /> */}

                    </Sprite>

                    <Sprite absall debug={debug} className="rds-price-convo-confirm">

                        <SpriteButton debug={debug} onClick={onClickHotspot} style={{top: 160, left: 110, width: 180, }} />

                    </Sprite>

                </Sprite>
        
                <Sprite debug={debug} className={"rds-price-convo-bottom"}>

                    <SpriteHover debug={debug} style={{height: 60, marginBottom: 67, }} />
                    <SpriteHover debug={debug} style={{height: 78, }} />
                    <SpriteHover debug={debug} style={{height: 56, }} />
                    <SpriteHover debug={debug} style={{height: 56, }} />
                    {/* <SpriteHover debug={debug} className="is-1" />
                    <SpriteHover debug={debug} className="is-2" />
                    <SpriteHover debug={debug} className="is-3" />
                    <SpriteHover debug={debug} className="is-4" /> */}

                </Sprite>


            {/* caching */}
            <SpriteCaching baseClass="rds-price-convo-middle" modifiers={['is-step-1', 'is-step-2', 'is-step-3', 'is-step-4', 'is-step-5', 'is-step-6', ]} />
            

        </Sprite>
    );
}



// --- global nav --- //

export function GlobalMessageDialog(props) {
    const { onClose, selectedValue, open } = props;
    const {app} = useGlobals();
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
        <Dialog onClose={handleClose} open={open}>
            {app === 'base' && <BaseCommentsPanel closer={handleClose} />}
            {app === 'promo' && <SupportPanel closer={handleClose} />}
            
        </Dialog>
    )
}

export function GlobalSupportDialog(props) {
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
        <Dialog onClose={handleClose} open={open}>
            <SupportPanel closer={handleClose} />
        </Dialog>
    )
}

export function PanelMessageDialog(props) {
    const { onClose, selectedValue, open, id } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
        <Dialog onClose={handleClose} open={open} id={id}>
            <MessagePanel closer={handleClose} />
        </Dialog>
    )
}

export function PanelButton () {

    const [open2, setOpen2] = React.useState(false);
    // const [selectedValue, setSelectedValue] = React.useState(emails[1]);
    // const handleClickOpen = () => {setOpen(true);};
    const handleClose = (value) => {setOpen2(false);};
    React.useEffect(() => {
        utils.toggleDialogClass(open2);
    }, [open2]);

    const onClickButton = () => {setOpen2(!open2);}; // TEMP

    return (
        <React.Fragment>
            <PanelMessageDialog open={open2} onClose={handleClose} id="rds-panelmessagedialog" />
            {!open2 && <div className="rds-messagebutton"><IconButton type="message" onClick={onClickButton} number={2} /></div>}
        </React.Fragment>
    );
}


// --- fullview -- //

export function FullviewButton (props) {
    const {type} = props;
    const css = classNames((type === "funding") && "is-type-funding");

    const [open2, setOpen2] = React.useState(false);
    const handleClose = (value) => {setOpen2(false);};
    React.useEffect(() => {
        utils.toggleDialogClass(open2);
    }, [open2]);

    const onClickButton = () => {setOpen2(!open2);}; // TEMP

    return (
        <React.Fragment>
            {open2 && <div id="rds-fullviewmessagedialog" className={css}><MessagePanelAlt type={type} closer={handleClose} /></div>}
            {!open2 && <div className="rds-messagebutton is-fullview"><IconButton type="message" onClick={onClickButton} number={2} /></div>}
        </React.Fragment>
    );
}

