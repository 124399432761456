import utils from "../app/utils";
import tasks_rows from '../theme/theme.data';
import promos_rows from './promos';
import fundings from './fundings';
import locations from './locations';
import locationsData from './locationsData';
// import productsData from './productsData-OLD2';
import periods from './periods';
import products from './products';
import vehicles from './vehicles';
import vehicleTypes from './vehicleTypes';
import vehicleTactics from './vehicleTactics';
import offers from './offers';

const makeOrigPromos = (rows) => {
    const rows2 = [...rows];
    rows2.forEach((row, i) => {
        rows2[i] = {...row};
        rows2[i].Offers = [...row.Offers];
    });
    return rows2;
}

const defaults = {
    optimizeObject: {
        // condition
        condValue: 3,
        condTarget: 1,
        condMin: 1,
        condMax: 10,
        // offer
        offerOptimized: false, // main indicator
        offerValue: 4,
        offerPrice: '$5',
        offerTarget: 1,
        offerMin: 1,
        offerMax: 10,
        // optimize
        optLoading: false,
        optStrategy: null,
        optRuleset: null,
        optMinMargin: '',
        optMaxMargin: '',
        optMinPrice: '',
        optMaxPrice: '',
        optMaxUnits: '',
        // graph / suggested
        graphLoading: true,
        sugDiscountPct: .22,
    },
    customOffer: {
        Name: undefined,
        // cond
        condAdded: false,
        condType: undefined,
        condValue: undefined,
        condMin: undefined,
        condMax: undefined,
        // offer
        offerAdded: false,
        offerType: undefined,
        offerValue: undefined,
        offerMin: undefined,
        offerMax: undefined,
        // submitted
        submitted: false,
    }
}

const profiles = {
    anna: {name: "Anna", },
    dave: {name: "Joe", },
}

const data = {
    defaults: defaults, // NEW 1/2
    initiated: false, // initiated?
    isLoading: false,
    error: null,
    article: null,
    title: 'Lumistry',
    //screen: 'tasks-list',
    //section: 'tasks',
    profile: "anna", // NEW - BASE
    profileData: profiles.anna, // new
    app: "analytics", // NEW - BASE, promo, base
    screen: "login",
    section: "login",
    showMenu: false,
    showReportsPanel: false,
    showLoader: false,
    optimizeObject: {...defaults.optimizeObject},
    swappingPromoOffer: false, // for optimize

    // ALDI 9/2023
    customOffer: {...defaults.customOffer},
    addedPromoOffer: false,
    showOptimizedOffer: false,

    // dates
    timelineDate: "1/14/2024",

    // trees
    loctree: [], // utils._makeLocationsTree(locationsData),
    prodtree: [], // utils._makeProductsTree(products),

    // panel
    showPanel: false,
    panelName: null, // string
    panelAction: null, // action object from reducer()
    // panelModel: null, // EDIT: {id: 1, table: 'promos'}  NEW: {table: 'promos'}

    // message
    showMessage: false, // BASE
    showMessageMode: null,

    showNote: false,

    // exported
    showToast: {open: false, },

    // view
    model: null, // EDIT: {id: 1, table: 'promos'}  NEW: {id: null, table: 'promos'}

    offerScreen: 'mainmenu',
    offerModel: null,


    // tables
    tasks_rows: tasks_rows, // DEPR
    tasks_sort: ['ID', true], // DEPR

    promos_rows: promos_rows,
    promos_sort: ['ID', true],

    promos2_rows: makeOrigPromos(promos_rows),

    fundings_rows: fundings,
    fundings_sort: ['ID', true],

    locations_rows: locations,
    locations_sort: ['ID', true],

    periods_rows: periods,
    periods_sort: ['ID', true],

    products_rows: products,
    products_sort: ['ID', true],

    vehicles_rows: vehicles,
    vehicles_sort: ['ID', true],

    vehicleTypes_rows: vehicleTypes,
    vehicleTypes_sort: ['ID', true],

    vehicleTactics_rows: vehicleTactics,
    vehicleTactics_sort: ['ID', true],

    offers_rows: offers,
    offers_sort: ['ID', true],
    

};




/*
// const array2 = array1.map((item, i) => "new value");
// array1.foreach((item, i) => {item[i] = "new value"}) // returns undefined
Object.keys(theme).foreach(key => {
    data[key] = theme[key];
});

Object.keys(theme).forEach(value => {
    data[value] = theme[value];
});
*/
export default data;
export {defaults, profiles, };