import {Link} from './Text';
import classNames from 'classnames';
import './_foundation.scss';
import {Sprite, SpriteButton} from './Sprite';
import { useGlobalsDispatch } from '../app/context';
import u from '../app/utils';
import './Layout.scss';



export function Split ({children, className}){
    const css = classNames('rds-split', className);
    return <div className={css}>{children}</div>
}

export function SplitLeft ({children, className}){
    const css = classNames('rds-split-left', className);
    return <div className={css}>{children}</div>
}

export function SplitRight ({children, className}){
    const css = classNames('rds-split-right', className);
    return <div className={css}>{children}</div>
}
/*
export function SplitLeft22 ({children, classes=''}){
    return (
        <div className={'rds-split-left ' + u.classes(classes)}>{children}</div>
    );
}
export function SplitRight22 ({children, classes=''}){
    return (
        <div className={'rds-split-right ' + classes}>{children}</div>
    );
}
*/


export function Center ({children, classes=''}){
    return (
        <div className={'rds-center ' + classes}>
            <div className="rds-center-content">{children}</div>
        </div>
    );
}




function Tab({selected, dispatch, screen, label}){
    const css = classNames('rds-header-tab', {'is--selected': selected});
    return <Link classes={css} dispatch={dispatch} screen={screen}>{label}</Link>;
}


// --- header --- //

function Header ({tab}){
    const dispatch = useGlobalsDispatch();
    const css = classNames('rds-header');

    return (
        <div className={css}>
            <Center>
                <Split>
                    <SplitLeft>
                        
                        <Link onClick={() => dispatch({type: "toggleMenu"})}>More</Link>
                        <Tab dispatch={dispatch} screen="messages-list" label="Messages" selected={tab === 'messages'} />
                        <Tab dispatch={dispatch} screen="tasks-list" label="Tasks" selected={tab === 'tasks'} />
                        

                    </SplitLeft>
                    <SplitRight>
                        <div className="rds-header-topright" />
                    </SplitRight>
                </Split>

                <Link screen="product-list" dispatch={dispatch}>This is a test</Link>
            </Center>
        </div>
    );
}


// --- layout --- //

export function Layout ({children, className}){
    const css = classNames('rds-layout', className);

    return (
        <div className={css}>
            <div className='rds-layout-content'>{children}</div>
        </div>
    );
}


export function TabLayout ({children, className}){
    const css = classNames('rds-tablayout is-clearfix', className);
    return (
        <div className={css}>{children}</div>
    );
}


export function TabLayoutLeft ({children, className}){
    const css = classNames('rds-tablayout-left', className);
    return <div className={css}>{children}</div>
}
export function TabLayoutRight ({children, className}){
    const css = classNames('rds-tablayout-right', className);
    return <div className={css}>{children}</div>;
}



// <div className='rds-tabnav-tab'></div>
