import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, SpriteText, Sprite, SpriteField, SpriteButton, SpriteSelect} from "../../foundation";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';


export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 0;

    const onType = (e) => {
        const value = utils.castField('vehicles', 'Funding_Type', e.target.value, record.Funding_Type);
        dispatch({type: "updateModel", value: {Funding_Type: value}});
    }

    const onFunds = (e) => {
        //const value = utils.castField('vehicles', 'Funds', e.target.value, record.Funds);
        //dispatch({type: "updateModel", value: {Funds: value}});
    }

    console.log("+++++++++++++++ VEHICLE: ", record);

    // <Sprite className="rds-vehicle-define is-internal" />;
    return (
            <Sprite debug={0} className="rds-vehicle-define  is-abs-all" style={{height:600, width: 884}}>
                <SpriteText debug={0} label={record.Type} style={{top: 71, left: 7, width: 380, cursor: 'pointer'}} />
                <SpriteField debug={0} value={record.Name} style={{top: 185, left: 7, width: 400}} />
                <SpriteField debug={0} value={record.External_ID} style={{top: 273, left: 7, width: 190}} />
                <SpriteField debug={0} value={record.Internal_ID} disabled={1} style={{top: 273, left: 222, width: 190}} />
            </Sprite>
    );
}

