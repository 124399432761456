import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import Chip from '@mui/material/Chip';
import {Sprite, SpriteButton, SpriteText, SpriteField, Select, DataCell, DataRow, DataList, DataHeader, DataValue} from "../../foundation";
import {GraphView} from "../../components/GraphView";
import PromoOfferV2 from "./PromoOffer-v2";

function SelectionValue({item}) {
    if(item.Baseline) return <div className="rds-chartchip">Base</div>;
    if(item.Selected) return <div className="rds-chartchip is-selected">Selected</div>; // if(item.Selected) 
}

function GraphTable({record, style}){
    const dispatch = useGlobalsDispatch();
    // const globals = useGlobals();
    // const debug = 1;
    const rows = record.Offer_Charts;

    const onClickName = (item) => { // item => offer chart
        const offerID = item.Offer_Obj.ID;
        dispatch({type: "showOfferPanel", offerID: offerID, promoID: record.ID}); // object: item.Offer_Obj
    }

    const headers = [
        {name: 'Selection'},
        {name: 'Name'},
        {name: 'Price'},
        {name: 'Revenue'},
        {name: 'Profit'},
        {name: 'Margin'},
        {name: 'Units'},
        {name: 'Units_Lift'},
    ];

    return (
        <DataList headers={headers} style={style} title="Chart data">
            {rows.map((item, i) => (
                <DataRow index={i} key={i}>
                    <DataCell name="Selection"><SelectionValue item={item} /></DataCell>
                    <DataCell name="Name" onClick={() => onClickName(item)}>{item.Name}</DataCell>
                    <DataCell name="Price"><DataValue type="dollar" value={item.Price} /></DataCell>
                    <DataCell name="Revenue"><DataValue type="dollar" value={item.Revenue} /></DataCell>
                    <DataCell name="Profit"><DataValue type="dollar" value={item.Profit} /></DataCell>
                    <DataCell name="Margin"><DataValue type="percent" value={item.Margin} /></DataCell>
                    <DataCell name="Units"><DataValue type="nicenumber" value={item.Units} /></DataCell>
                    <DataCell name="Units_Lift">{item.Baseline ? "n/a" : <DataValue type="nicenumber" value={item.Units_Lift} />}</DataCell>
                </DataRow>
            ))}
      </DataList>
    );
}



export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const {swappingOffers} = globals;

    const [swappingID, setSwappingID] = React.useState(false);

    React.useEffect(() => {
        utils.loadDetails(globals, 'promos', record);
    }, [record]);

    React.useEffect(() => {
        // console.log("=====================: swappingOffers: ", swappingOffers);
        if(Array.isArray(swappingOffers)){
            const [id, newID] = swappingOffers;
            // console.log("__________________ SWAP OFFERS: ", swappingOffers);
            setSwappingID(() => id);
            // --- set timeout
            setTimeout(() => {
                dispatch({type: 'swapPromoOffer', id: id, newID: newID});
            }, 2000);
        } else {
            setSwappingID(() => false);
        }
    }, [swappingOffers]);



    const onOffers = (e) => {
        var value = e.target.value;
        console.log("-------------- OFFERS: ", value, " ARRAY: ", utils.toJsonObject(value));

        // dispatch({type: "updateModel", value: {Offer: value}});
    }

    const onClickAdd = () => {
        // dispatch({type: "showPanel", value: "addoffer"});
        dispatch({type: "showOfferPanel", promoID: record.ID});
    }

    const onSwapClick = () => { // --- TEMP
        dispatch({type: "swappingPromoOffer", id: 5, newID: 101});
    }

    //<SpriteText label="xxx" className="is-title-md" style={{top: 10, left: 70, width: 200}} />
    //<SpriteText label="xxx" className="is-title-md" style={{top: 50, left: 70, width: 200}} />
    // {false && <SpriteField onChange={onOffer} debug={debug} value={record.Offer} style={{top: 265, left: 7, width: 400}} />}

    const debug = 1;
    return ( // --- TEMP
        <Sprite className="rds-promo-offer is-abs-all" style={{height:740, width: 916, left: -17}}>

            <PromoOfferV2 record={record} />

            {false && <button onClick={onSwapClick}>SWAP</button>}


            <Sprite debug={0} debugall={0} absall={1} style={{top: 0, width: 420, height: 10}}>

                <SpriteButton style={{top: -6, left: 570, width: 80}} />
                <SpriteButton style={{top: -6, left: 670, width: 80}} />
                <SpriteButton style={{top: -6, left: 810, width: 80}} onClick={onClickAdd} />



            </Sprite>

            <Sprite debug={0} absall={1} style={{top: 100, width: 420, height: 10}}>

                <GraphView promo={record} swappingID={swappingID} />

            </Sprite>

            <Sprite debug={0} absall={1} style={{top: 570, width: 420, height: 10}}>

                {false && <SpriteField onChange={onOffers} debug={debug} value={utils.toJsonString(record.Offers, '')} style={{top: 0, left: 0, width: 400, zIndex: 2}} />}
                
                <GraphTable record={record} style={{top: 0, width: 914, outline: 'white 30px solid'}} />

            </Sprite>


            
        </Sprite>
    );
};

