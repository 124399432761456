import React from "react";
import {Button} from '../foundation/Button';
import {useGlobals, useGlobalsDispatch} from '../app/context';



export default function MyPanel(){
    const globals = useGlobals();
    const dispatch = useGlobalsDispatch();


    return (
        <div style={{width: 400, border: 'red 1px solid'}}>
            <h1>My panel</h1>

            <Button onClick={() => {dispatch({type: 'hidePanel'})}}>Close</Button>

        </div>
    );
}
