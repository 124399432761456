import React from "react";
import {Layout} from "../../foundation/Layout";
import {Sprite} from '../../foundation/Sprite';
import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {TreeSelect} from '../../components/TreeSelect';

// import 'react-checkbox-tree/lib/react-checkbox-tree.css';

// import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
// import {Searchbar, Titlebar, Paginationbar} from '../../components/Toolbars';
// import {DataList, DataTop, DataRow, DataCell} from '../../foundation/DataList';
// import {Icon} from '../../foundation/Icon';
// import { borderRadius } from "@mui/system";
import "./Home.scss";


const nodes = [{
  value: 'mars',
  label: 'Mars',
  children: [
      { value: 'phobos', label: 'Phobos' },
      { value: 'deimos', label: 'Deimos' },
  ],
}];

class TreeView extends React.Component {
  state = {
      checked: [],
      expanded: [],
  };

  render() {
      return (
          <CheckboxTree
              nodes={nodes}
              checked={this.state.checked}
              expanded={this.state.expanded}
              onCheck={checked => this.setState({ checked })}
              onExpand={expanded => this.setState({ expanded })}
              iconsClass="fa5"
              icons222={{
                check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon="check-square" />,
                uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={['fas', 'square']} />,
                halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon="check-square" />,
                expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon="chevron-right" />,
                expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon="chevron-down" />,
                expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon="plus-square" />,
                collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon="minus-square" />,
                parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon="folder" />,
                parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon="folder-open" />,
                leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon="file" />
            }}

          />
      );
  }
}



export default function Dashboard(){
    return (
        <Layout>
            <h1>HOME</h1>
            <TreeSelect />
            <TreeView />
        </Layout>
    );
}