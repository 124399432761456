import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteField, SpriteButton, SpriteSelect} from "../../foundation";
import {TreeSelect} from "../../components/TreeSelect";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';


export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    // const debug = 0;

    // const onType = (e) => {
    //     const value = utils.castField('vehicles', 'Funding_Type', e.target.value, record.Funding_Type);
    //     dispatch({type: "updateModel", value: {Funding_Type: value}});
    // }


    const onChecked = (checkeds) => {
        dispatch({type: "updateModel", value: {Locations: checkeds}});
    }
    const onExpanded = (expanded) => {
        dispatch({type: "updateModel", value: {_Locations_Expanded: expanded}});
        // save which are expanded even if NOT saving
        if(record.ID) dispatch({type: "updateRow", table: "vehicles", id: record.ID, values: {_Locations_Expanded: expanded}});
    }


    return (
        <React.Fragment>
            <Sprite id="rds-locations-title" style={{top: 0, left: 0, width: 880, marginBottom: 20, }} />
            <TreeSelect type="locations" checked={record.Locations} expanded={record._Locations_Expanded} onChecked={onChecked} onExpanded={onExpanded} style={{top: 0, left: 0}} />
        </React.Fragment>
    )
    // return (
    //         <Sprite debug={debug} className="rds-vehicle-locations  is-abs-all" style={{height:600, width: 'auto'}}>
    //             <SpriteSelect type="fundings.Funding_Type" onChange={onType} debug={debug} value={record.Funding_Type} style={{top: 48, left: 7, width: 400}} />
    //         </Sprite>
    // );
}

