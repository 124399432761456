import React from "react";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight} from "../../foundation/Layout";
import {Sprite, SpriteButton, SpriteText} from '../../foundation/Sprite';
import classNames from 'classnames';
import "./Dashboard.scss";


function SettingsNav ({screen}) {
    const dispatch = useGlobalsDispatch();
    // const css = classNames('rds-tabnav', className);
    console.log("------ FLAG 111:", screen);
    const onClick = (screen) => {dispatch({type: "setScreen", value: screen})};
    return (
        <div className='rds-tabnav is-type-settings'>
            <SpriteButton debug={0} className={screen === "settings" && "is-selected"} onClick={() => onClick("settings")} />
            <SpriteButton debug={0} className={screen === "settings-datetime" && "is-selected"} onClick={() => onClick("settings-datetime")} />
            <SpriteButton debug={0} className={screen === "settings-access" && "is-selected"} onClick={() => onClick("settings-access")} />
            <SpriteButton debug={0} className={screen === "settings-privacy" && "is-selected"} onClick={() => onClick("settings-privacy")} />
            <SpriteButton debug={0} className={screen === "settings-email" && "is-selected"} onClick={() => onClick("settings-email")} />
        </div>
    );
}

function SettingsPersonal(){
    const {profile} = useGlobals();
    return (
        <Sprite className="rds-settings-personal" absall style={{height:600, width: 'auto'}}>
            {profile === "dave" && <div className="rds-globalnav-avatar is-type-dave" style={{width: 74, height: 74, top: 46, left: -10, }} />}
            {profile === "dave" && <SpriteText debug={0} label={"Joe Smith"} style={{top: 174, left: 12}} />}
        </Sprite>
    );
}


function Content({screen}){
    switch(screen){
        default:
        case 'settings-personal':   return <SettingsPersonal />;
        case 'settings-datetime':   return <Sprite className="rds-settings-datetime" style={{height:600, width: 'auto'}} />;
        case 'settings-access':     return <Sprite className="rds-settings-access" style={{height:600, width: 'auto'}} />;
        case 'settings-privacy':    return <Sprite className="rds-settings-privacy" style={{height:600, width: 'auto'}} />;
        case 'settings-email':      return <Sprite className="rds-settings-email" style={{height:600, width: 'auto'}} />;
    }
}


export default function Settings({screen}){
    if(!screen) screen = "settings";
    return (
        <TabLayout>
            <TabLayoutLeft>
                <SettingsNav screen={screen} />
            </TabLayoutLeft>
            <TabLayoutRight>
                <Content screen={screen} />
            </TabLayoutRight>
        </TabLayout>
    );
}