import utils from "./utils";
import gen from "../data/_generator";
import configs from "./configs";

function showCalc(Calc, note=""){

    const TEMP = {
        "0 Elasticity": Calc.Elasticity,
        "1 Base_Unit_Price": Calc.Base_Unit_Price,
        "2 Base_Units": Calc.Base_Units,
        "3 Locations_Total_Mod": Calc.Locations_Total_Mod,
        "4 Vehicle_Tactics_Mod": Calc.Vehicle_Tactics_Mod,
        "4 Baseline_Units_Per_Day": Calc.Baseline_Units_Per_Day,
        "5 -------------": 0,
        "6 Adjusted_Units": Calc.Adjusted_Units,
        "7 Base_Forecast_Units": Calc.Base_Forecast_Units,
        "8 Base_Forecast_Revenue": Calc.Base_Forecast_Revenue,
        "8 Base_Forecast_Revenue2": Calc.Base_Forecast_Revenue2,
        "9 Base_Unit_Cost": Calc.Base_Unit_Cost,
        "10 Base_Unit_Profit": Calc.Base_Unit_Profit,
        "11 Base_Forecast_Profit": Calc.Base_Forecast_Profit,
        "12 Base_Forecast_Margin": Calc.Base_Forecast_Margin,
        "13 Base_Forecast_Margin2": Calc.Base_Forecast_Margin2,
    }

    // if(!db.loop1234++) console.log("================== PROD CALC: ", TEMP);
    console.log("================ PRODUCT CALC: " + note, TEMP);
}

const db = {

    tableScreens: {
        "promos-edit": "promo-define",
    },
        
    // ========================= PROMOS ========================= //

    promos_types: {
        ID: "id",
        Name: "string",
        External_ID: "scalar",
        Description: "string",
        Status: "string",
    },

    promos_onSelectRow: () => {},
    promos_onSelectRow: () => {},

    promos_onLoadInits: (row) => { // NO STATE

    },
    
    /*

// need fields...
Name,
Status
Start
End
Baseline units,
Forecast units,
Baseline Rev,
Forecast Rev,
Baseline Profit,
Forecast Profit
    */
    loopi2: 0,

    periodDays: (start_int, end_int) => {
        if(!start_int || !end_int) return 0;
        const timebetween = end_int - start_int;
        return (timebetween / (1000 * 3600 * 24)) + 1;
    },


    // helpers...
    roundToMoney: (n) => {
        return '$' + utils.toNumber(n, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    roundTo2: (n) => {
        return Math.round((n + Number.EPSILON) * 100) / 100;
    },
    percentTo2: (n) => {
        return (n * 100).toFixed(2) + "%"; // db.roundTo2(n * 100) + "%"; //  db.roundTo2(n) + "%";
        // return (n*100)/100;
        // return n.toFixed(2) + "%";
    },

    showPromo: (row, prod, Obj, offerid) => {
        if(!configs.debug || !configs.debug_promos) return;

        const TEMP = {
            "1 Base_Unit_Price": Obj.Base_Unit_Price,
            "2 Base_Units": Obj.Base_Units,
            "3 Locations_Total_Mod": Obj.Locations_Total_Mod,
            "4 -------------": 0,
            "5 Baseline_Units_Per_Day": Obj.Baseline_Units_Per_Day,
            "6 Days_Mod": Obj.Days_Mod,
            "7 Base_Forecast_Units": Obj.Base_Forecast_Units,
            "8 Base_Forecast_Revenue": Obj.Base_Forecast_Revenue,
            "9 Base_Forecast_Revenue2": Obj.Base_Forecast_Revenue2,
            "10 Base_Unit_Cost": Obj.Base_Unit_Cost,
            "11 Base_Unit_Profit": Obj.Base_Unit_Profit,
            "12 Base_Forecast_Profit": Obj.Base_Forecast_Profit,
            "13 Base_Forecast_Margin": Obj.Base_Forecast_Margin,
            "14 Base_Forecast_Margin2": db.percentTo2(Obj.Base_Forecast_Margin),
            "15 -------------": 0,
            "16 Vehicle_Tactics_Mod": Obj.Vehicle_Tactics_Mod,
            "17 Adjusted_Units": Obj.Adjusted_Units,
            "18 -------------": 0,
            "19 Discount_Percent": Obj.Discount_Percent,
            "20 Discount_Dollar": Obj.Discount_Dollar,
            "21 Offer_Price": Obj.Offer_Price,
            "22 Elasticity": Obj.Elasticity,
            "23 -------------": 0,
            "24 Offer_Unit_Lift": Obj.Offer_Unit_Lift,
            "25 Offer_Unit_Lift2": db.percentTo2(Obj.Offer_Unit_Lift),
            "26 Offer_Forecast_Units": Obj.Offer_Forecast_Units,
            "27 Offer_Forecast_Revenue": Obj.Offer_Forecast_Revenue,
            "28 Offer_Forecast_Revenue2": db.roundToMoney(Obj.Offer_Forecast_Revenue, true),
            "29 Offer_Unit_Profit": Obj.Offer_Unit_Profit,
            "30 Offer_Forecast_Profit": Obj.Offer_Forecast_Profit,
            "31 Offer_Forecast_Profit2": db.roundToMoney(Obj.Offer_Forecast_Profit, true),
            "32 Offer_Forecast_Margin": Obj.Offer_Forecast_Margin,
            "33 Offer_Forecast_Margin2": db.percentTo2(Obj.Offer_Forecast_Margin),
        }
        
        const str = prod ? ("================ PROMO: #" + row.ID + " OFFER: #" + offerid + ", PROD: #" + prod.ID) : ("================ PROMO: #" + row.ID + ", COMPOSITE: ");

        console.log(str, TEMP);
    },

    getsDiscountValues: (Offer, Calc) => {
        var Discount_Percent = 0
        var Discount_Dollar = 0;
        switch(Offer.Type){
            case "Simple: Amount Off": 
                Discount_Dollar = Offer.Discount_USD; 
                Discount_Percent = Offer.Discount_USD / Calc.Base_Unit_Price; 
            break;
            case "Simple: Percent Off":
                Discount_Percent = Offer.Discount_PCT;
                Discount_Dollar = Calc.Base_Unit_Price * Discount_Percent; 
            break;
            case "Simple: Price": 
                Discount_Dollar = Calc.Base_Unit_Price - Offer.Price; 
                Discount_Percent = Discount_Dollar / Calc.Base_Unit_Price; 
            break;
            default: console.warn("_loadOfferCalcs(): Invalid Offer.Type: ", Offer.Type);
                Discount_Percent = 0; // no change
                Discount_Dollar = 0; // no change
            break;
        }
        return [Discount_Percent, Discount_Dollar];
    },

    promos_onLoadDetails: (state, row, _islist) => {
        // load defaults
        utils.loadDefaults(row, {
            Products: [],
            Locations: [], // storeid, 
            Vehicles: [],
            Offers: [],
        });

        // offers
        if(!row.Offers.find(id => id == row.Offer)){
            if(row.Offers.length) row.Offer = row.Offers[0];
            else {
                console.warn("NO offer for this promo: #" + row.ID);
                row.Offer = 5; // config default
                row.Offers = [row.Offer];
            }
        }
        row.Offer_Obj = utils.selectRow(state, "offers", row.Offer);

        // dates
        const pid = row.Period_ID;
        const Period = row.Period = pid && utils.selectRow(state, "periods", pid);
        row.Period_Name = Period ? Period.Name : null;
        if(Period){
            row.Start_Date = Period.Start_Date;
            row.End_Date = Period.End_Date;
        }
        
        // --- start calculations
        const Days_Mod = db.periodDays(row.Start_Date, row.End_Date); // 1/1/2023 to 1/14/2023 is 14 days

        // location total mod
        var Locations_Total_Mod = 0;
        row.Locations.forEach(locid => {
            var Loc = utils.selectRow(state, "locations", locid);
            if(Loc) Locations_Total_Mod += Loc.Volume_Modifier;
        });

        // vehicle tactics mod
        var Vehicle_Tactics_Mod = 0;
        row.Vehicles.forEach(vid => {
            var Vehicle = utils.selectRow(state, "vehicles", vid);
            if(!Vehicle) return; // no record
            var VehType = utils.selectRowByField(state, "vehicleTypes", "ID", Vehicle.Type_ID);
            var Mod = VehType.Tactic_Mod;

            // sum of mods
            Vehicle_Tactics_Mod += Mod;
        });
        
        // START OFFER COMPS...
        row.Offer_Comps = [];

        // iterate offers
        row.Offers.forEach(oid => {
            const Comp = {};
            const Offer_Obj = utils.selectRow(state, "offers", oid);
            Comp.Offer_Obj = Offer_Obj;

            // iterate products
            row.Products.forEach(pid => {
                var Product = utils.selectRow(state, "products", pid);
                if(!Product) return console.warn("Missing product: #" + pid);
                var pc = {}; // product calc

                // product
                pc.Elasticity = Product.Elasticity;
                pc.Base_Unit_Price = Product.Unit_Price; // 9.22; // Product.Unit_Price;
                pc.Base_Units = Product.Base_Units; // 105; // Product.Base_Units;
                pc.Days_Mod = Days_Mod;
                pc.Locations_Total_Mod = Locations_Total_Mod; 
                pc.Vehicle_Tactics_Mod = Vehicle_Tactics_Mod;

                // base
                pc.Baseline_Units_Per_Day = Product.Base_Units * Locations_Total_Mod;
                pc.Base_Forecast_Units = pc.Baseline_Units_Per_Day * Days_Mod;
                pc.Base_Forecast_Revenue = pc.Base_Unit_Price * pc.Base_Forecast_Units;
                pc.Base_Forecast_Revenue2 = db.roundTo2(pc.Base_Forecast_Revenue);
                pc.Base_Unit_Cost = Product.Unit_Cost; // 7.19; // Product.Unit_Cost; // 6.73
                pc.Base_Unit_Profit = pc.Base_Unit_Price - pc.Base_Unit_Cost;
                pc.Base_Unit_Profit2 = db.roundTo2(pc.Base_Unit_Profit);

                pc.Base_Forecast_Profit = pc.Base_Unit_Profit * pc.Base_Forecast_Units;
                pc.Base_Forecast_Profit2 = db.roundTo2(pc.Base_Forecast_Profit);
                var bfm = pc.Base_Forecast_Profit / pc.Base_Forecast_Revenue;
                pc.Base_Forecast_Margin = bfm;
                pc.Base_Forecast_Margin2 = db.percentTo2(pc.Base_Forecast_Margin);

                // offer
                var bupd = pc.Baseline_Units_Per_Day;
                pc.Adjusted_Units = (bupd + (Vehicle_Tactics_Mod * bupd)) * Days_Mod; // =(C74+(C82*C74))*C75
                var [dp, dd] = db.getsDiscountValues(Offer_Obj, pc);
                pc.Discount_Percent = dp;
                pc.Discount_Dollar = dd;
                pc.Offer_Price = pc.Base_Unit_Price - pc.Discount_Dollar;

                // offer unit lift
                var ofu = Math.exp(Math.log(pc.Adjusted_Units) + pc.Elasticity * (pc.Offer_Price/pc.Base_Unit_Price - 1));
                pc.Offer_Forecast_Units = ofu;
                pc.Offer_Unit_Lift = (ofu-pc.Base_Forecast_Units)/pc.Base_Forecast_Units; // .0935

                pc.Offer_Forecast_Revenue = pc.Offer_Forecast_Units * pc.Offer_Price; // 354346.14411403367 
                pc.Offer_Unit_Profit = pc.Offer_Price - pc.Base_Unit_Cost;
                pc.Offer_Forecast_Profit = pc.Offer_Unit_Profit * pc.Offer_Forecast_Units;
                pc.Offer_Forecast_Margin = pc.Offer_Forecast_Profit / pc.Offer_Forecast_Revenue;

                // add to comp
                Object.keys(pc).forEach(attr => {
                    var value = pc[attr];
                    if(typeof value !== 'number') return;
                    if(!Comp[attr]) Comp[attr]= 0;
                    Comp[attr] += value;
                });

                // show prod
                db.showPromo(row, Product, pc, oid); 
            });
            // end iterate products

            // margins
            Comp.Base_Forecast_Margin = Comp.Base_Forecast_Profit / Comp.Base_Forecast_Revenue;
            Comp.Offer_Forecast_Margin = Comp.Offer_Forecast_Profit / Comp.Offer_Forecast_Revenue;

            db.showPromo(row, false, Comp, oid); // debug

            // append offer calcs
            row.Offer_Comps.push(Comp);
        });
        // ...END OFFER COMPS


        // START OFFER CHARTS...

        // add baseline chart
        row.Offer_Charts = [];
        var First = row.Offer_Comps[0]; // use first
        row.Offer_Charts.push({
            Baseline: true,
            Name: "Baseline",
            Price: First.Base_Unit_Price,
            Revenue: First.Base_Forecast_Revenue,
            Profit: First.Base_Forecast_Profit,
            Margin: First.Base_Forecast_Margin,
            Units: First.Base_Forecast_Units,
            Units_Lift: 'n/a',
        });

        // add offer charts
        row.Offer_Comps.forEach(Comp => {
            const Offer_Obj = Comp.Offer_Obj;
            const Chart = {
                Offer_Obj: Offer_Obj,
                Selected: Offer_Obj.ID == row.Offer,
                Name: Offer_Obj.Name,
                Price: Comp.Offer_Price,
                Revenue: Comp.Offer_Forecast_Revenue,
                Profit: Comp.Offer_Forecast_Profit,
                Margin: Comp.Offer_Forecast_Margin,
                Units: Comp.Offer_Forecast_Units,
                Units_Lift: Comp.Offer_Unit_Lift,
            };
            row.Offer_Charts.push(Chart);
        });
        // ...END offer charts


        // output
        const SelComp = row.Offer_Comps.find(Comp => Comp.Offer_Obj.ID == row.Offer);
        row._Baseline_Units = SelComp.Base_Forecast_Units;
        row._Baseline_Profit = SelComp.Base_Forecast_Profit;
        row._Baseline_Rev = SelComp.Base_Forecast_Revenue;

        row._Forecast_Units = SelComp.Offer_Forecast_Units
        row._Forecast_Rev = SelComp.Offer_Forecast_Revenue;
        row._Forecast_Profit = SelComp.Offer_Forecast_Profit;
    },

    promos_onLoadList: (state, rows) => {

        if(false) db.promos_onLoadDetails(state, rows[0], true);
        else {
            rows.forEach(row => {
                db.promos_onLoadDetails(state, row, true);
            });
        }

        

        utils.resortTable(state, "promos");
    },




    // ========================= OFFERS ========================= //
    
    offers_onLoadList: (state, rows) => {
        rows.forEach(row => {

            const ispct = !!row.Discount_PCT;

            row._Discount = ispct ? row.Discount_PCT : row.Discount_USD; // `${row.Discount_PCT}%` : `$${row.Discount_USD}`;
            row.isPct = !!row.Discount_PCT;
            if(row._Regular_Price) row._Offer_Price = ispct ? (row._Regular_Price - (row._Regular_Price * row.Discount_PCT)) : (row._Regular_Price - row.Discount_USD)
        })
    },



    // --- fundings ---//

    validate_fundings: (name, value, _else) => {
        switch(name){
            default:
            case "Funding_Type": return utils.toFilledString(value, _else);
        }
    },


    // --- vehicles --- //

    vehicles_onLoadDetails: (state, row, _islist) => {
        
    },

    vehicles_onLoadList: (state, rows) => {

        rows.forEach(row => {
            db.vehicles_onLoadDetails(state, row, true);
        });


        utils.resortTable(state, "vehicles");
     },


     // --- offers --- //

     offers_onLoadDetails: (state, row, _islist) => {
     },

    // END OBJ
};

export default db;
