const rows = [
    {
      "ID": 713,
      "UpcCode": 8982613028,
      "vendorName": "Standard Beverage Corporation",
      "ProductDescription": "Sol Chelada 24Z Cn",
      "BrandName": "SOL",
      "CaseSize": 0,
      "Unit_Price": 3.49,
      "Unit_Cost": 2.2417,
      "Margin": 0.36,
      "Elasticity": -0.9987196409,
      "Base_Units": 103,
      "Level1": "Cooler",
      "Level2": "Beer/Wine",
      "Level3": "Imports",
      "FIELD15": "713, "
    },
    {
      "ID": 977,
      "UpcCode": 67288220006,
      "vendorName": "AB Beechwood Sales Alcohol",
      "ProductDescription": "Central Waters Mud Puppy Porter 46 Nr",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 7.625,
      "Margin": 0.24,
      "Elasticity": -0.983011518,
      "Base_Units": 56,
      "Level1": "Cooler",
      "Level2": "Beer/Wine",
      "Level3": "Microbrews",
      "FIELD15": "977, "
    },
    {
      "ID": 714,
      "UpcCode": 8982613022,
      "vendorName": "M/C Dietrich Distributing Company Alch",
      "ProductDescription": "Sol 24Oz Can",
      "BrandName": "SOL",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 2.0667,
      "Margin": 0.31,
      "Elasticity": -0.723540379,
      "Base_Units": 228,
      "Level1": "Cooler",
      "Level2": "Beer/Wine",
      "Level3": "Premium",
      "FIELD15": "714, "
    },
    {
      "ID": 9,
      "UpcCode": 74982612627,
      "vendorName": "McLane",
      "ProductDescription": "Pp Frosty Choc Shake",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 1.5025,
      "Margin": 0.62,
      "Elasticity": -0.8139231667,
      "Base_Units": 147,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "9, "
    },
    {
      "ID": 10,
      "UpcCode": 74982612632,
      "vendorName": "McLane",
      "ProductDescription": "Pp Van Cream Shake",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 1.5025,
      "Margin": 0.62,
      "Elasticity": -0.932710076,
      "Base_Units": 243,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "10, "
    },
    {
      "ID": 26,
      "UpcCode": 84259510954,
      "vendorName": "McLane",
      "ProductDescription": "C4 Starburst Cherry 16Z Cn",
      "BrandName": "C4",
      "CaseSize": 0,
      "Unit_Price": 3.19,
      "Unit_Cost": 1.5833,
      "Margin": 0.5,
      "Elasticity": -0.9854082395,
      "Base_Units": 79,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "26, "
    },
    {
      "ID": 27,
      "UpcCode": 84259511624,
      "vendorName": "McLane",
      "ProductDescription": "C4 Smart Peach Mango Nectr 16Z Cn",
      "BrandName": "C4",
      "CaseSize": 0,
      "Unit_Price": 3.09,
      "Unit_Cost": 1.5833,
      "Margin": 0.49,
      "Elasticity": -1.141165173,
      "Base_Units": 86,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "27, "
    },
    {
      "ID": 28,
      "UpcCode": 84259511627,
      "vendorName": "McLane",
      "ProductDescription": "C4 Smart Freedom Ice 16Z Cn",
      "BrandName": "C4",
      "CaseSize": 0,
      "Unit_Price": 3.09,
      "Unit_Cost": 1.5833,
      "Margin": 0.49,
      "Elasticity": -1.297636082,
      "Base_Units": 229,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "28, "
    },
    {
      "ID": 29,
      "UpcCode": 84259512175,
      "vendorName": "McLane",
      "ProductDescription": "C4 Starburst Strawberry 16Z Cn",
      "BrandName": "C4",
      "CaseSize": 0,
      "Unit_Price": 3.29,
      "Unit_Cost": 1.5892,
      "Margin": 0.52,
      "Elasticity": -1.508234089,
      "Base_Units": 81,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "29, "
    },
    {
      "ID": 30,
      "UpcCode": 84259512176,
      "vendorName": "McLane",
      "ProductDescription": "C4 Skittles 16Z Cn",
      "BrandName": "C4",
      "CaseSize": 0,
      "Unit_Price": 3.29,
      "Unit_Cost": 1.6017,
      "Margin": 0.51,
      "Elasticity": -0.9696032559,
      "Base_Units": 201,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "30, "
    },
    {
      "ID": 978,
      "UpcCode": 61076455219,
      "vendorName": "Pepsi",
      "ProductDescription": "Bang Candy Apple Crisp 16Oz",
      "BrandName": "BANG",
      "CaseSize": 0,
      "Unit_Price": 3.29,
      "Unit_Cost": 1.3333,
      "Margin": 0.59,
      "Elasticity": -1.243696121,
      "Base_Units": 110,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "978, "
    },
    {
      "ID": 979,
      "UpcCode": 61076402616,
      "vendorName": "Pepsi",
      "ProductDescription": "Bang Key Lime Pie 16Z Cn",
      "BrandName": "BANG",
      "CaseSize": 0,
      "Unit_Price": 3.39,
      "Unit_Cost": 1.3542,
      "Margin": 0.6,
      "Elasticity": -1.338332667,
      "Base_Units": 86,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "979, "
    },
    {
      "ID": 980,
      "UpcCode": 61076403004,
      "vendorName": "Pepsi",
      "ProductDescription": "Bang Wyldin Watermelon 16Z",
      "BrandName": "BANG",
      "CaseSize": 0,
      "Unit_Price": 2.49,
      "Unit_Cost": 1.3542,
      "Margin": 0.46,
      "Elasticity": -1.326966059,
      "Base_Units": 109,
      "Level1": "Cooler",
      "Level2": "Energy Drinks",
      "Level3": "Carb Energy Drnks",
      "FIELD15": "980, "
    },
    {
      "ID": 1000,
      "UpcCode": 4900054491,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Aha Raspberry Acai 16Z Btl",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 0.8383,
      "Margin": 0.58,
      "Elasticity": -1.064644619,
      "Base_Units": 98,
      "Level1": "Cooler",
      "Level2": "Other Pkged Bev",
      "Level3": "Other Pkg Bev",
      "FIELD15": "1000, "
    },
    {
      "ID": 47,
      "UpcCode": 78000041163,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "RC Cola Cn",
      "BrandName": "RC",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 1.33,
      "Margin": 0.73,
      "Elasticity": -1.53162164,
      "Base_Units": 136,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "47, "
    },
    {
      "ID": 48,
      "UpcCode": 78000041408,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "RC Cola Pet",
      "BrandName": "RC",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 0.8542,
      "Margin": 0.57,
      "Elasticity": -1.857742347,
      "Base_Units": 130,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "48, "
    },
    {
      "ID": 49,
      "UpcCode": 78000050813,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "RC Cola Pp Pet",
      "BrandName": "RC",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -1.201362878,
      "Base_Units": 42,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "49, "
    },
    {
      "ID": 50,
      "UpcCode": 78000003192,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "RC Cola Lt",
      "BrandName": "RC",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 0.92,
      "Margin": 0.54,
      "Elasticity": -1.668170014,
      "Base_Units": 67,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "50, "
    },
    {
      "ID": 51,
      "UpcCode": 78000003376,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "RC Cola Pet",
      "BrandName": "RC",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -2.414781984,
      "Base_Units": 119,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "51, "
    },
    {
      "ID": 52,
      "UpcCode": 7800005081,
      "vendorName": "M/C DET Dist Co Alcohol",
      "ProductDescription": "RC Cola Pp Pet",
      "BrandName": "RC",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.20875,
      "Margin": 0.39,
      "Elasticity": -1.553036949,
      "Base_Units": 126,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "52, "
    },
    {
      "ID": 53,
      "UpcCode": 7800003287,
      "vendorName": "M/C Nebraskaland Dist LLC - Alcohol",
      "ProductDescription": "2L RC Cola Pet Ls8 Pp129",
      "BrandName": "RC",
      "CaseSize": 0,
      "Unit_Price": 2.49,
      "Unit_Cost": 1.9375,
      "Margin": 0.22,
      "Elasticity": -1.216274129,
      "Base_Units": 213,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "53, "
    },
    {
      "ID": 54,
      "UpcCode": 7800003288,
      "vendorName": "Dr Pepper/Snapple SW",
      "ProductDescription": "2L Cherry RC Cola Pet Ls8 Pp129",
      "BrandName": "RC",
      "CaseSize": 0,
      "Unit_Price": 2.49,
      "Unit_Cost": 1.375,
      "Margin": 0.45,
      "Elasticity": -1.53139906,
      "Base_Units": 191,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "54, "
    },
    {
      "ID": 55,
      "UpcCode": 29500262954,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Nehi Peach Cn",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 1.33,
      "Margin": 0.73,
      "Elasticity": -1.902737519,
      "Base_Units": 33,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "55, "
    },
    {
      "ID": 56,
      "UpcCode": 29500267621,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Nehi Peach Pet",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 0.8542,
      "Margin": 0.57,
      "Elasticity": -1.408909383,
      "Base_Units": 123,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "56, "
    },
    {
      "ID": 57,
      "UpcCode": 29500269328,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Nehi Peach Pet",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -1.17321555,
      "Base_Units": 149,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "57, "
    },
    {
      "ID": 58,
      "UpcCode": 78000005318,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Cherry 7 Up Pet",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -0.8979500663,
      "Base_Units": 155,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "58, "
    },
    {
      "ID": 59,
      "UpcCode": 78000008319,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Diet Chery 7Up Pet",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -1.510281148,
      "Base_Units": 93,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "59, "
    },
    {
      "ID": 60,
      "UpcCode": 78000011883,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Diet Chery 7Up Cn",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 1.33,
      "Margin": 0.73,
      "Elasticity": -1.552800977,
      "Base_Units": 246,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "60, "
    },
    {
      "ID": 61,
      "UpcCode": 78000020250,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Tahitian Treat Cn",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 1.33,
      "Margin": 0.73,
      "Elasticity": -1.935093128,
      "Base_Units": 173,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "61, "
    },
    {
      "ID": 62,
      "UpcCode": 78000020366,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Tahitian Treat Pet",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -2.348500032,
      "Base_Units": 112,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "62, "
    },
    {
      "ID": 63,
      "UpcCode": 78000020403,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Tahitian Treat Pet",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 0.8542,
      "Margin": 0.57,
      "Elasticity": -1.352391013,
      "Base_Units": 92,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "63, "
    },
    {
      "ID": 64,
      "UpcCode": 78000000757,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Diet 7 Up Pet",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -0.9079580577,
      "Base_Units": 145,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "64, "
    },
    {
      "ID": 65,
      "UpcCode": 7800000831,
      "vendorName": "Dr Pepper/Snapple SW",
      "ProductDescription": "Diet Chery 7Up Pet",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -0.9569755238,
      "Base_Units": 40,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "65, "
    },
    {
      "ID": 66,
      "UpcCode": 7800000180,
      "vendorName": "M/C Columbia Dist Co",
      "ProductDescription": "7.5Oz 7Up Scn 6Pkx4 Bb Hlwn",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 3.29,
      "Unit_Cost": 1.375,
      "Margin": 0.58,
      "Elasticity": -1.07999118,
      "Base_Units": 181,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "66, "
    },
    {
      "ID": 67,
      "UpcCode": 7800001223,
      "vendorName": "M/C Columbia Dist Co",
      "ProductDescription": "0.5L Dt 7Up Pet 6Pkx4",
      "BrandName": "7UP",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.375,
      "Margin": 0.54,
      "Elasticity": -3.181243162,
      "Base_Units": 208,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "67, "
    },
    {
      "ID": 68,
      "UpcCode": 78000052169,
      "vendorName": "Craig Stein Beverage",
      "ProductDescription": "Aw Rootbeer Cn",
      "BrandName": "A&W",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.05,
      "Margin": 0.59,
      "Elasticity": -0.7069538932,
      "Base_Units": 113,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "68, "
    },
    {
      "ID": 69,
      "UpcCode": 78000052404,
      "vendorName": "Craig Stein Beverage",
      "ProductDescription": "Aw Rootbeer Pet",
      "BrandName": "A&W",
      "CaseSize": 0,
      "Unit_Price": 1.623333333,
      "Unit_Cost": 0.34,
      "Margin": 0.79,
      "Elasticity": -1.020169977,
      "Base_Units": 228,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "69, "
    },
    {
      "ID": 70,
      "UpcCode": 78000053166,
      "vendorName": "Craig Stein Beverage",
      "ProductDescription": "Diet Aw Rootb Cn",
      "BrandName": "A&W",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.69165,
      "Margin": 0.46,
      "Elasticity": -1.348235018,
      "Base_Units": 67,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "70, "
    },
    {
      "ID": 71,
      "UpcCode": 78000053463,
      "vendorName": "AB Mitchell Columbus",
      "ProductDescription": "Diet Aw Rootb Pet",
      "BrandName": "A&W",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.375,
      "Margin": 0.31,
      "Elasticity": -1.860828212,
      "Base_Units": 205,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "71, "
    },
    {
      "ID": 72,
      "UpcCode": 78000054408,
      "vendorName": "Craig Stein Beverage",
      "ProductDescription": "Aw Cream Soda Pet",
      "BrandName": "A&W",
      "CaseSize": 0,
      "Unit_Price": 1.77,
      "Unit_Cost": 1.12118,
      "Margin": 0.37,
      "Elasticity": -1.644867987,
      "Base_Units": 27,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "72, "
    },
    {
      "ID": 73,
      "UpcCode": 7800005288,
      "vendorName": "M/C Columbia Dist Co",
      "ProductDescription": "12Oz Aw Root Beer Pet 8Pkx3 Bb",
      "BrandName": "A&W",
      "CaseSize": 0,
      "Unit_Price": 5.29,
      "Unit_Cost": 1.13,
      "Margin": 0.79,
      "Elasticity": -1.094936883,
      "Base_Units": 162,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "73, "
    },
    {
      "ID": 989,
      "UpcCode": 4900017871,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "12Oz Coke Dt Blueberry Acai",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 1.39,
      "Unit_Cost": 0.7188,
      "Margin": 0.48,
      "Elasticity": -2.471611924,
      "Base_Units": 151,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "989, "
    },
    {
      "ID": 992,
      "UpcCode": 4900000307,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Coca Cola Mini Btl 390Ml",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 1.69,
      "Unit_Cost": 0.9375,
      "Margin": 0.45,
      "Elasticity": -1.476745388,
      "Base_Units": 146,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "992, "
    },
    {
      "ID": 993,
      "UpcCode": 4900000308,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Diet Coke Mini Btl 390Ml",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 1.69,
      "Unit_Cost": 0.9375,
      "Margin": 0.45,
      "Elasticity": -1.874737003,
      "Base_Units": 94,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "993, "
    },
    {
      "ID": 994,
      "UpcCode": 4900000335,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Coke Zero Mini Btl 390Ml",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 1.69,
      "Unit_Cost": 0.9375,
      "Margin": 0.45,
      "Elasticity": -1.06878286,
      "Base_Units": 121,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "994, "
    },
    {
      "ID": 995,
      "UpcCode": 4900000336,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Sprite Mini Btl 390Ml",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 1.69,
      "Unit_Cost": 0.9375,
      "Margin": 0.45,
      "Elasticity": -1.321515244,
      "Base_Units": 30,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "995, "
    },
    {
      "ID": 996,
      "UpcCode": 4900000341,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Cherry Coke Mini Btl 390Ml",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 1.69,
      "Unit_Cost": 0.9375,
      "Margin": 0.45,
      "Elasticity": -1.456170046,
      "Base_Units": 14,
      "Level1": "Cooler",
      "Level2": "Soda Pkged",
      "Level3": "Carb Soft Drinks",
      "FIELD15": "996, "
    },
    {
      "ID": 990,
      "UpcCode": 4900007814,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "13.7Oz Mccafe Cold Brew Toasted Coconut",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.5,
      "Margin": 0.5,
      "Elasticity": -1.523346158,
      "Base_Units": 153,
      "Level1": "Cooler",
      "Level2": "Tea/Coffee RTD",
      "Level3": "Coffee (Rtd)",
      "FIELD15": "990, "
    },
    {
      "ID": 991,
      "UpcCode": 4900007815,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "13.7Oz Mccafe Cold Brew Black",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.5,
      "Margin": 0.5,
      "Elasticity": -0.6868510292,
      "Base_Units": 52,
      "Level1": "Cooler",
      "Level2": "Tea/Coffee RTD",
      "Level3": "Coffee (Rtd)",
      "FIELD15": "991, "
    },
    {
      "ID": 997,
      "UpcCode": 4900053077,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Power Water Tropical Mango 20Z Btl",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 2.456666667,
      "Unit_Cost": 1.19,
      "Margin": 0.52,
      "Elasticity": -0.8981899162,
      "Base_Units": 33,
      "Level1": "Cooler",
      "Level2": "Water",
      "Level3": "Enhanced Water",
      "FIELD15": "997, "
    },
    {
      "ID": 998,
      "UpcCode": 4900053081,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Power Water Berry Cherry 20Z Btl",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 2.456666667,
      "Unit_Cost": 1.19,
      "Margin": 0.52,
      "Elasticity": -1.357560172,
      "Base_Units": 97,
      "Level1": "Cooler",
      "Level2": "Water",
      "Level3": "Enhanced Water",
      "FIELD15": "998, "
    },
    {
      "ID": 999,
      "UpcCode": 4900054467,
      "vendorName": "Coke Southwest Beverages",
      "ProductDescription": "Power Water Lemon 20Z Btl",
      "BrandName": "COKE",
      "CaseSize": 0,
      "Unit_Price": 1.69,
      "Unit_Cost": 1.0417,
      "Margin": 0.38,
      "Elasticity": -1.120504152,
      "Base_Units": 17,
      "Level1": "Cooler",
      "Level2": "Water",
      "Level3": "Enhanced Water",
      "FIELD15": "999, "
    },
    {
      "ID": 3,
      "UpcCode": 60216650925,
      "vendorName": "McLane",
      "ProductDescription": "Box Donut 11.75X8X2",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 0.25,
      "Margin": 0.96,
      "Elasticity": -1.038534417,
      "Base_Units": 221,
      "Level1": "Deli",
      "Level2": "Grab n' Go",
      "Level3": "Bakery",
      "FIELD15": "3, "
    },
    {
      "ID": 238,
      "UpcCode": 85010500715,
      "vendorName": "DAS",
      "ProductDescription": "Sentinel 1080P 17In Port Gaming Monitor",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 349.99,
      "Unit_Cost": 278.59,
      "Margin": 0.2,
      "Elasticity": -0.7991269742,
      "Base_Units": 175,
      "Level1": "Electronics",
      "Level2": "Electronics Misc",
      "Level3": "Elect Misc",
      "FIELD15": "238, "
    },
    {
      "ID": 987,
      "UpcCode": 85000549330,
      "vendorName": "DAS",
      "ProductDescription": "Wireless Ahd Vehicle Backup Camera",
      "BrandName": "BOYO",
      "CaseSize": 0,
      "Unit_Price": 349.99,
      "Unit_Cost": 208.5,
      "Margin": 0.4,
      "Elasticity": -1.139003303,
      "Base_Units": 144,
      "Level1": "Electronics",
      "Level2": "Electronics Misc",
      "Level3": "Elect Misc",
      "FIELD15": "987, "
    },
    {
      "ID": 477,
      "UpcCode": 9897450334,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Asst Pick Up Trucks",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.25,
      "Margin": 0.47,
      "Elasticity": -1.04099138,
      "Base_Units": 28,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "477, "
    },
    {
      "ID": 478,
      "UpcCode": 9897458765,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Sports And Import Pb Cars",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.25,
      "Margin": 0.47,
      "Elasticity": -0.7137702882,
      "Base_Units": 105,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "478, "
    },
    {
      "ID": 479,
      "UpcCode": 9897498264,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "1 24Th Transformers Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 14.95,
      "Margin": 0.4,
      "Elasticity": -0.985296126,
      "Base_Units": 144,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "479, "
    },
    {
      "ID": 501,
      "UpcCode": 9015932160,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "1 24Th Hd Graphic Vehicles",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 15.95,
      "Margin": 0.36,
      "Elasticity": -0.9268864309,
      "Base_Units": 215,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "501, "
    },
    {
      "ID": 502,
      "UpcCode": 9897400488,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "4Pk Tray Chevy Classic Cars",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.9,
      "Margin": 0.45,
      "Elasticity": -1.524222539,
      "Base_Units": 75,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "502, "
    },
    {
      "ID": 503,
      "UpcCode": 9897400489,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "4Pk Tray Pick Up Trucks",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.9,
      "Margin": 0.45,
      "Elasticity": -1.239175297,
      "Base_Units": 226,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "503, "
    },
    {
      "ID": 504,
      "UpcCode": 9897400490,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "4Pk Tray Dodge Ford Cars",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.9,
      "Margin": 0.45,
      "Elasticity": -1.318393946,
      "Base_Units": 154,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "504, "
    },
    {
      "ID": 505,
      "UpcCode": 9897400492,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "4Pk Tray Sports Car",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.9,
      "Margin": 0.45,
      "Elasticity": -1.56507136,
      "Base_Units": 67,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "505, "
    },
    {
      "ID": 506,
      "UpcCode": 9897421010,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Chevy Classic Btm Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 12.5,
      "Margin": 0.43,
      "Elasticity": -0.7248887099,
      "Base_Units": 34,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "506, "
    },
    {
      "ID": 507,
      "UpcCode": 9897426060,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Chevy Ford Pick Up Truck Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 12.5,
      "Margin": 0.43,
      "Elasticity": -1.089477508,
      "Base_Units": 166,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "507, "
    },
    {
      "ID": 508,
      "UpcCode": 9897429090,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "4Pk Tray Classic Muscle",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 12.5,
      "Margin": 0.43,
      "Elasticity": -0.7779115851,
      "Base_Units": 145,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "508, "
    },
    {
      "ID": 509,
      "UpcCode": 9897431010,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Sport And Import Car Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 12.5,
      "Margin": 0.43,
      "Elasticity": -1.516737304,
      "Base_Units": 249,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "509, "
    },
    {
      "ID": 510,
      "UpcCode": 9897450333,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Asst Chevy Wreckers",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.25,
      "Margin": 0.47,
      "Elasticity": -0.9269999037,
      "Base_Units": 121,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "510, "
    },
    {
      "ID": 511,
      "UpcCode": 9897458761,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Classic Pb Muscle Car Asst 12Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.25,
      "Margin": 0.47,
      "Elasticity": -0.7871783305,
      "Base_Units": 32,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "511, "
    },
    {
      "ID": 512,
      "UpcCode": 9897468562,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "6In L Mini School Bus",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.25,
      "Margin": 0.47,
      "Elasticity": -0.7768663286,
      "Base_Units": 46,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "512, "
    },
    {
      "ID": 513,
      "UpcCode": 9897498263,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "1 24Th Batmobile Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 14.95,
      "Margin": 0.4,
      "Elasticity": -1.172544534,
      "Base_Units": 10,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "513, "
    },
    {
      "ID": 573,
      "UpcCode": 9897400476,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Mini Mag Car Asst W Board",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.25,
      "Margin": 0.46,
      "Elasticity": -0.9738824972,
      "Base_Units": 114,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "573, "
    },
    {
      "ID": 574,
      "UpcCode": 9897400477,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Mini Magnet Car Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.25,
      "Margin": 0.46,
      "Elasticity": -0.6154314135,
      "Base_Units": 162,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Die-Cast",
      "FIELD15": "574, "
    },
    {
      "ID": 302,
      "UpcCode": 65794900547,
      "vendorName": "DAS",
      "ProductDescription": "Dbl Wall Stnlss Stl 25Oz Wtr Bottle Mblk",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 10.91,
      "Margin": 0.5,
      "Elasticity": -0.8035516699,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Drinkware",
      "FIELD15": "302, "
    },
    {
      "ID": 303,
      "UpcCode": 65794900548,
      "vendorName": "DAS",
      "ProductDescription": "Dbl Wall Stnlss Stl 25Oz Wtr Bottle Olv",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 10.91,
      "Margin": 0.5,
      "Elasticity": -0.7219068283,
      "Base_Units": 177,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Drinkware",
      "FIELD15": "303, "
    },
    {
      "ID": 350,
      "UpcCode": 84143710993,
      "vendorName": "DAS",
      "ProductDescription": "Mammoth 30 Oz Insulated Stainlesscopper",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 11.25,
      "Margin": 0.55,
      "Elasticity": -0.8646977394,
      "Base_Units": 177,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Drinkware",
      "FIELD15": "350, "
    },
    {
      "ID": 523,
      "UpcCode": 9897410104,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Mini Coin Purse Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.133434381,
      "Base_Units": 170,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "523, "
    },
    {
      "ID": 524,
      "UpcCode": 9897410105,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Mini Purse Bag Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.6,
      "Base_Units": 98,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "524, "
    },
    {
      "ID": 525,
      "UpcCode": 9897410106,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Mini Pen Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 3.95,
      "Margin": 0.51,
      "Elasticity": -1.873335343,
      "Base_Units": 177,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "525, "
    },
    {
      "ID": 526,
      "UpcCode": 9897410107,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Mini Coin Bag Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3,
      "Margin": 0.5,
      "Elasticity": -1.261366345,
      "Base_Units": 60,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "526, "
    },
    {
      "ID": 527,
      "UpcCode": 9897442035,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Cap Coin Purse Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5,
      "Margin": 0.5,
      "Elasticity": -0.7153485796,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "527, "
    },
    {
      "ID": 576,
      "UpcCode": 9897420080,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Lock N Load Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3,
      "Margin": 0.5,
      "Elasticity": -0.6540338752,
      "Base_Units": 38,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "576, "
    },
    {
      "ID": 577,
      "UpcCode": 9897441213,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ww Fingerless Gloves",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 8.25,
      "Margin": 0.45,
      "Elasticity": -1.682692525,
      "Base_Units": 82,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "577, "
    },
    {
      "ID": 595,
      "UpcCode": 9897418002,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Embroidered Purse",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 23.95,
      "Margin": 0.4,
      "Elasticity": -1.100262897,
      "Base_Units": 220,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "595, "
    },
    {
      "ID": 596,
      "UpcCode": 9897418004,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Embroidered Wallet",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.95,
      "Margin": 0.45,
      "Elasticity": -1.547509309,
      "Base_Units": 249,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "596, "
    },
    {
      "ID": 597,
      "UpcCode": 9897418101,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Leather Cowhide Purse 1",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.95,
      "Margin": 0.43,
      "Elasticity": -1.204018872,
      "Base_Units": 44,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "597, "
    },
    {
      "ID": 598,
      "UpcCode": 9897418103,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Leather Cowhide Wallet",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.95,
      "Margin": 0.45,
      "Elasticity": -1.433251187,
      "Base_Units": 245,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Fashion Accessories",
      "FIELD15": "598, "
    },
    {
      "ID": 192,
      "UpcCode": 3050643829,
      "vendorName": "DAS",
      "ProductDescription": "Watch Shipper 96Pc Kids License",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 959.04,
      "Unit_Cost": 527.04,
      "Margin": 0.45,
      "Elasticity": -0.7041645248,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "192, "
    },
    {
      "ID": 515,
      "UpcCode": 89651501336,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wood Sign Dishes Will Wait",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.5,
      "Margin": 0.45,
      "Elasticity": -0.7555828022,
      "Base_Units": 18,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "515, "
    },
    {
      "ID": 516,
      "UpcCode": 89651501337,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wood Sign Angels",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.5,
      "Margin": 0.45,
      "Elasticity": -1.158565971,
      "Base_Units": 63,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "516, "
    },
    {
      "ID": 528,
      "UpcCode": 9897407019,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Cowboy Boot Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2,
      "Margin": 0.5,
      "Elasticity": -0.923412778,
      "Base_Units": 209,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "528, "
    },
    {
      "ID": 542,
      "UpcCode": 9897442085,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Velvet Mini Purse 3In",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -0.8484797446,
      "Base_Units": 22,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "542, "
    },
    {
      "ID": 557,
      "UpcCode": 9897421168,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Day Of Dead Mag Ast 2",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.5,
      "Margin": 0.5,
      "Elasticity": -0.9879492244,
      "Base_Units": 150,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "557, "
    },
    {
      "ID": 572,
      "UpcCode": 9897441689,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "La 3D State Map Magnet Epoxy",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.35,
      "Margin": 0.53,
      "Elasticity": -0.726138947,
      "Base_Units": 48,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "572, "
    },
    {
      "ID": 662,
      "UpcCode": 9897410189,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Bling Shoe Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3,
      "Margin": 0.5,
      "Elasticity": -1.266112411,
      "Base_Units": 239,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "662, "
    },
    {
      "ID": 695,
      "UpcCode": 75385302001,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Beagle",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.46870312,
      "Base_Units": 141,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "695, "
    },
    {
      "ID": 696,
      "UpcCode": 75385302002,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Black Lab",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.538181753,
      "Base_Units": 32,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "696, "
    },
    {
      "ID": 697,
      "UpcCode": 75385302003,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Boston Terrier",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -0.6,
      "Base_Units": 133,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "697, "
    },
    {
      "ID": 698,
      "UpcCode": 75385302004,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Boxer",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -0.9450043787,
      "Base_Units": 47,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "698, "
    },
    {
      "ID": 699,
      "UpcCode": 75385302005,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Bulldog",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -0.8852656079,
      "Base_Units": 99,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "699, "
    },
    {
      "ID": 700,
      "UpcCode": 75385302006,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Chihauhua",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.297554043,
      "Base_Units": 123,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "700, "
    },
    {
      "ID": 701,
      "UpcCode": 75385302007,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Chocolate Lab",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -0.6,
      "Base_Units": 95,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "701, "
    },
    {
      "ID": 702,
      "UpcCode": 75385302008,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Dachshund",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.23186597,
      "Base_Units": 71,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "702, "
    },
    {
      "ID": 703,
      "UpcCode": 75385302009,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign German Shepherd",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.587966005,
      "Base_Units": 152,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "703, "
    },
    {
      "ID": 704,
      "UpcCode": 75385302010,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Golden Retriever",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -0.8889286103,
      "Base_Units": 194,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "704, "
    },
    {
      "ID": 705,
      "UpcCode": 75385302011,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Pitbull",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.600623108,
      "Base_Units": 46,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "705, "
    },
    {
      "ID": 706,
      "UpcCode": 75385302012,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Pug",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -2.139399015,
      "Base_Units": 146,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "706, "
    },
    {
      "ID": 707,
      "UpcCode": 75385302013,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Poodle",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.778153695,
      "Base_Units": 118,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "707, "
    },
    {
      "ID": 708,
      "UpcCode": 75385302014,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Rottweiler",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.421870577,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "708, "
    },
    {
      "ID": 709,
      "UpcCode": 75385302015,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Schnauzer",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.588659663,
      "Base_Units": 237,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "709, "
    },
    {
      "ID": 710,
      "UpcCode": 75385302016,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Shih Tzu",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -2.869944169,
      "Base_Units": 188,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "710, "
    },
    {
      "ID": 711,
      "UpcCode": 75385302017,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Yellow Lab",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -0.6549283435,
      "Base_Units": 127,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "711, "
    },
    {
      "ID": 712,
      "UpcCode": 75385302018,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dog Sign Yorkshire Terrier",
      "BrandName": "SJT",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -0.8379463438,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Gifts / Souvenirs",
      "FIELD15": "712, "
    },
    {
      "ID": 19,
      "UpcCode": 88962125600,
      "vendorName": "Captivating Headwear",
      "ProductDescription": "Appalachian St 1K",
      "BrandName": "1K",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.15,
      "Margin": 0.48,
      "Elasticity": -0.6562147861,
      "Base_Units": 95,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "19, "
    },
    {
      "ID": 20,
      "UpcCode": 88962125607,
      "vendorName": "Captivating Headwear",
      "ProductDescription": "Boston College 1K",
      "BrandName": "1K",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.15,
      "Margin": 0.48,
      "Elasticity": -1.291765557,
      "Base_Units": 95,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "20, "
    },
    {
      "ID": 21,
      "UpcCode": 76835391850,
      "vendorName": "Captivating Headwear",
      "ProductDescription": "1K2Tn Al Adj 2Tn1",
      "BrandName": "1K",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.15,
      "Margin": 0.48,
      "Elasticity": -1.328028536,
      "Base_Units": 15,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "21, "
    },
    {
      "ID": 22,
      "UpcCode": 76835391923,
      "vendorName": "Captivating Headwear",
      "ProductDescription": "1K2Tn Okst Adj 2Tn1",
      "BrandName": "1K",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.15,
      "Margin": 0.48,
      "Elasticity": -1.414286253,
      "Base_Units": 132,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "22, "
    },
    {
      "ID": 23,
      "UpcCode": 88962125841,
      "vendorName": "Captivating Headwear",
      "ProductDescription": "1K Fishing",
      "BrandName": "1K",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.15,
      "Margin": 0.48,
      "Elasticity": -1.434760606,
      "Base_Units": 66,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "23, "
    },
    {
      "ID": 24,
      "UpcCode": 88962125842,
      "vendorName": "Captivating Headwear",
      "ProductDescription": "1K Hunting",
      "BrandName": "1K",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.15,
      "Margin": 0.48,
      "Elasticity": -1.348843498,
      "Base_Units": 243,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "24, "
    },
    {
      "ID": 42,
      "UpcCode": 67609240736,
      "vendorName": "DAS",
      "ProductDescription": "Assorted Caps",
      "BrandName": "H3",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 8.18,
      "Margin": 0.37,
      "Elasticity": -0.8183070299,
      "Base_Units": 201,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "42, "
    },
    {
      "ID": 216,
      "UpcCode": 4546480212,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Tactical Cap Asst",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.3,
      "Margin": 0.57,
      "Elasticity": -1.471735429,
      "Base_Units": 246,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "216, "
    },
    {
      "ID": 266,
      "UpcCode": 4546479231,
      "vendorName": "DAS",
      "ProductDescription": "Bco Cap Assorted",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.28,
      "Margin": 0.57,
      "Elasticity": -0.717232051,
      "Base_Units": 110,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "266, "
    },
    {
      "ID": 373,
      "UpcCode": 20657949001908,
      "vendorName": "DAS",
      "ProductDescription": "Fierce Mossy Oak Cap 36 Pc Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 467.64,
      "Unit_Cost": 237.6,
      "Margin": 0.49,
      "Elasticity": -1.226893245,
      "Base_Units": 102,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "373, "
    },
    {
      "ID": 374,
      "UpcCode": 65794900137,
      "vendorName": "DAS",
      "ProductDescription": "Skullz Deer Logo Embroidery Bottomland",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.6,
      "Margin": 0.49,
      "Elasticity": -2.264240207,
      "Base_Units": 47,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "374, "
    },
    {
      "ID": 375,
      "UpcCode": 65794900138,
      "vendorName": "DAS",
      "ProductDescription": "Skullz Deer Logo Embroidery Break Up Cou",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.6,
      "Margin": 0.49,
      "Elasticity": -2.008668804,
      "Base_Units": 136,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "375, "
    },
    {
      "ID": 376,
      "UpcCode": 65794900139,
      "vendorName": "DAS",
      "ProductDescription": "Outdoor Logo Embroidery Bottomland",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 8.24,
      "Margin": 0.37,
      "Elasticity": -0.9404770464,
      "Base_Units": 210,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "376, "
    },
    {
      "ID": 377,
      "UpcCode": 65794900189,
      "vendorName": "DAS",
      "ProductDescription": "Outdoor Logo Embroidery Break Up Country",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.6,
      "Margin": 0.49,
      "Elasticity": -0.7211742004,
      "Base_Units": 40,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "377, "
    },
    {
      "ID": 411,
      "UpcCode": 4546479849,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Tactical Cap Camo",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.28,
      "Margin": 0.57,
      "Elasticity": -0.7295519315,
      "Base_Units": 101,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "411, "
    },
    {
      "ID": 412,
      "UpcCode": 4546479850,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Tactical Cap Black",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.28,
      "Margin": 0.57,
      "Elasticity": -1.109194114,
      "Base_Units": 19,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "412, "
    },
    {
      "ID": 413,
      "UpcCode": 81897101134,
      "vendorName": "DAS",
      "ProductDescription": "Diesel Life Cap Flex Trucker Blk Wh Grn",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 14.25,
      "Margin": 0.35,
      "Elasticity": -1.2987366,
      "Base_Units": 245,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "413, "
    },
    {
      "ID": 414,
      "UpcCode": 81897101268,
      "vendorName": "DAS",
      "ProductDescription": "Diesel Life Cap Snapback Char Grn Grn",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 14.25,
      "Margin": 0.35,
      "Elasticity": -1.23132534,
      "Base_Units": 200,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "414, "
    },
    {
      "ID": 415,
      "UpcCode": 81897101316,
      "vendorName": "DAS",
      "ProductDescription": "Diesel Life Cap Snap Back Char Org Norng",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 14.25,
      "Margin": 0.35,
      "Elasticity": -0.9945653011,
      "Base_Units": 116,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "415, "
    },
    {
      "ID": 416,
      "UpcCode": 81897101331,
      "vendorName": "DAS",
      "ProductDescription": "Diesel Life Cap Snap Back Char Blk Red",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 14.25,
      "Margin": 0.35,
      "Elasticity": -0.8547422368,
      "Base_Units": 178,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "416, "
    },
    {
      "ID": 417,
      "UpcCode": 81897101389,
      "vendorName": "DAS",
      "ProductDescription": "Diesel Life Cap Snap Back Camo Blk Norg",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 14.25,
      "Margin": 0.35,
      "Elasticity": -3.03938432,
      "Base_Units": 230,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "417, "
    },
    {
      "ID": 418,
      "UpcCode": 81897101563,
      "vendorName": "DAS",
      "ProductDescription": "Diesel Life Cap Tower Loves 64 Asst",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 1407.36,
      "Unit_Cost": 912,
      "Margin": 0.35,
      "Elasticity": -1.715980117,
      "Base_Units": 45,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "418, "
    },
    {
      "ID": 445,
      "UpcCode": 65794900190,
      "vendorName": "DAS",
      "ProductDescription": "Deer Flag Embroidery Bottomland",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.6,
      "Margin": 0.49,
      "Elasticity": -1.116998582,
      "Base_Units": 51,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "445, "
    },
    {
      "ID": 446,
      "UpcCode": 65794900191,
      "vendorName": "DAS",
      "ProductDescription": "Deer Flag Embroidery Break Up Country",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.6,
      "Margin": 0.49,
      "Elasticity": -1.938559513,
      "Base_Units": 202,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "446, "
    },
    {
      "ID": 447,
      "UpcCode": 65794900193,
      "vendorName": "DAS",
      "ProductDescription": "American Flag Leather Patch Bottomland",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.6,
      "Margin": 0.49,
      "Elasticity": -1.322933898,
      "Base_Units": 30,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "447, "
    },
    {
      "ID": 448,
      "UpcCode": 65794900194,
      "vendorName": "DAS",
      "ProductDescription": "American Flag Leather Patch Break Up Cou",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.6,
      "Margin": 0.49,
      "Elasticity": -2.636946672,
      "Base_Units": 76,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "448, "
    },
    {
      "ID": 452,
      "UpcCode": 67609255658,
      "vendorName": "DAS",
      "ProductDescription": "Cap Big Rig Assortment",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 7.6,
      "Margin": 0.41,
      "Elasticity": -2.079128302,
      "Base_Units": 220,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Hats",
      "FIELD15": "452, "
    },
    {
      "ID": 566,
      "UpcCode": 9897446493,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Camo Burly Tan Jacket Reg",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 24.25,
      "Margin": 0.39,
      "Elasticity": -1.618101614,
      "Base_Units": 121,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Jackets",
      "FIELD15": "566, "
    },
    {
      "ID": 567,
      "UpcCode": 9897446494,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Camo Burly Tan Jacket Bigs",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 26.25,
      "Margin": 0.34,
      "Elasticity": -0.8435269243,
      "Base_Units": 168,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Jackets",
      "FIELD15": "567, "
    },
    {
      "ID": 227,
      "UpcCode": 4546480282,
      "vendorName": "DAS",
      "ProductDescription": "Folding Pocket Knife",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.08,
      "Margin": 0.59,
      "Elasticity": -1.259128118,
      "Base_Units": 25,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Knives",
      "FIELD15": "227, "
    },
    {
      "ID": 228,
      "UpcCode": 4546480283,
      "vendorName": "DAS",
      "ProductDescription": "Carabiner Folding Camo Knife",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 5.03,
      "Margin": 0.61,
      "Elasticity": -1.00372873,
      "Base_Units": 47,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Knives",
      "FIELD15": "228, "
    },
    {
      "ID": 229,
      "UpcCode": 4546480284,
      "vendorName": "DAS",
      "ProductDescription": "Folding Camo Pocket Knife",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.08,
      "Margin": 0.59,
      "Elasticity": -1.269940095,
      "Base_Units": 43,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Knives",
      "FIELD15": "229, "
    },
    {
      "ID": 480,
      "UpcCode": 9897450115,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ss Blk Blade W Red Skulls Hand",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 5.85,
      "Margin": 0.55,
      "Elasticity": -1.606155779,
      "Base_Units": 142,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Knives",
      "FIELD15": "480, "
    },
    {
      "ID": 481,
      "UpcCode": 9897450118,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ss Blade Green Camo Handle",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -1.221329146,
      "Base_Units": 158,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Knives",
      "FIELD15": "481, "
    },
    {
      "ID": 482,
      "UpcCode": 9897450120,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ss Blk Blade Neon Green Handle",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -0.8626057134,
      "Base_Units": 107,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Knives",
      "FIELD15": "482, "
    },
    {
      "ID": 529,
      "UpcCode": 9897431341,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Tx Pink Charcoal Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.086073022,
      "Base_Units": 23,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "529, "
    },
    {
      "ID": 530,
      "UpcCode": 9897431343,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Tx Block Hoodie Blk Camo",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -0.8625470012,
      "Base_Units": 20,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "530, "
    },
    {
      "ID": 531,
      "UpcCode": 9897431347,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Tx Charcoal Black Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -2.057377988,
      "Base_Units": 235,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "531, "
    },
    {
      "ID": 532,
      "UpcCode": 9897432905,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Az Pink Charcoal Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.231140758,
      "Base_Units": 71,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "532, "
    },
    {
      "ID": 533,
      "UpcCode": 9897432906,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Nm Charcoal Black Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.205766151,
      "Base_Units": 61,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "533, "
    },
    {
      "ID": 534,
      "UpcCode": 9897432907,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Az Pink Charcoal Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -0.904212591,
      "Base_Units": 155,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "534, "
    },
    {
      "ID": 535,
      "UpcCode": 9897432909,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ia Pink Charcoal Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.064696967,
      "Base_Units": 100,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "535, "
    },
    {
      "ID": 536,
      "UpcCode": 9897432910,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ne Pink Charcoal Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.471021085,
      "Base_Units": 90,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "536, "
    },
    {
      "ID": 537,
      "UpcCode": 9897432911,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ia Charcoal Black Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -0.6,
      "Base_Units": 163,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "537, "
    },
    {
      "ID": 538,
      "UpcCode": 9897432912,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ne Charcoal Black Hoodie",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -0.7090136696,
      "Base_Units": 161,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "538, "
    },
    {
      "ID": 562,
      "UpcCode": 9897431570,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hi Vis Lg Hoodie Bulk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 18,
      "Margin": 0.4,
      "Elasticity": -1.181394728,
      "Base_Units": 218,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "562, "
    },
    {
      "ID": 563,
      "UpcCode": 9897431571,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hi Vis Xl Hoodie Bulk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 18,
      "Margin": 0.4,
      "Elasticity": -0.8587496668,
      "Base_Units": 222,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "563, "
    },
    {
      "ID": 564,
      "UpcCode": 9897431573,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hi Vis 3X Hoodie Bulk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 18,
      "Margin": 0.4,
      "Elasticity": -2.5532786,
      "Base_Units": 225,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "564, "
    },
    {
      "ID": 565,
      "UpcCode": 9897431574,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hi Vis 4X Hoodie Bulk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 18,
      "Margin": 0.4,
      "Elasticity": -1.280939031,
      "Base_Units": 231,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Misc Clothing",
      "FIELD15": "565, "
    },
    {
      "ID": 189,
      "UpcCode": 3050642836,
      "vendorName": "DAS",
      "ProductDescription": "Paw Patrol Watch",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.49,
      "Margin": 0.45,
      "Elasticity": -1.942743591,
      "Base_Units": 237,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "189, "
    },
    {
      "ID": 190,
      "UpcCode": 3050642844,
      "vendorName": "DAS",
      "ProductDescription": "Minnie Watch",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.49,
      "Margin": 0.45,
      "Elasticity": -1.264677523,
      "Base_Units": 18,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "190, "
    },
    {
      "ID": 191,
      "UpcCode": 3050642848,
      "vendorName": "DAS",
      "ProductDescription": "Trolls Watch",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.49,
      "Margin": 0.45,
      "Elasticity": -1.617411207,
      "Base_Units": 173,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "191, "
    },
    {
      "ID": 193,
      "UpcCode": 68631709594,
      "vendorName": "DAS",
      "ProductDescription": "The Classics",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 7.14,
      "Margin": 0.45,
      "Elasticity": -0.9179441281,
      "Base_Units": 236,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "193, "
    },
    {
      "ID": 194,
      "UpcCode": 68631709762,
      "vendorName": "DAS",
      "ProductDescription": "The Beach Buddies",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 7.14,
      "Margin": 0.45,
      "Elasticity": -0.7097066828,
      "Base_Units": 233,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "194, "
    },
    {
      "ID": 200,
      "UpcCode": 29187663281,
      "vendorName": "DAS",
      "ProductDescription": "Bottle Opener Sunglasses 24Pc Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 311.76,
      "Unit_Cost": 171.36,
      "Margin": 0.45,
      "Elasticity": -0.6,
      "Base_Units": 67,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "200, "
    },
    {
      "ID": 396,
      "UpcCode": 60841502368,
      "vendorName": "DAS",
      "ProductDescription": "Large Pearl Viking Horn",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 30.4,
      "Margin": 0.39,
      "Elasticity": -0.6,
      "Base_Units": 127,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "396, "
    },
    {
      "ID": 397,
      "UpcCode": 60841502373,
      "vendorName": "DAS",
      "ProductDescription": "Xxl Carved Drinking Horn Tankard",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 69.99,
      "Unit_Cost": 41,
      "Margin": 0.41,
      "Elasticity": -1.246900332,
      "Base_Units": 117,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "397, "
    },
    {
      "ID": 398,
      "UpcCode": 80299199661,
      "vendorName": "DAS",
      "ProductDescription": "20Oz Xl Drinking Horn Tankard",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 30.4,
      "Margin": 0.39,
      "Elasticity": -1.659295446,
      "Base_Units": 97,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "398, "
    },
    {
      "ID": 399,
      "UpcCode": 80299199676,
      "vendorName": "DAS",
      "ProductDescription": "Mead Cup",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 14.9,
      "Margin": 0.4,
      "Elasticity": -0.961537098,
      "Base_Units": 175,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "399, "
    },
    {
      "ID": 400,
      "UpcCode": 80299199680,
      "vendorName": "DAS",
      "ProductDescription": "Large Natural Finish Horn With Stand",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 29.25,
      "Margin": 0.41,
      "Elasticity": -0.6810939639,
      "Base_Units": 246,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "400, "
    },
    {
      "ID": 401,
      "UpcCode": 80299199681,
      "vendorName": "DAS",
      "ProductDescription": "Xl Natural Finish Horn With Stand",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 34.2,
      "Margin": 0.43,
      "Elasticity": -1.595706602,
      "Base_Units": 162,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "401, "
    },
    {
      "ID": 402,
      "UpcCode": 80299199695,
      "vendorName": "DAS",
      "ProductDescription": "Large Polished Viking Horn With Stand",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 54.99,
      "Unit_Cost": 33.33,
      "Margin": 0.39,
      "Elasticity": -1.186549612,
      "Base_Units": 23,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "402, "
    },
    {
      "ID": 403,
      "UpcCode": 80299199699,
      "vendorName": "DAS",
      "ProductDescription": "Xxl 1 Liter Drinking Horn Tankard",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 34.2,
      "Margin": 0.43,
      "Elasticity": -0.9321057036,
      "Base_Units": 188,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "403, "
    },
    {
      "ID": 434,
      "UpcCode": 71024422388,
      "vendorName": "DAS",
      "ProductDescription": "Voyager Turntable",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 89.99,
      "Unit_Cost": 60.5,
      "Margin": 0.33,
      "Elasticity": -2.01915837,
      "Base_Units": 163,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "434, "
    },
    {
      "ID": 435,
      "UpcCode": 71024423515,
      "vendorName": "DAS",
      "ProductDescription": "Corsair Radio Cd Player",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 119.99,
      "Unit_Cost": 77.74,
      "Margin": 0.35,
      "Elasticity": -1.12887788,
      "Base_Units": 174,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "435, "
    },
    {
      "ID": 441,
      "UpcCode": 5038650390,
      "vendorName": "DAS",
      "ProductDescription": "Lvs 12 Pc 6 Blue 6 Red Bats With Balls",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 119.88,
      "Unit_Cost": 59.4,
      "Margin": 0.5,
      "Elasticity": -0.7843761814,
      "Base_Units": 151,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "441, "
    },
    {
      "ID": 469,
      "UpcCode": 64099012861,
      "vendorName": "DAS",
      "ProductDescription": "Small Tire Skulls",
      "BrandName": "HPI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.5,
      "Margin": 0.42,
      "Elasticity": -1.016051855,
      "Base_Units": 12,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "469, "
    },
    {
      "ID": 470,
      "UpcCode": 64099087118,
      "vendorName": "DAS",
      "ProductDescription": "Wildlife Oil Lighter",
      "BrandName": "HPI",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.15,
      "Margin": 0.55,
      "Elasticity": -1.324221414,
      "Base_Units": 187,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "470, "
    },
    {
      "ID": 471,
      "UpcCode": 64099089514,
      "vendorName": "DAS",
      "ProductDescription": "2Nd Amendment Oil Lighter",
      "BrandName": "HPI",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.15,
      "Margin": 0.55,
      "Elasticity": -1.413186836,
      "Base_Units": 157,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "471, "
    },
    {
      "ID": 472,
      "UpcCode": 64099089911,
      "vendorName": "DAS",
      "ProductDescription": "Day Of The Dead Oil Lighter",
      "BrandName": "HPI",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.15,
      "Margin": 0.55,
      "Elasticity": -1.800371082,
      "Base_Units": 50,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "472, "
    },
    {
      "ID": 473,
      "UpcCode": 9897434520,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hiking Stick Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.9,
      "Margin": 0.54,
      "Elasticity": -0.8576395156,
      "Base_Units": 35,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "473, "
    },
    {
      "ID": 474,
      "UpcCode": 9897434522,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Walking Cane Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.9,
      "Margin": 0.54,
      "Elasticity": -0.6,
      "Base_Units": 127,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "474, "
    },
    {
      "ID": 490,
      "UpcCode": 9897412518,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Sugar Skull Opener W Magnet",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.9133358214,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "490, "
    },
    {
      "ID": 491,
      "UpcCode": 9897420200,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Bull Skull Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.95,
      "Margin": 0.34,
      "Elasticity": -1.066036681,
      "Base_Units": 41,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "491, "
    },
    {
      "ID": 492,
      "UpcCode": 9897422028,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dreamcatcher Earring Chand 1",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -1.076613072,
      "Base_Units": 51,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "492, "
    },
    {
      "ID": 493,
      "UpcCode": 9897422029,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dreamcatcher Earring Dangle",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -1.8121503,
      "Base_Units": 104,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "493, "
    },
    {
      "ID": 494,
      "UpcCode": 9897422030,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dreamcatcher Earring Sun",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -0.7533288232,
      "Base_Units": 161,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "494, "
    },
    {
      "ID": 495,
      "UpcCode": 9897422031,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dreamcatcher Earring Beads",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -0.6,
      "Base_Units": 38,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "495, "
    },
    {
      "ID": 496,
      "UpcCode": 9897422032,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dreamcatcher Earring Chand 2",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -1.639041115,
      "Base_Units": 236,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "496, "
    },
    {
      "ID": 497,
      "UpcCode": 9897422033,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dreamcatcher Earring Eye",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -1.831985681,
      "Base_Units": 128,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "497, "
    },
    {
      "ID": 514,
      "UpcCode": 80876510248,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Usa Flag 42In Pallet",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 27,
      "Margin": 0.46,
      "Elasticity": -1.682424131,
      "Base_Units": 17,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "514, "
    },
    {
      "ID": 517,
      "UpcCode": 89651501547,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wood Sign You Are My Sunshine",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.5,
      "Margin": 0.45,
      "Elasticity": -1.069576275,
      "Base_Units": 74,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "517, "
    },
    {
      "ID": 518,
      "UpcCode": 89651501550,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wood Sign Love Moon Back",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.5,
      "Margin": 0.45,
      "Elasticity": -1.69299332,
      "Base_Units": 95,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "518, "
    },
    {
      "ID": 519,
      "UpcCode": 89651501765,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wood Sign You Are Amazing",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.5,
      "Margin": 0.45,
      "Elasticity": -1.953010457,
      "Base_Units": 82,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "519, "
    },
    {
      "ID": 520,
      "UpcCode": 89651501904,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wood Sign Wine Me Up",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.5,
      "Margin": 0.45,
      "Elasticity": -1.045266533,
      "Base_Units": 53,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "520, "
    },
    {
      "ID": 522,
      "UpcCode": 9897402104,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Painted Horseshoe Asmt 1",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 11.9,
      "Margin": 0.4,
      "Elasticity": -1.521932411,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "522, "
    },
    {
      "ID": 539,
      "UpcCode": 9897420101,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dream Catcher Heart Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.981907859,
      "Base_Units": 238,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "539, "
    },
    {
      "ID": 540,
      "UpcCode": 9897420107,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Tree Of Life Glass Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5,
      "Margin": 0.5,
      "Elasticity": -1.253168396,
      "Base_Units": 125,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "540, "
    },
    {
      "ID": 541,
      "UpcCode": 9897420109,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Tree Of Life Wire Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5,
      "Margin": 0.5,
      "Elasticity": -2.538798496,
      "Base_Units": 249,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "541, "
    },
    {
      "ID": 543,
      "UpcCode": 87859941054,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Mario Super Fun Shades",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 5.95,
      "Margin": 0.54,
      "Elasticity": -1.944105683,
      "Base_Units": 59,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "543, "
    },
    {
      "ID": 544,
      "UpcCode": 9897420194,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Camper Coffee Mugs",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.5,
      "Margin": 0.5,
      "Elasticity": -1.157534232,
      "Base_Units": 163,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "544, "
    },
    {
      "ID": 545,
      "UpcCode": 9897420197,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Camper Lotion Dispenser",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.5,
      "Margin": 0.5,
      "Elasticity": -1.225214725,
      "Base_Units": 110,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "545, "
    },
    {
      "ID": 546,
      "UpcCode": 9897420198,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Happy Camper Cookie Jar",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.95,
      "Margin": 0.43,
      "Elasticity": -1.503764126,
      "Base_Units": 170,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "546, "
    },
    {
      "ID": 547,
      "UpcCode": 9897432505,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Garden Pail Medium",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.5,
      "Margin": 0.47,
      "Elasticity": -0.9174298317,
      "Base_Units": 164,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "547, "
    },
    {
      "ID": 548,
      "UpcCode": 9897412234,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Decal Asst Skull W Hat Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.5,
      "Margin": 0.5,
      "Elasticity": -1.482974383,
      "Base_Units": 17,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "548, "
    },
    {
      "ID": 549,
      "UpcCode": 9897412235,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Pvc Skull W Headphone Asst Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.5,
      "Margin": 0.5,
      "Elasticity": -1.368745924,
      "Base_Units": 210,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "549, "
    },
    {
      "ID": 550,
      "UpcCode": 9897407008,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Cowboy Boot Key Chain",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.25,
      "Margin": 0.46,
      "Elasticity": -1.04005244,
      "Base_Units": 196,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "550, "
    },
    {
      "ID": 551,
      "UpcCode": 9897408005,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Macrame Kc 1",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3,
      "Margin": 0.5,
      "Elasticity": -1.236272348,
      "Base_Units": 226,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "551, "
    },
    {
      "ID": 552,
      "UpcCode": 9897408007,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Macrame Kc 2",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3,
      "Margin": 0.5,
      "Elasticity": -1.633483956,
      "Base_Units": 71,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "552, "
    },
    {
      "ID": 553,
      "UpcCode": 9897412104,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Decal Skull Mug",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 11.9,
      "Margin": 0.46,
      "Elasticity": -1.269205786,
      "Base_Units": 128,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "553, "
    },
    {
      "ID": 554,
      "UpcCode": 9897412109,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Decal Skull Goblet",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.9,
      "Margin": 0.45,
      "Elasticity": -0.8894288987,
      "Base_Units": 38,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "554, "
    },
    {
      "ID": 555,
      "UpcCode": 9897412492,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Decal Skull Btl Opener",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -1.276202735,
      "Base_Units": 53,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "555, "
    },
    {
      "ID": 556,
      "UpcCode": 9897412493,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Decal Skull Btl Opener Magnet",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4,
      "Margin": 0.5,
      "Elasticity": -0.71971775,
      "Base_Units": 235,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "556, "
    },
    {
      "ID": 558,
      "UpcCode": 9897430006,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Album Cover Kiss Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9.95,
      "Margin": 0.5,
      "Elasticity": -0.7132797477,
      "Base_Units": 224,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "558, "
    },
    {
      "ID": 559,
      "UpcCode": 9897430008,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Album Cover Aerosmith Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9.95,
      "Margin": 0.5,
      "Elasticity": -2.228948904,
      "Base_Units": 128,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "559, "
    },
    {
      "ID": 560,
      "UpcCode": 9897430010,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Bottle Opener Plaque Beverage Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 8.95,
      "Margin": 0.5,
      "Elasticity": -0.8607528019,
      "Base_Units": 122,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "560, "
    },
    {
      "ID": 561,
      "UpcCode": 9897430011,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Bottle Opener Plaque Saying Novelty Asst",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 8.95,
      "Margin": 0.5,
      "Elasticity": -2.093454832,
      "Base_Units": 81,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "561, "
    },
    {
      "ID": 568,
      "UpcCode": 9897410205,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Glass Baby Elephant",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.9,
      "Margin": 0.47,
      "Elasticity": -1.330638046,
      "Base_Units": 135,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "568, "
    },
    {
      "ID": 569,
      "UpcCode": 9897410206,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Glass Sea Turtle",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.9,
      "Margin": 0.47,
      "Elasticity": -1.748417089,
      "Base_Units": 10,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "569, "
    },
    {
      "ID": 570,
      "UpcCode": 9897410207,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Glass Shark",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.9,
      "Margin": 0.45,
      "Elasticity": -0.6964783118,
      "Base_Units": 94,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "570, "
    },
    {
      "ID": 571,
      "UpcCode": 81122901508,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Guitar Ii",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 11.9,
      "Margin": 0.4,
      "Elasticity": -0.8528669762,
      "Base_Units": 52,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "571, "
    },
    {
      "ID": 578,
      "UpcCode": 9897410193,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Glass Owl",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 8.95,
      "Margin": 0.5,
      "Elasticity": -0.8591246517,
      "Base_Units": 239,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "578, "
    },
    {
      "ID": 579,
      "UpcCode": 9897410200,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Glass Turtle",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.5,
      "Margin": 0.47,
      "Elasticity": -2.107324289,
      "Base_Units": 51,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "579, "
    },
    {
      "ID": 580,
      "UpcCode": 9897410201,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Glass Frog",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.9,
      "Margin": 0.45,
      "Elasticity": -1.735929901,
      "Base_Units": 92,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "580, "
    },
    {
      "ID": 581,
      "UpcCode": 9897410202,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Glass Elephant",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.9,
      "Margin": 0.48,
      "Elasticity": -0.9788398596,
      "Base_Units": 116,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "581, "
    },
    {
      "ID": 582,
      "UpcCode": 9897430019,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Sign Auto Asst 1",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -2.441646574,
      "Base_Units": 121,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "582, "
    },
    {
      "ID": 583,
      "UpcCode": 9897430020,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Sign Auto Asst 2",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -1.374023692,
      "Base_Units": 219,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "583, "
    },
    {
      "ID": 584,
      "UpcCode": 9897430021,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Sign Personality",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -1.238711306,
      "Base_Units": 49,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "584, "
    },
    {
      "ID": 585,
      "UpcCode": 9897430022,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Sign Americanagun",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -0.9026397467,
      "Base_Units": 123,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "585, "
    },
    {
      "ID": 586,
      "UpcCode": 9897430023,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Sign Beverage",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -0.9139252818,
      "Base_Units": 129,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "586, "
    },
    {
      "ID": 587,
      "UpcCode": 9897430024,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Sign Adult Beverage",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -1.207704421,
      "Base_Units": 41,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "587, "
    },
    {
      "ID": 588,
      "UpcCode": 9897430025,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Sign Super Hero",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -1.092623464,
      "Base_Units": 158,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "588, "
    },
    {
      "ID": 589,
      "UpcCode": 9897424392,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Magnet Vintage Beverage",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2,
      "Margin": 0.5,
      "Elasticity": -1.971693321,
      "Base_Units": 90,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "589, "
    },
    {
      "ID": 590,
      "UpcCode": 9897424393,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Magnet Vintage Garage",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2,
      "Margin": 0.5,
      "Elasticity": -1.79751464,
      "Base_Units": 109,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "590, "
    },
    {
      "ID": 591,
      "UpcCode": 9897424394,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Magnet Vintage Celebrity",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2,
      "Margin": 0.5,
      "Elasticity": -0.8353016261,
      "Base_Units": 217,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "591, "
    },
    {
      "ID": 592,
      "UpcCode": 9897424395,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Magnet Vintage Gun",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2,
      "Margin": 0.5,
      "Elasticity": -2.220955392,
      "Base_Units": 140,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "592, "
    },
    {
      "ID": 594,
      "UpcCode": 4650193739,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hay Bale 18In",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 9.95,
      "Margin": 0.45,
      "Elasticity": -1.159747093,
      "Base_Units": 207,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "594, "
    },
    {
      "ID": 599,
      "UpcCode": 9897473687,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Bullet S And P Set",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.5,
      "Margin": 0.5,
      "Elasticity": -0.7881914752,
      "Base_Units": 116,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "599, "
    },
    {
      "ID": 600,
      "UpcCode": 9897473747,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Windmill S And P Set",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.9,
      "Margin": 0.48,
      "Elasticity": -0.9670783455,
      "Base_Units": 236,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "600, "
    },
    {
      "ID": 611,
      "UpcCode": 9897412252,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Mini Decal Skull Kc",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.5,
      "Margin": 0.5,
      "Elasticity": -0.7327343295,
      "Base_Units": 64,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "611, "
    },
    {
      "ID": 612,
      "UpcCode": 9897432507,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Garden Pail Small",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.85,
      "Margin": 0.48,
      "Elasticity": -0.6917805245,
      "Base_Units": 159,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "612, "
    },
    {
      "ID": 613,
      "UpcCode": 9897471406,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Plastic Flower In Planter",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -1.758524648,
      "Base_Units": 188,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "613, "
    },
    {
      "ID": 614,
      "UpcCode": 80876510691,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "White Gray Wood Lantern",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 15.9,
      "Margin": 0.47,
      "Elasticity": -1.453904597,
      "Base_Units": 95,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "614, "
    },
    {
      "ID": 615,
      "UpcCode": 80876532535,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Garden Pail Large",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.9,
      "Margin": 0.48,
      "Elasticity": -1.366664963,
      "Base_Units": 82,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "615, "
    },
    {
      "ID": 616,
      "UpcCode": 80876535509,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Sun W Moon Stars Wall Hanging",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 32.5,
      "Margin": 0.46,
      "Elasticity": -2.184432249,
      "Base_Units": 15,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "616, "
    },
    {
      "ID": 617,
      "UpcCode": 80876535804,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Nature Planter Bucket Large",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.75,
      "Margin": 0.46,
      "Elasticity": -1.134918845,
      "Base_Units": 159,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "617, "
    },
    {
      "ID": 618,
      "UpcCode": 80876566120,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Poly Baby Cow",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.5,
      "Margin": 0.5,
      "Elasticity": -1.967752137,
      "Base_Units": 222,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "618, "
    },
    {
      "ID": 619,
      "UpcCode": 80876586104,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "10X5 Cut Out Home",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.838505359,
      "Base_Units": 232,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "619, "
    },
    {
      "ID": 620,
      "UpcCode": 80876586166,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "22X17 Truck Door Wall Hanging",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 26.9,
      "Margin": 0.46,
      "Elasticity": -2.367950775,
      "Base_Units": 203,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "620, "
    },
    {
      "ID": 621,
      "UpcCode": 80876510593,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "7X6 Life Is Sweet Wood Block",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.176947766,
      "Base_Units": 244,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "621, "
    },
    {
      "ID": 622,
      "UpcCode": 80876510594,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "7X6 Today Is A Good Day",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.025612209,
      "Base_Units": 75,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "622, "
    },
    {
      "ID": 623,
      "UpcCode": 9897416420,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wood Cabin Block Asst Sign",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.417688943,
      "Base_Units": 110,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "623, "
    },
    {
      "ID": 624,
      "UpcCode": 9897435805,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Nature Planter Bucket Small",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.75,
      "Margin": 0.48,
      "Elasticity": -0.7248097956,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "624, "
    },
    {
      "ID": 625,
      "UpcCode": 9897417404,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Owl Assorted Sp Set",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 11.5,
      "Margin": 0.48,
      "Elasticity": -2.105090777,
      "Base_Units": 196,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "625, "
    },
    {
      "ID": 626,
      "UpcCode": 9897417692,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Ceramic Piggy Sp Set",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.220891362,
      "Base_Units": 18,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "626, "
    },
    {
      "ID": 627,
      "UpcCode": 9897417773,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Punch Tin Table Tray",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.95,
      "Margin": 0.48,
      "Elasticity": -1.034379764,
      "Base_Units": 66,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "627, "
    },
    {
      "ID": 628,
      "UpcCode": 9897418021,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Cowhide Top Handle Purse Asst.",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 27.9,
      "Margin": 0.44,
      "Elasticity": -0.9355976828,
      "Base_Units": 115,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "628, "
    },
    {
      "ID": 629,
      "UpcCode": 9897418024,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Cowhide W Cork Purse Asst.",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 27.9,
      "Margin": 0.44,
      "Elasticity": -1.11678373,
      "Base_Units": 92,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "629, "
    },
    {
      "ID": 630,
      "UpcCode": 9897418030,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Clutch Wallet Tri Fold Asst.",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.289765213,
      "Base_Units": 201,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "630, "
    },
    {
      "ID": 631,
      "UpcCode": 9897412499,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Eagle Statue",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 21.95,
      "Margin": 0.45,
      "Elasticity": -0.6,
      "Base_Units": 66,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "631, "
    },
    {
      "ID": 632,
      "UpcCode": 9897476585,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Home Windmill Table Dcor",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.9,
      "Margin": 0.48,
      "Elasticity": -1.661683801,
      "Base_Units": 44,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "632, "
    },
    {
      "ID": 633,
      "UpcCode": 9897476586,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Windmill Water Tower Welcome",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.03651149,
      "Base_Units": 186,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "633, "
    },
    {
      "ID": 634,
      "UpcCode": 9897476587,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Tractor Sp Holder",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 9.5,
      "Margin": 0.47,
      "Elasticity": -1.637542067,
      "Base_Units": 107,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "634, "
    },
    {
      "ID": 635,
      "UpcCode": 9897476588,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Cowstack Sp Holder",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.5,
      "Margin": 0.47,
      "Elasticity": -1.772580528,
      "Base_Units": 187,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "635, "
    },
    {
      "ID": 636,
      "UpcCode": 9897476589,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Rooster Sunflower Sp Set",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.9,
      "Margin": 0.48,
      "Elasticity": -1.215211006,
      "Base_Units": 82,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "636, "
    },
    {
      "ID": 637,
      "UpcCode": 9897476590,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Pig Wheelbarrow Sp Holder",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.9,
      "Margin": 0.48,
      "Elasticity": -0.8977080936,
      "Base_Units": 74,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "637, "
    },
    {
      "ID": 638,
      "UpcCode": 9897476591,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Windmill Vase",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.5,
      "Margin": 0.47,
      "Elasticity": -1.273825959,
      "Base_Units": 139,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "638, "
    },
    {
      "ID": 639,
      "UpcCode": 9897476592,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Van Succulent Decoration",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.9,
      "Margin": 0.48,
      "Elasticity": -1.711205106,
      "Base_Units": 202,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "639, "
    },
    {
      "ID": 640,
      "UpcCode": 9897476593,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Camper Wsucculent Decoration",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.9,
      "Margin": 0.48,
      "Elasticity": -1.681497551,
      "Base_Units": 216,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "640, "
    },
    {
      "ID": 641,
      "UpcCode": 9897484005,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Sitting Pig Statue",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 27.5,
      "Margin": 0.45,
      "Elasticity": -1.451086126,
      "Base_Units": 153,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "641, "
    },
    {
      "ID": 642,
      "UpcCode": 9897484007,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Cow Flower Pot",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 27.5,
      "Margin": 0.45,
      "Elasticity": -0.8874626124,
      "Base_Units": 174,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "642, "
    },
    {
      "ID": 643,
      "UpcCode": 9897484009,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Rooster",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 32.95,
      "Margin": 0.45,
      "Elasticity": -2.049274593,
      "Base_Units": 123,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "643, "
    },
    {
      "ID": 644,
      "UpcCode": 9897484011,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Pig Flower Pot",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 27.5,
      "Margin": 0.45,
      "Elasticity": -1.997600914,
      "Base_Units": 138,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "644, "
    },
    {
      "ID": 645,
      "UpcCode": 9897421072,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Blue Mason Shape Sp Set",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -0.6521951829,
      "Base_Units": 78,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "645, "
    },
    {
      "ID": 646,
      "UpcCode": 9897421073,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Blue Mason Jar Shape Cookie Jar",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.181404672,
      "Base_Units": 114,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "646, "
    },
    {
      "ID": 647,
      "UpcCode": 9897421291,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Blue Mason Jar Mug",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.5,
      "Margin": 0.5,
      "Elasticity": -1.743526105,
      "Base_Units": 134,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "647, "
    },
    {
      "ID": 648,
      "UpcCode": 9897466921,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Red Mason Jar Spoon Rest W Spoon",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.138575215,
      "Base_Units": 39,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "648, "
    },
    {
      "ID": 649,
      "UpcCode": 9897466922,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Red Mason Jar Water Pitcher",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.5,
      "Margin": 0.45,
      "Elasticity": -1.72947446,
      "Base_Units": 22,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "649, "
    },
    {
      "ID": 650,
      "UpcCode": 9897466923,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Red Mason Jar Soap Dispenser",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -2.206080259,
      "Base_Units": 159,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "650, "
    },
    {
      "ID": 651,
      "UpcCode": 9897466925,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Spoon Rest Lets Go Camping",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -0.7391604113,
      "Base_Units": 74,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "651, "
    },
    {
      "ID": 652,
      "UpcCode": 9897466927,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wood Frame Family Plaid Asst Sign",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -0.6974965558,
      "Base_Units": 243,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "652, "
    },
    {
      "ID": 653,
      "UpcCode": 9897466929,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Blue Mason Jar Soap Dispenser",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.660530647,
      "Base_Units": 37,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "653, "
    },
    {
      "ID": 654,
      "UpcCode": 9897466930,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Blue Mason Jar Spoon Rest W Spoon",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.126404598,
      "Base_Units": 210,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "654, "
    },
    {
      "ID": 655,
      "UpcCode": 9897466939,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Roosters",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 21.95,
      "Margin": 0.45,
      "Elasticity": -1.360804986,
      "Base_Units": 190,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "655, "
    },
    {
      "ID": 656,
      "UpcCode": 9897466943,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Rooster Sp",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.9,
      "Margin": 0.47,
      "Elasticity": -1.235822837,
      "Base_Units": 167,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "656, "
    },
    {
      "ID": 657,
      "UpcCode": 9897466951,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Silver Camper Sp Set",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.5,
      "Margin": 0.47,
      "Elasticity": -2.745750948,
      "Base_Units": 61,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "657, "
    },
    {
      "ID": 658,
      "UpcCode": 9897466972,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal Flutedwatering Can",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.35,
      "Margin": 0.46,
      "Elasticity": -1.141779291,
      "Base_Units": 31,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "658, "
    },
    {
      "ID": 659,
      "UpcCode": 9897412330,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Snow Owl Poly Statue",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 27.9,
      "Margin": 0.44,
      "Elasticity": -0.823079129,
      "Base_Units": 81,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "659, "
    },
    {
      "ID": 660,
      "UpcCode": 9897412331,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Metal White Owl Statue",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 27.5,
      "Margin": 0.45,
      "Elasticity": -1.895562374,
      "Base_Units": 31,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "660, "
    },
    {
      "ID": 661,
      "UpcCode": 9897419396,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Plastic Flower In Planter",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.85,
      "Margin": 0.47,
      "Elasticity": -0.7534350519,
      "Base_Units": 224,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "661, "
    },
    {
      "ID": 663,
      "UpcCode": 9897420097,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Dream Catcher Round C",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.455661588,
      "Base_Units": 159,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "663, "
    },
    {
      "ID": 746,
      "UpcCode": 60870700805,
      "vendorName": "SRP",
      "ProductDescription": "Srp Small Sticker 2.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.5,
      "Margin": 0.5,
      "Elasticity": -1.58940811,
      "Base_Units": 38,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "746, "
    },
    {
      "ID": 747,
      "UpcCode": 60870700806,
      "vendorName": "SRP",
      "ProductDescription": "Srp Large Sticker 4.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.5,
      "Margin": 0.5,
      "Elasticity": -1.436638281,
      "Base_Units": 223,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "747, "
    },
    {
      "ID": 766,
      "UpcCode": 60870700120,
      "vendorName": "SRP",
      "ProductDescription": "Srp Hot Buys 7 Assortment",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 7,
      "Unit_Cost": 2.75,
      "Margin": 0.61,
      "Elasticity": -2.833603982,
      "Base_Units": 143,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "766, "
    },
    {
      "ID": 767,
      "UpcCode": 60870737976,
      "vendorName": "SRP",
      "ProductDescription": "Srp Hot Buys 3 Assortment",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 3,
      "Unit_Cost": 1.8,
      "Margin": 0.4,
      "Elasticity": -1.640568276,
      "Base_Units": 69,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "767, "
    },
    {
      "ID": 768,
      "UpcCode": 60870737977,
      "vendorName": "SRP",
      "ProductDescription": "Srp Hot Buys 5 Assortment",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 5,
      "Unit_Cost": 3,
      "Margin": 0.4,
      "Elasticity": -0.7827066069,
      "Base_Units": 45,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "768, "
    },
    {
      "ID": 769,
      "UpcCode": 60870737978,
      "vendorName": "SRP",
      "ProductDescription": "Srp Hot Buys 10 Assortment",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 10,
      "Unit_Cost": 6,
      "Margin": 0.4,
      "Elasticity": -1.086913247,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "769, "
    },
    {
      "ID": 869,
      "UpcCode": 65236900304,
      "vendorName": "SRP",
      "ProductDescription": "24.99 Assorted Torch Lighter",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.5,
      "Margin": 0.5,
      "Elasticity": -2.533562939,
      "Base_Units": 195,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "869, "
    },
    {
      "ID": 870,
      "UpcCode": 65236900312,
      "vendorName": "SRP",
      "ProductDescription": "17.99 Assorted Torch Lighter",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 9,
      "Margin": 0.5,
      "Elasticity": -0.6,
      "Base_Units": 98,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "870, "
    },
    {
      "ID": 871,
      "UpcCode": 65236900320,
      "vendorName": "SRP",
      "ProductDescription": "34.99 Blue Torch Lighter",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 18,
      "Margin": 0.49,
      "Elasticity": -2.265257446,
      "Base_Units": 149,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "871, "
    },
    {
      "ID": 872,
      "UpcCode": 65236900321,
      "vendorName": "SRP",
      "ProductDescription": "19.99 Assorted Torch Lighter",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10,
      "Margin": 0.5,
      "Elasticity": -0.6113681996,
      "Base_Units": 32,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "872, "
    },
    {
      "ID": 873,
      "UpcCode": 65236900326,
      "vendorName": "SRP",
      "ProductDescription": "34.99 Grey Torch Lighter",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 18,
      "Margin": 0.4,
      "Elasticity": -0.6698969124,
      "Base_Units": 206,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "873, "
    },
    {
      "ID": 901,
      "UpcCode": 68711007006,
      "vendorName": "SRP",
      "ProductDescription": "Torch Lighter Double Color",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 3,
      "Margin": 0.4,
      "Elasticity": -1.031332817,
      "Base_Units": 120,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "901, "
    },
    {
      "ID": 902,
      "UpcCode": 68711007157,
      "vendorName": "SRP",
      "ProductDescription": "Torch Lighter Side Torch",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 3,
      "Margin": 0.4,
      "Elasticity": -1.776336391,
      "Base_Units": 60,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Novelties",
      "FIELD15": "902, "
    },
    {
      "ID": 195,
      "UpcCode": 2218880366,
      "vendorName": "DAS",
      "ProductDescription": "Keyguard Black Matte",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.91,
      "Margin": 0.47,
      "Elasticity": -1.204741273,
      "Base_Units": 128,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Other Gm",
      "FIELD15": "195, "
    },
    {
      "ID": 196,
      "UpcCode": 2218880913,
      "vendorName": "DAS",
      "ProductDescription": "Mace Mini Pepper Asst 12Pc Ct Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 179.88,
      "Unit_Cost": 94.92,
      "Margin": 0.47,
      "Elasticity": -0.6188579022,
      "Base_Units": 113,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Other Gm",
      "FIELD15": "196, "
    },
    {
      "ID": 231,
      "UpcCode": 85930700129,
      "vendorName": "DAS",
      "ProductDescription": "28Oz Double Compartment",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 3,
      "Margin": 0.62,
      "Elasticity": -0.8272232892,
      "Base_Units": 187,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Other Gm",
      "FIELD15": "231, "
    },
    {
      "ID": 386,
      "UpcCode": 2218880365,
      "vendorName": "DAS",
      "ProductDescription": "Keyguard Pink Matte",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.91,
      "Margin": 0.47,
      "Elasticity": -0.8191464247,
      "Base_Units": 145,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Other Gm",
      "FIELD15": "386, "
    },
    {
      "ID": 410,
      "UpcCode": 4546480746,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Neck Gaiter Printed Left Side",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -1.856346506,
      "Base_Units": 127,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Other Gm",
      "FIELD15": "410, "
    },
    {
      "ID": 233,
      "UpcCode": 73468952069,
      "vendorName": "DAS",
      "ProductDescription": "5 Inch Squishmallows",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -0.8833988104,
      "Base_Units": 11,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "233, "
    },
    {
      "ID": 234,
      "UpcCode": 73468980429,
      "vendorName": "DAS",
      "ProductDescription": "5 Inch Squishmallows",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -0.6,
      "Base_Units": 218,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "234, "
    },
    {
      "ID": 235,
      "UpcCode": 73468995382,
      "vendorName": "DAS",
      "ProductDescription": "5 Inch Squishmallows",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -0.8472408319,
      "Base_Units": 66,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "235, "
    },
    {
      "ID": 359,
      "UpcCode": 73468921415,
      "vendorName": "DAS",
      "ProductDescription": "5 Squishmallow 6 Asst",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -0.8480200508,
      "Base_Units": 53,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "359, "
    },
    {
      "ID": 360,
      "UpcCode": 73468921418,
      "vendorName": "DAS",
      "ProductDescription": "5 Squishmallow 6 Asst",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -1.022356419,
      "Base_Units": 50,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "360, "
    },
    {
      "ID": 361,
      "UpcCode": 73468921908,
      "vendorName": "DAS",
      "ProductDescription": "5 Squishmallow  6 Asst",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -1.449810203,
      "Base_Units": 186,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "361, "
    },
    {
      "ID": 362,
      "UpcCode": 73468929215,
      "vendorName": "DAS",
      "ProductDescription": "5 Squishmallow Cats",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -0.6,
      "Base_Units": 188,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "362, "
    },
    {
      "ID": 363,
      "UpcCode": 73468948141,
      "vendorName": "DAS",
      "ProductDescription": "5 Squsihmallow Sleepy Eye",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -0.6876038177,
      "Base_Units": 47,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "363, "
    },
    {
      "ID": 364,
      "UpcCode": 73468951313,
      "vendorName": "DAS",
      "ProductDescription": "5 Squishmallow Food Mix B",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -2.309199049,
      "Base_Units": 219,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "364, "
    },
    {
      "ID": 365,
      "UpcCode": 73468952021,
      "vendorName": "DAS",
      "ProductDescription": "5In Dog Squishamallow Assortment 4 Asst",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -1.253785078,
      "Base_Units": 184,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "365, "
    },
    {
      "ID": 366,
      "UpcCode": 19305200601,
      "vendorName": "DAS",
      "ProductDescription": "Zuru Rainbocornsplushmixseries 1 Ds",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 19.77,
      "Margin": 0.34,
      "Elasticity": -1.280730131,
      "Base_Units": 68,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "366, "
    },
    {
      "ID": 368,
      "UpcCode": 4546480858,
      "vendorName": "DAS",
      "ProductDescription": "5 Inch Squishmallow Refill Kit",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 19.77,
      "Margin": 0.34,
      "Elasticity": -1.170151548,
      "Base_Units": 128,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "368, "
    },
    {
      "ID": 406,
      "UpcCode": 73468952014,
      "vendorName": "DAS",
      "ProductDescription": "5 Jungle Squishmallow Assortment 4 Asst",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.6,
      "Margin": 0.42,
      "Elasticity": -0.7270142421,
      "Base_Units": 157,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "406, "
    },
    {
      "ID": 839,
      "UpcCode": 9167110314,
      "vendorName": "SRP",
      "ProductDescription": "Rocker Llama 18.5In 2 Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 6.76,
      "Margin": 0.66,
      "Elasticity": -1.196321047,
      "Base_Units": 131,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "839, "
    },
    {
      "ID": 840,
      "UpcCode": 9167110315,
      "vendorName": "SRP",
      "ProductDescription": "Rocker Llama 26In 2 Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.57,
      "Margin": 0.5,
      "Elasticity": -0.6,
      "Base_Units": 62,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "840, "
    },
    {
      "ID": 841,
      "UpcCode": 9167110316,
      "vendorName": "SRP",
      "ProductDescription": "Rocker Llama 31In 2 Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 19.77,
      "Margin": 0.34,
      "Elasticity": -1.140056508,
      "Base_Units": 217,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "841, "
    },
    {
      "ID": 842,
      "UpcCode": 9167115570,
      "vendorName": "SRP",
      "ProductDescription": "24In 3 Asst. Narwhals",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 11.27,
      "Margin": 0.44,
      "Elasticity": -0.7281876218,
      "Base_Units": 104,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "842, "
    },
    {
      "ID": 843,
      "UpcCode": 9167115681,
      "vendorName": "SRP",
      "ProductDescription": "Pearl 17In 2 Asst. Penguins",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 5.16,
      "Margin": 0.6,
      "Elasticity": -0.8328663668,
      "Base_Units": 237,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "843, "
    },
    {
      "ID": 844,
      "UpcCode": 9167115858,
      "vendorName": "SRP",
      "ProductDescription": "Dookie 21In Pooping Unicorn",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 3.55,
      "Margin": 0.82,
      "Elasticity": -0.6,
      "Base_Units": 65,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "844, "
    },
    {
      "ID": 845,
      "UpcCode": 9167115953,
      "vendorName": "SRP",
      "ProductDescription": "125In 3 Asst. Sequinsnakes",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 18.61,
      "Margin": 0.53,
      "Elasticity": -1.908737143,
      "Base_Units": 220,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "845, "
    },
    {
      "ID": 846,
      "UpcCode": 9167116164,
      "vendorName": "SRP",
      "ProductDescription": "Lennie 21In 2 Asst. Goats",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.91,
      "Margin": 0.45,
      "Elasticity": -1.248122823,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "846, "
    },
    {
      "ID": 847,
      "UpcCode": 9167116170,
      "vendorName": "SRP",
      "ProductDescription": "George 21In Donkey",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.85,
      "Margin": 0.46,
      "Elasticity": -1.099808497,
      "Base_Units": 197,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "847, "
    },
    {
      "ID": 848,
      "UpcCode": 9167116194,
      "vendorName": "SRP",
      "ProductDescription": "Gordy 20In Cow",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.76,
      "Margin": 0.46,
      "Elasticity": -0.9514603883,
      "Base_Units": 49,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "848, "
    },
    {
      "ID": 849,
      "UpcCode": 9167116200,
      "vendorName": "SRP",
      "ProductDescription": "Cecil 20In Pig",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.76,
      "Margin": 0.46,
      "Elasticity": -1.277091972,
      "Base_Units": 42,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "849, "
    },
    {
      "ID": 850,
      "UpcCode": 9167116266,
      "vendorName": "SRP",
      "ProductDescription": "Treasure 20In Giraffe",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.76,
      "Margin": 0.46,
      "Elasticity": -0.6000234242,
      "Base_Units": 70,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "850, "
    },
    {
      "ID": 851,
      "UpcCode": 9167116273,
      "vendorName": "SRP",
      "ProductDescription": "Dodger 38In Shark",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 17.7,
      "Margin": 0.41,
      "Elasticity": -0.9866360475,
      "Base_Units": 124,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "851, "
    },
    {
      "ID": 852,
      "UpcCode": 9167116279,
      "vendorName": "SRP",
      "ProductDescription": "Misty 36In Dolphin",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 17.49,
      "Margin": 0.42,
      "Elasticity": -1.00078477,
      "Base_Units": 98,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "852, "
    },
    {
      "ID": 853,
      "UpcCode": 9167116388,
      "vendorName": "SRP",
      "ProductDescription": "Lefty 16.5In 2 Asst. Shaggy Llamas",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 5.59,
      "Margin": 0.63,
      "Elasticity": -1.365761966,
      "Base_Units": 117,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "853, "
    },
    {
      "ID": 854,
      "UpcCode": 9167116389,
      "vendorName": "SRP",
      "ProductDescription": "Lefty 25.5In 2 Asst. Shaggy Llamas",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.68,
      "Margin": 0.47,
      "Elasticity": -0.6127345142,
      "Base_Units": 101,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "854, "
    },
    {
      "ID": 855,
      "UpcCode": 9167116417,
      "vendorName": "SRP",
      "ProductDescription": "Lefty 32In 2 Asst. Shaggy Llamas",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.14,
      "Margin": 0.46,
      "Elasticity": -0.6543441904,
      "Base_Units": 42,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "855, "
    },
    {
      "ID": 856,
      "UpcCode": 9167175789,
      "vendorName": "SRP",
      "ProductDescription": "31In Cuddle Elephant",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 20.58,
      "Margin": 0.31,
      "Elasticity": -0.8243230951,
      "Base_Units": 70,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "856, "
    },
    {
      "ID": 857,
      "UpcCode": 9167176480,
      "vendorName": "SRP",
      "ProductDescription": "Fuzzy Folk Abe 16In Bb Turtle",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 4.34,
      "Margin": 0.71,
      "Elasticity": -1.4639858,
      "Base_Units": 49,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "857, "
    },
    {
      "ID": 858,
      "UpcCode": 9167177056,
      "vendorName": "SRP",
      "ProductDescription": "12In X 12In Plush With 2 Zebra",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 4.84,
      "Margin": 0.63,
      "Elasticity": -0.9993953241,
      "Base_Units": 87,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "858, "
    },
    {
      "ID": 859,
      "UpcCode": 9167177057,
      "vendorName": "SRP",
      "ProductDescription": "12In X 12In Plush With 2 Tiger",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 4.84,
      "Margin": 0.63,
      "Elasticity": -0.8913813498,
      "Base_Units": 96,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "859, "
    },
    {
      "ID": 860,
      "UpcCode": 9167177061,
      "vendorName": "SRP",
      "ProductDescription": "12In X 12In Plush With 2 Penguin",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 4.84,
      "Margin": 0.63,
      "Elasticity": -1.455218079,
      "Base_Units": 136,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "860, "
    },
    {
      "ID": 861,
      "UpcCode": 9167177062,
      "vendorName": "SRP",
      "ProductDescription": "12In X 12In Plush With 2 Shark",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 4.84,
      "Margin": 0.63,
      "Elasticity": -0.6538117299,
      "Base_Units": 40,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "861, "
    },
    {
      "ID": 862,
      "UpcCode": 9167177260,
      "vendorName": "SRP",
      "ProductDescription": "11In Standing Rainbow Shaggy Llama",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.61,
      "Margin": 0.42,
      "Elasticity": -0.6,
      "Base_Units": 233,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "862, "
    },
    {
      "ID": 863,
      "UpcCode": 9167177063,
      "vendorName": "SRP",
      "ProductDescription": "12 X 12 Dolphin Print Pillow",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 4.84,
      "Margin": 0.63,
      "Elasticity": -2.267044636,
      "Base_Units": 174,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "863, "
    },
    {
      "ID": 926,
      "UpcCode": 9167110029,
      "vendorName": "SRP",
      "ProductDescription": "Gordo18In 2 Asst. Gorillas",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 11.07,
      "Margin": 0.45,
      "Elasticity": -1.555539148,
      "Base_Units": 107,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "926, "
    },
    {
      "ID": 927,
      "UpcCode": 9167110135,
      "vendorName": "SRP",
      "ProductDescription": "Mac 20In 2 Asst. Longtail Monkeys",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.44,
      "Margin": 0.48,
      "Elasticity": -1.354825441,
      "Base_Units": 83,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "927, "
    },
    {
      "ID": 928,
      "UpcCode": 9167110136,
      "vendorName": "SRP",
      "ProductDescription": "Mac 24In 2 Asst. Long Tail Monkeys",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 16.49,
      "Margin": 0.34,
      "Elasticity": -1.618204342,
      "Base_Units": 144,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "928, "
    },
    {
      "ID": 929,
      "UpcCode": 9167110141,
      "vendorName": "SRP",
      "ProductDescription": "Alice 20In 2Asst. Long Tail Unicorns",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.44,
      "Margin": 0.48,
      "Elasticity": -1.080616494,
      "Base_Units": 180,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "929, "
    },
    {
      "ID": 930,
      "UpcCode": 9167110178,
      "vendorName": "SRP",
      "ProductDescription": "Iridescent Light 19.5In 2 Asst. Pandas",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.86,
      "Margin": 0.46,
      "Elasticity": -0.6805071783,
      "Base_Units": 116,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "930, "
    },
    {
      "ID": 931,
      "UpcCode": 9167110184,
      "vendorName": "SRP",
      "ProductDescription": "19.5 2 Asst. Elephants",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.86,
      "Margin": 0.46,
      "Elasticity": -2.427106393,
      "Base_Units": 34,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "931, "
    },
    {
      "ID": 932,
      "UpcCode": 9167110190,
      "vendorName": "SRP",
      "ProductDescription": "Comic Sparkles 20In 2 Asst. Giraffes",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.57,
      "Margin": 0.47,
      "Elasticity": -1.22558725,
      "Base_Units": 147,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "932, "
    },
    {
      "ID": 933,
      "UpcCode": 9167110207,
      "vendorName": "SRP",
      "ProductDescription": "Rainbow Sherbet 32In Cuddle Bear",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 17.29,
      "Margin": 0.42,
      "Elasticity": -1.669915521,
      "Base_Units": 224,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "933, "
    },
    {
      "ID": 934,
      "UpcCode": 9167110212,
      "vendorName": "SRP",
      "ProductDescription": "19.5In 2 Asst Sitting Animals",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.46,
      "Margin": 0.48,
      "Elasticity": -1.464368329,
      "Base_Units": 171,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "934, "
    },
    {
      "ID": 935,
      "UpcCode": 9167110224,
      "vendorName": "SRP",
      "ProductDescription": "Skip 19.5In 2 Asst. Sitting Dogs",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.46,
      "Margin": 0.48,
      "Elasticity": -1.204784632,
      "Base_Units": 101,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "935, "
    },
    {
      "ID": 936,
      "UpcCode": 9167110236,
      "vendorName": "SRP",
      "ProductDescription": "Donny 23In 4 Asst. Printed Dinos",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.58,
      "Margin": 0.47,
      "Elasticity": -1.265930483,
      "Base_Units": 230,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "936, "
    },
    {
      "ID": 937,
      "UpcCode": 9167110237,
      "vendorName": "SRP",
      "ProductDescription": "Donny 27In 4 Asst. Printed Dinos",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 16.12,
      "Margin": 0.35,
      "Elasticity": -0.6,
      "Base_Units": 228,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "937, "
    },
    {
      "ID": 938,
      "UpcCode": 9167110238,
      "vendorName": "SRP",
      "ProductDescription": "Donny 32In 4 Asst. Printed Dinos",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 22.35,
      "Margin": 0.25,
      "Elasticity": -1.264996157,
      "Base_Units": 208,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "938, "
    },
    {
      "ID": 939,
      "UpcCode": 9167110289,
      "vendorName": "SRP",
      "ProductDescription": "20In Rainbow Sherbet Unicorn",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5.18,
      "Margin": 0.74,
      "Elasticity": -1.533169922,
      "Base_Units": 64,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "939, "
    },
    {
      "ID": 940,
      "UpcCode": 9167110291,
      "vendorName": "SRP",
      "ProductDescription": "20In Rainbow Sherbet Bear",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5.18,
      "Margin": 0.74,
      "Elasticity": -1.613184923,
      "Base_Units": 243,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "940, "
    },
    {
      "ID": 941,
      "UpcCode": 9167110293,
      "vendorName": "SRP",
      "ProductDescription": "20In Rainbow Sherbet Monkey",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5.18,
      "Margin": 0.74,
      "Elasticity": -0.986543311,
      "Base_Units": 151,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Plush Toys",
      "FIELD15": "941, "
    },
    {
      "ID": 475,
      "UpcCode": 9897411406,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Cool Rag",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.95,
      "Margin": 0.5,
      "Elasticity": -1.575621386,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "475, "
    },
    {
      "ID": 476,
      "UpcCode": 9897427042,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hivis Ear Plugs",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1,
      "Margin": 0.5,
      "Elasticity": -1.681549984,
      "Base_Units": 248,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "476, "
    },
    {
      "ID": 483,
      "UpcCode": 3936403880,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Brown Mayfly 2Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.8,
      "Margin": 0.4,
      "Elasticity": -1.267678843,
      "Base_Units": 46,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "483, "
    },
    {
      "ID": 484,
      "UpcCode": 3936403892,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Wooly Bugger Fly 2Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.8,
      "Margin": 0.4,
      "Elasticity": -1.472030238,
      "Base_Units": 186,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "484, "
    },
    {
      "ID": 485,
      "UpcCode": 3936410521,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "1.75In Bobbers 2Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 1.79,
      "Unit_Cost": 0.95,
      "Margin": 0.47,
      "Elasticity": -0.8637730831,
      "Base_Units": 47,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "485, "
    },
    {
      "ID": 486,
      "UpcCode": 3936411026,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Telescopic Spinning Combo W Kit",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 15.5,
      "Margin": 0.38,
      "Elasticity": -1.269837661,
      "Base_Units": 113,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "486, "
    },
    {
      "ID": 487,
      "UpcCode": 3936411028,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Telescopic Spincast Combo W Kit",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 15.5,
      "Margin": 0.38,
      "Elasticity": -1.067630512,
      "Base_Units": 220,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "487, "
    },
    {
      "ID": 488,
      "UpcCode": 3936411217,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Snelled Hook Size 6",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 0.99,
      "Unit_Cost": 0.6,
      "Margin": 0.39,
      "Elasticity": -0.7007525439,
      "Base_Units": 115,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "488, "
    },
    {
      "ID": 489,
      "UpcCode": 1389344593,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Lures Asst Colors",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.65,
      "Margin": 0.47,
      "Elasticity": -1.075681095,
      "Base_Units": 29,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "489, "
    },
    {
      "ID": 521,
      "UpcCode": 79632601698,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Disposable Ear Plugs 10Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 1.95,
      "Margin": 0.51,
      "Elasticity": -1.331250653,
      "Base_Units": 136,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "521, "
    },
    {
      "ID": 593,
      "UpcCode": 2989271923,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "18In 50A To 30A Rv Dogbone Adapter",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 16.5,
      "Margin": 0.34,
      "Elasticity": -0.8875938023,
      "Base_Units": 157,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "593, "
    },
    {
      "ID": 601,
      "UpcCode": 3936415919,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "1.25In Bobbers 3 Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.8,
      "Margin": 0.46,
      "Elasticity": -0.6649954001,
      "Base_Units": 79,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "601, "
    },
    {
      "ID": 602,
      "UpcCode": 3936416394,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Snap Swivel 6Pk Size 3",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.8,
      "Margin": 0.46,
      "Elasticity": -1.564595509,
      "Base_Units": 24,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "602, "
    },
    {
      "ID": 603,
      "UpcCode": 3936416454,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Snap Swivel 6Pk Size 4",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.8,
      "Margin": 0.46,
      "Elasticity": -1.023526178,
      "Base_Units": 70,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "603, "
    },
    {
      "ID": 604,
      "UpcCode": 3936416511,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Snap Swivel 6Pk Size 5",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.8,
      "Margin": 0.46,
      "Elasticity": -1.834095777,
      "Base_Units": 178,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "604, "
    },
    {
      "ID": 605,
      "UpcCode": 3936416966,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Large Sinkers",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.65,
      "Margin": 0.39,
      "Elasticity": -0.7337333694,
      "Base_Units": 93,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "605, "
    },
    {
      "ID": 606,
      "UpcCode": 3936432763,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Value Pack Swivels",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 6.5,
      "Margin": 0.41,
      "Elasticity": -0.8251272222,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "606, "
    },
    {
      "ID": 607,
      "UpcCode": 3936432764,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Value Pack Sinkers",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.95,
      "Margin": 0.4,
      "Elasticity": -1.897051921,
      "Base_Units": 152,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "607, "
    },
    {
      "ID": 608,
      "UpcCode": 3936488312,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Adams Fly 2Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.8,
      "Margin": 0.4,
      "Elasticity": -1.722719023,
      "Base_Units": 105,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "608, "
    },
    {
      "ID": 609,
      "UpcCode": 3936488484,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Royal Coachman Fly 2Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.8,
      "Margin": 0.4,
      "Elasticity": -1.219830803,
      "Base_Units": 216,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "609, "
    },
    {
      "ID": 610,
      "UpcCode": 3936488490,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Black Gnat Fly 2Pk",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.8,
      "Margin": 0.4,
      "Elasticity": -0.7617013067,
      "Base_Units": 129,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "610, "
    },
    {
      "ID": 689,
      "UpcCode": 4650077205,
      "vendorName": "McLane",
      "ProductDescription": "Off Deep Woods Insect Repellent Towelet",
      "BrandName": "OFF",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.67,
      "Margin": 0.55,
      "Elasticity": -1.313168371,
      "Base_Units": 10,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Seasonal",
      "FIELD15": "689, "
    },
    {
      "ID": 120,
      "UpcCode": 84333210004,
      "vendorName": "DAS",
      "ProductDescription": "Tee Eagle Country Navy 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.665619651,
      "Base_Units": 166,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "120, "
    },
    {
      "ID": 121,
      "UpcCode": 84333210006,
      "vendorName": "DAS",
      "ProductDescription": "Tee Ride Til I Die Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8497090695,
      "Base_Units": 160,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "121, "
    },
    {
      "ID": 122,
      "UpcCode": 84333210008,
      "vendorName": "DAS",
      "ProductDescription": "Tee Ride Til I Die Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.11699083,
      "Base_Units": 141,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "122, "
    },
    {
      "ID": 123,
      "UpcCode": 84333210009,
      "vendorName": "DAS",
      "ProductDescription": "Tee Ride Til I Die Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.37789471,
      "Base_Units": 27,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "123, "
    },
    {
      "ID": 124,
      "UpcCode": 84333210010,
      "vendorName": "DAS",
      "ProductDescription": "Tee Ride Til I Die Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.112960347,
      "Base_Units": 121,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "124, "
    },
    {
      "ID": 125,
      "UpcCode": 84333210011,
      "vendorName": "DAS",
      "ProductDescription": "Tee Midnight Rider Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.571016562,
      "Base_Units": 39,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "125, "
    },
    {
      "ID": 126,
      "UpcCode": 84333210012,
      "vendorName": "DAS",
      "ProductDescription": "Tee Midnight Rider Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.728710944,
      "Base_Units": 24,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "126, "
    },
    {
      "ID": 127,
      "UpcCode": 84333210013,
      "vendorName": "DAS",
      "ProductDescription": "Tee Midnight Rider Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.448064571,
      "Base_Units": 62,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "127, "
    },
    {
      "ID": 128,
      "UpcCode": 84333210015,
      "vendorName": "DAS",
      "ProductDescription": "Tee Midnight Rider Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.183796018,
      "Base_Units": 149,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "128, "
    },
    {
      "ID": 129,
      "UpcCode": 84333210016,
      "vendorName": "DAS",
      "ProductDescription": "Tee Truckin Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.03924168,
      "Base_Units": 229,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "129, "
    },
    {
      "ID": 130,
      "UpcCode": 84333210017,
      "vendorName": "DAS",
      "ProductDescription": "Tee Truckin Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.737764084,
      "Base_Units": 136,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "130, "
    },
    {
      "ID": 131,
      "UpcCode": 84333210018,
      "vendorName": "DAS",
      "ProductDescription": "Tee Truckin Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.800443835,
      "Base_Units": 14,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "131, "
    },
    {
      "ID": 132,
      "UpcCode": 84333210021,
      "vendorName": "DAS",
      "ProductDescription": "Tee Wolf Country Charcoal M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.156376209,
      "Base_Units": 155,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "132, "
    },
    {
      "ID": 133,
      "UpcCode": 84333210022,
      "vendorName": "DAS",
      "ProductDescription": "Tee Wolf Country Charcoal L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.598677263,
      "Base_Units": 89,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "133, "
    },
    {
      "ID": 134,
      "UpcCode": 84333210025,
      "vendorName": "DAS",
      "ProductDescription": "Tee Wolf Country Charcoal 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.819914769,
      "Base_Units": 71,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "134, "
    },
    {
      "ID": 135,
      "UpcCode": 84333210026,
      "vendorName": "DAS",
      "ProductDescription": "Tee Skull Wolf Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.102191494,
      "Base_Units": 115,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "135, "
    },
    {
      "ID": 136,
      "UpcCode": 84333210028,
      "vendorName": "DAS",
      "ProductDescription": "Tee Skull Wolf Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8926349105,
      "Base_Units": 26,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "136, "
    },
    {
      "ID": 137,
      "UpcCode": 84333210029,
      "vendorName": "DAS",
      "ProductDescription": "Tee Skull Wolf Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -2.342364287,
      "Base_Units": 33,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "137, "
    },
    {
      "ID": 138,
      "UpcCode": 84333210032,
      "vendorName": "DAS",
      "ProductDescription": "Tee Skull Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8224243539,
      "Base_Units": 28,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "138, "
    },
    {
      "ID": 139,
      "UpcCode": 84333210033,
      "vendorName": "DAS",
      "ProductDescription": "Tee Skull Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.509263592,
      "Base_Units": 22,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "139, "
    },
    {
      "ID": 140,
      "UpcCode": 84333210035,
      "vendorName": "DAS",
      "ProductDescription": "Tee Skull Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.151943017,
      "Base_Units": 94,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "140, "
    },
    {
      "ID": 141,
      "UpcCode": 84333210036,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Eagle Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.7046996041,
      "Base_Units": 104,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "141, "
    },
    {
      "ID": 142,
      "UpcCode": 84333210037,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Eagle Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.256508461,
      "Base_Units": 124,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "142, "
    },
    {
      "ID": 143,
      "UpcCode": 84333210038,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Eagle Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.273469861,
      "Base_Units": 114,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "143, "
    },
    {
      "ID": 144,
      "UpcCode": 84333210043,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Isn T Free Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.84524463,
      "Base_Units": 129,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "144, "
    },
    {
      "ID": 145,
      "UpcCode": 84333210044,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Isn T Free Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -2.534097018,
      "Base_Units": 208,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "145, "
    },
    {
      "ID": 146,
      "UpcCode": 84333210045,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Isn T Free Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.197721553,
      "Base_Units": 137,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "146, "
    },
    {
      "ID": 147,
      "UpcCode": 84333210046,
      "vendorName": "DAS",
      "ProductDescription": "Tee Death Howl Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.391974377,
      "Base_Units": 24,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "147, "
    },
    {
      "ID": 148,
      "UpcCode": 84333210047,
      "vendorName": "DAS",
      "ProductDescription": "Tee Death Howl Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8625286325,
      "Base_Units": 55,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "148, "
    },
    {
      "ID": 149,
      "UpcCode": 84333210049,
      "vendorName": "DAS",
      "ProductDescription": "Tee Death Howl Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.433329899,
      "Base_Units": 240,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "149, "
    },
    {
      "ID": 150,
      "UpcCode": 84333210050,
      "vendorName": "DAS",
      "ProductDescription": "Tee Death Howl Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.386987347,
      "Base_Units": 16,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "150, "
    },
    {
      "ID": 151,
      "UpcCode": 84333210051,
      "vendorName": "DAS",
      "ProductDescription": "Tee Fully Loaded Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.574629463,
      "Base_Units": 103,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "151, "
    },
    {
      "ID": 152,
      "UpcCode": 84333210052,
      "vendorName": "DAS",
      "ProductDescription": "Tee Fully Loaded Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.7895300036,
      "Base_Units": 27,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "152, "
    },
    {
      "ID": 153,
      "UpcCode": 84333210072,
      "vendorName": "DAS",
      "ProductDescription": "Tee Full Throttle Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.855828284,
      "Base_Units": 75,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "153, "
    },
    {
      "ID": 154,
      "UpcCode": 84333210074,
      "vendorName": "DAS",
      "ProductDescription": "Tee Full Throttle Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.6182212854,
      "Base_Units": 89,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "154, "
    },
    {
      "ID": 155,
      "UpcCode": 84333210075,
      "vendorName": "DAS",
      "ProductDescription": "Tee Full Throttle Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.973947771,
      "Base_Units": 81,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "155, "
    },
    {
      "ID": 156,
      "UpcCode": 84333210081,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Trucker Navy M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.505766826,
      "Base_Units": 179,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "156, "
    },
    {
      "ID": 157,
      "UpcCode": 84333210082,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Trucker Navy L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8098191154,
      "Base_Units": 233,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "157, "
    },
    {
      "ID": 158,
      "UpcCode": 84333210083,
      "vendorName": "DAS",
      "ProductDescription": "Tee Freedom Trucker Navy Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8506567101,
      "Base_Units": 131,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "158, "
    },
    {
      "ID": 268,
      "UpcCode": 84333210001,
      "vendorName": "DAS",
      "ProductDescription": "Tee Eagle Country Navy M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8397666864,
      "Base_Units": 185,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "268, "
    },
    {
      "ID": 269,
      "UpcCode": 84333210002,
      "vendorName": "DAS",
      "ProductDescription": "Tee Eagle Country Navy L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8031728626,
      "Base_Units": 24,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "269, "
    },
    {
      "ID": 270,
      "UpcCode": 84333210003,
      "vendorName": "DAS",
      "ProductDescription": "Tee Eagle Country Navy Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.773084563,
      "Base_Units": 82,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "270, "
    },
    {
      "ID": 289,
      "UpcCode": 84333210086,
      "vendorName": "DAS",
      "ProductDescription": "Tee Bullet Proof Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8220516354,
      "Base_Units": 85,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "289, "
    },
    {
      "ID": 290,
      "UpcCode": 84333210087,
      "vendorName": "DAS",
      "ProductDescription": "Tee Bullet Proof Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -2.664108264,
      "Base_Units": 51,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "290, "
    },
    {
      "ID": 291,
      "UpcCode": 84333210088,
      "vendorName": "DAS",
      "ProductDescription": "Tee Bullet Proof Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.206979595,
      "Base_Units": 135,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "291, "
    },
    {
      "ID": 292,
      "UpcCode": 84333210089,
      "vendorName": "DAS",
      "ProductDescription": "Tee Bullet Proof Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.149824582,
      "Base_Units": 16,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "292, "
    },
    {
      "ID": 293,
      "UpcCode": 84333210090,
      "vendorName": "DAS",
      "ProductDescription": "Tee Bullet Proof Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.233809274,
      "Base_Units": 71,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "293, "
    },
    {
      "ID": 294,
      "UpcCode": 84333210091,
      "vendorName": "DAS",
      "ProductDescription": "Tee Live Fast Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.565470711,
      "Base_Units": 145,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "294, "
    },
    {
      "ID": 295,
      "UpcCode": 84333210094,
      "vendorName": "DAS",
      "ProductDescription": "Tee Live Fast Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.567253785,
      "Base_Units": 176,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "295, "
    },
    {
      "ID": 296,
      "UpcCode": 84333210095,
      "vendorName": "DAS",
      "ProductDescription": "Tee Live Fast Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.281592242,
      "Base_Units": 53,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "296, "
    },
    {
      "ID": 297,
      "UpcCode": 84333210096,
      "vendorName": "DAS",
      "ProductDescription": "Tee Hammer Down Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.556692807,
      "Base_Units": 218,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "297, "
    },
    {
      "ID": 298,
      "UpcCode": 84333210097,
      "vendorName": "DAS",
      "ProductDescription": "Tee Hammer Down Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.6197410982,
      "Base_Units": 103,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "298, "
    },
    {
      "ID": 299,
      "UpcCode": 84333210098,
      "vendorName": "DAS",
      "ProductDescription": "Tee Hammer Down Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.8196109295,
      "Base_Units": 42,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "299, "
    },
    {
      "ID": 300,
      "UpcCode": 84333210099,
      "vendorName": "DAS",
      "ProductDescription": "Tee Hammer Down Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.351703823,
      "Base_Units": 92,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "300, "
    },
    {
      "ID": 333,
      "UpcCode": 84333210053,
      "vendorName": "DAS",
      "ProductDescription": "Tee Fully Loaded Black Xl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -2.044861129,
      "Base_Units": 216,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "333, "
    },
    {
      "ID": 334,
      "UpcCode": 84333210054,
      "vendorName": "DAS",
      "ProductDescription": "Tee Fully Loaded Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.300325341,
      "Base_Units": 234,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "334, "
    },
    {
      "ID": 335,
      "UpcCode": 84333210055,
      "vendorName": "DAS",
      "ProductDescription": "Tee Fully Loaded Black 3X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.479230434,
      "Base_Units": 133,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "335, "
    },
    {
      "ID": 336,
      "UpcCode": 84333210056,
      "vendorName": "DAS",
      "ProductDescription": "Tee American Rider Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.227109654,
      "Base_Units": 120,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "336, "
    },
    {
      "ID": 337,
      "UpcCode": 84333210061,
      "vendorName": "DAS",
      "ProductDescription": "Tee Wolf Pack Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.924034321,
      "Base_Units": 128,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "337, "
    },
    {
      "ID": 338,
      "UpcCode": 84333210062,
      "vendorName": "DAS",
      "ProductDescription": "Tee Wolf Pack Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -2.26424693,
      "Base_Units": 53,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "338, "
    },
    {
      "ID": 339,
      "UpcCode": 84333210066,
      "vendorName": "DAS",
      "ProductDescription": "Tee Hell Wolf Black M",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.9928124162,
      "Base_Units": 11,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "339, "
    },
    {
      "ID": 340,
      "UpcCode": 84333210067,
      "vendorName": "DAS",
      "ProductDescription": "Tee Hell Wolf Black L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -1.226646328,
      "Base_Units": 234,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "340, "
    },
    {
      "ID": 341,
      "UpcCode": 84333210069,
      "vendorName": "DAS",
      "ProductDescription": "Tee Hell Wolf Black 2X",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.29,
      "Margin": 0.51,
      "Elasticity": -0.6881958265,
      "Base_Units": 192,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Shirts",
      "FIELD15": "341, "
    },
    {
      "ID": 715,
      "UpcCode": 60870700470,
      "vendorName": "SRP",
      "ProductDescription": "Pugs 360 Readers 1.5",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.67,
      "Margin": 0.76,
      "Elasticity": -0.9989513682,
      "Base_Units": 90,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "715, "
    },
    {
      "ID": 716,
      "UpcCode": 60870700471,
      "vendorName": "SRP",
      "ProductDescription": "Pugs 360 Readers 2.0",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.67,
      "Margin": 0.76,
      "Elasticity": -0.9207498325,
      "Base_Units": 47,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "716, "
    },
    {
      "ID": 717,
      "UpcCode": 60870700472,
      "vendorName": "SRP",
      "ProductDescription": "Pugs 360 Readers 2.5",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.67,
      "Margin": 0.76,
      "Elasticity": -1.299373626,
      "Base_Units": 177,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "717, "
    },
    {
      "ID": 718,
      "UpcCode": 60870700473,
      "vendorName": "SRP",
      "ProductDescription": "Pugs 360 Readers 3.0",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.67,
      "Margin": 0.76,
      "Elasticity": -0.9060483393,
      "Base_Units": 161,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "718, "
    },
    {
      "ID": 749,
      "UpcCode": 60870700639,
      "vendorName": "SRP",
      "ProductDescription": "A12 Active Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 4.9,
      "Margin": 0.75,
      "Elasticity": -1.509748537,
      "Base_Units": 199,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "749, "
    },
    {
      "ID": 750,
      "UpcCode": 60870700640,
      "vendorName": "SRP",
      "ProductDescription": "F8 Fashion Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.67,
      "Margin": 0.76,
      "Elasticity": -0.7670292369,
      "Base_Units": 143,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "750, "
    },
    {
      "ID": 751,
      "UpcCode": 60870700656,
      "vendorName": "SRP",
      "ProductDescription": "L10 Lifestyle Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 4.16,
      "Margin": 0.76,
      "Elasticity": -0.6783920865,
      "Base_Units": 245,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "751, "
    },
    {
      "ID": 752,
      "UpcCode": 60870700697,
      "vendorName": "SRP",
      "ProductDescription": "C10 Classic Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.67,
      "Margin": 0.76,
      "Elasticity": -1.214070806,
      "Base_Units": 135,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "752, "
    },
    {
      "ID": 753,
      "UpcCode": 60870700698,
      "vendorName": "SRP",
      "ProductDescription": "M4 Metal Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.67,
      "Margin": 0.76,
      "Elasticity": -1.134315203,
      "Base_Units": 58,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "753, "
    },
    {
      "ID": 754,
      "UpcCode": 60870700722,
      "vendorName": "SRP",
      "ProductDescription": "L6 Lifestyle Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 4.9,
      "Margin": 0.75,
      "Elasticity": -2.920964735,
      "Base_Units": 186,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "754, "
    },
    {
      "ID": 755,
      "UpcCode": 60870700817,
      "vendorName": "SRP",
      "ProductDescription": "Pr22 Premium Plus Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 8.57,
      "Margin": 0.76,
      "Elasticity": -1.233389134,
      "Base_Units": 43,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "755, "
    },
    {
      "ID": 756,
      "UpcCode": 60870700818,
      "vendorName": "SRP",
      "ProductDescription": "A4 Active Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 4.41,
      "Margin": 0.75,
      "Elasticity": -1.42126036,
      "Base_Units": 189,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "756, "
    },
    {
      "ID": 757,
      "UpcCode": 60870700820,
      "vendorName": "SRP",
      "ProductDescription": "M6 Metal Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 4.41,
      "Margin": 0.75,
      "Elasticity": -1.413385269,
      "Base_Units": 119,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "757, "
    },
    {
      "ID": 758,
      "UpcCode": 60870700821,
      "vendorName": "SRP",
      "ProductDescription": "T4 Trend Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 4.16,
      "Margin": 0.76,
      "Elasticity": -1.105622503,
      "Base_Units": 215,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "758, "
    },
    {
      "ID": 759,
      "UpcCode": 60870700822,
      "vendorName": "SRP",
      "ProductDescription": "Rp1 Ripper Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 22.99,
      "Unit_Cost": 5.63,
      "Margin": 0.76,
      "Elasticity": -1.341193449,
      "Base_Units": 18,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "759, "
    },
    {
      "ID": 760,
      "UpcCode": 60870700824,
      "vendorName": "SRP",
      "ProductDescription": "Rp3 Ripper Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 22.99,
      "Unit_Cost": 5.63,
      "Margin": 0.76,
      "Elasticity": -2.729697783,
      "Base_Units": 90,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "760, "
    },
    {
      "ID": 761,
      "UpcCode": 60870700825,
      "vendorName": "SRP",
      "ProductDescription": "Rp4 Ripper Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 22.99,
      "Unit_Cost": 5.63,
      "Margin": 0.76,
      "Elasticity": -1.009344566,
      "Base_Units": 97,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "761, "
    },
    {
      "ID": 762,
      "UpcCode": 60870700836,
      "vendorName": "SRP",
      "ProductDescription": "Pf1 Flex Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 6.12,
      "Margin": 0.76,
      "Elasticity": -1.039106706,
      "Base_Units": 106,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "762, "
    },
    {
      "ID": 763,
      "UpcCode": 60870700837,
      "vendorName": "SRP",
      "ProductDescription": "Pf2 Flex Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 6.12,
      "Margin": 0.76,
      "Elasticity": -1.344643564,
      "Base_Units": 147,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "763, "
    },
    {
      "ID": 764,
      "UpcCode": 60870713880,
      "vendorName": "SRP",
      "ProductDescription": "T1 Trend Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 4.3,
      "Margin": 0.78,
      "Elasticity": -1.106646552,
      "Base_Units": 14,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "764, "
    },
    {
      "ID": 765,
      "UpcCode": 60870713881,
      "vendorName": "SRP",
      "ProductDescription": "A1 Active Sunglasses",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 4.9,
      "Margin": 0.75,
      "Elasticity": -0.9481408087,
      "Base_Units": 155,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "765, "
    },
    {
      "ID": 770,
      "UpcCode": 60870713724,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns1",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -1.429354063,
      "Base_Units": 66,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "770, "
    },
    {
      "ID": 771,
      "UpcCode": 60870713725,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns2 Blk",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -1.674156545,
      "Base_Units": 92,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "771, "
    },
    {
      "ID": 772,
      "UpcCode": 60870713726,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns2 Cmp",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -1.004682592,
      "Base_Units": 191,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "772, "
    },
    {
      "ID": 773,
      "UpcCode": 60870713729,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns3",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -0.8766571708,
      "Base_Units": 152,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "773, "
    },
    {
      "ID": 774,
      "UpcCode": 60870713731,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns4 Dem",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -0.8289948485,
      "Base_Units": 201,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "774, "
    },
    {
      "ID": 791,
      "UpcCode": 60870713253,
      "vendorName": "SRP",
      "ProductDescription": "Pugs 3Pk Reader 1.5",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 3.18,
      "Margin": 0.76,
      "Elasticity": -0.9969695889,
      "Base_Units": 213,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "791, "
    },
    {
      "ID": 792,
      "UpcCode": 60870713254,
      "vendorName": "SRP",
      "ProductDescription": "Pugs 3Pk Reader 2.0",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 3.18,
      "Margin": 0.76,
      "Elasticity": -0.830183175,
      "Base_Units": 47,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "792, "
    },
    {
      "ID": 793,
      "UpcCode": 60870713255,
      "vendorName": "SRP",
      "ProductDescription": "Pugs 3Pk Reader 2.5",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 3.18,
      "Margin": 0.76,
      "Elasticity": -1.436341771,
      "Base_Units": 235,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "793, "
    },
    {
      "ID": 794,
      "UpcCode": 60870713256,
      "vendorName": "SRP",
      "ProductDescription": "Pugs 3Pk Reader 3.0",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 3.18,
      "Margin": 0.76,
      "Elasticity": -1.327076323,
      "Base_Units": 24,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "794, "
    },
    {
      "ID": 795,
      "UpcCode": 60870700757,
      "vendorName": "SRP",
      "ProductDescription": "Mil Tac 152 A",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 8.57,
      "Margin": 0.76,
      "Elasticity": -1.156777011,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "795, "
    },
    {
      "ID": 820,
      "UpcCode": 60870713732,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns4 Blk",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -1.611770995,
      "Base_Units": 205,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "820, "
    },
    {
      "ID": 821,
      "UpcCode": 60870713733,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns5 Blk",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -3.8,
      "Base_Units": 201,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "821, "
    },
    {
      "ID": 822,
      "UpcCode": 60870713734,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns5 Clr",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -0.8248653899,
      "Base_Units": 89,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "822, "
    },
    {
      "ID": 823,
      "UpcCode": 60870713882,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns6",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -2.295592153,
      "Base_Units": 228,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "823, "
    },
    {
      "ID": 824,
      "UpcCode": 60870713883,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns7",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -1.040766373,
      "Base_Units": 43,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "824, "
    },
    {
      "ID": 825,
      "UpcCode": 60870713884,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns8",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -0.8634435112,
      "Base_Units": 151,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "825, "
    },
    {
      "ID": 826,
      "UpcCode": 60870713885,
      "vendorName": "SRP",
      "ProductDescription": "Pugssg Nowstalgia Ns9",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5.5,
      "Margin": 0.68,
      "Elasticity": -0.90056662,
      "Base_Units": 93,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "826, "
    },
    {
      "ID": 864,
      "UpcCode": 60870700759,
      "vendorName": "SRP",
      "ProductDescription": "Mil Tac 152 C",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 8.57,
      "Margin": 0.76,
      "Elasticity": -1.937927004,
      "Base_Units": 130,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "864, "
    },
    {
      "ID": 865,
      "UpcCode": 60870701334,
      "vendorName": "SRP",
      "ProductDescription": "Mil Tac 152",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 8.57,
      "Margin": 0.76,
      "Elasticity": -1.391454826,
      "Base_Units": 226,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "865, "
    },
    {
      "ID": 866,
      "UpcCode": 60870701240,
      "vendorName": "SRP",
      "ProductDescription": "Polarized Nd 2 19.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 4.9,
      "Margin": 0.75,
      "Elasticity": -1.328789578,
      "Base_Units": 125,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "866, "
    },
    {
      "ID": 867,
      "UpcCode": 60870701241,
      "vendorName": "SRP",
      "ProductDescription": "Polarized Nd 3 19.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 4.9,
      "Margin": 0.75,
      "Elasticity": -0.7971398743,
      "Base_Units": 158,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "867, "
    },
    {
      "ID": 868,
      "UpcCode": 60870701242,
      "vendorName": "SRP",
      "ProductDescription": "Polarized Nd 4 19.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 4.9,
      "Margin": 0.75,
      "Elasticity": -1.567942445,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "868, "
    },
    {
      "ID": 874,
      "UpcCode": 60870700819,
      "vendorName": "SRP",
      "ProductDescription": "F2 Fashion Sunglass",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 4.41,
      "Margin": 0.75,
      "Elasticity": -1.258160328,
      "Base_Units": 242,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "874, "
    },
    {
      "ID": 942,
      "UpcCode": 60870700827,
      "vendorName": "SRP",
      "ProductDescription": "Nd 1 Night Driver 17.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 4.41,
      "Margin": 0.75,
      "Elasticity": -1.316077564,
      "Base_Units": 169,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "942, "
    },
    {
      "ID": 943,
      "UpcCode": 60870700828,
      "vendorName": "SRP",
      "ProductDescription": "Nd 2 Night Driver 17.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 4.41,
      "Margin": 0.75,
      "Elasticity": -1.052038613,
      "Base_Units": 32,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "943, "
    },
    {
      "ID": 944,
      "UpcCode": 60870700829,
      "vendorName": "SRP",
      "ProductDescription": "Nd 3 Night Driver 17.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 4.41,
      "Margin": 0.75,
      "Elasticity": -1.156203064,
      "Base_Units": 28,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "944, "
    },
    {
      "ID": 945,
      "UpcCode": 60870700830,
      "vendorName": "SRP",
      "ProductDescription": "Nd 4 Night Driver 17.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 4.41,
      "Margin": 0.75,
      "Elasticity": -1.114689684,
      "Base_Units": 56,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Sunglasses",
      "FIELD15": "945, "
    },
    {
      "ID": 160,
      "UpcCode": 84562002559,
      "vendorName": "DAS",
      "ProductDescription": "My Arcade Plug N Play Cntlr",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 17.41,
      "Margin": 0.3,
      "Elasticity": -0.9079428682,
      "Base_Units": 130,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "160, "
    },
    {
      "ID": 169,
      "UpcCode": 77204196,
      "vendorName": "DAS",
      "ProductDescription": "Reusable Sticker Pad Habitats",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.015477929,
      "Base_Units": 120,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "169, "
    },
    {
      "ID": 170,
      "UpcCode": 77204199,
      "vendorName": "DAS",
      "ProductDescription": "Reusable Sticker Pad Vehicles",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.247699416,
      "Base_Units": 110,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "170, "
    },
    {
      "ID": 171,
      "UpcCode": 77204200,
      "vendorName": "DAS",
      "ProductDescription": "Jumbo Coloring Pad Animal",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.8235844491,
      "Base_Units": 170,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "171, "
    },
    {
      "ID": 172,
      "UpcCode": 77204205,
      "vendorName": "DAS",
      "ProductDescription": "Jumbo Coloring Pad Vehicles",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.359604219,
      "Base_Units": 189,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "172, "
    },
    {
      "ID": 173,
      "UpcCode": 77204225,
      "vendorName": "DAS",
      "ProductDescription": "Jumbo Coloring Pad Pink 11 X 14",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.6702968681,
      "Base_Units": 108,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "173, "
    },
    {
      "ID": 174,
      "UpcCode": 77204306,
      "vendorName": "DAS",
      "ProductDescription": "Reusable Sticker Pad Princess Castle",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -2.522139118,
      "Base_Units": 11,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "174, "
    },
    {
      "ID": 175,
      "UpcCode": 77205356,
      "vendorName": "DAS",
      "ProductDescription": "Colorblast Princess",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.6,
      "Base_Units": 111,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "175, "
    },
    {
      "ID": 176,
      "UpcCode": 77205357,
      "vendorName": "DAS",
      "ProductDescription": "Colorblast Dinosaur",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.7377876155,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "176, "
    },
    {
      "ID": 177,
      "UpcCode": 77205376,
      "vendorName": "DAS",
      "ProductDescription": "Water Wow Animals",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.4,
      "Margin": 0.43,
      "Elasticity": -0.8450115767,
      "Base_Units": 59,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "177, "
    },
    {
      "ID": 178,
      "UpcCode": 77205389,
      "vendorName": "DAS",
      "ProductDescription": "Water Wow Alphabet",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.9707318251,
      "Base_Units": 31,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "178, "
    },
    {
      "ID": 179,
      "UpcCode": 77208605,
      "vendorName": "DAS",
      "ProductDescription": "Make A Face Sticker Pad Crazy Animals",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.458850512,
      "Base_Units": 54,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "179, "
    },
    {
      "ID": 180,
      "UpcCode": 77209114,
      "vendorName": "DAS",
      "ProductDescription": "Reusable Sticker Pad My Town",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.9297862408,
      "Base_Units": 50,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "180, "
    },
    {
      "ID": 181,
      "UpcCode": 77209115,
      "vendorName": "DAS",
      "ProductDescription": "Reusable Sticker Pad Adventure Pad",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.336082215,
      "Base_Units": 201,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "181, "
    },
    {
      "ID": 182,
      "UpcCode": 77209415,
      "vendorName": "DAS",
      "ProductDescription": "Water Wow Fairy Tale",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.4,
      "Margin": 0.43,
      "Elasticity": -1.583091421,
      "Base_Units": 200,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "182, "
    },
    {
      "ID": 183,
      "UpcCode": 77209416,
      "vendorName": "DAS",
      "ProductDescription": "Water Wow Makeup Manicures",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.9249665643,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "183, "
    },
    {
      "ID": 184,
      "UpcCode": 77209433,
      "vendorName": "DAS",
      "ProductDescription": "Make A Face Princesses",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.6943725353,
      "Base_Units": 218,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "184, "
    },
    {
      "ID": 185,
      "UpcCode": 77209445,
      "vendorName": "DAS",
      "ProductDescription": "Water Wow Under The Sea Water Reveal",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -1.340501884,
      "Base_Units": 104,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "185, "
    },
    {
      "ID": 186,
      "UpcCode": 77209485,
      "vendorName": "DAS",
      "ProductDescription": "Water Wow Farm Connect The Dots",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.4,
      "Margin": 0.43,
      "Elasticity": -0.6,
      "Base_Units": 40,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "186, "
    },
    {
      "ID": 243,
      "UpcCode": 67522038816,
      "vendorName": "DAS",
      "ProductDescription": "Wave Runner  2Toned Ball Skips On Water",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 11.99,
      "Unit_Cost": 5.99,
      "Margin": 0.5,
      "Elasticity": -1.440583379,
      "Base_Units": 219,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "243, "
    },
    {
      "ID": 244,
      "UpcCode": 67522048442,
      "vendorName": "DAS",
      "ProductDescription": "Wave Runner  Mega Sports Ball",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 11.99,
      "Unit_Cost": 6.16,
      "Margin": 0.49,
      "Elasticity": -1.681815555,
      "Base_Units": 55,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "244, "
    },
    {
      "ID": 245,
      "UpcCode": 67522054894,
      "vendorName": "DAS",
      "ProductDescription": "Wave Runner  Galaxy Ball Skips On Water",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 11.99,
      "Unit_Cost": 6.16,
      "Margin": 0.49,
      "Elasticity": -1.578023095,
      "Base_Units": 71,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "245, "
    },
    {
      "ID": 246,
      "UpcCode": 67522055623,
      "vendorName": "DAS",
      "ProductDescription": "Wave Runner  Football",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 11.99,
      "Unit_Cost": 6.16,
      "Margin": 0.49,
      "Elasticity": -1.189221192,
      "Base_Units": 195,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "246, "
    },
    {
      "ID": 247,
      "UpcCode": 67522059029,
      "vendorName": "DAS",
      "ProductDescription": "Wave Runner  Grip It Ball",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 8.62,
      "Margin": 0.49,
      "Elasticity": -1.030140911,
      "Base_Units": 186,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "247, "
    },
    {
      "ID": 259,
      "UpcCode": 77117113700,
      "vendorName": "DAS",
      "ProductDescription": "Fingerlings 6 Assort",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 2.05,
      "Margin": 0.9,
      "Elasticity": -0.9807289652,
      "Base_Units": 217,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "259, "
    },
    {
      "ID": 260,
      "UpcCode": 77117113701,
      "vendorName": "DAS",
      "ProductDescription": "Finn Fingerling Monkey Black",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 12.15,
      "Margin": 0.39,
      "Elasticity": -0.9441751807,
      "Base_Units": 244,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "260, "
    },
    {
      "ID": 261,
      "UpcCode": 77117113702,
      "vendorName": "DAS",
      "ProductDescription": "Sophie Fingerling Monkey White",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 12.15,
      "Margin": 0.39,
      "Elasticity": -1.557469859,
      "Base_Units": 250,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "261, "
    },
    {
      "ID": 262,
      "UpcCode": 77117113703,
      "vendorName": "DAS",
      "ProductDescription": "Boris Finglerling Monkey Blue",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 12.15,
      "Margin": 0.39,
      "Elasticity": -2.214744856,
      "Base_Units": 170,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "262, "
    },
    {
      "ID": 263,
      "UpcCode": 77117113704,
      "vendorName": "DAS",
      "ProductDescription": "Mia Fingerling Monkey Purple",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 12.15,
      "Margin": 0.39,
      "Elasticity": -0.8954126023,
      "Base_Units": 92,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "263, "
    },
    {
      "ID": 264,
      "UpcCode": 77117113705,
      "vendorName": "DAS",
      "ProductDescription": "Bella Fingerling Monkey Pink",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 12.15,
      "Margin": 0.39,
      "Elasticity": -1.373155043,
      "Base_Units": 247,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "264, "
    },
    {
      "ID": 265,
      "UpcCode": 77117113706,
      "vendorName": "DAS",
      "ProductDescription": "Zoe Fingerling Monkey Turquoise",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 12.15,
      "Margin": 0.39,
      "Elasticity": -1.073994717,
      "Base_Units": 172,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "265, "
    },
    {
      "ID": 279,
      "UpcCode": 69420232773,
      "vendorName": "DAS",
      "ProductDescription": "Toy Rc Robotic Battle Spacebot",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 9.72,
      "Margin": 0.35,
      "Elasticity": -1.200686231,
      "Base_Units": 68,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "279, "
    },
    {
      "ID": 305,
      "UpcCode": 3505156580,
      "vendorName": "DAS",
      "ProductDescription": "L.O.L. Surprise Tiny Toys 1A",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 6.81,
      "Margin": 0.32,
      "Elasticity": -0.8289644395,
      "Base_Units": 160,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "305, "
    },
    {
      "ID": 306,
      "UpcCode": 3989732741,
      "vendorName": "DAS",
      "ProductDescription": "Mini Toddler Young Anna",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.388328698,
      "Base_Units": 152,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "306, "
    },
    {
      "ID": 307,
      "UpcCode": 3989732742,
      "vendorName": "DAS",
      "ProductDescription": "Mini Toddler Young Elsa",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.383013901,
      "Base_Units": 215,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "307, "
    },
    {
      "ID": 308,
      "UpcCode": 3989756106,
      "vendorName": "DAS",
      "ProductDescription": "Moana",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.116256664,
      "Base_Units": 82,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "308, "
    },
    {
      "ID": 309,
      "UpcCode": 3989770894,
      "vendorName": "DAS",
      "ProductDescription": "Ariel",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.8000088717,
      "Base_Units": 157,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "309, "
    },
    {
      "ID": 310,
      "UpcCode": 3989770895,
      "vendorName": "DAS",
      "ProductDescription": "Ariel Mermaid",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.08456857,
      "Base_Units": 229,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "310, "
    },
    {
      "ID": 311,
      "UpcCode": 3989784461,
      "vendorName": "DAS",
      "ProductDescription": "Aurora",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.8357699679,
      "Base_Units": 119,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "311, "
    },
    {
      "ID": 312,
      "UpcCode": 3989784613,
      "vendorName": "DAS",
      "ProductDescription": "Belle Yellow Dress",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.281141447,
      "Base_Units": 83,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "312, "
    },
    {
      "ID": 313,
      "UpcCode": 3989784615,
      "vendorName": "DAS",
      "ProductDescription": "Belle Blue Dress",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.871671317,
      "Base_Units": 134,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "313, "
    },
    {
      "ID": 314,
      "UpcCode": 3989784616,
      "vendorName": "DAS",
      "ProductDescription": "Belle Red Dress",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.729747927,
      "Base_Units": 161,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "314, "
    },
    {
      "ID": 315,
      "UpcCode": 3989784626,
      "vendorName": "DAS",
      "ProductDescription": "Cinderella Blue Dress",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.397368365,
      "Base_Units": 93,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "315, "
    },
    {
      "ID": 316,
      "UpcCode": 3989784627,
      "vendorName": "DAS",
      "ProductDescription": "Cinderella Pink Dress",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.9416425971,
      "Base_Units": 12,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "316, "
    },
    {
      "ID": 317,
      "UpcCode": 3989784628,
      "vendorName": "DAS",
      "ProductDescription": "Jasmine",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.737072896,
      "Base_Units": 55,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "317, "
    },
    {
      "ID": 318,
      "UpcCode": 3989784629,
      "vendorName": "DAS",
      "ProductDescription": "Merida",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.9698479013,
      "Base_Units": 74,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "318, "
    },
    {
      "ID": 319,
      "UpcCode": 3989784630,
      "vendorName": "DAS",
      "ProductDescription": "Mulan",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.944062706,
      "Base_Units": 98,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "319, "
    },
    {
      "ID": 320,
      "UpcCode": 3989784632,
      "vendorName": "DAS",
      "ProductDescription": "Rapunzel",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.8181314092,
      "Base_Units": 242,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "320, "
    },
    {
      "ID": 321,
      "UpcCode": 3989784633,
      "vendorName": "DAS",
      "ProductDescription": "Snow White",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.007072552,
      "Base_Units": 76,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "321, "
    },
    {
      "ID": 322,
      "UpcCode": 3989784634,
      "vendorName": "DAS",
      "ProductDescription": "Tiana",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.9560093759,
      "Base_Units": 17,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "322, "
    },
    {
      "ID": 323,
      "UpcCode": 19299520480,
      "vendorName": "DAS",
      "ProductDescription": "Elsa Travel Mini Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -2.310641387,
      "Base_Units": 42,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "323, "
    },
    {
      "ID": 324,
      "UpcCode": 19299520481,
      "vendorName": "DAS",
      "ProductDescription": "Anna Travel Mini Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.550243729,
      "Base_Units": 152,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "324, "
    },
    {
      "ID": 325,
      "UpcCode": 19299520482,
      "vendorName": "DAS",
      "ProductDescription": "Elsa Act 1 Mini Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.6808920182,
      "Base_Units": 242,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "325, "
    },
    {
      "ID": 326,
      "UpcCode": 19299520483,
      "vendorName": "DAS",
      "ProductDescription": "Anna Act 1 Mini Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.8784645032,
      "Base_Units": 241,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "326, "
    },
    {
      "ID": 327,
      "UpcCode": 19299521141,
      "vendorName": "DAS",
      "ProductDescription": "Mini Anna Epilogue Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.183526575,
      "Base_Units": 214,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "327, "
    },
    {
      "ID": 328,
      "UpcCode": 19299521192,
      "vendorName": "DAS",
      "ProductDescription": "Mini Elsa Epilogue Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.9797234173,
      "Base_Units": 134,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "328, "
    },
    {
      "ID": 329,
      "UpcCode": 19299521197,
      "vendorName": "DAS",
      "ProductDescription": "Mini Elsa Purple Nightgown Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.146916127,
      "Base_Units": 109,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "329, "
    },
    {
      "ID": 330,
      "UpcCode": 19299521198,
      "vendorName": "DAS",
      "ProductDescription": "Mini Kristoff Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.8941968691,
      "Base_Units": 48,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "330, "
    },
    {
      "ID": 331,
      "UpcCode": 19299521199,
      "vendorName": "DAS",
      "ProductDescription": "Mini Sven Doll",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.8790246704,
      "Base_Units": 13,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "331, "
    },
    {
      "ID": 332,
      "UpcCode": 19299521201,
      "vendorName": "DAS",
      "ProductDescription": "Mini Olaf",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -1.29720107,
      "Base_Units": 223,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "332, "
    },
    {
      "ID": 367,
      "UpcCode": 81001099074,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Care Bears Series 2",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.37,
      "Margin": 0.46,
      "Elasticity": -1.105206609,
      "Base_Units": 16,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "367, "
    },
    {
      "ID": 370,
      "UpcCode": 85494100783,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Lava Lamp",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.37,
      "Margin": 0.46,
      "Elasticity": -1.186782649,
      "Base_Units": 138,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "370, "
    },
    {
      "ID": 379,
      "UpcCode": 81001099060,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Nerf Basketball",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.53,
      "Margin": 0.55,
      "Elasticity": -1.948007694,
      "Base_Units": 183,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "379, "
    },
    {
      "ID": 380,
      "UpcCode": 81001099077,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Mattel Viewmaster",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.37,
      "Margin": 0.46,
      "Elasticity": -1.61817424,
      "Base_Units": 20,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "380, "
    },
    {
      "ID": 381,
      "UpcCode": 81001099121,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Operation",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.65,
      "Margin": 0.43,
      "Elasticity": -0.6,
      "Base_Units": 129,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "381, "
    },
    {
      "ID": 382,
      "UpcCode": 81001099157,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Cornhole",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.37,
      "Margin": 0.46,
      "Elasticity": -2.476076851,
      "Base_Units": 42,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "382, "
    },
    {
      "ID": 383,
      "UpcCode": 85494100763,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Uno",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.53,
      "Margin": 0.55,
      "Elasticity": -1.163332519,
      "Base_Units": 239,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "383, "
    },
    {
      "ID": 387,
      "UpcCode": 85942100514,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Rubiks Cube",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.65,
      "Margin": 0.43,
      "Elasticity": -0.6,
      "Base_Units": 229,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "387, "
    },
    {
      "ID": 388,
      "UpcCode": 85942100515,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Etch A Sketch",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.37,
      "Margin": 0.46,
      "Elasticity": -0.9357828427,
      "Base_Units": 203,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "388, "
    },
    {
      "ID": 389,
      "UpcCode": 81001099007,
      "vendorName": "DAS",
      "ProductDescription": "Micro Arcade Pacman",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 15.91,
      "Margin": 0.36,
      "Elasticity": -1.430283026,
      "Base_Units": 208,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "389, "
    },
    {
      "ID": 390,
      "UpcCode": 81001099056,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Magic 8 Ball Tie Dye",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.53,
      "Margin": 0.55,
      "Elasticity": -2.275109277,
      "Base_Units": 178,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "390, "
    },
    {
      "ID": 391,
      "UpcCode": 81001099091,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Slinky Dog",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.65,
      "Margin": 0.43,
      "Elasticity": -0.9872917196,
      "Base_Units": 204,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "391, "
    },
    {
      "ID": 392,
      "UpcCode": 85494100703,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Rock Em Sock Em Robots",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.65,
      "Margin": 0.43,
      "Elasticity": -1.095673107,
      "Base_Units": 146,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "392, "
    },
    {
      "ID": 393,
      "UpcCode": 85494100717,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Lincoln Logs",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.65,
      "Margin": 0.43,
      "Elasticity": -1.56786514,
      "Base_Units": 107,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "393, "
    },
    {
      "ID": 394,
      "UpcCode": 85494100721,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Lite Brite",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.65,
      "Margin": 0.43,
      "Elasticity": -0.6041420922,
      "Base_Units": 149,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "394, "
    },
    {
      "ID": 395,
      "UpcCode": 85494100756,
      "vendorName": "DAS",
      "ProductDescription": "Worlds Smallest Crocodile Dentist Game",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.37,
      "Margin": 0.46,
      "Elasticity": -1.518086937,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "395, "
    },
    {
      "ID": 407,
      "UpcCode": 81001099008,
      "vendorName": "DAS",
      "ProductDescription": "Micro Arcade Galaga Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 14,
      "Margin": 0.44,
      "Elasticity": -1.071961961,
      "Base_Units": 158,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "407, "
    },
    {
      "ID": 408,
      "UpcCode": 81001099010,
      "vendorName": "DAS",
      "ProductDescription": "Micro Arcade Frogger Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 15.91,
      "Margin": 0.36,
      "Elasticity": -1.310433422,
      "Base_Units": 182,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "408, "
    },
    {
      "ID": 409,
      "UpcCode": 81001099012,
      "vendorName": "DAS",
      "ProductDescription": "Micro Arcade Dig Dug Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 14,
      "Margin": 0.44,
      "Elasticity": -0.8445043769,
      "Base_Units": 85,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "409, "
    },
    {
      "ID": 420,
      "UpcCode": 19305200426,
      "vendorName": "DAS",
      "ProductDescription": "Lola",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -0.8181809078,
      "Base_Units": 154,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "420, "
    },
    {
      "ID": 421,
      "UpcCode": 19305200427,
      "vendorName": "DAS",
      "ProductDescription": "Kai",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -0.8199900216,
      "Base_Units": 46,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "421, "
    },
    {
      "ID": 422,
      "UpcCode": 19305200428,
      "vendorName": "DAS",
      "ProductDescription": "Pablo",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -1.508324332,
      "Base_Units": 149,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "422, "
    },
    {
      "ID": 423,
      "UpcCode": 19305200429,
      "vendorName": "DAS",
      "ProductDescription": "Felix",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -0.7060100046,
      "Base_Units": 216,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "423, "
    },
    {
      "ID": 424,
      "UpcCode": 19305200430,
      "vendorName": "DAS",
      "ProductDescription": "Benji",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -0.6,
      "Base_Units": 147,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "424, "
    },
    {
      "ID": 425,
      "UpcCode": 19305200431,
      "vendorName": "DAS",
      "ProductDescription": "Ella",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -1.966980916,
      "Base_Units": 205,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "425, "
    },
    {
      "ID": 426,
      "UpcCode": 19305200432,
      "vendorName": "DAS",
      "ProductDescription": "Roxy",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -1.733330476,
      "Base_Units": 146,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "426, "
    },
    {
      "ID": 427,
      "UpcCode": 19305200433,
      "vendorName": "DAS",
      "ProductDescription": "Jinx",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -1.340279151,
      "Base_Units": 152,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "427, "
    },
    {
      "ID": 428,
      "UpcCode": 19305200434,
      "vendorName": "DAS",
      "ProductDescription": "Summer",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -1.106217157,
      "Base_Units": 143,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "428, "
    },
    {
      "ID": 429,
      "UpcCode": 7500617127,
      "vendorName": "DAS",
      "ProductDescription": "5 Pack Princess Doll Set 11.5 Inch",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9.72,
      "Margin": 0.51,
      "Elasticity": -0.8092402083,
      "Base_Units": 142,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "429, "
    },
    {
      "ID": 430,
      "UpcCode": 7500617201,
      "vendorName": "DAS",
      "ProductDescription": "Fairy Dolls 11.5 Inch Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.83,
      "Margin": 0.53,
      "Elasticity": -0.8116368764,
      "Base_Units": 176,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "430, "
    },
    {
      "ID": 431,
      "UpcCode": 8222824414,
      "vendorName": "DAS",
      "ProductDescription": "11 Battery Operated Press Go Shark",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 9.6,
      "Margin": 0.43,
      "Elasticity": -0.8610609315,
      "Base_Units": 13,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "431, "
    },
    {
      "ID": 432,
      "UpcCode": 84189310602,
      "vendorName": "DAS",
      "ProductDescription": "2Pk Offroad Truck Friction Power",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.24,
      "Margin": 0.47,
      "Elasticity": -1.452302521,
      "Base_Units": 14,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "432, "
    },
    {
      "ID": 433,
      "UpcCode": 3989784652,
      "vendorName": "DAS",
      "ProductDescription": "Pocahontas",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.9113289899,
      "Base_Units": 178,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "433, "
    },
    {
      "ID": 439,
      "UpcCode": 10845218029347,
      "vendorName": "DAS",
      "ProductDescription": "Zuru 5 Surprise Mixed Series 2 24Pc Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 191.76,
      "Unit_Cost": 100.8,
      "Margin": 0.47,
      "Elasticity": -1.102480866,
      "Base_Units": 57,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "439, "
    },
    {
      "ID": 442,
      "UpcCode": 19305200435,
      "vendorName": "DAS",
      "ProductDescription": "Charlie",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -1.77358619,
      "Base_Units": 71,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "442, "
    },
    {
      "ID": 443,
      "UpcCode": 19305200436,
      "vendorName": "DAS",
      "ProductDescription": "Deeno",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.08,
      "Margin": 0.36,
      "Elasticity": -0.9959367056,
      "Base_Units": 245,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "443, "
    },
    {
      "ID": 444,
      "UpcCode": 10193052002508,
      "vendorName": "DAS",
      "ProductDescription": "Zuru Cocoplushcones Series 1",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 95.88,
      "Unit_Cost": 60.6,
      "Margin": 0.37,
      "Elasticity": -1.421150945,
      "Base_Units": 95,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "444, "
    },
    {
      "ID": 694,
      "UpcCode": 84277916805,
      "vendorName": "Group Sales",
      "ProductDescription": "Classic Games Display",
      "BrandName": "RMS",
      "CaseSize": 0,
      "Unit_Price": 579.42,
      "Unit_Cost": 305.35,
      "Margin": 0.47,
      "Elasticity": -0.7569552647,
      "Base_Units": 111,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "694, "
    },
    {
      "ID": 947,
      "UpcCode": 60870700953,
      "vendorName": "SRP",
      "ProductDescription": "3.99 Toy",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 1.6,
      "Margin": 0.6,
      "Elasticity": -1.183856291,
      "Base_Units": 235,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "947, "
    },
    {
      "ID": 948,
      "UpcCode": 60870700954,
      "vendorName": "SRP",
      "ProductDescription": "4.99 Toy",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2,
      "Margin": 0.6,
      "Elasticity": -1.282244775,
      "Base_Units": 77,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "948, "
    },
    {
      "ID": 949,
      "UpcCode": 60870700955,
      "vendorName": "SRP",
      "ProductDescription": "6.99 Toy",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 2.8,
      "Margin": 0.6,
      "Elasticity": -1.01454815,
      "Base_Units": 249,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "949, "
    },
    {
      "ID": 950,
      "UpcCode": 60870700956,
      "vendorName": "SRP",
      "ProductDescription": "9.99 Toy",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4,
      "Margin": 0.6,
      "Elasticity": -1.19659859,
      "Base_Units": 148,
      "Level1": "General Merch",
      "Level2": "General Merch",
      "Level3": "Toys",
      "FIELD15": "950, "
    },
    {
      "ID": 197,
      "UpcCode": 87861509221,
      "vendorName": "DAS",
      "ProductDescription": "Set In Ear Sport Earbud W Mic Vc Gr Cr",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 36.99,
      "Unit_Cost": 19.78,
      "Margin": 0.47,
      "Elasticity": -1.063457722,
      "Base_Units": 68,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "197, "
    },
    {
      "ID": 248,
      "UpcCode": 88944600701,
      "vendorName": "DAS",
      "ProductDescription": "Super Slimfit Earbud Hyperlite Black",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 17.5,
      "Margin": 0.42,
      "Elasticity": -1.294644298,
      "Base_Units": 75,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "248, "
    },
    {
      "ID": 249,
      "UpcCode": 88944600702,
      "vendorName": "DAS",
      "ProductDescription": "Superslim Fitting Earbud Hyperlite Wht",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 17.5,
      "Margin": 0.42,
      "Elasticity": -1.361382401,
      "Base_Units": 125,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "249, "
    },
    {
      "ID": 372,
      "UpcCode": 8006899957,
      "vendorName": "DAS",
      "ProductDescription": "Micro True Wireless Pro 48 Pc Floor Disp",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 1919.52,
      "Unit_Cost": 940.8,
      "Margin": 0.51,
      "Elasticity": -1.901968268,
      "Base_Units": 22,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "372, "
    },
    {
      "ID": 440,
      "UpcCode": 87861509995,
      "vendorName": "DAS",
      "ProductDescription": "Cassette Wireless Cobalt Blue",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 44.99,
      "Unit_Cost": 24.99,
      "Margin": 0.44,
      "Elasticity": -0.7941034936,
      "Base_Units": 148,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "440, "
    },
    {
      "ID": 675,
      "UpcCode": 4683807893,
      "vendorName": "DAS",
      "ProductDescription": "Hafx103M Xx Pdq",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 549.75,
      "Unit_Cost": 304.5,
      "Margin": 0.45,
      "Elasticity": -0.9878951879,
      "Base_Units": 112,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "675, "
    },
    {
      "ID": 676,
      "UpcCode": 4683807944,
      "vendorName": "DAS",
      "ProductDescription": "On Ear Foldable Wireless Headphones Carb",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 56.99,
      "Unit_Cost": 31.36,
      "Margin": 0.45,
      "Elasticity": -1.123916653,
      "Base_Units": 125,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "676, "
    },
    {
      "ID": 677,
      "UpcCode": 4683800021,
      "vendorName": "DAS",
      "ProductDescription": "JVC Gumy Wired Inner Ear Headphones With",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.45,
      "Margin": 0.5,
      "Elasticity": -0.7327183381,
      "Base_Units": 120,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "677, "
    },
    {
      "ID": 678,
      "UpcCode": 4683808155,
      "vendorName": "DAS",
      "ProductDescription": "JVC Inner Ear Neck Band Headset Black",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 28.34,
      "Margin": 0.43,
      "Elasticity": -1.46002302,
      "Base_Units": 177,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "678, "
    },
    {
      "ID": 679,
      "UpcCode": 4683807890,
      "vendorName": "DAS",
      "ProductDescription": "Hafx5 Gumy Dump Bin",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 879.2,
      "Unit_Cost": 476,
      "Margin": 0.46,
      "Elasticity": -0.8565250629,
      "Base_Units": 248,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "679, "
    },
    {
      "ID": 680,
      "UpcCode": 4683807891,
      "vendorName": "DAS",
      "ProductDescription": "Hafx5 Gumy Counter Display",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 274.75,
      "Unit_Cost": 148.75,
      "Margin": 0.46,
      "Elasticity": -0.8699787976,
      "Base_Units": 16,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "680, "
    },
    {
      "ID": 681,
      "UpcCode": 4683807082,
      "vendorName": "DAS",
      "ProductDescription": "Wired Gumy Sport Headset With Mic Black",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.12,
      "Margin": 0.52,
      "Elasticity": -0.7282037904,
      "Base_Units": 19,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "681, "
    },
    {
      "ID": 827,
      "UpcCode": 8079842795,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Tws",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 9.5,
      "Margin": 0.62,
      "Elasticity": -0.907936471,
      "Base_Units": 51,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "827, "
    },
    {
      "ID": 828,
      "UpcCode": 8079842796,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Aux Cbl Spruce",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 3.5,
      "Margin": 0.65,
      "Elasticity": -2.02431521,
      "Base_Units": 136,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Audio",
      "FIELD15": "828, "
    },
    {
      "ID": 43,
      "UpcCode": 81542502223,
      "vendorName": "DAS",
      "ProductDescription": "LG Tone Triumph Bt Wireless Headset",
      "BrandName": "LG",
      "CaseSize": 0,
      "Unit_Price": 54.99,
      "Unit_Cost": 36.29,
      "Margin": 0.34,
      "Elasticity": -1.443904802,
      "Base_Units": 25,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "43, "
    },
    {
      "ID": 44,
      "UpcCode": 81542502252,
      "vendorName": "DAS",
      "ProductDescription": "LG Tone Style Hbs Sl5 Bt Wireless Hdse",
      "BrandName": "LG",
      "CaseSize": 0,
      "Unit_Price": 99.99,
      "Unit_Cost": 65.67,
      "Margin": 0.34,
      "Elasticity": -1.221837629,
      "Base_Units": 195,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "44, "
    },
    {
      "ID": 45,
      "UpcCode": 81542502253,
      "vendorName": "DAS",
      "ProductDescription": "LG Tone Flex Hbs Xl7 Bt Wireless Hdset",
      "BrandName": "LG",
      "CaseSize": 0,
      "Unit_Price": 149.99,
      "Unit_Cost": 98.97,
      "Margin": 0.34,
      "Elasticity": -1.075819539,
      "Base_Units": 218,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "45, "
    },
    {
      "ID": 46,
      "UpcCode": 81542502280,
      "vendorName": "DAS",
      "ProductDescription": "LG Tone Style Hbs Sl5 Bt Wireless Hdse",
      "BrandName": "LG",
      "CaseSize": 0,
      "Unit_Price": 99.99,
      "Unit_Cost": 66,
      "Margin": 0.34,
      "Elasticity": -1.069432443,
      "Base_Units": 141,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "46, "
    },
    {
      "ID": 187,
      "UpcCode": 81107103208,
      "vendorName": "DAS",
      "ProductDescription": "Opencomm Noise Canceling Headset",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 179.99,
      "Unit_Cost": 100.29,
      "Margin": 0.44,
      "Elasticity": -0.664726004,
      "Base_Units": 213,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "187, "
    },
    {
      "ID": 232,
      "UpcCode": 63375514294,
      "vendorName": "DAS",
      "ProductDescription": "True Wireless Sportfit Earbuds",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 14.03,
      "Margin": 0.53,
      "Elasticity": -1.544094908,
      "Base_Units": 145,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "232, "
    },
    {
      "ID": 665,
      "UpcCode": 5003638249,
      "vendorName": "DAS",
      "ProductDescription": "JBL Vibe 100Tws Inear Headphones Purple",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 37.14,
      "Margin": 0.38,
      "Elasticity": -0.7438437859,
      "Base_Units": 65,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "665, "
    },
    {
      "ID": 666,
      "UpcCode": 5003637747,
      "vendorName": "DAS",
      "ProductDescription": "JBL Endurance Peak Ii Bt Headphones Blue",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 109.99,
      "Unit_Cost": 73.77,
      "Margin": 0.33,
      "Elasticity": -0.956730104,
      "Base_Units": 31,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "666, "
    },
    {
      "ID": 667,
      "UpcCode": 5003637953,
      "vendorName": "DAS",
      "ProductDescription": "JBL Tour Pro True Bt Earbuds Anc Black",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 199.99,
      "Unit_Cost": 139.3,
      "Margin": 0.3,
      "Elasticity": -0.9542864404,
      "Base_Units": 135,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "667, "
    },
    {
      "ID": 668,
      "UpcCode": 5003638005,
      "vendorName": "DAS",
      "ProductDescription": "JBL Tour One Headphone Overear Bt Anc Bk",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 299.99,
      "Unit_Cost": 199,
      "Margin": 0.34,
      "Elasticity": -1.198177816,
      "Base_Units": 109,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "668, "
    },
    {
      "ID": 669,
      "UpcCode": 5003638061,
      "vendorName": "DAS",
      "ProductDescription": "JBL Tune Onear Bt Headphones 510Bt Black",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 33.16,
      "Margin": 0.45,
      "Elasticity": -2.215975995,
      "Base_Units": 143,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "669, "
    },
    {
      "ID": 670,
      "UpcCode": 5003638062,
      "vendorName": "DAS",
      "ProductDescription": "JBL Tune Onear Bt Headphones 510Bt White",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 33.16,
      "Margin": 0.45,
      "Elasticity": -1.304851889,
      "Base_Units": 55,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "670, "
    },
    {
      "ID": 671,
      "UpcCode": 5003638247,
      "vendorName": "DAS",
      "ProductDescription": "JBL Vibe 100 True Wireless Earbuds Black",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 37.14,
      "Margin": 0.38,
      "Elasticity": -0.6,
      "Base_Units": 149,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "671, "
    },
    {
      "ID": 672,
      "UpcCode": 5003638250,
      "vendorName": "DAS",
      "ProductDescription": "JBL Vibe 100 True Wireless Earbuds Blue",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 37.14,
      "Margin": 0.38,
      "Elasticity": -1.257445048,
      "Base_Units": 114,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "672, "
    },
    {
      "ID": 673,
      "UpcCode": 5003638289,
      "vendorName": "DAS",
      "ProductDescription": "JBL Tune 760 Overear Headphone Anc Black",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 139.99,
      "Unit_Cost": 95.52,
      "Margin": 0.32,
      "Elasticity": -2.224838217,
      "Base_Units": 160,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "673, "
    },
    {
      "ID": 674,
      "UpcCode": 5003636959,
      "vendorName": "DAS",
      "ProductDescription": "JBL Quantum300 Over Ear Gaming Headphone",
      "BrandName": "JBL",
      "CaseSize": 0,
      "Unit_Price": 89.99,
      "Unit_Cost": 58.2,
      "Margin": 0.35,
      "Elasticity": -1.981143883,
      "Base_Units": 156,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "674, "
    },
    {
      "ID": 682,
      "UpcCode": 4683800225,
      "vendorName": "DAS",
      "ProductDescription": "JVC True Wireless Open Air Earbuds Black",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 28.47,
      "Margin": 0.43,
      "Elasticity": -1.57778365,
      "Base_Units": 126,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "682, "
    },
    {
      "ID": 683,
      "UpcCode": 4683800229,
      "vendorName": "DAS",
      "ProductDescription": "JVC Marshmallow True Wireless Earbuds Bk",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 54.99,
      "Unit_Cost": 31.84,
      "Margin": 0.42,
      "Elasticity": -1.590899067,
      "Base_Units": 186,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "683, "
    },
    {
      "ID": 684,
      "UpcCode": 4683800231,
      "vendorName": "DAS",
      "ProductDescription": "JVC Marshmallow True Wireless Earbuds Wt",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 54.99,
      "Unit_Cost": 31.84,
      "Margin": 0.42,
      "Elasticity": -1.101607997,
      "Base_Units": 237,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "684, "
    },
    {
      "ID": 685,
      "UpcCode": 4683800236,
      "vendorName": "DAS",
      "ProductDescription": "JVC True Wireless Open Air Earbuds White",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 49.99,
      "Unit_Cost": 28.47,
      "Margin": 0.43,
      "Elasticity": -1.426749836,
      "Base_Units": 67,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "685, "
    },
    {
      "ID": 686,
      "UpcCode": 4683800338,
      "vendorName": "DAS",
      "ProductDescription": "JVC True Wireless Open Air Earbuds Black",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 22.72,
      "Margin": 0.43,
      "Elasticity": -0.9202595337,
      "Base_Units": 154,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "686, "
    },
    {
      "ID": 687,
      "UpcCode": 4683800339,
      "vendorName": "DAS",
      "ProductDescription": "JVC True Wireless Open Air Earbuds White",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 22.61,
      "Margin": 0.43,
      "Elasticity": -0.9105910102,
      "Base_Units": 57,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "687, "
    },
    {
      "ID": 688,
      "UpcCode": 4683808106,
      "vendorName": "DAS",
      "ProductDescription": "JVC True Wireless W Charging Case",
      "BrandName": "JVC",
      "CaseSize": 0,
      "Unit_Price": 54.99,
      "Unit_Cost": 31.27,
      "Margin": 0.43,
      "Elasticity": -0.9078242654,
      "Base_Units": 68,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Bluetooth",
      "FIELD15": "688, "
    },
    {
      "ID": 159,
      "UpcCode": 84143711804,
      "vendorName": "DAS",
      "ProductDescription": "Auto Vent Cup And Phone Holder",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6,
      "Margin": 0.6,
      "Elasticity": -1.426203044,
      "Base_Units": 182,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "159, "
    },
    {
      "ID": 199,
      "UpcCode": 3087843715,
      "vendorName": "DAS",
      "ProductDescription": "Philips Ac USB Charger 1 Port 1.0A Whi",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 5.47,
      "Margin": 0.5,
      "Elasticity": -1.471914121,
      "Base_Units": 146,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "199, "
    },
    {
      "ID": 267,
      "UpcCode": 84270010364,
      "vendorName": "DAS",
      "ProductDescription": "Dark Marble Spinpop",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.52,
      "Margin": 0.55,
      "Elasticity": -1.000560281,
      "Base_Units": 243,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "267, "
    },
    {
      "ID": 280,
      "UpcCode": 63375513895,
      "vendorName": "DAS",
      "ProductDescription": "Roadstar 5 USB Car Charger Hub Blk Qc",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 20.9,
      "Margin": 0.48,
      "Elasticity": -0.8792279255,
      "Base_Units": 20,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "280, "
    },
    {
      "ID": 281,
      "UpcCode": 63375514247,
      "vendorName": "DAS",
      "ProductDescription": "5200Mah 2 In 1 Dual USB Portable Batte",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 22.38,
      "Margin": 0.44,
      "Elasticity": -2.630886741,
      "Base_Units": 166,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "281, "
    },
    {
      "ID": 282,
      "UpcCode": 63375514390,
      "vendorName": "DAS",
      "ProductDescription": "Turbo 30W USB C Pd USB C Car Chg Blk",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 19.05,
      "Margin": 0.52,
      "Elasticity": -1.813387922,
      "Base_Units": 30,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "282, "
    },
    {
      "ID": 283,
      "UpcCode": 63375514391,
      "vendorName": "DAS",
      "ProductDescription": "T3 Xtreme 18W USB C Pd2.4A Car Chgr Bl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 16.55,
      "Margin": 0.53,
      "Elasticity": -1.329606796,
      "Base_Units": 89,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "283, "
    },
    {
      "ID": 284,
      "UpcCode": 63375514490,
      "vendorName": "DAS",
      "ProductDescription": "Power Pad Qi Wireless Fast Charger Blk",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 20.9,
      "Margin": 0.48,
      "Elasticity": -0.7882680093,
      "Base_Units": 14,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "284, "
    },
    {
      "ID": 288,
      "UpcCode": 85719900830,
      "vendorName": "DAS",
      "ProductDescription": "Easy One Touch 5 Dash Windshield Mount",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 14.91,
      "Margin": 0.5,
      "Elasticity": -1.442348602,
      "Base_Units": 211,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "288, "
    },
    {
      "ID": 354,
      "UpcCode": 88944600853,
      "vendorName": "DAS",
      "ProductDescription": "Philips USB To Lightning Cable 6Ft Blk",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9.56,
      "Margin": 0.52,
      "Elasticity": -0.6329848935,
      "Base_Units": 173,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "354, "
    },
    {
      "ID": 355,
      "UpcCode": 88944600855,
      "vendorName": "DAS",
      "ProductDescription": "Philips USB To Micro Cable 6Ft Basic B",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 4.4,
      "Margin": 0.6,
      "Elasticity": -1.039521935,
      "Base_Units": 79,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "355, "
    },
    {
      "ID": 356,
      "UpcCode": 88944600865,
      "vendorName": "DAS",
      "ProductDescription": "Philips USB To Lightning Cable 6Ft Pre",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 11.3,
      "Margin": 0.55,
      "Elasticity": -0.6985057,
      "Base_Units": 23,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "356, "
    },
    {
      "ID": 357,
      "UpcCode": 88944600866,
      "vendorName": "DAS",
      "ProductDescription": "Philips Elite C To C Cable 6Ft Braided",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 10,
      "Margin": 0.6,
      "Elasticity": -1.216932667,
      "Base_Units": 125,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "357, "
    },
    {
      "ID": 358,
      "UpcCode": 88944600957,
      "vendorName": "DAS",
      "ProductDescription": "Philips Ac USB Charger 1 Port 1.0A Whi",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 5.5,
      "Margin": 0.5,
      "Elasticity": -1.732288624,
      "Base_Units": 32,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "358, "
    },
    {
      "ID": 371,
      "UpcCode": 81107103759,
      "vendorName": "DAS",
      "ProductDescription": "Opencomm Fast Charging Cord",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9.94,
      "Margin": 0.5,
      "Elasticity": -1.359186727,
      "Base_Units": 210,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "371, "
    },
    {
      "ID": 405,
      "UpcCode": 3399108421,
      "vendorName": "DAS",
      "ProductDescription": "4 In 1 Universal Mounting Kit Shipper",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 749.7,
      "Unit_Cost": 398.4,
      "Margin": 0.47,
      "Elasticity": -0.7425078213,
      "Base_Units": 95,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "405, "
    },
    {
      "ID": 419,
      "UpcCode": 3087839332,
      "vendorName": "DAS",
      "ProductDescription": "Philips Elite C To C Cable 6Ft Braided",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 9.95,
      "Margin": 0.6,
      "Elasticity": -1.372639747,
      "Base_Units": 84,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "419, "
    },
    {
      "ID": 449,
      "UpcCode": 85719900800,
      "vendorName": "DAS",
      "ProductDescription": "Itap Magnetic 2 Dash Windshield Mount",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 15,
      "Margin": 0.5,
      "Elasticity": -2.184601392,
      "Base_Units": 203,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "449, "
    },
    {
      "ID": 738,
      "UpcCode": 60870716702,
      "vendorName": "SRP",
      "ProductDescription": "3 In 1 Micro Cable",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 26.99,
      "Unit_Cost": 13.5,
      "Margin": 0.5,
      "Elasticity": -0.6887704265,
      "Base_Units": 79,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "738, "
    },
    {
      "ID": 739,
      "UpcCode": 60870716791,
      "vendorName": "SRP",
      "ProductDescription": "Pg 4 9Pin Charge Cbl",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 9,
      "Margin": 0.64,
      "Elasticity": -1.238043199,
      "Base_Units": 102,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "739, "
    },
    {
      "ID": 740,
      "UpcCode": 60870716792,
      "vendorName": "SRP",
      "ProductDescription": "Pg 9Pin Car Charger Cbl",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 26.99,
      "Unit_Cost": 13.5,
      "Margin": 0.5,
      "Elasticity": -1.204248234,
      "Base_Units": 196,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "740, "
    },
    {
      "ID": 741,
      "UpcCode": 60870716793,
      "vendorName": "SRP",
      "ProductDescription": "Pg Type C Cable",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 22.99,
      "Unit_Cost": 5,
      "Margin": 0.78,
      "Elasticity": -1.240308103,
      "Base_Units": 140,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "741, "
    },
    {
      "ID": 742,
      "UpcCode": 60870716794,
      "vendorName": "SRP",
      "ProductDescription": "Pg Wireless Earbud Black",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 26.99,
      "Unit_Cost": 10,
      "Margin": 0.63,
      "Elasticity": -0.762749,
      "Base_Units": 168,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "742, "
    },
    {
      "ID": 743,
      "UpcCode": 60870716795,
      "vendorName": "SRP",
      "ProductDescription": "Pg Wireless Earbud White",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 26.99,
      "Unit_Cost": 16.19,
      "Margin": 0.4,
      "Elasticity": -0.6296143551,
      "Base_Units": 48,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "743, "
    },
    {
      "ID": 744,
      "UpcCode": 60870716796,
      "vendorName": "SRP",
      "ProductDescription": "Pg Wireless Earbud Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 26.99,
      "Unit_Cost": 16.19,
      "Margin": 0.4,
      "Elasticity": -2.142875312,
      "Base_Units": 90,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "744, "
    },
    {
      "ID": 775,
      "UpcCode": 8079842953,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Typc Cbl Green",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5,
      "Margin": 0.71,
      "Elasticity": -0.7513743259,
      "Base_Units": 241,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "775, "
    },
    {
      "ID": 776,
      "UpcCode": 8079842954,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Typc Cbl Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5,
      "Margin": 0.71,
      "Elasticity": -1.350787148,
      "Base_Units": 26,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "776, "
    },
    {
      "ID": 777,
      "UpcCode": 8079842955,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Ltng Cbl Purple",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.75,
      "Margin": 0.61,
      "Elasticity": -1.10924896,
      "Base_Units": 43,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "777, "
    },
    {
      "ID": 778,
      "UpcCode": 8079842956,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Ltng Cbl Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.75,
      "Margin": 0.61,
      "Elasticity": -1.075470537,
      "Base_Units": 233,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "778, "
    },
    {
      "ID": 779,
      "UpcCode": 8079842957,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Ltng Cbl Green",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.75,
      "Margin": 0.61,
      "Elasticity": -0.6,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "779, "
    },
    {
      "ID": 780,
      "UpcCode": 8079842958,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Micro Cbl Purple",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 13.99,
      "Unit_Cost": 3.5,
      "Margin": 0.75,
      "Elasticity": -0.7525189656,
      "Base_Units": 197,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "780, "
    },
    {
      "ID": 781,
      "UpcCode": 8079842959,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Micro Cbl Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 13.99,
      "Unit_Cost": 3.5,
      "Margin": 0.75,
      "Elasticity": -0.8859577142,
      "Base_Units": 32,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "781, "
    },
    {
      "ID": 782,
      "UpcCode": 8079842960,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Micro Cbl Green",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 13.99,
      "Unit_Cost": 3.5,
      "Margin": 0.75,
      "Elasticity": -1.74883842,
      "Base_Units": 73,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "782, "
    },
    {
      "ID": 783,
      "UpcCode": 8079842961,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Typc Cbl Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5,
      "Margin": 0.75,
      "Elasticity": -0.8373790231,
      "Base_Units": 16,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "783, "
    },
    {
      "ID": 784,
      "UpcCode": 8079842962,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Typc Cbl Green",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5,
      "Margin": 0.75,
      "Elasticity": -1.520815079,
      "Base_Units": 69,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "784, "
    },
    {
      "ID": 785,
      "UpcCode": 8079842963,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Ltng Cbl Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 8.75,
      "Margin": 0.65,
      "Elasticity": -1.724818582,
      "Base_Units": 76,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "785, "
    },
    {
      "ID": 786,
      "UpcCode": 8079842964,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Ltng Cbl Green",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 8.75,
      "Margin": 0.65,
      "Elasticity": -1.6401616,
      "Base_Units": 157,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "786, "
    },
    {
      "ID": 787,
      "UpcCode": 8079842965,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Typc Cbl Purple",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5,
      "Margin": 0.75,
      "Elasticity": -0.9371863176,
      "Base_Units": 170,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "787, "
    },
    {
      "ID": 788,
      "UpcCode": 8079842966,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Ltng Cbl Purple",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 8.75,
      "Margin": 0.65,
      "Elasticity": -1.413227096,
      "Base_Units": 204,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "788, "
    },
    {
      "ID": 789,
      "UpcCode": 8079842967,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Pd Car Chgr Black",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 6.75,
      "Margin": 0.66,
      "Elasticity": -0.9968127711,
      "Base_Units": 185,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "789, "
    },
    {
      "ID": 790,
      "UpcCode": 8079842968,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Dual USB Car Chgr Black",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6,
      "Margin": 0.6,
      "Elasticity": -0.6177621836,
      "Base_Units": 16,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "790, "
    },
    {
      "ID": 796,
      "UpcCode": 8079842847,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Ltng Cbl Prym1 Freedom",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 10.22,
      "Margin": 0.59,
      "Elasticity": -1.575896994,
      "Base_Units": 155,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "796, "
    },
    {
      "ID": 797,
      "UpcCode": 8079842848,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Typc Cbl Prym1 Freedom",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5.38,
      "Margin": 0.73,
      "Elasticity": -0.7734136472,
      "Base_Units": 133,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "797, "
    },
    {
      "ID": 798,
      "UpcCode": 8079842849,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Ltng Cbl Prym1 Freedom",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 8.33,
      "Margin": 0.58,
      "Elasticity": -1.939015615,
      "Base_Units": 200,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "798, "
    },
    {
      "ID": 799,
      "UpcCode": 8079842850,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Typc Cbl Prym1 Freedom",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 5.38,
      "Margin": 0.7,
      "Elasticity": -1.591719271,
      "Base_Units": 193,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "799, "
    },
    {
      "ID": 800,
      "UpcCode": 8079842851,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Micro Cbl Prym1 Freedom",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 13.99,
      "Unit_Cost": 3.76,
      "Margin": 0.73,
      "Elasticity": -1.66086869,
      "Base_Units": 166,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "800, "
    },
    {
      "ID": 801,
      "UpcCode": 8079842863,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Dual USB Car Chgr Prym1 Freedom",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.45,
      "Margin": 0.57,
      "Elasticity": -0.9717590846,
      "Base_Units": 116,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "801, "
    },
    {
      "ID": 802,
      "UpcCode": 8079842864,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Ltng Cbl Prym1 Silver Mist",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 8.33,
      "Margin": 0.58,
      "Elasticity": -1.429591552,
      "Base_Units": 173,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "802, "
    },
    {
      "ID": 803,
      "UpcCode": 8079842865,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Ltng Cbl Prym1 Patriot Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 8.33,
      "Margin": 0.58,
      "Elasticity": -1.992326371,
      "Base_Units": 208,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "803, "
    },
    {
      "ID": 804,
      "UpcCode": 8079842866,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Typc Cbl Prym1 Silver Mist",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 5.38,
      "Margin": 0.7,
      "Elasticity": -0.7962281363,
      "Base_Units": 67,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "804, "
    },
    {
      "ID": 805,
      "UpcCode": 8079842867,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Typc Cbl Prym1 Patriot Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 5.38,
      "Margin": 0.7,
      "Elasticity": -1.576825883,
      "Base_Units": 157,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "805, "
    },
    {
      "ID": 806,
      "UpcCode": 8079842868,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Pd Car Chgr Prym1 Silver Mist",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.26,
      "Margin": 0.64,
      "Elasticity": -1.179704227,
      "Base_Units": 154,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "806, "
    },
    {
      "ID": 807,
      "UpcCode": 63375513626,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Plates",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.5,
      "Margin": 0.55,
      "Elasticity": -1.964965113,
      "Base_Units": 60,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "807, "
    },
    {
      "ID": 808,
      "UpcCode": 63375513667,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Dash Telescopic Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 11.25,
      "Margin": 0.55,
      "Elasticity": -1.09365556,
      "Base_Units": 189,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "808, "
    },
    {
      "ID": 809,
      "UpcCode": 63375513877,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Headrest",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 11.25,
      "Margin": 0.55,
      "Elasticity": -1.058667853,
      "Base_Units": 72,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "809, "
    },
    {
      "ID": 810,
      "UpcCode": 63375514052,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Anywhere Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9,
      "Margin": 0.55,
      "Elasticity": -1.060277546,
      "Base_Units": 238,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "810, "
    },
    {
      "ID": 811,
      "UpcCode": 63375514206,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Anywhere Mount Single",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.75,
      "Margin": 0.55,
      "Elasticity": -1.371346559,
      "Base_Units": 68,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "811, "
    },
    {
      "ID": 812,
      "UpcCode": 63375514242,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Air Ventcd Slot Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9,
      "Margin": 0.55,
      "Elasticity": -1.581124364,
      "Base_Units": 147,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "812, "
    },
    {
      "ID": 813,
      "UpcCode": 63375514533,
      "vendorName": "SRP",
      "ProductDescription": "Magbuddy Wireless Vent Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 33,
      "Margin": 0.45,
      "Elasticity": -1.478534538,
      "Base_Units": 211,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "813, "
    },
    {
      "ID": 814,
      "UpcCode": 63375514534,
      "vendorName": "SRP",
      "ProductDescription": "Magbuddy Wireless Dash Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 33,
      "Margin": 0.45,
      "Elasticity": -2.478554555,
      "Base_Units": 11,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "814, "
    },
    {
      "ID": 815,
      "UpcCode": 63375514535,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Vent Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9,
      "Margin": 0.55,
      "Elasticity": -1.486404692,
      "Base_Units": 10,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "815, "
    },
    {
      "ID": 816,
      "UpcCode": 63375514549,
      "vendorName": "SRP",
      "ProductDescription": "Magbuddy Wireless Windshield Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 33,
      "Margin": 0.45,
      "Elasticity": -2.788691841,
      "Base_Units": 182,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "816, "
    },
    {
      "ID": 817,
      "UpcCode": 63375514612,
      "vendorName": "SRP",
      "ProductDescription": "Magbuddy Wireless Anywhere Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 59.99,
      "Unit_Cost": 33,
      "Margin": 0.45,
      "Elasticity": -1.145485096,
      "Base_Units": 134,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "817, "
    },
    {
      "ID": 818,
      "UpcCode": 63375513575,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Windshield Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 9,
      "Margin": 0.55,
      "Elasticity": -1.752700273,
      "Base_Units": 20,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "818, "
    },
    {
      "ID": 819,
      "UpcCode": 63375513606,
      "vendorName": "SRP",
      "ProductDescription": "Mag Buddy Cupholder Mount",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10,
      "Margin": 0.5,
      "Elasticity": -1.36908147,
      "Base_Units": 128,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "819, "
    },
    {
      "ID": 829,
      "UpcCode": 8079842797,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Pd Chrger",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 6.75,
      "Margin": 0.66,
      "Elasticity": -1.1978141,
      "Base_Units": 58,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "829, "
    },
    {
      "ID": 830,
      "UpcCode": 8079842798,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Cc Cbl Shade",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5,
      "Margin": 0.75,
      "Elasticity": -1.14380877,
      "Base_Units": 111,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "830, "
    },
    {
      "ID": 831,
      "UpcCode": 8079842799,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm C Ltng Cbl Shade",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 8.75,
      "Margin": 0.65,
      "Elasticity": -0.9866781555,
      "Base_Units": 219,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "831, "
    },
    {
      "ID": 832,
      "UpcCode": 8079842800,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Type C Cbl Dawn",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 5,
      "Margin": 0.72,
      "Elasticity": -1.059931876,
      "Base_Units": 123,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "832, "
    },
    {
      "ID": 833,
      "UpcCode": 8079842801,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Type C Cbl Spruce",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 5,
      "Margin": 0.72,
      "Elasticity": -1.319028309,
      "Base_Units": 18,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "833, "
    },
    {
      "ID": 834,
      "UpcCode": 8079842802,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Micro Cbl Oak",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 13.99,
      "Unit_Cost": 3.5,
      "Margin": 0.75,
      "Elasticity": -1.752042524,
      "Base_Units": 16,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "834, "
    },
    {
      "ID": 835,
      "UpcCode": 8079842803,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Lightning Cbl Dawn",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.75,
      "Margin": 0.61,
      "Elasticity": -3.160754227,
      "Base_Units": 152,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "835, "
    },
    {
      "ID": 836,
      "UpcCode": 8079842804,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Lightning Cbl Oak",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.75,
      "Margin": 0.61,
      "Elasticity": -1.766691327,
      "Base_Units": 123,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "836, "
    },
    {
      "ID": 837,
      "UpcCode": 8079842805,
      "vendorName": "SRP",
      "ProductDescription": "Tt Ttnm Lightning Cbl Spruce",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.75,
      "Margin": 0.61,
      "Elasticity": -1.303169189,
      "Base_Units": 124,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "837, "
    },
    {
      "ID": 838,
      "UpcCode": 8079842952,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm USB To Typc Cbl Purple",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5,
      "Margin": 0.71,
      "Elasticity": -0.8939877834,
      "Base_Units": 116,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "838, "
    },
    {
      "ID": 875,
      "UpcCode": 8079842210,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Lightning Cable Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 8.33,
      "Margin": 0.58,
      "Elasticity": -0.9438758113,
      "Base_Units": 237,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "875, "
    },
    {
      "ID": 876,
      "UpcCode": 8079842211,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Type C Cable Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 5,
      "Margin": 0.71,
      "Elasticity": -0.6438925096,
      "Base_Units": 144,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "876, "
    },
    {
      "ID": 877,
      "UpcCode": 8079842212,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Micro Cable Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 13.99,
      "Unit_Cost": 3.5,
      "Margin": 0.75,
      "Elasticity": -0.7801038035,
      "Base_Units": 241,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "877, "
    },
    {
      "ID": 878,
      "UpcCode": 8079842226,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Aux Cable Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 3.5,
      "Margin": 0.65,
      "Elasticity": -0.605461369,
      "Base_Units": 81,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "878, "
    },
    {
      "ID": 879,
      "UpcCode": 8079842596,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Type C To Lightning Cable Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 8.75,
      "Margin": 0.65,
      "Elasticity": -1.054265856,
      "Base_Units": 127,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "879, "
    },
    {
      "ID": 880,
      "UpcCode": 8079842597,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Type C To Type C Cable Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5,
      "Margin": 0.75,
      "Elasticity": -0.8657006195,
      "Base_Units": 192,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "880, "
    },
    {
      "ID": 881,
      "UpcCode": 8079842598,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Pd Car Charger Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 18.99,
      "Unit_Cost": 6.8,
      "Margin": 0.64,
      "Elasticity": -0.9682119949,
      "Base_Units": 97,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "881, "
    },
    {
      "ID": 882,
      "UpcCode": 8079842599,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Car Charger Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 5.1,
      "Margin": 0.72,
      "Elasticity": -1.087539856,
      "Base_Units": 26,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "882, "
    },
    {
      "ID": 883,
      "UpcCode": 8079842600,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm True Wireless Earbuds Asst",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 9.5,
      "Margin": 0.62,
      "Elasticity": -1.227870809,
      "Base_Units": 230,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "883, "
    },
    {
      "ID": 884,
      "UpcCode": 8079842993,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Pd Car Chgr Prym1 Patriot Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.26,
      "Margin": 0.64,
      "Elasticity": -0.670922776,
      "Base_Units": 18,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "884, "
    },
    {
      "ID": 885,
      "UpcCode": 8079842994,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Dual USB Car Chgr Prym1 Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.45,
      "Margin": 0.57,
      "Elasticity": -1.570571606,
      "Base_Units": 60,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "885, "
    },
    {
      "ID": 886,
      "UpcCode": 8079842995,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Dual USB Car Chgr Prym1 Silver Mist",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.45,
      "Margin": 0.57,
      "Elasticity": -1.356205319,
      "Base_Units": 116,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "886, "
    },
    {
      "ID": 887,
      "UpcCode": 8079842996,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Ltng Cbl Prym1 Patriot Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 10.22,
      "Margin": 0.59,
      "Elasticity": -1.108805526,
      "Base_Units": 221,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "887, "
    },
    {
      "ID": 888,
      "UpcCode": 8079842997,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Ltng Cbl Prym1 Silver Mist",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 10.22,
      "Margin": 0.59,
      "Elasticity": -0.9685665751,
      "Base_Units": 198,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "888, "
    },
    {
      "ID": 889,
      "UpcCode": 8079842998,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Typc Cbl Prym1 Silver Mist",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5.38,
      "Margin": 0.73,
      "Elasticity": -1.035126578,
      "Base_Units": 49,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "889, "
    },
    {
      "ID": 890,
      "UpcCode": 8079842999,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Typc To Typc Cbl Prym1 Patriot Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5.38,
      "Margin": 0.73,
      "Elasticity": -1.376262361,
      "Base_Units": 85,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "890, "
    },
    {
      "ID": 891,
      "UpcCode": 8079843000,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Micro Cbl Prym1 Silver Mist",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.76,
      "Margin": 0.75,
      "Elasticity": -2.08718469,
      "Base_Units": 49,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "891, "
    },
    {
      "ID": 892,
      "UpcCode": 8079843001,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Micro Cbl Prym1 Patriot Blue",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 3.76,
      "Margin": 0.75,
      "Elasticity": -1.415071198,
      "Base_Units": 144,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "892, "
    },
    {
      "ID": 893,
      "UpcCode": 8079842856,
      "vendorName": "SRP",
      "ProductDescription": "Xl 8Ft Micro Cbl Prym1 Black",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 4.25,
      "Margin": 0.72,
      "Elasticity": -0.8925036112,
      "Base_Units": 88,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "893, "
    },
    {
      "ID": 894,
      "UpcCode": 8079842857,
      "vendorName": "SRP",
      "ProductDescription": "Xl 8Ft Typc Cbl Prym1 Black",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 4.65,
      "Margin": 0.69,
      "Elasticity": -1.112602287,
      "Base_Units": 149,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "894, "
    },
    {
      "ID": 895,
      "UpcCode": 8079842858,
      "vendorName": "SRP",
      "ProductDescription": "Xl 8Ft Ltng Cbl Prym1 Black",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.5,
      "Margin": 0.57,
      "Elasticity": -0.6486529817,
      "Base_Units": 111,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "895, "
    },
    {
      "ID": 896,
      "UpcCode": 8079842906,
      "vendorName": "SRP",
      "ProductDescription": "Xl 8Ft Typc Cbl Prym1 Orange",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 4.65,
      "Margin": 0.69,
      "Elasticity": -0.9032684638,
      "Base_Units": 201,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "896, "
    },
    {
      "ID": 897,
      "UpcCode": 8079842907,
      "vendorName": "SRP",
      "ProductDescription": "Xl 8Ft Ltng Cbl Prym1 Silver",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.5,
      "Margin": 0.57,
      "Elasticity": -0.9807291176,
      "Base_Units": 191,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "897, "
    },
    {
      "ID": 898,
      "UpcCode": 8079842908,
      "vendorName": "SRP",
      "ProductDescription": "Xl 8Ft Ltng Cbl Prym1 Orange",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.5,
      "Margin": 0.57,
      "Elasticity": -0.613800215,
      "Base_Units": 155,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "898, "
    },
    {
      "ID": 899,
      "UpcCode": 8079842928,
      "vendorName": "SRP",
      "ProductDescription": "Dual USB Car Chgr Prym1 Silver",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 5,
      "Margin": 0.67,
      "Elasticity": -0.8239854946,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "899, "
    },
    {
      "ID": 900,
      "UpcCode": 8079842929,
      "vendorName": "SRP",
      "ProductDescription": "Dual USB Car Chgr Prym1 Orange",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 5,
      "Margin": 0.67,
      "Elasticity": -0.9966428914,
      "Base_Units": 177,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "900, "
    },
    {
      "ID": 903,
      "UpcCode": 8079842008,
      "vendorName": "SRP",
      "ProductDescription": "Rlt Cmousb Car 8Pin Mfi Cbl",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 17.99,
      "Margin": 0.4,
      "Elasticity": -1.032508197,
      "Base_Units": 98,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "903, "
    },
    {
      "ID": 904,
      "UpcCode": 8079841843,
      "vendorName": "SRP",
      "ProductDescription": "2.1A Dual USB Car Charger Black",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.1,
      "Margin": 0.65,
      "Elasticity": -1.441247666,
      "Base_Units": 192,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "904, "
    },
    {
      "ID": 905,
      "UpcCode": 8079841844,
      "vendorName": "SRP",
      "ProductDescription": "2.1A Dual USB Car Charger Silver",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.1,
      "Margin": 0.65,
      "Elasticity": -1.193333108,
      "Base_Units": 179,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "905, "
    },
    {
      "ID": 906,
      "UpcCode": 8079841845,
      "vendorName": "SRP",
      "ProductDescription": "2.1A Dual USB Car Charger White",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.1,
      "Margin": 0.65,
      "Elasticity": -1.552861116,
      "Base_Units": 96,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "906, "
    },
    {
      "ID": 918,
      "UpcCode": 8079842072,
      "vendorName": "SRP",
      "ProductDescription": "Hivis Aux Green",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.898178288,
      "Base_Units": 134,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "918, "
    },
    {
      "ID": 919,
      "UpcCode": 8079842073,
      "vendorName": "SRP",
      "ProductDescription": "Hivis Aux Orange",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.456402919,
      "Base_Units": 211,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "919, "
    },
    {
      "ID": 920,
      "UpcCode": 8079842098,
      "vendorName": "SRP",
      "ProductDescription": "Hivis Aux Silver",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.5,
      "Margin": 0.5,
      "Elasticity": -1.581522589,
      "Base_Units": 217,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "920, "
    },
    {
      "ID": 921,
      "UpcCode": 8079842124,
      "vendorName": "SRP",
      "ProductDescription": "Audio Cable",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 8.99,
      "Unit_Cost": 5.39,
      "Margin": 0.4,
      "Elasticity": -1.991771848,
      "Base_Units": 117,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "921, "
    },
    {
      "ID": 922,
      "UpcCode": 8079842125,
      "vendorName": "SRP",
      "ProductDescription": "Camo 3.1A Car Charger",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 6.59,
      "Margin": 0.4,
      "Elasticity": -0.6106783432,
      "Base_Units": 110,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "922, "
    },
    {
      "ID": 923,
      "UpcCode": 8079842126,
      "vendorName": "SRP",
      "ProductDescription": "Camo 8Pin Cable",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 11.99,
      "Margin": 0.4,
      "Elasticity": -0.9024673716,
      "Base_Units": 151,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "923, "
    },
    {
      "ID": 924,
      "UpcCode": 8079842307,
      "vendorName": "SRP",
      "ProductDescription": "Sr Type C USB Cable",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 9,
      "Margin": 0.5,
      "Elasticity": -0.6459872438,
      "Base_Units": 184,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "924, "
    },
    {
      "ID": 925,
      "UpcCode": 8079842845,
      "vendorName": "SRP",
      "ProductDescription": "Ttnm Pd Car Chgr Prym1 Freedom",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.26,
      "Margin": 0.64,
      "Elasticity": -1.614808946,
      "Base_Units": 128,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Accessories",
      "FIELD15": "925, "
    },
    {
      "ID": 201,
      "UpcCode": 4546480311,
      "vendorName": "DAS",
      "ProductDescription": "Bco Gel Neck Pillow Assorted",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 7.79,
      "Margin": 0.61,
      "Elasticity": -0.9896270951,
      "Base_Units": 78,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Comfort",
      "FIELD15": "201, "
    },
    {
      "ID": 946,
      "UpcCode": 64874892893,
      "vendorName": "SRP",
      "ProductDescription": "Srp 14.99 Neck Pillow Asst.",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6,
      "Margin": 0.6,
      "Elasticity": -1.540096753,
      "Base_Units": 185,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Mobile Comfort",
      "FIELD15": "946, "
    },
    {
      "ID": 239,
      "UpcCode": 84017205641,
      "vendorName": "DAS",
      "ProductDescription": "Plug N Play Frogger",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10,
      "Margin": 0.5,
      "Elasticity": -1.443755765,
      "Base_Units": 241,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "239, "
    },
    {
      "ID": 276,
      "UpcCode": 69420213052,
      "vendorName": "DAS",
      "ProductDescription": "Sharper Image Auto Heated Mug",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.95,
      "Margin": 0.4,
      "Elasticity": -1.535199096,
      "Base_Units": 202,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "276, "
    },
    {
      "ID": 277,
      "UpcCode": 69420232734,
      "vendorName": "DAS",
      "ProductDescription": "Toy Rc Robotic Battle Spacebot",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 9.72,
      "Margin": 0.35,
      "Elasticity": -1.174401087,
      "Base_Units": 30,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "277, "
    },
    {
      "ID": 278,
      "UpcCode": 69420232834,
      "vendorName": "DAS",
      "ProductDescription": "Toy Rc Spin Drifter 360",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 12.97,
      "Margin": 0.35,
      "Elasticity": -1.431806941,
      "Base_Units": 34,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "278, "
    },
    {
      "ID": 345,
      "UpcCode": 81698301662,
      "vendorName": "DAS",
      "ProductDescription": "Mobile Gaming Controller",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 6.96,
      "Margin": 0.54,
      "Elasticity": -1.339668041,
      "Base_Units": 196,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "345, "
    },
    {
      "ID": 346,
      "UpcCode": 81698301754,
      "vendorName": "DAS",
      "ProductDescription": "Mobile Gaming Handles",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.47,
      "Margin": 0.55,
      "Elasticity": -0.7473544398,
      "Base_Units": 175,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "346, "
    },
    {
      "ID": 347,
      "UpcCode": 81698301939,
      "vendorName": "DAS",
      "ProductDescription": "Mobiel Gaming Triggers",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.08,
      "Margin": 0.53,
      "Elasticity": -0.6,
      "Base_Units": 219,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "347, "
    },
    {
      "ID": 348,
      "UpcCode": 81698301996,
      "vendorName": "DAS",
      "ProductDescription": "Premium Mobile Gaming Triggers",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.24,
      "Margin": 0.58,
      "Elasticity": -1.556482533,
      "Base_Units": 155,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "348, "
    },
    {
      "ID": 349,
      "UpcCode": 85984000246,
      "vendorName": "DAS",
      "ProductDescription": "Vanguard 720P 19In Port Gaming Monitor",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 299.99,
      "Unit_Cost": 250.31,
      "Margin": 0.17,
      "Elasticity": -1.436397555,
      "Base_Units": 145,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "349, "
    },
    {
      "ID": 908,
      "UpcCode": 85695900528,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Shoes",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 32.99,
      "Unit_Cost": 19.38,
      "Margin": 0.41,
      "Elasticity": -1.315775387,
      "Base_Units": 98,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "908, "
    },
    {
      "ID": 909,
      "UpcCode": 85695900529,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Workout Belt",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 21,
      "Margin": 0.4,
      "Elasticity": -1.403946788,
      "Base_Units": 14,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "909, "
    },
    {
      "ID": 910,
      "UpcCode": 85695900531,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Gloves",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 27.99,
      "Unit_Cost": 16.62,
      "Margin": 0.41,
      "Elasticity": -1.106536643,
      "Base_Units": 145,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "910, "
    },
    {
      "ID": 911,
      "UpcCode": 85695900532,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Socks",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 27.99,
      "Unit_Cost": 16.62,
      "Margin": 0.41,
      "Elasticity": -2.210452909,
      "Base_Units": 66,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "911, "
    },
    {
      "ID": 912,
      "UpcCode": 85695900533,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Massage Brace",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 36.99,
      "Unit_Cost": 22.15,
      "Margin": 0.4,
      "Elasticity": -0.6078390048,
      "Base_Units": 110,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "912, "
    },
    {
      "ID": 913,
      "UpcCode": 85695900546,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Elbow Brace",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 27.99,
      "Unit_Cost": 16.62,
      "Margin": 0.41,
      "Elasticity": -0.8536538164,
      "Base_Units": 247,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "913, "
    },
    {
      "ID": 914,
      "UpcCode": 85695900547,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Knee Brace",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 46.99,
      "Unit_Cost": 27.69,
      "Margin": 0.41,
      "Elasticity": -1.280002306,
      "Base_Units": 10,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "914, "
    },
    {
      "ID": 915,
      "UpcCode": 85695900558,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Electrode Pads Large",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 6.09,
      "Margin": 0.45,
      "Elasticity": -1.443196631,
      "Base_Units": 172,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "915, "
    },
    {
      "ID": 916,
      "UpcCode": 85695900559,
      "vendorName": "SRP",
      "ProductDescription": "Epulse Electrode Pads Jumbo",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 9.97,
      "Margin": 0.41,
      "Elasticity": -1.137793698,
      "Base_Units": 59,
      "Level1": "General Merch",
      "Level2": "Mobile Technologies",
      "Level3": "Tech Novelty",
      "FIELD15": "916, "
    },
    {
      "ID": 384,
      "UpcCode": 18122510062,
      "vendorName": "DAS",
      "ProductDescription": "Brentwood Ts 112B Sngle Serve Coffee Mak",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.49,
      "Unit_Cost": 15.27,
      "Margin": 0.38,
      "Elasticity": -0.8227726662,
      "Base_Units": 14,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "12 Volt",
      "FIELD15": "384, "
    },
    {
      "ID": 385,
      "UpcCode": 18122520130,
      "vendorName": "DAS",
      "ProductDescription": "3 Qt Slow Cooker Ss Body",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 15.82,
      "Margin": 0.47,
      "Elasticity": -2.268458704,
      "Base_Units": 68,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "12 Volt",
      "FIELD15": "385, "
    },
    {
      "ID": 301,
      "UpcCode": 8368300200,
      "vendorName": "DAS",
      "ProductDescription": "Starting Fluid 11 Oz. Aerosol",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.29,
      "Unit_Cost": 2.47,
      "Margin": 0.53,
      "Elasticity": -1.343896727,
      "Base_Units": 21,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Chemicals",
      "FIELD15": "301, "
    },
    {
      "ID": 221,
      "UpcCode": 4546480273,
      "vendorName": "DAS",
      "ProductDescription": "Scipio 2000Lumens Flashlight",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 19.02,
      "Margin": 0.37,
      "Elasticity": -1.345686437,
      "Base_Units": 228,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Flashlights",
      "FIELD15": "221, "
    },
    {
      "ID": 222,
      "UpcCode": 4546480274,
      "vendorName": "DAS",
      "ProductDescription": "Scipio 1000 Lumens Flashlight",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 12.55,
      "Margin": 0.37,
      "Elasticity": -0.6772410143,
      "Base_Units": 176,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Flashlights",
      "FIELD15": "222, "
    },
    {
      "ID": 223,
      "UpcCode": 4546480275,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Cree Tactical Headlamp",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.59,
      "Unit_Cost": 11.46,
      "Margin": 0.61,
      "Elasticity": -1.323693209,
      "Base_Units": 168,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Flashlights",
      "FIELD15": "223, "
    },
    {
      "ID": 224,
      "UpcCode": 4546480276,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Rechargeable Flashlight 4000Lms",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 50.99,
      "Unit_Cost": 25.79,
      "Margin": 0.49,
      "Elasticity": -1.629412757,
      "Base_Units": 216,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Flashlights",
      "FIELD15": "224, "
    },
    {
      "ID": 230,
      "UpcCode": 4546479625,
      "vendorName": "DAS",
      "ProductDescription": "Tactical Pen 18 Pc Display",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.74,
      "Margin": 0.53,
      "Elasticity": -0.6488177959,
      "Base_Units": 77,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Flashlights",
      "FIELD15": "230, "
    },
    {
      "ID": 453,
      "UpcCode": 4546481230,
      "vendorName": "DAS",
      "ProductDescription": "Led Baseball Bat Flashlight 12Ct Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 13.21,
      "Margin": 0.47,
      "Elasticity": -0.8838983194,
      "Base_Units": 106,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Flashlights",
      "FIELD15": "453, "
    },
    {
      "ID": 664,
      "UpcCode": 3959335872,
      "vendorName": "DAS",
      "ProductDescription": "Camo Military Grade Tactical Flashlight",
      "BrandName": "IIT",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.88,
      "Margin": 0.51,
      "Elasticity": -1.644497166,
      "Base_Units": 96,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Flashlights",
      "FIELD15": "664, "
    },
    {
      "ID": 167,
      "UpcCode": 4546479840,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Glove Tactical Recon L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 8.64,
      "Margin": 0.57,
      "Elasticity": -0.8077712955,
      "Base_Units": 214,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Gloves",
      "FIELD15": "167, "
    },
    {
      "ID": 168,
      "UpcCode": 4546479841,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Glove Tactical Pathfinder L",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 11.78,
      "Margin": 0.53,
      "Elasticity": -1.099221699,
      "Base_Units": 52,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Gloves",
      "FIELD15": "168, "
    },
    {
      "ID": 214,
      "UpcCode": 4546480140,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Tactical Glove Large",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 14.92,
      "Margin": 0.5,
      "Elasticity": -0.8189754584,
      "Base_Units": 169,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Gloves",
      "FIELD15": "214, "
    },
    {
      "ID": 215,
      "UpcCode": 4546480141,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Tactical Glove X Large",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 14.99,
      "Margin": 0.5,
      "Elasticity": -1.995634927,
      "Base_Units": 196,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Gloves",
      "FIELD15": "215, "
    },
    {
      "ID": 250,
      "UpcCode": 89880641504,
      "vendorName": "DAS",
      "ProductDescription": "Sole Trends Thermal Gloves",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 6.29,
      "Unit_Cost": 2.35,
      "Margin": 0.63,
      "Elasticity": -0.6,
      "Base_Units": 155,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Gloves",
      "FIELD15": "250, "
    },
    {
      "ID": 575,
      "UpcCode": 9897441215,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Truck Stuff Fingerless Glove",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 17.99,
      "Unit_Cost": 9.9,
      "Margin": 0.45,
      "Elasticity": -3.473048492,
      "Base_Units": 65,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Gloves",
      "FIELD15": "575, "
    },
    {
      "ID": 748,
      "UpcCode": 60870765536,
      "vendorName": "SRP",
      "ProductDescription": "Brown Jersey Glove",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1,
      "Margin": 0.5,
      "Elasticity": -1.409306954,
      "Base_Units": 144,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Gloves",
      "FIELD15": "748, "
    },
    {
      "ID": 31,
      "UpcCode": 5994100044345,
      "vendorName": "DAS",
      "ProductDescription": "6024 Halogen Hilo Beam Round 2Lamp Sys",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 22.29,
      "Unit_Cost": 8.7,
      "Margin": 0.61,
      "Elasticity": -1.351416957,
      "Base_Units": 179,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "31, "
    },
    {
      "ID": 32,
      "UpcCode": 5994100044352,
      "vendorName": "DAS",
      "ProductDescription": "4651 Halogen Hibeam Square 4 Lamp Sys",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 5.78,
      "Margin": 0.71,
      "Elasticity": -1.063498382,
      "Base_Units": 35,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "32, "
    },
    {
      "ID": 33,
      "UpcCode": 5994100044369,
      "vendorName": "DAS",
      "ProductDescription": "4656 Halogen Lo Beam Square 4Lamp System",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 5.8,
      "Margin": 0.74,
      "Elasticity": -2.292174203,
      "Base_Units": 198,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "33, "
    },
    {
      "ID": 34,
      "UpcCode": 5994100044376,
      "vendorName": "DAS",
      "ProductDescription": "6054 Halogen Hilo Beam Sq 2 Lamp Sys",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 20.99,
      "Unit_Cost": 7.66,
      "Margin": 0.64,
      "Elasticity": -1.200853202,
      "Base_Units": 216,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "34, "
    },
    {
      "ID": 35,
      "UpcCode": 5994100044406,
      "vendorName": "DAS",
      "ProductDescription": "5061 Longlife Halo Low Beam Sq 4Lamp Sys",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 20.99,
      "Unit_Cost": 7.96,
      "Margin": 0.62,
      "Elasticity": -0.6,
      "Base_Units": 175,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "35, "
    },
    {
      "ID": 36,
      "UpcCode": 5994100045113,
      "vendorName": "DAS",
      "ProductDescription": "4656 Night Hawk Low Beam 4 Lamp System",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 31.99,
      "Unit_Cost": 17.45,
      "Margin": 0.45,
      "Elasticity": -1.561150957,
      "Base_Units": 147,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "36, "
    },
    {
      "ID": 37,
      "UpcCode": 5994100049210,
      "vendorName": "DAS",
      "ProductDescription": "H11 Headlight Bulb Longlife",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 9.05,
      "Margin": 0.59,
      "Elasticity": -1.253456448,
      "Base_Units": 102,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "37, "
    },
    {
      "ID": 38,
      "UpcCode": 5994100049364,
      "vendorName": "DAS",
      "ProductDescription": "9005 Halogen Bulb Aerodynamic Hi Beam",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 4.64,
      "Margin": 0.79,
      "Elasticity": -0.8149926242,
      "Base_Units": 241,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "38, "
    },
    {
      "ID": 39,
      "UpcCode": 5994100049388,
      "vendorName": "DAS",
      "ProductDescription": "9003 Halogen Hilow Beam Repl Bulb",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 20.99,
      "Unit_Cost": 5.47,
      "Margin": 0.74,
      "Elasticity": -0.7340448411,
      "Base_Units": 173,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "39, "
    },
    {
      "ID": 40,
      "UpcCode": 5994100049463,
      "vendorName": "DAS",
      "ProductDescription": "H13 Replacement Bulbhalogen",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 9.94,
      "Margin": 0.6,
      "Elasticity": -1.087267424,
      "Base_Units": 179,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "40, "
    },
    {
      "ID": 41,
      "UpcCode": 5994100049517,
      "vendorName": "DAS",
      "ProductDescription": "H9006 Nighthawk Capsule Lo Beam 4 Lig",
      "BrandName": "GE",
      "CaseSize": 0,
      "Unit_Price": 26.99,
      "Unit_Cost": 13.96,
      "Margin": 0.48,
      "Elasticity": -1.575890987,
      "Base_Units": 162,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Lights & Lenses",
      "FIELD15": "41, "
    },
    {
      "ID": 198,
      "UpcCode": 4546480162,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Tactical Sling Bag Camo",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 12.97,
      "Margin": 0.48,
      "Elasticity": -1.453025152,
      "Base_Units": 121,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Luggage",
      "FIELD15": "198, "
    },
    {
      "ID": 217,
      "UpcCode": 4546480267,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Foam Lined Storage Case",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 15,
      "Margin": 0.5,
      "Elasticity": -1.535695267,
      "Base_Units": 76,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Luggage",
      "FIELD15": "217, "
    },
    {
      "ID": 218,
      "UpcCode": 4546480269,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Sling Bag With USB Port",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 13.54,
      "Margin": 0.55,
      "Elasticity": -0.8620245872,
      "Base_Units": 187,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Luggage",
      "FIELD15": "218, "
    },
    {
      "ID": 225,
      "UpcCode": 4546480278,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Soft Sided Carry Case",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.53,
      "Margin": 0.5,
      "Elasticity": -1.323617864,
      "Base_Units": 46,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Luggage",
      "FIELD15": "225, "
    },
    {
      "ID": 226,
      "UpcCode": 4546480279,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Soft Sided Carry Case",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 5.78,
      "Margin": 0.56,
      "Elasticity": -1.522796689,
      "Base_Units": 16,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Luggage",
      "FIELD15": "226, "
    },
    {
      "ID": 436,
      "UpcCode": 69769660047,
      "vendorName": "DAS",
      "ProductDescription": "Hershey Bar Spandex Pillow",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.91,
      "Margin": 0.51,
      "Elasticity": -0.6928229116,
      "Base_Units": 181,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Luggage",
      "FIELD15": "436, "
    },
    {
      "ID": 437,
      "UpcCode": 69769660049,
      "vendorName": "DAS",
      "ProductDescription": "Reese S Cup Microbead Pillow",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.91,
      "Margin": 0.51,
      "Elasticity": -1.215585809,
      "Base_Units": 207,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Luggage",
      "FIELD15": "437, "
    },
    {
      "ID": 438,
      "UpcCode": 69769660067,
      "vendorName": "DAS",
      "ProductDescription": "Hershey S Neck Roll Travel",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.91,
      "Margin": 0.51,
      "Elasticity": -1.670737835,
      "Base_Units": 211,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Luggage",
      "FIELD15": "438, "
    },
    {
      "ID": 165,
      "UpcCode": 69103667972,
      "vendorName": "DAS",
      "ProductDescription": "Trukr Stik Truck Mirror Squeegee Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 13.32333333,
      "Unit_Cost": 6.24,
      "Margin": 0.53,
      "Elasticity": -1.29120725,
      "Base_Units": 42,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Misc Trk Sup",
      "FIELD15": "165, "
    },
    {
      "ID": 188,
      "UpcCode": 1005432197,
      "vendorName": "DAS",
      "ProductDescription": "Flexible Spout Funnels12Pc Clipstrip",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 35.88,
      "Unit_Cost": 16.8,
      "Margin": 0.53,
      "Elasticity": -1.491794481,
      "Base_Units": 182,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Misc Trk Sup",
      "FIELD15": "188, "
    },
    {
      "ID": 219,
      "UpcCode": 4546480271,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Carabiner With Clip Opening",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 4.18,
      "Margin": 0.58,
      "Elasticity": -1.985792668,
      "Base_Units": 239,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Misc Trk Sup",
      "FIELD15": "219, "
    },
    {
      "ID": 220,
      "UpcCode": 4546480272,
      "vendorName": "DAS",
      "ProductDescription": "Scipio Carabiner Twist Closure",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 5.75,
      "Margin": 0.56,
      "Elasticity": -1.630750668,
      "Base_Units": 80,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Misc Trk Sup",
      "FIELD15": "220, "
    },
    {
      "ID": 236,
      "UpcCode": 75025480531,
      "vendorName": "DAS",
      "ProductDescription": "Self Powered Spotlight With Beacon",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 39.99,
      "Unit_Cost": 22.88,
      "Margin": 0.43,
      "Elasticity": -1.369608805,
      "Base_Units": 156,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Misc Trk Sup",
      "FIELD15": "236, "
    },
    {
      "ID": 237,
      "UpcCode": 75025480718,
      "vendorName": "DAS",
      "ProductDescription": "Weather Alert Clock Radio",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 54.99,
      "Unit_Cost": 34.33,
      "Margin": 0.38,
      "Elasticity": -2.421953321,
      "Base_Units": 93,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Misc Trk Sup",
      "FIELD15": "237, "
    },
    {
      "ID": 500,
      "UpcCode": 9897454024,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Push Broom 24 W60Handle",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.45,
      "Margin": 0.48,
      "Elasticity": -0.8548569162,
      "Base_Units": 15,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Misc Trk Sup",
      "FIELD15": "500, "
    },
    {
      "ID": 202,
      "UpcCode": 4546480770,
      "vendorName": "DAS",
      "ProductDescription": "Glvnzd Stl Pipe Stake Od 2.375 In 9Gauge",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 44.99,
      "Unit_Cost": 29.99,
      "Margin": 0.33,
      "Elasticity": -0.9176283865,
      "Base_Units": 140,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "202, "
    },
    {
      "ID": 271,
      "UpcCode": 85263900867,
      "vendorName": "DAS",
      "ProductDescription": "Flex Seal Black Mini 2 0 Oz In Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 10.30666667,
      "Unit_Cost": 4.66,
      "Margin": 0.55,
      "Elasticity": -1.20095966,
      "Base_Units": 230,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "271, "
    },
    {
      "ID": 272,
      "UpcCode": 85263900868,
      "vendorName": "DAS",
      "ProductDescription": "Flex Seal Clear Mini 2 0 Oz In Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 10.30666667,
      "Unit_Cost": 4.66,
      "Margin": 0.55,
      "Elasticity": -1.129891013,
      "Base_Units": 126,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "272, "
    },
    {
      "ID": 273,
      "UpcCode": 85263900877,
      "vendorName": "DAS",
      "ProductDescription": "Flex Tape Mini  Black W Clip Strip",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.09,
      "Unit_Cost": 4.66,
      "Margin": 0.49,
      "Elasticity": -1.479202559,
      "Base_Units": 123,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "273, "
    },
    {
      "ID": 274,
      "UpcCode": 85263900878,
      "vendorName": "DAS",
      "ProductDescription": "Flex Tape Mini  Clear W Clip Strip",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 9.09,
      "Unit_Cost": 4.66,
      "Margin": 0.49,
      "Elasticity": -2.208651107,
      "Base_Units": 173,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "274, "
    },
    {
      "ID": 275,
      "UpcCode": 85263900881,
      "vendorName": "DAS",
      "ProductDescription": "Flex Glue Mini 75 Oz W Clip Strip Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 8.156666667,
      "Unit_Cost": 4.66,
      "Margin": 0.43,
      "Elasticity": -0.6728662041,
      "Base_Units": 240,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "275, "
    },
    {
      "ID": 369,
      "UpcCode": 85405900303,
      "vendorName": "DAS",
      "ProductDescription": "Wrapit Heavy Duty Storage",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 8.823333333,
      "Unit_Cost": 3.13,
      "Margin": 0.65,
      "Elasticity": -1.954028491,
      "Base_Units": 115,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "369, "
    },
    {
      "ID": 378,
      "UpcCode": 85266539422,
      "vendorName": "DAS",
      "ProductDescription": "Vee Boards 2 Go Pack Of 20 Whandle",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 69.99,
      "Unit_Cost": 52.96,
      "Margin": 0.24,
      "Elasticity": -1.337242592,
      "Base_Units": 237,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "378, "
    },
    {
      "ID": 498,
      "UpcCode": 9897449125,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hivis Breakaway Vest Prepack",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 11.99,
      "Unit_Cost": 6.95,
      "Margin": 0.42,
      "Elasticity": -0.9936656022,
      "Base_Units": 35,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "498, "
    },
    {
      "ID": 499,
      "UpcCode": 9897449127,
      "vendorName": "Hi-Way Distributing",
      "ProductDescription": "Hivis Deluxe Vest Prepack",
      "BrandName": "HWI",
      "CaseSize": 0,
      "Unit_Price": 15.99,
      "Unit_Cost": 9.5,
      "Margin": 0.41,
      "Elasticity": -1.28685949,
      "Base_Units": 99,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Safety",
      "FIELD15": "499, "
    },
    {
      "ID": 203,
      "UpcCode": 76460800205,
      "vendorName": "DAS",
      "ProductDescription": "Bazic Jumbo Color Paper Clips 100 Pk",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.49,
      "Margin": 0.5,
      "Elasticity": -1.179560069,
      "Base_Units": 177,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "203, "
    },
    {
      "ID": 204,
      "UpcCode": 76460800261,
      "vendorName": "DAS",
      "ProductDescription": "Binder Clips Black 8Pk 1 14",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.49,
      "Margin": 0.5,
      "Elasticity": -1.316236648,
      "Base_Units": 95,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "204, "
    },
    {
      "ID": 205,
      "UpcCode": 76460800325,
      "vendorName": "DAS",
      "ProductDescription": "Bazic 12In30Cm Transprnt Plastic Ruler",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 0.8,
      "Margin": 0.6,
      "Elasticity": -2.314863259,
      "Base_Units": 125,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "205, "
    },
    {
      "ID": 206,
      "UpcCode": 76460800600,
      "vendorName": "DAS",
      "ProductDescription": "Bazic Mini Standard Stapler W 500 Ct Sta",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 1.99,
      "Margin": 0.6,
      "Elasticity": -0.6699120664,
      "Base_Units": 133,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "206, "
    },
    {
      "ID": 207,
      "UpcCode": 76460800912,
      "vendorName": "DAS",
      "ProductDescription": "Transparent Tape With Dispenser",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 2.006666667,
      "Unit_Cost": 1.15,
      "Margin": 0.43,
      "Elasticity": -1.953723008,
      "Base_Units": 59,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "207, "
    },
    {
      "ID": 208,
      "UpcCode": 76460801614,
      "vendorName": "DAS",
      "ProductDescription": "Bazic Metal Tip Correctn Pen Fluid 2Pk",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.123333333,
      "Unit_Cost": 2.74,
      "Margin": 0.47,
      "Elasticity": -1.248594172,
      "Base_Units": 15,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "208, "
    },
    {
      "ID": 209,
      "UpcCode": 76460801636,
      "vendorName": "DAS",
      "ProductDescription": "Correction Tape With Grip",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.19,
      "Unit_Cost": 3.63,
      "Margin": 0.5,
      "Elasticity": -1.398107582,
      "Base_Units": 36,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "209, "
    },
    {
      "ID": 210,
      "UpcCode": 76460802319,
      "vendorName": "DAS",
      "ProductDescription": "Flourescent Highlighter 3Pk",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 4.04,
      "Unit_Cost": 1.69,
      "Margin": 0.58,
      "Elasticity": -1.17252437,
      "Base_Units": 212,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "210, "
    },
    {
      "ID": 211,
      "UpcCode": 76460802711,
      "vendorName": "DAS",
      "ProductDescription": "Bazic 3 Round 2X Hndhld Magnfr 4X Bif",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 2.38,
      "Margin": 0.7,
      "Elasticity": -1.381768989,
      "Base_Units": 41,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "211, "
    },
    {
      "ID": 212,
      "UpcCode": 76460803201,
      "vendorName": "DAS",
      "ProductDescription": "Bazic Single Hole Paper Punch",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 1.88,
      "Margin": 0.53,
      "Elasticity": -2.055919622,
      "Base_Units": 244,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "212, "
    },
    {
      "ID": 213,
      "UpcCode": 76460805074,
      "vendorName": "DAS",
      "ProductDescription": "Bazic 2Part Carbonless Cashrent Rct Bk",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.37,
      "Margin": 0.31,
      "Elasticity": -1.375152119,
      "Base_Units": 250,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Stationary",
      "FIELD15": "213, "
    },
    {
      "ID": 351,
      "UpcCode": 4546480280,
      "vendorName": "DAS",
      "ProductDescription": "Craftsman 10In1 Mini Multi Tool 12Pc Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 119.88,
      "Unit_Cost": 55.68,
      "Margin": 0.54,
      "Elasticity": -1.907874415,
      "Base_Units": 218,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Tools",
      "FIELD15": "351, "
    },
    {
      "ID": 352,
      "UpcCode": 82090900128,
      "vendorName": "DAS",
      "ProductDescription": "Craftsman 14 In 1 Multi Purpose Tool",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 10.36,
      "Margin": 0.59,
      "Elasticity": -1.896399025,
      "Base_Units": 44,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Tools",
      "FIELD15": "352, "
    },
    {
      "ID": 161,
      "UpcCode": 4546480663,
      "vendorName": "DAS",
      "ProductDescription": "Roadpro 100Ct Antibacterial Wipes Conta",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 13.29,
      "Unit_Cost": 6.74,
      "Margin": 0.49,
      "Elasticity": -1.456560488,
      "Base_Units": 15,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Cleaning Supplies",
      "FIELD15": "161, "
    },
    {
      "ID": 162,
      "UpcCode": 4546480651,
      "vendorName": "DAS",
      "ProductDescription": "Roadpro 2 Oz Hand Sanitizer 24Pc Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 3.29,
      "Unit_Cost": 1.39,
      "Margin": 0.58,
      "Elasticity": -1.723052169,
      "Base_Units": 29,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Cleaning Supplies",
      "FIELD15": "162, "
    },
    {
      "ID": 163,
      "UpcCode": 86000127731,
      "vendorName": "DAS",
      "ProductDescription": "Antibacterial Wet Wipes",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.07,
      "Margin": 0.46,
      "Elasticity": -1.574726431,
      "Base_Units": 201,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Cleaning Supplies",
      "FIELD15": "163, "
    },
    {
      "ID": 164,
      "UpcCode": 86000127737,
      "vendorName": "DAS",
      "ProductDescription": "Hand Sanitzer 2 Oz.",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.4,
      "Margin": 0.53,
      "Elasticity": -0.9388601627,
      "Base_Units": 154,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Cleaning Supplies",
      "FIELD15": "164, "
    },
    {
      "ID": 166,
      "UpcCode": 4546480388,
      "vendorName": "DAS",
      "ProductDescription": "Dust Pan",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.89,
      "Unit_Cost": 6.46,
      "Margin": 0.57,
      "Elasticity": -0.8822928249,
      "Base_Units": 220,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Cleaning Supplies",
      "FIELD15": "166, "
    },
    {
      "ID": 304,
      "UpcCode": 86737600021,
      "vendorName": "DAS",
      "ProductDescription": "Nano Fiber Woven Cloth 2 Pack",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.68,
      "Margin": 0.49,
      "Elasticity": -2.209450911,
      "Base_Units": 129,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Cleaning Supplies",
      "FIELD15": "304, "
    },
    {
      "ID": 353,
      "UpcCode": 4546480627,
      "vendorName": "DAS",
      "ProductDescription": "Rp 1Oz Hand Sanitizer 50Pc Fishbowl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 2.156666667,
      "Unit_Cost": 1.06,
      "Margin": 0.51,
      "Elasticity": -1.237219515,
      "Base_Units": 157,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Cleaning Supplies",
      "FIELD15": "353, "
    },
    {
      "ID": 240,
      "UpcCode": 85441000431,
      "vendorName": "DAS",
      "ProductDescription": "Soap On A Rope Tactical Scrubber",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 10.87,
      "Margin": 0.46,
      "Elasticity": -1.11834953,
      "Base_Units": 21,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "240, "
    },
    {
      "ID": 241,
      "UpcCode": 85000867502,
      "vendorName": "DAS",
      "ProductDescription": "Shampoo Puck Gold Rush",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 8.16,
      "Margin": 0.46,
      "Elasticity": -1.48024292,
      "Base_Units": 124,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "241, "
    },
    {
      "ID": 242,
      "UpcCode": 85000867503,
      "vendorName": "DAS",
      "ProductDescription": "Shampoo Puck Barrel Char",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 8.16,
      "Margin": 0.46,
      "Elasticity": -1.475210865,
      "Base_Units": 225,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "242, "
    },
    {
      "ID": 251,
      "UpcCode": 4546480253,
      "vendorName": "DAS",
      "ProductDescription": "4 Piece Shaving Set Tiger Eye",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 19.62,
      "Margin": 0.44,
      "Elasticity": -1.187214764,
      "Base_Units": 182,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "251, "
    },
    {
      "ID": 252,
      "UpcCode": 4546480254,
      "vendorName": "DAS",
      "ProductDescription": "3 Piece Shaving Set Black",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.96,
      "Margin": 0.43,
      "Elasticity": -1.080666979,
      "Base_Units": 121,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "252, "
    },
    {
      "ID": 253,
      "UpcCode": 4546480255,
      "vendorName": "DAS",
      "ProductDescription": "3 Piece Shaving Set White",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.96,
      "Margin": 0.43,
      "Elasticity": -0.669295038,
      "Base_Units": 195,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "253, "
    },
    {
      "ID": 254,
      "UpcCode": 4546480256,
      "vendorName": "DAS",
      "ProductDescription": "Sharpening Strop",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.47,
      "Margin": 0.5,
      "Elasticity": -0.6,
      "Base_Units": 201,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "254, "
    },
    {
      "ID": 255,
      "UpcCode": 4546480257,
      "vendorName": "DAS",
      "ProductDescription": "Shaving Bowl",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.99,
      "Margin": 0.5,
      "Elasticity": -0.7050232386,
      "Base_Units": 43,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "255, "
    },
    {
      "ID": 256,
      "UpcCode": 4546480258,
      "vendorName": "DAS",
      "ProductDescription": "Toiletry Bag",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 12.99,
      "Unit_Cost": 6.59,
      "Margin": 0.49,
      "Elasticity": -1.373448458,
      "Base_Units": 150,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "256, "
    },
    {
      "ID": 257,
      "UpcCode": 4546480259,
      "vendorName": "DAS",
      "ProductDescription": "Beard Comb",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 2.95,
      "Margin": 0.51,
      "Elasticity": -0.8725583924,
      "Base_Units": 53,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "257, "
    },
    {
      "ID": 258,
      "UpcCode": 4546480260,
      "vendorName": "DAS",
      "ProductDescription": "German Razor",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 7.57,
      "Margin": 0.49,
      "Elasticity": -1.46756349,
      "Base_Units": 66,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "258, "
    },
    {
      "ID": 285,
      "UpcCode": 85000867541,
      "vendorName": "DAS",
      "ProductDescription": "Cold Shower Tower Counter Display",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 0.99,
      "Unit_Cost": 0.54,
      "Margin": 0.45,
      "Elasticity": -1.809046702,
      "Base_Units": 19,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "285, "
    },
    {
      "ID": 286,
      "UpcCode": 85000867544,
      "vendorName": "DAS",
      "ProductDescription": "Beard Balm And Oil Pdq",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 8.16,
      "Margin": 0.46,
      "Elasticity": -1.068486988,
      "Base_Units": 121,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "286, "
    },
    {
      "ID": 342,
      "UpcCode": 85000353997,
      "vendorName": "DAS",
      "ProductDescription": "Thick Liquid Shower Soap Naval Suprema",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 6.53,
      "Margin": 0.41,
      "Elasticity": -1.360767009,
      "Base_Units": 130,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "342, "
    },
    {
      "ID": 343,
      "UpcCode": 85000353998,
      "vendorName": "DAS",
      "ProductDescription": "Thick Liquid Shower Soap Old Glory",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 6.53,
      "Margin": 0.41,
      "Elasticity": -1.365853602,
      "Base_Units": 119,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "343, "
    },
    {
      "ID": 344,
      "UpcCode": 85441000465,
      "vendorName": "DAS",
      "ProductDescription": "Best Damn Beard Oil",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 33.99,
      "Unit_Cost": 18.67,
      "Margin": 0.45,
      "Elasticity": -1.446606231,
      "Base_Units": 112,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "344, "
    },
    {
      "ID": 404,
      "UpcCode": 85000867507,
      "vendorName": "DAS",
      "ProductDescription": "Bloody Knuckles Clip Strip 16 Pc",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 8.16,
      "Margin": 0.46,
      "Elasticity": -1.15463729,
      "Base_Units": 54,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "404, "
    },
    {
      "ID": 450,
      "UpcCode": 85030000858,
      "vendorName": "DAS",
      "ProductDescription": "News Anchor Thickening Tonic",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 16.99,
      "Unit_Cost": 10.14,
      "Margin": 0.4,
      "Elasticity": -0.6424901719,
      "Base_Units": 99,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "450, "
    },
    {
      "ID": 451,
      "UpcCode": 85030000859,
      "vendorName": "DAS",
      "ProductDescription": "Best Beard Wash",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 8.16,
      "Margin": 0.46,
      "Elasticity": -0.9482834025,
      "Base_Units": 158,
      "Level1": "General Merch",
      "Level2": "Trucker Merch",
      "Level3": "Trucker Grooming Accessories",
      "FIELD15": "451, "
    },
    {
      "ID": 8,
      "UpcCode": 65595600121,
      "vendorName": "McLane",
      "ProductDescription": "Snake Bitez 7Oz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2.586766667,
      "Margin": 0.35,
      "Elasticity": -0.6,
      "Base_Units": 245,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Bulk Candy",
      "FIELD15": "8, "
    },
    {
      "ID": 690,
      "UpcCode": 86036100263,
      "vendorName": "McLane",
      "ProductDescription": "Rev Caff Sprmnt Gum 6Ct",
      "BrandName": "REV",
      "CaseSize": 0,
      "Unit_Price": 3.373333333,
      "Unit_Cost": 1.578616667,
      "Margin": 0.53,
      "Elasticity": -1.129226459,
      "Base_Units": 55,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Gum",
      "FIELD15": "690, "
    },
    {
      "ID": 691,
      "UpcCode": 85001566111,
      "vendorName": "McLane",
      "ProductDescription": "Rev Es Sprmnt Caff Gum",
      "BrandName": "REV",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.6383,
      "Margin": 0.45,
      "Elasticity": -1.303682331,
      "Base_Units": 28,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Gum",
      "FIELD15": "691, "
    },
    {
      "ID": 692,
      "UpcCode": 85001566131,
      "vendorName": "McLane",
      "ProductDescription": "Rev Es Mnt Caff Gum",
      "BrandName": "REV",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.6383,
      "Margin": 0.45,
      "Elasticity": -1.446202184,
      "Base_Units": 208,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Gum",
      "FIELD15": "692, "
    },
    {
      "ID": 693,
      "UpcCode": 86036100261,
      "vendorName": "McLane",
      "ProductDescription": "Rev Caff Pprmnt Gum",
      "BrandName": "REV",
      "CaseSize": 0,
      "Unit_Price": 3.373333333,
      "Unit_Cost": 1.578616667,
      "Margin": 0.53,
      "Elasticity": -0.8643863908,
      "Base_Units": 114,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Gum",
      "FIELD15": "693, "
    },
    {
      "ID": 13,
      "UpcCode": 4142002579,
      "vendorName": "McLane",
      "ProductDescription": "N&L Shell Shchd Bogo 72Ct",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 143.28,
      "Unit_Cost": 31,
      "Margin": 0.78,
      "Elasticity": -1.098718304,
      "Base_Units": 78,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Non-Choc Bars",
      "FIELD15": "13, "
    },
    {
      "ID": 4,
      "UpcCode": 4137610156,
      "vendorName": "McLane",
      "ProductDescription": "Frkfrd Nick Jr Cndy Fansoz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.15,
      "Margin": 0.47,
      "Elasticity": -0.8968312341,
      "Base_Units": 79,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Nov/Seasnl Cdy",
      "FIELD15": "4, "
    },
    {
      "ID": 5,
      "UpcCode": 4137610235,
      "vendorName": "McLane",
      "ProductDescription": "Frkfrd Krbby Pty Tboz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.7663,
      "Margin": 0.49,
      "Elasticity": -0.9287482705,
      "Base_Units": 191,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Nov/Seasnl Cdy",
      "FIELD15": "5, "
    },
    {
      "ID": 6,
      "UpcCode": 4137610387,
      "vendorName": "McLane",
      "ProductDescription": "Frnkfrd 3D Gmmy Animaloz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 0.9867,
      "Margin": 0.5,
      "Elasticity": -1.132514906,
      "Base_Units": 92,
      "Level1": "Grocery",
      "Level2": "Candy",
      "Level3": "Nov/Seasnl Cdy",
      "FIELD15": "6, "
    },
    {
      "ID": 11,
      "UpcCode": 78656014050,
      "vendorName": "McLane",
      "ProductDescription": "Met Rx Rtd51 Vanilla",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2.2267,
      "Margin": 0.44,
      "Elasticity": -0.7830209339,
      "Base_Units": 214,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Hlth/Energy Bars",
      "FIELD15": "11, "
    },
    {
      "ID": 720,
      "UpcCode": 4810709592,
      "vendorName": "SRP",
      "ProductDescription": "Lean Shake Chocolate",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2.39,
      "Margin": 0.4,
      "Elasticity": -0.6018156938,
      "Base_Units": 74,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Hlth/Energy Bars",
      "FIELD15": "720, "
    },
    {
      "ID": 721,
      "UpcCode": 4810709598,
      "vendorName": "SRP",
      "ProductDescription": "Lean Shake Strawberry",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2.39,
      "Margin": 0.4,
      "Elasticity": -1.580909071,
      "Base_Units": 80,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Hlth/Energy Bars",
      "FIELD15": "721, "
    },
    {
      "ID": 731,
      "UpcCode": 4810712650,
      "vendorName": "SRP",
      "ProductDescription": "Lean Bar Chocolate Chip",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 6.59,
      "Margin": 0.4,
      "Elasticity": -1.523260987,
      "Base_Units": 176,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Hlth/Energy Bars",
      "FIELD15": "731, "
    },
    {
      "ID": 734,
      "UpcCode": 4810715500,
      "vendorName": "SRP",
      "ProductDescription": "Lean Bar Birthday Cake",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 6.59,
      "Margin": 0.4,
      "Elasticity": -1.095047703,
      "Base_Units": 20,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Hlth/Energy Bars",
      "FIELD15": "734, "
    },
    {
      "ID": 988,
      "UpcCode": 72225250003,
      "vendorName": "McLane",
      "ProductDescription": "Clif Oatmeal Raisin Wlnut 1Ct",
      "BrandName": "CLIF",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.0917,
      "Margin": 0.45,
      "Elasticity": -0.9073249401,
      "Base_Units": 51,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Hlth/Energy Bars",
      "FIELD15": "988, "
    },
    {
      "ID": 116,
      "UpcCode": 76170619013,
      "vendorName": "McLane",
      "ProductDescription": "Cvp Bleach Ss",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 2.89,
      "Unit_Cost": 1.96,
      "Margin": 0.32,
      "Elasticity": -1.378191491,
      "Base_Units": 106,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Laundry Care",
      "FIELD15": "116, "
    },
    {
      "ID": 117,
      "UpcCode": 76170618038,
      "vendorName": "McLane",
      "ProductDescription": "Cvp Lndry Scent Booster 4.9Oz",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2.92,
      "Margin": 0.27,
      "Elasticity": -0.6556401412,
      "Base_Units": 178,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Laundry Care",
      "FIELD15": "117, "
    },
    {
      "ID": 119,
      "UpcCode": 76170619012,
      "vendorName": "McLane",
      "ProductDescription": "Cvp Bleach 43Oz",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 2.47,
      "Margin": 0.45,
      "Elasticity": -1.669344097,
      "Base_Units": 41,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Laundry Care",
      "FIELD15": "119, "
    },
    {
      "ID": 12,
      "UpcCode": 76170612208,
      "vendorName": "McLane",
      "ProductDescription": "Cvp Hmtwn Mkt Sgr Cnstr",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.79,
      "Margin": 0.4,
      "Elasticity": -1.022883852,
      "Base_Units": 93,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Oth Edible Groc",
      "FIELD15": "12, "
    },
    {
      "ID": 112,
      "UpcCode": 76170626158,
      "vendorName": "McLane",
      "ProductDescription": "Beau Dacious Dog Leash",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 10.99,
      "Unit_Cost": 6.5933,
      "Margin": 0.4,
      "Elasticity": -0.8146493643,
      "Base_Units": 125,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Pet Care",
      "FIELD15": "112, "
    },
    {
      "ID": 118,
      "UpcCode": 76170626156,
      "vendorName": "McLane",
      "ProductDescription": "Beau Dacious Pb Big Bones 1Ct",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.6383,
      "Margin": 0.45,
      "Elasticity": -1.407599715,
      "Base_Units": 40,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Pet Care",
      "FIELD15": "118, "
    },
    {
      "ID": 25,
      "UpcCode": 4203710725,
      "vendorName": "McLane",
      "ProductDescription": "Bc Sinus Congestion",
      "BrandName": "BC",
      "CaseSize": 0,
      "Unit_Price": 4.09,
      "Unit_Cost": 1.3817,
      "Margin": 0.66,
      "Elasticity": -0.8491455603,
      "Base_Units": 47,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Cough & Cold",
      "FIELD15": "25, "
    },
    {
      "ID": 113,
      "UpcCode": 76170616843,
      "vendorName": "McLane",
      "ProductDescription": "Cvp Bobby Pinshair Ties",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 3.59,
      "Unit_Cost": 1.0767,
      "Margin": 0.7,
      "Elasticity": -0.7224516625,
      "Base_Units": 241,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Grooming Aids",
      "FIELD15": "113, "
    },
    {
      "ID": 114,
      "UpcCode": 76170616844,
      "vendorName": "McLane",
      "ProductDescription": "Cvp Coil Hair Ties",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 3.89,
      "Unit_Cost": 1.4983,
      "Margin": 0.61,
      "Elasticity": -0.6000238468,
      "Base_Units": 220,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Grooming Aids",
      "FIELD15": "114, "
    },
    {
      "ID": 115,
      "UpcCode": 76170616845,
      "vendorName": "McLane",
      "ProductDescription": "Cvp Thermometer",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 5.64,
      "Unit_Cost": 3.01,
      "Margin": 0.47,
      "Elasticity": -1.577411687,
      "Base_Units": 103,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Other Hbc",
      "FIELD15": "115, "
    },
    {
      "ID": 111,
      "UpcCode": 36671568630,
      "vendorName": "McLane",
      "ProductDescription": "Antibacterial Wipes 30Ct",
      "BrandName": "CVP",
      "CaseSize": 0,
      "Unit_Price": 2.99,
      "Unit_Cost": 1.69,
      "Margin": 0.43,
      "Elasticity": -0.8917355099,
      "Base_Units": 89,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Skin Care",
      "FIELD15": "111, "
    },
    {
      "ID": 287,
      "UpcCode": 89354300158,
      "vendorName": "DAS",
      "ProductDescription": "Joint Lube Joint Care 120 Ct Bottle",
      "BrandName": "DAS",
      "CaseSize": 0,
      "Unit_Price": 29.99,
      "Unit_Cost": 16.1,
      "Margin": 0.46,
      "Elasticity": -1.243588737,
      "Base_Units": 216,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "287, "
    },
    {
      "ID": 454,
      "UpcCode": 4810706154,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Melatonin Gummies",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.2,
      "Margin": 0.35,
      "Elasticity": -1.754336775,
      "Base_Units": 76,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "454, "
    },
    {
      "ID": 455,
      "UpcCode": 4810717719,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Vitamin C 500 Tablets",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.7442,
      "Margin": 0.45,
      "Elasticity": -0.7750890192,
      "Base_Units": 75,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "455, "
    },
    {
      "ID": 456,
      "UpcCode": 4810717749,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Zinc Lozenges",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 8.99,
      "Unit_Cost": 4.9446,
      "Margin": 0.45,
      "Elasticity": -1.542236519,
      "Base_Units": 102,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "456, "
    },
    {
      "ID": 457,
      "UpcCode": 4810717768,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Zinc 30Mg",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.74,
      "Margin": 0.45,
      "Elasticity": -0.6,
      "Base_Units": 60,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "457, "
    },
    {
      "ID": 458,
      "UpcCode": 4810722196,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Melatonin 10Mg 60Ct",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -0.9182610362,
      "Base_Units": 219,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "458, "
    },
    {
      "ID": 459,
      "UpcCode": 4810722260,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Vitamin C Gummies",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 8.99,
      "Unit_Cost": 5.1242,
      "Margin": 0.43,
      "Elasticity": -1.081513567,
      "Base_Units": 76,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "459, "
    },
    {
      "ID": 460,
      "UpcCode": 4810722550,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Mega Men Multi Gummies",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 8.99,
      "Unit_Cost": 5.52,
      "Margin": 0.39,
      "Elasticity": -1.124880838,
      "Base_Units": 32,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "460, "
    },
    {
      "ID": 461,
      "UpcCode": 4810722721,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Mens Staminol Health",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.7442,
      "Margin": 0.45,
      "Elasticity": -1.464641055,
      "Base_Units": 154,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "461, "
    },
    {
      "ID": 462,
      "UpcCode": 4810722845,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Multi Immune Gummies",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.3942,
      "Margin": 0.45,
      "Elasticity": -1.356273397,
      "Base_Units": 224,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "462, "
    },
    {
      "ID": 463,
      "UpcCode": 4810722416,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Burn Mf Powder Single Pack Gummy Worm",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.09,
      "Margin": 0.45,
      "Elasticity": -1.361905484,
      "Base_Units": 92,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "463, "
    },
    {
      "ID": 464,
      "UpcCode": 4810722431,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Slimvance Powder Single Pack Iced Tea",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.09,
      "Margin": 0.45,
      "Elasticity": -0.9694154748,
      "Base_Units": 227,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "464, "
    },
    {
      "ID": 465,
      "UpcCode": 4810722491,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Burn Mf Powder Single Pack Orng Mngo",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 1.99,
      "Unit_Cost": 1.09,
      "Margin": 0.45,
      "Elasticity": -1.077006559,
      "Base_Units": 241,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "465, "
    },
    {
      "ID": 466,
      "UpcCode": 4810721158,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Gnc Melatonin 10Mg 60Ct",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 3.84,
      "Margin": 0.45,
      "Elasticity": -2.114089081,
      "Base_Units": 98,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "466, "
    },
    {
      "ID": 467,
      "UpcCode": 4810723265,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Tower Display Empty",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.2,
      "Margin": 0.35,
      "Elasticity": -1.123074258,
      "Base_Units": 95,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "467, "
    },
    {
      "ID": 468,
      "UpcCode": 4810723266,
      "vendorName": "GNC Holding Inc",
      "ProductDescription": "Counter Display Empty",
      "BrandName": "GNC",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 5.2,
      "Margin": 0.35,
      "Elasticity": -0.8135230526,
      "Base_Units": 97,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "468, "
    },
    {
      "ID": 719,
      "UpcCode": 4810720274,
      "vendorName": "SRP",
      "ProductDescription": "Womens Ultra Mega Multi",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 3.6,
      "Margin": 0.55,
      "Elasticity": -1.803313426,
      "Base_Units": 61,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "719, "
    },
    {
      "ID": 722,
      "UpcCode": 4810711613,
      "vendorName": "SRP",
      "ProductDescription": "Apple Cider Complete 90 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 9.99,
      "Unit_Cost": 5.96,
      "Margin": 0.4,
      "Elasticity": -1.172554239,
      "Base_Units": 89,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "722, "
    },
    {
      "ID": 723,
      "UpcCode": 4810711848,
      "vendorName": "SRP",
      "ProductDescription": "Vitamin B12 Soft Chews 60 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 14.99,
      "Unit_Cost": 9.01,
      "Margin": 0.4,
      "Elasticity": -0.6221012541,
      "Base_Units": 224,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "723, "
    },
    {
      "ID": 724,
      "UpcCode": 4810712037,
      "vendorName": "SRP",
      "ProductDescription": "Mega Men One Daily 60 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 6.99,
      "Unit_Cost": 4.17,
      "Margin": 0.4,
      "Elasticity": -0.9666647323,
      "Base_Units": 149,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "724, "
    },
    {
      "ID": 725,
      "UpcCode": 4810712038,
      "vendorName": "SRP",
      "ProductDescription": "Mega Men 50 Plus One Daily 60 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.82,
      "Margin": 0.4,
      "Elasticity": -1.698356635,
      "Base_Units": 174,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "725, "
    },
    {
      "ID": 726,
      "UpcCode": 4810712119,
      "vendorName": "SRP",
      "ProductDescription": "Coq10 100Mg 60 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 8.99,
      "Unit_Cost": 5.43,
      "Margin": 0.4,
      "Elasticity": -2.012278897,
      "Base_Units": 164,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "726, "
    },
    {
      "ID": 727,
      "UpcCode": 4810712169,
      "vendorName": "SRP",
      "ProductDescription": "Vitmin B2 100Mg 100 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 4.99,
      "Unit_Cost": 2.99,
      "Margin": 0.4,
      "Elasticity": -1.025052393,
      "Base_Units": 117,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "727, "
    },
    {
      "ID": 728,
      "UpcCode": 4810712273,
      "vendorName": "SRP",
      "ProductDescription": "Calcium Plus 1000 180 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 7.99,
      "Unit_Cost": 4.79,
      "Margin": 0.4,
      "Elasticity": -0.9679498024,
      "Base_Units": 114,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "728, "
    },
    {
      "ID": 729,
      "UpcCode": 4810712300,
      "vendorName": "SRP",
      "ProductDescription": "Vitamin C 500Mg 100 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 5.99,
      "Unit_Cost": 3.58,
      "Margin": 0.4,
      "Elasticity": -0.7999920664,
      "Base_Units": 240,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "729, "
    },
    {
      "ID": 730,
      "UpcCode": 4810712370,
      "vendorName": "SRP",
      "ProductDescription": "Melatonin 3Mg 60 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2.39,
      "Margin": 0.4,
      "Elasticity": -1.420448828,
      "Base_Units": 21,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "730, "
    },
    {
      "ID": 732,
      "UpcCode": 4810712873,
      "vendorName": "SRP",
      "ProductDescription": "Milk Thistle 200Mg 100 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 15.99,
      "Unit_Cost": 9.65,
      "Margin": 0.4,
      "Elasticity": -1.359497169,
      "Base_Units": 248,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "732, "
    },
    {
      "ID": 733,
      "UpcCode": 4810715491,
      "vendorName": "SRP",
      "ProductDescription": "Mega Men 90 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 11.99,
      "Unit_Cost": 7.23,
      "Margin": 0.4,
      "Elasticity": -0.6,
      "Base_Units": 203,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "733, "
    },
    {
      "ID": 735,
      "UpcCode": 4810715568,
      "vendorName": "SRP",
      "ProductDescription": "Turmeric Sport 60 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 24.99,
      "Unit_Cost": 15.02,
      "Margin": 0.4,
      "Elasticity": -0.9681125442,
      "Base_Units": 111,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "735, "
    },
    {
      "ID": 736,
      "UpcCode": 4810715882,
      "vendorName": "SRP",
      "ProductDescription": "Womens Ultra Mega 50 Plus 60 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 8.99,
      "Unit_Cost": 5.38,
      "Margin": 0.4,
      "Elasticity": -1.389839773,
      "Base_Units": 179,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "736, "
    },
    {
      "ID": 737,
      "UpcCode": 4810717372,
      "vendorName": "SRP",
      "ProductDescription": "Probiotic Complex Daily 30 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 21.99,
      "Unit_Cost": 13.14,
      "Margin": 0.4,
      "Elasticity": -1.449631986,
      "Base_Units": 163,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "737, "
    },
    {
      "ID": 907,
      "UpcCode": 4810717388,
      "vendorName": "SRP",
      "ProductDescription": "Probiotic Complex Daily 100 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 8.99,
      "Unit_Cost": 5.41,
      "Margin": 0.4,
      "Elasticity": -1.34308508,
      "Base_Units": 53,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "907, "
    },
    {
      "ID": 917,
      "UpcCode": 89354300124,
      "vendorName": "SRP",
      "ProductDescription": "Power Wash 90 Count",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 19.99,
      "Unit_Cost": 15,
      "Margin": 0.25,
      "Elasticity": -1.773564177,
      "Base_Units": 168,
      "Level1": "Grocery",
      "Level2": "HBA/Other",
      "Level3": "Vit/Suplmnts",
      "FIELD15": "917, "
    },
    {
      "ID": 14,
      "UpcCode": 83815400323,
      "vendorName": "McLane",
      "ProductDescription": "Nml Mld Meat Stk Oz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.9371,
      "Margin": 0.37,
      "Elasticity": -1.749843562,
      "Base_Units": 148,
      "Level1": "Grocery",
      "Level2": "Meat Snack",
      "Level3": "Meat Snacks",
      "FIELD15": "14, "
    },
    {
      "ID": 15,
      "UpcCode": 83815400426,
      "vendorName": "McLane",
      "ProductDescription": "Nml Blk Pepr Meat Stk Oz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.9638,
      "Margin": 0.35,
      "Elasticity": -1.429305689,
      "Base_Units": 44,
      "Level1": "Grocery",
      "Level2": "Meat Snack",
      "Level3": "Meat Snacks",
      "FIELD15": "15, "
    },
    {
      "ID": 16,
      "UpcCode": 83815400724,
      "vendorName": "McLane",
      "ProductDescription": "Nml Ht Meat Stk Oz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 1.49,
      "Unit_Cost": 0.9638,
      "Margin": 0.35,
      "Elasticity": -3.122858449,
      "Base_Units": 126,
      "Level1": "Grocery",
      "Level2": "Meat Snack",
      "Level3": "Meat Snacks",
      "FIELD15": "16, "
    },
    {
      "ID": 985,
      "UpcCode": 81601201258,
      "vendorName": "McLane",
      "ProductDescription": "Bigs Ks Org Sr Sd Ss",
      "BrandName": "BIGS",
      "CaseSize": 0,
      "Unit_Price": 2.19,
      "Unit_Cost": 1.1625,
      "Margin": 0.47,
      "Elasticity": -1.550660242,
      "Base_Units": 196,
      "Level1": "Grocery",
      "Level2": "Salty Snack",
      "Level3": "Nuts Seeds (Take Home)",
      "FIELD15": "985, "
    },
    {
      "ID": 986,
      "UpcCode": 81601201021,
      "vendorName": "McLane",
      "ProductDescription": "Bigs Takis Snfl Seed 5.35Oz",
      "BrandName": "BIGS",
      "CaseSize": 0,
      "Unit_Price": 2.79,
      "Unit_Cost": 1.7975,
      "Margin": 0.36,
      "Elasticity": -1.011713588,
      "Base_Units": 186,
      "Level1": "Grocery",
      "Level2": "Salty Snack",
      "Level3": "Nuts/Seeds",
      "FIELD15": "986, "
    },
    {
      "ID": 981,
      "UpcCode": 1397102100,
      "vendorName": "Frito Lay",
      "ProductDescription": "Bare Baked Crunchy Fuji Red Apple 3.4Z",
      "BrandName": "BARE",
      "CaseSize": 0,
      "Unit_Price": 5.79,
      "Unit_Cost": 3.48,
      "Margin": 0.4,
      "Elasticity": -0.7164686311,
      "Base_Units": 81,
      "Level1": "Grocery",
      "Level2": "Salty Snack",
      "Level3": "Other Salty Snacks",
      "FIELD15": "981, "
    },
    {
      "ID": 982,
      "UpcCode": 1397102101,
      "vendorName": "Frito Lay",
      "ProductDescription": "Bare Baked Crunchy Apple Chips Cinn 3.4Z",
      "BrandName": "BARE",
      "CaseSize": 0,
      "Unit_Price": 5.79,
      "Unit_Cost": 3.48,
      "Margin": 0.4,
      "Elasticity": -1.034752065,
      "Base_Units": 215,
      "Level1": "Grocery",
      "Level2": "Salty Snack",
      "Level3": "Other Salty Snacks",
      "FIELD15": "982, "
    },
    {
      "ID": 983,
      "UpcCode": 1397103000,
      "vendorName": "Frito Lay",
      "ProductDescription": "Bare Baked Crunchy Coconut Chips 3.3Z",
      "BrandName": "BARE",
      "CaseSize": 0,
      "Unit_Price": 5.79,
      "Unit_Cost": 3.48,
      "Margin": 0.4,
      "Elasticity": -1.789184409,
      "Base_Units": 229,
      "Level1": "Grocery",
      "Level2": "Salty Snack",
      "Level3": "Other Salty Snacks",
      "FIELD15": "983, "
    },
    {
      "ID": 984,
      "UpcCode": 1397104000,
      "vendorName": "Frito Lay",
      "ProductDescription": "Bare Baked Crunchy Banana Chips 2.7Z",
      "BrandName": "BARE",
      "CaseSize": 0,
      "Unit_Price": 5.79,
      "Unit_Cost": 3.48,
      "Margin": 0.4,
      "Elasticity": -1.773097727,
      "Base_Units": 114,
      "Level1": "Grocery",
      "Level2": "Salty Snack",
      "Level3": "Other Salty Snacks",
      "FIELD15": "984, "
    },
    {
      "ID": 2,
      "UpcCode": 75752802651,
      "vendorName": "McLane",
      "ProductDescription": "Pop Fuego Ppcrn",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 3.49,
      "Unit_Cost": 2.047533333,
      "Margin": 0.41,
      "Elasticity": -1.027175447,
      "Base_Units": 104,
      "Level1": "Grocery",
      "Level2": "Salty Snack",
      "Level3": "Pkgd Rte Popcrn",
      "FIELD15": "2, "
    },
    {
      "ID": 1,
      "UpcCode": 4400006965,
      "vendorName": "McLane",
      "ProductDescription": "Oreo Team Usa Ks 2.54Oz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 1.69,
      "Unit_Cost": 1.0917,
      "Margin": 0.35,
      "Elasticity": -0.7211727176,
      "Base_Units": 82,
      "Level1": "Grocery",
      "Level2": "Sweet Snack",
      "Level3": "Cookies",
      "FIELD15": "1, "
    },
    {
      "ID": 17,
      "UpcCode": 81637402090,
      "vendorName": "McLane",
      "ProductDescription": "7Days Cake Br Choc Oz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 1.29,
      "Unit_Cost": 0.8975,
      "Margin": 0.3,
      "Elasticity": -0.9588988102,
      "Base_Units": 118,
      "Level1": "Grocery",
      "Level2": "Sweet Snack",
      "Level3": "Snk Cakes/Past",
      "FIELD15": "17, "
    },
    {
      "ID": 18,
      "UpcCode": 81637402091,
      "vendorName": "McLane",
      "ProductDescription": "7Days Cake Br Mx Berry Oz",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 1.29,
      "Unit_Cost": 0.8975,
      "Margin": 0.3,
      "Elasticity": -0.9228194736,
      "Base_Units": 80,
      "Level1": "Grocery",
      "Level2": "Sweet Snack",
      "Level3": "Snk Cakes/Past",
      "FIELD15": "18, "
    },
    {
      "ID": 7,
      "UpcCode": 7033065694,
      "vendorName": "McLane",
      "ProductDescription": "Bic Tattoo Ltr N Mrkr 78C",
      "BrandName": "5",
      "CaseSize": 0,
      "Unit_Price": 254.22,
      "Unit_Cost": 122.19,
      "Margin": 0.52,
      "Elasticity": -1.046641263,
      "Base_Units": 181,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "7, "
    },
    {
      "ID": 74,
      "UpcCode": 7033060988,
      "vendorName": "McLane",
      "ProductDescription": "Bic Chicago Cubs Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3282,
      "Margin": 0.42,
      "Elasticity": -1.550989929,
      "Base_Units": 28,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "74, "
    },
    {
      "ID": 75,
      "UpcCode": 7033061000,
      "vendorName": "McLane",
      "ProductDescription": "Bic Ny Yankees Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3282,
      "Margin": 0.42,
      "Elasticity": -1.073876207,
      "Base_Units": 239,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "75, "
    },
    {
      "ID": 76,
      "UpcCode": 7033065828,
      "vendorName": "McLane",
      "ProductDescription": "Bic Street Art Maxi Ltr Sngl",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1006,
      "Margin": 0.52,
      "Elasticity": -1.210218719,
      "Base_Units": 169,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "76, "
    },
    {
      "ID": 77,
      "UpcCode": 7033065830,
      "vendorName": "McLane",
      "ProductDescription": "Bic Retro Wave Ltr Sngl",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.39,
      "Unit_Cost": 1.1006,
      "Margin": 0.54,
      "Elasticity": -0.8241027968,
      "Base_Units": 89,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "77, "
    },
    {
      "ID": 78,
      "UpcCode": 7033065832,
      "vendorName": "McLane",
      "ProductDescription": "Bic Party Animal Maxi Ltr Sngl",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.39,
      "Unit_Cost": 1.1006,
      "Margin": 0.54,
      "Elasticity": -1.049310342,
      "Base_Units": 102,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "78, "
    },
    {
      "ID": 79,
      "UpcCode": 7033065849,
      "vendorName": "McLane",
      "ProductDescription": "Bic Street Art Maxi Ltr Pk",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 114.5,
      "Unit_Cost": 55.03,
      "Margin": 0.52,
      "Elasticity": -1.032023178,
      "Base_Units": 97,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "79, "
    },
    {
      "ID": 80,
      "UpcCode": 7033065851,
      "vendorName": "McLane",
      "ProductDescription": "Bic Retro Wave Ltr Pk",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 114.5,
      "Unit_Cost": 55.03,
      "Margin": 0.52,
      "Elasticity": -0.6,
      "Base_Units": 95,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "80, "
    },
    {
      "ID": 81,
      "UpcCode": 7033065854,
      "vendorName": "McLane",
      "ProductDescription": "Bic Party Animal Maxi Ltr Pk",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 114.5,
      "Unit_Cost": 55.03,
      "Margin": 0.52,
      "Elasticity": -0.8405799887,
      "Base_Units": 175,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "81, "
    },
    {
      "ID": 82,
      "UpcCode": 7033061060,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nfl Dolphins Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.39,
      "Unit_Cost": 1.3282,
      "Margin": 0.44,
      "Elasticity": -0.6748235718,
      "Base_Units": 216,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "82, "
    },
    {
      "ID": 83,
      "UpcCode": 7033061068,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nfl Patriots Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3282,
      "Margin": 0.42,
      "Elasticity": -1.482505521,
      "Base_Units": 216,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "83, "
    },
    {
      "ID": 84,
      "UpcCode": 7033061070,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nfl Jaguars Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.39,
      "Unit_Cost": 1.3282,
      "Margin": 0.44,
      "Elasticity": -1.492442889,
      "Base_Units": 173,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "84, "
    },
    {
      "ID": 85,
      "UpcCode": 7033061072,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nfl Eagles Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3282,
      "Margin": 0.42,
      "Elasticity": -1.054016842,
      "Base_Units": 239,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "85, "
    },
    {
      "ID": 86,
      "UpcCode": 7033061078,
      "vendorName": "McLane",
      "ProductDescription": "Bic Ny Giants Pro Series",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3282,
      "Margin": 0.42,
      "Elasticity": -1.679241521,
      "Base_Units": 234,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "86, "
    },
    {
      "ID": 87,
      "UpcCode": 7033061563,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nfl La Rms Lghtr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3296,
      "Margin": 0.42,
      "Elasticity": -0.9572018189,
      "Base_Units": 159,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "87, "
    },
    {
      "ID": 88,
      "UpcCode": 7033061569,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nfl Clevelnd Brwn Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3282,
      "Margin": 0.42,
      "Elasticity": -2.775328723,
      "Base_Units": 121,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "88, "
    },
    {
      "ID": 89,
      "UpcCode": 7033062091,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Houston Txn Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3282,
      "Margin": 0.42,
      "Elasticity": -1.231362024,
      "Base_Units": 185,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "89, "
    },
    {
      "ID": 90,
      "UpcCode": 7033062985,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nascar Drvrs Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1688,
      "Margin": 0.49,
      "Elasticity": -1.147525723,
      "Base_Units": 185,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "90, "
    },
    {
      "ID": 91,
      "UpcCode": 7033064328,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Hspnc Cultur",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 0.9562,
      "Margin": 0.58,
      "Elasticity": -1.454364088,
      "Base_Units": 226,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "91, "
    },
    {
      "ID": 92,
      "UpcCode": 7033064391,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Urban Music",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1688,
      "Margin": 0.49,
      "Elasticity": -1.209985068,
      "Base_Units": 103,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "92, "
    },
    {
      "ID": 93,
      "UpcCode": 7033064549,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Sturgis",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1688,
      "Margin": 0.49,
      "Elasticity": -1.782783218,
      "Base_Units": 151,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "93, "
    },
    {
      "ID": 94,
      "UpcCode": 7033064593,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Texas Tray",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 0.9562,
      "Margin": 0.58,
      "Elasticity": -0.9707364742,
      "Base_Units": 137,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "94, "
    },
    {
      "ID": 95,
      "UpcCode": 7033064628,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Classc Movie",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1688,
      "Margin": 0.49,
      "Elasticity": -2.72232935,
      "Base_Units": 219,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "95, "
    },
    {
      "ID": 96,
      "UpcCode": 7033064774,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nw Yrk Mxi Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 0.9562,
      "Margin": 0.58,
      "Elasticity": -1.636378995,
      "Base_Units": 152,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "96, "
    },
    {
      "ID": 97,
      "UpcCode": 7033065123,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Seasonal",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1006,
      "Margin": 0.52,
      "Elasticity": -1.344231417,
      "Base_Units": 149,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "97, "
    },
    {
      "ID": 98,
      "UpcCode": 7033065138,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Ca Tray",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.0732,
      "Margin": 0.53,
      "Elasticity": -1.989215685,
      "Base_Units": 122,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "98, "
    },
    {
      "ID": 99,
      "UpcCode": 7033065139,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Explrtn Tray",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 0.9668,
      "Margin": 0.58,
      "Elasticity": -1.030238371,
      "Base_Units": 92,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "99, "
    },
    {
      "ID": 100,
      "UpcCode": 7033065144,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Nature Tray",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 0.9668,
      "Margin": 0.58,
      "Elasticity": -0.8953204512,
      "Base_Units": 187,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "100, "
    },
    {
      "ID": 101,
      "UpcCode": 7033065177,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Lndscps Tray",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1006,
      "Margin": 0.52,
      "Elasticity": -0.7157178311,
      "Base_Units": 92,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "101, "
    },
    {
      "ID": 102,
      "UpcCode": 7033065199,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Guy Harvey",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1688,
      "Margin": 0.49,
      "Elasticity": -0.6669831673,
      "Base_Units": 31,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "102, "
    },
    {
      "ID": 103,
      "UpcCode": 7033061088,
      "vendorName": "McLane",
      "ProductDescription": "Bic Nfl Az Cardinals",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.3282,
      "Margin": 0.42,
      "Elasticity": -2.491777934,
      "Base_Units": 198,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "103, "
    },
    {
      "ID": 104,
      "UpcCode": 7033065382,
      "vendorName": "McLane",
      "ProductDescription": "Bic Mazi Ltr Bob Marley",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1688,
      "Margin": 0.49,
      "Elasticity": -2.257062898,
      "Base_Units": 125,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "104, "
    },
    {
      "ID": 105,
      "UpcCode": 7033065388,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Blown Glass",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1006,
      "Margin": 0.52,
      "Elasticity": -1.704543298,
      "Base_Units": 68,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "105, "
    },
    {
      "ID": 106,
      "UpcCode": 7033065392,
      "vendorName": "McLane",
      "ProductDescription": "Bic Cheers Ltrs",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.29,
      "Unit_Cost": 1.1006,
      "Margin": 0.52,
      "Elasticity": -1.470613058,
      "Base_Units": 222,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "106, "
    },
    {
      "ID": 107,
      "UpcCode": 7033065396,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr Spooky",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 2.39,
      "Unit_Cost": 1.1006,
      "Margin": 0.54,
      "Elasticity": -0.8434308472,
      "Base_Units": 223,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "107, "
    },
    {
      "ID": 108,
      "UpcCode": 7033065324,
      "vendorName": "McLane",
      "ProductDescription": "Bic Maxi Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 1.29,
      "Unit_Cost": 0.2642,
      "Margin": 0.8,
      "Elasticity": -1.483329131,
      "Base_Units": 27,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "108, "
    },
    {
      "ID": 109,
      "UpcCode": 7033065327,
      "vendorName": "McLane",
      "ProductDescription": "Maxi Ltr",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 1.89,
      "Unit_Cost": 0.7742,
      "Margin": 0.59,
      "Elasticity": -1.226281001,
      "Base_Units": 200,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "109, "
    },
    {
      "ID": 110,
      "UpcCode": 7033065152,
      "vendorName": "McLane",
      "ProductDescription": "Bic Mxd Ltr 9Tr 450Ct",
      "BrandName": "BIC",
      "CaseSize": 0,
      "Unit_Price": 408.11,
      "Unit_Cost": 201.13,
      "Margin": 0.51,
      "Elasticity": -1.701552771,
      "Base_Units": 190,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "110, "
    },
    {
      "ID": 745,
      "UpcCode": 65236900226,
      "vendorName": "SRP",
      "ProductDescription": "Torch Lighter 34.99",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 34.99,
      "Unit_Cost": 18,
      "Margin": 0.49,
      "Elasticity": -1.375277472,
      "Base_Units": 181,
      "Level1": "Tobacco",
      "Level2": "Smoker Accessories",
      "Level3": "Smoker Acc",
      "FIELD15": "745, "
    },
    {
      "ID": 951,
      "UpcCode": 60924990004,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Cool Mint 3Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -0.8244052212,
      "Base_Units": 127,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "951, "
    },
    {
      "ID": 952,
      "UpcCode": 60924990041,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Cool Mint 3Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.43,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -0.8774913909,
      "Base_Units": 204,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "952, "
    },
    {
      "ID": 953,
      "UpcCode": 60924990101,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Peppermint 3Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -0.6543598099,
      "Base_Units": 159,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "953, "
    },
    {
      "ID": 954,
      "UpcCode": 60924990702,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Citrus 6Mg .21 Oz Ca",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -1.874815389,
      "Base_Units": 147,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "954, "
    },
    {
      "ID": 955,
      "UpcCode": 60924990741,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Citrus 3Mg .21 Oz Ca Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.45,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -2.34253144,
      "Base_Units": 31,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "955, "
    },
    {
      "ID": 956,
      "UpcCode": 60924990742,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Citrus 6Mg .21 Oz Ca Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.43,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.315053386,
      "Base_Units": 202,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "956, "
    },
    {
      "ID": 957,
      "UpcCode": 60924990302,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Wintergrn 6Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -1.258273466,
      "Base_Units": 35,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "957, "
    },
    {
      "ID": 958,
      "UpcCode": 60924990342,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Wintergrn 6Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.43,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.560458998,
      "Base_Units": 196,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "958, "
    },
    {
      "ID": 959,
      "UpcCode": 60924990141,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Peppermint 3Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.45,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -0.8820254811,
      "Base_Units": 82,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "959, "
    },
    {
      "ID": 960,
      "UpcCode": 60924990201,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Spearmint 3Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -1.059319476,
      "Base_Units": 161,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "960, "
    },
    {
      "ID": 961,
      "UpcCode": 60924990241,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Spearmint 3Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.43,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.235265099,
      "Base_Units": 27,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "961, "
    },
    {
      "ID": 962,
      "UpcCode": 60924990341,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Wintergrn 3Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.43,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.924533687,
      "Base_Units": 236,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "962, "
    },
    {
      "ID": 963,
      "UpcCode": 60924990401,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Coffee 3Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -0.9897611112,
      "Base_Units": 53,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "963, "
    },
    {
      "ID": 964,
      "UpcCode": 60924990402,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Coffee 6Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -0.9507391897,
      "Base_Units": 205,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "964, "
    },
    {
      "ID": 965,
      "UpcCode": 60924990441,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Coffee 3Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.45,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -0.6,
      "Base_Units": 163,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "965, "
    },
    {
      "ID": 966,
      "UpcCode": 60924990442,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Coffee 6Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.45,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.247474513,
      "Base_Units": 219,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "966, "
    },
    {
      "ID": 967,
      "UpcCode": 60924990601,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Cinnamon 3Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -2.232921096,
      "Base_Units": 14,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "967, "
    },
    {
      "ID": 968,
      "UpcCode": 60924990602,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Cinnamon 6Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -1.540158557,
      "Base_Units": 194,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "968, "
    },
    {
      "ID": 969,
      "UpcCode": 60924990641,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Cinnamon 3Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.45,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.270961287,
      "Base_Units": 194,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "969, "
    },
    {
      "ID": 970,
      "UpcCode": 60924990642,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Cinnamon 6Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.45,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -0.6780265191,
      "Base_Units": 162,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "970, "
    },
    {
      "ID": 971,
      "UpcCode": 60924990003,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Cool Mint 6Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -1.137313546,
      "Base_Units": 116,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "971, "
    },
    {
      "ID": 972,
      "UpcCode": 60924990042,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Cool Mint 6Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.43,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.274927764,
      "Base_Units": 229,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "972, "
    },
    {
      "ID": 973,
      "UpcCode": 60924990103,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Peppermint 6Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -0.6089693676,
      "Base_Units": 55,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "973, "
    },
    {
      "ID": 974,
      "UpcCode": 60924990142,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Peppermint 6Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.43,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.495111982,
      "Base_Units": 120,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "974, "
    },
    {
      "ID": 975,
      "UpcCode": 60924990202,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Spearmint 6Mg .21 Oz Can",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 4.49,
      "Unit_Cost": 3.492,
      "Margin": 0.22,
      "Elasticity": -2.015821881,
      "Base_Units": 110,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "975, "
    },
    {
      "ID": 976,
      "UpcCode": 60924990242,
      "vendorName": "McLane",
      "ProductDescription": "Zyn Spearmint 6Mg .21 Oz Roll",
      "BrandName": "ZYN",
      "CaseSize": 0,
      "Unit_Price": 22.43,
      "Unit_Cost": 17.46,
      "Margin": 0.22,
      "Elasticity": -1.380799473,
      "Base_Units": 172,
      "Level1": "Tobacco",
      "Level2": "Smokless",
      "Level3": "Snus & Dislvable",
      "FIELD15": "976, "
    }
   ]

export default rows;