import React from "react";
import Checkbox from '@mui/material/Checkbox';
import classNames from 'classnames';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Icon, IconAdv} from '../foundation';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import utils from '../app/utils';
import "./Select.scss";


// --- status --- //

function StatusDot({type}){
    return <div className={"rds-statusdot is-type-" + type}></div>;
}

function StatusOptions({onClose, onClickItem, width}) {
    // console.log('value: ', value);
    return (
        <React.Fragment>
        <MenuItem onClick={onClickItem} value="Approved"><StatusDot type="approved" />Approved</MenuItem>
            <MenuItem onClick={onClickItem} value="Active" style={{width: width}}><StatusDot type="active" />Active</MenuItem>
            <MenuItem onClick={onClickItem} value="Pending"><StatusDot type="pending" />Pending</MenuItem>
            <MenuItem onClick={onClickItem} value="Planning"><StatusDot type="planning" />Planning</MenuItem>
            <MenuItem onClick={onClickItem} value="Rejected"><StatusDot type="rejected" />Rejected</MenuItem>
        </React.Fragment>
    );
}


function StatusSelect(props){
    const {className, value, solid, onClick, style} = props;
    const dottype = utils.toLowerCase(value);
    const css = classNames(className + " is-value-" + dottype, solid && "is-solid", );
    return (
        <div style={style} onClick={onClick} className={css}>
            <div className="rds-select-dot"></div>
            <StatusDot type={dottype} />
            <span>{value}</span>
            <IconAdv type="chevron" />
        </div>
    );
}



// --- main --- //



function SelectOptions(props) {
    switch(props.type){
        default: 
        case "status": 
        case "status-solid": return <StatusOptions {...props} />
    }
}

export function SelectMenu(props) {
    const {type, anchor, value, open, onClose, onChange} = props;
    //console.log('value: ', value);
    const onClickItem = (e) => {
        const value2 = e?.target?.getAttribute("value");
        if(onChange && value2 !== value) onChange(value2);
        if(onClose) onClose(e);
    }
    return (
        <Menu className="rds-selectmenu" anchorEl={anchor} open={open} onClose={onClose}>
            <SelectOptions {...props} onClickItem={onClickItem} />
        </Menu>
    );
}

export function SelectButton(props) {
    const css = classNames("rds-select is-type-" + props.type, props.className);
    switch(props.type){
        default: 
        case "status": return <StatusSelect {...props} className={css} />
        case "status-solid": return <StatusSelect {...props} className={css} solid />
    }
}

export function Select(props) {
    const {value, onChange} = props;
    const menuType = props.menuType || props.type;
    const menuWidth = props.menuWidth || props.style?.width;

    // menu handlers
    const [statusAnchor, setStatusAnchor] = React.useState(null);
    const statusOpen = Boolean(statusAnchor);
    const onClickStatus = (event) => {setStatusAnchor(event.currentTarget)}; // console.log("YOOOOOOOO 1111 onClickStatus");
    const onCloseStatus = () => setStatusAnchor(null);

    // render
    return (
        <React.Fragment>
            <SelectMenu type={menuType} value={value} open={statusOpen} onChange={onChange} onClose={onCloseStatus} anchor={statusAnchor} width={menuWidth} />
            <SelectButton {...props} onClick={onClickStatus} />
        </React.Fragment>
    );
}

