import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {DataList, DataRow, DataCell, Sprite, SpriteButton, Icon, Select, SpriteField, SpriteText} from '../../foundation';
import { $ }  from 'react-jquery-plugin';


export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var rawrows = globals.vehicles_rows;
    const rows = [...rawrows];
    rows.splice(7, 1000);


     // START show action...
     React.useEffect(() => utils.checkInit2($, rows, setChecks), []);
     const [checks, setChecks] = React.useState({});
     const [checksCount, setChecksCount] = React.useState(false);

     React.useEffect(() => setChecksCount(utils.checkCounter(checks)), [checks]);

     const onChecked = (e, item) => setChecks(utils.checkSetter(checks, e, item));
     // ... END show action


    // --- TEMP
    const vehicle = record.Vehicles.length ? record.Vehicles[0] : '';
    const onVehicle = (e) => {
        var value = utils.isTrue(e.target.value) ? [e.target.value] : [];
        dispatch({type: "updateModel", value: {Vehicles: value}});
    }
    const headers = [
        {name: 'Name'},
        {name: 'Status'},
        {name: 'Start_Date', label: 'Start date'},
        {name: 'End_Date', label: 'End date'},
        {name: 'Promotion_Count', label: '# of promotions'},
        // {name: 'Locations', label: "Location"},
    ];

    return ( // --- TEMP
        <Sprite className="rds-promo-vehicles is-abs-all" style={{height:600, width: 'auto'}}>

            <SpriteText debug={0} label={'(' + checksCount + ')'} style={{top: 47, left: 181, minWidth: 30, color: '#96a4b7', fontSize: '12px' }} />

            {true && <SpriteField onChange={onVehicle} debug={0} placeholder="Search vehicles" style={{top: 105, left: 7, width: 270}} />}

            <div id="rds-promo-vehicles-list" style={{top: 148, width: 881, }}>
                
                <DataList className="is-nooverflow" headers={headers} onChecked={onChecked} onClickSort={()=>{}}>
                {rows.map((item, i) => ( // => i<7 && 
                        <DataRow onChecked={onChecked} index={i} key={i} item={item}>
                            <DataCell name="Name" onClick={() => {}}>{item.Name}</DataCell>
                            <DataCell name="Status"><Select type="status" onChange={(value) => {}} value={item.Status} /></DataCell>
                            <DataCell name="Start_Date">{utils.printDate(item.Start_Date)}</DataCell>
                            <DataCell name="End_Date">{item.End_Date}</DataCell>
                            <DataCell name="Promotion_Count" right={1}>{item.Promotion_Count}</DataCell>
                            {false && <DataCell name="Locations">{item.Locations}</DataCell>}
                        </DataRow>
                ))}
                </DataList>

            </div>

        </Sprite>
    );
};

