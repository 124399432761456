const rows = [// DONE: 12/20/2022
    {
        "value": "Texas",
        "label": "Texas",
        "children": [
            {
                "value": 1,
                "label": "Austin #1"
            },
            {
                "value": 2,
                "label": "San Antonio #1"
            },
            {
                "value": 3,
                "label": "Houston #1"
            },
            {
                "value": 4,
                "label": "Georgetown #1"
            },
            {
                "value": 5,
                "label": "San Marcos #1"
            },
            {
                "value": 6,
                "label": "Austin #2"
            },
            {
                "value": 7,
                "label": "San Antonio #2"
            },
            {
                "value": 8,
                "label": "Houston #2"
            },
            {
                "value": 9,
                "label": "Georgetown #2"
            },
            {
                "value": 10,
                "label": "San Marcos #2"
            }
        ]
    },
    {
        "value": "Oklahoma",
        "label": "Oklahoma",
        "children": [
            {
                "value": 11,
                "label": "Oklahoma City #1"
            },
            {
                "value": 12,
                "label": "Tulsa #1"
            },
            {
                "value": 13,
                "label": "Oklahoma City #2"
            },
            {
                "value": 14,
                "label": "Tulsa #2"
            }
        ]
    },
    {
        "value": "Nevada",
        "label": "Nevada",
        "children": [
            {
                "value": 15,
                "label": "Las Vegas #1"
            },
            {
                "value": 16,
                "label": "Las Vegas #2"
            }
        ]
    },
    {
        "value": "New Mexico",
        "label": "New Mexico",
        "children": [
            {
                "value": 17,
                "label": "Santa Fe #1"
            },
            {
                "value": 18,
                "label": "Santa Fe #2"
            },
            {
                "value": 19,
                "label": "Albuquerque #1"
            },
            {
                "value": 20,
                "label": "Albuquerque #2"
            }
        ]
    },
    {
        "value": "Arizona",
        "label": "Arizona",
        "children": [
            {
                "value": 21,
                "label": "Phoenix #1"
            },
            {
                "value": 22,
                "label": "Tucson #1"
            },
            {
                "value": 23,
                "label": "Phoenix #2"
            },
            {
                "value": 24,
                "label": "Tucson #2"
            }
        ]
    },
    {
        "value": "Colorado",
        "label": "Colorado",
        "children": [
            {
                "value": 25,
                "label": "Denver #1"
            },
            {
                "value": 26,
                "label": "Colorado Springs #1"
            },
            {
                "value": 27,
                "label": "Denver #2"
            },
            {
                "value": 28,
                "label": "Colorado Springs #2"
            }
        ]
    },
    {
        "value": "Georgia",
        "label": "Georgia",
        "children": [
            {
                "value": 29,
                "label": "Atlanta #1"
            },
            {
                "value": 30,
                "label": "Atlanta #2"
            }
        ]
    },
    {
        "value": "Illinois",
        "label": "Illinois",
        "children": [
            {
                "value": 31,
                "label": "Chicago #1"
            },
            {
                "value": 32,
                "label": "Chicago #2"
            }
        ]
    },
    {
        "value": "New York",
        "label": "New York",
        "children": [
            {
                "value": 33,
                "label": "New York City #1"
            },
            {
                "value": 34,
                "label": "Rochester #1"
            },
            {
                "value": 35,
                "label": "Buffalo #1"
            },
            {
                "value": 36,
                "label": "New York City #2"
            },
            {
                "value": 37,
                "label": "Rochester #2"
            },
            {
                "value": 38,
                "label": "Buffalo #2"
            }
        ]
    },
    {
        "value": "Pennsylvania",
        "label": "Pennsylvania",
        "children": [
            {
                "value": 39,
                "label": "Pittsburgh #1"
            },
            {
                "value": 40,
                "label": "Philadelphia #1"
            },
            {
                "value": 41,
                "label": "Pittsburgh #2"
            },
            {
                "value": 42,
                "label": "Philadelphia #2"
            }
        ]
    },
    {
        "value": "North Carolina",
        "label": "North Carolina",
        "children": [
            {
                "value": 43,
                "label": "Charlotte #1"
            },
            {
                "value": 44,
                "label": "Durham #1"
            },
            {
                "value": 45,
                "label": "Charlotte #2"
            },
            {
                "value": 46,
                "label": "Durham #2"
            }
        ]
    },
    {
        "value": "South Carolina",
        "label": "South Carolina",
        "children": [
            {
                "value": 47,
                "label": "Charleston #1"
            },
            {
                "value": 48,
                "label": "Charleston #2"
            }
        ]
    },
    {
        "value": "Tennessee",
        "label": "Tennessee",
        "children": [
            {
                "value": 49,
                "label": "Nashville #1"
            },
            {
                "value": 50,
                "label": "Nashville #2"
            },
            {
                "value": 51,
                "label": "Nashville #3"
            }
        ]
    },
    {
        "value": "California",
        "label": "California",
        "children": [
            {
                "value": 52,
                "label": "Los Angeles #1"
            },
            {
                "value": 53,
                "label": "San Diego #1"
            },
            {
                "value": 54,
                "label": "Santa Monica #1"
            },
            {
                "value": 55,
                "label": "San Francisco #1"
            },
            {
                "value": 56,
                "label": "Santa Rosa #1"
            },
            {
                "value": 57,
                "label": "Los Angeles #2"
            },
            {
                "value": 58,
                "label": "San Diego #2"
            },
            {
                "value": 59,
                "label": "Santa Monica #2"
            },
            {
                "value": 60,
                "label": "San Francisco #2"
            },
            {
                "value": 61,
                "label": "Santa Rosa #2"
            }
        ]
    },
    {
        "value": "Canada",
        "label": "Canada",
        "children": [
            {
                "value": 62,
                "label": "Vancouver #1"
            },
            {
                "value": 63,
                "label": "Montreal #1"
            },
            {
                "value": 64,
                "label": "Calgary #1"
            },
            {
                "value": 65,
                "label": "Winnipeg #1"
            },
            {
                "value": 66,
                "label": "Edmonton #1"
            },
            {
                "value": 67,
                "label": "Ottawa #1"
            }
        ]
    },
    {
        "value": "Florida",
        "label": "Florida",
        "children": [
            {
                "value": 68,
                "label": "Miami #1"
            },
            {
                "value": 69,
                "label": "Orlando #1"
            },
            {
                "value": 70,
                "label": "Tampa #1"
            },
            {
                "value": 71,
                "label": "Miami #1"
            },
            {
                "value": 72,
                "label": "Orlando #1"
            },
            {
                "value": 73,
                "label": "Tampa #1"
            }
        ]
    },
    {
        "value": "Ohio",
        "label": "Ohio",
        "children": [
            {
                "value": 74,
                "label": "Columbus #1"
            },
            {
                "value": 75,
                "label": "Cincinnati #1"
            },
            {
                "value": 76,
                "label": "Columbus #2"
            },
            {
                "value": 77,
                "label": "Cincinnati #2"
            }
        ]
    },
    {
        "value": "Michigan",
        "label": "Michigan",
        "children": [
            {
                "value": 78,
                "label": "Detroit #1"
            },
            {
                "value": 79,
                "label": "Grand Rapids #1"
            },
            {
                "value": 80,
                "label": "Ann Arbor #1"
            },
            {
                "value": 81,
                "label": "Detroit #2"
            },
            {
                "value": 82,
                "label": "Grand Rapids #2"
            },
            {
                "value": 83,
                "label": "Ann Arbor #2"
            }
        ]
    },
    {
        "value": "Missouri",
        "label": "Missouri",
        "children": [
            {
                "value": 84,
                "label": "St Louis #1"
            },
            {
                "value": 85,
                "label": "Kansas City #1"
            },
            {
                "value": 86,
                "label": "Springfield #1"
            },
            {
                "value": 87,
                "label": "St Louis #2"
            },
            {
                "value": 88,
                "label": "Kansas City #2"
            },
            {
                "value": 89,
                "label": "Springfield #2"
            }
        ]
    },
    {
        "value": "Utah",
        "label": "Utah",
        "children": [
            {
                "value": 90,
                "label": "Salt Lake City #1"
            },
            {
                "value": 91,
                "label": "Salt Lake City #2"
            },
            {
                "value": 92,
                "label": "Salt Lake City #3"
            }
        ]
    },
    {
        "value": "Washington",
        "label": "Washington",
        "children": [
            {
                "value": 93,
                "label": "Seattle #1"
            },
            {
                "value": 94,
                "label": "Tacoma #1"
            },
            {
                "value": 95,
                "label": "Olympia #1"
            },
            {
                "value": 96,
                "label": "Seattle #2"
            },
            {
                "value": 97,
                "label": "Tacoma #2"
            },
            {
                "value": 98,
                "label": "Olympia #2"
            }
        ]
    },
    {
        "value": "Oregon",
        "label": "Oregon",
        "children": [
            {
                "value": 99,
                "label": "Portland #1"
            },
            {
                "value": 100,
                "label": "Portland #2"
            }
        ]
    }
]

export default rows;