import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton, SpriteInput, PanelBottom, Group, EditTableList, } from '../foundation';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';



export default function Panel({name, }){
    const dispatch = useGlobalsDispatch();
    const DEBUG = 1;

    // values
    const [values, setValues] = React.useState({});
    const [changed, setChanged] = React.useState(false);

    // actions
    const onCancel = () => {dispatch({type: 'hidePanel'})}
    const onSave = () => {
        dispatch({type: 'hidePanel'});
        dispatch({type: "showToast", value: {message: "Summer sale successfully sent to Phill", duration: 5000, }, });
    }
    // const onAction = () => {
    //     setChanged(true);
    //     // dispatch({type: "insertColumn", });
    // }

    // return
    return (
        <React.Fragment>

            <Sprite absall className="rds-taskmgt-submit-for-review" style={{width: 416, height: 201, marginTop: 200, }}>

                <SpriteButton debug={0} style={{top: 170, left: 260, width: 80}} onClick={onCancel} />
                <SpriteButton debug={0} style={{top: 170, left: 350, width: 80}} onClick={onSave} />
                {/* <Group type="cancel-save" onCancel={onCancel} onSave={onSave} disabled={!changed} /> */}
                
            </Sprite>

            {/* <PanelBottom>
                <Group type="cancel-save" onCancel={onCancel} onSave={onSave} disabled={!changed} />
            </PanelBottom> */}

        </React.Fragment>


        


    );

}
