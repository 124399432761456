// import utils from "../app/utils";




const configs = {
    // debug
    debug: false, // main
    debug_screen: 'promos', // promos
    debug_promos: false,

    // others
    numFields: {ID: true, },

};


export default configs;