import React from "react";
import utils from "../app/utils"
import {Button} from '../foundation/Button';
import {Sprite, SpriteButton} from '../foundation/Sprite';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import "./ReportsPanel.scss";


export default function ReportsPanel(){
    const globals = useGlobals();
    const dispatch = useGlobalsDispatch();
    const isDetails = (globals.screen === "report-details");
    const css = utils.classNames(isDetails ? "is-alt" : "");


    return (
        <React.Fragment>
                <Sprite debug={0} id="rds-reports-panel-top" className={css} style={{width: 222, top: 54, height: 660, }}>
                    {isDetails && <SpriteButton debug={0} style={{top: 80, left: 10, width: 180, height: 500}} />}
                    {!isDetails && <SpriteButton debug={0} style={{top: 80, left: 10, width: 180, height: 160}} />}
                    
                </Sprite>

                <Sprite debug={0} id="rds-reports-panel-bottom" absall style={{position: "absolute", bottom: 0, top: 'auto', height: 100, width: 222}}>
                    <SpriteButton debug={0} style={{top: 50, left: 10, width: 200}} />
                </Sprite>
        </React.Fragment>
    );
}
