import React from "react";
import classNames from 'classnames';
import utils from '../app/utils';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import MuiPopover from '@mui/material/Popover';
import {Group} from '../foundation';
import {Sprite, SpriteButton, SpriteInput, SpriteListHover, SpriteCheckbox, } from '../foundation/Sprite';
import './Popover.scss';



function HoverList({count, onClick, }){
    let children = [];
    for(var i=0; i<count; i++) children.push(<li />);
    return <ul className="rds-listhover" onClick={onClick}>{children}</ul>;
}

function TableSort({className, onClickSort}){
    return (
        <Sprite absall={1} className={className} style={{width: 314,height: 174,}}>
            {false && <SpriteInput debug={0} style={{top: 19, left: 20, width: 275}} placeholder="Name" />}
            <SpriteButton debug={0} style={{top: 36, left: 10, width: 200}} onClick={onClickSort} />
        </Sprite>
    );
}
function SearchCat({className, closePopover}){
    return (
        <SpriteButton absall={1} onClick={() => closePopover()} className={className + " is-likelink"} style={{width: 179,height: 322,}}>
            <ul className="rds-listhover" style={{position: 'absolute', top: 34, }}>
                <li style={{position: 'related', }}>
                    <SpriteCheckbox debug={0} checked style={{top: 3, left: 2, width: 2, position: 'absolute', }} />
                </li>
                <li /><li /><li /><li /><li /><li /><li /><li />
            </ul>
        </SpriteButton>
    );
}


function SearchRecent({className, closePopover}){
    return (
        <SpriteButton absall={1} onClick={() => closePopover()} className={className + " is-likelink"} style={{width: 340,height: 430,}}>
        </SpriteButton>
    );
}

// BASE
function PriceColumnMenu({className, onClickSort, closePopover, onClickItem, }){

    // return
    return (
        <Sprite absall={1} className={className} style={{width: 158, height: 358,}} onClick={() => closePopover()}>
            <SpriteListHover count={11} itemHeight={32.5} onClickItem={onClickItem} />
        </Sprite>
    );
}

function PriceSearchbarMenu2({className, onClickSort, closePopover}){

    return <div style={{width: 448, height: 224,}}>TESTING</div>;
}

function PriceSearchbarMenu({className, onClickSort, closePopover}){
    const [value, setValue] = React.useState('7%');
    const onChangeValue = (e) => {setValue(e?.target?.value)}

    
    const onCancel = () => closePopover();
    const onSave = () => closePopover();

    // render
    return (
        <Sprite absall={1} className={className} style={{width: 448, height: 224,}} >

            <SpriteInput debug222={1} style={{top: 116, left: 26, width: 400}} value={value || ''} onChange={onChangeValue} placeholder="Enter number" />

            <Group type="cancel-save" onCancel={onCancel} onSave={onSave}  disabled={!String(value).length} style={{top: 164, left: 240, backgroundColor: 'white', }} />

        </Sprite>
    );
}
function EditTableMenu({className, onClickSort, closePopover}){
    const dispatch = useGlobalsDispatch();

    const onClickInsert = () => {
        dispatch({type: "insertColumn", });
        dispatch({type: "showToast", value: "New column inserted", });
    }

    // return
    return (
        <Sprite className={className} style={{width: 192, height: 256,}} onClick={() => closePopover()}>
            <div onClick={onClickInsert} />
            <div onClick={onClickInsert} />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </Sprite>
    );
}


function PriceFormulaMenu({className, onClickSort, closePopover, onClick}){
    const onClick2 = () => {
        if(onClick) onClick();
        closePopover();
    }

    // return
    return (
        <Sprite className={className} style={{width: 286, height: 352,}} onClick={onClick2}>
            <HoverList count={11} />
        </Sprite>
    );
}

function CalBox({style, }){
    return <SpriteButton hover debug={0} style={{top: style.top, left: style.left, width: 40, height: 40, borderRadius: 8, }} />;
}

function PriceLockCalendar({className, closePopover, onClick}){
    return (
        <Sprite absall className={className} style={{width: 416, height: 414,}} onClick={closePopover}>

            <CalBox style={{top: 165, left: 16+(58*0), }} />
            <CalBox style={{top: 165, left: 16+(58*1), }} />
            <CalBox style={{top: 165, left: 16+(58*2), }} />
            <CalBox style={{top: 165, left: 16+(58*3), }} />
            <CalBox style={{top: 165, left: 16+(58*4), }} />
            <CalBox style={{top: 165, left: 16+(58*5), }} />
            <CalBox style={{top: 165, left: 16+(58*6), }} />

            <CalBox style={{top: 165+(48*1), left: 16+(58*0), }} />
            <CalBox style={{top: 165+(48*1), left: 16+(58*1), }} />
            <CalBox style={{top: 165+(48*1), left: 16+(58*2), }} />
            <CalBox style={{top: 165+(48*1), left: 16+(58*3), }} />
            <CalBox style={{top: 165+(48*1), left: 16+(58*4), }} />
            <CalBox style={{top: 165+(48*1), left: 16+(58*5), }} />
            <CalBox style={{top: 165+(48*1), left: 16+(58*6), }} />

            <CalBox style={{top: 165+(48*2), left: 16+(58*0), }} />
            <CalBox style={{top: 165+(48*2), left: 16+(58*1), }} />
            <CalBox style={{top: 165+(48*2), left: 16+(58*2), }} />
            <CalBox style={{top: 165+(48*2), left: 16+(58*3), }} />
            <CalBox style={{top: 165+(48*2), left: 16+(58*4), }} />
            <CalBox style={{top: 165+(48*2), left: 16+(58*5), }} />
            <CalBox style={{top: 165+(48*2), left: 16+(58*6), }} />

            <CalBox style={{top: 165+(48*3), left: 16+(58*0), }} />
            <CalBox style={{top: 165+(48*3), left: 16+(58*1), }} />
            <CalBox style={{top: 165+(48*3), left: 16+(58*2), }} />
            <CalBox style={{top: 165+(48*3), left: 16+(58*3), }} />
            <CalBox style={{top: 165+(48*3), left: 16+(58*4), }} />
            <CalBox style={{top: 165+(48*3), left: 16+(58*5), }} />
            <CalBox style={{top: 165+(48*3), left: 16+(58*6), }} />

            <CalBox style={{top: 165+(48*4), left: 16+(58*0), }} />
            <CalBox style={{top: 165+(48*4), left: 16+(58*1), }} />

        </Sprite>
    );
}


function PriceLockMenu({className, closePopover, onClick, onClose, }){
    const dispatch = useGlobalsDispatch();

    // handlers
    const onClickEditPrice = () => dispatch({type: "showPanel", value: "lock-edit-panel"});
    const onClickAllZones = () => dispatch({type: "showPanel", value: "lock-all-panel"});
    const onClickExport = (str) => dispatch({type: "showToast", value: {message: `10,000 prices have been exported`}, });

    // return
    return (
        <Sprite className={className} style={{width: 190, height: 96,}} onClick={closePopover}>

            <ul className="rds-listhover">
                <li className="is-item-1">
                    <Sprite className="rds-popover is-name-price-lock-menu3">
                        <ul className="rds-listhover">
                            <li onClick={() => onClickExport("Back Office")} />
                            <li onClick={() => onClickExport("Excel")} />
                            <li onClick={() => onClickExport("CSV")} />
                        </ul>
                    </Sprite>
                </li>
                <li className="is-item-2" />
                <li className="is-item-3" style={{position: 'relative', }}>
                    <Sprite className="rds-popover is-name-price-lock-menu2">
                        <ul className="rds-listhover">
                            <li />
                            <li />
                            <li onClick={onClickEditPrice} />
                            <li />
                            <li onClick={onClickAllZones} />
                        </ul>
                    </Sprite>
                </li>
            </ul>
           
        </Sprite>
    );
}

function PriceLockMenu2({className, closePopover, onClick}){

    return (
        <Sprite className={className} style={{width: 164, height: 160,}} onClick={closePopover}>
            <HoverList count={5} />
        </Sprite>
    );
}

function PriceListSliderNoteMenu({className, closePopover, onClick}){
    // rds-sprite rds-popover is-name-price-list-slider-note
    return (
        <Sprite className={className} style={{width: 107, height: 64,}} onClick={closePopover}>
            <HoverList count={2} />
        </Sprite>
    );
}

function PriceListSliderNote({className, closePopover, onClick}){

    // note
    const [noteEl, setNoteEl] = React.useState(null);
    const noteOpen = !!noteEl;    
    const onClickNote = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setNoteEl(e.currentTarget);
    }
    const onCloseNote = () => setNoteEl(null);

    // return
    return (
        <Sprite className={className} style={{width: 268, height: 284,}} onClick={closePopover}>
            <SpriteButton debug={0} onClick={onClickNote} style={{top: 10, left: 230, width: 30, }} />
            <Popover name="price-list-slider-note-menu" shape="rounded" open={noteOpen} anchorEl={noteEl} onClose={onCloseNote} />
        </Sprite>
    );
}

function PriceListCheckallMenu({className, closePopover, onClick, onClickItem, }){
    return (
        <Sprite className={className} style={{width: 170, height: 64,}} onClick={closePopover}>
            <HoverList count={2} onClick={onClickItem} />
        </Sprite>
    );
}

function PriceDetailsComps({className, closePopover, onClick, onClickItem, checkeds = {}, }){

    const onClickItem2 = (e) => {
        if(onClickItem) onClickItem(e);
    }

    // return
    return (
        <Sprite absall className={className} style={{width: 179, height: 160,}} >
            <SpriteCheckbox onChange={onClickItem2} checked={checkeds.all} name="all" style={{top: 2, left: 2, }} />
            <SpriteCheckbox onChange={onClickItem2} checked={checkeds.blue} name="blue" style={{top: 34, left: 2, }} />
            <SpriteCheckbox onChange={onClickItem2} checked={checkeds.yellow} name="yellow" style={{top: 66, left: 2, }} />
            <SpriteCheckbox onChange={onClickItem2} checked={checkeds.orange} name="orange" style={{top: 98, left: 2, }} />
            <SpriteCheckbox onChange={onClickItem2} checked={checkeds.purple} name="purple" style={{top: 130, left: 2, }} /> 
        </Sprite>
    );
}

function PriceDetailsZones({className, closePopover, onClick, onClickItem, }){
    return (
        <Sprite className={className} style={{width: 94, height: 128,}} onClick={closePopover}>
            <HoverList count={4} onClick={onClickItem} />
        </Sprite>
    );
}


// --- content / popover --- //

function Content(props){
    switch(props.name){
        default: // return console.warn('Popover() - Invalid name: ', props.name);
        case "tablesort": return <TableSort {...props} />
        case "searchbar-category": return <SearchCat {...props} />
        case "searchbar-recent": return <SearchRecent {...props} />
        case "price-column-menu": return <PriceColumnMenu {...props} />
        case "price-searchbar-menu": return <PriceSearchbarMenu {...props} />
        case "price-searchbar-menu2": return <PriceSearchbarMenu2 {...props} />
        case "edit-table-menu": return <EditTableMenu {...props} />
        case "price-formula-menu": return <PriceFormulaMenu {...props} />
        case "price-lock-calendar": return <PriceLockCalendar {...props} />
        case "price-lock-menu": return <PriceLockMenu {...props} />
        case "price-lock-menu2": return <PriceLockMenu2 {...props} />
        case "price-list-slider-note": return <PriceListSliderNote {...props} />
        case "price-list-slider-note-menu": return <PriceListSliderNoteMenu {...props} />
        case "price-list-checkall-menu": return <PriceListCheckallMenu {...props} />
        case "price-details-competitors": return <PriceDetailsComps {...props} />
        case "price-details-zones": return <PriceDetailsZones {...props} />
    }
}


export function Popover(props){
    const {name, open, anchorEl, onClose, onClick, shape = "rounded", className, ao, to, } = props;
    const css = classNames("rds-popover-container is-shape-" + shape, className);
    return (
        <MuiPopover 
            id={"rds-popover-" + name} 
            className={css}
            open={open} 
            anchorEl={anchorEl} 
            onClose={onClose}
            anchorOrigin={ao || {vertical: 'bottom', horizontal: 'left',}}
            transformOrigin={to || {vertical: 'top', horizontal: 'left',}}
        >
            <Content {...props} name={name} className={"rds-popover is-name-" + name} onClick={onClick} closePopover={onClose} />
        </MuiPopover>
    );
}
