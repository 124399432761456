import React from "react";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteButton, SpriteText} from "../../foundation";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';
// import "./Dashboard.scss";

// function Below(){
//     return (
//         <div className='rds-tabnav-below'>
//             <Template type="changelogs" />
//         </div>
//     );
// }

function Nav ({screen}) {
    const dispatch = useGlobalsDispatch();
    // const css = classNames('rds-tabnav', className);
    console.log("------ FLAG 111:", screen);
    if(screen === "task" || screen === "task-view") screen = "task-define";
    const onClick = (screen) => {dispatch({type: "setScreen", value: screen})};
    return (
        <div className='rds-tabnav is-type-system' style={{height: 204}}>
            <SpriteButton debug={0} className={screen === "system-workspace" && "is-selected"} onClick={() => {}} />
            <SpriteButton debug={0} className={screen === "system-privacy" && "is-selected"} onClick={() => {}} />
            <SpriteButton debug={0} className={screen === "system-members" && "is-selected"} onClick={() => onClick("system-members")} />
        </div>
    );
}


function Content({screen}){
    switch(screen){
        default:
        case 'system-members':     return <Sprite className="rds-system-members" style={{height:600, width: 890}} />;
    }
}

// function ViewPromoBar({label}){
//     const dispatch = useGlobalsDispatch();
//     const onClickCancel = () => {
//         dispatch({type: "setScreen", value: "task-list"});
//     };

//     return (
//         <Sprite className="rds-viewpromobar is-center" style={{width: 1344, height: 40, marginBottom: 20}}>
//             <SpriteText className="is-graybg is-abs is-body2 is-debug" style={{left: 200, minWidth: 400, top: 4}}>{label}</SpriteText>
//             <SpriteButton className="is-abs-right" style={{top: 1, right: 1}} debug={0} onClick={onClickCancel} />
//             <SpriteButton className="is-abs-right" style={{top: 1, right: 120}} debug={0} onClick={onClickCancel} />
//         </Sprite>
//     );
// }

function ViewPromoBar(){
    return <div></div>
}


export default function SystemView({screen}){
    if(!screen) screen = "task-define";
    return (
        <div>
            <ViewPromoBar label="Beef Sirloins, Eight 8 oz Steaks TPR $110.65" />

            <TabLayout>
                <TabLayoutLeft>
                    <Nav screen={screen} />
                </TabLayoutLeft>
                <TabLayoutRight>
                    <Content screen={screen} />
                </TabLayoutRight>
            </TabLayout>
        </div>
    );
}