import React from "react";
import {Layout} from "../../foundation/Layout";
import utils from "../../app/utils";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar, Titlebar3, Paginationbar} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Select, DataValue, SpriteButton, Sprite} from '../../foundation';
// import {Link2} from '../../foundation/Text';
import {Icon} from '../../foundation/Icon';
import {Button2} from '../../foundation/Button';


// import * as React from 'react';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function StatusMenu({anchor, value, open, onClose}) {
    console.log('value: ', value);
    return (
        <Menu anchorEl={anchor} open={open} onClose={onClose}>
            <MenuItem onClick={onClose}>Profile</MenuItem>
            <MenuItem onClick={onClose}>My account</MenuItem>
            <MenuItem onClick={onClose}>Logout</MenuItem>
        </Menu>
    );
}



const dots = {'Active': '#0F62FE',  'Approved': '#32C4A6', 'Pending': '#FF905F'};
    function Dot({status}){
    const color = dots[status] || 'gray';
    return <div style={{width: 8, height: 8, borderRadius: 8, backgroundColor: color, display: 'inline-block', marginRight: 8}}></div>;
    }



export default function OfferList(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var rows = globals.offers_rows;
    rows = utils.removeUnlisted(rows);

    utils.loadList(globals, "offers", rows);
    //var rows = [];
    console.log("YOOO )))))))))))))))))", globals.offers_rows);


    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const handleClick = (event) => {setAnchor(event.currentTarget)};
    const handleClose = () => {setAnchor(null)};

    // DEBUG
    React.useEffect(() => {
        // console.log('++++++++++++++++ PROMOS: ', globals.promos_rows);
        // utils.trigger(utils.getDB(), "promos_onLoadList", []);
        // utils.loadList(globals, 'promos', rows);
    }, [globals.promos_rows]);


    const headers = [
        {name: 'Name'},
        {name: '_Offer_Price', right: true},
        {name: '_Regular_Price', right: true},
        {name: '_Discount', right: true},
        {name: '_Applies_To'},
        {name: '_Offer_Limit', right: true},
        {name: '_Promotions_Count', label: "# Promotions", right: true},
    ];

    // const rows  = utils.remakeRows(rawrows, headers); // ['Name', 'Status', "Start_Date", "End_Date"]


    // handlers
    const onClickName = (item) => {
        console.log("))))))))))))))))))))))))) IT WORKS 111");
        dispatch({type: "showPanel", value: "offer-details", table: "offers", id: item.ID});
    }

    const onClickSort = (header) => {
        dispatch({type: "sortTable", table: "promos", field: header.name});
    }

    const onStatus = (item, value) => {
        console.log("--------- onStatusSelect: SORT ", globals.promos_sort);

        dispatch({type: "updateRow", table: "promos", id: item.ID, values: {Status: value}});
     }

     const onChecked = () => {
        console.log("testing");
     }

     const printValue = utils.printListValue;

     function onClickView(item){
        dispatch({type: "hidePanel"});
        dispatch({type: "showDetails", table: "offers", id: item.ID});
    }
     // {printValue(item._Offer_Price)}

    return (
        <Layout>
            <Searchbar type="create-import" />


            {false && <Titlebar title="Offer management" type="offermgr" />}

            <Sprite id="rds-toolbar-offers-listview" absall style={{width: 1344, height: 32, marginBottom: 20, }}>
                <SpriteButton debug={0} style={{top: 0, left: 1176, width: 90}} onClick={() => {}} />
                <SpriteButton debug={0} style={{top: 0, left: 1286, width: 30}} onClick={() => {}} />
                <SpriteButton debug={0} style={{top: 0, left: 1316, width: 30}} onClick={() => {}} />
            </Sprite>

            
            <StatusMenu open={open} onClose={handleClose} anchor={anchor} />

            <DataList className="is-nooverflow222" headers={headers} onChecked={onChecked} onClickSort={onClickSort}>
              {rows.map((item, i) => i<10 && ( // --- LIMIT to 10 
                    <DataRow onChecked={onChecked} index={i} key={i} onClickView={onClickView} item={item}>
                        <DataCell name="Name" onClick={() => onClickName(item)}>{item.Name}</DataCell>
                        <DataCell name="_Offer_Price" right><DataValue type="dollar" value={item._Offer_Price} /></DataCell>
                        <DataCell name="_Regular_Price" right><DataValue type="dollar" value={item._Regular_Price} /></DataCell>
                        <DataCell name="_Discount" right><DataValue type={item.isPct?"percent2":"dollar"} value={item.isPct?item.Discount_PCT:item.Discount_USD} /></DataCell>
                        <DataCell name="_Applies_To">{item._Applies_To}</DataCell>
                        <DataCell name="_Offer_Limit" right>{item._Offer_Limit}</DataCell>
                        <DataCell name="_Promotions_Count" right>{item._Promotions_Count}</DataCell>
                    </DataRow>

              ))}
            </DataList>


            <Paginationbar />
            
        </Layout>
    );
}


