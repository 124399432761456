import React from "react";
import utils from "../../app/utils";
import {Layout} from "../../foundation/Layout";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteTooltip, SpriteInput, SpriteCaching, SpriteHover, SpriteListHover, } from '../../foundation/Sprite';
import {FilterToolbar, Paginationbar, } from "../../components/Toolbars";
import {Popover} from '../../foundation';
import classNames from "classnames";
// import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
// import {Searchbar, Titlebar, Paginationbar} from '../../components/Toolbars';
// import {DataList, DataTop, DataRow, DataCell} from '../../foundation/DataList';
// import {Icon} from '../../foundation/Icon';
// import { borderRadius } from "@mui/system";

import "./Price.scss";

const DEBUG = 0;


// // invisible searchbar
// function FilterToolbar222({style, debug, }){
//     const [open, setOpen] = React.useState(false);

//     // init
//     React.useEffect(() => {
//         const onClickBody = () => {setOpen(false);}

//         // body click listener
//         document.addEventListener("click", onClickBody);
//         return () => {
//             document.removeEventListener("click", onClickBody);
//         };
//     }, []);

//     const onClickBar = (e) => {e.stopPropagation();}
//     const onClickFilter = (e) => {setOpen(!open);}
//     const onClickClearAll= () => {setOpen(!open)}

//     // popover - menu
//     const [recentEl, setRecentEl] = React.useState(null); // NOTE: remove
//     const recentOpen = !!recentEl;    
//     const onCloseMenu = () => {setRecentEl(null);};
//     const onClickMenu = (e) => setRecentEl(e.currentTarget);

//     // return
//     const css = classNames("rds-price-searchbar", open && 'is-open');
//     return (
//         <Sprite absall className={css} style={style} onClick={onClickBar}>
            
//             <SpriteButton debug={DEBUG} style={{top: 8, left: 450, width: 130, }} onClick={onClickFilter} />
//             <SpriteButton debug={DEBUG} style={{top: 60, left: 170, width: 230, }} onClick={onClickMenu} />

//             <SpriteButton debug={DEBUG} style={{top: 60, left: 'auto', right: 0, width: 200, }} onClick={onClickClearAll} />

//             <Popover name="price-searchbar-menu" shape="rounded" open={recentOpen} anchorEl={recentEl} onClose={onCloseMenu} />

//         </Sprite>
//     );
// }


// 
function FormulaToolbar(props){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const {style, debug, show, onCancel, } = props;
    const [filled, setFilled] = React.useState(false);
    const {insertColumn} = globals;

    // handlers
    const onClickClear = () => setFilled(false);
    const onClickCancel = () => {
        if(onCancel) onCancel();
        setFilled(false); // reset
    }
    const onClickSave = () => {
        onClickCancel();
        // if(insertColumn) dispatch({type: "insertColumn", value: 2, });
        dispatch({type: "insertColumn", value: 2, });
    }


    // popover - column menu
    const [recentEl, setRecentEl] = React.useState(null);
    const menuOpen = !!recentEl;    
    const onClickInput = (e) => setRecentEl(e.currentTarget);
    const onCloseMenu = (e) => setRecentEl(null);
    const onClickMenu = () => {
        setRecentEl(null);
        setFilled(true);
    }


    // return
    const css = classNames("rds-price-formulabar", show && 'is-show', filled && 'is-filled');
    return show && (
        <Sprite absall debug={DEBUG} className={css} style={style}>


            <SpriteTooltip debug={DEBUG} style={{top: 10, left: 410, }} tooltip="Use + - * / ^ ( ) symbols to create a formula" />

            <SpriteButton debug={DEBUG} style={{top: 10, left: 150, width: 260, }} onClick={onClickInput} />
            <Popover name="price-formula-menu" shape="rounded" open={menuOpen} anchorEl={recentEl} onClick={onClickMenu} onClose={onCloseMenu} />

            {filled && <SpriteButton debug={DEBUG} style={{top: 10, left: 450, width: 80, }} onClick={onClickClear} />}
            
            <SpriteButton debug={DEBUG} style={{top: 10, right: 80, left: 'auto', width: 60, }} onClick={onClickCancel} />
            <SpriteButton debug={DEBUG} style={{top: 10, right: 10, left: 'auto', width: 60, }} onClick={onClickSave} />

        </Sprite>
    );
}


export default function Page(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const {insertColumn, showMessage, showPanel, } = globals;

    // init
    React.useEffect(() => {
        return () => {
            // reset states
            dispatch({type: "insertColumn", value: false, });
            dispatch({type: "showNote", value: false, });
        }
    }, []);

    // mode
    const [mode, setMode] = React.useState("rds-price-list");
    const [checked, setChecked] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [noted, setNoted] = React.useState(false);
    React.useEffect(() => {
        setChecked(mode === 'checked');
        setExpanded(mode === 'expand');
        setNoted(mode === 'noted');
        // if(mode === 'checked' || mode === 'expand') dispatch({type: "showNote", value: false, });
    }, [mode]);

    // insert column?
    React.useEffect(() => {
        if(insertColumn) setMode(insertColumn === 2 ? 'inserted-2' : 'inserted');
    }, [insertColumn]);

    // edit table panel
    const onClickEditTable = () => dispatch({type: "showPanel", value: "edit-price-table"});

    // popover - category
    const [catEl, setCatEl] = React.useState(null); // NOTE: remove
    const catOpen = !!catEl;    
    const onCatClose = () => {setCatEl(null);};
    const onClickCat = (e) => setCatEl(e.currentTarget);

    // filters
    const onClickFilter = () => {
        dispatch({type: "showPanel", value: "price-filters"});
    }

    // formula
    const [showingFormula, showFormula] = React.useState(false);
    const onCancelFormula = () => {showFormula(false)};

    // popover - column menu
    const [recentEl, setRecentEl] = React.useState(null);
    const recentOpen = !!recentEl;    
    const onClickColumn = (e) => setRecentEl(e.currentTarget);
    const onCloseColumn = () => {
        setRecentEl(null);
        // showFormula(true);
    };

    // price lock
    const [calendarEl, setCalendarEl] = React.useState(null);
    const calendarOpen = !!calendarEl;    
    const onClickLockArrow = (e) => setCalendarEl(e.currentTarget);
    const onCloseLock = () => setCalendarEl(null);

    // price lock 2
    const onClickLock = () => dispatch({type: "showPanel", value: "lock-edit-panel"});

    // check all
    const ao2 = {vertical: 'bottom', horizontal: 'right',};
    const to2 = {vertical: 'top', horizontal: 'right',};
    const onClickChecked = () => {
        setMode((mode === 'checked') ? false : 'checked');
        dispatch({type: "insertColumn", value: false, }); // reset
    }

    // check all menu
    const [checkallEl, setCheckallEl] = React.useState(null);
    const checkallOpen = !!checkallEl;    
    const onClickCheckall = (e) => {
        if(mode === 'checked') setMode(false); // --- NEW: 12/14/23
        else setCheckallEl(e.currentTarget);
    }
    const onCloseCheckall = () => {
        setCheckallEl(null);
    }


    // action
    const [actionEl, setActionEl] = React.useState(null);
    const actionOpen = !!actionEl;    
    const onClickAction = (e) => setActionEl(e.currentTarget);
    const onCloseAction = () => {
        setActionEl(null);
        // setMode((mode === 'checked') ? false : 'checked');
        // setChecked(false);
    }

    // expand
    const onClickExpand = () => {
        setMode((mode === 'expand') ? false : 'expand');
        dispatch({type: "insertColumn", value: false, }); // reset
        // const expanded2 = !expanded;
        // setExpanded(expanded2);
        // setCss(expanded2 ? "rds-price-list-expand" : "rds-price-list");
        // setChecked(false);
    };

    // import 
    const onClickImport = () => {} // --- WIP

    // asdf
    // React.useEffect(() => {
    //     setCss(expanded ? "rds-price-list-checkall" : "rds-price-list");
    // }, [expanded]);

    // React.useEffect(() => {
    //     setCss(checked ? "rds-price-list-checked" : "rds-price-list");
    // }, [checked]);
    const onClickColumnMenu = (i) => {
        if(i === 8) showFormula(true);
        if(i === 6 || i === 7) setMode('noted'); // dispatch({type: "showNote", });
    };

    const onClickComment = () => dispatch({type: "toggleMessage", });


    
    // snackbar
    const [state, setState] = React.useState({open: false, vertical: 'bottom', horizontal: 'center', });
    const { vertical, horizontal, open } = state;
    // const handleClick = (newState) => () => setState({ ...newState, open: true });
    // const handleClose = () => setState({ ...state, open: false });

    // click cell
    const [selection, setSelection] = React.useState(null);
    const onClickCell = (i) => {
        dispatch({type: "toggleMessage", value: true, mode: "new", });
        setSelection(i);
    };

    React.useEffect(() => {
        if(!showMessage) setSelection(null);
    }, [showMessage]);


    // slider
    const [showNote_p2, setShowNote_p2] = React.useState(null);
    React.useEffect(() => {
        if(!noted) return;
        document.getElementById("slider-name").focus();
        setShowNote_p2(false);
    }, [noted]);
    const onSliderKey = (e) => {
        if(e.key !== 'Enter') return;
        setShowNote_p2(true);
        document.getElementById("slider-name").blur();
    };


    // note
    const [noteEl, setNoteEl] = React.useState(null);
    const noteOpen = !!noteEl;    
    const onClickNote = (e) => setNoteEl(e.currentTarget);
    const onCloseNote = () => setNoteEl(null);


    // return
    // rds-sprite rds-price-list is-mode-checked is-absall
    const css = classNames('rds-price-list', mode && `is-mode-${mode}`);
    return (
        <Layout>




            <Sprite debug={0} absall className={css} style={{width: 1364, height: 660, left: 0, }}>
                {/* caching */}
                <SpriteCaching baseClass="rds-price-list" modifiers={["is-mode-expand", "is-mode-checked", "is-mode-inserted", "is-mode-inserted-2", ]} />


                {/* notes */}
                <Sprite absall debug={0} className='rds-price-list-slider-box' style={{top: 110, left: 986, width: 358, borderRadius: 8, opacity: noted?1:0, }}>

                    <Sprite debug={0} absall className={'rds-price-list-slider'} style={{left: noted ? -50 : -160, }} >
                        
                        <SpriteInput value222="asdf" placeholder="New Name" id="slider-name" onKeyPress={onSliderKey} style={{top: 3, left: 16, width: 132, textAlign: 'right', backgroundColor: '#d9e5f5', }} />
                        
                        {showNote_p2 && <SpriteButton debug={0} label="Action Items..." style={{top: 45, left: 60, backgroundColor: "#f8f9fa", }} />}

                        <SpriteTooltip debug={0} id="rds-price-list-slider-tooltip" onClick={onClickNote} style={{top: 45, left: 60, width: 80, cursor: 'pointer', }} placement="left-start" tooltip="."></SpriteTooltip>
                        <Popover name="price-list-slider-note" shape="rounded" open={noteOpen} anchorEl={noteEl} onClose={onCloseNote} />


                        {/* see columns and formula icon (copy from below) */}
                        <SpriteButton debug={0} style={{top: 0, left: 160, width: 130, }} onClick={onClickColumn} />
                        {(insertColumn === 2) && <SpriteTooltip debug={0} style={{top: 0, left: 273, width: 20, height: 20, }} tooltip="(Suggested Price - Price) / Price" />}


                        
                    </Sprite>
                    
                </Sprite>



                {/* toolbar */}
                <FilterToolbar debug={DEBUG} style={{top: 53, left: 0, }} onClickFilter={onClickFilter} />
                <SpriteInput debug={DEBUG} style={{top: 62, left: 170, height: 30, width: 270}} placeholder="Search"  />

                <SpriteButton debug={DEBUG} style={{top: 60, left: 10, width: 150, }} onClick={onClickCat} />
                <Popover name="searchbar-category" shape="rounded" open={catOpen} anchorEl={catEl} onClose={onCatClose} />

                <FormulaToolbar debug={DEBUG} show={showingFormula} onCancel={onCancelFormula} style={{top: 54, left: 0, }} />
                {!checked && <SpriteButton debug={DEBUG} style={{top: 60, left: 'auto', right: 130, width: 150, }} onClick={onClickEditTable} />}
                {!checked && <SpriteButton debug={DEBUG} style={{top: 60, left: 'auto', right: 20, width: 100, }} onClick={onClickImport} />}


                {/* columns */}
                <SpriteButton debug={DEBUG} style={{top: 108, left: 120, width: 160, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 300, width: 160, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 470, width: 140, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 640, width: 140, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 790, width: 60, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 860, width: 130, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 990, width: 140, }} onClick={onClickColumn} />
                <SpriteButton debug={DEBUG} style={{top: 108, left: 1150, width: 100, }} onClick={onClickColumn} />

                <Popover name="price-column-menu" shape="rounded" open={recentOpen} anchorEl={recentEl} onClickItem={onClickColumnMenu} onClose={onCloseColumn} />

                <Sprite debug={DEBUG} style={{top: 142, left: 0, width: 1344, height: 300, }}>
                    <PriceListRow />
                    <PriceListRow />
                    <PriceListRow />
                    <PriceListRow />
                    <PriceListRow />
                    <PriceListRow />
                    <PriceListRow />
                    <PriceListRow />
                    <PriceListRow />
                    <PriceListRow />
                </Sprite>

                {/* formula icon */}
                {(insertColumn === 2) && <SpriteTooltip debug={DEBUG} style={{top: 110, left: 1100, width: 20, height: 20, }} tooltip="(Suggested Price - Price) / Price" />}

                {/* history icon */}
                <SpriteTooltip debug={DEBUG} id="rds-price-history-tooltip" placement="left" style={{top: 150, left: 610, width: 30, height: 30, zIndex: 2,  }} tooltip="." />
                <SpriteTooltip debug={DEBUG} id="rds-price-history-tooltip" placement="left" style={{top: 200, left: 610, width: 30, height: 30, zIndex: 2,  }} tooltip="." />


                {/* comments */}
                <SpriteButton debug={DEBUG} style={{top: 140, left: 740, width: 20, height: 20, zIndex: 2, }} onClick={onClickComment} />
                <SpriteButton debug={DEBUG} style={{top: 380, left: 740, width: 20, height: 20, zIndex: 2, }} onClick={onClickComment} />
                <SpriteButton debug={DEBUG} style={{top: 520, left: 740, width: 20, height: 20, zIndex: 2, }} onClick={onClickComment} />


                {/* new comment */}
                <SpriteListHover debug={0} className="rds-price-hoverlist" selection={selection} onClickItem={onClickCell} count={10} itemHeight={48} style={{width: 150, top: 142, left: 610, }} />                


                {/* lock / calendar */}
                <Sprite absall debug={DEBUG} style={{top: 148, left: expanded?430:406, width: 60, height: 470, }} >
                    <SpriteButton debug={DEBUG} style={{top: (48*0), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*1), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*2), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*3), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*4), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*5), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*6), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*7), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*8), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    <SpriteButton debug={DEBUG} style={{top: (48*9), left: 0, width: 20, height: 30, }} onClick={onClickLock} />
                    {/* arrow */}
                    <SpriteButton debug={DEBUG} style={{top: (48*0), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*1), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*2), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*3), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*4), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*5), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*6), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*7), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*8), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                    <SpriteButton debug={DEBUG} style={{top: (48*9), left: 24, width: 20, height: 30, }} onClick={onClickLockArrow} />
                </Sprite>
                <Popover name="price-lock-calendar" ao={ao2} to={to2} shape="rounded" open={calendarOpen} anchorEl={calendarEl} onClose={onCloseLock} />

                {/* check all */}
                <SpriteButton debug={DEBUG} style={{top: 110, left: 0, width: 30, height: 30, }} onClick={onClickCheckall} />
                <Popover name="price-list-checkall-menu" shape="rounded" open={checkallOpen} anchorEl={checkallEl} onClose={onCloseCheckall} onClickItem={onClickChecked} />


                {/* lock */}
                <Sprite className="rds-price-lock-checked" style={{width: 1, height: 1, }} /> {/* cache it */}
                {checked && <SpriteButton debug={DEBUG} style={{top: 60, left: 'auto', right: 27, width: 130, height: 36, }} onClick={onClickAction} />}
                <Popover name="price-lock-menu" ao={ao2} to={to2} shape="rounded" open={actionOpen} anchorEl={actionEl} onClose={onCloseAction} />


                {/* expand 
                <SpriteButton debug={DEBUG} style={{top: 150, left: 40, width: 40, height: 30, }} onClick={onClickExpand} />
                */}

                {/* cache */}
                <Sprite className="rds-price-list.is-mode-expand" style={{width: 1, height: 1, }} />
                <Sprite className="rds-price-list.is-mode-checked" style={{width: 1, height: 1, }} />




            </Sprite>

            <Paginationbar />

        </Layout>
    );
}

function PriceListRow(){
    const dispatch = useGlobalsDispatch();
    // const globals = useGlobals();

    const onClick = () => {
        utils.loader(dispatch, {type: 'setScreen', value: "price-details"}, 1000);
    }

    // return
    return (
        <SpriteButton absall debug={0} className="rds-price-list-row" onClick={onClick}>

            <SpriteButton absall debug={0} className="rds-price-list-row-leftbox">
                <Sprite className="rds-iconadv is-type-open" />
            </SpriteButton>

        </SpriteButton>
    );
}

