import React from 'react';
import {Link} from './Text';
import classNames from 'classnames';
import './_foundation.scss';
import {Sprite, SpriteButton} from './Sprite';
import {Popover} from './Popover';
import { useGlobalsDispatch } from '../app/context';
import u from '../app/utils';
import './Misc.scss';


function EditTableList_Item ({children, onAction, }){

    // popover - recent
    const [recentEl, setRecentEl] = React.useState(null); // NOTE: remove
    const recentOpen = !!recentEl;    
    const onActionClose = () => {
        setRecentEl(null);
        if(onAction) onAction();
    };
    const onActionClick = (e) => setRecentEl(e.currentTarget);

    const ao = {vertical: 'top', horizontal: 'right',};
    const to = {vertical: 'bottom', horizontal: 'right',};

    // return
    return (
        <li className={recentOpen && 'is-selected'}>
            <div>{children}</div>
            <SpriteButton debug={0} className="rds-action-button" onClick={onActionClick} />
            <Popover name="edit-table-menu" shape="rounded" open={recentOpen} anchorEl={recentEl} ao={ao} to={to} onClose={onActionClose} />
        </li>
    );
}


// main
export function EditTableList ({children, className, style, itemCount, hasActions, onClick, onAction, }){
    const css = classNames('rds-edittablelist', className, hasActions && 'is-actions');

    // item count?
    if(itemCount){
        children = [];
        for(var i=0; i<itemCount; i++) children.push(<EditTableList_Item onAction={onAction} />);
    }

    // return
    return <ul className={css} style={style} onClick={onClick}>{children}</ul>
}

EditTableList.Item = EditTableList_Item;