import gen from "./_generator";
import vehicleTactics from "./vehicleTactics";
import data from "./vehicleTypesData";

const rows = data;
/*
var rows = gen.rows(10, (i) => {
    const vehicleTactic1 = gen.randomItem(vehicleTactics);
    const vehicleTactic2 = gen.randomItem(vehicleTactics);

    return {
        ID: gen.id(i),
        Name: "Vehicle Type " + (i+1),
        Tactic_1: vehicleTactic1.Name,
        Tactic_1_Min: vehicleTactic1.Min,
        Tactic_1_Mod: vehicleTactic1.Mod,
        Tactic_2: vehicleTactic2.Name,
        Tactic_2_Min: vehicleTactic2.Min,
        Tactic_2_Mod: vehicleTactic2.Mod,
    };
});
*/

export default rows;

