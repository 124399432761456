import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteField} from "../../foundation";



export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 0;

    const onFunding = (e) => {
        var value = e.target.value;
        dispatch({type: "updateModel", value: {Funding: value}});
    }

    return ( // --- TEMP
        <Sprite className="rds-promo-customer is-abs-all" style={{height:600, width: 'auto'}}>
            {false && <SpriteField onChange={onFunding} debug={debug} value={record.Funding} style={{top: 265, left: 7, width: 400}} />}
        </Sprite>
    );
};

