import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteListHover, SpriteButton, SpriteCheckbox, } from '../foundation/Sprite';
import {PanelBottom, Group, EditTableList, } from '../foundation';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';
import './PriceFiltersPanel.scss';



export default function Panel({name, }){
    const dispatch = useGlobalsDispatch();
    const [changed, setChanged] = React.useState(false);
    const [openCat, setOpenCat] = React.useState(false);
    const [openFruits, setOpenFruits] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    const onCancel = () => {dispatch({type: 'hidePanel'})}
    const onSave = () => {dispatch({type: 'hidePanel'})}
    const onAction = () => {setChanged(true);}

    const Item = EditTableList.Item;

    const onClickCategory = () => {
        // --- WIP
        setChanged(true);
        setOpenCat(!openCat);
    }

    const onClickFruits = () => {
        // --- WIP
        setChanged(true);
        setOpenFruits(!openFruits);
    }

    const onClickCheckbox = (e) => {
        console.log('---- onClickCheckbox', e);
        const check = !checked;
        if(check) setOpenFruits(true);
        setChecked(check);
    }

    //<Sprite absall className="rds-price-filters-panel" onClick={() => setChanged(true)} style={{width: 422, height: 1053}}>
    //<SpriteListHover count={19} itemHeight={40} rounded debug222={1} style={{top: 300, left: -3, }} />
    // return
    const css = utils.classNames("rds-price-filters-panel", openCat && `is-open-category`, openFruits && `is-open-fruits`);
    return (
        <React.Fragment>

            
            <Sprite className={css}>

                <Sprite className="rds-price-filters-panel-top">

                    <SpriteListHover debug={0} id="category-button" className="is-abs" count={1} itemHeight={40} onClickItem={onClickCategory} rounded style={{top: 300, left: -3, zIndex: 1, }} />
                    
                    <Sprite debug={0} className="rds-price-filters-panel-arrow-1 is-abs" style={{top: 310, left: 400, }} />
                    
                </Sprite>


                <Sprite absall className="rds-price-filters-panel-middle" >
                     
                    <SpriteButton debug={0} id="fruits-button" onClick={onClickFruits} style={{top: 140, left: 350, width: 70, height: 30, zIndex: 2, }} />

                    <Sprite debug={0} className="rds-price-filters-panel-arrow-2 is-abs" style={{top: 147, left: 400, }} />

                    <SpriteCheckbox.Auto debug={0} style={{top: 6, left: -4, width: 200, }} />
                    <SpriteCheckbox.Auto debug={0} style={{top: 34, left: -4, width: 200, }} />
                    <SpriteCheckbox.Auto debug={0} style={{top: 62, left: -4, width: 200, }} />
                    <SpriteCheckbox.Auto debug={0} style={{top: 90, left: -4, width: 200, }} />
                    <SpriteCheckbox.Auto debug={0} style={{top: 117, left: -4, width: 200, }} />


                    <SpriteCheckbox debug={0} style={{top: 146, left: -4, }} checked={checked} onChange={onClickCheckbox} />
                    <SpriteCheckbox debug={0} style={{top: 174, left: 12, }} checked={checked} />
                    <SpriteCheckbox debug={0} style={{top: 202, left: 12, }} checked={checked} />
                    <SpriteCheckbox debug={0} style={{top: 230, left: 12, }} checked={checked} />
                    <SpriteCheckbox debug={0} style={{top: 258, left: 12, }} checked={checked} />
                    <SpriteCheckbox debug={0} style={{top: 286, left: 12, }} checked={checked} />
                    <SpriteCheckbox debug={0} style={{top: 314, left: 12, }} checked={checked} />

                </Sprite>


                <Sprite absall className="rds-price-filters-panel-bottom" >
                    <SpriteListHover debug={0} count={17} itemHeight={40} rounded style={{top: 'auto', left: -3, bottom: 42, }} /> 
                </Sprite>

            </Sprite>

            <PanelBottom>
                <Group type="cancel-save" onCancel={onCancel} onSave={onSave} disabled={!changed} />
            </PanelBottom>

        </React.Fragment>


        


    );

}
