import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteField} from "../../foundation";
import {TreeSelect} from "../../components/TreeSelect";



export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 1;

    // const onLocation = (e) => {
    //     var value = utils.toJsonObject(e.target.value, []);
    //     dispatch({type: "updateModel", value: {Locations: value}});
    // }
    // {false && <SpriteField onChange={onLocation} debug={debug} value={utils.toJsonString(record.Locations, '')} style={{top: 200, left: 7, width: 400}} />}
    const [expanded, setExpanded] = React.useState([]);

    const onChecked = (checkeds) => {
        dispatch({type: "updateModel", value: {Locations: checkeds}});
    }
    const onExpanded = (expanded) => {
        dispatch({type: "updateModel", value: {_Locations_Expanded: expanded}});
        // save which are expanded even if NOT saving
        if(record.ID) dispatch({type: "updateRow", table: "fundings", id: record.ID, values: {_Locations_Expanded: expanded}});
    }

    return ( // --- TEMP

        <React.Fragment>
            <Sprite id="rds-locations-title" style={{top: 0, left: 0, width: 880, marginBottom: 20, }} />
            <TreeSelect type="locations" checked={record.Locations} expanded={record._Locations_Expanded} onChecked={onChecked} onExpanded={onExpanded} style={{top: 0, left: 0}} />
        </React.Fragment>
    );
};

