import React from "react";
import { useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Layout} from "../../foundation/Layout";



export default function Screen(){
    const globals = useGlobals();
    const style = {width: 800, height: 400, };
    const style2 = {width: 800, height: 100, };
    const onChange = () => {};


    return (
        <Layout>
            <h2>Promos</h2>
            <textarea style={style} onChange={onChange} value={JSON.stringify(globals.promos_rows, undefined, 2)} />
            <textarea style={style2} onChange={onChange} value={JSON.stringify(globals.promos_rows)} />
            <h2>Fundings</h2>
            <textarea style={style} onChange={onChange} value={JSON.stringify(globals.fundings_rows, undefined, 2)} />
            <textarea style={style2} onChange={onChange} value={JSON.stringify(globals.fundings_rows)} />
            <h2>Locations</h2>
            <textarea style={style} onChange={onChange} value={JSON.stringify(globals.locations_rows, undefined, 2)} />
            <h2>Periods</h2>
            <textarea style={style} onChange={onChange} value={JSON.stringify(globals.periods_rows, undefined, 2)} />
            <h2>Products</h2>
            <textarea style={style} onChange={onChange} value={JSON.stringify(globals.products_rows, undefined, 2)} />
            <h2>Vehicles</h2>
            <textarea style={style} onChange={onChange} value={JSON.stringify(globals.vehicles_rows, undefined, 2)} />
        </Layout>
    );
}


