// DONE: 12/20/2022

const rows = [
    {
      "ID": 1,
      "Tactic": "Door Deal Feature",
      "Min": 2,
      "Max": 3,
      "Volume_Modifier": 0.22
    },
    {
      "ID": 2,
      "Tactic": "Door Deal Spotlight",
      "Min": 2,
      "Max": 1,
      "Volume_Modifier": 0.08
    },
    {
      "ID": 3,
      "Tactic": "Counter Deal Feature",
      "Min": 3,
      "Max": 9,
      "Volume_Modifier": 0.15
    },
    {
      "ID": 4,
      "Tactic": "Counter Deal Spotlight",
      "Min": 3,
      "Max": 10,
      "Volume_Modifier": 0.23
    },
    {
      "ID": 5,
      "Tactic": "Cooler Deal Feature",
      "Min": 1,
      "Max": 7,
      "Volume_Modifier": 0.21
    },
    {
      "ID": 6,
      "Tactic": "Cooler Deal Spotlight",
      "Min": 1,
      "Max": 9,
      "Volume_Modifier": 0.16
    },
    {
      "ID": 7,
      "Tactic": "Topper Deal Feature",
      "Min": 2,
      "Max": 3,
      "Volume_Modifier": 0.28
    },
    {
      "ID": 8,
      "Tactic": "Topper Deal Spotlight",
      "Min": 1,
      "Max": 10,
      "Volume_Modifier": 0.3
    },
    {
      "ID": 9,
      "Tactic": "Diesel Deal Feature",
      "Min": 1,
      "Max": 3,
      "Volume_Modifier": 0.1
    },
    {
      "ID": 10,
      "Tactic": "Diesel Deal Spotlight",
      "Min": 3,
      "Max": 9,
      "Volume_Modifier": 0.13
    },
    {
      "ID": 11,
      "Tactic": "Flyer Deal Feature",
      "Min": 1,
      "Max": 9,
      "Volume_Modifier": 0.09
    },
    {
      "ID": 12,
      "Tactic": "Flyer Deal Spotlight",
      "Min": 1,
      "Max": 8,
      "Volume_Modifier": 0.2
    },
    {
      "ID": 13,
      "Tactic": "Email Deal Feature",
      "Min": 1,
      "Max": 4,
      "Volume_Modifier": 0.26
    },
    {
      "ID": 14,
      "Tactic": "Email Deal Spotlight",
      "Min": 2,
      "Max": 9,
      "Volume_Modifier": 0.14
    },
    {
      "ID": 15,
      "Tactic": "Rewards Deal Feature",
      "Min": 1,
      "Max": 1,
      "Volume_Modifier": 0.12
    },
    {
      "ID": 16,
      "Tactic": "Rewards Deal Spotlight",
      "Min": 1,
      "Max": 2,
      "Volume_Modifier": 0.2
    },
    {
      "ID": 17,
      "Tactic": "App Deal Banner",
      "Min": 3,
      "Max": 8,
      "Volume_Modifier": 0.09
    },
    {
      "ID": 18,
      "Tactic": "App Deal Feature",
      "Min": 3,
      "Max": 3,
      "Volume_Modifier": 0.3
    }
   ]

   export default rows;