import { GlobalsProvider } from './context.js';
import Router from './Main';

// scss includes
import '../components/_components.scss';
import './app.scss';
import '../theme/theme.scss';


export default function App() {

  return (
    <GlobalsProvider>
      <Router />
    </GlobalsProvider>
  );
}

