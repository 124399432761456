import gen from "./_generator";
import data from "./productsData";
import utils from "../app/utils";



var rows = gen.rows(data.length, (i) => {
    const row = data[i];

    // data?
    var obj = {};
    obj.ID = row.ID;
    obj.Base_Units = row.Base_Units;
    obj.UPC = row.UpcCode;
    obj.Vendor = row.vendorName;
    obj.Name = row.ProductDescription;
    obj.Brand = row.BrandName;
    obj.Case_Size = row.CaseSize; // is this base units?
    obj.Unit_Price = row.Unit_Price;
    obj.Unit_Cost = row.Unit_Cost;
    obj.Unit_Margin = row.Margin;
    obj.Elasticity = row.Elasticity;
    obj.Level1 = row.Level1;
    obj.Level2 = row.Level2;
    obj.Level3 = row.Level3;

    /* --- OLD
    var obj = {};// Base_Units: gen.randomRange(gen.ranges[9], gen.ranges[10]), // --- WHERE IS THIS??
    obj.ID = gen.id(i);
    // obj.Base_Units = gen.randomRange(gen.ranges[9], gen.ranges[10]); // THIS IS MISSING FROM productsData.js
    obj.Base_Units = 16;

    // data?
    obj.UPC = row.UpcCode;
    obj.Vendor = row.vendorName;
    obj.Name = row.ProductDescription;
    obj.Brand = row.BrandName;
    obj.Case_Size = row.CaseSize; // is this base units?
    obj.Unit_Price = row.Unit_Price;
    obj.Unit_Cost = row.Unit_Cost;
    obj.Unit_Margin = row.Margin;
    obj.Elasticity = row.Elasticity;
    obj.Level1 = row.Level1;
    obj.Level2 = row.Level2;
    obj.Level3 = row.Level3;
    */

    // return
    return utils.createRow("products", obj);
});


export default rows;