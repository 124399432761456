import gen from "./_generator";
import utils from "../app/utils";

import offers from "./offers";
import periods from "./periods";
import fundings from "./fundings";
import products from "./products";
import vehicles from "./vehicles";
import data2 from "./promosData";


const status_enum = ["Approved", "Planning", "Active"];
const status_index = [1,0,2,0,1,0,2,2,0,1,2,0,1,2,0,2,2,0,1,2,0,1,2,0,1,0,2,0,1,0,2,2,0,1,2,0,1,2,0,2,2,0,1,2,0,1,2,0];


/*
    // --- PROMO
      "ID": 1, // KW
      "Name": "Save Big on 7Up - Soda Pkged - 20% Off 7Up",
      "External_ID": 551000,
      "Description": "Winter - Cooler",
      "Status": "",
      "Custom_Labels": "Winter",
      "FIELD7": "Cooler",
      "Start_Date": "1/1/2023",
      "End_Date": "1/14/2023",
      "Period_ID": "1", // KW
      "Products": "58,59,60,64,65,66,67",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "1,3,5,7,9,11,13,15,17",
      "Funding": null,
      "Offer": 5

    // --- PROMO
      "ID": 10,
      "Name": "Snack Better - Energy Bars - 10% Off Lean Energy Bars",
      "External_ID": 551011,
      "Description": "Spring Fling - Grocery (Energy Bars)",
      "Status": "",
      "Custom_Labels": "Spring Fling",
      "FIELD7": "Grocery (Energy Bars)",
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period_ID": "8",
      "Products": 720, // TEMP. WAS "720,721,731,734",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "32,36,38,39,42,43,46,47",
      "Funding": null,
      "Offer": 4

    // --- PRODUCT      
    "ID": 720,
      "UpcCode": 4810709592,
      "vendorName": "SRP",
      "ProductDescription": "Lean Shake Chocolate",
      "BrandName": "SRP",
      "CaseSize": 0,
      "Unit_Price": 3.99,
      "Unit_Cost": 2.39,
      "Margin": 0.4,
      "Elasticity": -0.6018156938,
      "Base_Units": 74,
      "Level1": "Grocery",
      "Level2": "Grocery",
      "Level3": "Hlth/Energy Bars"
*/

// --- TEMP: ID: 10
// const data = [data2[9]];
// const product_id = 720;

 // --- TEMP: ID: 9
//const data = [data2[8]];
//const product_id = 69;

// const data = data2;
// ---- TEST 12/26

var data = data2;
var product_id = 0;
var debug_group = false;

if(false){
    var id = 1;
    data = [data2[id-1]];
    debug_group = {
        // products: [67], // tester
        // products: [58,59], // tester
        products: [58,59,60,65,66,67], // was incorrect: 58,59,60,64,65,66,67 had to remove 64
     
        locations: [1,2,3,4,5,6,7,8,9,10],
        vehicles: [1,3,5,7,9,11,13,15,17],
     };
}

if(false){
    var id = 1;
    data = [data2[id-1]];
}



// iterate promos
var rows = gen.rows(data.length, (i) => {
    // if(i) return // --- TEMP: only do first
    const row = data[i];
    const status = (row.Status === "") ? status_enum[status_index[i]] : row.Status;

    const obj = {
        ID: row.ID,
        Name: row.Name,
        External_ID: row.External_ID,
        Description: row.Description,
        Status: status,
        Custom_Labels: row.Custom_Labels,
        Funding: row.Funding,

        // dates
        Period_ID: parseInt(row.Period_ID), // KW, INT
        Period: null, // KW period object
        Period_Name: null, // STRING period.Name,
        Start_Date: null, // INT  period.Start_Date, // can put in any date here
        End_Date: null, // INT period.End_Date,

        // focus:
        Products: utils.toString(row.Products).split(","),
        Locations: utils.toString(row.Locations).split(","),
        Vehicles: utils.toString(row.Vehicles).split(","),
        Offer: row.Offer, // selected
        Offers: [row.Offer],

        // extras
        _Products_Expanded: [], // for TreeSelect
        _Locations_Expanded: [], // for TreeSelect
        _Upc_Numbers: null,
    }


    // ------- TEMP: for testing
   // if(product_id) obj.Products = [product_id]; // focus on single product

   if(debug_group){
        obj.Products = debug_group.products;
        obj.Locations = debug_group.locations;
        obj.Vehicles = debug_group.vehicles;
   } else if(product_id){
        obj.Products = [product_id]; // focus on single product
   }





    // console.log("+++++++++++++++++++++ PROMO 10: ", obj);

    /*
    if(obj.ID === 1){ // --- TEMP
        // Start_Date: 
        obj.Name = "TEST";
        obj.Products = [58,59,60]; // 58,59,60,64,65,66,67
        obj.Locations = [1,2,3,4,5,6,7,8,9,10];
        obj.Vehicles = [1]; // 1,3,5,7,9,11,13,15,17
        obj.Offers = [5];
        obj.Offers_Selected = 5;    
    }
    */

    /*
    const period = gen.randomItem(periods);
    const offers = gen.randomItem([[1,3,5], [2,4,6], [7,8,9]]);

    const obj = {
        ID: gen.id(i),
        Name: "Promo " + (i+1), // gen.nextItem(i, names),
        External_ID: gen.extId(i), //gen.randomRange(456789, 556789),
        Description: "Promo Description " + (i+1), // "all coke 12 packs, traffic driver", // TODO
        Status: gen.randomItem(["Planning", "Approved", "Active"]),
        Custom_Labels: "Custom Label " + (i+1), // "2023 Father's Day, Coke, Coca-Cola, Bev", // TODO
        Funding: gen.randomItem(fundings).ID, // NOTE: single object      gen.randomItem(fundings).Funding_Type, // ???

        // dates
        Period_ID: 1, // KW, 
        Period: null, // KW period object
        Period_Name: null, // STRING period.Name,
        Start_Date: null, // INT  period.Start_Date, // can put in any date here
        End_Date: null, // INT period.End_Date,
        

        // FOCUS:
        Products: gen.randomItem([[1,3,5], [2,4,6], [7,8,9]]),
        Locations: gen.randomItem([[3,6,8],[2,4,8,9],[1,3,5,8,10]]),
        Vehicles: ['1.1', '2.1', '3.1'], // NOTE: array, keep as 1 for now
        Offers222: offers,
        Offers: offers, // gen.randomItem(offers).ID, // Array
        Offers_Selected: offers[0], // select first

        // extras
        _Products_Expanded: [], // for TreeSelect
        _Locations_Expanded: [], // for TreeSelect
        _Upc_Numbers: null,
    };

    // data ====================================================== TEMP
    if(obj.ID === 1){ // --- TEMP
        obj.Products = [1];
        obj.Locations = [1,2,3,4,5,6,7,8,9,10];
        obj.Vehicles = ['1.1'];
        obj.Offers = [1];
        obj.Offers_Selected = 1;    
    }
    */

    // return
    return utils.createRow("promos", obj);
});


export default rows;



