import React from "react";
import {Layout} from "../../foundation/Layout";
import utils from "../../app/utils";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar3, Paginationbar} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Sprite, SpriteButton, Icon, Select} from '../../foundation';






export default function Screen(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var rawrows = globals.vehicles_rows;
    const rows = rawrows;

    // limit 10 (1/5)
    

    // console.log("================= ROWS: ", rows);

    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const handleClick = (event) => {setAnchor(event.currentTarget)};
    const handleClose = () => {setAnchor(null)};

    const onClickEditTable = () => dispatch({type: "showPanel", value: "edit-table"});
    const onClickTimeline = () => dispatch({type: "setScreen", value: "vehicle-timeline"});

    // const onClickEditTable = () => dispatch({type: "showPanel", value: "edit-table"});
    // const onClickCards = () => dispatch({type: "setScreen", value: "funding-cards"});
    // const onClickTimeline = () => dispatch({type: "setScreen", value: "funding-timeline"});

    const onStatus = (item, value) => {
        console.log("--------- onStatusSelect: SORT ", globals.promos_sort);

        dispatch({type: "updateRow", table: "vehicles", id: item.ID, values: {Status: value}});
     }

    const headers = [
        {name: 'Name'},
        {name: 'Status'},
        {name: 'Start_Date', label: 'Start date'},
        {name: 'End_Date', label: 'End date'},
        {name: 'Promotion_Count', label: '# of promotions'},
        // {name: 'Locations', label: "Location"},
        {name: 'Type', label: "Vehicle type"},
    ];
    // const rows  = utils.remakeRows(rawrows, headers); // ['Name', 'Status', "Start_Date", "End_Date"]

    // handlers
    const onClickSort = (header) => {
        dispatch({type: "sortTable", table: "vehicles", field: header.name});
        // dispatch({type: "sortTable", value: ['vehicles', header.name]});
    }

    const onClickName = (item) => {
        dispatch({type: "showDetails", table: "vehicles", id: item.ID});
    }

    const onChecked = () => {
        console.log("testing");
     }

     function onClickView(item){
        dispatch({type: "hidePanel"});
        dispatch({type: "showDetails", table: "vehicles", id: item.ID});
    }

    return (
        <Layout>
            <Searchbar />

            <Titlebar3 type="vehicle-list">
                <SpriteButton className="is-abs-right is-debu" style={{width: 20}} onClick={onClickTimeline} />
            </Titlebar3>


            <DataList className="is-nooverflow" headers={headers} onChecked={onChecked} onClickSort={onClickSort}>
              {rows.map((item, i) => i<10 && ( 
                    <DataRow onChecked={onChecked} index={i} key={i} onClickView={onClickView} item={item}>
                        <DataCell name="Name" className="is-wrap" onClick={() => {onClickName(item)}}>{item.Name}</DataCell>
                        <DataCell name="Status"><Select type="status" onChange={(value) => onStatus(item, value)} value={item.Status} /></DataCell>
                        <DataCell name="Start_Date">{utils.printDate(item.Start_Date)}</DataCell>
                        <DataCell name="End_Date">{item.End_Date}</DataCell>
                        <DataCell name="Promotion_Count">{item.Promotion_Count}</DataCell>
                        {false && <DataCell name="Locations">{item.Locations}</DataCell>}
                        <DataCell name="Type">{item.Type}</DataCell>
                    </DataRow>
              ))}
            </DataList>

            <Paginationbar />
        </Layout>
    );
}
