import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton, Panel, PanelBottom, Group} from '../foundation';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';



export default function FilterPanel({id}){
    const dispatch = useGlobalsDispatch();
    const onCancel = () => {dispatch({type: 'hidePanel'})}

    return (
        <Panel name="filters" style={{height: 1130}}>
            

            <PanelBottom>
                <Group type="confirm-buttons" onCancel={onCancel} />
            </PanelBottom>
        </Panel>
    );
}
