const rows = [
    {
      "ID": 1,
      "Name": "Save Big on 7Up - Soda Pkged - 20% Off 7Up",
      "External_ID": 551000,
      "Description": "Winter - Cooler",
      "Status": "Active",
      "Custom_Labels": "Winter",
      "FIELD7": "Cooler",
      "Start_Date": "1/15/2023",
      "End_Date": "1/28/2023",
      "Period_ID": "2",
      "Products": "58,59,60,64,65,66,67",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "1,3,5,7,9,11,13,15,17",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 2,
      "Name": "Beat Winter - Gloves - 20% Off Select Gloves",
      "External_ID": 551001,
      "Description": "Winter - General Merch (Gloves)",
      "Status": "Active",
      "Custom_Labels": "Winter",
      "FIELD7": "General Merch (Gloves)",
      "Start_Date": "1/15/2023",
      "End_Date": "1/28/2023",
      "Period_ID": "2",
      "Products": "167,168,214,215,250,575,748",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "4,6,8,10,12,14,16,18",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 3,
      "Name": "Light up Savings - Lighters - 50% Off Select Lighters",
      "External_ID": 551019,
      "Description": "Valentine's Day - Grocery (Lighters)",
      "Status": "Approved",
      "Custom_Labels": "Valentine's Day",
      "FIELD7": "Grocery (Lighters)",
      "Start_Date": "2/12/2023",
      "End_Date": "2/25/2023",
      "Period_ID": "3",
      "Products": "7,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "19,22",
      "Funding": null,
      "Offer": 6
    },
    {
      "ID": 4,
      "Name": "Save on Feeling Better - Vitamins - 10% All GNC Vitamins",
      "External_ID": 551003,
      "Description": "Valentine's Day - Grocery (Vitamins)",
      "Status": "Approved",
      "Custom_Labels": "Valentine's Day",
      "FIELD7": "Grocery (Vitamins)",
      "Start_Date": "2/12/2023",
      "End_Date": "2/25/2023",
      "Period_ID": "3",
      "Products": "454,455,456,457,458,459,460,461,462,463,464,465,466,467,468",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "20,24",
      "Funding": null,
      "Offer": 4
    },
    {
      "ID": 5,
      "Name": "Never lose your phone again - Mobile Accessories - 10% Off MagBuddy Phone Mounts",
      "External_ID": 551004,
      "Description": "Valentine's Day - General Merch (Mobile Accessories)",
      "Status": "Planning",
      "Custom_Labels": "Valentine's Day",
      "FIELD7": "General Merch (Mobile Accessories)",
      "Start_Date": "2/12/2023",
      "End_Date": "2/25/2023",
      "Period_ID": "3",
      "Products": "807,808,809,810,811,812,813,814,815,816,817,818,819",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "21,23",
      "Funding": 1,
      "Offer": 4
    },
    {
      "ID": 6,
      "Name": "Beat March Madness with Coke - Soda Pkged - $1 Off Coke",
      "External_ID": 551006,
      "Description": "March Madness - Cooler",
      "Status": "",
      "Custom_Labels": "March Madness",
      "FIELD7": "Cooler",
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period_ID": "4",
      "Products": "989,992,993,994,995,996",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "25,27",
      "Funding": null,
      "Offer": 2
    },
    {
      "ID": 7,
      "Name": "Snack Better - Salty Snacks - 20% Off Bare Baked Fruit Chips",
      "External_ID": 551007,
      "Description": "March Madness - Grocery (Salty Snacks)",
      "Status": "",
      "Custom_Labels": "March Madness",
      "FIELD7": "Grocery (Salty Snacks)",
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period_ID": "4",
      "Products": "981,982,983,984",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "26,29",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 8,
      "Name": "Disney Savings - Disney Dolls - 20% Off Disney Dolls",
      "External_ID": 551008,
      "Description": "March Madness - General Merch (Disney Toys)",
      "Status": "",
      "Custom_Labels": "March Madness",
      "FIELD7": "General Merch (Disney Toys)",
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period_ID": "4",
      "Products": "306,307,308,309,310,311,312,313,314,315,316,317,318,319,320,321,322,323,324,325,326,327,328,329,330,331,332",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "28,30",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 9,
      "Name": "Save Big on AW Rootbeer - Soda Pkged - $1 Off AW Rootbeer",
      "External_ID": 551010,
      "Description": "Spring Fling - Cooler",
      "Status": "",
      "Custom_Labels": "Spring Fling",
      "FIELD7": "Cooler",
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period_ID": "4",
      "Products": "68,69,70,71,72,73",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "31,33,37,41,45",
      "Funding": null,
      "Offer": 2
    },
    {
      "ID": 10,
      "Name": "Snack Better - Energy Bars - 10% Off Lean Energy Bars",
      "External_ID": 551011,
      "Description": "Spring Fling - Grocery (Energy Bars)",
      "Status": "",
      "Custom_Labels": "Spring Fling",
      "FIELD7": "Grocery (Energy Bars)",
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period_ID": "4",
      "Products": "720,721,731,734",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "32,36,38,39,42,43,46,47",
      "Funding": null,
      "Offer": 4
    },
    {
      "ID": 11,
      "Name": "Upgrade Your Headphones - Headphones - 20% Off JBL Headphones",
      "External_ID": 551012,
      "Description": "Spring Fling - General Merch (Headphones)",
      "Status": "",
      "Custom_Labels": "Spring Fling",
      "FIELD7": "General Merch (Headphones)",
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period_ID": "4",
      "Products": "665,666,667,668,669,670,671,672,673,674",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "34,35,40,44,48",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 12,
      "Name": "Save Big on 7Up - Soda Pkged - $1 Off 7Up",
      "External_ID": 551015,
      "Description": "Summer Vacation - Cooler",
      "Status": "",
      "Custom_Labels": "Summer Vacation",
      "FIELD7": "Cooler",
      "Start_Date": "5/7/2023",
      "End_Date": "5/20/2023",
      "Period_ID": "5",
      "Products": "58,59,60,64,65,66,67",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "49,51,53",
      "Funding": 2,
      "Offer": 2
    },
    {
      "ID": 13,
      "Name": "Lost Your Charger - Chargers - 50% Off Ttnm Chargers",
      "External_ID": 551016,
      "Description": "Summer Vacation - General Merch (Chargers)",
      "Status": "",
      "Custom_Labels": "Summer Vacation",
      "FIELD7": "General Merch (Chargers)",
      "Start_Date": "5/7/2023",
      "End_Date": "5/20/2023",
      "Period_ID": "5",
      "Products": "775,776,777,778,779,780,781,782,783,784,785,786,787,788,789,790,796,797,798,799,800,801,802,803,804,805,806, 829,830,831,832,833,834,835,836,837,838,875,876,877,878,879,880,881,882,883,884,885,886,887,888,889,890,891,892",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "50,52,54",
      "Funding": null,
      "Offer": 6
    },
    {
      "ID": 14,
      "Name": "Celebrate America with Mason Jars - Novelties - 20% Off Mason Jars",
      "External_ID": 551018,
      "Description": "July 4th - General Merch (Novelties)",
      "Status": "",
      "Custom_Labels": "July 4th",
      "FIELD7": "General Merch (Novelties)",
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period_ID": "6",
      "Products": "645,646,647,648,649,650,653,654",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "62,65,69,73,77",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 15,
      "Name": "Save Big on RC - Soda Pkged - $1 Off RC Cola",
      "External_ID": 551002,
      "Description": "Valentine's Day - Cooler",
      "Status": "",
      "Custom_Labels": "July 4th",
      "FIELD7": "Cooler",
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period_ID": "6",
      "Products": "47,48,49,50,51,52,53,54",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "64,66,67,70,71,74,75,78",
      "Funding": null,
      "Offer": 2
    },
    {
      "ID": 16,
      "Name": "Sumer Savings - Sunglasses - 20% Off Pugssg Nowstalgia",
      "External_ID": 551020,
      "Description": "July 4th - General Merch (Sunglasses)",
      "Status": "",
      "Custom_Labels": "July 4th",
      "FIELD7": "General Merch (Sunglasses)",
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period_ID": "6",
      "Products": "770,771,772,773,774,820,821,822,823,824,825,826",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "63,68,72,76",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 17,
      "Name": "Upgrade Your Headphones - Headphones - 20% Off JVC Headphones",
      "External_ID": 551023,
      "Description": "Back to School - General Merch (Headphones)",
      "Status": "",
      "Custom_Labels": "Back to School",
      "FIELD7": "General Merch (Headphones)",
      "Start_Date": "8/13/2023",
      "End_Date": "8/26/2023",
      "Period_ID": "7",
      "Products": "682,683,684,685,686,687,688",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "79,81,83",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 18,
      "Name": "Beat the Heat - Hats - 10% Off Hats",
      "External_ID": 551024,
      "Description": "Back to School - General Merch (Hats)",
      "Status": "",
      "Custom_Labels": "Back to School",
      "FIELD7": "General Merch (Hats)",
      "Start_Date": "8/13/2023",
      "End_Date": "8/26/2023",
      "Period_ID": "7",
      "Products": "19,20,21,22,23,24,42,216,266,373,374,375,376,377,411,412,413,414,415,416,417,418,445,446,447,448,452",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "80,82,84",
      "Funding": null,
      "Offer": 4
    },
    {
      "ID": 19,
      "Name": "Get Ready for Winter - Gloves - 20% Off Select Gloves",
      "External_ID": 551026,
      "Description": "Halloween - General Merch (Gloves)",
      "Status": "",
      "Custom_Labels": "Halloween",
      "FIELD7": "General Merch (Gloves)",
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period_ID": "8",
      "Products": "167,168,214,215,250,575,748",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "91,95,99,103,107",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 20,
      "Name": "Upgrade Your Speakers - Bluetooth - 20% Off LG Speakers",
      "External_ID": 551027,
      "Description": "Halloween - General Merch (Bluetooth)",
      "Status": "",
      "Custom_Labels": "Halloween",
      "FIELD7": "General Merch (Bluetooth)",
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period_ID": "8",
      "Products": "43,44,45,46",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "92,93,96,97,100,101,104,105,108",
      "Funding": 3,
      "Offer": 5
    },
    {
      "ID": 21,
      "Name": "Get Spooky Shirts - TShirts - $2 Off All TShirts",
      "External_ID": 551028,
      "Description": "Halloween - General Merch (TShirts)",
      "Status": "",
      "Custom_Labels": "Halloween",
      "FIELD7": "General Merch (TShirts)",
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period_ID": "8",
      "Products": "120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,268,269,270,289,290,291,292,293,294,295,296,297,298,299,300,333,334,335,336,337,338,339,340,341",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "94,98,102,106",
      "Funding": null,
      "Offer": 1
    },
    {
      "ID": 22,
      "Name": "Get them what they really want - Gifts - 20% Off Dogs Signs",
      "External_ID": 551029,
      "Description": "Happy Holidays - General Merch (Gifts)",
      "Status": "",
      "Custom_Labels": "Happy Holidays",
      "FIELD7": "General Merch (Gifts)",
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period_ID": "8",
      "Products": "695,696,697,698,699,700,701,702,703,704,705,706,707,708,709,710,711,712",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "115,119",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 23,
      "Name": "Give the Gift of Self Care - Grooming Supplies - 20% Select Grooming Supplies",
      "External_ID": 551031,
      "Description": "Happy Holidays - General Merch (Trucker Supplies)",
      "Status": "",
      "Custom_Labels": "Happy Holidays",
      "FIELD7": "General Merch (Trucker Supplies)",
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period_ID": "8",
      "Products": "240,241,242,251,252,253,254,255,256,257,258,285,286,342,343,344,404,450,451",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "116,117",
      "Funding": null,
      "Offer": 5
    },
    {
      "ID": 24,
      "Name": "Holiday Savings - Plush Toys - 50% Off All Plush Toys",
      "External_ID": 551032,
      "Description": "Happy Holidays - General Merch (Plush Toys)",
      "Status": "",
      "Custom_Labels": "Happy Holidays",
      "FIELD7": "General Merch (Plush Toys)",
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period_ID": "8",
      "Products": "233,234,235,359,360,361,362,363,364,365,366,368,406,839,840,841,842,843,844,845,846,847,848,849,850,851,852,853,854,855,856,857,858,859,860,861,862,863,926,927,928,929,930,931,932,933,934,935,936,937,938,939,940,941",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Vehicles": "118,120",
      "Funding": null,
      "Offer": 6
    }
   ]

   export default rows;