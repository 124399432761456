import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton, SpriteField, SpriteInput, SpriteText, SpriteCheckbox, SpriteSelect, Panel, PanelBottom, Group, MiniAnim} from '../foundation';
import {SimpleList} from "../foundation/DataList";
import {useGlobals, useGlobalsDispatch} from '../app/context';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import utils from '../app/utils';
import db from "../app/db";

import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import {CustomOffer,  } from "./AddOfferPanel-Custom";

// optimization


function Graph({onChange, initValue = 1}){
    const [graph, setGraph] = React.useState(initValue);

    React.useEffect(() => {
        if(onChange) onChange(graph);
    }, [graph]);

    return (
        <Sprite className={`rds-addoffer-optimizegraph${graph}-panel`} absall style={{width: 428, height: 360, top: 90}}>

            <SpriteButton debug={0} style={{top: 264, left: 10, width: 90, height: 80}} onClick={() => setGraph(1)} />
            <SpriteButton debug={0} style={{top: 264, left: 120, width: 90, height: 80}} onClick={() => setGraph(2)} />
            <SpriteButton debug={0} style={{top: 264, left: 220, width: 90, height: 80}} onClick={() => setGraph(3)} />
            <SpriteButton debug={0} style={{top: 264, left: 330, width: 90, height: 80}} onClick={() => setGraph(4)} />
            {false && <SpriteButton debug={0} style={{top: 264, left: 350, width: 70, height: 80}} onClick={() => setGraph(5)} />}

        </Sprite>
    );
}



function OptimizeGraph({onClose, dispatch, model}){
    const {optimizeObject: opt} = useGlobals();

    const [loading, setLoading] = React.useState(null);


    React.useEffect(() => {
        if(opt.graphLoading) {
            setTimeout(() => dispatch({type: 'updateOptimizeObject', name: 'graphLoading', value: false}), 2000);
        }
    }, [opt.graphLoading]);

    React.useEffect(() => {
        if(loading === false) return;
        if(loading === null) return setLoading(true);
         // console.log("##################### OptimizeGraph: ", loading);
         setTimeout(() => {
            setLoading(false);
         }, 1000);
    }, [loading]);


    const onBack = () => {
        dispatch({type: 'setOfferScreen', value: "optimization"})
    }

    const onApply = () => { // --- APPLY
        const values = {offerOptimized: true, offerValue: 4.24, offerPrice: 5.76}; // --- WIP: static values?
        dispatch({type: 'updateOptimizeObject', values: values});
        dispatch({type: 'setOfferScreen', value: "details"})
    }

    // `rds-addoffer-optimizegraph${graph}-panel`
    return (
        <React.Fragment>

            {/* v2 */}
            <Sprite className="rds-addoffer-optimizegraph-panel-v2" absall style={{width: 428, height: 640}}>

                {opt.graphLoading && <MiniAnim debug={0} style={{top: 460, left: 30, width: 100}} />}
                <SpriteText readOnly={0} debug={0} style={{top: 460, left: 30, width: 340}} label={utils.printPercent(opt.sugDiscountPct)}  />

                <SpriteButton debug={0} style={{top: 552, left: 10, width: 380}} />

            </Sprite>

            {/* v1
            <Sprite className="rds-addoffer-optimizegraph-panel" absall style={{width: 428, height: 640}}>

       
                {true && <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress color="inherit" /></Backdrop>}

                <Graph initValue={1} />


                {opt.graphLoading && <MiniAnim debug={0} style={{top: 504, left: 30, width: 100}} />}
                <SpriteText readOnly={1} debug={0} style={{top: 504, left: 30, width: 340}} label={utils.printPercent(opt.sugDiscountPct)}  />


                <SpriteButton debug={0} style={{top: 600, left: 10, width: 380}} />

            </Sprite>
            */}

            <PanelBottom>
                <Group type="back-apply" onBack={onBack} onApply={onApply} />
            </PanelBottom>
        </React.Fragment>
    );
}

const valueOptions = [
    {value: 0, label: "% off"},
    {value: 1, label: "$ off"},
    {value: 2, label: "Price"},
    {value: 3, label: "Buy X Get Y"},
];

const targetOptions = [
    {value: 0, label: "Product"},
    {value: 1, label: "Product from sublist 2"},
    {value: 2, label: "Product from sublist 3"},
];

const rulesetOptions = [
    {value: 0, label: "Default ruleset"},
    {value: 1, label: "Cooler ruleset"},
    {value: 2, label: "General merch (apparel) ruleset"},
    {value: 3, label: "Grocery ruleset"},
];

const strategyOptions = ["Margin", "Profit", "Units", "Revenue"];

// optimization

function Optimization({onClose, dispatch, model}){
    const {optimizeObject: opt} = useGlobals();
    const [completed, setCompleted] = React.useState(false);

    React.useEffect(() => {
        if(opt.optloading) {
            setTimeout(() => {
                dispatch({type: 'updateOptimizeObject', name: 'optloading', value: false})
            }, 2000);
        }
    }, [opt.optloading]);

    React.useEffect(() => {
        const completed = utils.isNumber(opt.optMaxUnits) && !opt.optloading;
        setCompleted(completed);
    }, [opt.optMaxUnits, opt.optloading]);

    const onBack = () => {
        dispatch({type: 'setOfferScreen', value: "details"})
    }

    const onOptimize = () => {
        dispatch({type: 'setOfferScreen', value: "optimizegraph"})
    }

    const onValueChange = (e) => {
        const {name, value} = e.target;
        
        // on ruleset change
        if(name === 'optRuleset'){
            dispatch({type: 'updateOptimizeObject', values: {
                optloading: true,
                optRuleset: value,
                optMinMargin: 30,
                optMaxMargin: 45,
                optMinPrice: 15,
                optMaxPrice: 75,
                optMaxUnits: 2000,
            }});
        } else {
            dispatch({type: 'updateOptimizeObject', name: name, value: value});
        }
    }

    return (
        <React.Fragment>

            <Sprite className="rds-addoffer-optimization-panel" absall style={{width: 428, height: 800}}>

                
                {/* --- Top --- */}
                <SpriteSelect debug={0} style={{top: 124, left: 10, width: 370}} name="optValue" value={0} options={valueOptions} />
                <SpriteSelect debug={0} style={{top: 204, left: 10, width: 370}} name="optTarget" value={0} options={targetOptions} />
                <SpriteSelect debug={0} style={{top: 284, left: 10, width: 370}} name="optStrategy" value={opt.optStrategy} options={strategyOptions} placeholder="Choose your strategy" onChange={(p1, p2, e) => onValueChange(e)} />
                <SpriteSelect debug={0} style={{top: 364, left: 10, width: 370}} name="optRuleset" value={opt.optRuleset} options={rulesetOptions} placeholder="Choose your ruleset" onChange={(p1, p2, e) => onValueChange(e)} />

                {/* --- Bottom --- */}
                {opt.optloading && <MiniAnim debug={0} style={{top: 444, left: 10, width: 100}} />}
                <SpriteInput debug={0} style={{top: 444, left: 10, width: 340}} name="optMinMargin" value={opt.optMinMargin} placeholder="Number" onChange={onValueChange} />

                {opt.optloading && <MiniAnim debug={0} style={{top: 524, left: 10, width: 100}} />}
                <SpriteInput debug={0} style={{top: 524, left: 10, width: 340}} name="optMaxMargin" value={opt.optMaxMargin} placeholder="Number" onChange={onValueChange} />

                {opt.optloading && <MiniAnim debug={0} style={{top: 604, left: 10, width: 100}} />}
                <SpriteField debug={0} style={{top: 604, left: 10, width: 340}} name="optMinPrice" value={opt.optMinPrice} placeholder="Number" onChange={onValueChange} />

                {opt.optloading && <MiniAnim debug={0} style={{top: 684, left: 10, width: 100}} />}
                <SpriteField debug={0} style={{top: 684, left: 10, width: 340}} name="optMaxPrice" value={opt.optMaxPrice} placeholder="Number" onChange={onValueChange} />

                {opt.optloading && <MiniAnim debug={0} style={{top: 764, left: 10, width: 100}} />}
                <SpriteField debug={0} style={{top: 764, left: 10, width: 340}} name="optMaxUnits" value={opt.optMaxUnits} placeholder="Number" onChange={onValueChange} />



            </Sprite>

            <PanelBottom>
                <Group type="back-optimize" onBack={onBack} onOptimize={onOptimize}  disabled={!completed} />
            </PanelBottom>
        </React.Fragment>
    );
}



// details

function Details({onClose, dispatch, model, promoModel}){
    const {optimizeObject: opt} = useGlobals();

    const [savable, setSavable] = React.useState(false);
    const [showAnim, setShowAnim] = React.useState(false);
    const [showCheck, setShowCheck] = React.useState(false);

    React.useEffect(() => {
        console.log("============: offerOptimized: ", opt.offerOptimized);
        if(opt.offerOptimized){
            setShowAnim(() => true);
            setTimeout(() => {
                setShowAnim(() => false);
                setShowCheck(() => true);
            }, 2000);
            setSavable(true);
        }
    }, [opt.offerOptimized]);

    const onChangeName = (e) => {
        const value = utils.toFilledString(e.target.value, model.Name);
        dispatch({type: "updateOfferModel", values: {Name: value}});
    }

    const onClickOptimize = () => {
        // --- WIP: do something
        dispatch({type: 'setOfferScreen', value: "optimization"})

    }

    const onClickContinue = () => {
        // --- WIP: update model
        // dispatch({type: 'setOfferScreen', value: "optimization"})
    }    

    const onClickSave = () => {
        // --- WIP: update offer row !!!
        dispatch({type: "swappingPromoOffer", id: model.ID, newID: 101});
        dispatch({type: "showOptimizedOffer", value: opt.offerOptimized, }); // ALDI 9/9/23
        // console.log("==================== ON SAVE: model: ", model, " promoModel: ", promoModel);
        onClose();
    }

    const targetOptions = [
        {value: 1, label: "Product from sublist 1"},
        {value: 2, label: "Product from sublist 2"},
    ];
    const targetOptions2 = [
        {value: 1, label: "Product"},
    ];


    return (
        <React.Fragment>    

            <Sprite className="rds-addoffer-details-panel" absall style={{width: 428, height: 770}}>

                <SpriteField debug={0} style={{top: 125, left: 12, width: 300}} value={model.Name} onChange={onChangeName} placeholder="Name this offer" />

                {/* --- Condition --- */}
                <SpriteSelect debug={0} style={{top: 242, left: 30, width: 130}} name="condValue" value={opt.condValue} options={[1,2,3,4,5]} menuWidth={300} />
                <SpriteSelect debug={0} style={{top: 314, left: 30, width: 350}} name="condTarget" value={opt.condTarget} options={targetOptions} />
                <SpriteField debug={0} style={{top: 386, left: 30, width: 130}} name="condMin" value={opt.condMin}  />
                <SpriteField debug={0} style={{top: 386, left: 240, width: 130}} name="condMax"  value={opt.condMax} />

                
                {/* --- Offer --- */}

                {showAnim && <MiniAnim debug={0} style={{top: 502, left: 70, width: 20, padding: 0}} />}
                {showCheck && <Sprite debug={0} style={{top: 502, left: 70, width: 16, backgroundColor: 'white'}}><Check style={{width: 16, height: 16}} /></Sprite>}
                <SpriteButton debug={0} style={{top: 500, left: 70, width: 90}} onClick={onClickOptimize} />


                <SpriteField debug={0} style={{top: 562, left: 30, width: 130}} name="offerValue" value={opt.offerValue} />
                <SpriteField debug={0} style={{top: 562, left: 335, width: 70}} name="offerPrice" value={opt.offerPrice} className="is-disabled"  />
                <SpriteSelect debug={0} style={{top: 634, left: 30, width: 350}} name="offerTarget" value={opt.offerTarget} options={targetOptions2} />
                <SpriteField debug={0} style={{top: 706, left: 30, width: 130}} name="offerMin" value={opt.offerMin}  />
                <SpriteField debug={0} style={{top: 706, left: 240, width: 130}} name="offerMax" value={opt.offerMax} />


            </Sprite>

            <PanelBottom>
                {!savable && <Group type="close-continue" onClose={onClose} onContinue={onClickContinue}  disabled={true} />}
                {savable && <Group type="cancel-save" onCancel={onClose} onSave={onClickSave} />}
            </PanelBottom>
        </React.Fragment>
    );
}


// custom screen

function Library({onClose, dispatch, globals, promoModel}){ // ------------------------- HERE
    const [checked, setChecked] = React.useState([]);
    const rows = globals.offers_rows;




    const onChangeCheckbox = (e) => {
        setChecked(e.target.checked);
    }

    const onChecked = (arr) => {
        const dupid = arr.find(id => id == promoModel.Offer);
        if(dupid) arr.splice(rows.indexOf(dupid), 1);
        setChecked(arr);
    }

    const onContinue = () => {
        const offerID = checked.length ? checked[0] : false;
        if(!offerID) return;
        
        console.log("OFFER ID: ", offerID);
        dispatch({type: "addingPromoOffer", id: offerID});
        onClose();
    }

    return (
        <React.Fragment>

            <Sprite className="rds-addoffer-library-panel" absall style={{width: 428, height: 80}}>
            </Sprite>

            <SimpleList type="library" rows={rows} headers={['Name']} checked={checked} onChecked={onChecked} style={{paddingTop: 100, }} />

            <PanelBottom>
                <Group type="close-continue" onContinue={onContinue} onClose={onClose} disabled={!checked.length} />
            </PanelBottom>
        </React.Fragment>
    );
}


function Suggest({onClose, dispatch, globals, model}){ // ------------------------- HERE
    const disabled = true;
    const [checked, setChecked] = React.useState(false);

    const onChange = (e) => {
        setChecked(e.target.checked);
    }

    const onContinue = () => {
        dispatch({type: "addingPromoOffer", id: 102});
        onClose();
    }

    return (
        <React.Fragment>

            <Sprite className="rds-addoffer-suggest-panel" absall style={{width: 428, height: 620}}>

                <SpriteCheckbox debug={0} style={{top: 306, left: 1, width: 400, padding: '20px 16px'}} checked={checked} onChange={onChange} />
                <SpriteCheckbox debug={0} style={{top: 370, left: 1, width: 400, padding: '20px 16px'}} checked={false} onChange={()=>{}} />
                <SpriteCheckbox debug={0} style={{top: 434, left: 1, width: 400, padding: '20px 16px'}} checked={false} onChange={()=>{}} />
                <SpriteCheckbox debug={0} style={{top: 500, left: 1, width: 400, padding: '20px 16px'}} checked={false} onChange={()=>{}} />

            </Sprite>

            <PanelBottom>
                <Group type="close-continue" onContinue={onContinue} onClose={onClose} disabled={!checked} />
            </PanelBottom>
        </React.Fragment>
    );
}



// custom screen

function Custom2({onClose, dispatch, model}){

    const onClickAdd = () => {
        dispatch({type: 'setOfferScreen', value: "custom"})
    }

    const onChangeName = (e) => {
        const value = utils.toFilledString(e.target.value, model.Name);
        dispatch({type: "updateOfferModel", values: {Name: value}});
    }

    return (
        <React.Fragment>

            <Sprite className="rds-addoffer3-panel" absall style={{width: 418, height: 500}}>
                <SpriteField debug={0} style={{top: 125, left: 7, width: 300}} value={model.Name || ''} onChange={onChangeName} placeholder="Name this offer" />
                <SpriteButton debug={0} style={{top: 210, left: 0, width: 90, height: 20}} onClick={onClickAdd} />
            </Sprite>

            <PanelBottom>
                <Group type="close-continue" onClose={onClose}  disabled={true} />
            </PanelBottom>
        </React.Fragment>
    );
}


// custom screen

function Custom({onClose, dispatch, globals, model}){

    const onClickAdd = () => {
        dispatch({type: 'setOfferScreen', value: "custom2"})
    }

    const onChangeName = (e) => {
        const value = utils.toFilledString(e.target.value, model.Name);
        dispatch({type: "updateOfferModel", values: {Name: value}});
    }

    return (
        <React.Fragment>

            <Sprite className="rds-addoffer2-panel" absall style={{width: 418, height: 500}}>
                <SpriteField debug={0} style={{top: 125, left: 7, width: 300}} placeholder="Name this offer" value={model.Name || ''} onChange={onChangeName} />
                <SpriteButton debug={0} style={{top: 210, left: 0, width: 90, height: 20}} onClick={onClickAdd} />
            </Sprite>

            <PanelBottom>
                <Group type="close-continue" onClose={onClose}  disabled={true} />
            </PanelBottom>
        </React.Fragment>
    );
}



// landing screen

function OfferMenu({onClose, dispatch}){

    const onClickLibrary = () => {
        dispatch({type: 'setOfferScreen', value: "library"})
    }

    const onClickSuggest = () => {
        console.log("##################### onClickSuggest");
        dispatch({type: 'setOfferScreen', value: "suggest"})
    }

    const onClickCustom = () => {
        dispatch({type: 'setOfferScreen', value: "custom3"}); // --- ALDI
    }


    return (
        <React.Fragment>

            <Sprite className="rds-addoffer-panel" absall style={{width: 418, height: 500}}>
                <SpriteButton debug={0} style={{top: 70, left: 0, width: 420, height: 60}} onClick={onClickLibrary} />
                <SpriteButton debug={0} style={{top: 200, left: 0, width: 420, height: 60}} onClick={onClickCustom} />
                <SpriteButton debug={0} style={{top: 270, left: 0, width: 420, height: 60}} onClick={onClickSuggest} />
            </Sprite>

            <PanelBottom>
                <Group type="close-continue" onClose={onClose}  disabled={true} />
            </PanelBottom>
        </React.Fragment>
    );
}



// --------- MAIN ----------- //

function Content(props){

    switch(props.screen){
        default:
        case "mainmenu": return <OfferMenu {...props} />;
        case "custom": return <Custom {...props} />;
        case "custom2": return <Custom2 {...props} />;
        case "custom3": return <CustomOffer {...props} />; // --- ALDI
        case "library": return <Library {...props} />;
        case "suggest": return <Suggest {...props} />;
        case "details": return <Details {...props} />; // return <Details {...props} />;
        case "optimization": return <Optimization {...props} />;
        case "optimizegraph": return <OptimizeGraph {...props} />;
    }
}


export default function AddOfferPanel({id}){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const {offerScreen}  = useGlobals();

    const onClose = () => {
        dispatch({type: 'hideOfferPanel'});
    }

    // <Panel name="addoffer" style={{height: 1130}}>
    return (
        <Panel name="addoffer">
            
            <Content screen={offerScreen} onClose={onClose} dispatch={dispatch} globals={globals} model={globals.offerModel || {}} promoModel={globals.model || {}} />

        </Panel>
    );
}
