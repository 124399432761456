import React from "react";
import {Layout} from "../../foundation/Layout";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar, Paginationbar, Titlebar3} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Template, SpriteButton, Sprite} from '../../foundation';
import {Timeline} from '../../components/Timeline';
// import {Link2} from '../../foundation/Text';
import {Icon} from '../../foundation/Icon';
import {Button2} from '../../foundation/Button';
// NEW
import Popover from '@mui/material/Popover';
import classNames from "classnames";




export default function Screen(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var rows = globals.vehicles_rows;


    function onClickList(){dispatch({type: "setScreen", value:"promo-list"});}
    function onClickCards(){dispatch({type: "setScreen", value:"promo-cards"});}
    function onClickTimeline(){dispatch({type: "setScreen", value:"promo-timeline"});}
    function onClickGraphs(){dispatch({type: "setScreen", value:"promo-graphs"});}

    
    // START hover...
    const [isover, setOver] = React.useState();
    const onMouseEnter = () => setOver(true);
    const onMouseLeave = () => setOver(false);
    const hoverCss = classNames("rds-promos-graphsview-hover", isover && "is-show");
    // END hover

    // START focus...
    const [isover2, setOver2] = React.useState();
    const onMouseEnter2 = () => setOver2(true);
    const onMouseLeave2 = () => setOver2(false);
    const hoverCss2 = classNames("rds-promos-graphsview-focus", isover2 && "is-show");
    // END focus



    return (
        <Layout>
            <Searchbar />

            <Titlebar3 type="promo-graphs">
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 86}} onClick={onClickTimeline} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 60}} onClick={onClickList} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 30}} onClick={onClickCards} />
            </Titlebar3>


            <Sprite absall className="rds-promos-graphsview" style={{top: 0, left: 0, width: 1344, height: 1660, }}>

                <Sprite debug={0} style={{top: 94, left: 520, width: 526, height: 306, }} className={hoverCss} />
                <SpriteButton debug={0} style={{top: 360, left: 640, width: 120, height: 40, zIndex: 100}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />

                <Sprite debug={0} style={{top: 64, left: 0, width: 1344, height: 415, }} className={hoverCss2} />
                <SpriteButton debug={0} style={{top: 430, left: 160, width: 140, height: 50, zIndex: 100}} onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2} />

            </Sprite>



        </Layout>
    );
}


/*
    const show = (type !== 'units');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event, bar) => {
      setAnchorEl(event.currentTarget);
      setPopData(bar);
      // console.log("+++++++++++ POPOVER: ", bar);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

                <div className="lds-graphview-hotspot" onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave} onClick={onClick2} />

                    const cells = bars.map((bar, key) => <GraphCell key={key} bar={bar} onClick={onClick} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} />);

<Popover2 anchorEl={anchorEl} open={open} data={popData} handlePopoverClose={handlePopoverClose} />
*/