import React from "react";
import { useGlobals, useGlobalsDispatch } from './context';
import classNames from 'classnames';
import AppBar from '@mui/material/AppBar';
import utils from './utils';
// import {Topnav} from '../components/Topnav';
import {GlobalNav, MainMenu, ProfileNav} from '../components/GlobalNav';
import {Sprite, SpriteButton} from '../foundation/Sprite';
import {Icon} from '../foundation/Icon';
// import MainMenu from '../components/MainMenu';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// screens
import Login from '../screens/General/Login-v2';
import Home from '../screens/General/Home';
import Vector from '../screens/-Playground/Vector';
import JsonData from '../screens/-Playground/JsonData';
import Playground from '../screens/-Playground/Playground1';
import Dashboard from '../screens/General/Dashboard';
import MessageList from '../screens/Message/MessageList';
import ProductList from '../screens/Product/ProductList';
// import Reports from '../screens/General/Reports';
import Settings from '../screens/General/Settings';
import TaskList from '../screens/Task/TaskList';
import TaskView from '../screens/Task/TaskView';
import PromoList from '../screens/Promo/PromoList';
import PromoTimeline from '../screens/Promo/PromoTimeline';
import PromoCards from '../screens/Promo/PromoCards';
import PromoGraphs from '../screens/Promo/PromoGraphs';
import PromoView from '../screens/Promo/PromoView';
import OfferList from "../screens/Offer/OfferList";
import SystemView from "../screens/System/SystemView";
import ReportList from "../screens/Report/ReportList";
import ReportDetails from "../screens/Report/ReportDetails";
import VehicleList from "../screens/Vehicle/VehicleList";
import VehicleTimeline from "../screens/Vehicle/VehicleTimeline";
import VehicleView from "../screens/Vehicle/VehicleView";
import FundingList from "../screens/Funding/FundingList";
import FundingCards from "../screens/Funding/FundingCards";
import FundingTimeline from "../screens/Funding/FundingTimeline";
import FundingView from "../screens/Funding/FundingView";

// panels
import PromoDetailsPanel from '../Panels/PromoDetailsPanel';
import OfferDetailsPanel from '../Panels/OfferDetailsPanel';
import MiscPanel from '../Panels/MiscPanel';
import FiltersPanel from '../Panels/FiltersPanel';
import EditTablePanel from '../Panels/EditTablePanel';
import ImportPanel from '../Panels/ImportPanel';
import AddOfferPanel from '../Panels/AddOfferPanel';
import ReportsPanel from '../Panels/ReportsPanel';
// BASE panels
import EditPriceTablePanel from '../Panels/EditPriceTablePanel';
import LockEditPanel from '../Panels/LockEditPanel';
import LockAllPanel from '../Panels/LockAllPanel';
import PriceFiltersPanel from '../Panels/PriceFiltersPanel';
import TaskmgtFiltersPanel from "../Panels/TaskmgtFiltersPanel";
import SubmitReviewPanel from '../Panels/SubmitReviewPanel';

// https://www.kirkw.com/revionics/test.php
// --- content --- //


// --- BASE
import BaseDash from '../screens/Base/BaseDash';
import PriceList from '../screens/Price/PriceList';
import PriceDetails from '../screens/Price/PriceDetails';
import TaskmgtList from '../screens/Taskmgt/TaskmgtList';
import TaskmgtDetails from '../screens/Taskmgt/TaskmgtDetails';


// analytics
import AnalyticsDash from '../screens/Analytics/AnalyticsDash';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function ScreenContent({name}) {
    switch( name ){
        case 'playground': return <Playground />;
        case 'message-list': return <MessageList />
        // case 'promos': return <TaskList />
        case 'product-list': return <ProductList />
        // case 'reports': return <Reports />

        case 'jsondata': return <JsonData />
        case 'vector': return <Vector />

        // general
        case 'login': return <Login />;
        case 'dashboard': return <Dashboard />;

        // tasks --- OLD
        case 'task-list': return <TaskList />
        case 'task':
        case 'task-product': 
        case 'task-location':
        case 'task-vehicles':
        case 'task-funding':
        case 'task-offer': return <TaskView screen={name} />

        // promos
        case 'promo':
        case 'promos':
        case "promo-timeline": return <PromoTimeline />; // ALDI
        case 'promo-list': return <PromoList />
        case "promo-cards": return <PromoCards />
        case "promo-graphs": return <PromoGraphs />
        case 'promo-define': // --- NEW
        case 'promo-customer': 
        case 'promo-product': 
        case 'promo-location':
        case 'promo-vehicles':
        case 'promo-funding':
        case 'promo-offer': return <PromoView screen={name} />

        // settings
        case 'settings':
        case 'settings-datetime':   
        case 'settings-access': 
        case 'settings-privacy': 
        case 'settings-email': return <Settings screen={name} />

        // offers
        case "offers":
        case 'offer-list': return <OfferList />

        // system
        case "system":
        case "system-members": return <SystemView screen={name} />

        // system
        case "reports":
        case "report-list": return <ReportList />
        case "report-details": return <ReportDetails />

        // system
        case "vehicles":
        case "vehicle-list": return <VehicleList />
        case "vehicle-timeline": return <VehicleTimeline />
        case "vehicle-define":
        case "vehicle-locations":
        case "vehicle-assignpromos": return <VehicleView screen={name} />

        // funding
        case "funding":
        case "funding-list": return <FundingList />
        case "funding-cards": return <FundingCards />
        case "funding-timeline": return <FundingTimeline />
        case 'funding-general':
        case 'funding-funds': 
        case 'funding-products': 
        case 'funding-locations': 
        case 'funding-conditions': 
        case 'funding-accounting': return <FundingView screen={name} />


        // --- BASE...
        case 'base-home': return <BaseDash />;
        // case 'home': // --- HOME
        case 'price-list': return <PriceList />;
        case 'price-details': return <PriceDetails />;
        case 'taskmgt-list': return <TaskmgtList />;
        case 'taskmgt-details': return <TaskmgtDetails />;
        case 'taskmgt-details-list': return <TaskmgtDetails />;

        // analytics
        default: // if(name) return console.warn('ScreenContent() invalid screen name: ', name);
        case 'home': // --- HOME
        case 'analytics-home': return <AnalyticsDash />;

    }
}

function PanelContent({name, action}){
    action = utils.isObject(action) ? action : {};
    
    switch( name ){
        case 'addoffer':      return <AddOfferPanel {...action} />;
        case 'edit-table':      return <EditTablePanel {...action} />;
        case 'import':          return <ImportPanel {...action} />;
        case 'filters':         return <FiltersPanel {...action} />;
        case 'promo-details':   return <PromoDetailsPanel {...action} />;
        case 'offer-details':   return <OfferDetailsPanel {...action} />;
        case 'misc':            return <MiscPanel {...action} />;
        // base
        case 'edit-price-table': return <EditPriceTablePanel {...action} />;
        case 'lock-edit-panel': return <LockEditPanel {...action} />;
        case 'lock-all-panel':  return <LockAllPanel {...action} />;
        case 'price-filters':    return <PriceFiltersPanel {...action} />;
        case 'taskmgt-filters':      return <TaskmgtFiltersPanel {...action} />;
        case 'submit-review-panel':  return <SubmitReviewPanel {...action} />
        default: 
            return console.warn('PanelContent() invalid panel name: ', name);
    }
}


// --- main --- //

export default function Main() {
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const section = globals.section;
    const {loggedIn, showLoader, showToast} = globals;

    // handlers
    const onMenuOutside = () => dispatch({type: 'toggleMenu'});
    const onPanelOutside = () => dispatch({type: 'hidePanel'});
    const onPanelClick = onPanelOutside;
    const onLogoutOutside = () => dispatch({type: 'toggleLogout'}); // BASE
    const onClickLogoutCancel = () => dispatch({type: 'toggleLogout', }); // BASE
    const onClickLogoutOk = () => dispatch({type: "logout"}); // BASE

    // hooks
    
    React.useEffect(() => {
        if(!globals.initiated) dispatch({type: "initiate"});
    }, [globals.initiated]);


    React.useEffect(() => {
        // console.log("---- SCREEN: ", globals.screen);
        // if(!globals.screen || !globals.screen.length) {
        //     return dispatch({setScreen: "login"});
        // }
        window.history.pushState("object or string", globals.screen, "/#" + globals.screen);
        
    }, [globals.screen]);

    React.useEffect(() => {
        console.log('globals.screen: ' + globals.section);
    }, [globals.section]);

    // TEMP
    const onClick_showOfferPanel = () => { // dispatch({type: "showOfferPanel", object: item.Offer_Obj});
        const offer = utils.selectRow(globals, "offers", 5);
        console.log("-------------- onClick_showOfferPanel: ", offer);
        dispatch({type: "showOfferPanel", object: offer});
    }

    const onClickLogout = () => {
        dispatch({type: "logout"});
    }
    
    // toast
    const toast = showToast || {};
    const onCloseToast = () => dispatch({type: "showToast", value: false, });



    document.body.classList.toggle('is-noscroll', !loggedIn);

    // if(!globals.initiated) return;
    // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    return (
        <div id={'rds-screen-' + section}>
            {loggedIn && <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}><GlobalNav /></AppBar>}
            {loggedIn && <ProfileNav />}

            {false && <button onClick={() => dispatch({type: "showPanel", value: "addoffer"})}>Add offer</button>}
            {false && <button onClick={onClick_showOfferPanel}>showOfferPanel</button>}

            {false && <textarea style={{height: 400, width: 800}}>{JSON.stringify(globals.loctree, undefined, 4)}</textarea>}
            {false && <textarea style={{height: 400, width: 800}}>{JSON.stringify(globals.prodtree, undefined, 4)}</textarea>}

            {false && <Drawer id="rds-reports-panel" open={true} open222={globals.showReportsPanel} hideBackdrop={true} ><ReportsPanel /></Drawer>}

            <div id="rds-reports-panel" className={classNames(globals.showReportsPanel && "is-open")}><ReportsPanel /></div>

            <Backdrop open={showLoader} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
            <Drawer open={globals.showMenu} ModalProps={{ onBackdropClick: onMenuOutside }} >
                <MainMenu />
                <SpriteButton href="/" id="rds-mainmenu-logout" debug={0} style={{left: 30, top: 'auto', bottom: 40, width: 140, height: 50, position: 'absolute' }} onClick={onClickLogout} />
            </Drawer>
            
            <Drawer id="rds-logout-outer" anchor="right" open={globals.showLogout} ModalProps={{ onBackdropClick: onLogoutOutside }} >
                <Sprite absall id="rds-logout" debug={0} style={{top: 380, width: 416, height: 146, margin: 48, }} >
                    <SpriteButton debug={0} onClick={onClickLogoutCancel} style={{top: 100, left: 250, width: 80, }} />
                    <SpriteButton debug={0} onClick={onClickLogoutOk} style={{top: 100, left: 340, width: 80, }} />
                </Sprite>
            </Drawer> 

            <Drawer style={{padding: 32}} open={globals.showPanel} ModalProps={{ onBackdropClick: onPanelOutside }} anchor="right">
                <SpriteButton debug={0} onClick={onPanelClick} style={{position: 'fixed', top: 70, left: 'auto', right: 24, width: 24, height: 24, zIndex: 1}}>
                    <Icon type="x" />
                </SpriteButton>
                <div style={{padding: "96px 48px 80px"}}>
                    <PanelContent name={globals.panelName} action={globals.panelAction} />
                </div>
            </Drawer>
            <ScreenContent name={globals.screen} />

            <Snackbar 
                open={toast.open} 
                autoHideDuration={toast.duration || 6000} 
                onClose={onCloseToast}
                anchorOrigin={{ vertical: 'bottom', horizontal: toast.align || 'center', }} 
            >
                <Alert 
                    className={toast.onClick && 'is-likelink'}
                    onClose={toast.x ? onCloseToast : undefined} 
                    onClick={toast.onClick} 
                    severity={toast.severity || "success"} 
                >{toast.message}</Alert>
            </Snackbar>
        </div>
    );
}

