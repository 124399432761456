import React from "react";
import {Layout} from "../../foundation/Layout";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar, Paginationbar} from '../../components/Toolbars';
import {DataList, DataRow, DataCell} from '../../foundation/DataList';
// import {Link2} from '../../foundation/Text';
import {Icon} from '../../foundation/Icon';
import {Button2} from '../../foundation/Button';


// import * as React from 'react';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function StatusMenu({anchor, value, open, onClose}) {
    console.log('value: ', value);
    return (
        <Menu anchorEl={anchor} open={open} onClose={onClose}>
            <MenuItem onClick={onClose}>Profile</MenuItem>
            <MenuItem onClick={onClose}>My account</MenuItem>
            <MenuItem onClick={onClose}>Logout</MenuItem>
        </Menu>
    );
}



const dots = {'Active': '#0F62FE',  'Approved': '#32C4A6', 'Pending': '#FF905F'};
    function Dot({status}){
    const color = dots[status] || 'gray';
    return <div style={{width: 8, height: 8, borderRadius: 8, backgroundColor: color, display: 'inline-block', marginRight: 8}}></div>;
    }





export default function TasksList(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var records = globals.tasks_rows;
    var promos_rows = globals.periods_rows;


    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const handleClick = (event) => {setAnchor(event.currentTarget)};
    const handleClose = () => {setAnchor(null)};

    React.useEffect(() => {
        
        console.log('++++++++++++++++ PROMOS: ', promos_rows);

    }, [promos_rows]);


    const headers = [
        {name: 'name'},
        {name: 'status'},
        {name: 'startdate', label: 'Start date'},
        {name: 'enddate', label: 'End date'},
        {name: 'funding'},
        {name: 'upc', label: 'UPC Numbers'},
        {name: 'supplier'},
        {name: 'vehicle'},
    ];
    
    const onClickHeader = (item) => {
        console.log('===== TETING');
    }



    const onClickName = ({id}) => {
        dispatch({type: 'showPanel', value: 'promo-details', id: id});
    }

    // anchor, value, open, onClose
    return (
        <Layout>
            <Searchbar type="create-import" />
            <Titlebar title="Promotion management" />
  

            <StatusMenu open={open} onClose={handleClose} anchor={anchor} />

            <DataList headers={headers} onClick={onClickHeader}>
              {records.map((item, i) => ( 
                    <DataRow index={i} key={i}>
                        <DataCell name="name" className="is-wrap" onClick={() => {onClickName(item)}}>{item.name}</DataCell>
                        <DataCell name="status" onClick={handleClick}><Dot status={item.status} />{item.status}<Icon type="chevron-down" /></DataCell>
                        <DataCell name="startdate">{item.startdate}</DataCell>
                        <DataCell name="enddate">{item.enddate}</DataCell>
                        <DataCell name="funding">{item.funding}</DataCell>
                        <DataCell name="upc">{item.upc}</DataCell>
                        <DataCell name="supplier">{item.supplier}</DataCell>
                        <DataCell name="vehicle">{item.vehicle}</DataCell>
                    </DataRow>
              ))}
            </DataList>


            <Paginationbar />
            
        </Layout>
    );
}


