import React from "react";
import {Layout} from "../../foundation/Layout";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar3, Paginationbar} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Sprite, SpriteButton} from '../../foundation';



export default function Screen(){
    const dispatch = useGlobalsDispatch();
    


    function onClickList(){dispatch({type: "setScreen", value:"promo-list"});}
    function onClickCards(){dispatch({type: "setScreen", value:"promo-cards"});}
    function onClickTimeline(){dispatch({type: "setScreen", value:"promo-timeline"});}
    function onClickGraphs(){dispatch({type: "setScreen", value:"promo-graphs"});}


    return (
        <Layout>
            <Searchbar />

            <Titlebar3 type="promo-cards">
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 86}} onClick={onClickTimeline} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20, right: 60}} onClick={onClickList} />
                <SpriteButton debug={0} className="is-abs-right" style={{width: 20}} onClick={onClickGraphs} />
            </Titlebar3>



            <Sprite className="rds-promo-cards" style={{width: 1349, height: 550}}>

            </Sprite>

        </Layout>
    );
}
