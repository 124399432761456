import React from "react";
import {Layout} from "../../foundation/Layout";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar3, Paginationbar} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Sprite, SpriteButton} from '../../foundation';



export default function Screen(){
    const dispatch = useGlobalsDispatch();
    // const onClickEditTable = () => dispatch({type: "showPanel", value: "edit-table"});
    const onClickList = () => dispatch({type: "setScreen", value: "funding-list"});
    const onClickTimeline = () => dispatch({type: "setScreen", value: "funding-timeline"});


    return (
        <Layout>
            <Searchbar />

            <Titlebar3 type="funding-cards">
                <SpriteButton className="is-abs-right is-debu" style={{width: 20, right: 60}} onClick={onClickList} />
                <SpriteButton className="is-abs-right is-debu" style={{width: 20}} onClick={onClickTimeline} />
            </Titlebar3>



            <Sprite className="rds-funding-cards" style={{width: 1349, height: 550}}>

            </Sprite>

            <Paginationbar />
        </Layout>
    );
}
