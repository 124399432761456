import React from "react";
import {Template, Button, Sprite, SpriteButton, SpriteText, PanelBottom} from "../foundation";
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';



export default function OfferDetailsPanel({id}){
    const globals = useGlobals();
    const dispatch = useGlobalsDispatch();
    const [row, setRow] = React.useState({});

    // load row
    React.useEffect(() => {
        const row2 = utils.selectRow(globals, "offers", id);
        if(!row2) return; // missing row
        utils.loadDetails(globals, "offers", row2);
        setRow(row2);
    }, [id]);

    const onClickCancel = () => {dispatch({type: "hidePanel"})};
    const onClickConfirm = () => {dispatch({type: "hidePanel"})};
    const onClickFull = () => {
        dispatch({type: "hidePanel"});
        dispatch({type: "showDetails", table: "promos", id: row.ID});
    };


    // <SpriteButton debug={debug} style={{top: -10, left: 330, width: 90}} onClick={onClickFull} />
    const debug = 0;
    return row && (
        <React.Fragment>
        <Sprite className="rds-offerdetails-panel is-abs-all" style={{width: 422, height: 1200}}>

            
            <Sprite debug={debug} absall={1} style={{top: 0,width: 420, height: 10}}>
                {true && <SpriteText label={row.Name} className="is-headline6" style={{top: -6, left: 0, width: '100%', height: 'auto'}} />}

            </Sprite>

            <Sprite debug={debug} absall={1} style={{top: 120, width: 420, height: 10}}>

            </Sprite>

            <Sprite debug={debug} absall={1} style={{top: 632, width: 420, height: 10}}>

            </Sprite>

            <Sprite debug={debug} absall={1} style={{top: 942, width: 420, height: 10}}>

            </Sprite>





        </Sprite>
            <PanelBottom>
                <Template type="buttons-save-remove" className="is-panel-bottom" onClick0={onClickCancel} onClick1={onClickCancel} onClick2={onClickConfirm} />
              
            </PanelBottom>
        </React.Fragment>
    );
}
