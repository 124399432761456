// DONE: 12/20/2022

const rows = [
    {
      "ID": 1,
      "Vehicle": "Sep23 Cooler Cling - Cooler Deal Feature",
      "Vehicle_Type_ID": 3.1,
      "Vehicle_Type": "Cooler Cling",
      "Vehicle_Tactic": "Cooler Deal Feature",
      "External_ID": 2000002,
      "Internal_ID": 30001,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/20233",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 2,
      "Vehicle": "Sep23 Cooler Cling - Cooler Deal Spotlight",
      "Vehicle_Type_ID": 3.2,
      "Vehicle_Type": "Cooler Cling",
      "Vehicle_Tactic": "Cooler Deal Spotlight",
      "External_ID": 2000003,
      "Internal_ID": 30002,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 3,
      "Vehicle": "Sep23 Counter Mat - Counter Deal Feature",
      "Vehicle_Type_ID": 2.1,
      "Vehicle_Type": "Counter Mat",
      "Vehicle_Tactic": "Counter Deal Feature",
      "External_ID": 2000004,
      "Internal_ID": 30003,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 4,
      "Vehicle": "Sep23 Counter Mat - Counter Deal Spotlight",
      "Vehicle_Type_ID": 2.2,
      "Vehicle_Type": "Counter Mat",
      "Vehicle_Tactic": "Counter Deal Spotlight",
      "External_ID": 2000005,
      "Internal_ID": 30004,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 5,
      "Vehicle": "Sep23 Diesel Poster - Diesel Deal Feature",
      "Vehicle_Type_ID": 5.1,
      "Vehicle_Type": "Diesel Poster",
      "Vehicle_Tactic": "Diesel Deal Feature",
      "External_ID": 2000006,
      "Internal_ID": 30005,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 6,
      "Vehicle": "Sep23 Diesel Poster - Diesel Deal Spotlight",
      "Vehicle_Type_ID": 5.2,
      "Vehicle_Type": "Diesel Poster",
      "Vehicle_Tactic": "Diesel Deal Spotlight",
      "External_ID": 2000007,
      "Internal_ID": 30006,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 7,
      "Vehicle": "Sep23 Door Sign - Door Deal Feature",
      "Vehicle_Type_ID": 1.1,
      "Vehicle_Type": "Door Sign",
      "Vehicle_Tactic": "Door Deal Feature",
      "External_ID": 2000008,
      "Internal_ID": 30007,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 8,
      "Vehicle": "Sep23 Door Sign - Door Deal Spotlight",
      "Vehicle_Type_ID": 1.2,
      "Vehicle_Type": "Door Sign",
      "Vehicle_Tactic": "Door Deal Spotlight",
      "External_ID": 2000009,
      "Internal_ID": 30008,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 9,
      "Vehicle": "Sep23 Gas Pump Topper - Topper Deal Feature",
      "Vehicle_Type_ID": 4.1,
      "Vehicle_Type": "Gas Pump Topper",
      "Vehicle_Tactic": "Topper Deal Feature",
      "External_ID": 2000010,
      "Internal_ID": 30009,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 10,
      "Vehicle": "Sep23 Gas Pump Topper - Topper Deal Spotlight",
      "Vehicle_Type_ID": 4.2,
      "Vehicle_Type": "Gas Pump Topper",
      "Vehicle_Tactic": "Topper Deal Spotlight",
      "External_ID": 2000011,
      "Internal_ID": 30010,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 11,
      "Vehicle": "Sep23 Loyalty App - App Deal Banner",
      "Vehicle_Type_ID": 9.1,
      "Vehicle_Type": "Loyalty App",
      "Vehicle_Tactic": "App Deal Banner",
      "External_ID": 2000012,
      "Internal_ID": 30011,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 12,
      "Vehicle": "Sep23 Loyalty App - App Deal Feature",
      "Vehicle_Type_ID": 9.2,
      "Vehicle_Type": "Loyalty App",
      "Vehicle_Tactic": "App Deal Feature",
      "External_ID": 2000013,
      "Internal_ID": 30012,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 13,
      "Vehicle": "Sep23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000014,
      "Internal_ID": 30013,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 14,
      "Vehicle": "Sep23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000015,
      "Internal_ID": 30014,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 15,
      "Vehicle": "Sep23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000016,
      "Internal_ID": 30015,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 16,
      "Vehicle": "Sep23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000017,
      "Internal_ID": 30016,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 17,
      "Vehicle": "Sep23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000018,
      "Internal_ID": 30017,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 1,
      "Description": ""
    },
    {
      "ID": 18,
      "Vehicle": "Sep23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000019,
      "Internal_ID": 30018,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 2,
      "Description": ""
    },
    {
      "ID": 19,
      "Vehicle": "Sep23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000020,
      "Internal_ID": 30019,
      "Start_Date": "1/29/2023",
      "End_Date": "2/11/2023",
      "Period": "Period 3",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 3,
      "Description": ""
    },
    {
      "ID": 20,
      "Vehicle": "Feb23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000021,
      "Internal_ID": 30020,
      "Start_Date": "2/12/2023",
      "End_Date": "2/25/2023",
      "Period": "Period 4",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 4,
      "Description": ""
    },
    {
      "ID": 21,
      "Vehicle": "Feb23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000022,
      "Internal_ID": 30021,
      "Start_Date": "2/12/2023",
      "End_Date": "2/25/2023",
      "Period": "Period 4",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 5,
      "Description": ""
    },
    {
      "ID": 22,
      "Vehicle": "Sep23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000023,
      "Internal_ID": 30022,
      "Start_Date": "1/29/2023",
      "End_Date": "2/11/2023",
      "Period": "Period 3",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 3,
      "Description": ""
    },
    {
      "ID": 23,
      "Vehicle": "Feb23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000024,
      "Internal_ID": 30023,
      "Start_Date": "2/12/2023",
      "End_Date": "2/25/2023",
      "Period": "Period 4",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 5,
      "Description": ""
    },
    {
      "ID": 24,
      "Vehicle": "Feb23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000025,
      "Internal_ID": 30024,
      "Start_Date": "2/12/2023",
      "End_Date": "2/25/2023",
      "Period": "Period 4",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 4,
      "Description": ""
    },
    {
      "ID": 25,
      "Vehicle": "Mar23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000026,
      "Internal_ID": 30025,
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period": "Period 6",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 6,
      "Description": ""
    },
    {
      "ID": 26,
      "Vehicle": "Mar23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000027,
      "Internal_ID": 30026,
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period": "Period 6",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 7,
      "Description": ""
    },
    {
      "ID": 27,
      "Vehicle": "Mar23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000028,
      "Internal_ID": 30027,
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period": "Period 6",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 6,
      "Description": ""
    },
    {
      "ID": 28,
      "Vehicle": "Mar23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000029,
      "Internal_ID": 30028,
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period": "Period 6",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 8,
      "Description": ""
    },
    {
      "ID": 29,
      "Vehicle": "Mar23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000030,
      "Internal_ID": 30029,
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period": "Period 6",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 7,
      "Description": ""
    },
    {
      "ID": 30,
      "Vehicle": "Mar23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000031,
      "Internal_ID": 30030,
      "Start_Date": "3/12/2023",
      "End_Date": "3/25/2023",
      "Period": "Period 6",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 8,
      "Description": ""
    },
    {
      "ID": 31,
      "Vehicle": "Apr23 Cooler Cling - Cooler Deal Feature",
      "Vehicle_Type_ID": 3.1,
      "Vehicle_Type": "Cooler Cling",
      "Vehicle_Tactic": "Cooler Deal Feature",
      "External_ID": 2000032,
      "Internal_ID": 30031,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 9,
      "Description": ""
    },
    {
      "ID": 32,
      "Vehicle": "Apr23 Cooler Cling - Cooler Deal Spotlight",
      "Vehicle_Type_ID": 3.2,
      "Vehicle_Type": "Cooler Cling",
      "Vehicle_Tactic": "Cooler Deal Spotlight",
      "External_ID": 2000033,
      "Internal_ID": 30032,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 10,
      "Description": ""
    },
    {
      "ID": 33,
      "Vehicle": "Apr23 Counter Mat - Counter Deal Feature",
      "Vehicle_Type_ID": 2.1,
      "Vehicle_Type": "Counter Mat",
      "Vehicle_Tactic": "Counter Deal Feature",
      "External_ID": 2000034,
      "Internal_ID": 30033,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 9,
      "Description": ""
    },
    {
      "ID": 34,
      "Vehicle": "Apr23 Counter Mat - Counter Deal Spotlight",
      "Vehicle_Type_ID": 2.2,
      "Vehicle_Type": "Counter Mat",
      "Vehicle_Tactic": "Counter Deal Spotlight",
      "External_ID": 2000035,
      "Internal_ID": 30034,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 11,
      "Description": ""
    },
    {
      "ID": 35,
      "Vehicle": "Apr23 Diesel Poster - Diesel Deal Feature",
      "Vehicle_Type_ID": 5.1,
      "Vehicle_Type": "Diesel Poster",
      "Vehicle_Tactic": "Diesel Deal Feature",
      "External_ID": 2000036,
      "Internal_ID": 30035,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 11,
      "Description": ""
    },
    {
      "ID": 36,
      "Vehicle": "Apr23 Diesel Poster - Diesel Deal Spotlight",
      "Vehicle_Type_ID": 5.2,
      "Vehicle_Type": "Diesel Poster",
      "Vehicle_Tactic": "Diesel Deal Spotlight",
      "External_ID": 2000037,
      "Internal_ID": 30036,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 10,
      "Description": ""
    },
    {
      "ID": 37,
      "Vehicle": "Apr23 Door Sign - Door Deal Feature",
      "Vehicle_Type_ID": 1.1,
      "Vehicle_Type": "Door Sign",
      "Vehicle_Tactic": "Door Deal Feature",
      "External_ID": 2000038,
      "Internal_ID": 30037,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 9,
      "Description": ""
    },
    {
      "ID": 38,
      "Vehicle": "Apr23 Door Sign - Door Deal Spotlight",
      "Vehicle_Type_ID": 1.2,
      "Vehicle_Type": "Door Sign",
      "Vehicle_Tactic": "Door Deal Spotlight",
      "External_ID": 2000039,
      "Internal_ID": 30038,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 10,
      "Description": ""
    },
    {
      "ID": 39,
      "Vehicle": "Apr23 Gas Pump Topper - Topper Deal Feature",
      "Vehicle_Type_ID": 4.1,
      "Vehicle_Type": "Gas Pump Topper",
      "Vehicle_Tactic": "Topper Deal Feature",
      "External_ID": 2000040,
      "Internal_ID": 30039,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 10,
      "Description": ""
    },
    {
      "ID": 40,
      "Vehicle": "Apr23 Gas Pump Topper - Topper Deal Spotlight",
      "Vehicle_Type_ID": 4.2,
      "Vehicle_Type": "Gas Pump Topper",
      "Vehicle_Tactic": "Topper Deal Spotlight",
      "External_ID": 2000041,
      "Internal_ID": 30040,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 11,
      "Description": ""
    },
    {
      "ID": 41,
      "Vehicle": "Apr23 Loyalty App - App Deal Banner",
      "Vehicle_Type_ID": 9.1,
      "Vehicle_Type": "Loyalty App",
      "Vehicle_Tactic": "App Deal Banner",
      "External_ID": 2000042,
      "Internal_ID": 30041,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 9,
      "Description": ""
    },
    {
      "ID": 42,
      "Vehicle": "Apr23 Loyalty App - App Deal Feature",
      "Vehicle_Type_ID": 9.2,
      "Vehicle_Type": "Loyalty App",
      "Vehicle_Tactic": "App Deal Feature",
      "External_ID": 2000043,
      "Internal_ID": 30042,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 10,
      "Description": ""
    },
    {
      "ID": 43,
      "Vehicle": "Apr23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000044,
      "Internal_ID": 30043,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 10,
      "Description": ""
    },
    {
      "ID": 44,
      "Vehicle": "Apr23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000045,
      "Internal_ID": 30044,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 11,
      "Description": ""
    },
    {
      "ID": 45,
      "Vehicle": "Apr23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000046,
      "Internal_ID": 30045,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 9,
      "Description": ""
    },
    {
      "ID": 46,
      "Vehicle": "Apr23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000047,
      "Internal_ID": 30046,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 10,
      "Description": ""
    },
    {
      "ID": 47,
      "Vehicle": "Apr23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000048,
      "Internal_ID": 30047,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 10,
      "Description": ""
    },
    {
      "ID": 48,
      "Vehicle": "Apr23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000049,
      "Internal_ID": 30048,
      "Start_Date": "4/9/2023",
      "End_Date": "4/22/2023",
      "Period": "Period 8",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 11,
      "Description": ""
    },
    {
      "ID": 49,
      "Vehicle": "May23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000050,
      "Internal_ID": 30049,
      "Start_Date": "5/7/2023",
      "End_Date": "5/20/2023",
      "Period": "Period 10",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 12,
      "Description": ""
    },
    {
      "ID": 50,
      "Vehicle": "May23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000051,
      "Internal_ID": 30050,
      "Start_Date": "5/7/2023",
      "End_Date": "5/20/2023",
      "Period": "Period 10",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 13,
      "Description": ""
    },
    {
      "ID": 51,
      "Vehicle": "May23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000052,
      "Internal_ID": 30051,
      "Start_Date": "5/7/2023",
      "End_Date": "5/20/2023",
      "Period": "Period 10",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 12,
      "Description": ""
    },
    {
      "ID": 52,
      "Vehicle": "May23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000053,
      "Internal_ID": 30052,
      "Start_Date": "5/7/2023",
      "End_Date": "5/20/2023",
      "Period": "Period 10",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 13,
      "Description": ""
    },
    {
      "ID": 53,
      "Vehicle": "May23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000054,
      "Internal_ID": 30053,
      "Start_Date": "5/7/2023",
      "End_Date": "5/20/2023",
      "Period": "Period 10",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 12,
      "Description": ""
    },
    {
      "ID": 54,
      "Vehicle": "May23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000055,
      "Internal_ID": 30054,
      "Start_Date": "5/7/2023",
      "End_Date": "5/20/2023",
      "Period": "Period 10",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 13,
      "Description": ""
    },
    {
      "ID": 55,
      "Vehicle": "Jun23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000056,
      "Internal_ID": 30055,
      "Start_Date": "6/18/2023",
      "End_Date": "7/1/2023",
      "Period": "Period 13",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 56,
      "Vehicle": "Jun23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000057,
      "Internal_ID": 30056,
      "Start_Date": "6/18/2023",
      "End_Date": "7/1/2023",
      "Period": "Period 13",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 57,
      "Vehicle": "Jun23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000058,
      "Internal_ID": 30057,
      "Start_Date": "6/18/2023",
      "End_Date": "7/1/2023",
      "Period": "Period 13",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 58,
      "Vehicle": "Jun23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000059,
      "Internal_ID": 30058,
      "Start_Date": "6/18/2023",
      "End_Date": "7/1/2023",
      "Period": "Period 13",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 59,
      "Vehicle": "Jun23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000060,
      "Internal_ID": 30059,
      "Start_Date": "6/18/2023",
      "End_Date": "7/1/2023",
      "Period": "Period 13",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 60,
      "Vehicle": "Jun23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000061,
      "Internal_ID": 30060,
      "Start_Date": "6/18/2023",
      "End_Date": "7/1/2023",
      "Period": "Period 13",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 61,
      "Vehicle": "Jul23 Cooler Cling - Cooler Deal Feature",
      "Vehicle_Type_ID": 3.1,
      "Vehicle_Type": "Cooler Cling",
      "Vehicle_Tactic": "Cooler Deal Feature",
      "External_ID": 2000062,
      "Internal_ID": 30061,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 62,
      "Vehicle": "Jul23 Cooler Cling - Cooler Deal Spotlight",
      "Vehicle_Type_ID": 3.2,
      "Vehicle_Type": "Cooler Cling",
      "Vehicle_Tactic": "Cooler Deal Spotlight",
      "External_ID": 2000063,
      "Internal_ID": 30062,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 14,
      "Description": ""
    },
    {
      "ID": 63,
      "Vehicle": "Jul23 Counter Mat - Counter Deal Feature",
      "Vehicle_Type_ID": 2.1,
      "Vehicle_Type": "Counter Mat",
      "Vehicle_Tactic": "Counter Deal Feature",
      "External_ID": 2000064,
      "Internal_ID": 30063,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 16,
      "Description": ""
    },
    {
      "ID": 64,
      "Vehicle": "Jul23 Counter Mat - Counter Deal Spotlight",
      "Vehicle_Type_ID": 2.2,
      "Vehicle_Type": "Counter Mat",
      "Vehicle_Tactic": "Counter Deal Spotlight",
      "External_ID": 2000065,
      "Internal_ID": 30064,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 65,
      "Vehicle": "Jul23 Diesel Poster - Diesel Deal Feature",
      "Vehicle_Type_ID": 5.1,
      "Vehicle_Type": "Diesel Poster",
      "Vehicle_Tactic": "Diesel Deal Feature",
      "External_ID": 2000066,
      "Internal_ID": 30065,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 14,
      "Description": ""
    },
    {
      "ID": 66,
      "Vehicle": "Jul23 Diesel Poster - Diesel Deal Spotlight",
      "Vehicle_Type_ID": 5.2,
      "Vehicle_Type": "Diesel Poster",
      "Vehicle_Tactic": "Diesel Deal Spotlight",
      "External_ID": 2000067,
      "Internal_ID": 30066,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 67,
      "Vehicle": "Jul23 Door Sign - Door Deal Feature",
      "Vehicle_Type_ID": 1.1,
      "Vehicle_Type": "Door Sign",
      "Vehicle_Tactic": "Door Deal Feature",
      "External_ID": 2000068,
      "Internal_ID": 30067,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 68,
      "Vehicle": "Jul23 Door Sign - Door Deal Spotlight",
      "Vehicle_Type_ID": 1.2,
      "Vehicle_Type": "Door Sign",
      "Vehicle_Tactic": "Door Deal Spotlight",
      "External_ID": 2000069,
      "Internal_ID": 30068,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 16,
      "Description": ""
    },
    {
      "ID": 69,
      "Vehicle": "Jul23 Gas Pump Topper - Topper Deal Feature",
      "Vehicle_Type_ID": 4.1,
      "Vehicle_Type": "Gas Pump Topper",
      "Vehicle_Tactic": "Topper Deal Feature",
      "External_ID": 2000070,
      "Internal_ID": 30069,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 14,
      "Description": ""
    },
    {
      "ID": 70,
      "Vehicle": "Jul23 Gas Pump Topper - Topper Deal Spotlight",
      "Vehicle_Type_ID": 4.2,
      "Vehicle_Type": "Gas Pump Topper",
      "Vehicle_Tactic": "Topper Deal Spotlight",
      "External_ID": 2000071,
      "Internal_ID": 30070,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 71,
      "Vehicle": "Jul23 Loyalty App - App Deal Banner",
      "Vehicle_Type_ID": 9.1,
      "Vehicle_Type": "Loyalty App",
      "Vehicle_Tactic": "App Deal Banner",
      "External_ID": 2000072,
      "Internal_ID": 30071,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 72,
      "Vehicle": "Jul23 Loyalty App - App Deal Feature",
      "Vehicle_Type_ID": 9.2,
      "Vehicle_Type": "Loyalty App",
      "Vehicle_Tactic": "App Deal Feature",
      "External_ID": 2000073,
      "Internal_ID": 30072,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 16,
      "Description": ""
    },
    {
      "ID": 73,
      "Vehicle": "Jul23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000074,
      "Internal_ID": 30073,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 14,
      "Description": ""
    },
    {
      "ID": 74,
      "Vehicle": "Jul23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000075,
      "Internal_ID": 30074,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 75,
      "Vehicle": "Jul23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000076,
      "Internal_ID": 30075,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 76,
      "Vehicle": "Jul23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000077,
      "Internal_ID": 30076,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 16,
      "Description": ""
    },
    {
      "ID": 77,
      "Vehicle": "Jul23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000078,
      "Internal_ID": 30077,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 14,
      "Description": ""
    },
    {
      "ID": 78,
      "Vehicle": "Jul23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000079,
      "Internal_ID": 30078,
      "Start_Date": "7/2/2023",
      "End_Date": "7/15/2023",
      "Period": "Period 14",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 15,
      "Description": ""
    },
    {
      "ID": 79,
      "Vehicle": "Aug23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000080,
      "Internal_ID": 30079,
      "Start_Date": "8/13/2023",
      "End_Date": "8/26/2023",
      "Period": "Period 17",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 17,
      "Description": ""
    },
    {
      "ID": 80,
      "Vehicle": "Aug23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000081,
      "Internal_ID": 30080,
      "Start_Date": "8/13/2023",
      "End_Date": "8/26/2023",
      "Period": "Period 17",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 18,
      "Description": ""
    },
    {
      "ID": 81,
      "Vehicle": "Aug23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000082,
      "Internal_ID": 30081,
      "Start_Date": "8/13/2023",
      "End_Date": "8/26/2023",
      "Period": "Period 17",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 17,
      "Description": ""
    },
    {
      "ID": 82,
      "Vehicle": "Aug23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000083,
      "Internal_ID": 30082,
      "Start_Date": "8/13/2023",
      "End_Date": "8/26/2023",
      "Period": "Period 17",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 18,
      "Description": ""
    },
    {
      "ID": 83,
      "Vehicle": "Aug23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000084,
      "Internal_ID": 30083,
      "Start_Date": "8/13/2023",
      "End_Date": "8/26/2023",
      "Period": "Period 17",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 17,
      "Description": ""
    },
    {
      "ID": 84,
      "Vehicle": "Aug23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000085,
      "Internal_ID": 30084,
      "Start_Date": "8/13/2023",
      "End_Date": "8/26/2023",
      "Period": "Period 17",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 18,
      "Description": ""
    },
    {
      "ID": 85,
      "Vehicle": "Sep23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000086,
      "Internal_ID": 30085,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 86,
      "Vehicle": "Sep23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000087,
      "Internal_ID": 30086,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 87,
      "Vehicle": "Sep23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000088,
      "Internal_ID": 30087,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 88,
      "Vehicle": "Sep23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000089,
      "Internal_ID": 30088,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 89,
      "Vehicle": "Sep23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000090,
      "Internal_ID": 30089,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 90,
      "Vehicle": "Sep23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000091,
      "Internal_ID": 30090,
      "Start_Date": "9/10/2023",
      "End_Date": "9/23/2023",
      "Period": "Period 19",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 91,
      "Vehicle": "Oct23 Cooler Cling - Cooler Deal Feature",
      "Vehicle_Type_ID": 3.1,
      "Vehicle_Type": "Cooler Cling",
      "Vehicle_Tactic": "Cooler Deal Feature",
      "External_ID": 2000092,
      "Internal_ID": 30091,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 19,
      "Description": ""
    },
    {
      "ID": 92,
      "Vehicle": "Oct23 Cooler Cling - Cooler Deal Spotlight",
      "Vehicle_Type_ID": 3.2,
      "Vehicle_Type": "Cooler Cling",
      "Vehicle_Tactic": "Cooler Deal Spotlight",
      "External_ID": 2000093,
      "Internal_ID": 30092,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 93,
      "Vehicle": "Oct23 Counter Mat - Counter Deal Feature",
      "Vehicle_Type_ID": 2.1,
      "Vehicle_Type": "Counter Mat",
      "Vehicle_Tactic": "Counter Deal Feature",
      "External_ID": 2000094,
      "Internal_ID": 30093,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 94,
      "Vehicle": "Oct23 Counter Mat - Counter Deal Spotlight",
      "Vehicle_Type_ID": 2.2,
      "Vehicle_Type": "Counter Mat",
      "Vehicle_Tactic": "Counter Deal Spotlight",
      "External_ID": 2000095,
      "Internal_ID": 30094,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 21,
      "Description": ""
    },
    {
      "ID": 95,
      "Vehicle": "Oct23 Diesel Poster - Diesel Deal Feature",
      "Vehicle_Type_ID": 5.1,
      "Vehicle_Type": "Diesel Poster",
      "Vehicle_Tactic": "Diesel Deal Feature",
      "External_ID": 2000096,
      "Internal_ID": 30095,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 19,
      "Description": ""
    },
    {
      "ID": 96,
      "Vehicle": "Oct23 Diesel Poster - Diesel Deal Spotlight",
      "Vehicle_Type_ID": 5.2,
      "Vehicle_Type": "Diesel Poster",
      "Vehicle_Tactic": "Diesel Deal Spotlight",
      "External_ID": 2000097,
      "Internal_ID": 30096,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 97,
      "Vehicle": "Oct23 Door Sign - Door Deal Feature",
      "Vehicle_Type_ID": 1.1,
      "Vehicle_Type": "Door Sign",
      "Vehicle_Tactic": "Door Deal Feature",
      "External_ID": 2000098,
      "Internal_ID": 30097,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 98,
      "Vehicle": "Oct23 Door Sign - Door Deal Spotlight",
      "Vehicle_Type_ID": 1.2,
      "Vehicle_Type": "Door Sign",
      "Vehicle_Tactic": "Door Deal Spotlight",
      "External_ID": 2000099,
      "Internal_ID": 30098,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 21,
      "Description": ""
    },
    {
      "ID": 99,
      "Vehicle": "Oct23 Gas Pump Topper - Topper Deal Feature",
      "Vehicle_Type_ID": 4.1,
      "Vehicle_Type": "Gas Pump Topper",
      "Vehicle_Tactic": "Topper Deal Feature",
      "External_ID": 2000100,
      "Internal_ID": 30099,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 19,
      "Description": ""
    },
    {
      "ID": 100,
      "Vehicle": "Oct23 Gas Pump Topper - Topper Deal Spotlight",
      "Vehicle_Type_ID": 4.2,
      "Vehicle_Type": "Gas Pump Topper",
      "Vehicle_Tactic": "Topper Deal Spotlight",
      "External_ID": 2000101,
      "Internal_ID": 30100,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 101,
      "Vehicle": "Oct23 Loyalty App - App Deal Banner",
      "Vehicle_Type_ID": 9.1,
      "Vehicle_Type": "Loyalty App",
      "Vehicle_Tactic": "App Deal Banner",
      "External_ID": 2000102,
      "Internal_ID": 30101,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 102,
      "Vehicle": "Oct23 Loyalty App - App Deal Feature",
      "Vehicle_Type_ID": 9.2,
      "Vehicle_Type": "Loyalty App",
      "Vehicle_Tactic": "App Deal Feature",
      "External_ID": 2000103,
      "Internal_ID": 30102,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 21,
      "Description": ""
    },
    {
      "ID": 103,
      "Vehicle": "Oct23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000104,
      "Internal_ID": 30103,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 19,
      "Description": ""
    },
    {
      "ID": 104,
      "Vehicle": "Oct23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000105,
      "Internal_ID": 30104,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 105,
      "Vehicle": "Oct23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000106,
      "Internal_ID": 30105,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 106,
      "Vehicle": "Oct23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000107,
      "Internal_ID": 30106,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 21,
      "Description": ""
    },
    {
      "ID": 107,
      "Vehicle": "Oct23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000108,
      "Internal_ID": 30107,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 19,
      "Description": ""
    },
    {
      "ID": 108,
      "Vehicle": "Oct23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000109,
      "Internal_ID": 30108,
      "Start_Date": "10/8/2023",
      "End_Date": "10/21/2023",
      "Period": "Period 21",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 20,
      "Description": ""
    },
    {
      "ID": 109,
      "Vehicle": "Nov23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000110,
      "Internal_ID": 30109,
      "Start_Date": "11/5/2023",
      "End_Date": "11/18/2023",
      "Period": "Period 23",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 110,
      "Vehicle": "Nov23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000111,
      "Internal_ID": 30110,
      "Start_Date": "11/5/2023",
      "End_Date": "11/18/2023",
      "Period": "Period 23",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 111,
      "Vehicle": "Nov23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000112,
      "Internal_ID": 30111,
      "Start_Date": "11/5/2023",
      "End_Date": "11/18/2023",
      "Period": "Period 23",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 112,
      "Vehicle": "Nov23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000113,
      "Internal_ID": 30112,
      "Start_Date": "11/5/2023",
      "End_Date": "11/18/2023",
      "Period": "Period 23",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 113,
      "Vehicle": "Nov23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000114,
      "Internal_ID": 30113,
      "Start_Date": "11/5/2023",
      "End_Date": "11/18/2023",
      "Period": "Period 23",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 114,
      "Vehicle": "Nov23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000115,
      "Internal_ID": 30114,
      "Start_Date": "11/5/2023",
      "End_Date": "11/18/2023",
      "Period": "Period 23",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": null,
      "Description": ""
    },
    {
      "ID": 115,
      "Vehicle": "Dec23 Monthly Email - Email Deal Feature",
      "Vehicle_Type_ID": 7.1,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Feature",
      "External_ID": 2000116,
      "Internal_ID": 30115,
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period": "Period 25",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 22,
      "Description": ""
    },
    {
      "ID": 116,
      "Vehicle": "Dec23 Monthly Email - Email Deal Spotlight",
      "Vehicle_Type_ID": 7.2,
      "Vehicle_Type": "Monthly Email",
      "Vehicle_Tactic": "Email Deal Spotlight",
      "External_ID": 2000117,
      "Internal_ID": 30116,
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period": "Period 25",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 23,
      "Description": ""
    },
    {
      "ID": 117,
      "Vehicle": "Dec23 Monthly Flyer - Flyer Deal Feature",
      "Vehicle_Type_ID": 6.1,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Feature",
      "External_ID": 2000118,
      "Internal_ID": 30117,
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period": "Period 25",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 23,
      "Description": ""
    },
    {
      "ID": 118,
      "Vehicle": "Dec23 Monthly Flyer - Flyer Deal Spotlight",
      "Vehicle_Type_ID": 6.2,
      "Vehicle_Type": "Monthly Flyer",
      "Vehicle_Tactic": "Flyer Deal Spotlight",
      "External_ID": 2000119,
      "Internal_ID": 30118,
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period": "Period 25",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 24,
      "Description": ""
    },
    {
      "ID": 119,
      "Vehicle": "Dec23 Rewards Email - Rewards Deal Feature",
      "Vehicle_Type_ID": 8.1,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Feature",
      "External_ID": 2000120,
      "Internal_ID": 30119,
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period": "Period 25",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 22,
      "Description": ""
    },
    {
      "ID": 120,
      "Vehicle": "Dec23 Rewards Email - Rewards Deal Spotlight",
      "Vehicle_Type_ID": 8.2,
      "Vehicle_Type": "Rewards Email",
      "Vehicle_Tactic": "Rewards Deal Spotlight",
      "External_ID": 2000121,
      "Internal_ID": 30120,
      "Start_Date": "12/3/2023",
      "End_Date": "12/16/2023",
      "Period": "Period 25",
      "Locations": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100",
      "Promotions": 24,
      "Description": ""
    }
   ]

   export default rows;