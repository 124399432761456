import gen from "./_generator";
import utils from "../app/utils";
import data from "./periodsData";

/*
const startDate = new Date('1/1/2023');
const endDate = new Date('1/14/2023');

// START TESTS
function printDate(d){
    return d.getDate()+"/"+(d.getMonth() + 1) + "/" + d.getFullYear();
}

function test(){
    //var n = Date.parse('04/26/2021'); // number
    var d = new Date('1/1/2023');
    console.log(d.getFullYear()+" mo:"+d.getMonth() + " day:" + d.getDate());
}
//test();

function test2(){
    console.log("______ NEXT DAY 1: " + gen.nextDay(0, gen.periodStart, 7));
    console.log("______ NEXT DAY 2: " + gen.nextDay(1, startDate, 7));
    console.log("______ NEXT DAY 3: " + gen.nextDay(2, startDate, 7));
    console.log("______ NEXT DAY 4: " + gen.nextDay(3, startDate, 7));
    //console.log("______ NEXT DAY 2: " + gen.nextDay(1, endDate, 7));
}
//test2();
// END TESTS
*/



var rows = gen.rows(data.length, (i) => {
    const obj = data[i];
    
    const row = {};
    row.ID = gen.id(i);
    row.Start_Date = Number(new Date(obj.Start_Date));
    row.End_Date = Number(new Date(obj.End_Date));

    // return
    return utils.createRow("periods", row);
});


export default rows;

