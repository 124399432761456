// import {default as MuiButton} from '@mui/material/Button';
// import {Split, SplitLeft, SplitRight} from '../foundation/Layout';
import classNames from 'classnames';
import Pagination from '@mui/material/Pagination';
import {Group, } from '../foundation';
import {Sprite, SpriteButton, SpriteText, SpriteField, SpriteInput, SpriteListHover, } from '../foundation/Sprite';
import {Split, SplitLeft, SplitRight} from '../foundation/Layout';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import {Searchbar} from './Searchbar';
import Popover from '@mui/material/Popover';
import './Toolbars.scss';
import React from 'react';
import utils from "../app/utils";
import {GlobalSupportDialog} from "../Panels/MessagePanel"

// // import IconButton from '@mui/material/IconButton';
// import {useGlobalsDispatch} from '../app/context';
// import {Button} from '../foundation/Button';
// import {Layout} from "../../foundation/Layout";

import MuiPopover from '@mui/material/Popover';

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

export {Searchbar};




// export function ViewPromoBar({children}) {
//     const css = classNames('rds-viewpromobar');
//     return <td className={css}>{children}</td>;
// }


export function Graphbar({children}) {
    const css = classNames('rds-pagebar');
    return <td className={css}>{children}</td>;
}

export function Viewbar({children}) {
    const css = classNames('rds-pagebar');
    return <td className={css}>{children}</td>;
}


export function Pagebar({children}) {
    const css = classNames('rds-pagebar');
    return (
        <div className={css}>
            <Pagination count={10} size="small" />
        </div>
    );
}




export function Titlebar({title, type}){    
    const css = classNames('rds-titlebar is-type-' + type);
    return (
        <div className={css}>
            <Sprite className="rds-titlebar-left is-headline6" >{title}</Sprite>
            <Sprite className="rds-titlebar-right">
                <SpriteButton style={{top: 10, width: '100%'}} />
            </Sprite>
        </div>
    );
}

export function Titlebar3({type, children, share, actionCount = 0}){    
    const css = classNames('rds-titlebar3 is-debu is-type-' + type);
    const showAction = !isNaN(actionCount) && (actionCount > 1); // --- IS THIS CORRECT


    // share
    const [shareEl, setBellEl] = React.useState(null);
    const shareOpen = !!shareEl;
    const onShare = (event) => {setBellEl(event.currentTarget);}
    const onShareClose = () => {setBellEl(null);};
    const to = {vertical: 'top', horizontal: 'left',};
    const ao = {vertical: 'top', horizontal: 'right',};

    // action
    const [actionEl2, setActionEl2] = React.useState(null);
    const actionOpen2 = !!actionEl2;
    const onAction2 = (event) => {setActionEl2(event.currentTarget);}
    const onActionClose2 = () => {setActionEl2(null);};
    const to2 = {vertical: 'top', horizontal: 'left',};
    const ao2 = {vertical: 36, horizontal: 'left',};


    // support --- NEW 1/5
    const [open2, setOpen2] = React.useState(false);
    const handleClose = (value) => {setOpen2(false);};
    React.useEffect(() => {
        utils.toggleDialogClass(open2);
    }, [open2]);

    const onSupportClick = () => {setOpen2(!open2); onShareClose();}

    return (
        <React.Fragment>

            <GlobalSupportDialog open={open2} onClose={handleClose}/>

            <Popover 
                id="rds-titlebar-share-popover-container" 
                open={shareOpen} 
                anchorEl={shareEl} 
                onClose={onShareClose}
                transformOrigin={to}
                anchorOrigin={ao}
            >
                <Sprite id="rds-titlebar-share-popover" absall style={{width: 408, height: 216}}>
                    <SpriteButton debug={0}  style={{left: 250, top: 10, width: 150, height: 30}} onClick={onSupportClick} />
                    {false && <SpriteButton debug={0}  style={{left: 20, top: 80, width: 370, height: 30}} onClick={onShareClose} />}
                    <SpriteField debug={0} style={{top: 88, left: 30, width: 260}} placeholder="Start typing" />
                    <SpriteButton debug={0}  style={{left: 20, top: 130, width: 120, height: 70}} onClick={onShareClose} />
                    <SpriteButton debug={0}  style={{left: 240, top: 170, width: 80, height: 30}} onClick={onShareClose} />
                </Sprite>

            </Popover>


            <Popover 
                id="rds-titlebar-action-popover-container" 
                open={actionOpen2} 
                anchorEl={actionEl2} 
                onClose={onActionClose2}
                transformOrigin={to2}
                anchorOrigin={ao2}
            >
                <Sprite id="rds-titlebar-action-menu" onClick={onActionClose2} absall style={{width: 132, height: 192, cursor: 'pointer'}} />

            </Popover>



            <div className={css}>
                <React.Fragment>
                    {children}
                    {share && <SpriteButton debug={0} className="rds-titlebar3-sharebtn" onClick={onShare} />}
                    
                    {showAction && (
                        <Sprite absall style={{top: 0, left: 943, width: 400, height: 32, backgroundColor: '#f8f9fa'}}>
                            <SpriteText className="is-body3" style={{width: 260, color: '#8F9EB2', backgroundColor: 'transparent', textAlign: 'right', padding: '6px 4px 0 0'}}>{actionCount} promotions are selected</SpriteText>
                            <SpriteButton debug={0} id="rds-titlebar-action-button" style={{left: 268, width: 132, height: 32}} onClick={onAction2} />
                        </Sprite>
                    )}

                </React.Fragment>
            </div>
        </React.Fragment>
    );
}

export function TitlebarAdv({label, description, type, children}){    
    const css = classNames('rds-titlebaradv', type && `is-type-${type}`);
    return (
        <div className={css}>
            <div className="rds-titlebaradv-left" >
                <span className="rds-titlebaradv-label is-headline6">{label}</span>
                {description && <span className="rds-titlebaradv-desc is-body2">{description}</span>}
            </div>
            <Sprite className="rds-titlebaradv-right">{children}</Sprite>
        </div>
    );
}





export function Paginationbar(){    
    const css = classNames('rds-paginationbar');
    return (
        <div className={css}>
            <div className="is-center">
                <Sprite className="rds-paginationbar-left">
                    <SpriteButton style={{top: 10, width: 50}} />
                </Sprite>
                <Sprite className="rds-paginationbar-right">
                    <SpriteButton style={{top: 10, width: 200}} />
                </Sprite>
            </div>
        </div>
    );
}







// ---- filter toolbar --- //

// function FilterToolbarPopoverContent({className, onClickSort, closePopover}){
//     const [value, setValue] = React.useState('7%');
//     const onChangeValue = (e) => {setValue(e?.target?.value)}

//     const onCancel = () => closePopover();
//     const onSave = () => closePopover();

//     // render
//     return (
//         <Sprite absall={1} className={className} style={{width: 448, height: 224,}} >

//             <SpriteInput debug222={1} style={{top: 116, left: 26, width: 400}} value={value || ''} onChange={onChangeValue} placeholder="Enter number" />

//             <Group type="cancel-save" onCancel={onCancel} onSave={onSave}  disabled={!String(value).length} style={{top: 164, left: 240, backgroundColor: 'white', }} />

//         </Sprite>
//     );
// }

export function FilterToolbarPopover(props){
    const {name, open, anchorEl, onClose, onClick, shape = "rounded", className, ao, to, closePopover, } = props;
    const [changed, setChanged] = React.useState(false);

    // popover - submenu
    const [submenuEl, setSubmenuEl] = React.useState(null);
    const submenuOpen = !!submenuEl;    
    const onCloseSubmenu = () => {setSubmenuEl(null);};
    const onClickSubmenu = (e) => setSubmenuEl(e.currentTarget);

    const ao2 = {vertical: 'bottom', horizontal: 'left',};
    const to2 = {vertical: 'top', horizontal: 'left',};

    // selection
    const [selection, setSelection] = React.useState(null);
    const between = (selection===4||selection===5);
    const onItemClick = (i) => {
        setSelection(i);
        setChanged(true);
    }

    // value
    const [value, setValue] = React.useState('7%');
    const [value2, setValue2] = React.useState('0.45');
    const [value3, setValue3] = React.useState('0.80');
    const onChangeValue = (e) => {
        setValue(e?.target?.value || '');
        setChanged(true);
    }
    const onChangeValue2 = (e) => {
        setChanged(true);
        setValue2(e?.target?.value || '');
    }
    const onChangeValue3 = (e) => {
        setChanged(true);
        setValue3(e?.target?.value || '');
    }

    // actions
    const onCancel = () => onClose();
    const onSave = () => onClose();

    // render
    const css2 = classNames("rds-price-searchbar-submenu-button", selection && `is-item-${selection}`); // 
    const css = classNames("rds-popover-container is-shape-" + shape, className);
    return (
        <MuiPopover 
            id={"rds-popover-" + name} 
            className={css}
            open={open} 
            anchorEl={anchorEl} 
            onClose={onClose}
            anchorOrigin={ao || {vertical: 'bottom', horizontal: 'left',}}
            transformOrigin={to || {vertical: 'top', horizontal: 'left',}}
        >
            {/* <FilterToolbarPopoverContent {...props} name={name} className={"rds-popover is-name-" + name} onClick={onClick} closePopover={onClose} /> */}
            <Sprite absall={1} className={"rds-popover is-name-" + name + ' is-item-' + selection} style={{width: 448, height: 224,}} >

                {/* submenu */}
                <SpriteButton className={css2} debug={0} style={{top: 42, left: 20, width: 370, height: 28, }} onClick={onClickSubmenu} />
                <Popover open={submenuOpen} anchorEl={submenuEl} onClose={onCloseSubmenu} transformOrigin={to2} anchorOrigin={ao2}>
                    <Sprite absall className="rds-price-searchbar-submenu" onClick={onCloseSubmenu}>
                        <SpriteListHover count={6} onClickItem={onItemClick} />
                    </Sprite>
                </Popover>
                
                {/* inputs */}
                <SpriteInput style={{top: 116, left: 26, width: 160}} value={value} onChange={onChangeValue} placeholder="Enter value" />
                {between && <SpriteInput style={{top: 116, left: 26, width: 160}} value={value2} onChange={onChangeValue2} placeholder="Enter value" />}
                {between && <SpriteInput style={{top: 116, left: 242, width: 160}} value={value3} onChange={onChangeValue3} placeholder="Enter value" />}

                {/* cancel save */}
                <Group type="cancel-save" onCancel={onCancel} onSave={onSave}  disabled={!changed} style={{top: 164, left: 240, backgroundColor: 'white', }} />

            </Sprite>
        </MuiPopover>
    );
}

export function FilterToolbar(props){
    const {style, debug, onClickFilter} = props;
    const [open, setOpen] = React.useState(false);

    const DEBUG = 0; // config

    React.useEffect(() => {
        const onClickBody = () => {setOpen(false);}

        // body click listener
        document.addEventListener("click", onClickBody);
        return () => {
            document.removeEventListener("click", onClickBody);
        };
    }, []);

    const onClickBar = (e) => {e.stopPropagation();}
    const onClickClearAll= () => {setOpen(false)}
    const onClickFilterArrow = (e) => {setOpen(!open);}
    const onClickFilterButton = (e) => {
        if(onClickFilter) onClickFilter();
    }

    // popover - menu
    const [recentEl, setRecentEl] = React.useState(null); // NOTE: remove
    const recentOpen = !!recentEl;    
    const onCloseMenu = () => {setRecentEl(null);};
    const onClickMenu = (e) => setRecentEl(e.currentTarget);


    // return
    const css = classNames("rds-price-searchbar", open && 'is-open');
    return (
        <Sprite absall className={css} style={style} onClick={onClickBar}>
            
            <SpriteButton debug={DEBUG} style={{top: 8, left: 450, width: 100, }} onClick={onClickFilterButton} />
            <SpriteButton debug={DEBUG} style={{top: 8, left: 550, width: 40, }} onClick={onClickFilterArrow} />

            <SpriteButton debug={DEBUG} style={{top: 60, left: 170, width: 230, }} onClick={onClickMenu} />

            <SpriteButton debug={DEBUG} style={{top: 60, left: 'auto', right: 0, width: 200, }} onClick={onClickClearAll} />

            <FilterToolbarPopover name="price-searchbar-menu" shape="rounded" open={recentOpen} anchorEl={recentEl} onClose={onCloseMenu} />
        

        </Sprite>
    );
}

