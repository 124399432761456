import React from "react";
import {Template, Button, Sprite, SpriteButton, SpriteText, PanelBottom, Select} from "../foundation";
import {useGlobals, useGlobalsDispatch} from '../app/context';
import {PanelButton} from "./MessagePanel";
import utils from '../app/utils';



export default function PromoDetailsPanel(actions){
    const {id} = actions;
    const globals = useGlobals();
    const dispatch = useGlobalsDispatch();
    const [row, setRow] = React.useState({});

    // load row
    React.useEffect(() => {
        const row2 = utils.selectRow(globals, "promos", id);
        if(!row2) return; // missing row
        utils.loadDetails(globals, "promos", row2);
        setRow(row2);
    }, [id]);

    // load row
    // React.useEffect(() => {
    //     console.log("################# ID: ", id);
    //     const row2 = utils.selectRow(globals, "promos", id);
    //     if(!row2) return; // missing row
    //     utils.loadDetails(globals, "promos", row2);
    //     setRow(row2);
    // }, [id]);

    const onClickCancel = () => {dispatch({type: "hidePanel"})};
    const onClickConfirm = () => {dispatch({type: "hidePanel"})};
    const onClickFull = () => {
        console.log("################# onClickFull: ", row);
        dispatch({type: "hidePanel"});
        dispatch({type: "showDetails", table: "promos", id: row.ID});



        //dispatch({type: "hidePanel"});
        dispatch({type: "showDetails", table: "promos", id: row.ID});
    };

    //const datestr = utils.printDate(row.Start_Date) + ' - ' + utils.printDate(row.End_Date);
    //<SpriteText debug={0} label={datestr} className="is-caption1" style={{top: 298, left: 12, width: 300}} />

    const onStatusChange = () => {};

    // render
    return row && (
        <React.Fragment>
        <Sprite className="rds-promodetails-panel is-abs-all" style={{width: 422, height: 1241}}>



            <SpriteButton debug={0} style={{top: -10, left: 330, width: 90}} onClick={onClickFull} />
            <SpriteText debug={0} label={row.Name} className="is-title-md is-bg-ltgray is-ellipsis111" style={{top: 45, left: 68, width: 204, height: 67, overflow: 'hidden'}} />
            <Select debug={0} type="status-solid" value={row.Status} onChange={onStatusChange} className="is-abs" style={{top: 48, left: 278, width: 124}} />
            <SpriteText debug={0} label={'AFX42310589'} className="is-caption1" style={{top: 167, left: 36, width: 150}} />
            <SpriteText debug={0} label={'#' +row.External_ID} className="is-caption1" style={{top: 167, left: 282, width: 130}} />
            <SpriteText debug={0} label={utils.printDate(row.Start_Date)} className="is-body2" style={{top: 236, left: 84, width: 110}} />
            <SpriteText debug={0} label={utils.printDate(row.End_Date)} className="is-body2" style={{top: 236, left: 288, width: 110}} />




            {false && <SpriteText debug={0} label={row.Custom_Labels} className="is-caption1 is-ellipsis" style={{top: 442, left: 12, width: 400}} />}




        </Sprite>
            <PanelBottom>
                <Template type="buttons-confirm" className="is-panel-bottom" onClick1={onClickCancel} onClick2={onClickConfirm} />
            </PanelBottom>
            
            <PanelButton />
        </React.Fragment>
    );
}
/*
<SpriteText debug={0} label={row.Description} className="is-body3 is-bg-ltgray is-ellipsis" style={{top: 22288, left: 14, width: 394, height: 37, color: '#4D607B'}} />
*/
