import React, { useEffect } from "react";
import {Sprite, SpriteButton, SpriteField, SpriteInput, SpriteText, SpriteCheckbox, SpriteSelect, Panel, PanelBottom, Group, MiniAnim} from '../foundation';
import { useGlobalsDispatch, useGlobals } from "../app/context";
import utils from '../app/utils';
import {defaults} from "../data/data";


const debug = 0;

const valueOptions = [
    {value: "percent-off", label: "% off",},
    {value: "dollar-off", label: "$ off",},
    {value: "set-dollar", label: "set $",},
];

const targetOptions = [
    "Any product",
    "Product from Default list",
    "Product from Beef sirloin",
    "Product from BBQ sauce",
    "Product from list (create)",
    "Bundle",
    "Basket",
    "Gift card",
    "Product lists",
];

function effectFunc(value, valueSetter, priceSetter){
    const isnumber = isNaN(parseInt(value));
    priceSetter(isnumber ? "-" : "$2-6");
    valueSetter(value);
}


// --- components --- //

function ReviewText({label, style, }){
    return <SpriteText label={label} debug={debug} style={style} className="is-bg-ltgray is-text-md" />;
}

function CondName({setter, offer, }){
    const onChangeName = (e) => {setter("Name", e.target.value);}
    return <SpriteField debug={debug} style={{top: 120, left: 7, width: 300}} value={offer.Name || ''} onChange={onChangeName} placeholder="Name this offer" />;
}

function CondGroup({setter, offer, }){
    const {condAdded, condTarget, condMin, condMax, } = offer;
    const onChangeTarget = (p1, p2, e) => {setter("condTarget", e.target.value);}
    const onChangeMin = (e) => {setter("condMin", e.target.value);}
    const onChangeMax = (e) => {setter("condMax", e.target.value);}

    if(!condAdded) return; // hide

    return (
        <React.Fragment>
            <CondTop setter={setter} offer={offer} />
            <SpriteSelect debug={debug} style={{top: 300, left: 30, width: 350}} placeholder="Choose target" name="condTarget" value={condTarget} options={targetOptions} onChange={onChangeTarget} />
            <SpriteField debug={debug} style={{top: 372, left: 30, width: 170}} value={condMin} onChange={onChangeMin} placeholder="Number" />
            <SpriteField debug={debug} style={{top: 372, left: 236, width: 170}} value={condMax} onChange={onChangeMax} placeholder="Number" />
        </React.Fragment>
    );
}

function CondTop({setter, offer, }){
    const {condValue, condType, } = offer;
    const [value, setValue] = React.useState(condValue);
    const [price, setPrice] = React.useState("-");

    React.useEffect(() => {
        effectFunc(condValue, setValue, setPrice);
    }, [condValue]);

    const onChangeType = (p1, p2, e) => {setter("condType", e.target.value);}
    const onChangeValue = (e) => setter("condValue", e.target.value);

    // has type?
    if(!condType) return (
        <SpriteSelect debug={debug} style={{top: 228, left: 30, width: 350}} placeholder="Choose value" name="condType" value={condType} options={valueOptions} onChange={onChangeType} />
    ); 
    
    return (
        <React.Fragment>
            <SpriteInput debug={debug} style={{top: 228, left: 30, width: 150}} value={value || ''} onChange={onChangeValue} placeholder="Number" />
            <SpriteText debug={debug} style={{top: 226, left: 337, width: 70, minWidth: 0, color: '#8F9EB2', }} label={price} className="is-bg-ltgray is-caption1" />
        </React.Fragment>
    );
}


function OfferGroup({setter, offer, }){
    const {offerAdded, offerTarget, offerMin, offerMax, } = offer;
    const onChangeTarget = (p1, p2, e) => {setter("offerTarget", e.target.value);}
    const onChangeMin = (e) => {setter("offerMin", e.target.value);}
    const onChangeMax = (e) => {setter("offerMax", e.target.value);}

    if(!offerAdded) return; // hide

    return (
        <React.Fragment>
            <OfferTop setter={setter} offer={offer} />
            <SpriteSelect debug={debug} style={{top: 620, left: 30, width: 350}} placeholder="Choose target" name="offerTarget" value={offerTarget} options={targetOptions} onChange={onChangeTarget} />
            <SpriteField debug={debug} style={{top: 692, left: 30, width: 170}} value={offerMin} onChange={onChangeMin} placeholder="Number" />
            <SpriteField debug={debug} style={{top: 692, left: 236, width: 170}} value={offerMax} onChange={onChangeMax} placeholder="Number" />
        </React.Fragment>
    );
}

function OfferTop({setter, offer, }){
    const {offerValue, offerType, } = offer;
    const [value, setValue] = React.useState(offerValue);
    const [price, setPrice] = React.useState("-");

    React.useEffect(() => {
        effectFunc(offerValue, setValue, setPrice);
    }, [offerValue]);

    const onChangeType = (p1, p2, e) => {setter("offerType", e.target.value);}
    const onChangeValue = (e) => setter("offerValue", e.target.value);

    // has type?
    if(!offerType) return (
        <SpriteSelect debug={debug} style={{top: 548, left: 30, width: 350}} placeholder="Choose value" name="offerType" value={offerType} options={valueOptions} onChange={onChangeType} />
    );

    return (
        <React.Fragment>
            <SpriteInput debug={debug} style={{top: 548, left: 30, width: 150}} value={value || ''} onChange={onChangeValue} placeholder="Number" />
            <SpriteText debug={debug} style={{top: 546, left: 337, width: 70, minWidth: 0, color: '#8F9EB2', }} label={price} className="is-bg-ltgray is-caption1" />
        </React.Fragment>
    );
}


// --- screens --- //

export function CustomOffer({onClose, dispatch, globals, model}){
    const [offer, setOffer] = React.useState({});
    const {condAdded, offerAdded, condType, offerType, submitted, } = offer;

    const setOfferValue = (name, value) => {
        offer[name] = value;
        setOffer({...offer});
    }

    // init
    React.useEffect(() => {
        setOffer({...defaults.customOffer, });
    }, []);

    // add condition / offer
    const onClickCond = () => setOfferValue("condAdded", true);
    const onClickOffer = () => setOfferValue("offerAdded", true);

    // bottom button
    const onSave = () => {
        setOfferValue("submitted", true);
    }

    // review?
    if(submitted) return <CustomOfferReview setOfferValue={setOfferValue} offer={offer} dispatch={dispatch} onClose={onClose} />

    // render
    const css = utils.classNames("rds-custom-offer-panel", condAdded && 'is-condadded', offerAdded && 'is-offeradded', condType && 'is-condtype', offerType && 'is-offertype');
    return (
        <React.Fragment>

            <Sprite className={css} absall>

                <SpriteButton debug={debug} onClick={onClose} style={{top: -11, left: 3, width: 250, height: 40, zIndex: 1, }} />
                <CondName setter={setOfferValue} offer={offer} />
                <CondGroup setter={setOfferValue} offer={offer} />
                <OfferGroup setter={setOfferValue} offer={offer} />
                
                {!condAdded && <SpriteButton debug={debug} style={{top: 173, left: 0, width: 120, height: 20}} onClick={onClickCond} />}
                {condType && !offerAdded && <SpriteButton debug={debug} style={{top: 477, left: 0, width: 120, height: 20}} onClick={onClickOffer} />}

            </Sprite>

            <PanelBottom>
                <Group type="back-save" onBack={onClose} onSave={onSave} disabled={!utils.isFilled(offer.Name)} />
            </PanelBottom>
        </React.Fragment>
    );
}


export function CustomOfferReview({offer, setOfferValue, dispatch, onClose, }){
    const {Name, condTarget, offerTarget, } = offer;

    // bottom button
    const onBack = () => {
        setOfferValue("submitted", false);
    }

    const onSave = () => {
        dispatch({type: "addingPromoOffer", id: 102}); // --- WIP: 
        onClose();
    }

    // render
    const css = utils.classNames("rds-custom-offer-review-panel");
    return (
        <React.Fragment>

            <Sprite className={css} absall>
                <SpriteButton debug={debug} onClick={onBack} style={{top: -11, left: 3, width: 250, height: 40, zIndex: 1, }} />
                <SpriteText label={Name} debug={debug} style={{top: -11, left: 30, width: 230, height: 100, }} className="is-headline6" />

                <SpriteText label={Name} debug={debug} style={{top: 110, left: 15, width: 390,  }} className="is-title-md is-bg-ltgray" />

                <ReviewText label="$1.6-4.8" style={{top: 173, left: 13, }} />
                <ReviewText label="0%" style={{top: 235, left: 13, }} />
                <ReviewText label="20%" style={{top: 297, left: 13, }} />

                <ReviewText label="$2-6" style={{top: 173, left: 206, }} />
                <ReviewText label={condTarget} style={{top: 235, left: 206, width: 200, height: 28, whiteSpace: "nowrap", overflow: 'hidden', }} />
                <ReviewText label={offerTarget} style={{top: 297, left: 206, width: 200, height: 28, whiteSpace: "nowrap", overflow: 'hidden', }} />
            </Sprite>

            <PanelBottom>
                <Group type="back-addoffer" onBack={onClose} onSave={onSave} disabled={false} />
            </PanelBottom>
        </React.Fragment>
    );
}

