import classNames from 'classnames';
// import {utils} from "../app/functions";
import utils from '../app/utils';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import './ProfileNav.scss';


// --- topbar --- //
export default function ProfileNav(props) {
    const dispatch = useGlobalsDispatch();
    const {profile} = useGlobals();

    const onClickJoe = () => {
        utils.loader(dispatch, {type: 'setProfile', value: "dave", }, 1000);
        // dispatch({type: "setProfile", value: "dave", });
    };

    const onClickJane = () => {
        utils.loader(dispatch, {type: 'setProfile', value: "anna", }, 1000);
        // dispatch({type: "setProfile", value: "anna", });
    };
    
    const css = classNames('rds-profilenav');
    const dave = classNames('rds-profilenav-item is-type-dave', (profile === 'dave') && 'is-selected');
    const anna = classNames('rds-profilenav-item is-type-anna', (profile === 'anna') && 'is-selected');
    return (
        <div className={css}>
            <div className={dave} onClick={onClickJoe} />
            <div className={anna} onClick={onClickJane} />
        </div>
    );
};
