import React from "react";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteButton, SpriteText} from "../../foundation";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';
// import "./Dashboard.scss";

// function Below(){
//     return (
//         <div className='rds-tabnav-below'>
//             <Template type="changelogs" />
//         </div>
//     );
// }

function Nav ({screen}) {
    const dispatch = useGlobalsDispatch();
    // const css = classNames('rds-tabnav', className);
    console.log("------ FLAG 111:", screen);
    if(screen === "task" || screen === "task-view") screen = "task-define";
    const onClick = (screen) => {dispatch({type: "setScreen", value: screen})};
    return (
        <div className='rds-tabnav is-type-task' style={{height: 408}}>
            <SpriteButton debug={0} className={screen === "task-define" && "is-selected"} onClick={() => onClick("task-define")} />
            <SpriteButton debug={0} className={screen === "task-product" && "is-selected"} onClick={() => onClick("task-product")} />
            <SpriteButton debug={0} className={screen === "task-location" && "is-selected"} onClick={() => onClick("task-location")} />
            <SpriteButton debug={0} className={screen === "task-vehicles" && "is-selected"} onClick={() => onClick("task-vehicles")} />
            <SpriteButton debug={0} className={screen === "task-funding" && "is-selected"} onClick={() => onClick("task-funding")} />
            <SpriteButton debug={0} className={screen === "task-offer" && "is-selected"} onClick={() => onClick("task-offer")} />
        </div>
    );
}


function Content({screen}){
    switch(screen){
        default:
        case 'task-define':     return <Sprite className="rds-task-define" style={{height:600, width: 'auto'}} />;
        case 'task-product':    return <Sprite className="rds-task-product" style={{height:600, width: 'auto'}} />;
        case 'task-location':    return <Sprite className="rds-task-location" style={{height:600, width: 'auto'}} />;
        case 'task-vehicles':    return <Sprite className="rds-task-vehicles" style={{height:600, width: 'auto'}} />;
        case 'task-funding':    return <Sprite className="rds-task-funding" style={{height:600, width: 'auto'}} />;
        case 'task-offer':    return <Sprite className="rds-task-offer" style={{height:740, width: 916, left: -17}} />;
    }
}

function ViewPromoBar({label}){
    const dispatch = useGlobalsDispatch();
    const onClickCancel = () => {
        dispatch({type: "setScreen", value: "task-list"});
    };

    return (
        <Sprite className="rds-viewpromobar is-center" style={{width: 1344, height: 40, marginBottom: 20}}>
            <SpriteText className="is-graybg is-abs is-body2 is-debu" style={{left: 200, minWidth: 400, top: 4}}>{label}</SpriteText>
            <SpriteButton className="is-abs-right" style={{top: 1, right: 1}} debug={0} onClick={onClickCancel} />
            <SpriteButton className="is-abs-right" style={{top: 1, right: 120}} debug={0} onClick={onClickCancel} />
        </Sprite>
    );
}


export default function Settings({screen}){
    if(!screen) screen = "task-define";
    return (
        <div>
            <ViewPromoBar label="Beef Sirloins, Eight 8 oz Steaks TPR $110.65" />

            <TabLayout>
                <TabLayoutLeft>
                    <Nav screen={screen} />
                    <Template type="changelogs" />
                </TabLayoutLeft>
                <TabLayoutRight>
                    <Content screen={screen} />
                </TabLayoutRight>
            </TabLayout>
        </div>
    );
}