import React from "react";
import {Split, SplitLeft, SplitRight} from '../foundation/Layout';
import classNames from 'classnames';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import {Button} from '../foundation/Button';
// import Badge from '@mui/material/Badge';
import {Link, Title} from '../foundation/Text';
import {IconButton, IconLink} from '../foundation/Icon';
import {Sprite, SpriteButton, SpriteText} from '../foundation/Sprite';
import MainMenu from './MainMenu';
import ProfileNav from './ProfileNav';
import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog';
import {GlobalMessageDialog} from "../Panels/MessagePanel"
import './GlobalNav.scss';
import utils from "../app/utils";

// import Drawer from '@mui/material/Drawer';

/*
import Avatar from '@mui/material/Avatar';
<Avatar alt="Remy Sharp" src="/spacer.png" onClick={onProfile} />
*/

export {MainMenu, ProfileNav}

/*
function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} open={open}>
            <MessagePanel closer={handleClose} />
    </Dialog>
    )
}
*/


// --- topbar --- //

export function GlobalNav(props) {

    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const {showMessage, app, } = globals;
    const onLogo = () => dispatch({type: "setScreen", value: "home"});
    const onMore = () => dispatch({type: "toggleMenu"});
    const onProfile = () => dispatch({type: "setScreen", value: "settings"});
    // const onMessage = () => {setOpen2(!open2);}; // TEMP
    const onLightning = () => {} // BASE --- WIP
    const onSignout = () => dispatch({type: "toggleLogout"});

    
    // const [showMessage2, setShowMessage] = React.useState(null);
    // React.useEffect(() => {
    //     setShowMessage(showMessage);
    // }, [showMessage]);
    // React.useEffect(() => {
    //     console.log("==== showMessage2: " + showMessage2);
    //     setShowMessage(showNewMessage);
    // }, [showNewMessage]);

    // menu
    const [menuEl, setMenuEl] = React.useState(null);
    const menuOpen = !!menuEl;
    const onMenu = (event) => {setMenuEl(event.currentTarget);}
    const onMenuClose = () => {setMenuEl(null);};
    const onClickLogout = () => {} // --- WIP

    // help
    const [helpEl, setHelpEl] = React.useState(null);
    const helpOpen = !!helpEl;
    const onHelp = (event) => {setHelpEl(event.currentTarget);}
    const onHelpClose = () => {setHelpEl(null);};

    // bell
    const [bellEl, setBellEl] = React.useState(null);
    const bellOpen = !!bellEl;
    const onBell = (event) => {setBellEl(event.currentTarget);}
    const onBellClose = () => {setBellEl(null);};

    // apps
    const [appsEl, setAppsEl] = React.useState(null);
    const appsOpen = !!appsEl;
    const onApps = (event) => {setAppsEl(event.currentTarget);}
    const onAppsClose = () => {setAppsEl(null);};

    // messages --- NEW 1/5
    /*
    const [open2, setOpen2] = React.useState(false);
    // const [selectedValue, setSelectedValue] = React.useState(emails[1]);
    // const handleClickOpen = () => {setOpen(true);};
    const handleClose = (value) => {setOpen2(false);};
    React.useEffect(() => {
        // if preview panel is open, close it.
        if(open2) dispatch({type: "hidePanel"});
        utils.toggleDialogClass(open2);
    }, [open2]);
    */

   // message --- BASE 11/25/23
    const onMessage = () => dispatch({type: "toggleMessage"});
    const handleClose = () => {dispatch({type: "toggleMessage", value: false, })};
    
    // avatar
    // const [profileEl, setProfileEl] = React.useState(null);
    // const profileOpen = !!profileEl;
    // const onProfile = (event) => {setProfileEl(event.currentTarget);}
    // const onProfileClose = () => {setProfileEl(null);};

    const to = {vertical: 'top', horizontal: 'right',};
    const ao = {vertical: 56, horizontal: 'right',};
    
    const to2 = {vertical: 'top', horizontal: 'left',};
    const ao2 = {vertical: 56, horizontal: 'left',};

    // base
    const onAnalyticsClick = () => {
        // --- WIP
        dispatch({type: "setApp", value: "analytics", });
        utils.loader(dispatch, {type: 'setScreen', value: "analytics-home"}, 700);
    }
    const onBaseClick = () => {
        dispatch({type: "setApp", value: "base", });
        utils.loader(dispatch, {type: 'setScreen', value: "base-home"}, 700);
    }
    const onPromoClick = () => {
        dispatch({type: "setApp", value: "promo", });
        utils.loader(dispatch, {type: 'setScreen', value: "dashboard"}, 1000);
    };

    // return
    const css = classNames('rds-globalnav is-app-' + app);
    return (
        <div className={css}>
            <Split className="is-center">
                <SplitLeft>
                    {/* 
                    {   globals.showMenu ? 
                        <Button onClick={onMore} icon="x" label="Close" /> : 
                        <Button onClick={onMore} icon="menu" label="Menu" />
                    }
                    */}

                    <SpriteButton onClick={onMenu} className={menuOpen ? "rds-menu-button is-open" : "rds-menu-button"} debug={0} />

                    <SpriteButton className="rds-namebox is-clear" onClick={onLogo} href="/" />

                </SplitLeft>
                <SplitRight>
                    <IconButton type="message" onClick={onMessage} number={5} />
                    <IconButton type="help" onClick={onHelp} />
                    <IconButton type="bell" onClick={onBell} number={17} />
                    <IconButton type="signout" onClick={onSignout} />
                    <IconButton type="apps" onClick={onApps} />
                    <IconButton type="lightning" onClick={onLightning} />
                    <SpriteButton className={"rds-globalnav-avatar is-type-" + globals.profile} onClick={onProfile} />
                </SplitRight>
            </Split>

            <Popover id="rds-mainmenu2-popover" open={menuOpen} anchorEl={menuEl} onClose={onMenuClose} transformOrigin={to2} anchorOrigin={ao2}>
                <MainMenu onClickItem={onMenuClose} closer={() => setMenuEl(null)} />
            </Popover>

            <Popover id="rds-helpmenu-popover" open={helpOpen} anchorEl={helpEl} onClose={onHelpClose} transformOrigin={to} anchorOrigin={ao}>
                <Sprite absall className="rds-helpmenu" onClick={onHelpClose}>
                    <ul className="rds-listhover" style={{top: 2, }}><li /><li /><li /><li /></ul>
                </Sprite>
            </Popover>

            <Popover 
                id="rds-bellmenu-popover" 
                open={bellOpen} 
                anchorEl={bellEl} 
                onClose={onBellClose}
                transformOrigin={to}
                anchorOrigin={ao}
            >
                <Sprite className={(app === "base") ? "rds-base-notifications" : "rds-bellmenu"}>
                    <SpriteButton className="rds-bellmenu-btn1" />
                    <SpriteButton className="rds-bellmenu-btn2" />
                    <SpriteButton className="rds-bellmenu-seeall" />
                </Sprite>
            </Popover>

            <Popover 
                id="rds-appmenu-popover" 
                open={appsOpen} 
                anchorEl={appsEl} 
                onClose={onAppsClose}
                transformOrigin={to}
                anchorOrigin={ao}
            >
                <Sprite absall className="rds-appmenu" onClick={onAppsClose}>
                    <SpriteButton debug={0} onClick={onAnalyticsClick} style={{top: 16, left: 16, opacity: app==='analytics'?1:0, }} />
                    <SpriteButton debug={0} onClick={onBaseClick} style={{top: 16, left: 136, opacity: app==='base'?1:0, }} />
                    <SpriteButton debug={0} onClick={onPromoClick} style={{top: 16, left: 256, opacity: app==='promo'?1:0,  }} />
                </Sprite>
            </Popover>

            <GlobalMessageDialog open={showMessage} onClose={handleClose}/>
        </div>
    );
}
/*
function MainMenuClick(dispatch, name){
    console.log('------ IT WORKS: ' + name);
    dispatch({type: 'setScreen', value: name});
}

function MainMenuGroup({name, items}){
    const dispatch = useGlobalsDispatch();
    
    return (
        <div className={'rds-mainmenu-group is-name-' + name}>
            <SpriteButton  onClick={() => MainMenuClick(dispatch, name)} />
            <ul>
            {items && items.map((str, i) => <li key={i}><SpriteButton label={str} onClick={() => MainMenuClick(dispatch, str)} /></li>)}
            </ul>
        </div>
    );
}

export function MainMenu(){
    const globals = useGlobals();
    const css = classNames("rds-mainmenu is-clear");
    let i = 0;

    return (
            <Sprite className={css}>
                <MainMenuGroup key={i++} name="dashboard" />
                <MainMenuGroup key={i++} name="promos" items={["promo-cal", 'view-promos', 'import-promos']} />
                <MainMenuGroup key={i++} name="offers" />
                <MainMenuGroup key={i++} name="profile" items={["system-members", "settings"]} />

                <MainMenuGroup key={i++} name="reports" />
                <MainMenuGroup key={i++} name="automation" />
                <MainMenuGroup key={i++} name="help" />
                
                <MainMenuGroup key={i++} name="vehicles" />
                <MainMenuGroup key={i++} name="funding" />
                <MainMenuGroup key={i++} name="privacy" />
            </Sprite>
    );
}
*/
