import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton, SpriteListHover, PanelBottom, Group, EditTableList, } from '../foundation';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';



export default function Panel({name, }){
    const dispatch = useGlobalsDispatch();
    const [changed, setChanged] = React.useState(false);

    const onCancel = () => {dispatch({type: 'hidePanel'})}
    const onSave = () => {dispatch({type: 'hidePanel'})}
    const onAction = () => {setChanged(true);}

    const Item = EditTableList.Item;


    // return
    return (
        <React.Fragment>

            <Sprite absall className="rds-taskmgt-filters-panel" onClick={() => setChanged(true)} style={{width: 424, height: 572}}>

                {/* <EditTableList itemCount={10} onAction={onAction} style={{top: 100, left: 4, width: 414, }} /> */}
                
                <SpriteListHover count={7} itemHeight={40} rounded debug222={1} style={{top: 300, left: -3, }} />

            </Sprite>

            <PanelBottom>
                <Group type="cancel-save" onCancel={onCancel} onSave={onSave} disabled={!changed} />
            </PanelBottom>

        </React.Fragment>


        


    );

}
