import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import {Sprite, SpriteButton, SpriteText, SpriteField, Select, DataCell, DataRow, DataList, DataHeader, DataValue} from "../../foundation";
import {GraphView} from "../../components/GraphView";
import "./PromoOffer-v2.scss";

const debug = 0;

const barData2 = [
    {
        id: 0, label: "Baseline", revenue: '$951,409.97', profit: '$339,028.66', margin: '29.35%',
    },
    {
        id: 1, label: "20% Off", revenue: '$1,316,577.78', profit: '$59,726.68', margin: '25.75%', selectable: true,
    },
    {
        id: 2, label: "18% Off", revenue: '$1,316,577.78', profit: '$59,726.68', margin: '25.75%', selectable: false, // disable selecting this for now
    },
];


// --- components --- //

function Popover2({anchorEl, open, handlePopClose, data}){
    const bar = data || {}; 

    return (

        <Popover 
            id="lds-graphview-popover"
            sx={{pointerEvents: 'none',}}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'center', horizontal: 60, }}
            transformOrigin={{vertical: 'center', horizontal: 'left', }}
            onClose={handlePopClose}
            disableRestoreFocus
        >
            <Sprite className="lds-graphview-popover" absall>

                <SpriteText debug={debug} label={"Summary: " + bar.label} className={"is-label"} style={{top: 7, left: 13}} />
                <SpriteText debug={debug} label={bar.revenue} style={{top: 49, left: 26}} />
                <SpriteText debug={debug} label={bar.profit} style={{top: 89, left: 26}} />
                <SpriteText debug={debug} label={bar.margin} style={{top: 129, left: 26}} />

            </Sprite>
            <div className="lds-graphview-popover-arrow" />
        </Popover>

    );
}

function BarGraph(props){
    const {style, data, onClick, onMouseEnter, onMouseLeave, } = props;
    const {id, animated, optimized, selected, } = data;
    const onMouseEnter2 = (e) => onMouseEnter(e, data);
    const onClick2 = (e) => onClick(e, data);

    // render
    const css = utils.classNames("rds-bargraph", selected && 'is-selected', optimized && 'is-optimized', animated && 'is-animated');
    return (
        <Sprite debug={debug} id={id} style={style} className={css} onClick={onClick2} onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave}>
            <div className="rds-bargraph-anim"><div className="rds-bargraph-anim-innerbar" /></div>
        </Sprite>
    );
}

function BarLabel(props){
    const {style, data,  } = props;
    const {optimized, } = data;
    return optimized && <div className="rds-bargraph-label" style={style}>Optimized 22%</div>;
}


// --- main --- //

export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const {addedPromoOffer, showOptimizedOffer, } = globals;
    const [barData, setBarData] = React.useState([...barData2]);
    const [popData, setPopData] = React.useState(null);
    const added = !!addedPromoOffer;
    const offset = added ? -36 : 0; // left offset for bars


    // init
    React.useEffect(() => {
        // reset
        return () => {
            dispatch({type: "removePromoOffer"});
            dispatch({type: "showOptimizedOffer", value: false, });
            barData.forEach((data, i) => {
                barData[i].selected = false;
                barData[i].optimized = false;
            });
            setBarData([...barData]);
        }
    }, []);

    // selected?
    const selectBar = (id) => {
        barData.forEach((data, i) => {
            barData[i].selected = (data.id === id);
        });
        setBarData([...barData]);
    }

    // optimzed?
    React.useEffect(() => {
        if(!showOptimizedOffer) return; // skip if false

        let opti;
        barData.forEach((data, i) => {
            if(data.selected) opti= i;
        });

        if(!isNaN(opti)) {
            barData[opti].animated = true;
            setBarData([...barData]);
            
            // after animation...
            setTimeout(() => {
                barData[opti].selected = false;
                barData[opti].animated = false;
                barData[opti].optimized = true;
                setBarData([...barData]);
            }, 2000);
        }

    }, [showOptimizedOffer]);

    const onClickAdd = () => {
        dispatch({type: "showOfferPanel", promoID: record.ID});
    }

    const onClickBar = (e, data) => {
        if(!data.selectable) return;
        dispatch({type: "showOfferPanel", offerID: 5, promoID: undefined, });
        selectBar(data?.id);
        // dispatch({type: "setSelectedBar", value: data, });
    }

    // popover...
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopOpen = (event, data) => {
      setAnchorEl(event.currentTarget);
      setPopData(data);
    };
    const handlePopClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    // render
    const height = addedPromoOffer ? 760 : 690; // hide/show last row of chart data
    const css = utils.classNames("rds-promo-offer-v2 is-abs-all", addedPromoOffer && "is-addedoffer", showOptimizedOffer && "is-optimized");
    return (
        <Sprite className={css}  style={{height:height, width: 940, left: 0, top: -11, zIndex: 4, }} >

            <Sprite debug={debug} debugall={0} absall={1} style={{top: 10, width: 420, height: 10}}>
                <SpriteButton style={{top: -6, left: 570, width: 80}} />
                <SpriteButton style={{top: -6, left: 670, width: 80}} />
                <SpriteButton debug={debug} style={{top: -6, left: 810, width: 80}} onClick={onClickAdd} />
            </Sprite>

            <BarGraph data={barData[0]} style={{top: 203, left: 106+offset, height: 135, }} onClick={onClickBar} onMouseEnter={handlePopOpen} onMouseLeave={handlePopClose} />
            <BarGraph data={barData[1]} style={{top: 203, left: 182+offset, height: 146, }} onClick={onClickBar} onMouseEnter={handlePopOpen} onMouseLeave={handlePopClose} />
            {added && <BarGraph data={barData[2]} style={{top: 203, left: 223, height: 142, }} onClick={onClickBar} onMouseEnter={handlePopOpen} onMouseLeave={handlePopClose} />}

            <BarLabel data={barData[1]} style={{top: 414, left: 132+offset, backgroundColor: "#5F45D9", }} />
            <BarLabel data={barData[1]} style={{top: 414, left: 448+offset, backgroundColor: "#0F62FE", }} />
            <BarLabel data={barData[1]} style={{top: 414, left: 707+offset, backgroundColor: "#238974", }} />

            <Popover2 anchorEl={anchorEl} open={open} data={popData} handlePopClose={handlePopClose} />

            {addedPromoOffer && <Sprite className="rds-promo-offer-v2-bars2 is-abs-all" style={{height: 273, width: 900, top: 174, left: 10, }} />}

            {showOptimizedOffer && <Sprite className="rds-promo-offer-v2-22pct is-abs-all" style={{height: 48, width: 880, top: 642, left: 17, }} />}

        </Sprite>
    );
}

