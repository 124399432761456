// DONE: 12/20/2022

const rows = [
    {
      "ID": 1,
      "Store_ID": 3695,
      "Store_Name": "Austin #1",
      "Zone_Name": "Texas",
      "Volume_Modifier": 1.29
    },
    {
      "ID": 2,
      "Store_ID": 3713,
      "Store_Name": "San Antonio #1",
      "Zone_Name": "Texas",
      "Volume_Modifier": 1.27
    },
    {
      "ID": 3,
      "Store_ID": 3712,
      "Store_Name": "Houston #1",
      "Zone_Name": "Texas",
      "Volume_Modifier": 1.25
    },
    {
      "ID": 4,
      "Store_ID": 4477,
      "Store_Name": "Georgetown #1",
      "Zone_Name": "Texas",
      "Volume_Modifier": 1.08
    },
    {
      "ID": 5,
      "Store_ID": 3623,
      "Store_Name": "San Marcos #1",
      "Zone_Name": "Texas",
      "Volume_Modifier": 1.31
    },
    {
      "ID": 6,
      "Store_ID": 2976,
      "Store_Name": "Austin #2",
      "Zone_Name": "Texas",
      "Volume_Modifier": 1.15
    },
    {
      "ID": 7,
      "Store_ID": 4207,
      "Store_Name": "San Antonio #2",
      "Zone_Name": "Texas",
      "Volume_Modifier": 1.4
    },
    {
      "ID": 8,
      "Store_ID": 3408,
      "Store_Name": "Houston #2",
      "Zone_Name": "Texas",
      "Volume_Modifier": 1.34
    },
    {
      "ID": 9,
      "Store_ID": 2923,
      "Store_Name": "Georgetown #2",
      "Zone_Name": "Texas",
      "Volume_Modifier": 0.68
    },
    {
      "ID": 10,
      "Store_ID": 4499,
      "Store_Name": "San Marcos #2",
      "Zone_Name": "Texas",
      "Volume_Modifier": 0.84
    },
    {
      "ID": 11,
      "Store_ID": 4113,
      "Store_Name": "Oklahoma City #1",
      "Zone_Name": "Oklahoma",
      "Volume_Modifier": 1.5
    },
    {
      "ID": 12,
      "Store_ID": 4210,
      "Store_Name": "Tulsa #1",
      "Zone_Name": "Oklahoma",
      "Volume_Modifier": 1.17
    },
    {
      "ID": 13,
      "Store_ID": 3093,
      "Store_Name": "Oklahoma City #2",
      "Zone_Name": "Oklahoma",
      "Volume_Modifier": 1.31
    },
    {
      "ID": 14,
      "Store_ID": 2942,
      "Store_Name": "Tulsa #2",
      "Zone_Name": "Oklahoma",
      "Volume_Modifier": 0.55
    },
    {
      "ID": 15,
      "Store_ID": 3291,
      "Store_Name": "Las Vegas #1",
      "Zone_Name": "Nevada",
      "Volume_Modifier": 1.26
    },
    {
      "ID": 16,
      "Store_ID": 3883,
      "Store_Name": "Las Vegas #2",
      "Zone_Name": "Nevada",
      "Volume_Modifier": 0.5
    },
    {
      "ID": 17,
      "Store_ID": 3521,
      "Store_Name": "Santa Fe #1",
      "Zone_Name": "New Mexico",
      "Volume_Modifier": 0.68
    },
    {
      "ID": 18,
      "Store_ID": 3013,
      "Store_Name": "Santa Fe #2",
      "Zone_Name": "New Mexico",
      "Volume_Modifier": 0.52
    },
    {
      "ID": 19,
      "Store_ID": 3049,
      "Store_Name": "Albuquerque #1",
      "Zone_Name": "New Mexico",
      "Volume_Modifier": 1.47
    },
    {
      "ID": 20,
      "Store_ID": 2943,
      "Store_Name": "Albuquerque #2",
      "Zone_Name": "New Mexico",
      "Volume_Modifier": 0.68
    },
    {
      "ID": 21,
      "Store_ID": 2913,
      "Store_Name": "Phoenix #1",
      "Zone_Name": "Arizona",
      "Volume_Modifier": 0.59
    },
    {
      "ID": 22,
      "Store_ID": 4209,
      "Store_Name": "Tucson #1",
      "Zone_Name": "Arizona",
      "Volume_Modifier": 1.07
    },
    {
      "ID": 23,
      "Store_ID": 2945,
      "Store_Name": "Phoenix #2",
      "Zone_Name": "Arizona",
      "Volume_Modifier": 1
    },
    {
      "ID": 24,
      "Store_ID": 3183,
      "Store_Name": "Tucson #2",
      "Zone_Name": "Arizona",
      "Volume_Modifier": 0.54
    },
    {
      "ID": 25,
      "Store_ID": 4192,
      "Store_Name": "Denver #1",
      "Zone_Name": "Colorado",
      "Volume_Modifier": 1.13
    },
    {
      "ID": 26,
      "Store_ID": 3292,
      "Store_Name": "Colorado Springs #1",
      "Zone_Name": "Colorado",
      "Volume_Modifier": 1.12
    },
    {
      "ID": 27,
      "Store_ID": 3404,
      "Store_Name": "Denver #2",
      "Zone_Name": "Colorado",
      "Volume_Modifier": 1.34
    },
    {
      "ID": 28,
      "Store_ID": 4279,
      "Store_Name": "Colorado Springs #2",
      "Zone_Name": "Colorado",
      "Volume_Modifier": 0.94
    },
    {
      "ID": 29,
      "Store_ID": 4278,
      "Store_Name": "Atlanta #1",
      "Zone_Name": "Georgia",
      "Volume_Modifier": 0.87
    },
    {
      "ID": 30,
      "Store_ID": 3339,
      "Store_Name": "Atlanta #2",
      "Zone_Name": "Georgia",
      "Volume_Modifier": 0.93
    },
    {
      "ID": 31,
      "Store_ID": 4287,
      "Store_Name": "Chicago #1",
      "Zone_Name": "Illinois",
      "Volume_Modifier": 0.71
    },
    {
      "ID": 32,
      "Store_ID": 4302,
      "Store_Name": "Chicago #2",
      "Zone_Name": "Illinois",
      "Volume_Modifier": 0.94
    },
    {
      "ID": 33,
      "Store_ID": 3030,
      "Store_Name": "New York City #1",
      "Zone_Name": "New York",
      "Volume_Modifier": 1.35
    },
    {
      "ID": 34,
      "Store_ID": 3956,
      "Store_Name": "Rochester #1",
      "Zone_Name": "New York",
      "Volume_Modifier": 0.67
    },
    {
      "ID": 35,
      "Store_ID": 3894,
      "Store_Name": "Buffalo #1",
      "Zone_Name": "New York",
      "Volume_Modifier": 0.61
    },
    {
      "ID": 36,
      "Store_ID": 3850,
      "Store_Name": "New York City #2",
      "Zone_Name": "New York",
      "Volume_Modifier": 0.88
    },
    {
      "ID": 37,
      "Store_ID": 3157,
      "Store_Name": "Rochester #2",
      "Zone_Name": "New York",
      "Volume_Modifier": 1.06
    },
    {
      "ID": 38,
      "Store_ID": 4088,
      "Store_Name": "Buffalo #2",
      "Zone_Name": "New York",
      "Volume_Modifier": 1.18
    },
    {
      "ID": 39,
      "Store_ID": 3146,
      "Store_Name": "Pittsburgh #1",
      "Zone_Name": "Pennsylvania",
      "Volume_Modifier": 1.34
    },
    {
      "ID": 40,
      "Store_ID": 4247,
      "Store_Name": "Philadelphia #1",
      "Zone_Name": "Pennsylvania",
      "Volume_Modifier": 1.43
    },
    {
      "ID": 41,
      "Store_ID": 3310,
      "Store_Name": "Pittsburgh #2",
      "Zone_Name": "Pennsylvania",
      "Volume_Modifier": 0.5
    },
    {
      "ID": 42,
      "Store_ID": 3478,
      "Store_Name": "Philadelphia #2",
      "Zone_Name": "Pennsylvania",
      "Volume_Modifier": 0.83
    },
    {
      "ID": 43,
      "Store_ID": 2896,
      "Store_Name": "Charlotte #1",
      "Zone_Name": "North Carolina",
      "Volume_Modifier": 1.07
    },
    {
      "ID": 44,
      "Store_ID": 3361,
      "Store_Name": "Durham #1",
      "Zone_Name": "North Carolina",
      "Volume_Modifier": 0.83
    },
    {
      "ID": 45,
      "Store_ID": 4251,
      "Store_Name": "Charlotte #2",
      "Zone_Name": "North Carolina",
      "Volume_Modifier": 1.42
    },
    {
      "ID": 46,
      "Store_ID": 4472,
      "Store_Name": "Durham #2",
      "Zone_Name": "North Carolina",
      "Volume_Modifier": 0.78
    },
    {
      "ID": 47,
      "Store_ID": 3931,
      "Store_Name": "Charleston #1",
      "Zone_Name": "South Carolina",
      "Volume_Modifier": 1.14
    },
    {
      "ID": 48,
      "Store_ID": 3141,
      "Store_Name": "Charleston #2",
      "Zone_Name": "South Carolina",
      "Volume_Modifier": 0.67
    },
    {
      "ID": 49,
      "Store_ID": 2884,
      "Store_Name": "Nashville #1",
      "Zone_Name": "Tennessee",
      "Volume_Modifier": 0.52
    },
    {
      "ID": 50,
      "Store_ID": 3300,
      "Store_Name": "Nashville #2",
      "Zone_Name": "Tennessee",
      "Volume_Modifier": 0.78
    },
    {
      "ID": 51,
      "Store_ID": 4268,
      "Store_Name": "Nashville #3",
      "Zone_Name": "Tennessee",
      "Volume_Modifier": 0.61
    },
    {
      "ID": 52,
      "Store_ID": 2521,
      "Store_Name": "Los Angeles #1",
      "Zone_Name": "California",
      "Volume_Modifier": 0.66
    },
    {
      "ID": 53,
      "Store_ID": 4179,
      "Store_Name": "San Diego #1",
      "Zone_Name": "California",
      "Volume_Modifier": 1.47
    },
    {
      "ID": 54,
      "Store_ID": 540,
      "Store_Name": "Santa Monica #1",
      "Zone_Name": "California",
      "Volume_Modifier": 0.94
    },
    {
      "ID": 55,
      "Store_ID": 1382,
      "Store_Name": "San Francisco #1",
      "Zone_Name": "California",
      "Volume_Modifier": 0.82
    },
    {
      "ID": 56,
      "Store_ID": 1447,
      "Store_Name": "Santa Rosa #1",
      "Zone_Name": "California",
      "Volume_Modifier": 0.73
    },
    {
      "ID": 57,
      "Store_ID": 1026,
      "Store_Name": "Los Angeles #2",
      "Zone_Name": "California",
      "Volume_Modifier": 1.29
    },
    {
      "ID": 58,
      "Store_ID": 2882,
      "Store_Name": "San Diego #2",
      "Zone_Name": "California",
      "Volume_Modifier": 1.1
    },
    {
      "ID": 59,
      "Store_ID": 3694,
      "Store_Name": "Santa Monica #2",
      "Zone_Name": "California",
      "Volume_Modifier": 1.09
    },
    {
      "ID": 60,
      "Store_ID": 1361,
      "Store_Name": "San Francisco #2",
      "Zone_Name": "California",
      "Volume_Modifier": 0.56
    },
    {
      "ID": 61,
      "Store_ID": 2701,
      "Store_Name": "Santa Rosa #2",
      "Zone_Name": "California",
      "Volume_Modifier": 1.28
    },
    {
      "ID": 62,
      "Store_ID": 2726,
      "Store_Name": "Vancouver #1",
      "Zone_Name": "Canada",
      "Volume_Modifier": 1.26
    },
    {
      "ID": 63,
      "Store_ID": 2784,
      "Store_Name": "Montreal #1",
      "Zone_Name": "Canada",
      "Volume_Modifier": 1.16
    },
    {
      "ID": 64,
      "Store_ID": 2724,
      "Store_Name": "Calgary #1",
      "Zone_Name": "Canada",
      "Volume_Modifier": 0.95
    },
    {
      "ID": 65,
      "Store_ID": 2795,
      "Store_Name": "Winnipeg #1",
      "Zone_Name": "Canada",
      "Volume_Modifier": 1.1
    },
    {
      "ID": 66,
      "Store_ID": 2781,
      "Store_Name": "Edmonton #1",
      "Zone_Name": "Canada",
      "Volume_Modifier": 0.5
    },
    {
      "ID": 67,
      "Store_ID": 2809,
      "Store_Name": "Ottawa #1",
      "Zone_Name": "Canada",
      "Volume_Modifier": 0.81
    },
    {
      "ID": 68,
      "Store_ID": 695,
      "Store_Name": "Miami #1",
      "Zone_Name": "Florida",
      "Volume_Modifier": 0.8
    },
    {
      "ID": 69,
      "Store_ID": 699,
      "Store_Name": "Orlando #1",
      "Zone_Name": "Florida",
      "Volume_Modifier": 1.32
    },
    {
      "ID": 70,
      "Store_ID": 1235,
      "Store_Name": "Tampa #1",
      "Zone_Name": "Florida",
      "Volume_Modifier": 0.87
    },
    {
      "ID": 71,
      "Store_ID": 26,
      "Store_Name": "Miami #1",
      "Zone_Name": "Florida",
      "Volume_Modifier": 1.29
    },
    {
      "ID": 72,
      "Store_ID": 2043,
      "Store_Name": "Orlando #1",
      "Zone_Name": "Florida",
      "Volume_Modifier": 0.5
    },
    {
      "ID": 73,
      "Store_ID": 105,
      "Store_Name": "Tampa #1",
      "Zone_Name": "Florida",
      "Volume_Modifier": 0.57
    },
    {
      "ID": 74,
      "Store_ID": 1945,
      "Store_Name": "Columbus #1",
      "Zone_Name": "Ohio",
      "Volume_Modifier": 0.61
    },
    {
      "ID": 75,
      "Store_ID": 232,
      "Store_Name": "Cincinnati #1",
      "Zone_Name": "Ohio",
      "Volume_Modifier": 1.22
    },
    {
      "ID": 76,
      "Store_ID": 2535,
      "Store_Name": "Columbus #2",
      "Zone_Name": "Ohio",
      "Volume_Modifier": 1.11
    },
    {
      "ID": 77,
      "Store_ID": 2228,
      "Store_Name": "Cincinnati #2",
      "Zone_Name": "Ohio",
      "Volume_Modifier": 1.26
    },
    {
      "ID": 78,
      "Store_ID": 375,
      "Store_Name": "Detroit #1",
      "Zone_Name": "Michigan",
      "Volume_Modifier": 0.58
    },
    {
      "ID": 79,
      "Store_ID": 996,
      "Store_Name": "Grand Rapids #1",
      "Zone_Name": "Michigan",
      "Volume_Modifier": 1.09
    },
    {
      "ID": 80,
      "Store_ID": 381,
      "Store_Name": "Ann Arbor #1",
      "Zone_Name": "Michigan",
      "Volume_Modifier": 0.62
    },
    {
      "ID": 81,
      "Store_ID": 277,
      "Store_Name": "Detroit #2",
      "Zone_Name": "Michigan",
      "Volume_Modifier": 1.37
    },
    {
      "ID": 82,
      "Store_ID": 1315,
      "Store_Name": "Grand Rapids #2",
      "Zone_Name": "Michigan",
      "Volume_Modifier": 0.96
    },
    {
      "ID": 83,
      "Store_ID": 2499,
      "Store_Name": "Ann Arbor #2",
      "Zone_Name": "Michigan",
      "Volume_Modifier": 1.11
    },
    {
      "ID": 84,
      "Store_ID": 345,
      "Store_Name": "St Louis #1",
      "Zone_Name": "Missouri",
      "Volume_Modifier": 0.68
    },
    {
      "ID": 85,
      "Store_ID": 3787,
      "Store_Name": "Kansas City #1",
      "Zone_Name": "Missouri",
      "Volume_Modifier": 1.08
    },
    {
      "ID": 86,
      "Store_ID": 1048,
      "Store_Name": "Springfield #1",
      "Zone_Name": "Missouri",
      "Volume_Modifier": 0.71
    },
    {
      "ID": 87,
      "Store_ID": 2401,
      "Store_Name": "St Louis #2",
      "Zone_Name": "Missouri",
      "Volume_Modifier": 1.19
    },
    {
      "ID": 88,
      "Store_ID": 354,
      "Store_Name": "Kansas City #2",
      "Zone_Name": "Missouri",
      "Volume_Modifier": 1.39
    },
    {
      "ID": 89,
      "Store_ID": 2466,
      "Store_Name": "Springfield #2",
      "Zone_Name": "Missouri",
      "Volume_Modifier": 1.46
    },
    {
      "ID": 90,
      "Store_ID": 192,
      "Store_Name": "Salt Lake City #1",
      "Zone_Name": "Utah",
      "Volume_Modifier": 0.95
    },
    {
      "ID": 91,
      "Store_ID": 2363,
      "Store_Name": "Salt Lake City #2",
      "Zone_Name": "Utah",
      "Volume_Modifier": 1.17
    },
    {
      "ID": 92,
      "Store_ID": 23,
      "Store_Name": "Salt Lake City #3",
      "Zone_Name": "Utah",
      "Volume_Modifier": 0.84
    },
    {
      "ID": 93,
      "Store_ID": 1008,
      "Store_Name": "Seattle #1",
      "Zone_Name": "Washington",
      "Volume_Modifier": 0.51
    },
    {
      "ID": 94,
      "Store_ID": 95,
      "Store_Name": "Tacoma #1",
      "Zone_Name": "Washington",
      "Volume_Modifier": 1.14
    },
    {
      "ID": 95,
      "Store_ID": 941,
      "Store_Name": "Olympia #1",
      "Zone_Name": "Washington",
      "Volume_Modifier": 0.57
    },
    {
      "ID": 96,
      "Store_ID": 1577,
      "Store_Name": "Seattle #2",
      "Zone_Name": "Washington",
      "Volume_Modifier": 1.43
    },
    {
      "ID": 97,
      "Store_ID": 896,
      "Store_Name": "Tacoma #2",
      "Zone_Name": "Washington",
      "Volume_Modifier": 0.97
    },
    {
      "ID": 98,
      "Store_ID": 117,
      "Store_Name": "Olympia #2",
      "Zone_Name": "Washington",
      "Volume_Modifier": 0.55
    },
    {
      "ID": 99,
      "Store_ID": 7,
      "Store_Name": "Portland #1",
      "Zone_Name": "Oregon",
      "Volume_Modifier": 0.86
    },
    {
      "ID": 100,
      "Store_ID": 2648,
      "Store_Name": "Portland #2",
      "Zone_Name": "Oregon",
      "Volume_Modifier": 0.91
    }
   ]

   export default rows;