import React from "react";
import {Layout} from "../../foundation/Layout";
import {Sprite, SpriteButton} from '../../foundation/Sprite';
import {Searchbar, Titlebar, Paginationbar} from '../../components/Toolbars';
import {DataList, DataTop, DataRow, DataCell} from '../../foundation/DataList';
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch

import "./Report.scss";



export default function Reports(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();

    const onClickScreen = () => {
        dispatch({type: "setScreen", value: "report-list"});
    }

    return (
        <Layout className="rds-reports">

            <div className="rds-reports-topblock" ></div>

            <Sprite className="rds-reports-details" style={{width: 1147, height: 4185, marginLeft: 200}}>

                <SpriteButton debug={0} style={{top: 20, left: 0, width: 30, height: 40}} onClick={onClickScreen} />

            </Sprite>

        </Layout>
    );
}