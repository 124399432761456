import {Badge} from "../foundation";
import classNames from 'classnames';
import db from "./db";
import configs from "./configs";
import locdata from "../data/locationsData";
import { useGlobals, useGlobalsDispatch } from './context';
// import { $ }  from 'react-jquery-plugin'

const utils = {

    isFilled: (mixed) => {
        if(typeof mixed === 'string') return mixed.length ? true : false;
        return false;
    },

    // START checkall...
    checkInit: ($, rows, setChecks) => {
        $(".rds-checkall").change((e) => {
            $(".rds-datalist-checkbox").prop('checked', e.target.checked).trigger("change");
            utils.checkAll(rows, e.target.checked, setChecks);
        });
    },
    checkInit2: ($, rows, setChecks) => { // vehicles
        $(".rds-checkall").change((e) => {
            $("#rds-promo-vehicles-list .rds-datalist-checkbox").prop('checked', e.target.checked).trigger("change");
            utils.checkAll(rows, e.target.checked, setChecks);
        });
    },
    checkAll: (rows, bool, setChecks) => {
        if(!bool) return setChecks({});
        var obj = {};
        rows.forEach(row => {
            obj[row.ID] = true;
        });
        setChecks(obj);
    },
    checkCounter: (checks) => {
        var r = 0;
        Object.keys(checks).forEach(id => {
            if(checks[id]) r++;
        })
        return r;
    },
    checkSetter: (obj, e, item) => {
        obj[item.ID] = e.target.checked;
        return {...obj};
    },
    // ...END checkall


    toggleDialogClass: (value) => {
        document.body.classList.toggle('is-show-dialog', value);
        // if(value) document.body.classList.toggle('is-show-dialog', true);
        // else {
        //     setTimeout(() => {
        //         document.body.classList.toggle('is-show-dialog', false);
        //     }, 200);
        // }
    },

    togglePanelClass: (value) => {
        document.body.classList.toggle('is-show-panel', value);
    },

    removeUnlisted: (rows) => {
        var rows2 = [];
        rows.map(row => {
            if(!row.Unlisted) rows2.push(row);
        })
        return rows2;
    },

    loader: (dispatch, actions, duration=1000) => {
        dispatch({type: 'showLoader'});
        setTimeout(() => {
            dispatch({type: 'hideLoader'});
            dispatch(actions);
        }, duration);
    },

    loaderCall: (dispatch, func, duration=1000) => {
        dispatch({type: 'showLoader'});
        // return;
        setTimeout(() => {
            dispatch({type: 'hideLoader'});
            func();
        }, duration);
    },


    classNames: classNames,

    getDB: () => db,


    // casting
    isNull: (value) => ((value === undefined || value === null) ? true : false),
    isFalse: (value) => ((!value || value === '' || value === '0' || utils.isNull(value)) ? true : false),
    isTrue: (value) => (!utils.isFalse(value)),
    isArray: (value) => Array.isArray(value),
    isArrayLength: (value, len) => (Array.isArray(value) && value.length === len),
    isMap: (value) => (typeof value === 'object' && value !== null && !Array.isArray(value)),
    isObject: (value) => (typeof value === 'object' && value !== null),
    isString: (value) => (typeof value === 'string'),
    isNumber: (value) => (typeof value === 'number'),
    isBool: (value) => (typeof value === 'boolean'),
    isScalar: (value) => (utils.isString(value) || utils.isNumber(value) || utils.isBool(value) || utils.isNull(value)),
    isTable: (globals, value) => (utils.isString(value) && utils.isArray(globals[value + '_rows'])),

    toBool: (value) => (utils.isFalse(value) ? false : true),
    toNumber: (value, _else) => ((typeof(value) === 'number') ? value : _else),
    toString: (value, _else) => (utils.isScalar(value) ? (value + "") : _else),
    toStringAbs: (value, _else="") => (utils.isScalar(value) ? (value + "") : _else),
    toArray: (value, _else) => (utils.isArray(value) ? value : _else),
    toObject: (value, _else) => (utils.isObject(value) ? value : _else),
    toID: (value, _else) => (utils.isNumber(value) && value > 0) ? value : _else,


    // advanced
    toFilledString: (value, _else) => (utils.toString(value) && value.length ? value : _else),
    toJsonString: (value, _else) => (utils.isObject(value) ? JSON.stringify(value) : _else),
    toJsonObject: (value, _else) => (utils.isString(value) ? JSON.parse(value) : _else), // JSON.parse(value),

    toLowerCase: (value, _else) => utils.isString(value) ? value.toLowerCase() : _else,
    toDollar: (value, _else) => (utils.isNumber(value) ? ('$' + utils.toNiceNumber(value)) : _else),
    toPercent: (value, _else) => (utils.isNumber(value) ? ((value * 100).toFixed(2) + "%") : _else), // return (n * 100).toFixed(2) + "%";
    toPercent2: (value, _else) => (utils.isNumber(value) ? ((value * 100) + "%") : _else),
    toNiceNumber: (value, _else) => (utils.isNumber(value) ? (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : _else),



    // functions
    initiated: false,
    init: (state) => {
        if(utils.initiated) return; // just incase
        utils.initiated = true;
    },

    cast: (type, value, _else) => {
        switch(type){
            case "bool": return utils.toBool(value, _else);
            case "number": return utils.toNumber(value, _else);
            case "string": return utils.toString(value, _else);
            case "stringabs": return utils.toStringAbs(value, _else);
            case "scalar": return utils.isScalar(value, _else);
            case "array": return utils.toArray(value, _else);
            case "object": return utils.toObject(value, _else);
            case "id": return utils.toID(value, _else);
            default: console.warn("castBasic(): Invalid type: ", type);
            case "mixed": return value;
        }
    },

    castField: (table, field, value, _else) => {
        const types = db[table + '_types'] || {};
        return types[field] ? utils.castBasic(types[field], value, _else) : value; // if no type exists, treat it as mixed
    },


    // functions


    remakeRows: (rows, headers) => {
        var rows2 = [];
        rows.forEach((row) => {
            var obj = {};
            headers.forEach((header) => {
                const name = utils.isString(header) ? header : header.name;
                // if(utils.isObject(name)) name = name.Name; // header object
                obj[name] = row[name];
            });
            rows2.push(obj);
        })
        return rows2;
    },

    printLongDate: (d) => {
        const day = d.getDay();
        const month = d.getMonth();
        const date = d.getDate();
        return utils.days2[day] + ", " + utils.months[month] + " " + date;
    },

    printDate: (d) => {
        switch(typeof(d)){
            case "string": return d;
            case "number": d = new Date(d); break;
            case "object": if(!(d instanceof Date)) return '';
            default: return '';
        }
        // if(!isNaN(d)) d = new Date(d);
        if(isNaN(d.getMonth())) return 'NAN';
        return (d.getMonth() + 1) + "/" + d.getDate()+"/"+d.getFullYear();
        // return utils.toString(d, '');
    },

    days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    days2: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Satday'],
    months: ["January","February","March","April","May","June","July","August","September","October","November","December"],

    printDay: (d) => {
        return utils.days[d.getDay()];
    },
    printMonth: (d) => {
        return utils.months[d.getMonth()].substring(0, 3);
    },
    printNiceDate: (d) => {
        return utils.printMonth(d) + " " + d.getDate();
    },

    printMoney: (value, with_symbol) => {
        const symbol = with_symbol===false ? '' : '$';
        return symbol + utils.toNumber(value, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },

    printPercent: (value) => {
        return utils.toPercent(value, '');
    },

    classes: (str) => {
        return str;
    },

    removeByID: (items, id) => {
        const index = utils.findByID(items, id, true);
        console.log('ITEM: ', index, ' INDEX: ', index);
        items.splice(index, 1);

    },

    onClick: ({screen, onClick}) => {
        if(screen) return () => {screen[0]({type: 'setScreen', value: screen[1]})};
        return onClick;
    },

    toggle: (current, newvalue) => {
        return utils.isNull(newvalue) ? !utils.toBool(current) : utils.toBool(newvalue);
    },

    printCellByIDs: (rows, ids, attr) => {
        if(!utils.isArray(ids)) return 'None'; // --- TEMP
        rows = utils.findByIDs(rows, ids);
        if(!rows.length) return 'None';
        var str = utils.toStringAbs(rows[0][attr]);
        return rows.length === 1 ? str : <span>{str}<Badge label={'+' + (rows.length - 1)} /></span>;
        //if(rows.length > 1) str += " (+" + (rows.length - 1) + ")";
        //return str;
    },

    printListValue: (mixed) => {
        return utils.isFalse(mixed) ? "-" : utils.toString(mixed);
    },

    // queries

    getRecord: (globals, table, id) => {
        console.log('FLAG 111: ', table);
        if(utils.isArray(table)){
            table = table[0];
            id = table[1];
        }
        const records = globals[table + '_rows'];
        if(!records) return console.warn('getRecord() invalid table: ', table);
        return utils.findByID(records, id);
        // return records[id[1]];
    },

    findByID: (rows, id, get_index) => {
        const item = rows.find((element) => (element.ID == id));
        return get_index ? rows.indexOf(item) : item;
    },

    findByIDs: (rows, ids) => {
        var rows2 = [];
        ids.forEach(id => {
            const row = utils.findByID(rows, id);
            if(row) rows2.push(row);
        })
        return rows2;
    },

    findByField: (rows, field, value, get_index) => {
        const item = rows.find((element) => (element[field] == value));
        return get_index ? rows.indexOf(item) : item;
    },


    // simply calls method IF it exists
    trigger: (obj, method, args, _func) => {
        if(!args) args = [];
        else if(!utils.isArray(args)){
            console.warn("trigger(): param 3 must be an array or undefined METHOD: " + method);
            args = [];
        }
        if(obj[method]) return obj[method].apply(obj, args);
        if(_func) return _func.apply(obj, args);
    },

    selectRow: (state, table, id) => {
        const rows = utils.requireTableRows(state, table);
        if(!rows) return;
        const row = utils.findByID(rows, id);
        if(row) utils.trigger(db, table + '_onSelectRow', [state, row]);
        return row;
    },

    selectRowByField: (state, table, field, value) => {
        const rows = utils.requireTableRows(state, table);
        if(!rows) return;
        const row = utils.findByField(rows, field, value);
        if(row) utils.trigger(db, table + '_onSelectRow', [state, row]);
        return row;
    },

    saveRow: (state, table, row) => {
        if(!utils.isObject(row)) row = {};
        var updating = utils.isTrue(row.ID);
        return updating ? utils.updateRow(state, table, false, row) : utils.insertRow(state, table, row);
    },

    // loadList: (state, table, rows) => {
    //     utils.trigger(db, table + '_onLoadList', [state, rows]);
    // },

    createRow: (table, values) => {
        var row = {_table: table}; // built-in init
        if(typeof values === "object") row = {...row, ...values};
        utils.loadInits(table, row);
        return row;
    },

    loadInits: (table, row) => { // NO STATE
        utils.trigger(db, table + '_onLoadInits', [row]);
    },

    loadList: (state, table, rows) => {
        utils.trigger(db, table + '_onLoadList', [state, rows]);
    },

    loadDetails: (state, table, row) => {
        // row._table = table;
        utils.trigger(db, table + '_onLoadDetails', [state, row]);
    },

    loadDefaults: (row, defaults) => {
        Object.keys(defaults).forEach(name => {
            const value = row[name];
            if(value === null || value === undefined) row[name] = defaults[name];
        });

    },

    sanitize: (row, types) => { // ---- WIP
        Object.keys(row).forEach(key => {
            // if(types[key]) row[key] =
        });

    },

    sanitizeRow: (table, row) => { // ---- WIP
        utils.trigger(db, table + '_onLoadDetails', [row]);
    },

    selectRows: (state, table, ids) => {
        const rows = utils.requireTableRows(state, table);
        if(rows) return utils.findByIDs(rows, ids);
    },

    updateRow: (state, table, id, values) => {
        var rows = utils.requireTableRows(state, table);
        if(!rows) return;
        if(!id) id = values.ID;

        // update
        rows.forEach((item, i) => {
            if(item.ID === id) rows[i] = {...item, ...values}
        });

        // re-sort
        const sort = utils.requireTableSort(state, table);
        // console.log();
        utils.sortRows(rows, sort);

        return rows;
    },


    insertRow: (state, table, values) => {
        var rows = utils.requireTableRows(state, table);
        if(!rows) return;

        // insert
        rows.push({...values, ID: rows.length + 1, });

        // re-sort
        const sort = utils.requireTableSort(state, table);
        utils.sortRows(rows, sort);

        return rows;
    },

    // selectRowsByIDs: (rows, ids) => {
    //     if(!utils.isArray(rows)){
    //         console.warn('selectRowsByIDs(): Invalid rows. typeof: ', typeof(rows));
    //         rows = [];
    //     }
    //     if(!utils.isArray(ids) || !ids.length) return [];
    //     var rows2 = [];
    //     ids.forEach(id => {
    //         const row = utils.findByID(rows, id);
    //         if(row) rows2.push(row);
    //     })
    //     return rows2;
    // },

    resortTable: (state, table) => {
        var rows = utils.requireTableRows(state, table);
        if(rows) return utils.sortRows(rows, state[table + "_sort"]);
},

    sortTable: (state, table, field) => {
            var rows = utils.requireTableRows(state, table);
            if(!rows) return;

            // new sort
            if(!field) console.warn("sortTable(): param 3 must be a field");
            const sort = state[table + "_sort"];
            var [sortfield, sortasc] = sort;
            const asc = (sortfield === field) ? !sortasc : true;
            sort = [field, asc];
            return utils.sortRows(rows, [field, asc]);
    },

    sortRows: (rows, sort) => {
        if(!utils.isArray(sort)) sort = utils.isString(sort) ? [sort, true] : ['ID', true];
        const [field, isasc] = sort;
        const numFields = configs.numFields;

        // numeric?
        if(numFields[field]){
            return rows.sort((a, b) => {
                const r = (a[field] < b[field]) ? -1 : (a[field] > b[field]) ? 1 : 0;
                return isasc ? r : (r*-1);
            });
        }
        // string
        return rows.sort((a, b) => {
            var textA = (a[field]+"").toUpperCase();
            var textB = (b[field]+"").toUpperCase();
            const r = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            return isasc ? r : (r*-1);
        });
    },


    requireTableRows: (globals, table) => {
        const rows = globals[table + '_rows'];
        return rows ? rows : console.warn("requireTableRows(): Invalid table: ", table);
    },

    requireTableSort: (globals, table) => {
        const sort = globals[table + '_sort'];
        return sort ? sort : console.warn("requireTableSort(): Invalid table: ", table);
    },


    requireItem: (map, key) => {
        return utils.isNull(map[key]) ? console.warn("requireItem(): Missing item: ", key) : map[key];
    },

    getTableScreen: (table, command) => {
        return utils.requireItem(db.tableScreens, `${table}-${command}`);
    },





    // --- special

    _makeLocationsTree: (rows) => {
        // NOTE: put this in Main.js
        // <textarea style={{height: 400, width: 800}}>{JSON.stringify(globals.loctree, undefined, 4)}</textarea>
        
        var zones = {};
        var rows2 = [];

        rows.forEach(({ID, Store_Name, Zone_Name}) => {

            if(zones[Zone_Name]){
                // existing zone...
                zones[Zone_Name].children.push({
                    value: ID,
                    label: Store_Name,
                });
            } else {
                // new zone...
                const obj = {
                    value: Zone_Name,
                    label: Zone_Name,
                    children: [{
                        value: ID,
                        label: Store_Name,
                    }],
                }
                zones[Zone_Name] = obj;
                rows2.push(obj);
            }

        });

        // console.log("+++++++++++++ _makeLocationsTree: ", rows2);
        return rows2;
    },

    _makeProductsTree: (rows) => { // --- WIP
        var level1 = {};
        var level2 = {};
        var level3 = {};
        var rows2 = [];

        rows.forEach(({ID, Name, Brand, ProductDescription, Level1, Level2, Level3}) => {

            var child = {value: ID, label: Name,}

            if(level3[Level3]){
                // existing zone...
                level3[Level3].children.push(child);
            } else {
                // new zone...
                const obj = {
                    value: Level3,
                    label: Level3,
                    children: [child],
                }
                level3[Level3] = obj;
                // rows2.push(obj);
            }

            // level 2
            if(!level2[Level2]){
                level2[Level2] = {
                    value: Level2,
                    label: Level2,
                    children: [level3[Level3]],
                    map: {},
                }
                level2[Level2].map[Level3] = true;
                //rows2.push(level2[Level2]);
            } else if(!level2[Level2].map[Level3]) {
                level2[Level2].children.push(level3[Level3]);
                level2[Level2].map[Level3] = true;
            }

            // level 1
            if(!level1[Level1]){
                level1[Level1] = {
                    value: Level1,
                    label: Level1,
                    children: [level2[Level2]],
                    map: {},
                }
                level1[Level1].map[Level2] = true;
                rows2.push(level1[Level1]);
            } else if(!level1[Level1].map[Level2]) {
                level1[Level1].children.push(level2[Level2]);
                level1[Level1].map[Level2] = true;
            }

        });

        // rows.forEach(({Level2, Level3}) => { // --- I THINK THIS WORKS
        //     // --- WIP
        //     if(!level2[Level2]){
        //         level2[Level2] = {
        //             value: Level2,
        //             label: Level2,
        //             children: [level3[Level3]],
        //             map: {},
        //         }
        //         level2[Level2].map[Level3] = true;
        //         rows2.push(level2[Level2]);
        //     } else if(!level2[Level2].map[Level3]) {
        //         level2[Level2].children.push(level3[Level3]);
        //         level2[Level2].map[Level3] = true;
        //     }

        // });





        console.log("+++++++++++++ _makeProductsTree: ", rows2);

        return rows2;
    },

    // END OF UTILS
};

export default utils;

/*
const utils = {

    // members
    ticks: [0, 30, 60, 90, 120, 150],
    maxSeconds: 6000,
    numberClock: false,
    timerClock: false,

    // functions
    stopTimer: () => {
        if(utils.timerClock) utils.timerClock = clearInterval(utils.timerClock);
    },

    startTimer: ({seconds, onTick, onEnd}) => {
        utils.stopTimer();
        utils.timerClock = setInterval(function(){
            seconds -= 1;
            if(seconds > 0) {
                onTick(seconds);
            } else {
                utils.stopTimer();
                onEnd();
            }
        }, 10);
    },

    stringToSeconds: (str) => {
        let ms = parseInt(str.substr(0, 4));
        if(isNaN(ms)) return 0;
        return Math.min(ms, utils.maxSeconds);

    },

    getCirclePoint: (radius, angle) => {
        return {
            x: 0+(radius*Math.cos((angle+(-90))*(Math.PI/180))),
            y: 0+(radius*Math.sin((angle+(-90))*(Math.PI/180))),
        }
    },

    getDegree: (x, y) => {
        var theta = Math.atan2(y, x);
        theta = (theta * (180 / Math.PI)) + 90;
        if (theta < 0) theta = 360 + theta;
        return theta;
    },

    secondsToDegrees: (n) => {
        return (n / utils.maxSeconds) * 360;
    },

    degreesToSeconds: (deg) => {
        return Math.floor((deg / 360) * utils.maxSeconds);
    },

    degreesTo100: (deg) => {
        return Math.round((deg / 370) * 100); // 370 fixes render issue
    },

    dragHandler: (data, prev, onMove, setPrev) => {
        let deg = utils.getDegree(data.x, data.y);

        // no repeat values
        if(deg !== prev){
            let dif = (deg - prev);
            let hitlimit = Math.abs(dif) > 180;
            let forward = dif > 0;

            // prevent needle from moving past 0 from either side
            if(hitlimit){
                if(!forward){
                    deg = 359.99999;
                    onMove(utils.maxSeconds);
                } else {
                    deg = 0;
                    onMove(0);
                }
            } else {
                setPrev(deg);
                onMove(utils.degreesToSeconds(deg));
            }
            if(Math.abs(dif) > 180) deg = prev;
        }
    },
    
}

export default utils;


import React from 'react';
import {InlineMessage} from '@homeaway/react-alerts';

const util = {
    formatValue(value) {
        return value && String(value).replace(/^0+/, '');
    },
    normalizeValue(value, min, max, dfault = null) {
        value = parseFloat(value);
        return isNaN(value) ? dfault : Math.min(max, Math.max(min, value));
    },
    renderHelp(props) {
        const {id, errorMsg, helpText, inverse} = props;
        return (
            <InlineMessage
                marginTop={4}
                marginBottom={16}
                id={`${id}__help`}
                message={errorMsg || helpText || ''}
                type={typeof errorMsg === 'string' ? 'danger' : 'help'}
                inverse={inverse}
            />
        );
    },
};

export default util;

*/