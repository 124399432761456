import React from "react";
import classNames from 'classnames';
import utils from "../app/utils";
import {Link, Badge} from './Text';
import {IconAdv} from "./index";
import MuiPopover from '@mui/material/Popover';
import {Sprite, SpriteButton, Popover, Select} from '../foundation';
import './DataList.scss';






function SimpleFirstCell({id, onChecked, checked}){

    const isChecked = Array.isArray(checked) && !!checked.find(item => id == item);

    return <td className="firstcell"><input type="checkbox" checked={isChecked} value={id || 1} onChange={onChecked} /></td>
}

function SimpleCell({name, value, id, children, onClick}){
    const t = typeof(value);
    var css = name;
    if(onClick) css += " is-link";
    const onClick2 = onClick ? () => {onClick(id);} : null;
    return <td className={css} onClick={onClick2}>{value || children}</td>
}

function SimpleHeader({names, onChecked}){
    return (
        <thead>
            <tr className="is-top">
                {onChecked && <SimpleCell>.</SimpleCell>}
                {names.map(name => <SimpleCell key={name} name={name} value={name} />)}
            </tr>
        </thead>

    );
}


export function SimpleList({type, rows, headers, checked, onChecked, debug}){
    const css = classNames('lds-simplelist', debug && 'is-debug', type && ('is-type-' + type));
    const islib = type === 'library';
    // const showCheckbox = !!onChecked;


    // headers?
    if(headers === true && rows.length) headers = Object.keys(rows[0]);

    const handler = (e) => {
        if(!onChecked) return;

        const arr = [...checked];
        const value = e?.target?.getAttribute('value') || 0;
        const check = e?.target?.checked || false;
        const item = arr.find(v => value == v); // non-strict comparison
        const index = checked.indexOf(item);

        if(index === -1){  // not in array
            if(check) arr.push(parseInt(value)); // append arr
        } else if(arr[index]){ // is in array
            if(!check) arr.splice(index, 1); // remove
        }

        onChecked(arr);
        // console.log("====== YOOOOOO: " + value, " CHECKED: ", check, " INDEX: ", index, " ARRAY: ", checked, arr);
    }

    const onCellClick = (id) => { // NOTE: very hacky, might need to rethink this
        if(islib) {
            const target = {
                getAttribute: () => {return id;},
                checked: checked.find(v => id == v) ? false : true,
            }
            handler({target: target});
        }
    }

    // return
    return (
        <div className={css}>
            <table>
                {Array.isArray(headers) && <SimpleHeader names={headers} onChecked={onChecked && handler} />}
                    <tbody>
                    {rows.map((row, i) => row.Unlisted ? null : (
                        <tr key={i}>
                            {onChecked && <SimpleFirstCell id={row.ID} key="first" checked={checked} onChecked={handler} />}
                            {headers.map(name => <SimpleCell key={name} name={name} id={row.ID} value={row[name]} onClick={onCellClick} />)}
                            {islib && <td className="lastcell"><IconAdv type="overflow" /></td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}







// data list below...
/*
export function DataTop({names, onClick}){
    if(!onClick) onClick = () => {};

    return (
        <DataRow>
            {names.map((name, i) => (
                <DataCell name={name} key={i}>
                    <Link onClick={() => onClick(name)}>{name}</Link>
                </DataCell>
            ))}
        </DataRow>
    );
}


export function DataTopRow({children, className}) {
    return <tr className={className}>{children}</tr>;
}
*/
 

export function DataValue({type, value}){
    switch(type){
        case "nicenumber": return utils.toNiceNumber(value, '-');
        case "dollar": return utils.toDollar(value, '-');
        case "percent": return utils.toPercent(value, '-');
        case "percent2": return utils.toPercent2(value, '-');
        case "array": 
            if(utils.isArray(value)) return (
                <div>{utils.toString(value[0])} {value.length > 1 && <Badge label={'+ ' + value.length} />}</div>
            );
        break;
        default: break;
    }
    return utils.isFalse(value) ? "-" : utils.toString(value, '');
}

// 
export function DataHeader222({title, colspan=1}){
    return (
        <tr className="rds-datalist-header">
            <td colspan={colspan}>
                <span>{title} <IconAdv type="chevron" /></span>
            </td>
        </tr>
    );
}

export function DataHeader({title, colspan=1}){
    return (
        <div className="rds-datalist-header">
        <span>{title}</span>
        </div>
    );
}


function FirstCell({istop, onChecked, onClickView, item}){
    const Ele = istop ? DataTopCell : DataCell;
    const onClock = () => {console.log('asdf');};

    const onClickView2 = () => {
        if(onClickView) onClickView(item);
    }

    const onChecked2 = (e) => {
        onChecked(e, item);
    }


    if(!onChecked) return;
    return (
        <Ele name="firstcol" isfirst={true}>
            {onClickView && <div debug={0} onClick={onClickView2} className="rds-datalist-fullbtn"><IconAdv type="fullview" /></div>}
            <input type="checkbox" className="rds-datalist-checkbox" onChange={onChecked2} />
            <IconAdv type="star" className="is-inline is-muted" onClick={onClock} />
        </Ele>
    );
}

function CheckAndStar(){
    return (
        <React.Fragment>
        <input type="checkbox" className="rds-checkall" onChange={() => {}} />
        <IconAdv type="star" className="is-inline is-muted" onClick={() => {}} />
        </React.Fragment>
    );
}

export function DataTopCell({item, isfirst, onClick}) {
    var {name, label, right} = item || {};
    const css = classNames(name && `is-name-${name}`, right && 'is-align-right', isfirst && 'is-firstcol');
    const onClick2 = (e) => onClick(e, item);
    if(!label){
        if(isfirst) return <th className={'is-name-firstcol'}><CheckAndStar /></th>; // START
        label = (name+"").replaceAll("_", " ");
    }
    // const label = nolabel ? "" : (name+"").replace("_", " ");
    return <th className={css} onClick={onClick2}>{label}</th>;
}

export function DataTopCell222({item, isfirst, onClick}) {
    var {name, label, right} = item || {};
    const css = classNames(name && `is-name-${name}`, right && 'is-align-right', isfirst && 'is-firstcol');
    const onClick2 = (e) => onClick(e, item);
    if(!label) label = isfirst ? "" : (name+"").replaceAll("_", " ");
    // const label = nolabel ? "" : (name+"").replace("_", " ");
    return <th className={css} onClick={onClick2}>{label}</th>;
}


export function DataCell({name, children, onClick, className, right}){
    const css = classNames(className, name && `is-name-${name}`, onClick && 'is-link', right && 'is-align-right');
    return <td className={css} onClick={onClick}>{children}</td>;
}

export function DataRow({index, children, className, istop, onChecked, onClickView, item}) {
    const css = (index%2) ? 'is-even' : 'is-odd';

    return (
        <tr key={index} className={css + ' ' + className}>
            <FirstCell istop={istop} onChecked={onChecked} onClickView={onClickView} item={item} />
            {children}
        </tr>
    );
    // return <tr key={index} className={css + ' ' + className}>{children}</tr>;
}




export function DataList({children, className, headers, onClickSort, style, onChecked, title}){
    const css = classNames('rds-datalist', className);
    

    // header
    const [headerEl, setHeaderEl] = React.useState(null); // NOTE: remove
    const [headerItem, setHeaderItem] = React.useState(null); // NOTE: remove
    const headerOpen = !!headerEl;
    const onHeaderClose = () => {setHeaderEl(null);};
    const onClickHeader = (e, item) => {
        setHeaderEl(e.currentTarget);
        setHeaderItem(item);
    };

    // TEMP
    const onSort = () => {
        console.log("---- onClickSort 111", headerItem);
        if(onClickSort && headerItem) onClickSort(headerItem);
        onHeaderClose();
    }

    return (
        <div className={css} style={style}>
            {title && <DataHeader title={title} />}
            <table>
                <thead>
                    <DataRow onChecked={onChecked} istop={true}>
                        {headers.map((item, i) => <DataTopCell item={item} onClick={(e) => onClickHeader(e, item)} key={i} />)}
                    </DataRow>
                </thead>
                <tbody>{children}</tbody>
            </table>
            <Popover 
                name="tablesort" 
                shape="flattop" 
                open={headerOpen} 
                anchorEl={headerEl} 
                onClose={onHeaderClose} 
                onClickSort={onSort}
            />
        </div>
    );
}

/*
            <MuiPopover 
                id="rds-appmenu-popover" 
                open={appsOpen} 
                anchorEl={appsEl} 
                onClose={onAppsClose}
                transformOrigin={{vertical: 'top', horizontal: 'right',}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
            >
                <Sprite className="rds-appmenu">
                    <SpriteButton className="rds-appmenu-btn" />
                </Sprite>
            </MuiPopover>

*/






/*
            <HeaderPopover open={appsOpen} anchorEl={appsEl} onClose={onAppsClose} />

function HeaderPopover({open, anchorEl, onClose}){
    const to = {vertical: 'top', horizontal: 'right',};
    const ao = {vertical: 'bottom', horizontal: 'right',};
    return (
            <Popover 
                id="rds-appmenu-popover" 
                open={open} 
                anchorEl={anchorEl} 
                onClose={onClose}
                transformOrigin={to}
                anchorOrigin={ao}
            >
                <Sprite className="rds-appmenu">
                    <SpriteButton className="rds-appmenu-btn" />
                </Sprite>
            </Popover>
    );
}
*/


/*

export function DataList({children, className, onClick, headers}){
    const css = classNames('rds-datalist', className);
    return (
        <div className={css}>
            <table>
                <thead>
                    <DataRow istop={true}>
                        {headers.map((item, i) => (
                            <th className={'is-name-' + item.name} key={i}>
                                <Link onClick={() => onClick(item)}>{item.name}</Link>
                            </th>
                        ))}
                    </DataRow>
                </thead>
                <tbody>{children}</tbody>
            </table>
        </div>
    );
}



*/