import React from "react";
import utils from "../app/utils";
// import {Layout} from "../../foundation/Layout";
// import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteTooltip, } from '../foundation/Sprite';
// import classNames from "classnames";
// import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
// import {Searchbar, Titlebar, Paginationbar} from '../../components/Toolbars';
// import {DataList, DataTop, DataRow, DataCell} from '../../foundation/DataList';
// import {Icon} from '../../foundation/Icon';
// import { borderRadius } from "@mui/system";
import "./Misc.scss";



export function Donut(props){
    let {style, values, debug, } = props;
    const [hover, setHover] = React.useState(false);

    if(!values) values = ["9% 1,065", "30% 12,055", "60% 25,189"];
    // const tt = tooltips = ["Increase: 9% 1,065 Items", "Reduction: 30% 12,055 Items", "No change: 60% 25,189 Items"];

    // hover
    const onHover = (id, isenter) => setHover(isenter ? id : false);
    
    // handlers
    const in1 = () => onHover(1, true);
    const out1 = () => onHover(1, false);
    const in2 = () => onHover(2, true);
    const out2 = () => onHover(2, false);
    const in3 = () => onHover(3, true);
    const out3 = () => onHover(3, false);
    

    // return
    const css = utils.classNames("rds-base-donut", hover && `is-hovering-${hover}`);
    return (
        <Sprite absall debug={debug} className={css} style={style}>

            <SpriteTooltip debug={0} className="rds-base-donut-hover-1" onOpen={in1} onClose={out1} tooltip={`Increase: ${values[0]} Items`} placement="right" style={{top: 0, left: 110, height: 100, width: 50, zIndex: 3, }} />
            <SpriteTooltip debug={0} className="rds-base-donut-hover-2" onOpen={in2} onClose={out2} tooltip={`Reduction: ${values[1]} Items`} placement="right" style={{top: 50, left: 110, height: 100, width: 100, zIndex: 2, }} />
            <SpriteTooltip debug={0} className="rds-base-donut-hover-3" onOpen={in3} onClose={out3} tooltip={`No change: ${values[2]} Items`} placement="bottom" style={{top: 0, left: 0, height: '100%', width: 150, zIndex: 1, }} />

            <Sprite debug={0} style={{top: 60, left: 60, width: 100, height: 100, borderRadius: 100, zIndex: 5, backgroundColor: 'white', }} />

            <Sprite className="rds-base-donut is-slice-3" />
            <Sprite className="rds-base-donut is-slice-2" />
            <Sprite className="rds-base-donut is-slice-1" />
            
        </Sprite>
    )
}



