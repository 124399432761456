const rows = [
    {
        "value": "Cooler",
        "label": "Cooler",
        "children": [
            {
                "value": "Beer/Wine",
                "label": "Beer/Wine",
                "children": [
                    {
                        "value": "Imports",
                        "label": "Imports",
                        "children": [
                            {
                                "value": 713,
                                "label": "Sol Chelada 24Z Cn"
                            }
                        ]
                    },
                    {
                        "value": "Microbrews",
                        "label": "Microbrews",
                        "children": [
                            {
                                "value": 977,
                                "label": "Central Waters Mud Puppy Porter 46 Nr"
                            }
                        ]
                    },
                    {
                        "value": "Premium",
                        "label": "Premium",
                        "children": [
                            {
                                "value": 714,
                                "label": "Sol 24Oz Can"
                            }
                        ]
                    }
                ],
                "map": {
                    "Imports": true,
                    "Microbrews": true,
                    "Premium": true
                }
            },
            {
                "value": "Energy Drinks",
                "label": "Energy Drinks",
                "children": [
                    {
                        "value": "Carb Energy Drnks",
                        "label": "Carb Energy Drnks",
                        "children": [
                            {
                                "value": 9,
                                "label": "Pp Frosty Choc Shake"
                            },
                            {
                                "value": 10,
                                "label": "Pp Van Cream Shake"
                            },
                            {
                                "value": 26,
                                "label": "C4 Starburst Cherry 16Z Cn"
                            },
                            {
                                "value": 27,
                                "label": "C4 Smart Peach Mango Nectr 16Z Cn"
                            },
                            {
                                "value": 28,
                                "label": "C4 Smart Freedom Ice 16Z Cn"
                            },
                            {
                                "value": 29,
                                "label": "C4 Starburst Strawberry 16Z Cn"
                            },
                            {
                                "value": 30,
                                "label": "C4 Skittles 16Z Cn"
                            },
                            {
                                "value": 978,
                                "label": "Bang Candy Apple Crisp 16Oz"
                            },
                            {
                                "value": 979,
                                "label": "Bang Key Lime Pie 16Z Cn"
                            },
                            {
                                "value": 980,
                                "label": "Bang Wyldin Watermelon 16Z"
                            }
                        ]
                    }
                ],
                "map": {
                    "Carb Energy Drnks": true
                }
            },
            {
                "value": "Other Pkged Bev",
                "label": "Other Pkged Bev",
                "children": [
                    {
                        "value": "Other Pkg Bev",
                        "label": "Other Pkg Bev",
                        "children": [
                            {
                                "value": 1000,
                                "label": "Aha Raspberry Acai 16Z Btl"
                            }
                        ]
                    }
                ],
                "map": {
                    "Other Pkg Bev": true
                }
            },
            {
                "value": "Soda Pkged",
                "label": "Soda Pkged",
                "children": [
                    {
                        "value": "Carb Soft Drinks",
                        "label": "Carb Soft Drinks",
                        "children": [
                            {
                                "value": 47,
                                "label": "RC Cola Cn"
                            },
                            {
                                "value": 48,
                                "label": "RC Cola Pet"
                            },
                            {
                                "value": 49,
                                "label": "RC Cola Pp Pet"
                            },
                            {
                                "value": 50,
                                "label": "RC Cola Lt"
                            },
                            {
                                "value": 51,
                                "label": "RC Cola Pet"
                            },
                            {
                                "value": 52,
                                "label": "RC Cola Pp Pet"
                            },
                            {
                                "value": 53,
                                "label": "2L RC Cola Pet Ls8 Pp129"
                            },
                            {
                                "value": 54,
                                "label": "2L Cherry RC Cola Pet Ls8 Pp129"
                            },
                            {
                                "value": 55,
                                "label": "Nehi Peach Cn"
                            },
                            {
                                "value": 56,
                                "label": "Nehi Peach Pet"
                            },
                            {
                                "value": 57,
                                "label": "Nehi Peach Pet"
                            },
                            {
                                "value": 58,
                                "label": "Cherry 7 Up Pet"
                            },
                            {
                                "value": 59,
                                "label": "Diet Chery 7Up Pet"
                            },
                            {
                                "value": 60,
                                "label": "Diet Chery 7Up Cn"
                            },
                            {
                                "value": 61,
                                "label": "Tahitian Treat Cn"
                            },
                            {
                                "value": 62,
                                "label": "Tahitian Treat Pet"
                            },
                            {
                                "value": 63,
                                "label": "Tahitian Treat Pet"
                            },
                            {
                                "value": 64,
                                "label": "Diet 7 Up Pet"
                            },
                            {
                                "value": 65,
                                "label": "Diet Chery 7Up Pet"
                            },
                            {
                                "value": 66,
                                "label": "7.5Oz 7Up Scn 6Pkx4 Bb Hlwn"
                            },
                            {
                                "value": 67,
                                "label": "0.5L Dt 7Up Pet 6Pkx4"
                            },
                            {
                                "value": 68,
                                "label": "Aw Rootbeer Cn"
                            },
                            {
                                "value": 69,
                                "label": "Aw Rootbeer Pet"
                            },
                            {
                                "value": 70,
                                "label": "Diet Aw Rootb Cn"
                            },
                            {
                                "value": 71,
                                "label": "Diet Aw Rootb Pet"
                            },
                            {
                                "value": 72,
                                "label": "Aw Cream Soda Pet"
                            },
                            {
                                "value": 73,
                                "label": "12Oz Aw Root Beer Pet 8Pkx3 Bb"
                            },
                            {
                                "value": 989,
                                "label": "12Oz Coke Dt Blueberry Acai"
                            },
                            {
                                "value": 992,
                                "label": "Coca Cola Mini Btl 390Ml"
                            },
                            {
                                "value": 993,
                                "label": "Diet Coke Mini Btl 390Ml"
                            },
                            {
                                "value": 994,
                                "label": "Coke Zero Mini Btl 390Ml"
                            },
                            {
                                "value": 995,
                                "label": "Sprite Mini Btl 390Ml"
                            },
                            {
                                "value": 996,
                                "label": "Cherry Coke Mini Btl 390Ml"
                            }
                        ]
                    }
                ],
                "map": {
                    "Carb Soft Drinks": true
                }
            },
            {
                "value": "Tea/Coffee RTD",
                "label": "Tea/Coffee RTD",
                "children": [
                    {
                        "value": "Coffee (Rtd)",
                        "label": "Coffee (Rtd)",
                        "children": [
                            {
                                "value": 990,
                                "label": "13.7Oz Mccafe Cold Brew Toasted Coconut"
                            },
                            {
                                "value": 991,
                                "label": "13.7Oz Mccafe Cold Brew Black"
                            }
                        ]
                    }
                ],
                "map": {
                    "Coffee (Rtd)": true
                }
            },
            {
                "value": "Water",
                "label": "Water",
                "children": [
                    {
                        "value": "Enhanced Water",
                        "label": "Enhanced Water",
                        "children": [
                            {
                                "value": 997,
                                "label": "Power Water Tropical Mango 20Z Btl"
                            },
                            {
                                "value": 998,
                                "label": "Power Water Berry Cherry 20Z Btl"
                            },
                            {
                                "value": 999,
                                "label": "Power Water Lemon 20Z Btl"
                            }
                        ]
                    }
                ],
                "map": {
                    "Enhanced Water": true
                }
            }
        ],
        "map": {
            "Beer/Wine": true,
            "Energy Drinks": true,
            "Other Pkged Bev": true,
            "Soda Pkged": true,
            "Tea/Coffee RTD": true,
            "Water": true
        }
    },
    {
        "value": "Deli",
        "label": "Deli",
        "children": [
            {
                "value": "Grab n' Go",
                "label": "Grab n' Go",
                "children": [
                    {
                        "value": "Bakery",
                        "label": "Bakery",
                        "children": [
                            {
                                "value": 3,
                                "label": "Box Donut 11.75X8X2"
                            }
                        ]
                    }
                ],
                "map": {
                    "Bakery": true
                }
            }
        ],
        "map": {
            "Grab n' Go": true
        }
    },
    {
        "value": "Electronics",
        "label": "Electronics",
        "children": [
            {
                "value": "Electronics Misc",
                "label": "Electronics Misc",
                "children": [
                    {
                        "value": "Elect Misc",
                        "label": "Elect Misc",
                        "children": [
                            {
                                "value": 238,
                                "label": "Sentinel 1080P 17In Port Gaming Monitor"
                            },
                            {
                                "value": 987,
                                "label": "Wireless Ahd Vehicle Backup Camera"
                            }
                        ]
                    }
                ],
                "map": {
                    "Elect Misc": true
                }
            }
        ],
        "map": {
            "Electronics Misc": true
        }
    },
    {
        "value": "General Merch",
        "label": "General Merch",
        "children": [
            {
                "value": "General Merch 2",
                "label": "General Merch 2",
                "children": [
                    {
                        "value": "Die-Cast",
                        "label": "Die-Cast",
                        "children": [
                            {
                                "value": 477,
                                "label": "Asst Pick Up Trucks"
                            },
                            {
                                "value": 478,
                                "label": "Sports And Import Pb Cars"
                            },
                            {
                                "value": 479,
                                "label": "1 24Th Transformers Asst"
                            },
                            {
                                "value": 501,
                                "label": "1 24Th Hd Graphic Vehicles"
                            },
                            {
                                "value": 502,
                                "label": "4Pk Tray Chevy Classic Cars"
                            },
                            {
                                "value": 503,
                                "label": "4Pk Tray Pick Up Trucks"
                            },
                            {
                                "value": 504,
                                "label": "4Pk Tray Dodge Ford Cars"
                            },
                            {
                                "value": 505,
                                "label": "4Pk Tray Sports Car"
                            },
                            {
                                "value": 506,
                                "label": "Chevy Classic Btm Asst"
                            },
                            {
                                "value": 507,
                                "label": "Chevy Ford Pick Up Truck Asst"
                            },
                            {
                                "value": 508,
                                "label": "4Pk Tray Classic Muscle"
                            },
                            {
                                "value": 509,
                                "label": "Sport And Import Car Asst"
                            },
                            {
                                "value": 510,
                                "label": "Asst Chevy Wreckers"
                            },
                            {
                                "value": 511,
                                "label": "Classic Pb Muscle Car Asst 12Pk"
                            },
                            {
                                "value": 512,
                                "label": "6In L Mini School Bus"
                            },
                            {
                                "value": 513,
                                "label": "1 24Th Batmobile Asst"
                            },
                            {
                                "value": 573,
                                "label": "Mini Mag Car Asst W Board"
                            },
                            {
                                "value": 574,
                                "label": "Mini Magnet Car Asst"
                            }
                        ]
                    },
                    {
                        "value": "Drinkware",
                        "label": "Drinkware",
                        "children": [
                            {
                                "value": 302,
                                "label": "Dbl Wall Stnlss Stl 25Oz Wtr Bottle Mblk"
                            },
                            {
                                "value": 303,
                                "label": "Dbl Wall Stnlss Stl 25Oz Wtr Bottle Olv"
                            },
                            {
                                "value": 350,
                                "label": "Mammoth 30 Oz Insulated Stainlesscopper"
                            }
                        ]
                    },
                    {
                        "value": "Fashion Accessories",
                        "label": "Fashion Accessories",
                        "children": [
                            {
                                "value": 523,
                                "label": "Mini Coin Purse Kc"
                            },
                            {
                                "value": 524,
                                "label": "Mini Purse Bag Kc"
                            },
                            {
                                "value": 525,
                                "label": "Mini Pen Kc"
                            },
                            {
                                "value": 526,
                                "label": "Mini Coin Bag Kc"
                            },
                            {
                                "value": 527,
                                "label": "Cap Coin Purse Kc"
                            },
                            {
                                "value": 576,
                                "label": "Lock N Load Kc"
                            },
                            {
                                "value": 577,
                                "label": "Ww Fingerless Gloves"
                            },
                            {
                                "value": 595,
                                "label": "Embroidered Purse"
                            },
                            {
                                "value": 596,
                                "label": "Embroidered Wallet"
                            },
                            {
                                "value": 597,
                                "label": "Leather Cowhide Purse 1"
                            },
                            {
                                "value": 598,
                                "label": "Leather Cowhide Wallet"
                            }
                        ]
                    },
                    {
                        "value": "Gifts / Souvenirs",
                        "label": "Gifts / Souvenirs",
                        "children": [
                            {
                                "value": 192,
                                "label": "Watch Shipper 96Pc Kids License"
                            },
                            {
                                "value": 515,
                                "label": "Wood Sign Dishes Will Wait"
                            },
                            {
                                "value": 516,
                                "label": "Wood Sign Angels"
                            },
                            {
                                "value": 528,
                                "label": "Cowboy Boot Kc"
                            },
                            {
                                "value": 542,
                                "label": "Velvet Mini Purse 3In"
                            },
                            {
                                "value": 557,
                                "label": "Day Of Dead Mag Ast 2"
                            },
                            {
                                "value": 572,
                                "label": "La 3D State Map Magnet Epoxy"
                            },
                            {
                                "value": 662,
                                "label": "Bling Shoe Kc"
                            },
                            {
                                "value": 695,
                                "label": "Dog Sign Beagle"
                            },
                            {
                                "value": 696,
                                "label": "Dog Sign Black Lab"
                            },
                            {
                                "value": 697,
                                "label": "Dog Sign Boston Terrier"
                            },
                            {
                                "value": 698,
                                "label": "Dog Sign Boxer"
                            },
                            {
                                "value": 699,
                                "label": "Dog Sign Bulldog"
                            },
                            {
                                "value": 700,
                                "label": "Dog Sign Chihauhua"
                            },
                            {
                                "value": 701,
                                "label": "Dog Sign Chocolate Lab"
                            },
                            {
                                "value": 702,
                                "label": "Dog Sign Dachshund"
                            },
                            {
                                "value": 703,
                                "label": "Dog Sign German Shepherd"
                            },
                            {
                                "value": 704,
                                "label": "Dog Sign Golden Retriever"
                            },
                            {
                                "value": 705,
                                "label": "Dog Sign Pitbull"
                            },
                            {
                                "value": 706,
                                "label": "Dog Sign Pug"
                            },
                            {
                                "value": 707,
                                "label": "Dog Sign Poodle"
                            },
                            {
                                "value": 708,
                                "label": "Dog Sign Rottweiler"
                            },
                            {
                                "value": 709,
                                "label": "Dog Sign Schnauzer"
                            },
                            {
                                "value": 710,
                                "label": "Dog Sign Shih Tzu"
                            },
                            {
                                "value": 711,
                                "label": "Dog Sign Yellow Lab"
                            },
                            {
                                "value": 712,
                                "label": "Dog Sign Yorkshire Terrier"
                            }
                        ]
                    },
                    {
                        "value": "Hats",
                        "label": "Hats",
                        "children": [
                            {
                                "value": 19,
                                "label": "Appalachian St 1K"
                            },
                            {
                                "value": 20,
                                "label": "Boston College 1K"
                            },
                            {
                                "value": 21,
                                "label": "1K2Tn Al Adj 2Tn1"
                            },
                            {
                                "value": 22,
                                "label": "1K2Tn Okst Adj 2Tn1"
                            },
                            {
                                "value": 23,
                                "label": "1K Fishing"
                            },
                            {
                                "value": 24,
                                "label": "1K Hunting"
                            },
                            {
                                "value": 42,
                                "label": "Assorted Caps"
                            },
                            {
                                "value": 216,
                                "label": "Scipio Tactical Cap Asst"
                            },
                            {
                                "value": 266,
                                "label": "Bco Cap Assorted"
                            },
                            {
                                "value": 373,
                                "label": "Fierce Mossy Oak Cap 36 Pc Pdq"
                            },
                            {
                                "value": 374,
                                "label": "Skullz Deer Logo Embroidery Bottomland"
                            },
                            {
                                "value": 375,
                                "label": "Skullz Deer Logo Embroidery Break Up Cou"
                            },
                            {
                                "value": 376,
                                "label": "Outdoor Logo Embroidery Bottomland"
                            },
                            {
                                "value": 377,
                                "label": "Outdoor Logo Embroidery Break Up Country"
                            },
                            {
                                "value": 411,
                                "label": "Scipio Tactical Cap Camo"
                            },
                            {
                                "value": 412,
                                "label": "Scipio Tactical Cap Black"
                            },
                            {
                                "value": 413,
                                "label": "Diesel Life Cap Flex Trucker Blk Wh Grn"
                            },
                            {
                                "value": 414,
                                "label": "Diesel Life Cap Snapback Char Grn Grn"
                            },
                            {
                                "value": 415,
                                "label": "Diesel Life Cap Snap Back Char Org Norng"
                            },
                            {
                                "value": 416,
                                "label": "Diesel Life Cap Snap Back Char Blk Red"
                            },
                            {
                                "value": 417,
                                "label": "Diesel Life Cap Snap Back Camo Blk Norg"
                            },
                            {
                                "value": 418,
                                "label": "Diesel Life Cap Tower Loves 64 Asst"
                            },
                            {
                                "value": 445,
                                "label": "Deer Flag Embroidery Bottomland"
                            },
                            {
                                "value": 446,
                                "label": "Deer Flag Embroidery Break Up Country"
                            },
                            {
                                "value": 447,
                                "label": "American Flag Leather Patch Bottomland"
                            },
                            {
                                "value": 448,
                                "label": "American Flag Leather Patch Break Up Cou"
                            },
                            {
                                "value": 452,
                                "label": "Cap Big Rig Assortment"
                            }
                        ]
                    },
                    {
                        "value": "Jackets",
                        "label": "Jackets",
                        "children": [
                            {
                                "value": 566,
                                "label": "Camo Burly Tan Jacket Reg"
                            },
                            {
                                "value": 567,
                                "label": "Camo Burly Tan Jacket Bigs"
                            }
                        ]
                    },
                    {
                        "value": "Knives",
                        "label": "Knives",
                        "children": [
                            {
                                "value": 227,
                                "label": "Folding Pocket Knife"
                            },
                            {
                                "value": 228,
                                "label": "Carabiner Folding Camo Knife"
                            },
                            {
                                "value": 229,
                                "label": "Folding Camo Pocket Knife"
                            },
                            {
                                "value": 480,
                                "label": "Ss Blk Blade W Red Skulls Hand"
                            },
                            {
                                "value": 481,
                                "label": "Ss Blade Green Camo Handle"
                            },
                            {
                                "value": 482,
                                "label": "Ss Blk Blade Neon Green Handle"
                            }
                        ]
                    },
                    {
                        "value": "Misc Clothing",
                        "label": "Misc Clothing",
                        "children": [
                            {
                                "value": 529,
                                "label": "Tx Pink Charcoal Hoodie"
                            },
                            {
                                "value": 530,
                                "label": "Tx Block Hoodie Blk Camo"
                            },
                            {
                                "value": 531,
                                "label": "Tx Charcoal Black Hoodie"
                            },
                            {
                                "value": 532,
                                "label": "Az Pink Charcoal Hoodie"
                            },
                            {
                                "value": 533,
                                "label": "Nm Charcoal Black Hoodie"
                            },
                            {
                                "value": 534,
                                "label": "Az Pink Charcoal Hoodie"
                            },
                            {
                                "value": 535,
                                "label": "Ia Pink Charcoal Hoodie"
                            },
                            {
                                "value": 536,
                                "label": "Ne Pink Charcoal Hoodie"
                            },
                            {
                                "value": 537,
                                "label": "Ia Charcoal Black Hoodie"
                            },
                            {
                                "value": 538,
                                "label": "Ne Charcoal Black Hoodie"
                            },
                            {
                                "value": 562,
                                "label": "Hi Vis Lg Hoodie Bulk"
                            },
                            {
                                "value": 563,
                                "label": "Hi Vis Xl Hoodie Bulk"
                            },
                            {
                                "value": 564,
                                "label": "Hi Vis 3X Hoodie Bulk"
                            },
                            {
                                "value": 565,
                                "label": "Hi Vis 4X Hoodie Bulk"
                            }
                        ]
                    },
                    {
                        "value": "Novelties",
                        "label": "Novelties",
                        "children": [
                            {
                                "value": 189,
                                "label": "Paw Patrol Watch"
                            },
                            {
                                "value": 190,
                                "label": "Minnie Watch"
                            },
                            {
                                "value": 191,
                                "label": "Trolls Watch"
                            },
                            {
                                "value": 193,
                                "label": "The Classics"
                            },
                            {
                                "value": 194,
                                "label": "The Beach Buddies"
                            },
                            {
                                "value": 200,
                                "label": "Bottle Opener Sunglasses 24Pc Pdq"
                            },
                            {
                                "value": 396,
                                "label": "Large Pearl Viking Horn"
                            },
                            {
                                "value": 397,
                                "label": "Xxl Carved Drinking Horn Tankard"
                            },
                            {
                                "value": 398,
                                "label": "20Oz Xl Drinking Horn Tankard"
                            },
                            {
                                "value": 399,
                                "label": "Mead Cup"
                            },
                            {
                                "value": 400,
                                "label": "Large Natural Finish Horn With Stand"
                            },
                            {
                                "value": 401,
                                "label": "Xl Natural Finish Horn With Stand"
                            },
                            {
                                "value": 402,
                                "label": "Large Polished Viking Horn With Stand"
                            },
                            {
                                "value": 403,
                                "label": "Xxl 1 Liter Drinking Horn Tankard"
                            },
                            {
                                "value": 434,
                                "label": "Voyager Turntable"
                            },
                            {
                                "value": 435,
                                "label": "Corsair Radio Cd Player"
                            },
                            {
                                "value": 441,
                                "label": "Lvs 12 Pc 6 Blue 6 Red Bats With Balls"
                            },
                            {
                                "value": 469,
                                "label": "Small Tire Skulls"
                            },
                            {
                                "value": 470,
                                "label": "Wildlife Oil Lighter"
                            },
                            {
                                "value": 471,
                                "label": "2Nd Amendment Oil Lighter"
                            },
                            {
                                "value": 472,
                                "label": "Day Of The Dead Oil Lighter"
                            },
                            {
                                "value": 473,
                                "label": "Hiking Stick Asst"
                            },
                            {
                                "value": 474,
                                "label": "Walking Cane Asst"
                            },
                            {
                                "value": 490,
                                "label": "Sugar Skull Opener W Magnet"
                            },
                            {
                                "value": 491,
                                "label": "Bull Skull Kc"
                            },
                            {
                                "value": 492,
                                "label": "Dreamcatcher Earring Chand 1"
                            },
                            {
                                "value": 493,
                                "label": "Dreamcatcher Earring Dangle"
                            },
                            {
                                "value": 494,
                                "label": "Dreamcatcher Earring Sun"
                            },
                            {
                                "value": 495,
                                "label": "Dreamcatcher Earring Beads"
                            },
                            {
                                "value": 496,
                                "label": "Dreamcatcher Earring Chand 2"
                            },
                            {
                                "value": 497,
                                "label": "Dreamcatcher Earring Eye"
                            },
                            {
                                "value": 514,
                                "label": "Usa Flag 42In Pallet"
                            },
                            {
                                "value": 517,
                                "label": "Wood Sign You Are My Sunshine"
                            },
                            {
                                "value": 518,
                                "label": "Wood Sign Love Moon Back"
                            },
                            {
                                "value": 519,
                                "label": "Wood Sign You Are Amazing"
                            },
                            {
                                "value": 520,
                                "label": "Wood Sign Wine Me Up"
                            },
                            {
                                "value": 522,
                                "label": "Painted Horseshoe Asmt 1"
                            },
                            {
                                "value": 539,
                                "label": "Dream Catcher Heart Kc"
                            },
                            {
                                "value": 540,
                                "label": "Tree Of Life Glass Kc"
                            },
                            {
                                "value": 541,
                                "label": "Tree Of Life Wire Kc"
                            },
                            {
                                "value": 543,
                                "label": "Mario Super Fun Shades"
                            },
                            {
                                "value": 544,
                                "label": "Camper Coffee Mugs"
                            },
                            {
                                "value": 545,
                                "label": "Camper Lotion Dispenser"
                            },
                            {
                                "value": 546,
                                "label": "Happy Camper Cookie Jar"
                            },
                            {
                                "value": 547,
                                "label": "Metal Garden Pail Medium"
                            },
                            {
                                "value": 548,
                                "label": "Decal Asst Skull W Hat Kc"
                            },
                            {
                                "value": 549,
                                "label": "Pvc Skull W Headphone Asst Kc"
                            },
                            {
                                "value": 550,
                                "label": "Cowboy Boot Key Chain"
                            },
                            {
                                "value": 551,
                                "label": "Macrame Kc 1"
                            },
                            {
                                "value": 552,
                                "label": "Macrame Kc 2"
                            },
                            {
                                "value": 553,
                                "label": "Decal Skull Mug"
                            },
                            {
                                "value": 554,
                                "label": "Decal Skull Goblet"
                            },
                            {
                                "value": 555,
                                "label": "Decal Skull Btl Opener"
                            },
                            {
                                "value": 556,
                                "label": "Decal Skull Btl Opener Magnet"
                            },
                            {
                                "value": 558,
                                "label": "Album Cover Kiss Asst"
                            },
                            {
                                "value": 559,
                                "label": "Album Cover Aerosmith Asst"
                            },
                            {
                                "value": 560,
                                "label": "Bottle Opener Plaque Beverage Asst"
                            },
                            {
                                "value": 561,
                                "label": "Bottle Opener Plaque Saying Novelty Asst"
                            },
                            {
                                "value": 568,
                                "label": "Glass Baby Elephant"
                            },
                            {
                                "value": 569,
                                "label": "Glass Sea Turtle"
                            },
                            {
                                "value": 570,
                                "label": "Glass Shark"
                            },
                            {
                                "value": 571,
                                "label": "Metal Guitar Ii"
                            },
                            {
                                "value": 578,
                                "label": "Glass Owl"
                            },
                            {
                                "value": 579,
                                "label": "Glass Turtle"
                            },
                            {
                                "value": 580,
                                "label": "Glass Frog"
                            },
                            {
                                "value": 581,
                                "label": "Glass Elephant"
                            },
                            {
                                "value": 582,
                                "label": "Metal Sign Auto Asst 1"
                            },
                            {
                                "value": 583,
                                "label": "Metal Sign Auto Asst 2"
                            },
                            {
                                "value": 584,
                                "label": "Metal Sign Personality"
                            },
                            {
                                "value": 585,
                                "label": "Metal Sign Americanagun"
                            },
                            {
                                "value": 586,
                                "label": "Metal Sign Beverage"
                            },
                            {
                                "value": 587,
                                "label": "Metal Sign Adult Beverage"
                            },
                            {
                                "value": 588,
                                "label": "Metal Sign Super Hero"
                            },
                            {
                                "value": 589,
                                "label": "Magnet Vintage Beverage"
                            },
                            {
                                "value": 590,
                                "label": "Magnet Vintage Garage"
                            },
                            {
                                "value": 591,
                                "label": "Magnet Vintage Celebrity"
                            },
                            {
                                "value": 592,
                                "label": "Magnet Vintage Gun"
                            },
                            {
                                "value": 594,
                                "label": "Hay Bale 18In"
                            },
                            {
                                "value": 599,
                                "label": "Bullet S And P Set"
                            },
                            {
                                "value": 600,
                                "label": "Windmill S And P Set"
                            },
                            {
                                "value": 611,
                                "label": "Mini Decal Skull Kc"
                            },
                            {
                                "value": 612,
                                "label": "Metal Garden Pail Small"
                            },
                            {
                                "value": 613,
                                "label": "Plastic Flower In Planter"
                            },
                            {
                                "value": 614,
                                "label": "White Gray Wood Lantern"
                            },
                            {
                                "value": 615,
                                "label": "Metal Garden Pail Large"
                            },
                            {
                                "value": 616,
                                "label": "Sun W Moon Stars Wall Hanging"
                            },
                            {
                                "value": 617,
                                "label": "Nature Planter Bucket Large"
                            },
                            {
                                "value": 618,
                                "label": "Poly Baby Cow"
                            },
                            {
                                "value": 619,
                                "label": "10X5 Cut Out Home"
                            },
                            {
                                "value": 620,
                                "label": "22X17 Truck Door Wall Hanging"
                            },
                            {
                                "value": 621,
                                "label": "7X6 Life Is Sweet Wood Block"
                            },
                            {
                                "value": 622,
                                "label": "7X6 Today Is A Good Day"
                            },
                            {
                                "value": 623,
                                "label": "Wood Cabin Block Asst Sign"
                            },
                            {
                                "value": 624,
                                "label": "Nature Planter Bucket Small"
                            },
                            {
                                "value": 625,
                                "label": "Owl Assorted Sp Set"
                            },
                            {
                                "value": 626,
                                "label": "Ceramic Piggy Sp Set"
                            },
                            {
                                "value": 627,
                                "label": "Punch Tin Table Tray"
                            },
                            {
                                "value": 628,
                                "label": "Cowhide Top Handle Purse Asst."
                            },
                            {
                                "value": 629,
                                "label": "Cowhide W Cork Purse Asst."
                            },
                            {
                                "value": 630,
                                "label": "Clutch Wallet Tri Fold Asst."
                            },
                            {
                                "value": 631,
                                "label": "Eagle Statue"
                            },
                            {
                                "value": 632,
                                "label": "Home Windmill Table Dcor"
                            },
                            {
                                "value": 633,
                                "label": "Windmill Water Tower Welcome"
                            },
                            {
                                "value": 634,
                                "label": "Tractor Sp Holder"
                            },
                            {
                                "value": 635,
                                "label": "Cowstack Sp Holder"
                            },
                            {
                                "value": 636,
                                "label": "Rooster Sunflower Sp Set"
                            },
                            {
                                "value": 637,
                                "label": "Pig Wheelbarrow Sp Holder"
                            },
                            {
                                "value": 638,
                                "label": "Windmill Vase"
                            },
                            {
                                "value": 639,
                                "label": "Van Succulent Decoration"
                            },
                            {
                                "value": 640,
                                "label": "Camper Wsucculent Decoration"
                            },
                            {
                                "value": 641,
                                "label": "Metal Sitting Pig Statue"
                            },
                            {
                                "value": 642,
                                "label": "Metal Cow Flower Pot"
                            },
                            {
                                "value": 643,
                                "label": "Metal Rooster"
                            },
                            {
                                "value": 644,
                                "label": "Pig Flower Pot"
                            },
                            {
                                "value": 645,
                                "label": "Blue Mason Shape Sp Set"
                            },
                            {
                                "value": 646,
                                "label": "Blue Mason Jar Shape Cookie Jar"
                            },
                            {
                                "value": 647,
                                "label": "Blue Mason Jar Mug"
                            },
                            {
                                "value": 648,
                                "label": "Red Mason Jar Spoon Rest W Spoon"
                            },
                            {
                                "value": 649,
                                "label": "Red Mason Jar Water Pitcher"
                            },
                            {
                                "value": 650,
                                "label": "Red Mason Jar Soap Dispenser"
                            },
                            {
                                "value": 651,
                                "label": "Spoon Rest Lets Go Camping"
                            },
                            {
                                "value": 652,
                                "label": "Wood Frame Family Plaid Asst Sign"
                            },
                            {
                                "value": 653,
                                "label": "Blue Mason Jar Soap Dispenser"
                            },
                            {
                                "value": 654,
                                "label": "Blue Mason Jar Spoon Rest W Spoon"
                            },
                            {
                                "value": 655,
                                "label": "Roosters"
                            },
                            {
                                "value": 656,
                                "label": "Rooster Sp"
                            },
                            {
                                "value": 657,
                                "label": "Silver Camper Sp Set"
                            },
                            {
                                "value": 658,
                                "label": "Metal Flutedwatering Can"
                            },
                            {
                                "value": 659,
                                "label": "Snow Owl Poly Statue"
                            },
                            {
                                "value": 660,
                                "label": "Metal White Owl Statue"
                            },
                            {
                                "value": 661,
                                "label": "Plastic Flower In Planter"
                            },
                            {
                                "value": 663,
                                "label": "Dream Catcher Round C"
                            },
                            {
                                "value": 746,
                                "label": "Srp Small Sticker 2.99"
                            },
                            {
                                "value": 747,
                                "label": "Srp Large Sticker 4.99"
                            },
                            {
                                "value": 766,
                                "label": "Srp Hot Buys 7 Assortment"
                            },
                            {
                                "value": 767,
                                "label": "Srp Hot Buys 3 Assortment"
                            },
                            {
                                "value": 768,
                                "label": "Srp Hot Buys 5 Assortment"
                            },
                            {
                                "value": 769,
                                "label": "Srp Hot Buys 10 Assortment"
                            },
                            {
                                "value": 869,
                                "label": "24.99 Assorted Torch Lighter"
                            },
                            {
                                "value": 870,
                                "label": "17.99 Assorted Torch Lighter"
                            },
                            {
                                "value": 871,
                                "label": "34.99 Blue Torch Lighter"
                            },
                            {
                                "value": 872,
                                "label": "19.99 Assorted Torch Lighter"
                            },
                            {
                                "value": 873,
                                "label": "34.99 Grey Torch Lighter"
                            },
                            {
                                "value": 901,
                                "label": "Torch Lighter Double Color"
                            },
                            {
                                "value": 902,
                                "label": "Torch Lighter Side Torch"
                            }
                        ]
                    },
                    {
                        "value": "Other Gm",
                        "label": "Other Gm",
                        "children": [
                            {
                                "value": 195,
                                "label": "Keyguard Black Matte"
                            },
                            {
                                "value": 196,
                                "label": "Mace Mini Pepper Asst 12Pc Ct Pdq"
                            },
                            {
                                "value": 231,
                                "label": "28Oz Double Compartment"
                            },
                            {
                                "value": 386,
                                "label": "Keyguard Pink Matte"
                            },
                            {
                                "value": 410,
                                "label": "Scipio Neck Gaiter Printed Left Side"
                            }
                        ]
                    },
                    {
                        "value": "Plush Toys",
                        "label": "Plush Toys",
                        "children": [
                            {
                                "value": 233,
                                "label": "5 Inch Squishmallows"
                            },
                            {
                                "value": 234,
                                "label": "5 Inch Squishmallows"
                            },
                            {
                                "value": 235,
                                "label": "5 Inch Squishmallows"
                            },
                            {
                                "value": 359,
                                "label": "5 Squishmallow 6 Asst"
                            },
                            {
                                "value": 360,
                                "label": "5 Squishmallow 6 Asst"
                            },
                            {
                                "value": 361,
                                "label": "5 Squishmallow  6 Asst"
                            },
                            {
                                "value": 362,
                                "label": "5 Squishmallow Cats"
                            },
                            {
                                "value": 363,
                                "label": "5 Squsihmallow Sleepy Eye"
                            },
                            {
                                "value": 364,
                                "label": "5 Squishmallow Food Mix B"
                            },
                            {
                                "value": 365,
                                "label": "5In Dog Squishamallow Assortment 4 Asst"
                            },
                            {
                                "value": 366,
                                "label": "Zuru Rainbocornsplushmixseries 1 Ds"
                            },
                            {
                                "value": 368,
                                "label": "5 Inch Squishmallow Refill Kit"
                            },
                            {
                                "value": 406,
                                "label": "5 Jungle Squishmallow Assortment 4 Asst"
                            },
                            {
                                "value": 839,
                                "label": "Rocker Llama 18.5In 2 Asst"
                            },
                            {
                                "value": 840,
                                "label": "Rocker Llama 26In 2 Asst"
                            },
                            {
                                "value": 841,
                                "label": "Rocker Llama 31In 2 Asst"
                            },
                            {
                                "value": 842,
                                "label": "24In 3 Asst. Narwhals"
                            },
                            {
                                "value": 843,
                                "label": "Pearl 17In 2 Asst. Penguins"
                            },
                            {
                                "value": 844,
                                "label": "Dookie 21In Pooping Unicorn"
                            },
                            {
                                "value": 845,
                                "label": "125In 3 Asst. Sequinsnakes"
                            },
                            {
                                "value": 846,
                                "label": "Lennie 21In 2 Asst. Goats"
                            },
                            {
                                "value": 847,
                                "label": "George 21In Donkey"
                            },
                            {
                                "value": 848,
                                "label": "Gordy 20In Cow"
                            },
                            {
                                "value": 849,
                                "label": "Cecil 20In Pig"
                            },
                            {
                                "value": 850,
                                "label": "Treasure 20In Giraffe"
                            },
                            {
                                "value": 851,
                                "label": "Dodger 38In Shark"
                            },
                            {
                                "value": 852,
                                "label": "Misty 36In Dolphin"
                            },
                            {
                                "value": 853,
                                "label": "Lefty 16.5In 2 Asst. Shaggy Llamas"
                            },
                            {
                                "value": 854,
                                "label": "Lefty 25.5In 2 Asst. Shaggy Llamas"
                            },
                            {
                                "value": 855,
                                "label": "Lefty 32In 2 Asst. Shaggy Llamas"
                            },
                            {
                                "value": 856,
                                "label": "31In Cuddle Elephant"
                            },
                            {
                                "value": 857,
                                "label": "Fuzzy Folk Abe 16In Bb Turtle"
                            },
                            {
                                "value": 858,
                                "label": "12In X 12In Plush With 2 Zebra"
                            },
                            {
                                "value": 859,
                                "label": "12In X 12In Plush With 2 Tiger"
                            },
                            {
                                "value": 860,
                                "label": "12In X 12In Plush With 2 Penguin"
                            },
                            {
                                "value": 861,
                                "label": "12In X 12In Plush With 2 Shark"
                            },
                            {
                                "value": 862,
                                "label": "11In Standing Rainbow Shaggy Llama"
                            },
                            {
                                "value": 863,
                                "label": "12 X 12 Dolphin Print Pillow"
                            },
                            {
                                "value": 926,
                                "label": "Gordo18In 2 Asst. Gorillas"
                            },
                            {
                                "value": 927,
                                "label": "Mac 20In 2 Asst. Longtail Monkeys"
                            },
                            {
                                "value": 928,
                                "label": "Mac 24In 2 Asst. Long Tail Monkeys"
                            },
                            {
                                "value": 929,
                                "label": "Alice 20In 2Asst. Long Tail Unicorns"
                            },
                            {
                                "value": 930,
                                "label": "Iridescent Light 19.5In 2 Asst. Pandas"
                            },
                            {
                                "value": 931,
                                "label": "19.5 2 Asst. Elephants"
                            },
                            {
                                "value": 932,
                                "label": "Comic Sparkles 20In 2 Asst. Giraffes"
                            },
                            {
                                "value": 933,
                                "label": "Rainbow Sherbet 32In Cuddle Bear"
                            },
                            {
                                "value": 934,
                                "label": "19.5In 2 Asst Sitting Animals"
                            },
                            {
                                "value": 935,
                                "label": "Skip 19.5In 2 Asst. Sitting Dogs"
                            },
                            {
                                "value": 936,
                                "label": "Donny 23In 4 Asst. Printed Dinos"
                            },
                            {
                                "value": 937,
                                "label": "Donny 27In 4 Asst. Printed Dinos"
                            },
                            {
                                "value": 938,
                                "label": "Donny 32In 4 Asst. Printed Dinos"
                            },
                            {
                                "value": 939,
                                "label": "20In Rainbow Sherbet Unicorn"
                            },
                            {
                                "value": 940,
                                "label": "20In Rainbow Sherbet Bear"
                            },
                            {
                                "value": 941,
                                "label": "20In Rainbow Sherbet Monkey"
                            }
                        ]
                    },
                    {
                        "value": "Seasonal",
                        "label": "Seasonal",
                        "children": [
                            {
                                "value": 475,
                                "label": "Cool Rag"
                            },
                            {
                                "value": 476,
                                "label": "Hivis Ear Plugs"
                            },
                            {
                                "value": 483,
                                "label": "Brown Mayfly 2Pk"
                            },
                            {
                                "value": 484,
                                "label": "Wooly Bugger Fly 2Pk"
                            },
                            {
                                "value": 485,
                                "label": "1.75In Bobbers 2Pk"
                            },
                            {
                                "value": 486,
                                "label": "Telescopic Spinning Combo W Kit"
                            },
                            {
                                "value": 487,
                                "label": "Telescopic Spincast Combo W Kit"
                            },
                            {
                                "value": 488,
                                "label": "Snelled Hook Size 6"
                            },
                            {
                                "value": 489,
                                "label": "Lures Asst Colors"
                            },
                            {
                                "value": 521,
                                "label": "Disposable Ear Plugs 10Pk"
                            },
                            {
                                "value": 593,
                                "label": "18In 50A To 30A Rv Dogbone Adapter"
                            },
                            {
                                "value": 601,
                                "label": "1.25In Bobbers 3 Pk"
                            },
                            {
                                "value": 602,
                                "label": "Snap Swivel 6Pk Size 3"
                            },
                            {
                                "value": 603,
                                "label": "Snap Swivel 6Pk Size 4"
                            },
                            {
                                "value": 604,
                                "label": "Snap Swivel 6Pk Size 5"
                            },
                            {
                                "value": 605,
                                "label": "Large Sinkers"
                            },
                            {
                                "value": 606,
                                "label": "Value Pack Swivels"
                            },
                            {
                                "value": 607,
                                "label": "Value Pack Sinkers"
                            },
                            {
                                "value": 608,
                                "label": "Adams Fly 2Pk"
                            },
                            {
                                "value": 609,
                                "label": "Royal Coachman Fly 2Pk"
                            },
                            {
                                "value": 610,
                                "label": "Black Gnat Fly 2Pk"
                            },
                            {
                                "value": 689,
                                "label": "Off Deep Woods Insect Repellent Towelet"
                            }
                        ]
                    },
                    {
                        "value": "Shirts",
                        "label": "Shirts",
                        "children": [
                            {
                                "value": 120,
                                "label": "Tee Eagle Country Navy 2X"
                            },
                            {
                                "value": 121,
                                "label": "Tee Ride Til I Die Black M"
                            },
                            {
                                "value": 122,
                                "label": "Tee Ride Til I Die Black Xl"
                            },
                            {
                                "value": 123,
                                "label": "Tee Ride Til I Die Black 2X"
                            },
                            {
                                "value": 124,
                                "label": "Tee Ride Til I Die Black 3X"
                            },
                            {
                                "value": 125,
                                "label": "Tee Midnight Rider Black M"
                            },
                            {
                                "value": 126,
                                "label": "Tee Midnight Rider Black L"
                            },
                            {
                                "value": 127,
                                "label": "Tee Midnight Rider Black Xl"
                            },
                            {
                                "value": 128,
                                "label": "Tee Midnight Rider Black 3X"
                            },
                            {
                                "value": 129,
                                "label": "Tee Truckin Black M"
                            },
                            {
                                "value": 130,
                                "label": "Tee Truckin Black L"
                            },
                            {
                                "value": 131,
                                "label": "Tee Truckin Black Xl"
                            },
                            {
                                "value": 132,
                                "label": "Tee Wolf Country Charcoal M"
                            },
                            {
                                "value": 133,
                                "label": "Tee Wolf Country Charcoal L"
                            },
                            {
                                "value": 134,
                                "label": "Tee Wolf Country Charcoal 3X"
                            },
                            {
                                "value": 135,
                                "label": "Tee Skull Wolf Black M"
                            },
                            {
                                "value": 136,
                                "label": "Tee Skull Wolf Black Xl"
                            },
                            {
                                "value": 137,
                                "label": "Tee Skull Wolf Black 2X"
                            },
                            {
                                "value": 138,
                                "label": "Tee Skull Black L"
                            },
                            {
                                "value": 139,
                                "label": "Tee Skull Black Xl"
                            },
                            {
                                "value": 140,
                                "label": "Tee Skull Black 3X"
                            },
                            {
                                "value": 141,
                                "label": "Tee Freedom Eagle Black M"
                            },
                            {
                                "value": 142,
                                "label": "Tee Freedom Eagle Black L"
                            },
                            {
                                "value": 143,
                                "label": "Tee Freedom Eagle Black Xl"
                            },
                            {
                                "value": 144,
                                "label": "Tee Freedom Isn T Free Black Xl"
                            },
                            {
                                "value": 145,
                                "label": "Tee Freedom Isn T Free Black 2X"
                            },
                            {
                                "value": 146,
                                "label": "Tee Freedom Isn T Free Black 3X"
                            },
                            {
                                "value": 147,
                                "label": "Tee Death Howl Black M"
                            },
                            {
                                "value": 148,
                                "label": "Tee Death Howl Black L"
                            },
                            {
                                "value": 149,
                                "label": "Tee Death Howl Black 2X"
                            },
                            {
                                "value": 150,
                                "label": "Tee Death Howl Black 3X"
                            },
                            {
                                "value": 151,
                                "label": "Tee Fully Loaded Black M"
                            },
                            {
                                "value": 152,
                                "label": "Tee Fully Loaded Black L"
                            },
                            {
                                "value": 153,
                                "label": "Tee Full Throttle Black L"
                            },
                            {
                                "value": 154,
                                "label": "Tee Full Throttle Black 2X"
                            },
                            {
                                "value": 155,
                                "label": "Tee Full Throttle Black 3X"
                            },
                            {
                                "value": 156,
                                "label": "Tee Freedom Trucker Navy M"
                            },
                            {
                                "value": 157,
                                "label": "Tee Freedom Trucker Navy L"
                            },
                            {
                                "value": 158,
                                "label": "Tee Freedom Trucker Navy Xl"
                            },
                            {
                                "value": 268,
                                "label": "Tee Eagle Country Navy M"
                            },
                            {
                                "value": 269,
                                "label": "Tee Eagle Country Navy L"
                            },
                            {
                                "value": 270,
                                "label": "Tee Eagle Country Navy Xl"
                            },
                            {
                                "value": 289,
                                "label": "Tee Bullet Proof Black M"
                            },
                            {
                                "value": 290,
                                "label": "Tee Bullet Proof Black L"
                            },
                            {
                                "value": 291,
                                "label": "Tee Bullet Proof Black Xl"
                            },
                            {
                                "value": 292,
                                "label": "Tee Bullet Proof Black 2X"
                            },
                            {
                                "value": 293,
                                "label": "Tee Bullet Proof Black 3X"
                            },
                            {
                                "value": 294,
                                "label": "Tee Live Fast Black M"
                            },
                            {
                                "value": 295,
                                "label": "Tee Live Fast Black 2X"
                            },
                            {
                                "value": 296,
                                "label": "Tee Live Fast Black 3X"
                            },
                            {
                                "value": 297,
                                "label": "Tee Hammer Down Black M"
                            },
                            {
                                "value": 298,
                                "label": "Tee Hammer Down Black L"
                            },
                            {
                                "value": 299,
                                "label": "Tee Hammer Down Black Xl"
                            },
                            {
                                "value": 300,
                                "label": "Tee Hammer Down Black 2X"
                            },
                            {
                                "value": 333,
                                "label": "Tee Fully Loaded Black Xl"
                            },
                            {
                                "value": 334,
                                "label": "Tee Fully Loaded Black 2X"
                            },
                            {
                                "value": 335,
                                "label": "Tee Fully Loaded Black 3X"
                            },
                            {
                                "value": 336,
                                "label": "Tee American Rider Black M"
                            },
                            {
                                "value": 337,
                                "label": "Tee Wolf Pack Black M"
                            },
                            {
                                "value": 338,
                                "label": "Tee Wolf Pack Black L"
                            },
                            {
                                "value": 339,
                                "label": "Tee Hell Wolf Black M"
                            },
                            {
                                "value": 340,
                                "label": "Tee Hell Wolf Black L"
                            },
                            {
                                "value": 341,
                                "label": "Tee Hell Wolf Black 2X"
                            }
                        ]
                    },
                    {
                        "value": "Sunglasses",
                        "label": "Sunglasses",
                        "children": [
                            {
                                "value": 715,
                                "label": "Pugs 360 Readers 1.5"
                            },
                            {
                                "value": 716,
                                "label": "Pugs 360 Readers 2.0"
                            },
                            {
                                "value": 717,
                                "label": "Pugs 360 Readers 2.5"
                            },
                            {
                                "value": 718,
                                "label": "Pugs 360 Readers 3.0"
                            },
                            {
                                "value": 749,
                                "label": "A12 Active Sunglasses"
                            },
                            {
                                "value": 750,
                                "label": "F8 Fashion Sunglasses"
                            },
                            {
                                "value": 751,
                                "label": "L10 Lifestyle Sunglasses"
                            },
                            {
                                "value": 752,
                                "label": "C10 Classic Sunglasses"
                            },
                            {
                                "value": 753,
                                "label": "M4 Metal Sunglasses"
                            },
                            {
                                "value": 754,
                                "label": "L6 Lifestyle Sunglasses"
                            },
                            {
                                "value": 755,
                                "label": "Pr22 Premium Plus Sunglasses"
                            },
                            {
                                "value": 756,
                                "label": "A4 Active Sunglasses"
                            },
                            {
                                "value": 757,
                                "label": "M6 Metal Sunglasses"
                            },
                            {
                                "value": 758,
                                "label": "T4 Trend Sunglasses"
                            },
                            {
                                "value": 759,
                                "label": "Rp1 Ripper Sunglasses"
                            },
                            {
                                "value": 760,
                                "label": "Rp3 Ripper Sunglasses"
                            },
                            {
                                "value": 761,
                                "label": "Rp4 Ripper Sunglasses"
                            },
                            {
                                "value": 762,
                                "label": "Pf1 Flex Sunglasses"
                            },
                            {
                                "value": 763,
                                "label": "Pf2 Flex Sunglasses"
                            },
                            {
                                "value": 764,
                                "label": "T1 Trend Sunglasses"
                            },
                            {
                                "value": 765,
                                "label": "A1 Active Sunglasses"
                            },
                            {
                                "value": 770,
                                "label": "Pugssg Nowstalgia Ns1"
                            },
                            {
                                "value": 771,
                                "label": "Pugssg Nowstalgia Ns2 Blk"
                            },
                            {
                                "value": 772,
                                "label": "Pugssg Nowstalgia Ns2 Cmp"
                            },
                            {
                                "value": 773,
                                "label": "Pugssg Nowstalgia Ns3"
                            },
                            {
                                "value": 774,
                                "label": "Pugssg Nowstalgia Ns4 Dem"
                            },
                            {
                                "value": 791,
                                "label": "Pugs 3Pk Reader 1.5"
                            },
                            {
                                "value": 792,
                                "label": "Pugs 3Pk Reader 2.0"
                            },
                            {
                                "value": 793,
                                "label": "Pugs 3Pk Reader 2.5"
                            },
                            {
                                "value": 794,
                                "label": "Pugs 3Pk Reader 3.0"
                            },
                            {
                                "value": 795,
                                "label": "Mil Tac 152 A"
                            },
                            {
                                "value": 820,
                                "label": "Pugssg Nowstalgia Ns4 Blk"
                            },
                            {
                                "value": 821,
                                "label": "Pugssg Nowstalgia Ns5 Blk"
                            },
                            {
                                "value": 822,
                                "label": "Pugssg Nowstalgia Ns5 Clr"
                            },
                            {
                                "value": 823,
                                "label": "Pugssg Nowstalgia Ns6"
                            },
                            {
                                "value": 824,
                                "label": "Pugssg Nowstalgia Ns7"
                            },
                            {
                                "value": 825,
                                "label": "Pugssg Nowstalgia Ns8"
                            },
                            {
                                "value": 826,
                                "label": "Pugssg Nowstalgia Ns9"
                            },
                            {
                                "value": 864,
                                "label": "Mil Tac 152 C"
                            },
                            {
                                "value": 865,
                                "label": "Mil Tac 152"
                            },
                            {
                                "value": 866,
                                "label": "Polarized Nd 2 19.99"
                            },
                            {
                                "value": 867,
                                "label": "Polarized Nd 3 19.99"
                            },
                            {
                                "value": 868,
                                "label": "Polarized Nd 4 19.99"
                            },
                            {
                                "value": 874,
                                "label": "F2 Fashion Sunglass"
                            },
                            {
                                "value": 942,
                                "label": "Nd 1 Night Driver 17.99"
                            },
                            {
                                "value": 943,
                                "label": "Nd 2 Night Driver 17.99"
                            },
                            {
                                "value": 944,
                                "label": "Nd 3 Night Driver 17.99"
                            },
                            {
                                "value": 945,
                                "label": "Nd 4 Night Driver 17.99"
                            }
                        ]
                    },
                    {
                        "value": "Toys",
                        "label": "Toys",
                        "children": [
                            {
                                "value": 160,
                                "label": "My Arcade Plug N Play Cntlr"
                            },
                            {
                                "value": 169,
                                "label": "Reusable Sticker Pad Habitats"
                            },
                            {
                                "value": 170,
                                "label": "Reusable Sticker Pad Vehicles"
                            },
                            {
                                "value": 171,
                                "label": "Jumbo Coloring Pad Animal"
                            },
                            {
                                "value": 172,
                                "label": "Jumbo Coloring Pad Vehicles"
                            },
                            {
                                "value": 173,
                                "label": "Jumbo Coloring Pad Pink 11 X 14"
                            },
                            {
                                "value": 174,
                                "label": "Reusable Sticker Pad Princess Castle"
                            },
                            {
                                "value": 175,
                                "label": "Colorblast Princess"
                            },
                            {
                                "value": 176,
                                "label": "Colorblast Dinosaur"
                            },
                            {
                                "value": 177,
                                "label": "Water Wow Animals"
                            },
                            {
                                "value": 178,
                                "label": "Water Wow Alphabet"
                            },
                            {
                                "value": 179,
                                "label": "Make A Face Sticker Pad Crazy Animals"
                            },
                            {
                                "value": 180,
                                "label": "Reusable Sticker Pad My Town"
                            },
                            {
                                "value": 181,
                                "label": "Reusable Sticker Pad Adventure Pad"
                            },
                            {
                                "value": 182,
                                "label": "Water Wow Fairy Tale"
                            },
                            {
                                "value": 183,
                                "label": "Water Wow Makeup Manicures"
                            },
                            {
                                "value": 184,
                                "label": "Make A Face Princesses"
                            },
                            {
                                "value": 185,
                                "label": "Water Wow Under The Sea Water Reveal"
                            },
                            {
                                "value": 186,
                                "label": "Water Wow Farm Connect The Dots"
                            },
                            {
                                "value": 243,
                                "label": "Wave Runner  2Toned Ball Skips On Water"
                            },
                            {
                                "value": 244,
                                "label": "Wave Runner  Mega Sports Ball"
                            },
                            {
                                "value": 245,
                                "label": "Wave Runner  Galaxy Ball Skips On Water"
                            },
                            {
                                "value": 246,
                                "label": "Wave Runner  Football"
                            },
                            {
                                "value": 247,
                                "label": "Wave Runner  Grip It Ball"
                            },
                            {
                                "value": 259,
                                "label": "Fingerlings 6 Assort"
                            },
                            {
                                "value": 260,
                                "label": "Finn Fingerling Monkey Black"
                            },
                            {
                                "value": 261,
                                "label": "Sophie Fingerling Monkey White"
                            },
                            {
                                "value": 262,
                                "label": "Boris Finglerling Monkey Blue"
                            },
                            {
                                "value": 263,
                                "label": "Mia Fingerling Monkey Purple"
                            },
                            {
                                "value": 264,
                                "label": "Bella Fingerling Monkey Pink"
                            },
                            {
                                "value": 265,
                                "label": "Zoe Fingerling Monkey Turquoise"
                            },
                            {
                                "value": 279,
                                "label": "Toy Rc Robotic Battle Spacebot"
                            },
                            {
                                "value": 305,
                                "label": "L.O.L. Surprise Tiny Toys 1A"
                            },
                            {
                                "value": 306,
                                "label": "Mini Toddler Young Anna"
                            },
                            {
                                "value": 307,
                                "label": "Mini Toddler Young Elsa"
                            },
                            {
                                "value": 308,
                                "label": "Moana"
                            },
                            {
                                "value": 309,
                                "label": "Ariel"
                            },
                            {
                                "value": 310,
                                "label": "Ariel Mermaid"
                            },
                            {
                                "value": 311,
                                "label": "Aurora"
                            },
                            {
                                "value": 312,
                                "label": "Belle Yellow Dress"
                            },
                            {
                                "value": 313,
                                "label": "Belle Blue Dress"
                            },
                            {
                                "value": 314,
                                "label": "Belle Red Dress"
                            },
                            {
                                "value": 315,
                                "label": "Cinderella Blue Dress"
                            },
                            {
                                "value": 316,
                                "label": "Cinderella Pink Dress"
                            },
                            {
                                "value": 317,
                                "label": "Jasmine"
                            },
                            {
                                "value": 318,
                                "label": "Merida"
                            },
                            {
                                "value": 319,
                                "label": "Mulan"
                            },
                            {
                                "value": 320,
                                "label": "Rapunzel"
                            },
                            {
                                "value": 321,
                                "label": "Snow White"
                            },
                            {
                                "value": 322,
                                "label": "Tiana"
                            },
                            {
                                "value": 323,
                                "label": "Elsa Travel Mini Doll"
                            },
                            {
                                "value": 324,
                                "label": "Anna Travel Mini Doll"
                            },
                            {
                                "value": 325,
                                "label": "Elsa Act 1 Mini Doll"
                            },
                            {
                                "value": 326,
                                "label": "Anna Act 1 Mini Doll"
                            },
                            {
                                "value": 327,
                                "label": "Mini Anna Epilogue Doll"
                            },
                            {
                                "value": 328,
                                "label": "Mini Elsa Epilogue Doll"
                            },
                            {
                                "value": 329,
                                "label": "Mini Elsa Purple Nightgown Doll"
                            },
                            {
                                "value": 330,
                                "label": "Mini Kristoff Doll"
                            },
                            {
                                "value": 331,
                                "label": "Mini Sven Doll"
                            },
                            {
                                "value": 332,
                                "label": "Mini Olaf"
                            },
                            {
                                "value": 367,
                                "label": "Worlds Smallest Care Bears Series 2"
                            },
                            {
                                "value": 370,
                                "label": "Worlds Smallest Lava Lamp"
                            },
                            {
                                "value": 379,
                                "label": "Worlds Smallest Nerf Basketball"
                            },
                            {
                                "value": 380,
                                "label": "Worlds Smallest Mattel Viewmaster"
                            },
                            {
                                "value": 381,
                                "label": "Worlds Smallest Operation"
                            },
                            {
                                "value": 382,
                                "label": "Worlds Smallest Cornhole"
                            },
                            {
                                "value": 383,
                                "label": "Worlds Smallest Uno"
                            },
                            {
                                "value": 387,
                                "label": "Worlds Smallest Rubiks Cube"
                            },
                            {
                                "value": 388,
                                "label": "Worlds Smallest Etch A Sketch"
                            },
                            {
                                "value": 389,
                                "label": "Micro Arcade Pacman"
                            },
                            {
                                "value": 390,
                                "label": "Worlds Smallest Magic 8 Ball Tie Dye"
                            },
                            {
                                "value": 391,
                                "label": "Worlds Smallest Slinky Dog"
                            },
                            {
                                "value": 392,
                                "label": "Worlds Smallest Rock Em Sock Em Robots"
                            },
                            {
                                "value": 393,
                                "label": "Worlds Smallest Lincoln Logs"
                            },
                            {
                                "value": 394,
                                "label": "Worlds Smallest Lite Brite"
                            },
                            {
                                "value": 395,
                                "label": "Worlds Smallest Crocodile Dentist Game"
                            },
                            {
                                "value": 407,
                                "label": "Micro Arcade Galaga Pdq"
                            },
                            {
                                "value": 408,
                                "label": "Micro Arcade Frogger Pdq"
                            },
                            {
                                "value": 409,
                                "label": "Micro Arcade Dig Dug Pdq"
                            },
                            {
                                "value": 420,
                                "label": "Lola"
                            },
                            {
                                "value": 421,
                                "label": "Kai"
                            },
                            {
                                "value": 422,
                                "label": "Pablo"
                            },
                            {
                                "value": 423,
                                "label": "Felix"
                            },
                            {
                                "value": 424,
                                "label": "Benji"
                            },
                            {
                                "value": 425,
                                "label": "Ella"
                            },
                            {
                                "value": 426,
                                "label": "Roxy"
                            },
                            {
                                "value": 427,
                                "label": "Jinx"
                            },
                            {
                                "value": 428,
                                "label": "Summer"
                            },
                            {
                                "value": 429,
                                "label": "5 Pack Princess Doll Set 11.5 Inch"
                            },
                            {
                                "value": 430,
                                "label": "Fairy Dolls 11.5 Inch Pdq"
                            },
                            {
                                "value": 431,
                                "label": "11 Battery Operated Press Go Shark"
                            },
                            {
                                "value": 432,
                                "label": "2Pk Offroad Truck Friction Power"
                            },
                            {
                                "value": 433,
                                "label": "Pocahontas"
                            },
                            {
                                "value": 439,
                                "label": "Zuru 5 Surprise Mixed Series 2 24Pc Pdq"
                            },
                            {
                                "value": 442,
                                "label": "Charlie"
                            },
                            {
                                "value": 443,
                                "label": "Deeno"
                            },
                            {
                                "value": 444,
                                "label": "Zuru Cocoplushcones Series 1"
                            },
                            {
                                "value": 694,
                                "label": "Classic Games Display"
                            },
                            {
                                "value": 947,
                                "label": "3.99 Toy"
                            },
                            {
                                "value": 948,
                                "label": "4.99 Toy"
                            },
                            {
                                "value": 949,
                                "label": "6.99 Toy"
                            },
                            {
                                "value": 950,
                                "label": "9.99 Toy"
                            }
                        ]
                    }
                ],
                "map": {
                    "Die-Cast": true,
                    "Drinkware": true,
                    "Fashion Accessories": true,
                    "Gifts / Souvenirs": true,
                    "Hats": true,
                    "Jackets": true,
                    "Knives": true,
                    "Misc Clothing": true,
                    "Novelties": true,
                    "Other Gm": true,
                    "Plush Toys": true,
                    "Seasonal": true,
                    "Shirts": true,
                    "Sunglasses": true,
                    "Toys": true
                }
            },
            {
                "value": "Mobile Technologies",
                "label": "Mobile Technologies",
                "children": [
                    {
                        "value": "Audio",
                        "label": "Audio",
                        "children": [
                            {
                                "value": 197,
                                "label": "Set In Ear Sport Earbud W Mic Vc Gr Cr"
                            },
                            {
                                "value": 248,
                                "label": "Super Slimfit Earbud Hyperlite Black"
                            },
                            {
                                "value": 249,
                                "label": "Superslim Fitting Earbud Hyperlite Wht"
                            },
                            {
                                "value": 372,
                                "label": "Micro True Wireless Pro 48 Pc Floor Disp"
                            },
                            {
                                "value": 440,
                                "label": "Cassette Wireless Cobalt Blue"
                            },
                            {
                                "value": 675,
                                "label": "Hafx103M Xx Pdq"
                            },
                            {
                                "value": 676,
                                "label": "On Ear Foldable Wireless Headphones Carb"
                            },
                            {
                                "value": 677,
                                "label": "JVC Gumy Wired Inner Ear Headphones With"
                            },
                            {
                                "value": 678,
                                "label": "JVC Inner Ear Neck Band Headset Black"
                            },
                            {
                                "value": 679,
                                "label": "Hafx5 Gumy Dump Bin"
                            },
                            {
                                "value": 680,
                                "label": "Hafx5 Gumy Counter Display"
                            },
                            {
                                "value": 681,
                                "label": "Wired Gumy Sport Headset With Mic Black"
                            },
                            {
                                "value": 827,
                                "label": "Tt Ttnm Tws"
                            },
                            {
                                "value": 828,
                                "label": "Tt Ttnm Aux Cbl Spruce"
                            }
                        ]
                    },
                    {
                        "value": "Bluetooth",
                        "label": "Bluetooth",
                        "children": [
                            {
                                "value": 43,
                                "label": "LG Tone Triumph Bt Wireless Headset"
                            },
                            {
                                "value": 44,
                                "label": "LG Tone Style Hbs Sl5 Bt Wireless Hdse"
                            },
                            {
                                "value": 45,
                                "label": "LG Tone Flex Hbs Xl7 Bt Wireless Hdset"
                            },
                            {
                                "value": 46,
                                "label": "LG Tone Style Hbs Sl5 Bt Wireless Hdse"
                            },
                            {
                                "value": 187,
                                "label": "Opencomm Noise Canceling Headset"
                            },
                            {
                                "value": 232,
                                "label": "True Wireless Sportfit Earbuds"
                            },
                            {
                                "value": 665,
                                "label": "JBL Vibe 100Tws Inear Headphones Purple"
                            },
                            {
                                "value": 666,
                                "label": "JBL Endurance Peak Ii Bt Headphones Blue"
                            },
                            {
                                "value": 667,
                                "label": "JBL Tour Pro True Bt Earbuds Anc Black"
                            },
                            {
                                "value": 668,
                                "label": "JBL Tour One Headphone Overear Bt Anc Bk"
                            },
                            {
                                "value": 669,
                                "label": "JBL Tune Onear Bt Headphones 510Bt Black"
                            },
                            {
                                "value": 670,
                                "label": "JBL Tune Onear Bt Headphones 510Bt White"
                            },
                            {
                                "value": 671,
                                "label": "JBL Vibe 100 True Wireless Earbuds Black"
                            },
                            {
                                "value": 672,
                                "label": "JBL Vibe 100 True Wireless Earbuds Blue"
                            },
                            {
                                "value": 673,
                                "label": "JBL Tune 760 Overear Headphone Anc Black"
                            },
                            {
                                "value": 674,
                                "label": "JBL Quantum300 Over Ear Gaming Headphone"
                            },
                            {
                                "value": 682,
                                "label": "JVC True Wireless Open Air Earbuds Black"
                            },
                            {
                                "value": 683,
                                "label": "JVC Marshmallow True Wireless Earbuds Bk"
                            },
                            {
                                "value": 684,
                                "label": "JVC Marshmallow True Wireless Earbuds Wt"
                            },
                            {
                                "value": 685,
                                "label": "JVC True Wireless Open Air Earbuds White"
                            },
                            {
                                "value": 686,
                                "label": "JVC True Wireless Open Air Earbuds Black"
                            },
                            {
                                "value": 687,
                                "label": "JVC True Wireless Open Air Earbuds White"
                            },
                            {
                                "value": 688,
                                "label": "JVC True Wireless W Charging Case"
                            }
                        ]
                    },
                    {
                        "value": "Mobile Accessories",
                        "label": "Mobile Accessories",
                        "children": [
                            {
                                "value": 159,
                                "label": "Auto Vent Cup And Phone Holder"
                            },
                            {
                                "value": 199,
                                "label": "Philips Ac USB Charger 1 Port 1.0A Whi"
                            },
                            {
                                "value": 267,
                                "label": "Dark Marble Spinpop"
                            },
                            {
                                "value": 280,
                                "label": "Roadstar 5 USB Car Charger Hub Blk Qc"
                            },
                            {
                                "value": 281,
                                "label": "5200Mah 2 In 1 Dual USB Portable Batte"
                            },
                            {
                                "value": 282,
                                "label": "Turbo 30W USB C Pd USB C Car Chg Blk"
                            },
                            {
                                "value": 283,
                                "label": "T3 Xtreme 18W USB C Pd2.4A Car Chgr Bl"
                            },
                            {
                                "value": 284,
                                "label": "Power Pad Qi Wireless Fast Charger Blk"
                            },
                            {
                                "value": 288,
                                "label": "Easy One Touch 5 Dash Windshield Mount"
                            },
                            {
                                "value": 354,
                                "label": "Philips USB To Lightning Cable 6Ft Blk"
                            },
                            {
                                "value": 355,
                                "label": "Philips USB To Micro Cable 6Ft Basic B"
                            },
                            {
                                "value": 356,
                                "label": "Philips USB To Lightning Cable 6Ft Pre"
                            },
                            {
                                "value": 357,
                                "label": "Philips Elite C To C Cable 6Ft Braided"
                            },
                            {
                                "value": 358,
                                "label": "Philips Ac USB Charger 1 Port 1.0A Whi"
                            },
                            {
                                "value": 371,
                                "label": "Opencomm Fast Charging Cord"
                            },
                            {
                                "value": 405,
                                "label": "4 In 1 Universal Mounting Kit Shipper"
                            },
                            {
                                "value": 419,
                                "label": "Philips Elite C To C Cable 6Ft Braided"
                            },
                            {
                                "value": 449,
                                "label": "Itap Magnetic 2 Dash Windshield Mount"
                            },
                            {
                                "value": 738,
                                "label": "3 In 1 Micro Cable"
                            },
                            {
                                "value": 739,
                                "label": "Pg 4 9Pin Charge Cbl"
                            },
                            {
                                "value": 740,
                                "label": "Pg 9Pin Car Charger Cbl"
                            },
                            {
                                "value": 741,
                                "label": "Pg Type C Cable"
                            },
                            {
                                "value": 742,
                                "label": "Pg Wireless Earbud Black"
                            },
                            {
                                "value": 743,
                                "label": "Pg Wireless Earbud White"
                            },
                            {
                                "value": 744,
                                "label": "Pg Wireless Earbud Blue"
                            },
                            {
                                "value": 775,
                                "label": "Ttnm USB To Typc Cbl Green"
                            },
                            {
                                "value": 776,
                                "label": "Ttnm USB To Typc Cbl Blue"
                            },
                            {
                                "value": 777,
                                "label": "Ttnm USB To Ltng Cbl Purple"
                            },
                            {
                                "value": 778,
                                "label": "Ttnm USB To Ltng Cbl Blue"
                            },
                            {
                                "value": 779,
                                "label": "Ttnm USB To Ltng Cbl Green"
                            },
                            {
                                "value": 780,
                                "label": "Ttnm Micro Cbl Purple"
                            },
                            {
                                "value": 781,
                                "label": "Ttnm Micro Cbl Blue"
                            },
                            {
                                "value": 782,
                                "label": "Ttnm Micro Cbl Green"
                            },
                            {
                                "value": 783,
                                "label": "Ttnm Typc To Typc Cbl Blue"
                            },
                            {
                                "value": 784,
                                "label": "Ttnm Typc To Typc Cbl Green"
                            },
                            {
                                "value": 785,
                                "label": "Ttnm Typc To Ltng Cbl Blue"
                            },
                            {
                                "value": 786,
                                "label": "Ttnm Typc To Ltng Cbl Green"
                            },
                            {
                                "value": 787,
                                "label": "Ttnm Typc To Typc Cbl Purple"
                            },
                            {
                                "value": 788,
                                "label": "Ttnm Typc To Ltng Cbl Purple"
                            },
                            {
                                "value": 789,
                                "label": "Ttnm Pd Car Chgr Black"
                            },
                            {
                                "value": 790,
                                "label": "Ttnm Dual USB Car Chgr Black"
                            },
                            {
                                "value": 796,
                                "label": "Ttnm Typc To Ltng Cbl Prym1 Freedom"
                            },
                            {
                                "value": 797,
                                "label": "Ttnm Typc To Typc Cbl Prym1 Freedom"
                            },
                            {
                                "value": 798,
                                "label": "Ttnm USB To Ltng Cbl Prym1 Freedom"
                            },
                            {
                                "value": 799,
                                "label": "Ttnm USB To Typc Cbl Prym1 Freedom"
                            },
                            {
                                "value": 800,
                                "label": "Ttnm Micro Cbl Prym1 Freedom"
                            },
                            {
                                "value": 801,
                                "label": "Ttnm Dual USB Car Chgr Prym1 Freedom"
                            },
                            {
                                "value": 802,
                                "label": "Ttnm USB To Ltng Cbl Prym1 Silver Mist"
                            },
                            {
                                "value": 803,
                                "label": "Ttnm USB To Ltng Cbl Prym1 Patriot Blue"
                            },
                            {
                                "value": 804,
                                "label": "Ttnm USB To Typc Cbl Prym1 Silver Mist"
                            },
                            {
                                "value": 805,
                                "label": "Ttnm USB To Typc Cbl Prym1 Patriot Blue"
                            },
                            {
                                "value": 806,
                                "label": "Ttnm Pd Car Chgr Prym1 Silver Mist"
                            },
                            {
                                "value": 807,
                                "label": "Mag Buddy Plates"
                            },
                            {
                                "value": 808,
                                "label": "Mag Buddy Dash Telescopic Mount"
                            },
                            {
                                "value": 809,
                                "label": "Mag Buddy Headrest"
                            },
                            {
                                "value": 810,
                                "label": "Mag Buddy Anywhere Mount"
                            },
                            {
                                "value": 811,
                                "label": "Mag Buddy Anywhere Mount Single"
                            },
                            {
                                "value": 812,
                                "label": "Mag Buddy Air Ventcd Slot Mount"
                            },
                            {
                                "value": 813,
                                "label": "Magbuddy Wireless Vent Mount"
                            },
                            {
                                "value": 814,
                                "label": "Magbuddy Wireless Dash Mount"
                            },
                            {
                                "value": 815,
                                "label": "Mag Buddy Vent Mount"
                            },
                            {
                                "value": 816,
                                "label": "Magbuddy Wireless Windshield Mount"
                            },
                            {
                                "value": 817,
                                "label": "Magbuddy Wireless Anywhere Mount"
                            },
                            {
                                "value": 818,
                                "label": "Mag Buddy Windshield Mount"
                            },
                            {
                                "value": 819,
                                "label": "Mag Buddy Cupholder Mount"
                            },
                            {
                                "value": 829,
                                "label": "Tt Ttnm Pd Chrger"
                            },
                            {
                                "value": 830,
                                "label": "Tt Ttnm Cc Cbl Shade"
                            },
                            {
                                "value": 831,
                                "label": "Tt Ttnm C Ltng Cbl Shade"
                            },
                            {
                                "value": 832,
                                "label": "Tt Ttnm Type C Cbl Dawn"
                            },
                            {
                                "value": 833,
                                "label": "Tt Ttnm Type C Cbl Spruce"
                            },
                            {
                                "value": 834,
                                "label": "Tt Ttnm Micro Cbl Oak"
                            },
                            {
                                "value": 835,
                                "label": "Tt Ttnm Lightning Cbl Dawn"
                            },
                            {
                                "value": 836,
                                "label": "Tt Ttnm Lightning Cbl Oak"
                            },
                            {
                                "value": 837,
                                "label": "Tt Ttnm Lightning Cbl Spruce"
                            },
                            {
                                "value": 838,
                                "label": "Ttnm USB To Typc Cbl Purple"
                            },
                            {
                                "value": 875,
                                "label": "Ttnm Lightning Cable Asst"
                            },
                            {
                                "value": 876,
                                "label": "Ttnm Type C Cable Asst"
                            },
                            {
                                "value": 877,
                                "label": "Ttnm Micro Cable Asst"
                            },
                            {
                                "value": 878,
                                "label": "Ttnm Aux Cable Asst"
                            },
                            {
                                "value": 879,
                                "label": "Ttnm Type C To Lightning Cable Asst"
                            },
                            {
                                "value": 880,
                                "label": "Ttnm Type C To Type C Cable Asst"
                            },
                            {
                                "value": 881,
                                "label": "Ttnm Pd Car Charger Asst"
                            },
                            {
                                "value": 882,
                                "label": "Ttnm Car Charger Asst"
                            },
                            {
                                "value": 883,
                                "label": "Ttnm True Wireless Earbuds Asst"
                            },
                            {
                                "value": 884,
                                "label": "Ttnm Pd Car Chgr Prym1 Patriot Blue"
                            },
                            {
                                "value": 885,
                                "label": "Ttnm Dual USB Car Chgr Prym1 Blue"
                            },
                            {
                                "value": 886,
                                "label": "Ttnm Dual USB Car Chgr Prym1 Silver Mist"
                            },
                            {
                                "value": 887,
                                "label": "Ttnm Typc To Ltng Cbl Prym1 Patriot Blue"
                            },
                            {
                                "value": 888,
                                "label": "Ttnm Typc To Ltng Cbl Prym1 Silver Mist"
                            },
                            {
                                "value": 889,
                                "label": "Ttnm Typc To Typc Cbl Prym1 Silver Mist"
                            },
                            {
                                "value": 890,
                                "label": "Ttnm Typc To Typc Cbl Prym1 Patriot Blue"
                            },
                            {
                                "value": 891,
                                "label": "Ttnm Micro Cbl Prym1 Silver Mist"
                            },
                            {
                                "value": 892,
                                "label": "Ttnm Micro Cbl Prym1 Patriot Blue"
                            },
                            {
                                "value": 893,
                                "label": "Xl 8Ft Micro Cbl Prym1 Black"
                            },
                            {
                                "value": 894,
                                "label": "Xl 8Ft Typc Cbl Prym1 Black"
                            },
                            {
                                "value": 895,
                                "label": "Xl 8Ft Ltng Cbl Prym1 Black"
                            },
                            {
                                "value": 896,
                                "label": "Xl 8Ft Typc Cbl Prym1 Orange"
                            },
                            {
                                "value": 897,
                                "label": "Xl 8Ft Ltng Cbl Prym1 Silver"
                            },
                            {
                                "value": 898,
                                "label": "Xl 8Ft Ltng Cbl Prym1 Orange"
                            },
                            {
                                "value": 899,
                                "label": "Dual USB Car Chgr Prym1 Silver"
                            },
                            {
                                "value": 900,
                                "label": "Dual USB Car Chgr Prym1 Orange"
                            },
                            {
                                "value": 903,
                                "label": "Rlt Cmousb Car 8Pin Mfi Cbl"
                            },
                            {
                                "value": 904,
                                "label": "2.1A Dual USB Car Charger Black"
                            },
                            {
                                "value": 905,
                                "label": "2.1A Dual USB Car Charger Silver"
                            },
                            {
                                "value": 906,
                                "label": "2.1A Dual USB Car Charger White"
                            },
                            {
                                "value": 918,
                                "label": "Hivis Aux Green"
                            },
                            {
                                "value": 919,
                                "label": "Hivis Aux Orange"
                            },
                            {
                                "value": 920,
                                "label": "Hivis Aux Silver"
                            },
                            {
                                "value": 921,
                                "label": "Audio Cable"
                            },
                            {
                                "value": 922,
                                "label": "Camo 3.1A Car Charger"
                            },
                            {
                                "value": 923,
                                "label": "Camo 8Pin Cable"
                            },
                            {
                                "value": 924,
                                "label": "Sr Type C USB Cable"
                            },
                            {
                                "value": 925,
                                "label": "Ttnm Pd Car Chgr Prym1 Freedom"
                            }
                        ]
                    },
                    {
                        "value": "Mobile Comfort",
                        "label": "Mobile Comfort",
                        "children": [
                            {
                                "value": 201,
                                "label": "Bco Gel Neck Pillow Assorted"
                            },
                            {
                                "value": 946,
                                "label": "Srp 14.99 Neck Pillow Asst."
                            }
                        ]
                    },
                    {
                        "value": "Tech Novelty",
                        "label": "Tech Novelty",
                        "children": [
                            {
                                "value": 239,
                                "label": "Plug N Play Frogger"
                            },
                            {
                                "value": 276,
                                "label": "Sharper Image Auto Heated Mug"
                            },
                            {
                                "value": 277,
                                "label": "Toy Rc Robotic Battle Spacebot"
                            },
                            {
                                "value": 278,
                                "label": "Toy Rc Spin Drifter 360"
                            },
                            {
                                "value": 345,
                                "label": "Mobile Gaming Controller"
                            },
                            {
                                "value": 346,
                                "label": "Mobile Gaming Handles"
                            },
                            {
                                "value": 347,
                                "label": "Mobiel Gaming Triggers"
                            },
                            {
                                "value": 348,
                                "label": "Premium Mobile Gaming Triggers"
                            },
                            {
                                "value": 349,
                                "label": "Vanguard 720P 19In Port Gaming Monitor"
                            },
                            {
                                "value": 908,
                                "label": "Epulse Shoes"
                            },
                            {
                                "value": 909,
                                "label": "Epulse Workout Belt"
                            },
                            {
                                "value": 910,
                                "label": "Epulse Gloves"
                            },
                            {
                                "value": 911,
                                "label": "Epulse Socks"
                            },
                            {
                                "value": 912,
                                "label": "Epulse Massage Brace"
                            },
                            {
                                "value": 913,
                                "label": "Epulse Elbow Brace"
                            },
                            {
                                "value": 914,
                                "label": "Epulse Knee Brace"
                            },
                            {
                                "value": 915,
                                "label": "Epulse Electrode Pads Large"
                            },
                            {
                                "value": 916,
                                "label": "Epulse Electrode Pads Jumbo"
                            }
                        ]
                    }
                ],
                "map": {
                    "Audio": true,
                    "Bluetooth": true,
                    "Mobile Accessories": true,
                    "Mobile Comfort": true,
                    "Tech Novelty": true
                }
            },
            {
                "value": "Trucker Merch",
                "label": "Trucker Merch",
                "children": [
                    {
                        "value": "12 Volt",
                        "label": "12 Volt",
                        "children": [
                            {
                                "value": 384,
                                "label": "Brentwood Ts 112B Sngle Serve Coffee Mak"
                            },
                            {
                                "value": 385,
                                "label": "3 Qt Slow Cooker Ss Body"
                            }
                        ]
                    },
                    {
                        "value": "Chemicals",
                        "label": "Chemicals",
                        "children": [
                            {
                                "value": 301,
                                "label": "Starting Fluid 11 Oz. Aerosol"
                            }
                        ]
                    },
                    {
                        "value": "Flashlights",
                        "label": "Flashlights",
                        "children": [
                            {
                                "value": 221,
                                "label": "Scipio 2000Lumens Flashlight"
                            },
                            {
                                "value": 222,
                                "label": "Scipio 1000 Lumens Flashlight"
                            },
                            {
                                "value": 223,
                                "label": "Scipio Cree Tactical Headlamp"
                            },
                            {
                                "value": 224,
                                "label": "Scipio Rechargeable Flashlight 4000Lms"
                            },
                            {
                                "value": 230,
                                "label": "Tactical Pen 18 Pc Display"
                            },
                            {
                                "value": 453,
                                "label": "Led Baseball Bat Flashlight 12Ct Pdq"
                            },
                            {
                                "value": 664,
                                "label": "Camo Military Grade Tactical Flashlight"
                            }
                        ]
                    },
                    {
                        "value": "Gloves",
                        "label": "Gloves",
                        "children": [
                            {
                                "value": 167,
                                "label": "Scipio Glove Tactical Recon L"
                            },
                            {
                                "value": 168,
                                "label": "Scipio Glove Tactical Pathfinder L"
                            },
                            {
                                "value": 214,
                                "label": "Scipio Tactical Glove Large"
                            },
                            {
                                "value": 215,
                                "label": "Scipio Tactical Glove X Large"
                            },
                            {
                                "value": 250,
                                "label": "Sole Trends Thermal Gloves"
                            },
                            {
                                "value": 575,
                                "label": "Truck Stuff Fingerless Glove"
                            },
                            {
                                "value": 748,
                                "label": "Brown Jersey Glove"
                            }
                        ]
                    },
                    {
                        "value": "Lights & Lenses",
                        "label": "Lights & Lenses",
                        "children": [
                            {
                                "value": 31,
                                "label": "6024 Halogen Hilo Beam Round 2Lamp Sys"
                            },
                            {
                                "value": 32,
                                "label": "4651 Halogen Hibeam Square 4 Lamp Sys"
                            },
                            {
                                "value": 33,
                                "label": "4656 Halogen Lo Beam Square 4Lamp System"
                            },
                            {
                                "value": 34,
                                "label": "6054 Halogen Hilo Beam Sq 2 Lamp Sys"
                            },
                            {
                                "value": 35,
                                "label": "5061 Longlife Halo Low Beam Sq 4Lamp Sys"
                            },
                            {
                                "value": 36,
                                "label": "4656 Night Hawk Low Beam 4 Lamp System"
                            },
                            {
                                "value": 37,
                                "label": "H11 Headlight Bulb Longlife"
                            },
                            {
                                "value": 38,
                                "label": "9005 Halogen Bulb Aerodynamic Hi Beam"
                            },
                            {
                                "value": 39,
                                "label": "9003 Halogen Hilow Beam Repl Bulb"
                            },
                            {
                                "value": 40,
                                "label": "H13 Replacement Bulbhalogen"
                            },
                            {
                                "value": 41,
                                "label": "H9006 Nighthawk Capsule Lo Beam 4 Lig"
                            }
                        ]
                    },
                    {
                        "value": "Luggage",
                        "label": "Luggage",
                        "children": [
                            {
                                "value": 198,
                                "label": "Scipio Tactical Sling Bag Camo"
                            },
                            {
                                "value": 217,
                                "label": "Scipio Foam Lined Storage Case"
                            },
                            {
                                "value": 218,
                                "label": "Scipio Sling Bag With USB Port"
                            },
                            {
                                "value": 225,
                                "label": "Scipio Soft Sided Carry Case"
                            },
                            {
                                "value": 226,
                                "label": "Scipio Soft Sided Carry Case"
                            },
                            {
                                "value": 436,
                                "label": "Hershey Bar Spandex Pillow"
                            },
                            {
                                "value": 437,
                                "label": "Reese S Cup Microbead Pillow"
                            },
                            {
                                "value": 438,
                                "label": "Hershey S Neck Roll Travel"
                            }
                        ]
                    },
                    {
                        "value": "Misc Trk Sup",
                        "label": "Misc Trk Sup",
                        "children": [
                            {
                                "value": 165,
                                "label": "Trukr Stik Truck Mirror Squeegee Pdq"
                            },
                            {
                                "value": 188,
                                "label": "Flexible Spout Funnels12Pc Clipstrip"
                            },
                            {
                                "value": 219,
                                "label": "Scipio Carabiner With Clip Opening"
                            },
                            {
                                "value": 220,
                                "label": "Scipio Carabiner Twist Closure"
                            },
                            {
                                "value": 236,
                                "label": "Self Powered Spotlight With Beacon"
                            },
                            {
                                "value": 237,
                                "label": "Weather Alert Clock Radio"
                            },
                            {
                                "value": 500,
                                "label": "Push Broom 24 W60Handle"
                            }
                        ]
                    },
                    {
                        "value": "Safety",
                        "label": "Safety",
                        "children": [
                            {
                                "value": 202,
                                "label": "Glvnzd Stl Pipe Stake Od 2.375 In 9Gauge"
                            },
                            {
                                "value": 271,
                                "label": "Flex Seal Black Mini 2 0 Oz In Pdq"
                            },
                            {
                                "value": 272,
                                "label": "Flex Seal Clear Mini 2 0 Oz In Pdq"
                            },
                            {
                                "value": 273,
                                "label": "Flex Tape Mini  Black W Clip Strip"
                            },
                            {
                                "value": 274,
                                "label": "Flex Tape Mini  Clear W Clip Strip"
                            },
                            {
                                "value": 275,
                                "label": "Flex Glue Mini 75 Oz W Clip Strip Pdq"
                            },
                            {
                                "value": 369,
                                "label": "Wrapit Heavy Duty Storage"
                            },
                            {
                                "value": 378,
                                "label": "Vee Boards 2 Go Pack Of 20 Whandle"
                            },
                            {
                                "value": 498,
                                "label": "Hivis Breakaway Vest Prepack"
                            },
                            {
                                "value": 499,
                                "label": "Hivis Deluxe Vest Prepack"
                            }
                        ]
                    },
                    {
                        "value": "Stationary",
                        "label": "Stationary",
                        "children": [
                            {
                                "value": 203,
                                "label": "Bazic Jumbo Color Paper Clips 100 Pk"
                            },
                            {
                                "value": 204,
                                "label": "Binder Clips Black 8Pk 1 14"
                            },
                            {
                                "value": 205,
                                "label": "Bazic 12In30Cm Transprnt Plastic Ruler"
                            },
                            {
                                "value": 206,
                                "label": "Bazic Mini Standard Stapler W 500 Ct Sta"
                            },
                            {
                                "value": 207,
                                "label": "Transparent Tape With Dispenser"
                            },
                            {
                                "value": 208,
                                "label": "Bazic Metal Tip Correctn Pen Fluid 2Pk"
                            },
                            {
                                "value": 209,
                                "label": "Correction Tape With Grip"
                            },
                            {
                                "value": 210,
                                "label": "Flourescent Highlighter 3Pk"
                            },
                            {
                                "value": 211,
                                "label": "Bazic 3 Round 2X Hndhld Magnfr 4X Bif"
                            },
                            {
                                "value": 212,
                                "label": "Bazic Single Hole Paper Punch"
                            },
                            {
                                "value": 213,
                                "label": "Bazic 2Part Carbonless Cashrent Rct Bk"
                            }
                        ]
                    },
                    {
                        "value": "Tools",
                        "label": "Tools",
                        "children": [
                            {
                                "value": 351,
                                "label": "Craftsman 10In1 Mini Multi Tool 12Pc Pdq"
                            },
                            {
                                "value": 352,
                                "label": "Craftsman 14 In 1 Multi Purpose Tool"
                            }
                        ]
                    },
                    {
                        "value": "Trucker Cleaning Supplies",
                        "label": "Trucker Cleaning Supplies",
                        "children": [
                            {
                                "value": 161,
                                "label": "Roadpro 100Ct Antibacterial Wipes Conta"
                            },
                            {
                                "value": 162,
                                "label": "Roadpro 2 Oz Hand Sanitizer 24Pc Pdq"
                            },
                            {
                                "value": 163,
                                "label": "Antibacterial Wet Wipes"
                            },
                            {
                                "value": 164,
                                "label": "Hand Sanitzer 2 Oz."
                            },
                            {
                                "value": 166,
                                "label": "Dust Pan"
                            },
                            {
                                "value": 304,
                                "label": "Nano Fiber Woven Cloth 2 Pack"
                            },
                            {
                                "value": 353,
                                "label": "Rp 1Oz Hand Sanitizer 50Pc Fishbowl"
                            }
                        ]
                    },
                    {
                        "value": "Trucker Grooming Accessories",
                        "label": "Trucker Grooming Accessories",
                        "children": [
                            {
                                "value": 240,
                                "label": "Soap On A Rope Tactical Scrubber"
                            },
                            {
                                "value": 241,
                                "label": "Shampoo Puck Gold Rush"
                            },
                            {
                                "value": 242,
                                "label": "Shampoo Puck Barrel Char"
                            },
                            {
                                "value": 251,
                                "label": "4 Piece Shaving Set Tiger Eye"
                            },
                            {
                                "value": 252,
                                "label": "3 Piece Shaving Set Black"
                            },
                            {
                                "value": 253,
                                "label": "3 Piece Shaving Set White"
                            },
                            {
                                "value": 254,
                                "label": "Sharpening Strop"
                            },
                            {
                                "value": 255,
                                "label": "Shaving Bowl"
                            },
                            {
                                "value": 256,
                                "label": "Toiletry Bag"
                            },
                            {
                                "value": 257,
                                "label": "Beard Comb"
                            },
                            {
                                "value": 258,
                                "label": "German Razor"
                            },
                            {
                                "value": 285,
                                "label": "Cold Shower Tower Counter Display"
                            },
                            {
                                "value": 286,
                                "label": "Beard Balm And Oil Pdq"
                            },
                            {
                                "value": 342,
                                "label": "Thick Liquid Shower Soap Naval Suprema"
                            },
                            {
                                "value": 343,
                                "label": "Thick Liquid Shower Soap Old Glory"
                            },
                            {
                                "value": 344,
                                "label": "Best Damn Beard Oil"
                            },
                            {
                                "value": 404,
                                "label": "Bloody Knuckles Clip Strip 16 Pc"
                            },
                            {
                                "value": 450,
                                "label": "News Anchor Thickening Tonic"
                            },
                            {
                                "value": 451,
                                "label": "Best Beard Wash"
                            }
                        ]
                    }
                ],
                "map": {
                    "12 Volt": true,
                    "Chemicals": true,
                    "Flashlights": true,
                    "Gloves": true,
                    "Lights & Lenses": true,
                    "Luggage": true,
                    "Misc Trk Sup": true,
                    "Safety": true,
                    "Stationary": true,
                    "Tools": true,
                    "Trucker Cleaning Supplies": true,
                    "Trucker Grooming Accessories": true
                }
            }
        ],
        "map": {
            "General Merch": true,
            "Mobile Technologies": true,
            "Trucker Merch": true
        }
    },
    {
        "value": "Grocery",
        "label": "Grocery",
        "children": [
            {
                "value": "Candy",
                "label": "Candy",
                "children": [
                    {
                        "value": "Bulk Candy",
                        "label": "Bulk Candy",
                        "children": [
                            {
                                "value": 8,
                                "label": "Snake Bitez 7Oz"
                            }
                        ]
                    },
                    {
                        "value": "Gum",
                        "label": "Gum",
                        "children": [
                            {
                                "value": 690,
                                "label": "Rev Caff Sprmnt Gum 6Ct"
                            },
                            {
                                "value": 691,
                                "label": "Rev Es Sprmnt Caff Gum"
                            },
                            {
                                "value": 692,
                                "label": "Rev Es Mnt Caff Gum"
                            },
                            {
                                "value": 693,
                                "label": "Rev Caff Pprmnt Gum"
                            }
                        ]
                    },
                    {
                        "value": "Non-Choc Bars",
                        "label": "Non-Choc Bars",
                        "children": [
                            {
                                "value": 13,
                                "label": "N&L Shell Shchd Bogo 72Ct"
                            }
                        ]
                    },
                    {
                        "value": "Nov/Seasnl Cdy",
                        "label": "Nov/Seasnl Cdy",
                        "children": [
                            {
                                "value": 4,
                                "label": "Frkfrd Nick Jr Cndy Fansoz"
                            },
                            {
                                "value": 5,
                                "label": "Frkfrd Krbby Pty Tboz"
                            },
                            {
                                "value": 6,
                                "label": "Frnkfrd 3D Gmmy Animaloz"
                            }
                        ]
                    }
                ],
                "map": {
                    "Bulk Candy": true,
                    "Gum": true,
                    "Non-Choc Bars": true,
                    "Nov/Seasnl Cdy": true
                }
            },
            {
                "value": "Grocery 2",
                "label": "Grocery 2",
                "children": [
                    {
                        "value": "Hlth/Energy Bars",
                        "label": "Hlth/Energy Bars",
                        "children": [
                            {
                                "value": 11,
                                "label": "Met Rx Rtd51 Vanilla"
                            },
                            {
                                "value": 720,
                                "label": "Lean Shake Chocolate"
                            },
                            {
                                "value": 721,
                                "label": "Lean Shake Strawberry"
                            },
                            {
                                "value": 731,
                                "label": "Lean Bar Chocolate Chip"
                            },
                            {
                                "value": 734,
                                "label": "Lean Bar Birthday Cake"
                            },
                            {
                                "value": 988,
                                "label": "Clif Oatmeal Raisin Wlnut 1Ct"
                            }
                        ]
                    },
                    {
                        "value": "Laundry Care",
                        "label": "Laundry Care",
                        "children": [
                            {
                                "value": 116,
                                "label": "Cvp Bleach Ss"
                            },
                            {
                                "value": 117,
                                "label": "Cvp Lndry Scent Booster 4.9Oz"
                            },
                            {
                                "value": 119,
                                "label": "Cvp Bleach 43Oz"
                            }
                        ]
                    },
                    {
                        "value": "Oth Edible Groc",
                        "label": "Oth Edible Groc",
                        "children": [
                            {
                                "value": 12,
                                "label": "Cvp Hmtwn Mkt Sgr Cnstr"
                            }
                        ]
                    },
                    {
                        "value": "Pet Care",
                        "label": "Pet Care",
                        "children": [
                            {
                                "value": 112,
                                "label": "Beau Dacious Dog Leash"
                            },
                            {
                                "value": 118,
                                "label": "Beau Dacious Pb Big Bones 1Ct"
                            }
                        ]
                    }
                ],
                "map": {
                    "Hlth/Energy Bars": true,
                    "Laundry Care": true,
                    "Oth Edible Groc": true,
                    "Pet Care": true
                }
            },
            {
                "value": "HBA/Other",
                "label": "HBA/Other",
                "children": [
                    {
                        "value": "Cough & Cold",
                        "label": "Cough & Cold",
                        "children": [
                            {
                                "value": 25,
                                "label": "Bc Sinus Congestion"
                            }
                        ]
                    },
                    {
                        "value": "Grooming Aids",
                        "label": "Grooming Aids",
                        "children": [
                            {
                                "value": 113,
                                "label": "Cvp Bobby Pinshair Ties"
                            },
                            {
                                "value": 114,
                                "label": "Cvp Coil Hair Ties"
                            }
                        ]
                    },
                    {
                        "value": "Other Hbc",
                        "label": "Other Hbc",
                        "children": [
                            {
                                "value": 115,
                                "label": "Cvp Thermometer"
                            }
                        ]
                    },
                    {
                        "value": "Skin Care",
                        "label": "Skin Care",
                        "children": [
                            {
                                "value": 111,
                                "label": "Antibacterial Wipes 30Ct"
                            }
                        ]
                    },
                    {
                        "value": "Vit/Suplmnts",
                        "label": "Vit/Suplmnts",
                        "children": [
                            {
                                "value": 287,
                                "label": "Joint Lube Joint Care 120 Ct Bottle"
                            },
                            {
                                "value": 454,
                                "label": "Gnc Melatonin Gummies"
                            },
                            {
                                "value": 455,
                                "label": "Gnc Vitamin C 500 Tablets"
                            },
                            {
                                "value": 456,
                                "label": "Gnc Zinc Lozenges"
                            },
                            {
                                "value": 457,
                                "label": "Gnc Zinc 30Mg"
                            },
                            {
                                "value": 458,
                                "label": "Gnc Melatonin 10Mg 60Ct"
                            },
                            {
                                "value": 459,
                                "label": "Gnc Vitamin C Gummies"
                            },
                            {
                                "value": 460,
                                "label": "Gnc Mega Men Multi Gummies"
                            },
                            {
                                "value": 461,
                                "label": "Gnc Mens Staminol Health"
                            },
                            {
                                "value": 462,
                                "label": "Gnc Multi Immune Gummies"
                            },
                            {
                                "value": 463,
                                "label": "Burn Mf Powder Single Pack Gummy Worm"
                            },
                            {
                                "value": 464,
                                "label": "Slimvance Powder Single Pack Iced Tea"
                            },
                            {
                                "value": 465,
                                "label": "Burn Mf Powder Single Pack Orng Mngo"
                            },
                            {
                                "value": 466,
                                "label": "Gnc Melatonin 10Mg 60Ct"
                            },
                            {
                                "value": 467,
                                "label": "Tower Display Empty"
                            },
                            {
                                "value": 468,
                                "label": "Counter Display Empty"
                            },
                            {
                                "value": 719,
                                "label": "Womens Ultra Mega Multi"
                            },
                            {
                                "value": 722,
                                "label": "Apple Cider Complete 90 Count"
                            },
                            {
                                "value": 723,
                                "label": "Vitamin B12 Soft Chews 60 Count"
                            },
                            {
                                "value": 724,
                                "label": "Mega Men One Daily 60 Count"
                            },
                            {
                                "value": 725,
                                "label": "Mega Men 50 Plus One Daily 60 Count"
                            },
                            {
                                "value": 726,
                                "label": "Coq10 100Mg 60 Count"
                            },
                            {
                                "value": 727,
                                "label": "Vitmin B2 100Mg 100 Count"
                            },
                            {
                                "value": 728,
                                "label": "Calcium Plus 1000 180 Count"
                            },
                            {
                                "value": 729,
                                "label": "Vitamin C 500Mg 100 Count"
                            },
                            {
                                "value": 730,
                                "label": "Melatonin 3Mg 60 Count"
                            },
                            {
                                "value": 732,
                                "label": "Milk Thistle 200Mg 100 Count"
                            },
                            {
                                "value": 733,
                                "label": "Mega Men 90 Count"
                            },
                            {
                                "value": 735,
                                "label": "Turmeric Sport 60 Count"
                            },
                            {
                                "value": 736,
                                "label": "Womens Ultra Mega 50 Plus 60 Count"
                            },
                            {
                                "value": 737,
                                "label": "Probiotic Complex Daily 30 Count"
                            },
                            {
                                "value": 907,
                                "label": "Probiotic Complex Daily 100 Count"
                            },
                            {
                                "value": 917,
                                "label": "Power Wash 90 Count"
                            }
                        ]
                    }
                ],
                "map": {
                    "Cough & Cold": true,
                    "Grooming Aids": true,
                    "Other Hbc": true,
                    "Skin Care": true,
                    "Vit/Suplmnts": true
                }
            },
            {
                "value": "Meat Snack",
                "label": "Meat Snack",
                "children": [
                    {
                        "value": "Meat Snacks",
                        "label": "Meat Snacks",
                        "children": [
                            {
                                "value": 14,
                                "label": "Nml Mld Meat Stk Oz"
                            },
                            {
                                "value": 15,
                                "label": "Nml Blk Pepr Meat Stk Oz"
                            },
                            {
                                "value": 16,
                                "label": "Nml Ht Meat Stk Oz"
                            }
                        ]
                    }
                ],
                "map": {
                    "Meat Snacks": true
                }
            },
            {
                "value": "Salty Snack",
                "label": "Salty Snack",
                "children": [
                    {
                        "value": "Nuts Seeds (Take Home)",
                        "label": "Nuts Seeds (Take Home)",
                        "children": [
                            {
                                "value": 985,
                                "label": "Bigs Ks Org Sr Sd Ss"
                            }
                        ]
                    },
                    {
                        "value": "Nuts/Seeds",
                        "label": "Nuts/Seeds",
                        "children": [
                            {
                                "value": 986,
                                "label": "Bigs Takis Snfl Seed 5.35Oz"
                            }
                        ]
                    },
                    {
                        "value": "Other Salty Snacks",
                        "label": "Other Salty Snacks",
                        "children": [
                            {
                                "value": 981,
                                "label": "Bare Baked Crunchy Fuji Red Apple 3.4Z"
                            },
                            {
                                "value": 982,
                                "label": "Bare Baked Crunchy Apple Chips Cinn 3.4Z"
                            },
                            {
                                "value": 983,
                                "label": "Bare Baked Crunchy Coconut Chips 3.3Z"
                            },
                            {
                                "value": 984,
                                "label": "Bare Baked Crunchy Banana Chips 2.7Z"
                            }
                        ]
                    },
                    {
                        "value": "Pkgd Rte Popcrn",
                        "label": "Pkgd Rte Popcrn",
                        "children": [
                            {
                                "value": 2,
                                "label": "Pop Fuego Ppcrn"
                            }
                        ]
                    }
                ],
                "map": {
                    "Nuts Seeds (Take Home)": true,
                    "Nuts/Seeds": true,
                    "Other Salty Snacks": true,
                    "Pkgd Rte Popcrn": true
                }
            },
            {
                "value": "Sweet Snack",
                "label": "Sweet Snack",
                "children": [
                    {
                        "value": "Cookies",
                        "label": "Cookies",
                        "children": [
                            {
                                "value": 1,
                                "label": "Oreo Team Usa Ks 2.54Oz"
                            }
                        ]
                    },
                    {
                        "value": "Snk Cakes/Past",
                        "label": "Snk Cakes/Past",
                        "children": [
                            {
                                "value": 17,
                                "label": "7Days Cake Br Choc Oz"
                            },
                            {
                                "value": 18,
                                "label": "7Days Cake Br Mx Berry Oz"
                            }
                        ]
                    }
                ],
                "map": {
                    "Cookies": true,
                    "Snk Cakes/Past": true
                }
            }
        ],
        "map": {
            "Candy": true,
            "Grocery": true,
            "HBA/Other": true,
            "Meat Snack": true,
            "Salty Snack": true,
            "Sweet Snack": true
        }
    },
    {
        "value": "Tobacco",
        "label": "Tobacco",
        "children": [
            {
                "value": "Smoker Accessories",
                "label": "Smoker Accessories",
                "children": [
                    {
                        "value": "Smoker Acc",
                        "label": "Smoker Acc",
                        "children": [
                            {
                                "value": 7,
                                "label": "Bic Tattoo Ltr N Mrkr 78C"
                            },
                            {
                                "value": 74,
                                "label": "Bic Chicago Cubs Ltr"
                            },
                            {
                                "value": 75,
                                "label": "Bic Ny Yankees Ltr"
                            },
                            {
                                "value": 76,
                                "label": "Bic Street Art Maxi Ltr Sngl"
                            },
                            {
                                "value": 77,
                                "label": "Bic Retro Wave Ltr Sngl"
                            },
                            {
                                "value": 78,
                                "label": "Bic Party Animal Maxi Ltr Sngl"
                            },
                            {
                                "value": 79,
                                "label": "Bic Street Art Maxi Ltr Pk"
                            },
                            {
                                "value": 80,
                                "label": "Bic Retro Wave Ltr Pk"
                            },
                            {
                                "value": 81,
                                "label": "Bic Party Animal Maxi Ltr Pk"
                            },
                            {
                                "value": 82,
                                "label": "Bic Nfl Dolphins Ltr"
                            },
                            {
                                "value": 83,
                                "label": "Bic Nfl Patriots Ltr"
                            },
                            {
                                "value": 84,
                                "label": "Bic Nfl Jaguars Ltr"
                            },
                            {
                                "value": 85,
                                "label": "Bic Nfl Eagles Ltr"
                            },
                            {
                                "value": 86,
                                "label": "Bic Ny Giants Pro Series"
                            },
                            {
                                "value": 87,
                                "label": "Bic Nfl La Rms Lghtr"
                            },
                            {
                                "value": 88,
                                "label": "Bic Nfl Clevelnd Brwn Ltr"
                            },
                            {
                                "value": 89,
                                "label": "Bic Maxi Houston Txn Ltr"
                            },
                            {
                                "value": 90,
                                "label": "Bic Nascar Drvrs Ltr"
                            },
                            {
                                "value": 91,
                                "label": "Bic Maxi Ltr Hspnc Cultur"
                            },
                            {
                                "value": 92,
                                "label": "Bic Maxi Ltr Urban Music"
                            },
                            {
                                "value": 93,
                                "label": "Bic Maxi Ltr Sturgis"
                            },
                            {
                                "value": 94,
                                "label": "Bic Maxi Ltr Texas Tray"
                            },
                            {
                                "value": 95,
                                "label": "Bic Maxi Ltr Classc Movie"
                            },
                            {
                                "value": 96,
                                "label": "Bic Nw Yrk Mxi Ltr"
                            },
                            {
                                "value": 97,
                                "label": "Bic Maxi Ltr Seasonal"
                            },
                            {
                                "value": 98,
                                "label": "Bic Maxi Ltr Ca Tray"
                            },
                            {
                                "value": 99,
                                "label": "Bic Maxi Ltr Explrtn Tray"
                            },
                            {
                                "value": 100,
                                "label": "Bic Maxi Ltr Nature Tray"
                            },
                            {
                                "value": 101,
                                "label": "Bic Maxi Ltr Lndscps Tray"
                            },
                            {
                                "value": 102,
                                "label": "Bic Maxi Ltr Guy Harvey"
                            },
                            {
                                "value": 103,
                                "label": "Bic Nfl Az Cardinals"
                            },
                            {
                                "value": 104,
                                "label": "Bic Mazi Ltr Bob Marley"
                            },
                            {
                                "value": 105,
                                "label": "Bic Maxi Ltr Blown Glass"
                            },
                            {
                                "value": 106,
                                "label": "Bic Cheers Ltrs"
                            },
                            {
                                "value": 107,
                                "label": "Bic Maxi Ltr Spooky"
                            },
                            {
                                "value": 108,
                                "label": "Bic Maxi Ltr"
                            },
                            {
                                "value": 109,
                                "label": "Maxi Ltr"
                            },
                            {
                                "value": 110,
                                "label": "Bic Mxd Ltr 9Tr 450Ct"
                            },
                            {
                                "value": 745,
                                "label": "Torch Lighter 34.99"
                            }
                        ]
                    }
                ],
                "map": {
                    "Smoker Acc": true
                }
            },
            {
                "value": "Smokless",
                "label": "Smokless",
                "children": [
                    {
                        "value": "Snus & Dislvable",
                        "label": "Snus & Dislvable",
                        "children": [
                            {
                                "value": 951,
                                "label": "Zyn Cool Mint 3Mg .21 Oz Can"
                            },
                            {
                                "value": 952,
                                "label": "Zyn Cool Mint 3Mg .21 Oz Roll"
                            },
                            {
                                "value": 953,
                                "label": "Zyn Peppermint 3Mg .21 Oz Can"
                            },
                            {
                                "value": 954,
                                "label": "Zyn Citrus 6Mg .21 Oz Ca"
                            },
                            {
                                "value": 955,
                                "label": "Zyn Citrus 3Mg .21 Oz Ca Roll"
                            },
                            {
                                "value": 956,
                                "label": "Zyn Citrus 6Mg .21 Oz Ca Roll"
                            },
                            {
                                "value": 957,
                                "label": "Zyn Wintergrn 6Mg .21 Oz Can"
                            },
                            {
                                "value": 958,
                                "label": "Zyn Wintergrn 6Mg .21 Oz Roll"
                            },
                            {
                                "value": 959,
                                "label": "Zyn Peppermint 3Mg .21 Oz Roll"
                            },
                            {
                                "value": 960,
                                "label": "Zyn Spearmint 3Mg .21 Oz Can"
                            },
                            {
                                "value": 961,
                                "label": "Zyn Spearmint 3Mg .21 Oz Roll"
                            },
                            {
                                "value": 962,
                                "label": "Zyn Wintergrn 3Mg .21 Oz Roll"
                            },
                            {
                                "value": 963,
                                "label": "Zyn Coffee 3Mg .21 Oz Can"
                            },
                            {
                                "value": 964,
                                "label": "Zyn Coffee 6Mg .21 Oz Can"
                            },
                            {
                                "value": 965,
                                "label": "Zyn Coffee 3Mg .21 Oz Roll"
                            },
                            {
                                "value": 966,
                                "label": "Zyn Coffee 6Mg .21 Oz Roll"
                            },
                            {
                                "value": 967,
                                "label": "Zyn Cinnamon 3Mg .21 Oz Can"
                            },
                            {
                                "value": 968,
                                "label": "Zyn Cinnamon 6Mg .21 Oz Can"
                            },
                            {
                                "value": 969,
                                "label": "Zyn Cinnamon 3Mg .21 Oz Roll"
                            },
                            {
                                "value": 970,
                                "label": "Zyn Cinnamon 6Mg .21 Oz Roll"
                            },
                            {
                                "value": 971,
                                "label": "Zyn Cool Mint 6Mg .21 Oz Can"
                            },
                            {
                                "value": 972,
                                "label": "Zyn Cool Mint 6Mg .21 Oz Roll"
                            },
                            {
                                "value": 973,
                                "label": "Zyn Peppermint 6Mg .21 Oz Can"
                            },
                            {
                                "value": 974,
                                "label": "Zyn Peppermint 6Mg .21 Oz Roll"
                            },
                            {
                                "value": 975,
                                "label": "Zyn Spearmint 6Mg .21 Oz Can"
                            },
                            {
                                "value": 976,
                                "label": "Zyn Spearmint 6Mg .21 Oz Roll"
                            }
                        ]
                    }
                ],
                "map": {
                    "Snus & Dislvable": true
                }
            }
        ],
        "map": {
            "Smoker Accessories": true,
            "Smokless": true
        }
    }
]

export default rows;