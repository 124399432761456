import React from "react";
import utils from "../../app/utils";
import {Layout} from "../../foundation/Layout";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteTooltip, } from '../../foundation/Sprite';
import {Donut, } from '../../components/Misc';
import classNames from "classnames";
// import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
// import {Searchbar, Titlebar, Paginationbar} from '../../components/Toolbars';
// import {DataList, DataTop, DataRow, DataCell} from '../../foundation/DataList';
// import {Icon} from '../../foundation/Icon';
// import { borderRadius } from "@mui/system";
import "./Base.scss";



const DEBUG = 0;


export default function BaseHome(){
    const dispatch = useGlobalsDispatch();
    const {profileData, } = useGlobals();

    // init
    React.useEffect(() => {

        // show toast
        setTimeout(() => {
            const toast = {
                message: "Anna Paltrow sends you Spring sale and 2 others for final price approval...", 
                duration: 20000, 
                x: true, 
                align: 'left',
                onClick: () => {
                    dispatch({type: "showToast", value: false, });
                    utils.loader(dispatch, {type: "setScreen", value: "taskmgt-list", }, 2000);
                }, 
            };
            dispatch({type: "showToast", value: toast, });
        }, 3000);
    }, []);


    // quick links
    const onPriceList = () => utils.loader(dispatch, {type: "setScreen", value: "price-list", }, 1000);
    const onPriceDetails = () => utils.loader(dispatch, {type: "setScreen", value: "price-details", }, 1000);
    const onTaskList = () => utils.loader(dispatch, {type: "setScreen", value: "taskmgt-list", }, 1000);
    const onTaskDetails = () => utils.loader(dispatch, {type: "setScreen", value: "taskmgt-details", }, 1000);


    // return
    return (
        <Layout>

            <Sprite debug={0} absall className="rds-base-home" style={{width: 1364, height: 800, left: 0, }}>

                {/* quick links */}
                <SpriteButton debug={DEBUG} style={{top: 80, left: 10, width: 220, }} onClick={onPriceList} />
                <SpriteButton debug={DEBUG} style={{top: 80, left: 1280, width: 60, }} onClick={onPriceList} />
                <SpriteButton debug={DEBUG} style={{top: 480, left: 20, width: 300, height: 240, }} onClick={onPriceDetails} />
                <SpriteButton debug={DEBUG} style={{top: 420, left: 940, width: 60, }} onClick={onTaskList} />
                <SpriteButton debug={DEBUG} style={{top: 500, left: 360, width: 640, height: 190, }} onClick={onTaskDetails} />
                <SpriteButton debug={DEBUG} style={{top: 480, left: 1040, width: 300, height: 210, }} onClick={onPriceDetails} />
                
                {/* profile name */}
                <SpriteText label={profileData.name} debug={0} style={{top: 10, left: 182, fontSize: 20, fontWeight: 600, width: 70, minWidth: 0, }} />

                <Donut style={{top: 144, left: 75, }} />

                
                {/* tooltips */}
                <SpriteTooltip debug={DEBUG} tooltip="2 days left to the due date" style={{top: 488, left: 939, }} />
                <SpriteTooltip debug={DEBUG} tooltip="The task is overdue by 3 days" style={{top: 588, left: 939, }} />

            </Sprite>

        </Layout>
    );
}


