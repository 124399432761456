import {default as MuiButton} from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import {Icon} from './Icon';
import utils from '../app/utils';
import './Button.scss';


const types = {primary: 'contained'}

export function Button(props) {
    let {icon, label, onClick,  children, type} = props;
    onClick = utils.onClick(props);

    if(typeof(icon) === 'string') icon = <Icon type={icon} />;

    const variant = types[type];
    const css = classNames('rds-button', {'rds-button--primary': type==='primary'}); // , {'rds-button--iconic': !!icon});

    return <MuiButton className={css} variant={variant} onClick={onClick} startIcon={icon}>{label || children}</MuiButton>;
}

// KEEP: direct access to MUI Button
export function Button2(props) {
    console.log('yooooooooo: ', props.label);
    const css = classNames('rds-button', props.className);
    return <MuiButton {...props} className={css}>{props.label || props.children}</MuiButton>;
}





/*
export function ButtonOLD(props) {
    let props2 = {...props};
    const {screen, iconic} = props; // iconic
    var onClick = screen ? () => {screen[0]({type: 'setScreen', value: screen[1]})} : props.onClick;
    const css = classNames('rds-button', {'rds-button--iconic': props.iconic});
    const Ele = false ? IconButton : MuiButton;
    
    // return
    if(iconic) delete props2['iconic'];
    return <Ele onClick={onClick} className={css} {...props2}>{props.children}</Ele>; 
}

*/