import gen from "./_generator";
import utils from "../app/utils";
import data from "./offersData";


data.push({ // for optimized
    "ID": 101,
    "Name": "Optimized 22%",
    "Type": "Simple: Percent Off",
    "Discount_PCT": 0.22,
    "Discount_USD": null,
    "Price": null,
    "Unlisted": true, // unlisted
});

// suggest
data.push({
    "ID": 102,
    "Name": "18% Offer",
    "Type": "Simple: Percent Off",
    "Discount_PCT": 0.18,
    "Discount_USD": null,
    "Price": null,
    "Unlisted": true, // unlisted
},{
    "ID": 103,
    "Name": "19% Offer",
    "Type": "Simple: Percent Off",
    "Discount_PCT": 0.19,
    "Discount_USD": null,
    "Price": null,
    "Unlisted": true, // unlisted
},{
    "ID": 104,
    "Name": "22% Offer",
    "Type": "Simple: Percent Off",
    "Discount_PCT": 0.22,
    "Discount_USD": null,
    "Price": null,
    "Unlisted": true, // unlisted
},{
    "ID": 105,
    "Name": "21% Offer",
    "Type": "Simple: Percent Off",
    "Discount_PCT": 0.21,
    "Discount_USD": null,
    "Price": null,
    "Unlisted": true, // unlisted
},
);

var rows = gen.rows(data.length, (i) => {
    var row = data[i];

    // extra --- WHAT ARE THESE FOR????
    row._Applies_To = gen.randomItem(["Product", "Collection"]);
    row._Offer_Limit = gen.randomItem([6, 5, 4, 3, 2, 1]);
    row._Promotions_Count = gen.randomItem([3, 12, 1, 5, 10, 0, 0]);
    // row._Regular_Price = gen.randomItem([null, null, 8, 10]);
    // row._Offer_Price = gen.randomItem([null, 8]);


    // return
    return utils.createRow("offers", row);
});

export default rows;

