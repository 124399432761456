// import {default as MuiButton} from '@mui/material/Button';
// import {Split, SplitLeft, SplitRight} from '../foundation/Layout';
import React from "react";
import classNames from 'classnames';
import Pagination from '@mui/material/Pagination';
import {Sprite, SpriteButton, SpriteInput, Popover} from '../foundation';
import {Split, SplitLeft, SplitRight} from '../foundation/Layout';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import './Toolbars.scss';
// // import IconButton from '@mui/material/IconButton';
// import {useGlobalsDispatch} from '../app/context';
// import {Button} from '../foundation/Button';



export function CreateImport({dispatch}){
    const onCreate = () => {dispatch({type: "showPanel", value: 'filters'})};
    const onImport = () => {dispatch({type: "showPanel", value: 'import'})};

    return (
        <Sprite className="rds-searchbar-right">
            <SpriteButton className="rds-searchbar-create" debug={0} onClick={onCreate} />
            <SpriteButton className="rds-searchbar-import" debug={0} onClick={onImport} />
        </Sprite>
    );
}



// --- right side / main --- //

function RightSide(props){
    switch(props.type){
        default:
        case 'create-import': return <CreateImport {...props} />
    }
}

export function Searchbar(props) {
    const {type, onClick} = props;
    const dispatch = useGlobalsDispatch();
    const css = classNames('rds-searchbar');
    const onClickFilter = () => dispatch({type: "showPanel", value: "filters"});

    // popover - category
    const [catEl, setCatEl] = React.useState(null); // NOTE: remove
    const catOpen = !!catEl;    
    const onCatClose = () => {setCatEl(null);};
    const onClickCat = (e) => setCatEl(e.currentTarget);

    // popover - recent
    const [recentEl, setRecentEl] = React.useState(null); // NOTE: remove
    const recentOpen = !!recentEl;    
    const onRecentClose = () => {setRecentEl(null);};
    const onClickRecent = (e) => setRecentEl(e.currentTarget);

    // const dispatch2 = () => {};
    // onFocus={onClickCat}
    return (
        <div className={css}>
           <Sprite absall={1} className="rds-searchbar-left">
                <SpriteButton debug={0} style={{top: 8, left: 20}} onClick={onClickCat} />
                <SpriteInput debug={0} style={{top: 8, left: 160, height: 30, width: 270}} placeholder="Search for promotions" onClick={onClickRecent}   />
                <SpriteButton debug={0} style={{top: 8, left: 450}} onClick={onClickFilter} />

            </Sprite>
            <RightSide {...props} dispatch={dispatch} />
            <Popover name="searchbar-category" shape="rounded" open={catOpen} anchorEl={catEl} onClose={onCatClose} />
            <Popover name="searchbar-recent" shape="rounded" open={recentOpen} anchorEl={recentEl} onClose={onRecentClose} />
        </div>
    );
}
