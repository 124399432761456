import React from "react";
import utils from "../../app/utils";
import {Layout} from "../../foundation/Layout";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteTooltip, SpriteInput, SpriteCaching, SpriteHover, SpriteListHover, } from '../../foundation/Sprite';
import {FilterToolbar, Paginationbar, } from "../../components/Toolbars";
import {Popover} from '../../foundation';
import classNames from "classnames";
import "./Price.scss";

const DEBUG = 0;



const data = {all: true, blue: true, yellow: true, orange: true, purple: true, }

export default function Page(){
    const dispatch = useGlobalsDispatch();
    // const globals = useGlobals();
    const [checkeds, setCheckeds] = React.useState(data);

    const onClick = () => {
        utils.loader(dispatch, {type: 'setScreen', value: "dashboard"}, 1000);
    }

    // popover - zones
    const [zoneEl, setZoneEl] = React.useState(null); // NOTE: remove
    const zoneOpen = !!zoneEl;    
    const onZoneClose = () => {setZoneEl(null);};
    const onZoneClick = (e) => setZoneEl(e.currentTarget);

    // popover - competitors
    const [compEl, setCompEl] = React.useState(null); // NOTE: remove
    const compOpen = !!compEl;    
    const onCompClose = () => {setCompEl(null);};
    const onCompClick = (e) => setCompEl(e.currentTarget);

    // click cancel / save
    const onClickCancel = () => {
        utils.loader(dispatch, {type: 'setScreen', value: "price-list"}, 1000);
    }

    // click item
    const onClickItem = (e) => {
        let obj = {...checkeds};
        const name = e.target.name;
        const checked = e.target.checked;
        obj[name] = checked;

        // all?
        if(name === 'all'){
            Object.keys(data).forEach(name => {obj[name] = checked; });
        } else {
            let allchecked = true;
            Object.keys(data).forEach(name => {
                if(!obj[name] && name !== 'all') allchecked = false;
            });
            obj.all = allchecked;
        }
        setCheckeds(obj);
    }

    // return
    const css = classNames('rds-price-details');
    return (
        <Layout>
            
            <Sprite debug={0} absall className={css} style={{width: 1344, height: 932, }}>

                <SpriteButton debug={0} style={{top: 0, left: 1140, width: 200, }} onClick={onClickCancel} />

                {/* zone menu */}
                <SpriteButton debug={0} style={{top: 244, left: 836, width: 100, }} onClick={onZoneClick} />
                <Popover name="price-details-zones" shape="rounded" open={zoneOpen} anchorEl={zoneEl} onClose={onZoneClose} />

                {/* competitors menu */}
                <SpriteButton debug={0} style={{top: 244, left: 953, width: 140, }} onClick={onCompClick} />
                <Popover name="price-details-competitors" shape="rounded" open={compOpen} anchorEl={compEl} onClose={onCompClose} onClickItem={onClickItem} checkeds={checkeds}  />

                {/* lines */}
                <Sprite debug={0} absall className="rds-price-details-lines" style={{top: 390, left: 458, }}>
                    <Sprite debug={0} className="rds-price-details-line is-purple" style={{opacity: 1, }} />
                    <Sprite debug={0} className="rds-price-details-line is-blue" style={{opacity: checkeds.blue?1:0, }} />
                    <Sprite debug={0} className="rds-price-details-line is-green" style={{opacity: checkeds.yellow?1:0, }} />
                    <Sprite debug={0} className="rds-price-details-line is-yellow" style={{opacity: checkeds.orange?1:0, }} />
                    <Sprite debug={0} className="rds-price-details-line is-orange" style={{opacity: checkeds.purple?1:0, }} />
                </Sprite>

            </Sprite>

        </Layout>
    )
}
