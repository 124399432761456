import {default as MuiIconButton} from '@mui/material/IconButton'; 
import classNames from 'classnames';
import utils from '../app/utils';
import {Link} from './Text';
import Badge from '@mui/material/Badge';
import './Icon.scss';

// icons
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AppsIcon from '@mui/icons-material/Apps';
import CloseIcon from '@mui/icons-material/Close';
// import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
// import CircleIcon from '@material-ui/icons/Circle';
// import CircleIcon from '@material-ui/icons/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



const icons = {
    /*
    'delete': DeleteIcon,
    'menu': MenuIcon,
    'bell': NotificationsNoneIcon,
    'apps': AppsIcon,
    'x': CloseIcon,
    'message': CircleIcon,
    'help': CircleIcon,
    */
}


export function IconButton(props){
    const css = classNames('rds-iconbutton');
    let {type, number} = props;
    const onClick = utils.onClick(props);

    let icon = <Icon type={type} />;
    if(number !== undefined) icon = <Badge badgeContent={number} color="error">{icon}</Badge>
    
    return <MuiIconButton className={css} onClick={onClick}>{icon}</MuiIconButton>;
}

export function IconLink(props){
    const {label, children, type} = props;
    const css = classNames('rds-iconbutton');
    const onClick = utils.onClick(props);
    
    return <Link className={css} onClick={onClick}><Icon type={type} />{label || children}</Link>;
}


export function Icon({type}) {
    switch(type){
        case 'chevron-down2': return <div style={{width: 12, height: 12, display: 'inline-block'}}><KeyboardArrowDown style={{width: 16, height: 16, position: 'relative', top: -4}} /></div>; 
        case 'chevron-down3': return <KeyboardArrowDown style={{width: 16, height: 16, verticalAlign: 'middle'}} />; 
        case 'chevron-down': return <div className="rds-iconvector is-rightside"><KeyboardArrowDown /></div>; 
        default: return <div className={'rds-icon is-type-' + type} />;
    }
    
}


export function IconAdv({type = 'circle', className, onClick}) {
    const css = classNames('rds-iconadv is-type-' + type, className, onClick && 'is-link');
    return <div className={css} onClick={onClick}></div>
}

export function MiniAnim(props){
    const {style, debug} = props;
    const [x, setX] = React.useState(0);

    React.useEffect(() => {
        var intervalId = setInterval(() => {
            setX(x => x + 20);
        }, 200);

        return () => clearInterval(intervalId);
    }, []);


    const css2 = classNames('rds-minianim-container', debug && 'is-debug');
    return (
        <div className={css2} style={style}>
            <div className='rds-minianim' style={{backgroundPositionX: x}} />
        </div>
    );
}

