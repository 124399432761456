import React from "react";
import utils from "../../app/utils";
import {Layout} from "../../foundation/Layout";
import {FilterToolbar, Paginationbar, } from "../../components/Toolbars";
import {Donut, } from "../../components/Misc";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteTooltip, SpriteInput, } from '../../foundation/Sprite';
import {Popover} from '../../foundation';
import {Loader, } from '../../foundation';
import classNames from "classnames";
import "./Taskmgt.scss";


// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';



function Details(props){
    const {debug, } = props;
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    return (
        <Sprite absall debug={debug} style={{top: 87, width: '100%', height: 580, }}>
            {loading && <Loader debug={0} type="comment" style={{top: 130, width: 1000, height: 330, zIndex: 2, }} />}
            <Donut debug={0} style={{top: 205, left: 25, }} values={["5% 500", "15% 1,500", "80% 8,000", ]} />
        </Sprite>
    );
}


function List(props){
    const {debug, } = props;
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => setLoading(false), 2000);
    }, []);
    return (
        <Sprite absall debug={debug} style={{top: 87, width: '100%', height: 580, }}>
            {loading && <Loader debug={DEBUG} type="comment" style={{top: 57, width: '100%', height: 520, zIndex: 2,}} />}
        </Sprite>
    );
}



const DEBUG = 0;

export default function Page(){
    const dispatch = useGlobalsDispatch();
    const [showingList, showList] = React.useState(false);
    const [reviewed, setReviewed] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
    }, [showingList]);


    const onClickBack = () => utils.loader(dispatch, {type: 'setScreen', value: 'taskmgt-list'}, 1000);

    const onClickDetails = () => showList(false);
    const onClickList = () => showList(true);

    const onClickReview = () => {
        dispatch({type: "showPanel", value: "submit-review-panel"});
        setReviewed(true);
    }

    // return
    const css = classNames(showingList ? 'rds-taskmgt-details-list' : 'rds-taskmgt-details');
    return (
        <Layout>

            <Sprite debug={DEBUG} absall className={css} style={{width: 1364, height: 660, left: 0, }}>

                {/* top left */}
                <SpriteButton debug={DEBUG} style={{width: 30, height: 30, }} onClick={onClickBack} />
                <SpriteButton debug={DEBUG} style={{top: 40, width: 110, height: 40, }} onClick={onClickDetails} />
                <SpriteButton debug={DEBUG} style={{top: 40, width: 140, height: 40, left: 110, }} onClick={onClickList} />

                {/* top right */}
                {!reviewed && <SpriteButton className="rds-price-review-button" debug={0} style={{top: 0, left: 'auto', right: 60, }} onClick={onClickReview} />}
                

                {/* content */}
                {showingList ? <List debug={0} /> : <Details debug={0} />}

            </Sprite>

            {showingList && <Paginationbar />}
        </Layout>
    );
}


