import React from 'react';
import ReactDOM from 'react-dom/client';
// import App2 from './app/App';
// import App2 from './-example2/App';
import App2 from './app/App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/font-awesome/css/font-awesome.min.css';

// const root = ReactDOM.createRoot(document.getElementById('root')); // --- KIRK: Strict mode causing double reducer calls.
// root.render(
//   <React.StrictMode>
//     <App2 />
//   </React.StrictMode>
// );

// SIMULATES PRODUCTION
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App2 />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
