import initialState from '../data/data';
import utils from './utils';
import db from './db';
import configs from "./configs";
import {defaults, profiles, } from "../data/data";

const warn = (action, state, msg) => {
    console.warn("REDUCER: ", action.type, " MESSAGE: ", msg);
    return {...state};
}

const appendState = (state, arr) => {
    var r = {...state};
    for(var i=0; i<arr.length; i+=2){
        r[arr[i]] = arr[i+1];
    }
    return r;
}

var testing1 = 0;





const reducer = (state, action) => {
    let {type, value} = action;
    switch(type){
        default: 
            console.warn('reducer() Invalid reducer type: ', type);
            return state;
        case 'hideLoader':
            return {...state, showLoader: false};
        case 'showLoader':
            return {...state, showLoader: true};
        case "logout":
            window.sessionStorage.setItem("loggedIn", false);
            return {...state, loggedIn: false, screen: 'login', showMenu: false, showPanel: false, showLogout: false, };
        case "loginSubmit": // from login screen, go to dashboard
            window.sessionStorage.setItem("loggedIn", true);
            return {...state, loggedIn: true, screen: 'analytics-home'}; // CONFIG: initial screen after login
        case "login": // auto login, go direct to promotions list
            window.sessionStorage.setItem("loggedIn", true);
            var screen = (configs.debug && configs.debug_screen) ? configs.debug_screen : 'home';// DEBUGGING
            return {...state, loggedIn: true, screen: screen};
        case "initiate":
            utils.init(state);
            document.body.classList.toggle('is-app-' + state.app, true); // BASE
            return {...state, initiated: true};
        case "toggleMenu":
            utils.togglePanelClass(false); // new
            return {...state, showMenu: !state.showMenu, showPanel: false};
        case "toggleLogout": // --- BASE
            utils.togglePanelClass(false); // new
            return {...state, showLogout: !state.showLogout, showPanel: false};
        case "removeTask":
            utils.removeByID(state.tasks_rows, value);
            // return {...state};
            return {...state, tasks_rows: state.tasks_rows};
        case "updateOptimizeObject": // --- NEW 1/2
            var {values, name} = action; // should be {values, name, value} but value is already declared
            if(!utils.isObject(values)){
                values = {};
                values[name] = value;
                console.log("+++++++++++++++ updateOptimizeObject 111: ", name, ", value: ", value);
            }
            if(!utils.isObject(values)) return warn(action, state, "Value must be attributes object");
            var {optimizeObject} = state || {};
            var optimizeObject2 = {...optimizeObject, ...values};
            console.log("+++++++++++++++ updateOptimizeObject 222: ", values);
            return {...state, optimizeObject: {...optimizeObject2}}; 
        
        case "showOptimizedOffer": // ALDI 9/9/23
            if(value === undefined) value = true;
            return {...state, showOptimizedOffer: value, };
        case "resetCustomOffer":
            return {...state, customOffer: {...defaults.customOffer}};
        case "updateCustomOffer": // ALDI
            var {values, name} = action; // should be {values, name, value} but value is already declared
            if(!utils.isObject(values)){
                values = {};
                values[name] = value;
            }
            if(!utils.isObject(values)) return warn(action, state, "Value must be attributes object");
            var {customOffer} = state || {};
            var customOffer2 = {...customOffer, ...values};
            return {...state, customOffer: {...customOffer2}};
    
        case "updateOfferModel":
            var {values} = action;
            if(!utils.isObject(values)) return warn(action, state, "Value must be attributes object");
            var offerModel2 = utils.isObject(state.offerModel) ? {...state.offerModel, ...values} : {...values};
            utils.loadDetails(state, "offers", offerModel2);
            return {...state, offerModel: {...offerModel2}};

        case "setOfferScreen":
            var screen = value || '';
            return {...state, offerScreen: screen};
        case "hideOfferPanel":
            utils.togglePanelClass(false); // new
            return {...state, showPanel: false, offerScreen: null, offerModel: null};
        case "showOfferPanel":
            console.log("===================== YOOOOOOOO showOfferPanel");
            var {object, offerID} = action;
            var offerScreen = offerID ? 'details' : 'mainmenu';
            var offerModel = utils.selectRow(state, 'offers', offerID);
            if(!offerModel) offerModel = {}; // --- WIP

            utils.loadDetails(state, "offers", offerModel);// init 
            
            utils.togglePanelClass(true); // new
            const r = {...state, 
                showPanel: true, 
                panelName: 'addoffer', 
                panelAction: action, 
                offerScreen: offerScreen, 
                offerModel: offerModel,
                optimizeObject: {...state.defaults.optimizeObject}, // reset optimize object
            }

            return r;
        case "swappingPromoOffer":
            var {id, newID} = action;
            return {...state, swappingOffers: [id, newID]};
        case "swapPromoOffer":
            var {id, newID} = action;
            var {model} = state;
            if(!model) return warn(action, state, "Missing model");
            model.Offer = newID;
            var offeri = model.Offers.indexOf(id);
            if(offeri !== -1) model.Offers[offeri] = newID;
            db.promos_onLoadDetails(state, model);
            return {...state, model: {...model}, swappingOffers: false};
        case "addingPromoOffer":
            var {id} = action;
            var {model} = state;
            model.Offers.push(id);
            db.promos_onLoadDetails(state, model);
            return {...state, model: {...model}, swappingOffers: [id, id], addedPromoOffer: true, }; // ALDI 9/9/23: "addedPromoOffer"
        case "removePromoOffer": 
            return {...state, addedPromoOffer: false, }; // ALDI 9/9/23
        case "addPromoOffer":
            var {id} = action;
            var {model} = state;
            model.Offers.push(id);
            db.promos_onLoadDetails(state, model);
            return {...state, model: {...model}};
        
        case "cancelPromo": // ------------------- KW 1/9, IMPORTANT: this might mess things up, be careful
            var {model} = state;
            if(!utils.isObject(model)) return {...state, screen: 'promo-list', showMenu: false, };

            // 
            var origrow =  utils.selectRow(state, 'promos2', model.ID);
            var model2 = {...model};
            model2.Offers = [...origrow.Offers];
            var rows = utils.saveRow(state, 'promos', model2);
            return {...state, screen: 'promo-list', showMenu: false, model: {...model2}};
        case "setProfile":  // NEW - BASE
            return {...state, profile: value, profileData: profiles[value] || profiles.anna, };
        case "setApp":  // NEW - BASE
            var app = value || '';

            // set body class
            document.body.classList.toggle('is-app-' + state.app, false);
            document.body.classList.toggle('is-app-' + app, true);

            return {...state, app: app, };
        case "setScreen": 
            var screen = value || '';
            const parts = screen.split('-');
            return {...state, screen: screen, section: parts[0], showMenu: false, showReportsPanel: screen.indexOf("report") !== -1};
        case "showPanel":
            value = utils.toString(value);
            if(!value) console.warn('showPanel(): Value is not a string: ', value);
            utils.togglePanelClass(true); // new
            return {...state, showPanel: true, panelName: value, panelAction: action};
        case "hidePanel":
            utils.togglePanelClass(false); // new
            return {...state, showPanel: false, panelAction: {}};
        case "toggleMessage":
            const {mode, } = action;
            value = value === undefined ? !state.showMessage : utils.toBool(value);
            document.body.classList.toggle('is-show-dialog', value);
            console.log('toggleMessage: ' + value); // TEMP
            // --- WIP
            return {...state, showMessage: value, showMessageMode: mode, showPanel: false, panelAction: {}};
        case "showNote":
            value = value === undefined ? true : utils.toBool(value);
            return {...state, showNote: value, };
        case "getArticleStart": 
            return {...state, isLoading: true,};
        case "showToast": 
            switch(typeof(value)){
                case 'string': value = {open: true, message: value, }; break;
                case 'object': value.open = true; break;
                default: value = {...state.showToast, open: false, }; break;
            }
            return {...state, showToast: value, };
        case "insertColumn": 
            if(value === undefined) value = true;
            return {...state, insertColumn: value, };
        case "showDetails":
            var {table, id, screen} = action; 
            const screens = {promos: "promo-offer", fundings: "funding-general", vehicles: "vehicle-define", };
            var hasid = utils.isTrue(id);
            var row = hasid ? utils.selectRow(state, table, id) : utils.createRow(table);

            // console.log("+++++++++++++++++ showDetails: state: ", state);
            if(!row) return warn(action, state, "Missing record: " + table + " ID: " + id);
            utils.loadDetails(state, table, row);
            return {...state, model: {...row}, screen: screens[table] || state.screen};
        case "cancelDetails":
            var {table, screen} = action; 
            return {...state, model: false, screen: screen || state.screen};
        case "saveDetails":
            if(utils.isNull(state.model)) return warn(action, state, "Model is required");
            var {table, screen} = action; 
            var rows = utils.saveRow(state, table, state.model);
            return rows ? appendState(state, [table + '_rows', rows, 'screen', screen || state.screen]) : {...state};
        case "updateRow":
            var {table, id, values} = action; 
            var sort = state[table + "_sort"];
            var rows = utils.updateRow(state, table, id, values);
            return rows ? appendState(state, [table + '_rows', rows]) : {...state};
        case "updateModel":
            if(!utils.isObject(value)) return warn(action, state, "Value must be attributes object");
            var model2 = utils.isObject(state.model) ? {...state.model, ...value} : {...value};
            utils.loadDetails(state, model2._table, model2);
            return {...state, model: {...model2}};
        // --- queries --- //
        case "sortTable":
            var {table, field} = action;
            var rows = utils.requireTableRows(state, table);
            if(!rows) return {...state}; // invalid table

            // sorting
            var [sortfield, sortasc] = state[table + "_sort"];
            var asc = (sortfield === field) ? !sortasc : true;
            utils.sortRows(rows, [field, asc]);

            // return
            return appendState(state, [table + '_rows', rows, table + '_sort', [field, asc]])
    }
}

export {initialState, reducer};
export default reducer;