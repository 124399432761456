

var uid = 20001;


const locations = [
    [3,6,8],
    [2,4,8,9],
    [1,3,5,8,10],
];

function printDate(d){
    return (d.getMonth() + 1) + "/" + d.getDate()+"/"+d.getFullYear();
}

const gen = {

    periodStart: new Date('1/1/2024'),
    periodEnd: new Date('1/13/2024'),

    locations: [[3,6,8],[2,4,8,9],[1,3,5,8,10],],

    ranges: [null, 
        50, 100, 
        5, 15, 
        0.6, 3.8, 
        10, 50, 
        10, 250, 
        1, 3,
        1, 10, 
        5, 30,
    ],

    rows: (count, func) => {
        var rows = [];
        for(var i=0; i<count; i++){
            rows.push(func(i));
        }
        return rows;
    },


    // fields
    uid: () => (uid++),
    id: (i) => (1 + i),
    extId: (i) => (2000002 + i),
    intId: (i) => (30001 + i),
    locationField: () => gen.randomItem(locations),



    // functions
    nextItem: (i, items) => (items[i%items.length]),

    randomRange: (min, max) => parseInt(Math.random() * (max - min) + min),

    randomItem: (items) => {
        var j = gen.randomRange(0, items.length);
        return items[j];
    },

    nextDay: (i, d, offset=1) => {
        const d2 = new Date(d);
        d2.setDate(d.getDate() + (i*offset));
        return printDate(d2);
    },

    offsetDayNum: (i, d, offset) => {
        const d2 = new Date(d);
        d2.setDate(d.getDate() + (i*offset));
        return Number(d2);
    },


};


export default gen;