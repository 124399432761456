import React from "react";
import utils from "../app/utils";
import { useGlobalsDispatch, useGlobals } from '../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar3, Paginationbar} from '../components/Toolbars';
import {DataList, DataRow, DataCell, Sprite, SpriteButton, Icon, Select} from '../foundation';
import "./Timeline.scss";


function StatusBlock({row}){
    //<div className="rds-statusblock"><b>Approved</b></div>
    // <div className="rds-statusblock"><Select type="status" value="Approved" /></div>
    const status = row.Status;
    if(!status) return;
    const css = "rds-statusblock is-type-" + utils.toLowerCase(status);

    return (
        <div className={css}><Select type="status" value={status} /></div>
    );
}


function TopCell({date, key}){
    return <th key={key}><b>{utils.printDay(date)}</b> {utils.printNiceDate(date)}</th>;
}

function Cell({date, key, row, show}){
    return <td key={key}>{show && <StatusBlock row={row} />}</td>
}

function Cell2({date, key, value}){
    return <td key={key}>{value}</td>
}

function TimeSpan({rows, initDate}){
    const {timelineDate} = useGlobals();
    var initdate = timelineDate; // "12/1/2022"


    var dates = [];
    for(var i=0; i<52; i++) {
        var d = new Date(initdate);
        d.setDate(d.getDate() + i);
        dates.push(d);
    }

    var testi = 0;
    var testcells = {2:1, 55:1, 108:1, 159:1, 266:1, 314:1, 370:1, 422:1, 422:1, 
        471:1, 526:false, 578:false, 631:1, 684:1, 732:1, 783:1, 840:1, 995:1, 1096:1, 1149:1, 1204:1    
    };

    return (
        <div className="rds-timespan">
        <table className="rds-timespan">
            <thead>
            <tr>
                {dates.map((d, i) => (<TopCell date={d} key={i} />))}
            </tr>
            </thead>
            {rows.map((row, rowi) => {
                const css = (rowi%2) ? 'is-even' : 'is-odd';

                return (
                    <tbody>
                    <tr key={rowi} className={css}>
                        {dates.map((d, coli) => {
                            testi++
                            // --- WIP{testi} 
                            // return <Cell2 key={coli} value={testi} />; // KEEP THIS FOR TESTING
                            return <Cell key={coli} row={row} show={testcells[testi]} />
                            // return <td key={coli}>{testcells[testi] && <StatusBlock row={row} />}</td>
                        })}
                    </tr>
                    </tbody>
                );
            })}

        </table>
        </div>

    );


}


function TimeSpan222({rows, initDate}){


    var initdate = new Date("12/1/2022");
    var dates = [];
    for(var i=0; i<100; i++) {
        var d = new Date("12/1/2022");
        d.setDate(d.getDate() + 1);
        dates.push(d);
    }


    return (
        <table className="rds-timespan">
            <tr>
                <th>Sun <b>Nov 21</b></th>
                <th>Sun <b>Nov 21</b></th>
                <th>Sun <b>Nov 21</b></th>
                <th>Sun <b>Nov 21</b></th>
            </tr>
            <tr className="is-odd">
                <td>asdf</td>
                <td>asdf</td>
                <td>asdf</td>
                <td>asdf</td>
            </tr>
            <tr className="is-even">
                <td>asdf</td>
                <td>asdf</td>
                <td>asdf</td>
                <td>asdf</td>
            </tr>
        </table>
    );


}



export function Timeline({rows, start, end, type}){
    const dispatch = useGlobalsDispatch();
    const ispromo = (type === 'promo');
    //const globals = useGlobals();
    var rows2 = [...rows];
    rows2.splice(10, 100);


    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const handleClick = (event) => {setAnchor(event.currentTarget)};
    const handleClose = () => {setAnchor(null)};


    const headers = [
        {name: 'Name'},
    ];



    // handlers
    const onClickSort = (header) => {
        dispatch({type: "sortTable", table: "vehicles", field: header.name});
        // dispatch({type: "sortTable", value: ['vehicles', header.name]});
    }

    const onClickName = (item) => {
        if(ispromo) dispatch({type: "showPanel", value: "promo-details", table: "promos", id: item.ID});
        else dispatch({type: "showDetails", table: "vehicles", id: item.ID});
    }

    function onClickView(item){ // NEW 1/9
        const table = ispromo ? "promos" : "vehicles";
        // consol
        dispatch({type: "hidePanel"});
        dispatch({type: "showDetails", table: table, id: item.ID});
    }

    return (
        <table className="rds-timline">
            <tr>
                <td className="rds-timline-left">
                    <DataList className="is-nooverflow" headers={headers} onClickSort={onClickSort} onChecked={() => {}}>
                        {rows2.map((item, i) => ( 
                                <DataRow onChecked={() => {}} index={i} key={i} onClickView={() => onClickView(item)}>
                                    <DataCell name="Name" className="is-wrap222" onClick={() => {onClickName(item)}}>{item.Name}</DataCell>
                                </DataRow>
                        ))}
                    </DataList>
                </td>
                <td className="rds-timline-right">
                    <TimeSpan rows={rows2} />
                </td>
            </tr>
        </table>
    );

}   

