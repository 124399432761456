import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton, SpriteCheckbox, SpriteInput, PanelBottom, Group, EditTableList, } from '../foundation';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';



export default function Panel({name, }){
    const dispatch = useGlobalsDispatch();
    const DEBUG = 0;

    // values
    const [changed, setChanged] = React.useState(false);
    const [values, setValues] = React.useState({
        apply: true,
        search: "$0.90",
    });
    const onChange = (e) => {
        const target = e?.target || {};
        values[target.name] = (target.type === 'checkbox') ? target.checked : (target.value || '');
        setValues({...values});
        setChanged(true);
    }

    // actions
    const onCancel = () => {dispatch({type: 'hidePanel'})}
    const onSave = () => {dispatch({type: 'hidePanel'})}


    // return
    return (
        <React.Fragment>

            <Sprite absall className="rds-price-lock-edit-panel" style={{width: 425, height: 618, cursor222: 'text', }}>

                <SpriteCheckbox debug={DEBUG} style={{top: 38, left: -2, width: 240, }} name="apply" checked={values.apply} onChange={onChange} />

                <SpriteInput debug={DEBUG} style={{top: 286, left: 18, width: 180, }} name="search" onChange={onChange} value={values.search} placeholder="Search"  />

                
            </Sprite>

            <PanelBottom>
                <Group type="cancel-save" onCancel={onCancel} onSave={onSave} disabled={!changed} />
            </PanelBottom>

        </React.Fragment>


        


    );

}
