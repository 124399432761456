import gen from "./_generator";
import periods from "./periods";
import utils from "../app/utils";
import data from "./fundingsData";

// iterate promos
var rows = gen.rows(data.length, (i) => {
    // if(i) return // --- TEMP: only do first
    const row = data[i];


    row.Promotion_Count = gen.randomItem([10, 8, 6, 14, 12, 18]);
    row.Products = [58,59,60,64,65,66,67];
    row.Locations = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100];

        // extras
        row._Products_Expanded = [];
        row._Locations_Expanded = [];
        row._Upc_Numbers = null;
    
    // row.Funding_Type = gen.randomItem(["Off Invoice", "ScanBack", "BillBack", "Allowance", "Accrual", "Incentive"]);



    // return
    return utils.createRow("fundings", row);
});


export default rows;



/*
var rows = gen.rows(10, (i) => {
    // const type = gen.randomItem(["PCT", "USD"]);
    const period = gen.randomItem(periods);

    return {
        ID: gen.id(i),
        Internal_ID: (100594 + i), // --- KW added because of prototype
        Name: "Funding " + (i+1),
        Description: "Funding description",
        Status: gen.randomItem(["Planning", "Approved", "Active"]),
        Custom_Labels: "2023 Father's Day, Coke, Coca-Cola, Bev", // STRING? what is this
        Start_Date: period.Start_Date,
        End_Date: period.End_Date,
        Period: period.Name,
        Promotion_Count: gen.randomItem([10, 8, 6, 14, 12, 18]), // --- KW added based on Figma prototype
        Funding_Type: gen.randomItem(["Off Invoice", "ScanBack", "BillBack", "Allowance", "Accrual", "Incentive"]),
        Funds: "", // ???   
        Fund_Symbol: gen.randomItem(["%", "$"]),
        Per: gen.randomItem(["Case", "Item", "Period"]),
        Trigger_Event: gen.randomItem(["Order", "Sale", "Demand", "Period End"]),
        Vendor: gen.randomItem(["McLane", "Captivating Headwear", "DAS", "AB Mitchell Columbus", "Dr Pepper/Snapple SW", "Craig Stein Beverage"]),
        Products: gen.randomItem([[3,6,8], [2,4,8,9],]),
        Brands: "",
        Locations: gen.randomItem([[3,6,8], [2,4,8,9], [1,3,5,8,10]]),
        // Conditions: null, // NOTE: leave null for now
        Accounting_System_ID: "",
        Funding_Source: gen.randomItem(["Account", "Invoice", "Cost Reduction"]),
        PnL_Attribution: gen.randomItem(["Split", "COGS Reduction", "Income"]),
        Expiration_Action: gen.randomItem(["Cease", "Lose", "Roll"]),
    };
});

export default rows;

*/
