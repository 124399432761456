// import {default as MuiButton} from '@mui/material/Button';
import {default as MuiLink} from '@mui/material/Link';
import classNames from 'classnames';
import {Icon} from './Icon';
import "./Text.scss";


export function Text({type, children}) {
    const css = classNames({'rds-testing': type});
    return <div className={css}>{children}</div>
}

export function Title({label, children}) {
    const css = classNames('rds-title');
    return <div className={css}>{label || children}</div>
}

export function Link(props) {
    let {dispatch, screen, children, label, block, classes, onClick, icon} = props;
    if(icon) icon = <Icon type={icon} />

    if(screen && dispatch) onClick = () => dispatch({ type: "setScreen", value: screen });

    // css
    const css = classNames('rds-link', classes, {'is--block': block, 'rds-link--iconic': icon});
    return <MuiLink className={css} onClick={onClick} component="button">{icon}{label || children}</MuiLink>
}


export function Badge(props){
    const {label, className, type} = props;
    const css = classNames('rds-badge', type && ("is-type-" + type), className);
    return <div {...props} className={css}>{label}</div>
}
/*
export function Link2(props){
    const blah = 'script:0;';
    const href = props.href || ('java' + blah);
    return <a {...props} href={href}>{props.label||props.children}</a>
}
*/
// export function I

