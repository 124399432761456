import React from "react";
import {Button2} from '../foundation/Button';
import {Sprite, SpriteButton} from '../foundation';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import utils from '../app/utils';



export default function ImportPanel({name}){
    const dispatch = useGlobalsDispatch();
    const onClickCancel = () => {dispatch({type: 'hidePanel'})}
    console.log("++++++++++++++++++ StaticPanel: ", name);

    return (
        <Sprite className="rds-panel-import" style={{width: 420, height: 680}}>
            <SpriteButton className="is-abs-right" debug={0} style={{top: 620, right: 100, width: 80}} onClick={onClickCancel} />
            <SpriteButton className="is-abs-right" debug={0} style={{top: 620, right: 0, width: 80}} onClick={onClickCancel} />
        </Sprite>
    );
}
