import React from "react";
import {Layout} from "../../foundation/Layout";
import utils from "../../app/utils";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar3, Paginationbar} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Sprite, SpriteButton, Icon, Select} from '../../foundation';



export default function Screen(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var rawrows = globals.fundings_rows;

    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const handleClick = (event) => {setAnchor(event.currentTarget)};
    const handleClose = () => {setAnchor(null)};

    // DEBUG
    React.useEffect(() => {
        // console.log('++++++++++++++++ PROMOS: ', globals.promos_rows);
    }, [globals.promos_rows]);



    const onClickEditTable = () => dispatch({type: "showPanel", value: "edit-table"});
    const onClickCards = () => dispatch({type: "setScreen", value: "funding-cards"});
    const onClickTimeline = () => dispatch({type: "setScreen", value: "funding-timeline"});


    const headers = [
        {name: 'Name'},
        {name: 'Status'},
        {name: 'Start_Date', label: 'Start date'},
        {name: 'End_Date', label: 'End date'},
        {name: 'Promotion_Count', label: '# of promotions', right: true},
        {name: 'Funding_Type'},
        // {name: 'Vendor'},
    ];
    // const rows  = utils.remakeRows(rawrows, headers); // ['Name', 'Status', "Start_Date", "End_Date"]
    const rows = rawrows;

    // handlers
    const onClickSort = (header) => {
        dispatch({type: "sortTable", table: "fundings", field: header.name});
        // dispatch({type: "sortTable", value: ['fundings', header.name]});
    }

    const onClickName = (item) => {
        dispatch({type: "showDetails", table: "fundings", id: item.ID});
    }

    function onClickView(item){
        dispatch({type: "hidePanel"});
        dispatch({type: "showDetails", table: "fundings", id: item.ID});
    }

    return (
        <Layout>
            <Searchbar />

            <Titlebar3 type="funding-list">
                <SpriteButton className="is-abs-right is-debu" style={{width: 100, right: 100}} onClick={onClickEditTable} />
                <SpriteButton className="is-abs-right is-debu" style={{width: 20, right: 30}} onClick={onClickCards} />
                <SpriteButton className="is-abs-right is-debu" style={{width: 20}} onClick={onClickTimeline} />
            </Titlebar3>


            <DataList className="is-nooverflow" headers={headers} onChecked={() => {}} onClickSort={onClickSort}>
              {rows.map((item, i) => i<10 && ( // --- LIMIT to 10 
                    <DataRow onChecked={() => {}} index={i} key={i} onClickView={onClickView} item={item}>
                        <DataCell name="Name" className="is-wrap" onClick={() => {onClickName(item)}}>{item.Name}</DataCell>
                        <DataCell name="Status"><Select type="status" value={item.Status} /></DataCell>
                        <DataCell name="Start_Date">{utils.printDate(item.Start_Date)}</DataCell>
                        <DataCell name="End_Date">{utils.printDate(item.End_Date)}</DataCell>
                        <DataCell name="Promotion_Count">{item.Promotion_Count}</DataCell>
                        <DataCell name="Funding_Type">{item.Funding_Type}</DataCell>
                        {false && <DataCell name="Vendor">{item.Vendor}</DataCell>}
                    </DataRow>

              ))}
            </DataList>




            <Paginationbar />
        </Layout>
    );
}
