import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteField, SpriteButton, SpriteSelect} from "../../foundation";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';

const options = [
    {value: "Accrual", label:"Accrual"},
    {value: "Allowance", label:"Allowance"},
    {value: "Billback", label:"Billback"},
    {value: "Incentive", label:"Incentive"},
    {value: "IO", label:"IO"},
    {value: "Scanback", label:"Scanback"},
];

const options2 = [
    {value: "Case", label:"Case"},
    {value: "Item", label:"Item"},
    {value: "Period", label:"Period"},
];

const options3 = [
    {value: "On Order", label:"On order"},
    {value: "On Sale", label:"On sale"},
    {value: "On Demand", label:"On demand"},
    {value: "On End", label:"On end"},
];

export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 0;

    const onType = (e) => {
        const value = utils.castField("fundings", "Funding_Type", e.target.value, record.Funding_Type);
        dispatch({type: "updateModel", value: {Funding_Type: value}});
    }

    const onFunds = (e) => {
        const value = utils.castField("fundings", "Funds", e.target.value, record.Funds);
        dispatch({type: "updateModel", value: {Funds: value}});
    }

    return (
            <Sprite debug={0} className="rds-funding-funds  is-abs-all" style={{height:600, width: 'auto'}}>
                <SpriteSelect debug={0} type="fundings.Funding_Type" onChange={onType} options={options} value={record.Funding_Type} style={{top: 76, left: 10, width: 370}} />
                <SpriteField debug={0} onChange={onFunds} value={record.Funds} style={{top: 164, left: 10, width: 130}} />
                <SpriteSelect debug={0} value={record.Per} options={options2} style={{top: 164, left: 250, width: 140}} />
                <SpriteSelect debug={0} value={record.Trigger} options={options3} style={{top: 252, left: 10, width: 380}} />
                <SpriteButton debug={0} style={{top: 336, left: 10, width: 380}} />

                
            </Sprite>
    );
}

