import { createContext, useContext, useReducer } from 'react';
import {reducer, initialState} from './reducer';


const GlobalContext = createContext(null);
const GlobalsDispatchContext = createContext(null);

// provider
export function GlobalsProvider({ children }) {
  const [tasks, dispatch] = useReducer(
    reducer,
    initialState
  );

  return (
    <GlobalContext.Provider value={tasks}>
      <GlobalsDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalsDispatchContext.Provider>
    </GlobalContext.Provider>
  );
}

export function useGlobals() {
  return useContext(GlobalContext);
}

export function useGlobalsDispatch() {
  return useContext(GlobalsDispatchContext);
}
