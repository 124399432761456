

const fundings = [
    'Scanback', 
    'Inventory Ivents',
    'Billback',
    'Growth Funding'
];

const upcs = [
    '2-2424-1729-104', 
    '1-8711-7626-232hh'
];
const suppliers = [
    '1-2323 Beyond Meat',
    '1-9274 Tofurky',
    '4-5629 Thomas Farms',
    '9-1742 Dukes Hatch',
    '3-3280 Companion',
    '1-4234 Hillshire Farms',
    '6-1411 Artisan Meat',
    '4-2321 Wellsley Farms ',
    '7-7829 Troyer Cheese ',
];

const vehicles = [
    'Radio',
    'Billboard',
    'TV',
    'Outdoor and transit',
    'Magazine',
];

const startdates = [
    '04/26/2021',
    '07/04/2021',
    '06/01/2021',
];

const enddates = [
    '04/26/2021',
    '07/04/2021',
    '06/01/2021',
];

/*

                        <DataCell>{item.name}</DataCell>
                        <DataCell>{item.status}</DataCell>
                        <DataCell>{item.startdate}</DataCell>
                        <DataCell>{item.enddate}</DataCell>
                        <DataCell>{item.funding}</DataCell>
                        <DataCell>{item.upc}</DataCell>
                        <DataCell>{item.supplier}</DataCell>
                        <DataCell>{item.vehicle}</DataCell>
*/

var index = 1;
export const data = [
    {
        id: index++,
        name: "Beef Sirloins, Eight 8 oz Steaks TPR $110.65",
        status: "Pending",
        upc: upcs[0],
        startdate: startdates[0],
        enddate: enddates[0],
        funding: fundings[0],
        supplier: suppliers[0],
        vehicle: vehicles[0],
    },
    {
        id: index++,
        name: "Plant-Based Ground Beef 4-pack $2.00 OFF",
        status: "Pending",
        upc: upcs[1],
        startdate: startdates[0],
        enddate: enddates[0],
        funding: fundings[1],
        supplier: suppliers[1],
        vehicle: vehicles[1],
    },
    {
        id: index++,
        name: "Plant-Based Ground Beef 4-pack $2.00 OFF",
        status: "Pending",
        upc: upcs[1],
        startdate: startdates[0],
        enddate: enddates[0],
        funding: fundings[2],
        supplier: suppliers[2],
        vehicle: vehicles[2],
    },
    {
        id: index++,
        name: "Thomas Farms Grass Fed 85/15 Ground Beef...",
        status: "Approved",
        upc: upcs[0],
        startdate: startdates[1],
        enddate: enddates[1],
        funding: fundings[3],
        supplier: suppliers[3],
        vehicle: vehicles[3],
    },
    {
        id: index++,
        name: "Duke's Hatch Green Chile Smoked Shorty...",
        status: "Approved",
        upc: upcs[1],
        startdate: startdates[1],
        enddate: enddates[1],
        funding: fundings[2],
        supplier: suppliers[4],
        vehicle: vehicles[4],
    },
    {
        id: index++,
        name: "Companion Peking Vegetarian Roast Duck, 10...",
        status: "Approved",
        upc: upcs[0],
        startdate: startdates[1],
        enddate: enddates[1],
        funding: fundings[0],
        supplier: suppliers[5],
        vehicle: vehicles[0],
    },
    {
        id: index++,
        name: "Hillshire Farms Chicken Breasts TPR $3.88/lb.",
        status: "Active",
        upc: upcs[1],
        startdate: startdates[2],
        enddate: enddates[2],
        funding: fundings[1],
        supplier: suppliers[6],
        vehicle: vehicles[1],
    },
    {
        id: index++,
        name: "Dry Artisan Dry Aged Steak Pack TRP $11.89",
        status: "Active",
        upc: upcs[1],
        startdate: startdates[2],
        enddate: enddates[2],
        funding: fundings[2],
        supplier: suppliers[7],
        vehicle: vehicles[2],
    },
    {
        id: index++,
        name: "Wellsley Farms Premium Chunk Chicken Brea...",
        status: "Active",
        upc: upcs[0],
        startdate: startdates[2],
        enddate: enddates[2],
        funding: fundings[3],
        supplier: suppliers[8],
        vehicle: vehicles[3],
    },
    {
        id: index++,
        name: "Troyer Cheese Fully Cooked Boneless USA....",
        status: "Active",
        upc: upcs[0],
        startdate: startdates[2],
        enddate: enddates[2],
        funding: fundings[2],
        supplier: suppliers[0],
        vehicle: vehicles[4],
    },
];



/*
for(i=0; i<=promos.length; i++){
    promo
}
*/

export default data;