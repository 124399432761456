import gen from "./_generator";
import data from "./locationsData";
import utils from "../app/utils";

const rows = data; // NOTE: direct transfer

// TEST DATA...
// const tests = [0.88, 0.55, 0.67, 0.97, 0.68, 0.53, 0.58, 0.87, 0.67, 0.69];
// tests.forEach((num, i) => {
//     rows[i].Volume_Modifier = num;
// });



// const isdata = true; // CONFIG
// utils._makeLocationsTree(data);

/*
var rows = gen.rows(data.length, (i) => {
    const obj = data[i];
    var obj222 = {
        // ID: gen.id(i),
        // Name: "Location " + (i+1),
        // Volume_Modifier: gen.randomRange(gen.ranges[1], gen.ranges[2])/100,

        ID: data.ID,
        Store_Name: data.Store_Name,
        Zone_Name: data.Zone_Name,
    };

    const row = {};
    row.ID = gen.id(i);

    row.Store_ID = obj.Store_ID;
    row.Store_Name = obj.Store_Name;
    row.Zone_Name = obj.Zone_Name;
    row.Volume_Modifier = obj.Volume_Modifier;

    // from data...



    // obj.Name = gen.nextItem(i, data).storename;

    // return
    return utils.createRow("locations", obj);
});
*/

export default rows;

