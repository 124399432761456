import React from "react";
import {Layout} from "../../foundation/Layout";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import {Searchbar, Titlebar, Paginationbar, Titlebar3} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Template, SpriteButton} from '../../foundation';
import {Timeline} from '../../components/Timeline';
// import {Link2} from '../../foundation/Text';
import {Icon} from '../../foundation/Icon';
import {Button2} from '../../foundation/Button';




export default function Screen(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    var rows = globals.vehicles_rows;






    // --- YOOOOOO
    // anchor, value, open, onClose
    // const onClickList = () => {dispatch({type: "setScreen", value: "vehicle-list"})}
    const onClickList = () => {dispatch({type: "setScreen", value: "vehicle-list"})};
    const onClick = () => {};
    return (
        <Layout>
            <Searchbar />

            <Titlebar3 type="vehicle-timeline">
                <SpriteButton className="is-abs is-debu" style={{width: 100, left: 260}} onClick={onClick} />
                <SpriteButton className="is-abs-right is-debu" style={{width: 20, right: 30}} onClick={onClickList} />
            </Titlebar3>



            <Timeline rows={rows} />





            <Paginationbar />
        </Layout>
    );
}
