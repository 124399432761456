import React from "react";
import {Layout} from "../../foundation/Layout";
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import CheckboxTree from '../../-examples/CheckboxTree';
import {Searchbar, Titlebar, Paginationbar, Titlebar3} from '../../components/Toolbars';
import {DataList, DataRow, DataCell, Template, SpriteButton} from '../../foundation';
// import {Link2} from '../../foundation/Text';
import {Icon} from '../../foundation/Icon';
import {Button2} from '../../foundation/Button';




export default function Screen(){

    // <path fill="none" stroke="red" d="M20,20 L50,80 L80,20" />
    // <path stroke="black" fill="none" d="M0 0 L100 50" />
    // stroke:rgb(255,0,0);stroke-width:2
    return (
        <div>
            <h1>TESTING</h1>
            <svg height="100" width="100" style={{backgroundColor: 'gray'}}>
                <line x1="0" y1="0" x2="100" y2="50" stroke="black" style={{stroke: 'rgb(255,0,0)', strokeWidth: 1}} />
            </svg>
        </div>
    );
}
