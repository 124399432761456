import React from "react";
import {Split, SplitLeft, SplitRight} from '../foundation/Layout';
import classNames from 'classnames';
import utils from '../app/utils';
import {useGlobals, useGlobalsDispatch} from '../app/context';
import {Button} from '../foundation/Button';
// import Badge from '@mui/material/Badge';
import {Link, Title} from '../foundation/Text';
import {IconButton, IconLink} from '../foundation/Icon';
import {Sprite, SpriteButton} from '../foundation/Sprite';
import Popover from '@mui/material/Popover';
import './GlobalNav.scss';




function MainMenuClick(dispatch, name){
    console.log('------ IT WORKS: ' + name);
    dispatch({type: 'setScreen', value: name});
}

function MainMenuGroup({name, items}){
    const dispatch = useGlobalsDispatch();
    
    return (
        <div className={'rds-mainmenu-group is-name-' + name}>
            <SpriteButton debug={0} onClick={() => MainMenuClick(dispatch, name)} />
            <ul>
            {items && items.map((str, i) => <li key={i}><SpriteButton onClick={() => MainMenuClick(dispatch, str)} /></li>)}
            </ul>
        </div>
    );
}



// --- BASE
export function BaseMenu(props){
    const {closer, } = props;
    const dispatch = useGlobalsDispatch();
    const css = classNames("rds-basemenu is-clear");

    const onClick = (screen) => {
        utils.loader(dispatch, {type: 'setScreen', value: screen}, 1000); // loader
        closer();
    };

    // return
    return (
        <Sprite absall className={css}>
            <SpriteButton debug={0} style={{left: 30, top: 100, width: 140, }} onClick={() => onClick('base-home')} />
            <SpriteButton debug={0} style={{left: 30, top: 160, width: 160, }} onClick={() => onClick('taskmgt-list')} />
            <SpriteButton debug={0} style={{left: 30, top: 220, width: 120, }} onClick={() => onClick('price-list')} />
            <SpriteButton debug={0} style={{left: 30, top: 260, width: 120, }} onClick={() => onClick('price-list')} />
            <SpriteButton debug={0} style={{left: 30, top: 300, width: 120, }} onClick={() => {}} />
        </Sprite>
    );
}


export default function MainMenu(props){
    const {onClickItem, closer, } = props;
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();
    const {app} = globals;
    const css = classNames("rds-mainmenu is-clear");
    let i = 0;

    const onClick = (screen) => {
        const action = {type: 'setScreen', value: screen};
        if(screen === 'reports') utils.loader(dispatch, action, 1000); // loader
        else dispatch(action);
        if(onClickItem) onClickItem(screen);
        closer();
    }

    // base app
    if(app === 'base') return <BaseMenu closer={closer} />;

    // promo app
    return (
            <Sprite absall className={css}>
                <SpriteButton debug={0} style={{left: 30, top: 100, width: 140, }} onClick={() => onClick('dashboard')} />

                <SpriteButton debug={0} style={{left: 30, top: 160, width: 140, }} onClick={() => onClick('promos')} />
                {false && <SpriteButton debug={0} style={{left: 30, top: 200, width: 140, }} onClick={() => onClick('promos2')} />}
                {false && <SpriteButton debug={0} style={{left: 30, top: 240, width: 140, }} onClick={() => onClick('promos3')} />}

                <SpriteButton debug={0} style={{left: 30, top: 300, width: 140, }} onClick={() => onClick('offers')} />
                {false && <SpriteButton debug={0} style={{left: 30, top: 340, width: 140, }} onClick={() => onClick('offers2')} />}
                {false && <SpriteButton debug={0} style={{left: 30, top: 380, width: 140, }} onClick={() => onClick('offers3')} />}

                <SpriteButton debug={0} style={{left: 30, top: 430, width: 140, }} onClick={() => onClick('vehicles')} />
                {false && <SpriteButton debug={0} style={{left: 30, top: 470, width: 140, }} onClick={() => onClick('vehicles2')} />}
                {false && <SpriteButton debug={0} style={{left: 30, top: 510, width: 140, }} onClick={() => onClick('vehicles2')} />}

                <SpriteButton debug={0} style={{left: 300, top: 100, width: 140, }} onClick={() => onClick('funding')} />
                <SpriteButton debug={0} style={{left: 300, top: 230, width: 140, }} onClick={() => onClick('reports')} />
                <SpriteButton debug={0} style={{left: 300, top: 300, width: 140, }} onClick={() => onClick('help')} />


                <SpriteButton debug={0} style={{left: 560, top: 100, width: 140, }} onClick={() => onClick('settings')} />




            </Sprite>
    );
}

/*
                <MainMenuGroup key={i++} name="dashboard" />
                <MainMenuGroup key={i++} name="promos" items={["promo-cal", 'view-promos', 'import-promos']} />
                <MainMenuGroup key={i++} name="offers" />
                <MainMenuGroup key={i++} name="profile" items={["system-members", "settings"]} />

                <MainMenuGroup key={i++} name="reports" />
                <MainMenuGroup key={i++} name="automation" />
                <MainMenuGroup key={i++} name="help" />
                
                <MainMenuGroup key={i++} name="vehicles" />
                <MainMenuGroup key={i++} name="funding" />
                <MainMenuGroup key={i++} name="privacy" />

*/