import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteField, SpriteSelect, Select} from "../../foundation";
import {DateSelect} from "../../components/DateSelect";


export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 0;

    const onName = (e) => {
        const value = utils.toFilledString(e.target.value, record.Name);
        dispatch({type: "updateModel", value: {Name: value}});
    }

    const onIntID = (e) => {
        const value = utils.toFilledString(e.target.value, record.External_ID);
        dispatch({type: "updateModel", value: {External_ID: value}});
    }

    const onDesc = (e) => {
        const value = utils.toFilledString(e.target.value, record.Description);
        dispatch({type: "updateModel", value: {Description: value}});
    }

    const onStatus = (e) => {
        const value = utils.toFilledString(e.target.value, record.Status);
        dispatch({type: "updateModel", value: {Status: value}});
    }

    const onCustom = (e) => {
        const value = utils.toFilledString(e.target.value, record.Custom_Labels);
        dispatch({type: "updateModel", value: {Custom_Labels: value}});
    }

    const onStatusChange = () => {}; // WIP


    return (
        <Sprite className="rds-funding-general is-abs-all" style={{height:600, width: 884}}>
            <SpriteField onChange={onName} debug={debug} value={record.Name} style={{top: 73, left: 7, width: 400}} />
            <SpriteField disabled={1} debug={0} value={record.Internal_ID} style={{top: 161, left: 7, width: 400}} />

            <SpriteButton debug={0} style={{top: 244, left: 7, width: 400}} />

            <SpriteField onChange={onDesc} debug={0} value={record.Description} style={{top: 336, left: 7, width: 400}} />

            
            <Select type="status-solid" value={'Active'} onChange={onStatusChange} className="is-abs" style={{top: 420, left: 2, width: 418}} />
            <SpriteField debug={0} value={record.Custom_Labels} style={{top: 512, left: 7, width: 400}} />
        
            <DateSelect style={{top: 42, left: 464}} start={record.Start_Date} end={record.End_Date} onStart={()=>{}} onEnd={()=>{}}  />
        </Sprite>
    );
};

// {false && <SpriteField onChange={onStatus} debug={debug} value={record.Status} style={{top: 337, left: 7, width: 400}} />}
// <SpriteSelect onChange={onCustom} debug={0} value={record.Custom_Labels} style={{top: 425, left: 7, width: 400}} />