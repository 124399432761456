import React from "react";
// import {utils} from "../functions"; // --- KEEP
import utils from "../app/utils";
import {Sprite,  SpriteText} from "../foundation";
import Popover from '@mui/material/Popover';
import {useGlobals, useGlobalsDispatch} from '../app/context';

import "./GraphView.scss";
import { red } from "@mui/material/colors";
import Check from "@mui/icons-material/Check";
// import {Layout} from "../components";

const debug = 0;
const GRAPH_HEIGHT = 196;

function AxisLabel(comp) {
    const css = utils.classNames("lds-graphview-axis", comp.className);
    return <SpriteText debug={debug}  className={css} {...comp} />;
}

function AxisLabels({unit, type}){
    var items = [];
    for(var i=0; i<8; i++){
        var str = String(unit * i);
        if(type === 'pct') str += '%';
        items.push(str);
    }
    return (
        <Sprite debug={debug} className={"lds-graphview-axises is-type-" + type}>
            <AxisLabel label={items[7]} style={{top: 14, left: 0}} />
            <AxisLabel label={items[6]} style={{top: 42, left: 0}} />
            <AxisLabel label={items[5]} style={{top: 70, left: 0}} />
            <AxisLabel label={items[4]} style={{top: 98, left: 0}} />
            <AxisLabel label={items[3]} style={{top: 126, left: 0}} />
            <AxisLabel label={items[2]} style={{top: 154, left: 0}} />
            <AxisLabel label={items[1]} style={{top: 182, left: 0}} />
            <AxisLabel label={items[0]} style={{top: 210, left: 0}} />
        </Sprite>
    );
}


function toInt(n){
    n = parseInt(n);
    return isNaN(n) ? 0 : n;
}

function GraphCell({bar, onMouseEnter, onMouseLeave, onClick}) {
    const {inner_ratio, outer_ratio, selected, swapping} = bar;

    const outer_h = toInt(outer_ratio * GRAPH_HEIGHT);
    const inner_h = inner_ratio ? toInt(inner_ratio * GRAPH_HEIGHT) : false;
    const sel_h = outer_h + 20;
    // const dot_h = bar.dot_ratio ? toInt(bar.dot_ratio * GRAPH_HEIGHT) : false;
    const dot_h = bar.dot_h;
    const dot_h2 = bar.dot_h2;
    const css = utils.classNames(selected && 'is-selected', swapping && 'is-swapping');

    function onMouseEnter2(e){
        return onMouseEnter(e, bar);
    }

    function onClick2(e){
        return onClick(e, bar);
    }

    function Line(props){
        const {dot_h, dot_h2} = props;
        if(dot_h2 === null) return;

        var h = (dot_h - dot_h2);
        var y2 = h;
        var y1 = 0;
        var top = 0;

        if(dot_h < dot_h2){
            h = (dot_h2 - dot_h);
            top = (dot_h - dot_h2);
            y1 = h;
            y2 = 0;
        } else if(dot_h == dot_h2) {
            h = 1;
            y2 = 0;
        }

        return (
            <svg style={{width: 100, height: h, top: top}}>
                <line x1="0" y1={y1} x2="100" y2={y2} />
            </svg>
        );
    }

    return (
        <td align="center" valign="bottom" className={css}>
            <div className="lds-graphview-bar" style={{height: outer_h}}>
                <div className="lds-graphview-hotspot" onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave} onClick={onClick2} />
                {inner_h ? <div className="lds-graphview-innerbar" style={{height: inner_h}} /> : ''}
                {dot_h ? <div className="lds-graphview-bardot" style={{bottom: dot_h}}><div /><Line dot_h={dot_h} dot_h2={dot_h2} /></div> : ''}
                {selected && <div className="lds-graphview-selbar" style={{height: sel_h}} />}
            </div>
            {bar.label && <div className="lds-graphview-barlabel"><b>{bar.label}</b><span>{bar.sublabel}</span></div>}
        </td>
    );
}


function Popover2({anchorEl, open, handlePopoverClose, data}){
    const bar = data || {};


    const label = "";
    // const label = "The content of the Popover: " + bar.label;

    return (

        <Popover 
        id="lds-graphview-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 60, // 'right'
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        >
            <Sprite className="lds-graphview-popover" absall>

                <SpriteText debug={0} label={"Summary: " + bar.label} className={"is-label"} style={{top: 7, left: 13}} />
             <SpriteText debug={0} label={bar.revenue} style={{top: 49, left: 26}} />
             <SpriteText debug={0} label={bar.profit} style={{top: 89, left: 26}} />
             <SpriteText debug={0} label={bar.margin} style={{top: 129, left: 26}} />

            </Sprite>
            <div className="lds-graphview-popover-arrow" />
        </Popover>

    );
}

function GraphGroup({type, bars}) {
    const dispatch = useGlobalsDispatch();
    const {model} = useGlobals();
    const [popData, setPopData] = React.useState(null);

    const onClick = (event, bar) => {
        if(bar.offerID == model.Offer){
            // open edit modal
            dispatch({type: "showOfferPanel", offerID: bar.offerID, promoID: bar.promoID});
        } else {
            // make as selected
            dispatch({type: "updateModel", value: {Offer: bar.offerID}});
        }
    }


    // popover...
    const show = (type !== 'units');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event, bar) => {
      setAnchorEl(event.currentTarget);
      setPopData(bar);
      // console.log("+++++++++++ POPOVER: ", bar);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);


    // if(type == 'units') return;
    // items.map((item, key) => <GraphCell key={key} item={item} />);
    const cells = bars.map((bar, key) => <GraphCell key={key} bar={bar} onClick={onClick} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} />);

    return (
        <Sprite debug={0} className={"lds-graphview-group is-type-" + type}>
            <table>
                <tbody>
                    <tr>
                        {cells}
                    </tr>
                </tbody>
            </table>
            {show && <Popover2 anchorEl={anchorEl} open={open} data={popData} handlePopoverClose={handlePopoverClose} />}
        </Sprite>
    );
}

// --- graph view --- //

function AxisLabels2({unit, type}){
    // const unit2 = unit/1000;
    if(type === 'usd') unit = unit/1000;
    const units = [];
    const px = GRAPH_HEIGHT/7; // should be 28
    for(var i=0; i<=7; i++){
        var n = unit * i;
        units.push(<AxisLabel key={i} label={n || '0'} style={{bottom: px * i, left: 0}} />);
    }
    
    return (
        <Sprite debug={debug} className={"lds-graphview-axises2 is-type-" + type}>
            {units}
        </Sprite>
    );
}


function findUnit(max, arr){
    var unit = 0;
    for(var i=0; i<arr.length; i++){
        unit = arr[i];
         if((unit * 7) > max) break;
    }
    return unit; // just incase
}

function findMax(promo, field){
    var n = 0;
    promo.Offer_Charts.forEach(Chart => {
        n = Math.max(n, Chart[field]);
    });
    return n;
}



export function  GraphView(record) {
    const {className, promo, swappingID} = record;

    console.log("================ GraphView: ", swappingID);

/*

    const rows = record.Offer_Charts;

    const onClickName = (item) => { // item => offer chart
        const offerID = item.Offer_Obj.ID;
        dispatch({type: "showOfferPanel", offerID: offerID, promoID: record.ID}); // object: item.Offer_Obj
    }
*/

    // revenue
    var rev_max = findMax(promo, 'Revenue');
    var rev_unit = findUnit(rev_max,[1000, 10000, 20000, 50000, 100000, 150000, 200000, 1000000, 2000000, 5000000, 10000000, 20000000, 50000000]);
    rev_max = rev_unit * 7;

    // percent
    const pct_unit = 10; // fixed

    // units
    var units_max = findMax(promo, 'Units');
    var units_unit = findUnit(units_max,[10, 50, 100, 200, 500, 1000, 10000, 20000, 50000, 100000, 150000, 200000, 1000000, 2000000, 5000000, 10000000, 20000000, 50000000]);
    units_max = units_unit * 7;


    var leftbars = [];
    var rightbars = [];
    promo.Offer_Charts.forEach((Chart, i) => {
        if(i >= 3) return; // --- LIMIT 3 bars

        const promoID = record.ID;
        const offerID = Chart.Offer_Obj?.ID;
        const isBaseline = !offerID;
        const NextChart = (i < 2) ? promo.Offer_Charts[i+1] : false;

        var Revenue_Percent = Chart.Revenue <= 0 ? 0 : (Chart.Revenue / rev_max);
        var Profit_Percent = Chart.Profit <= 0 ? 0 : (Chart.Profit / rev_max);

        var Margin = Math.min(.7, Math.max(0, Chart.Margin));
        var Dot_Height = Margin * 270; // KW: this is how you figure out the dot height (prevent from going lower than 1)
        var Dot_Height2 = null;
        if(NextChart){
            Dot_Height2 = Math.min(.7, Math.max(0, NextChart.Margin)) * 270;
        }

        var Units_Percent = Chart.Units <= 0 ? 0 : (Chart.Units / units_max);

        // left side
        leftbars.push({
            isBaseline: isBaseline,
            promoID: promoID,
            offerID: offerID,
            label: Chart.Name,
            sublabel: utils.printMoney(Chart.Price),
            selected: Chart.Selected,
            swapping: offerID == swappingID,
            outer_ratio: Revenue_Percent,
            inner_ratio: Profit_Percent,
            dot_h: Dot_Height,
            dot_h2: Dot_Height2,
            revenue: utils.printMoney(Chart.Revenue),
            profit: utils.printMoney(Chart.Profit),
            margin: utils.toPercent(Chart.Margin, '-'), 
        });

        // right side
        rightbars.push({
            label: Chart.Name,
            sublabel: utils.printMoney(Chart.Price),
            selected: Chart.Selected,
            outer_ratio: Units_Percent,
        });
    });


    const css = utils.classNames("lds-graphview", className);
    return (
        <Sprite className={css}>
            <AxisLabels2 type="usd" unit={rev_unit} />
            <AxisLabels type="pct" unit={pct_unit} />
            <GraphGroup type="usd" bars={leftbars} />
            <GraphGroup type="units" bars={rightbars} />
        </Sprite>
    );
}

/*


function AxisLabels({unit, type}){
    
    return (
        <Sprite debug={0} className="lds-graphview-left">
            <AxisLabel label="1,400" style={{top: 34, left: 0}} />
            <AxisLabel label="1,200" style={{top: 62, left: 0}} />
            <AxisLabel label="1,000" style={{top: 90, left: 0}} />
            <AxisLabel label="800" style={{top: 118, left: 0}} />
            <AxisLabel label="600" style={{top: 146, left: 0}} />
            <AxisLabel label="400" style={{top: 174, left: 0}} />
            <AxisLabel label="200" style={{top: 202, left: 0}} />
            <AxisLabel label="0" style={{top: 230, left: 0}} />
        </Sprite>
    );
}


    const leftbars = [
        {
            label: "Baseline",
            sublabel: '$9',
            outer_ratio: 1,
            inner_ratio: .8,
            dot_ratio: .4,
        },
        {
            label: "Monday sale",
            sublabel: '$7',
            outer_ratio: .4,
            inner_ratio: .2,
            dot_ratio: .4,
        },
    ];

    const rightbars = [
        {
            label: "Baseline",
            sublabel: '$9',
            outer_ratio: 1,
        },
        {
            label: "Monday sale",
            sublabel: '$7',
            outer_ratio: .4,
        },
    ];



*/