
import React from "react";
import {Sprite} from '../../foundation/Sprite';



export default function MessageList (){
    return (
        <div>
            <h1>Message List</h1>
            <Sprite className="rds-dashboard-top" />
            <Sprite className="rds-dashboard-bottom" />
        </div>
    );

}