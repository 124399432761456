// import {default as MuiButton} from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
// import {Icon} from './Icon';
// import utils from '../app/utils';
import './Loader.scss';


const types = {primary: 'contained'}


export function Loader(props) {
    let {type, style, debug, } = props;

    // render
    const css = classNames("rds-loader", type && `is-type-${type}`, debug && 'is-debug');
    return (
        <div className={css} style={style}>
            <CircularProgress color="inherit" />
        </div>
    );
}

// export function Loader(props) {
//     let {icon, label, onClick,  children, type} = props;
//     onClick = utils.onClick(props);

//     if(typeof(icon) === 'string') icon = <Icon type={icon} />;

//     const variant = types[type];
//     const css = classNames('rds-button', {'rds-button--primary': type==='primary'}); // , {'rds-button--iconic': !!icon});

//     return <MuiButton className={css} variant={variant} onClick={onClick} startIcon={icon}>{label || children}</MuiButton>;
// }
