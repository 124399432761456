
import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteField, SpriteButton, SpriteText} from "../../foundation";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';


export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 0;

    const onName = (e) => {
        const value = utils.toFilledString(e.target.value, record.Name);
        dispatch({type: "updateModel", value: {Name: value}});
    }

    return (
            <Sprite debug={0} className="rds-funding-conditions  is-abs-all" style={{height:600, width: 920, left: -8}}>
                {false && <SpriteField onChange={onName} debug={debug} value={record.Name} style={{top: 76, left: 7, width: 400}} />}
            </Sprite>
    );
}

