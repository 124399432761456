import React from "react";
import utils from "../../app/utils";
import {Layout} from "../../foundation/Layout";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText} from '../../foundation/Sprite';
import classNames from "classnames";
// import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
// import {Searchbar, Titlebar, Paginationbar} from '../../components/Toolbars';
// import {DataList, DataTop, DataRow, DataCell} from '../../foundation/DataList';
// import {Icon} from '../../foundation/Icon';
// import { borderRadius } from "@mui/system";
import "./Dashboard.scss";



export default function Dashboard(){
    const dispatch = useGlobalsDispatch();
    const [editmode, setEditMode] = React.useState(false);
    const {profileData, } = useGlobals();
    // const firstName = profiles[profile]?.name || profiles.anna.name;


    const onClickPromos = () => {
        //dispatch({type: "setScreen", value: "promo-list"});
        utils.loader(dispatch, {type: "setScreen", value: "promo-timeline"}, 500); // loader
    };

    const onClickInflight = () => {
        utils.loader(dispatch, {type: "showDetails", table: "promos", id: 1}, 500); // loader
    }

    // START toggle ...
    const onEditToggle = () => {
        //utils.loaderCall(dispatch, () => setEditMode(!editmode), 300); // loader
        setEditMode(!editmode);
    }
    const footerCss = classNames("rds-dashboard-editfooter", editmode && "is-show");
    // ... end

    // <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}><CircularProgress color="inherit" /></Backdrop>
    return (
        <Layout>

            {!editmode && (
            <Sprite absall className="rds-dashboard-fullview" style={{width: 1364, height: 981, left: -8, }}>
                <SpriteText label={profileData.name} debug={0} style={{top: 27, left: 192, fontSize: 20, fontWeight: 600, }} />
                <SpriteButton debug={0} style={{top: 30, left: 1180, width: 150}} onClick={onEditToggle} />
                <SpriteText debug={0} className="rds-dashboard-date" style={{top: 27, left: 860, width: 290}} label={utils.printLongDate(new Date())} />
                <SpriteButton debug={0} onClick={onClickPromos} style={{top: 370, left: 20, width: 290}} />
                <SpriteButton debug={0} onClick={onClickInflight} style={{top: 370, left: 700, width: 250}} />
            </Sprite>
            )}

            <Sprite className="rds-dashboard-editview" style={{width: 1, height: 1, opacity: .01, }} />{/* preload image */}
            {editmode && (
            <Sprite absall className="rds-dashboard-editview" style={{width: 1364, height: 981, left: -8, top: -1, }}>  
                {false && <SpriteButton debug={0} style={{top: 30, left: 850, width: 480}} onClick={onEditToggle} />}
            </Sprite>
            )}

            <div className={footerCss}>
                <Sprite className="rds-dashboard-editcontrol" absall style={{width: 1412, height: 72, margin: '0 auto'}}>
                    <SpriteButton debug={0} style={{top: 20, left: 1100, width: 90, height: 40, }} onClick={onEditToggle} />
                    <SpriteButton debug={0} style={{top: 20, left: 1200, width: 180, height: 40, }} onClick={onEditToggle} />
                </Sprite>
            </div>

        </Layout>
    );
}

/*
            <Sprite absall className="rds-dashboard-top">
                <SpriteText debug={0} className="rds-dashboard-date" style={{top: 27, left: 850, width: 290}} label={utils.printLongDate(new Date('1/9/2023'))} />
            </Sprite>
            <Sprite absall className="rds-dashboard-bottom">
                <SpriteButton onClick={onClickPromos} debug={0} style={{top: 20, left: 20, width: 290}} />
            </Sprite>

*/