
import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteField, SpriteButton, SpriteText, SpriteSelect} from "../../foundation";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';


const options1 = [
    {value: "Account", label:"Account"},
    {value: "Invoice", label:"Invoice"},
    {value: "Cost Reduction", label:"Cost reduction"},
];
const options2 = [
    {value: "Split", label:"Split"},
    {value: "COGS Reduction", label:"COGS reduction"},
    {value: "Income", label:"Income"},
];
const options3 = [
    {value: "Cease", label:"Cease"},
    {value: "Lose", label:"Lose"},
    {value: "Roll", label:"Roll"},
];

export default function Screen({record}){
    const dispatch = useGlobalsDispatch();
    const debug = 0;

    const onName = (e) => {
        const value = utils.toFilledString(e.target.value, record.Name);
        dispatch({type: "updateModel", value: {Name: value}});
    }

    return (
            <Sprite debug={0} className="rds-funding-accounting  is-abs-all" style={{height:600, width: 'auto'}}>
                {false && <SpriteField onChange={onName} debug={debug} value={record.Name} style={{top: 76, left: 7, width: 400}} />}

                <SpriteField debug={0} style={{top: 76, left: 6, width: 400}} value={record.Accounting_System_ID} />
                <SpriteSelect debug={0} style={{top: 164, left: 10, width: 380}} value={record.Funding_Source} options={options1} />
                <SpriteSelect debug={0} style={{top: 251, left: 10, width: 380}} value={record.PnL_Attribution} options={options2} />
                <SpriteSelect debug={0} style={{top: 340, left: 10, width: 380}} value={record.Expiration_Action} options={options3} />
                

            </Sprite>
    );
}
/*
<SpriteButton debug={0} style={{top: 70, left: 6, width: 400, cursor: 'text'}} />
                <SpriteButton debug={0} style={{top: 160, left: 6, width: 400}} />
                <SpriteButton debug={0} style={{top: 250, left: 6, width: 400}} />
                <SpriteButton debug={0} style={{top: 340, left: 6, width: 400}} />
*/
