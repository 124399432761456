const rows = [// DONE: 12/20/2022
    {
      "ID": 1.1,
      "Name": "Door Sign",
      "Tactic_1": "Door Deal Feature",
      "Tactic_Min": 1,
      "Tactic_Max": 4,
      "Tactic_Mod": 0.05
    },
    {
      "ID": 1.2,
      "Name": "Door Sign",
      "Tactic_1": "Door Deal Spotlight",
      "Tactic_Min": 2,
      "Tactic_Max": 1,
      "Tactic_Mod": 0.07
    },
    {
      "ID": 2.1,
      "Name": "Counter Mat",
      "Tactic_1": "Counter Deal Feature",
      "Tactic_Min": 2,
      "Tactic_Max": 8,
      "Tactic_Mod": 0.07
    },
    {
      "ID": 2.2,
      "Name": "Counter Mat",
      "Tactic_1": "Counter Deal Spotlight",
      "Tactic_Min": 1,
      "Tactic_Max": 8,
      "Tactic_Mod": 0.17
    },
    {
      "ID": 3.1,
      "Name": "Cooler Cling",
      "Tactic_1": "Cooler Deal Feature",
      "Tactic_Min": 1,
      "Tactic_Max": 5,
      "Tactic_Mod": 0.15
    },
    {
      "ID": 3.2,
      "Name": "Cooler Cling",
      "Tactic_1": "Cooler Deal Spotlight",
      "Tactic_Min": 2,
      "Tactic_Max": 1,
      "Tactic_Mod": 0.18
    },
    {
      "ID": 4.1,
      "Name": "Gas Pump Topper",
      "Tactic_1": "Topper Deal Feature",
      "Tactic_Min": 3,
      "Tactic_Max": 2,
      "Tactic_Mod": 0.23
    },
    {
      "ID": 4.2,
      "Name": "Gas Pump Topper",
      "Tactic_1": "Topper Deal Spotlight",
      "Tactic_Min": 2,
      "Tactic_Max": 4,
      "Tactic_Mod": 0.17
    },
    {
      "ID": 5.1,
      "Name": "Diesel Poster",
      "Tactic_1": "Diesel Deal Feature",
      "Tactic_Min": 2,
      "Tactic_Max": 4,
      "Tactic_Mod": 0.27
    },
    {
      "ID": 5.2,
      "Name": "Diesel Poster",
      "Tactic_1": "Diesel Deal Spotlight",
      "Tactic_Min": 2,
      "Tactic_Max": 5,
      "Tactic_Mod": 0.15
    },
    {
      "ID": 6.1,
      "Name": "Monthly Flyer",
      "Tactic_1": "Flyer Deal Feature",
      "Tactic_Min": 2,
      "Tactic_Max": 6,
      "Tactic_Mod": 0.12
    },
    {
      "ID": 6.2,
      "Name": "Monthly Flyer",
      "Tactic_1": "Flyer Deal Spotlight",
      "Tactic_Min": 3,
      "Tactic_Max": 8,
      "Tactic_Mod": 0.08
    },
    {
      "ID": 7.1,
      "Name": "Monthly Email",
      "Tactic_1": "Email Deal Feature",
      "Tactic_Min": 1,
      "Tactic_Max": 4,
      "Tactic_Mod": 0.06
    },
    {
      "ID": 7.2,
      "Name": "Monthly Email",
      "Tactic_1": "Email Deal Spotlight",
      "Tactic_Min": 1,
      "Tactic_Max": 4,
      "Tactic_Mod": 0.26
    },
    {
      "ID": 8.1,
      "Name": "Rewards Email",
      "Tactic_1": "Rewards Deal Feature",
      "Tactic_Min": 2,
      "Tactic_Max": 8,
      "Tactic_Mod": 0.06
    },
    {
      "ID": 8.2,
      "Name": "Rewards Email",
      "Tactic_1": "Rewards Deal Spotlight",
      "Tactic_Min": 1,
      "Tactic_Max": 1,
      "Tactic_Mod": 0.09
    },
    {
      "ID": 9.1,
      "Name": "Loyalty App",
      "Tactic_1": "App Deal Banner",
      "Tactic_Min": 3,
      "Tactic_Max": 7,
      "Tactic_Mod": 0.14
    },
    {
      "ID": 9.2,
      "Name": "Loyalty App",
      "Tactic_1": "App Deal Feature",
      "Tactic_Min": 1,
      "Tactic_Max": 5,
      "Tactic_Mod": 0.09
    }
   ]

   export default rows;