const rows = [// DONE: 12/20/2022
    {
      "ID": 1,
      "Name": "Save $2",
      "Type": "Simple: Amount Off",
      "Discount_PCT": null,
      "Discount_USD": 2,
      "Price": null
    },
    {
      "ID": 2,
      "Name": "Save $1",
      "Type": "Simple: Amount Off",
      "Discount_PCT": null,
      "Discount_USD": 1,
      "Price": null
    },
    {
      "ID": 3,
      "Name": "Save $0.50",
      "Type": "Simple: Amount Off",
      "Discount_PCT": null,
      "Discount_USD": 0.5,
      "Price": null
    },
    {
      "ID": 4,
      "Name": "Save 10%",
      "Type": "Simple: Percent Off",
      "Discount_PCT": 0.1,
      "Discount_USD": null,
      "Price": null
    },
    {
      "ID": 5,
      "Name": "Save 20%",
      "Type": "Simple: Percent Off",
      "Discount_PCT": 0.2,
      "Discount_USD": null,
      "Price": null
    },
    {
      "ID": 6,
      "Name": "Save 50%",
      "Type": "Simple: Percent Off",
      "Discount_PCT": 0.5,
      "Discount_USD": null,
      "Price": null
    },
    {
      "ID": 7,
      "Name": "Save 75%",
      "Type": "Simple: Percent Off",
      "Discount_PCT": 0.75,
      "Discount_USD": null,
      "Price": null
    },
    {
      "ID": 8,
      "Name": "Spotlight Price - $1.99",
      "Type": "Simple: Price",
      "Discount_PCT": null,
      "Discount_USD": null,
      "Price": 1.99
    },
    {
      "ID": 9,
      "Name": "Spotlight Price - $4.99",
      "Type": "Simple: Price",
      "Discount_PCT": null,
      "Discount_USD": null,
      "Price": 4.99
    },
    {
      "ID": 10,
      "Name": "Spotlight Price - $9.99",
      "Type": "Simple: Price",
      "Discount_PCT": null,
      "Discount_USD": null,
      "Price": 9.99
    }
   ]

   export default rows;