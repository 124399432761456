import React from "react";
import classNames from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import {Sprite, SpriteButton, SpriteField, SpriteText} from "../foundation"
import Chip from '@mui/material/Chip';
import locationNodes from "../data/locationsTree"; // "./TreeSelect_locations";
import productNodes from "../data/productsTree";
import './TreeSelect.scss';



// export functi

function loadMap(map, node){
    map[node.value] = node;
    if(node.children) node.children.forEach(node => loadMap(map, node))
}

export function BadgeList({nodes, checked, onDelete = ()=>{}}){

    var map = {};
    nodes.forEach(node => loadMap(map, node));

    return (
        <Sprite className="rds-badgelist">
            {checked.map((id, i) => (
                map[id] && <Chip key={i} label={map[id].label} value={map[id].value} size="small" variant="outlined" onDelete={() => onDelete(id)} />
            ))}
        </Sprite>
    );
}


export function TreeList({nodes, checked, expanded, onChecked, onExpanded, placeholder}){
    // const [checked, setChecked] = React.useState(['phobos', 'deimos']);
    // const [expanded, setExpanded] = React.useState([]);

    return (
        <div className="rds-treelist is-abs-all">
            <SpriteField debug={0} style={{top: 10, left: 10, width: 400}} placeholder={placeholder} />
            <div className="rds-treelist-list">
                <CheckboxTree
                    nodes={nodes}
                    checked={checked}
                    expanded={expanded}
                    onCheck={checked => onChecked(checked)}
                    onExpand={expanded => onExpanded(expanded)}
                    iconsClass="fa5"
                />
            </div>

        </div>

    );
}



// --- main --- //



export function TreeSelect({type, className, checked, expanded, onChecked, onExpanded, style}){
    const css = classNames('rds-treeselect is-debug222 is-abs-all is-type-' + type, className);
    const isprod = (type === "products");
    var nodes = [];

    const onDelete = (value) => {
        // // --- WIP
        const item = checked.find((value2) => (value2 === value));
        const index = checked.indexOf(item);
        checked.splice(index, 1);
        console.log("VLAUE index: ", index, checked);
        onChecked([...checked]);
    }

    const onClear = () => {
        onChecked([]);
    }
    const total = checked.length ? `${checked.length} locations total` : "No locations"


    // locations or default
    nodes = isprod ? productNodes : locationNodes;
    const placeholder = isprod ? "Product search" : "Location search";


    // return
    return (
        <Sprite className={css} style={style}>
            <TreeList nodes={nodes} checked={checked} expanded={expanded} onChecked={onChecked} onExpanded={onExpanded} placeholder={placeholder} />
            <BadgeList nodes={nodes} checked={checked} onDelete={onDelete} />


            <SpriteText debug={0} style={{top: 375, left: 480, width: 150}} label={total} className="rds-treeselect-total" />

            {isprod && <SpriteButton debug={0} style={{top: 320, left: 780, width: 100}} onClick={onClear} />}
            {isprod && <SpriteButton debug={0} style={{top: 370, left: 640, width: 230}} />}

            {!isprod && <SpriteButton debug={0} style={{top: 370, left: 780, width: 100}} onClick={onClear} />}

        </Sprite>
    );
}
