import React from "react";
import {Layout} from "../../foundation/Layout";
import {Sprite, SpriteButton} from '../../foundation/Sprite';
import {Searchbar, Titlebar, Paginationbar} from '../../components/Toolbars';
import {DataList, DataTop, DataRow, DataCell} from '../../foundation/DataList';
import { useGlobalsDispatch, useGlobals } from '../../app/context'; // useGlobalsDispatch
import utils from "../../app/utils";

import "./Report.scss";



export default function Reports(){
    const dispatch = useGlobalsDispatch();
    const globals = useGlobals();

    const onClickScreen = () => {
        utils.loader(dispatch, {type: "setScreen", value: "report-details"}, 500);
        // dispatch({type: "setScreen", value: "report-details"});
    }

    return (
        <Layout className="rds-reports">

            <Sprite className="rds-reports-landing" style={{width: 1147, height: 702, marginLeft: 200}}>

                <SpriteButton debug={0} style={{top: 120, left: 0, width: 370, height: 270}} onClick={onClickScreen} />

            </Sprite>

        </Layout>
    );
}