import gen from "./_generator";
import data from "./vehicleTacticsData";

const rows = data; // direct transfer
/*
var rows = gen.rows(10, (i) => {
    return {
        ID: gen.id(i),
        Name: "Tactic " + (i+1),
        Mix: gen.randomRange(gen.ranges[11], gen.ranges[12]),
        Max: gen.randomRange(gen.ranges[13], gen.ranges[14]),
        Volume_Modifier: gen.randomRange(gen.ranges[15], gen.ranges[16])/100,
    };
});
*/

export default rows;

