const rows = [
    {
      "ID": 1,
      "Name": "SRP - Mobile Accessories - 10% Off MagBuddy Phone Mounts",
      "Internal_ID": 3000001,
      "Description": "SRP Scanback for MagBuddy Phone Mounts",
      "Status": "Approved",
      "Custom_Labels": "Valentine's Day",
      "Start_Date": "9/10/2024",
      "End_Date": "9/23/2024",
      "Period": "Period 19",
      "Funding_Type": "Scanback",
      "Funds": 10,
      "Fund_Symbol": "%",
      "Per": "Item",
      "Trigger": "On Sale",
      "Brands": "",
      "Locations": "",
      "Conditions": "",
      "Accounting_System_ID": "101333",
      "Funding_Source": "Invoice",
      "PnL_Attribution": "COGS Reduction",
      "Expiration_Action": "Cease"
    },
    {
      "ID": 2,
      "Name": "AB Mitchell Columbus - Soda Pkged - $1 Off 7Up",
      "Internal_ID": 3000002,
      "Description": "AB Mitchell Columbus Scanback for 7Up",
      "Status": "Approved",
      "Custom_Labels": "Summer Vacation",
      "Start_Date": "9/24/2024",
      "End_Date": "10/7/2024",
      "Period": "Period 20",
      "Funding_Type": "Scanback",
      "Funds": 1,
      "Fund_Symbol": "$",
      "Per": "Item",
      "Trigger": "On Sale",
      "Brands": "",
      "Locations": "",
      "Conditions": "",
      "Accounting_System_ID": "439002",
      "Funding_Source": "Invoice",
      "PnL_Attribution": "COGS Reduction",
      "Expiration_Action": "Cease"
    },
    {
      "ID": 3,
      "Name": "DAS - Bluetooth - 20% Off LG Speakers",
      "Internal_ID": 3000003,
      "Description": "DAS Scanback for LG Speakers",
      "Status": "Approved",
      "Custom_Labels": "Halloween",
      "Start_Date": "12/3/2024",
      "End_Date": "12/16/20243",
      "Period": "Period 25",
      "Funding_Type": "Scanback",
      "Funds": 20,
      "Fund_Symbol": "%",
      "Per": "Item",
      "Trigger": "On Sale",
      "Brands": "",
      "Locations": "",
      "Conditions": "",
      "Accounting_System_ID": "945801",
      "Funding_Source": "Invoice",
      "PnL_Attribution": "COGS Reduction",
      "Expiration_Action": "Cease"
    }
   ]

export default rows;