import React from "react";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {TabLayout, TabLayoutLeft, TabLayoutRight, Template, Sprite, SpriteButton, SpriteText} from "../../foundation";
import {FullviewButton} from "../../Panels/MessagePanel";
import PromoDefine from "./PromoDefine";
import PromoCustomer from "./PromoCustomer";
import PromoProduct from "./PromoProduct";
import PromoLocation from "./PromoLocation";
import PromoVehicles from "./PromoVehicles";
import PromoFunding from "./PromoFunding";
import PromoOffer from "./PromoOffer";
// import {ViewPromoBar} from '../../components/Toolbars';
import classNames from 'classnames';
import utils from "../../app/utils";
// import "./Dashboard.scss";

// function Below(){
//     return (
//         <div className='rds-tabnav-below'>
//             <Template type="changelogs" />
//         </div>
//     );
// }

function Nav ({screen}) {
    const dispatch = useGlobalsDispatch();
    // const css = classNames('rds-tabnav', className);
    // console.log("------ FLAG 111:", screen);
    if(screen === "promo" || screen === "promo-view") screen = "promo-define";
    const onClick = (screen) => {dispatch({type: "setScreen", value: screen})};
    return (
        <div className='rds-tabnav is-type-promo' style={{height: 478}}>
            <SpriteButton debug={0} className={screen === "promo-define" && "is-selected"} onClick={() => onClick("promo-define")} />
            <SpriteButton debug={0} className={screen === "promo-customer" && "is-selected"} onClick={() => onClick("promo-customer")} />
            <SpriteButton debug={0} className={screen === "promo-product" && "is-selected"} onClick={() => onClick("promo-product")} />
            <SpriteButton debug={0} className={screen === "promo-location" && "is-selected"} onClick={() => onClick("promo-location")} />
            <SpriteButton debug={0} className={screen === "promo-vehicles" && "is-selected"} onClick={() => onClick("promo-vehicles")} />
            <SpriteButton debug={0} className={screen === "promo-funding" && "is-selected"} onClick={() => onClick("promo-funding")} />
            <SpriteButton debug={0} className={screen === "promo-offer" && "is-selected"} onClick={() => onClick("promo-offer")} />
        </div>
    );
}



function Content({screen}){
    const globals = useGlobals();
    const record = globals.model;

    switch(screen){
        default:
        case 'promo-define':     return <PromoDefine record={record} />;
        case 'promo-customer':     return <PromoCustomer record={record} />;
        case 'promo-product':    return <PromoProduct record={record} />;
        case 'promo-location':    return <PromoLocation record={record} />;
        case 'promo-vehicles':    return <PromoVehicles record={record} />;
        case 'promo-funding':    return <PromoFunding record={record} />;
        case 'promo-offer':    return <PromoOffer record={record} />;
    }
}

function ViewPromoBar({label}){
    const globals = useGlobals();
    const dispatch = useGlobalsDispatch();
    const {model} = globals; 

    const onClickCancel = () => {
        // dispatch({type: 'cancelPromo'});
        utils.loader(dispatch, {type: 'cancelPromo'}, 1000);
    };
    const onClickSave = () => {
        utils.loader(dispatch, {type: "saveDetails", table: "promos", screen: "promo-timeline"}, 3000);
        // dispatch({type: "saveDetails", table: "promos", screen: "promo-list"});
    };

    return (
        <Sprite className="rds-viewpromobar is-center" style={{width: 1344, height: 40, marginBottom: 20}}>
            <SpriteText className="is-graybg is-abs is-body2 is-debu" style={{left: 200, minWidth: 400, top: 4}}>{label}</SpriteText>
            <SpriteButton className="is-abs-right" style={{top: 1, right: 120}} debug={0} onClick={onClickCancel} href222="/" />{/* HREF */}
            <SpriteButton className="is-abs-right" style={{top: 1, right: 1}} debug={0} onClick={onClickSave} />
        </Sprite>
    );
}


export default function Screen({screen}){
    const globals = useGlobals();
    const {model} = globals;
    if(!screen) screen = "promo-define";
    return (
        <div>
            <ViewPromoBar label={model.Name} />

            <TabLayout>
                <TabLayoutLeft>
                    <Nav screen={screen} />
                    <Template type="changelogs" />
                    
                    <FullviewButton />
                </TabLayoutLeft>
                <TabLayoutRight>
                    <Content screen={screen} />
                </TabLayoutRight>
            </TabLayout>
        </div>
    );
}