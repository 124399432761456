import React from "react";
import utils from "../../app/utils";
import {useGlobals, useGlobalsDispatch} from '../../app/context';
import {Sprite, SpriteButton, SpriteText, SpriteField} from "../../foundation";
import {TreeSelect} from "../../components/TreeSelect";



export default function Screen({record}){
    const dispatch = useGlobalsDispatch();

    // tree START
    const onChecked = (checkeds) => {
        dispatch({type: "updateModel", value: {Products: checkeds}});
    }
    const onExpanded = (expanded) => {
        dispatch({type: "updateModel", value: {_Products_Expanded: expanded}});
        // save which are expanded even if NOT saving
        if(record.ID) dispatch({type: "updateRow", table: "fundings", id: record.ID, values: {_Products_Expanded: expanded}});
    }
    // tree END

    return (
        <React.Fragment>
            <Sprite id="rds-products-title" style={{top: 0, left: 0, width: 880, marginBottom: 20, }} />
            <TreeSelect type="products" checked={record.Products} expanded={record._Products_Expanded} onChecked={onChecked} onExpanded={onExpanded} style={{top: 0, left: 0}} />
        </React.Fragment>
    );
};

