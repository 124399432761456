import React from "react";
import classNames from 'classnames';
import MuiInput from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import utils from '../app/utils';
import './Sprite.scss';

// import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';



export function Sprite(props){
    const css = classNames('rds-sprite', props.className, props.debug && 'is-debug', props.debugall && 'is-debug-all', props.absall && 'is-absall');
    var props2 = {...props};
    delete props2.absall;
    return <div {...props2} onClick={utils.onClick(props)} className={css}>{props.children}</div>;
}


export function SpriteCaching(props){
    const {items, baseClass, modifiers, } = props;
    const style = {position: 'absolute', width: 1, height: 1, };

    // has baseclass?
    if(baseClass){
        if(Array.isArray(modifiers)) return modifiers.map(item => <Sprite className={baseClass + " " + item} style={style} />);
        props.className = baseClass;
    }

    // not array
    if(!Array.isArray(items)) return <Sprite {...props} style={style} />;

    // has items
    return items.map((item) => {
        if(item[0] === '#') return <Sprite id={item.substring(1, 100)} style={style} />;
        return (item[0] === '.') ? <Sprite className={item.substring(1, 100)} style={style} /> : <Sprite className={item} style={style} />;
    });
}


export function SpriteButton(props){
    const css = classNames('rds-spritebutton', props.className, props.debug && 'is-debug', props.hover && 'is-hover');
    var props2 = {...props};
    delete props2.absall;
    if(props2.href) return <a {...props2} onClick={utils.onClick(props)} className={css}>{props.label || props.children}</a>;
    return <div {...props2} onClick={utils.onClick(props)} className={css}>{props.label || props.children}</div>;
}


export function SpriteHover(props){
    const css = classNames('rds-spritehover', props.className, props.debug && 'is-debug');
    var props2 = {...props};
    delete props2.absall;
    if(props2.href) return <a {...props2} onClick={utils.onClick(props)} className={css}>{props.label || props.children}</a>;
    return <div {...props2} onClick={utils.onClick(props)} className={css}>{props.label || props.children}</div>;
}



export function SpriteTooltip(props){
    const {tooltip="Tooltip", style={}, className, debug, placement="top", onOpen, onClose, enterDelay, children, id, onClick, } = props;
    const style2 = {...{width: 30, height: 30, }, ...style};

    // code
    const [showingTooltip, showTooltip] = React.useState(false);
    const onTooltipClose = () => {
        showTooltip(false);
        if(onClose) onClose();
    }
    const onTooltipOpen = () => {
        showTooltip(true);
        if(onOpen) onOpen();
    }

   
    const onClick2 = () => console.log("++++++++ onClick: ");

    // return
    const css = classNames('rds-spritetooltip', className, debug && 'is-debug');
    return (
        <Tooltip onClick={onClick2} open={showingTooltip} id={id} onClose={onTooltipClose} placement={placement} enterDelay={enterDelay} onOpen={onTooltipOpen} title={tooltip} arrow>
            <Button className={css} onClick={onClick} style={style2}>&nbsp;</Button>
        </Tooltip>
    );

}


export function SpriteText(props){
    const css = classNames('rds-spritetext', props.className, props.debug && 'is-debug');
    return <div {...props} onClick={utils.onClick(props)} className={css}>{props.label || props.children}</div>;
}

export function SpriteInput(props){
    const css = classNames('rds-spriteinput', props.className, props.debug && 'is-debug');
    return <input {...props} className={css} />
    // return <div {...props} onClick={utils.onClick(props)} className={css}>{props.label || props.children}</div>;
}
export function SpriteCheckbox(props){
    const {style, className, debug, onChange, value=1, name, checked=false} = props;
    const css = classNames('rds-spritecheckbox', className, debug && 'is-debug');
    return <label className={css} style={style}><input type="checkbox" name={name} value={value} checked={checked} onChange={onChange} /></label>
}

export function SpriteCheckboxAuto(props){
    let {style, className, debug, onChange, value=1, name, checked=false, auto, onClick, } = props;
    
    // auto
    const [checked2, setChecked] = React.useState(checked);
    let onChange2 = () => {};
    if(auto){
        checked = checked2;
        onChange2 = (e) => {
            setChecked(!checked2);
            if(onChange) onChange(e);
        }
    }

    // render
    const css = classNames('rds-spritecheckbox', className, debug && 'is-debug');
    return <label className={css} style={style}><input type="checkbox" name={name} value={value} checked={checked} onClick={onClick} onChange={onChange2} /></label>
}

SpriteCheckbox.Auto = SpriteCheckboxAuto;

// export function SpriteCheckboxAuto(props){
//     return <SpriteCheckbox {...props} checked={checked} onChange={() => setChecked(!checked)} />
// }





export function SpriteField(props){
    var props2 = {...props};
    const {onChange, password} = props2;
    if(onChange) delete props2['onChange'];
    const [value, setValue] = React.useState(props.value);
    const onChangeValue = (e) => {
        setValue(e.target.value);
    }


    React.useEffect(() => { // --- NEW: listen for any changes from props
        setValue(props.value);
    }, [props]);

    const inputType = password ? "password" : "text";
    if(password) delete props2['password'];


    const css = classNames('rds-spriteinput', props.className, props.debug && 'is-debug', props.disabled && 'is-disabled');
    return <input {...props2} onChange={onChangeValue} type={inputType} onBlur={onChange} value={value} className={css} />
}


export function SpriteSelect222(props){
    const css = classNames('rds-spriteselect', props.className);
    return <SpriteField {...props} className={css} />
}


const defaultOptions = [
    {value: "one", label: "Option 1"},
    {value: "two", label: "Option 2"},
    {value: "three", label: "Option 3"},
];



function SpriteSelectButton(props){
    const css = classNames('rds-spriteselect', props.className, props.debug && 'is-debug');
    var props2 = {...props};
    delete props2.absall;
    return <div {...props2} onClick={utils.onClick(props)} className={css}>{props.label || props.children}</div>;
}

function SpriteSelectAuto (props) {
    const {options, placeholder, value, name} = props;
    const [value2, setValue] = React.useState(null);

    const onChange = (value) => {
        setValue(value);
    }

    React.useEffect(() => {
        setValue(value);
    }, []);

    return <SpriteSelect {...props} onChange={onChange} value={value2} />;
}


export function SpriteSelect (props) {
    var {name, value, onChange, options, placeholder, className, style, menuWidth, anchorOrigin, transformOrigin, debug} = props;

    // options
    if (!options) options = defaultOptions;
    else if(typeof(options[0]) !== 'object'){
        var options2 = [];
        for(var i=0; i<options.length; i++) options2.push({value: options[i], label: options[i]+''});
        options = options2;
    }


    const [anchorEl, setAnchorEl] = React.useState(null); // KEEP HERE: it must be before if() to avoid error
    if(!onChange) return <SpriteSelectAuto {...props} />; // AUTO
    const open = Boolean(anchorEl);
    const handleClick = (event) => {setAnchorEl(event.currentTarget);};
    const handleClose = () => {setAnchorEl(null);};

    // anchorOrigin / transformOrigin
    if(!anchorOrigin) anchorOrigin = {vertical: 30, horizontal: -12, };
    if(!transformOrigin) transformOrigin = {vertical: 'top', horizontal: 'left', };

    // on click item
    const onClickItem = (e, item) => {
        const value2 = e.target.getAttribute("value");
        if(item.value == value2){
            var target = {};
            target.name = name;
            target.value = value2;
            console.log("########################## VALUE: ", value2, " 222: ", item.value);
            target.getAttribute = () => {return value2;}
            onChange(item.value, item, {target: target});
        }
        handleClose();
    }


    // selection
    var SelItem = options.find(obj => obj.value == value);
    const isEmpty = !SelItem;
    const label = SelItem?.label || placeholder || "Empty";
    if(name === "test1") console.log("@@@@@@@@@@@@@@@@@@@@@@@: test1555: ", value, " yooo: ", SelItem);

    // options
    if(!options) options = [];
    const items = options.map((item, key) => {
        var selected = SelItem && item.value == SelItem.value;
        return (
            <MenuItem key={key} value={item.value} onClick={(e) => onClickItem(e, item)}>
                {item.label}{selected && <ListItemIcon><Check /></ListItemIcon>}
            </MenuItem>
        );
    });
    
    const css = utils.classNames("lds-spriteselect", isEmpty && "is-empty", className) // isEmpty ? "is-empty" : null;
    return (
        <React.Fragment>
            {/* <SpriteButton label={label} onClick={handleClick} className={css} style={style} /> */}
            <SpriteSelectButton debug={debug} label={label} onClick={handleClick} className={css} style={style} />
            <Menu 
                id="basic-menu" 
                anchorEl={anchorEl} 
                open={open} 
                onClose={handleClose} 
                MenuListProps={{'aria-labelledby': 'basic-button', }}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={menuWidth && {style: {width: menuWidth}}}
            >
                {items}
            </Menu>
        </React.Fragment>
    )


}



// --- list hover --- //

function SpriteListHoverItem(props){
    let {height, onClick, index, style, selected, } = props;

    if(height){
        if(!style) style = {};
        style.height = height;
    }

    const onClick2 = () => {
        if(onClick) onClick(index);
    }

    // render
    const css = classNames('is-index-' + index, selected && 'is-selected');
    return <li className={css} style={style} onClick={onClick2} />
}

export function SpriteListHover(props){
    const {className, id, style, debug, rounded, onClick, count=1, itemHeight, itemStyle, itemProps, onClickItem, selection, } = props;

    // items
    let children = [];
    for(var i=0; i<count; i++){
        children.push(<SpriteListHoverItem key={i} index={i} {...itemProps} height={itemHeight} style={itemStyle} onClick={onClickItem} selected={selection==i} />);
    }

    // render
    const css = classNames('rds-spritelisthover', className, debug && 'is-debug');
    return (
        <div className={css} id={id} style={style} onClick={onClick}>
            <ul className={rounded ? "rds-listhover is-rounded" : "rds-listhover"}>{children}</ul>
        </div>
    );
}









// export function Template(props){
//     const css = classNames('rds-template-' + props.type, props.className);
//     switch(props.type){
//         case "buttons-confirm": return (
//             <Sprite debug={0} style={{width: 190, height: 50, ...props.style}} className={css}>
//                 <SpriteButton debug={0} onClick={props.onClick1} className="is-abs" style={{width: 90, top: 8, left: 6}} />
//                 <SpriteButton debug={0} onClick={props.onClick2} className="is-abs" style={{width: 90, top: 8, left: 100}} />
//             </Sprite>
//         );
//         default: console.warn("Template() Invalid type: ", props.type);
//     }
// }


